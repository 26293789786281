import React from "react";

const Main = ({ data }) => {
  return (
    <div className="flex flex-col w-full gap-4 text-[12px] my-4 p-2">
      <div className="w-full">
        <p>{data?.data?.firstKey}</p>
        <input
          type="text"
          defaultValue={data?.data?.firstVal}
          className="w-full"
        />
        <hr />
      </div>
      <div>
        {data?.data?.label === "Output" ? (
          <div className="flex gap-2">
            <p>Output Type</p>
            <select className="border border-[#eee]">
              <option value={"string"}>string</option>
              <option value={"string[]"}>string[]</option>
              <option value={"any"}>any</option>
            </select>
          </div>
        ) : (
          <>
            <p>Input Name</p>
            <p>user_email</p>
            <hr />
          </>
        )}
      </div>
    </div>
  );
};

export default Main;
