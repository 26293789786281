import { useCallback } from "react";
import { Handle, Position } from "@xyflow/react";
import LeadHead from "./LeadHead";
import { Select } from "antd";
import DecisionGate from "./DecisionGateComponent";
import WebhookTrigger from "./WebhookTriggercomponent";
import EnrichAPINodeComponent from "./EnrichAPIComponent";
import PushAPINodeComponent from "./PushAPIComponent";
import SheetSelector from "./GoogleSheetNodeComponent";
// import Formulas from "./Formulas";

const handleStyle = { left: 10 };

function LeadSummarizerNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
    console.log(data);
  }, []);

  const options = [
    { label: "Add to Nurture Campaign", value: "Add to Nurture Campaign" },
    { label: "Sales Leads", value: "Sales Leads" },
    { label: "general", value: "general" },
  ];

  return (
    <div className={`rounded-[5px] bg-white ${data?.id === "node-17" ? 'min-w-[27.5rem] border-x border-b border-black' : data?.id === "node-18" ? 'min-w-[24rem] border-x border-b border-black' : 'min-w-[20rem] border border-[#eee]'}`}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className="flex flex-col justify-start items-start">
        <LeadHead data={data} />
        {(data?.id == 'node-25') &&
          <div className="w-full text-[12px] mt-2 flex flex-col gap-1 p-2">
              <p>Action</p>
              <Select options={options} className="w-full" defaultValue={options[0].value} />
          </div>
        }
        {["node-19", "node-20"].includes(data?.id) && (
          <DecisionGate defaultOption={data?.id === "node-19" ? "response.results.match" : "account_icp.valid" 
          }/>
        )}
        {data?.id == "node-1" && 
          <WebhookTrigger/>
        }
        {data?.id == "node-3" && 
          <SheetSelector/>
        }
        {["node-13","node-21", "node-22"].includes(data?.id) && 
          <EnrichAPINodeComponent type={data?.id==="node-13" ? 'check' : data?.id==="node-22" ? 'account' : 'person'}/>
        }
        {
          ["node-23", "node-24"].includes(data?.id) && (
            <PushAPINodeComponent type={data?.id==='node-23' ? 'person' : 'account'}/>
          )
        }
        


        
      </div>
      {data?.id === "node-1" && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          style={handleStyle}
          isConnectable={isConnectable}
        />
      )}
      {!["node-14"].includes(data?.id) && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
}

export default LeadSummarizerNode;
