import React, { useEffect } from "react";
import Canvas from "../Canvas/Canvas";
import { useRecoilValue } from "recoil";
import { agentValAtom } from "../../store";

const AgentHow = () => {
  const agentVal = useRecoilValue(agentValAtom);
  useEffect(() => {
    console.log(agentVal, "agentVal----------");
  }, []);

  return (
    <div className="flex flex-col gap-4 justify-center px-6 relative">
      <p className="text-character h4_Medium">How it works</p>
      <div className="flex flex-col gap-2 justify-start w-1/2 min-h-40">
        {agentVal?.works?.split("\n")?.map((work, index) => (
          <p className="text-secondary h4_Medium" key={index}>
            {work}
          </p>
        )) || (
          agentVal?.process && <p className="text-secondary h5_Medium">
          {agentVal?.process}
        </p>
        )}
      </div>
      <div className="absolute right-5 -top-16 w-1/3 h-[135%] border rounded-md border-light-primary bg-[#F6F8FA]">
        <Canvas />
      </div>
    </div>
  );
};

export default AgentHow;
