import { atom } from "recoil";

const progGumloopRowsAtom = atom({
  key: "progGumloopRows",
  default: 1,
});

const progGumloopInputFilledAtom = atom({
  key: "progGumloopInputFilled",
  default: false,
});

const progGumloopQuestionAtom = atom({
  key: "progGumloopQuestion",
  default: "",
});

const progGumloopChatArrayAtom = atom({
  key: "progGumloopChatArray",
  default: [
    {
      answer:
        "I see you have selected the Lead Research Template. Would you like help setting it up?"
    },
  ],
});

const progGumloopLoadingAtom = atom({
  key: "progGumloopLoading",
  default: false,
});

const progFocusNodeIdAtom = atom({
    key: "progFocusNodeId",
    default: null
})

const progNewNodeAtom = atom({
    key: "progNewNode",
    default: false
})

const progContactAtom = atom({
    key: "progContact",
    default: false
})

const progSlackAtom = atom({
    key: "progSlack",
    default: false
})

const progFitEntireAtom = atom({
    key: "progFitEntire",
    default: false
})

const progTestLoadingAtom = atom({
    key: "progTestLoading",
    default: false
})

const progDeployAtom = atom({
    key: "progDeploy",
    default: false
})

const progCollapsibleAtom = atom({
  key: "progCollapsible",
  default: false
})

const progChecked = atom({
  key: "progChecked",
  default: 1
})

const progEmailAtom = atom({
  key: "progEmail",
  default: "cisco"
})

const progPolledContentAtom = atom({
  key: "progPolledContent",
  default: ""
})

const progTestEmailAtom = atom({
  key: "progTestEmail",
  default: ""
})

const progVisibleAtom = atom({
  key: "progVisible",
  default: [0, 1, 2]
})

const progActiveIndexAtom = atom({
  key: "progActiveIndex",
  default: 0
})

const progInputAtom = atom({
  key: "progInput",
  default: {
    "company_info": [
      {
        "id": "",
        "company": "Cisco",
        "country": "USA",
        "website": "https://www.cisco.com"
      }
    ],
    "job_function": [
      "Accounting / Finance"
    ],
    "job_level": [
      "C-level / CXO"
    ],
    "max_leads_count": 10
  }
})

const progFinalAtom = atom({
  key: "progFinal",
  default: []
})

const progModalOpenAtom = atom({
  key: "progModalOpen",
  default: false
})

const progBtnLoadingAtom = atom({
  key: "progBtnLoading",
  default: false
})

const progNodeInProgressAtom = atom({
  key: "progNodeInProgress",
  default: null
})

export {
  progGumloopRowsAtom,
  progGumloopInputFilledAtom,
  progGumloopQuestionAtom,
  progGumloopChatArrayAtom,
  progGumloopLoadingAtom,
  progFocusNodeIdAtom,
  progNewNodeAtom,
  progContactAtom,
  progSlackAtom,
  progFitEntireAtom,
  progTestLoadingAtom,
  progDeployAtom,
  progCollapsibleAtom,
  progChecked,
  progEmailAtom,
  progPolledContentAtom,
  progTestEmailAtom,
  progVisibleAtom,
  progActiveIndexAtom,
  progInputAtom,
  progFinalAtom,
  progModalOpenAtom,
  progBtnLoadingAtom,
  progNodeInProgressAtom
};