import { atom } from "recoil";

const selectedWorkspaceAtom = atom({
  key: "selectedWorkspace",
  default: null,
});

const listWorkspacesAtom = atom({
  key: "listWorkspaces",
  default: [],
});

const workspaceUserPermissionAtom = atom({
  key: "workspacePermissions",
  default: [],
});

const workspaceRolesAtom = atom({
  key: "workspaceRoles",
  default: [],
});

const workspaceResourcesAtom = atom({
  key: "workspaceResources",
  default: [],
});

export {
  selectedWorkspaceAtom,
  listWorkspacesAtom,
  workspaceUserPermissionAtom,
  workspaceRolesAtom,
  workspaceResourcesAtom,
};
