import { Card, Spin } from "antd";
import { useEffect, useState } from "react";
import { Client } from "../../services/Client";
import { useRecoilState } from "recoil";
import {
  listWorkspacesAtom,
  selectedWorkspaceAtom,
} from "../../store/atoms/WorkspaceAtoms";
import { authUserAtom } from "../../store/atoms/AuthUserAtoms";
import { useNavigate } from "react-router-dom";

const Workspaces = () => {
  const [loading, setLoading] = useState(true);
  const [workspaceList, setWorkspaceList] = useRecoilState(listWorkspacesAtom);
  const [authUser] = useRecoilState(authUserAtom);
  const [_, setSelectedWorkspace] = useRecoilState(selectedWorkspaceAtom);

  const navigate = useNavigate();

  const fetchWorkspaces = async () => {
    try {
      const { data } = await Client.get("/workspace/list");
      if (data?.data) {
        setWorkspaceList(data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSelectWorkspace = (value) => {
    setSelectedWorkspace(value);
    navigate("/settings");
  };

  useEffect(() => {
    if (authUser) {
      fetchWorkspaces();
    }
  }, [authUser]);

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <div
      className="w-screen h-screen
       flex justify-center items-center"
    >
      <div className="w-3/4">
        <Card title="Welcome to GTM Co Pilot">
          <div className="w-full h-full p-8 flex flex-col gap-6">
            <div className="flex justify-between">
              <div className="font-bold text-xl">Select a Workspace</div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="font-bold text-lg text-gray-600">
                Organisation
              </div>
              <div className="font-semibold text-base">Test</div>
            </div>

            <div className="flex flex-col gap-4">
              {workspaceList.map((ele, index) => (
                <div
                  className={`w-full p-2 flex justify-between border-2 cursor-pointer rounded-md hover:border-blue-400`}
                  onClick={() => handleSelectWorkspace(ele)}
                >
                  <div className="flex gap-4 items-center">
                    <div className="text-normal">{ele.workspace_name}</div>
                  </div>
                </div>
              ))}

              {workspaceList.length === 0 ? (
                <div className=" p-2 text-center text-normal">
                  No workspaces found
                </div>
              ) : null}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Workspaces;
