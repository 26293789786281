import React from 'react'
import { CaretDownOutlined } from "@ant-design/icons"
import { btnClickedAtom } from '../../store'
import { useRecoilValue } from 'recoil'

const CustomButton = ({ id, text, icon, onClick }) => {
  const btnClicked = useRecoilValue(btnClickedAtom);

  return (
    <div className={`flex justify-center items-center gap-1 border rounded-lg border-2 px-2 cursor-pointer ${btnClicked && id === 1 && 'bg-[#8EA9D0]'} hover:bg-[#8EA9D0] hover:scale-105 duration-300 ease-in-out`} onClick={id === 1 ? onClick : null}>
        <img src={icon} alt={`${text}_img`} width={text === "Test" ? 22 : 18} />
        <p className='text-base'>{text}</p>
        {text === "Save" && <CaretDownOutlined />}
    </div>
  )
}

export default CustomButton