import React from 'react'
import imageImg from "../../assets/images/imageImg.png";
import plusImg from "../../assets/images/plusImg.png";
import calendarImg from "../../assets/images/calendarImg.png";
import branchImg from "../../assets/images/branchImg.png";
import helpImg from "../../assets/images/helpImg.png";

const SideOpt = () => {
    const imgs = [
        imageImg,
        plusImg,
        calendarImg,
        branchImg,
        helpImg
    ]
  return (
    <div className='absolute left-5 top-32 z-50 flex flex-col gap-2 border border-2 p-4 bg-white rounded-lg'>
        {imgs.map((img, index) => (
            <img src={img} key={index} width={[1, 2, 4].includes(index) ? 26 : 22} className={`${[0, 3].includes(index) && 'ml-0.5'} cursor-pointer hover:scale-110 duration-300 ease-in-out`} />
        ))}
    </div>
  )
}

export default SideOpt