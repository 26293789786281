import React, { useState, useEffect } from 'react';
import { Table, Spin, Alert } from 'antd';
import { fetchTableData } from '../../services/InsightsServices'; // Adjust path as necessary

const DisplayTableComponent = ({ postgres_query, minimized }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = postgres_query.trim();
        if (!query.endsWith(';')) {
          query += ';';
        }
        if (minimized) {
          if (query.endsWith(';')) {
            query = query.slice(0, -1);
          }
          query += ' LIMIT 10;';
        }
        const result = await fetchTableData({ sqlQuery: query });
        setData(result.data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [postgres_query, minimized]);

  if (loading) return <>Generating table based on the data...</>;
  if (error) return <Alert message="Error" description="Getting some issues in displaying the table right now" type="error" showIcon />;

  const columns = data.length > 0 ? Object.keys(data[0]).map(key => ({
    title: key,
    dataIndex: key,
    key: key,
    align: 'center',
  })) : [];

  return (
    <Table
      className='w-full'
      style={{ overflowX: "scroll" }}
      dataSource={data}
      columns={columns}
      pagination={!minimized}
      rowKey={(record) => record.id || record.key || record[Object.keys(record)[0]]}
    />
  );
};

export default DisplayTableComponent;
