import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import shareImg from "../../assets/images/shareImg.png";
import saveImg from "../../assets/images/saveImg.png";
import testImg from "../../assets/images/testImg.png";
import deployImg from "../../assets/images/deployImg.png";
import EnrichCustomButton from "./EnrichCustomButton";
import { Spin, Modal, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { enrichTestLoadingAtom, enrichDeployAtom, enrichGumloopChatArrayAtom, enrichEmailAtom, enrichPolledContentAtom, enrichTestEmailAtom, enrichVisibleAtom, enrichActiveIndexAtom, enrichCollapsibleAtom, enrichFocusNodeIdAtom } from "../../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { jwtDecode } from "jwt-decode";
import { startAgent, pollAgent, chatAgent } from "../../services/waterfallServices.js";
// import GumSuccess from "./GumSuccess";

const EnrichMainHeader = ({ setFinalContent, setModalOpen }) => {
  const [activeHead, setActiveHead] = useState(0);
  const [testClicked, setTestClicked] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);

  const [loading, setLoading] = useRecoilState(enrichTestLoadingAtom);
  const [deploy, setDeploy] = useRecoilState(enrichDeployAtom);
  const setChatArray = useSetRecoilState(enrichGumloopChatArrayAtom);
  // const [modalOpen, setModalOpen] = useState(false);
  const email = useRecoilValue(enrichEmailAtom);
  const setPolledContent = useSetRecoilState(enrichPolledContentAtom);
  const setTestEmail = useSetRecoilState(enrichTestEmailAtom);
  const setVisibleProcess = useSetRecoilState(enrichVisibleAtom);
  const setActiveIndex = useSetRecoilState(enrichActiveIndexAtom);
  const setIsCollapsed = useSetRecoilState(enrichCollapsibleAtom);
  const setFocusNodeId = useSetRecoilState(enrichFocusNodeIdAtom);
  const location = useLocation();
  const btns = [
    {
      text: "Save",
      icon: saveImg,
    },
    {
      text: "Test",
      icon: testImg,
    },
    {
      text: "Deploy",
      icon: deployImg,
    },
  ];
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (loading) {
  //       setLoading(false);
  //       setModalOpen(true);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, [loading]);
  const handleClick = async (index) => {
    if (index !== 1) {
      return;
    }
    
    setLoading(false);
    setTempLoading(true);
    setIsCollapsed(true);
    // setTestEmail("");
    setVisibleProcess([0, 1, 2]);
    setPolledContent(null)
    setActiveIndex(0)
    setModalOpen(false)
    const searchParams = new URLSearchParams(location?.search);
    const params = Object.fromEntries(searchParams?.entries())
    // console.log(JSON.stringify(params, null, 2))

    // console.log(email, "email")
    // console.log(params?.flow_id, "session_id");
    // console.log(jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id, "user_id")

    const response = await startAgent({
      email,
      session_id: params?.flow_id,
      user_id: jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
      testClicked
    })
    // console.log(response, "response")
    setTestClicked(true);
    
    await new Promise(resolve => setTimeout(resolve, 3000));

    let flag = false
    setTestEmail(email)
    setLoading(true);
    setIsCollapsed(false);

    while(!flag) {
      await new Promise(resolve => setTimeout(resolve, 3000));
      const responseAgent = await pollAgent({
        session_id: params?.flow_id,
        user_id: jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id
      })
      // console.log(responseAgent?.data?.async_out, "responseAgent")
      setPolledContent(responseAgent?.data?.async_out)
      flag = responseAgent?.data?.flag;
    }
    const responseChatArray = await chatAgent({
      session_id: params?.flow_id,
      user_id: jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
    }) 
    console.log(JSON.parse(responseChatArray?.data?.chatArray))
    setFinalContent(responseChatArray?.data?.chatArray);
    setFocusNodeId("whole");
    setTempLoading(false);
    setModalOpen(true);
  };
  const handleWorkflow = () => {
    setModalOpen(false);
    setDeploy(true);
    setChatArray(prev => ([
      ...prev,
      {
        question: "Yes",
        answer: "Deployed! To see your deployed workflows & their performance, please navigate to Live in the Main Menu."
      }
    ]))
  }
  return (
    <>
      <Modal
        title="Generated Output"
        centered
        open={false}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleWorkflow}
          >
            Deploy Workflow
          </Button>,
        ]}
      >
        <div className="mt-4 flex flex-col gap-1">
          <p className="font-semibold">
            max@hubspot.com (2,170,000,000 ARR)
          </p>
          <p>
            <span className="font-semibold">INFO:</span> HubSpot is a leading
            AI-powered customer relationship management (CRM) platform that
            provides integrated software and tools for marketing, sales,
            customer service, content management, operations, and commerce,
            enabling businesses to grow and connect with their customers more
            effectively.
          </p>
          <p>
            <span className="font-semibold">Company name:</span> HubSpot
          </p>
          <p>
            <span className="font-semibold">Industry:</span> Information
            Technology & Services
          </p>
          <p>
            <span className="font-semibold">Country:</span> United States
          </p>
        </div>
      </Modal>
      {/* <Modal
          title=""
          visible={deploy}
          onCancel={() => setDeploy(false)}
          footer={null}
          className=""
          centered
        >
          <div className="h-[50vh] flex flex-col justify-center items-center gap-4">
            <GumSuccess setModal={setDeploy}/>
          </div>
        </Modal> */}
      <div className="p-2 ml-3">
        <div className="flex justify-start items-center pl-1 pr-4 border-b border-gray-300">
          {["Builder", "Files", "Log"].map((tab, index) => (
            <div
              key={index}
              className={`${
                activeHead === index
                  ? "text-[#2966BC] border-b-2 border-[#2966BC]"
                  : "text-gray-600"
              } px-4 py-2 text-sm cursor-pointer`}
              onClick={() => setActiveHead(index)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="flex w-full justify-between items-center p-2 mt-1 border border-2 bg-white rounded-lg">
          <p className="text-base">Enrichment Waterfall</p>
          <div className="flex gap-1 items-center">
            <img
              src={shareImg}
              alt="shareImg"
              width={30}
              className="hover:scale-110 duration-300 ease-in-out cursor-pointer"
            />
            <div className="flex gap-2">
              {btns.map((btnData, idx) => {
                // if (idx === 1 && loading) {
                //   return (
                //     <Spin
                //       key="spinner"
                //       indicator={<LoadingOutlined />}
                //       size="large"
                //       className="w-16"
                //     />
                //   );
                // }
                return (
                  <EnrichCustomButton
                    key={idx}
                    id={idx}
                    text={btnData.text}
                    icon={btnData.icon}
                    onClick={() => handleClick(idx)}
                    testClicked={testClicked}
                    loading={tempLoading}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrichMainHeader;
