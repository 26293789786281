import React, { useState } from 'react';
import { Tabs, Select, InputNumber } from 'antd';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { Option } = Select;

const WebhookTrigger = () => {
  // State for dropdown and input fields
  const [operation, setOperation] = useState('Await workflow and respond');
  const [allowWebhooks, setAllowWebhooks] = useState('True');
  const [csrfToken, setCsrfToken] = useState('');
  const [timeout, setTimeout] = useState(123);

  // Handlers for updating the state
  const handleOperationChange = (value) => {
    setOperation(value);
  };

  const handleAllowWebhooksChange = (value) => {
    setAllowWebhooks(value);
  };

  const handleTimeoutChange = (value) => {
    setTimeout(value);
  };

  return (
    <div className="p-6 max-w-xl mx-auto bg-white rounded-lg shadow-md space-y-4 border border-gray-200">
      {/* Webhook Header */}
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold text-gray-700">Webhook <span className="text-sm">(trigger)</span></h2>
        <InfoCircleOutlined className="text-gray-500" />
      </div>

      {/* Tabs */}
      <Tabs defaultActiveKey="3" type="card">
        <TabPane tab="Description" key="1" disabled></TabPane>
        <TabPane tab="Auth" key="2" disabled></TabPane>
        <TabPane tab="Step inputs" key="3">
          {/* Operation Dropdown */}
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 font-medium mb-1">Operation</label>
              <Select
                value={operation}
                onChange={handleOperationChange}
                className="w-full"
              >
                <Option value="Await workflow and respond">Await workflow and respond</Option>
                <Option value="Start workflow immediately">Start workflow immediately</Option>
              </Select>
            </div>

            {/* Allow Webhooks from Browsers */}
            <div>
              <label className="block text-gray-700 font-medium mb-1 flex items-center">
                Allow webhooks from browsers?
                <QuestionCircleOutlined className="ml-1 text-gray-500" />
              </label>
              <Select
                value={allowWebhooks}
                onChange={handleAllowWebhooksChange}
                className="w-full"
              >
                <Option value="True">True</Option>
                <Option value="False">False</Option>
              </Select>
            </div>

            {/* CSRF Token */}
            <div>
              <label className="block text-gray-700 font-medium mb-1 flex items-center">
                CSRF Token
                <QuestionCircleOutlined className="ml-1 text-gray-500" />
              </label>
              <input
                type="text"
                value={csrfToken}
                onChange={(e) => setCsrfToken(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="No value"
              />
            </div>

            {/* Timeout */}
            <div>
              <label className="block text-gray-700 font-medium mb-1 flex items-center">
                Timeout (ms)
                <QuestionCircleOutlined className="ml-1 text-gray-500" />
              </label>
              <InputNumber
                value={timeout}
                onChange={handleTimeoutChange}
                className="w-full"
              />
            </div>
          </div>
        </TabPane>
        <TabPane tab="Output" key="4" disabled></TabPane>
      </Tabs>
    </div>
  );
};

export default WebhookTrigger;