import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  BarChart, Bar, LineChart, Line, PieChart, Pie, FunnelChart, Funnel, ScatterChart, Scatter,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList
} from 'recharts';
import { Card, Select, Checkbox, Button, Radio, Input } from 'antd';
import {
  BarChartOutlined,
  ColumnWidthOutlined,
  LineChartOutlined,
  PieChartOutlined,
  FunnelPlotOutlined,
  DotChartOutlined,
} from '@ant-design/icons';
import { updateChartType, fetchTableData } from '../services/InsightsServices';
import { copilotChatArrayAtom, activeSessionAtom } from '../store';
const { Option } = Select;

const GraphOptions = ({ queryID, postgres_query, graphOptionclose }) => {
  const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);

  const [selectedChartType, setSelectedChartType] = useState('Bar');
  const [chartTitle, setChartTitle] = useState('');
  const [xAxis, setXAxis] = useState('');
  const [yAxis, setYAxis] = useState('');
  const [showValues, setShowValues] = useState(true);
  const [showReferenceLine, setShowReferenceLine] = useState(false);
  const [chartColor, setChartColor] = useState('#2966BC');
  const [chartData, setChartData] = useState(null);
  const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom)

  const chartTypes = [
    { label: 'Bar', icon: <BarChartOutlined /> },
    { label: 'Column', icon: <ColumnWidthOutlined /> },
    { label: 'Line', icon: <LineChartOutlined /> },
    { label: 'Donut', icon: <PieChartOutlined /> },
    { label: 'Funnel', icon: <FunnelPlotOutlined /> },
    { label: 'Scatter Plot', icon: <DotChartOutlined /> },
  ];

  const updateChart = async () => {
    const payload = {
      session_id: activeSession,
      chartName: chartTitle,
      x_axis: xAxis,
      y_axis: yAxis,
      value: showValues,
      grids: showReferenceLine,
      color: chartColor,
      type: selectedChartType,
      query_id: queryID,
      data: JSON.stringify(chartData),
    };
    let response = await updateChartType(payload);

    setChatArray(prevChatArray =>
      prevChatArray.map(chat =>
        chat.query_id === queryID
          ? {
            ...chat,
            chartType: selectedChartType,
            x_Axis: xAxis,
            y_Axis: yAxis,
            chartTitle: chartTitle,
            showValues: showValues,
            showGrid: showReferenceLine,
            color: chartColor,
            data: chartData,
          }
          : chat
      )
    );
    graphOptionclose()
  };

  const renderChart = () => {
    if (!chartData) {
      return <div>No data available</div>;
    }
    switch (selectedChartType) {
      case 'Bar':
        return (
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill={chartColor}>
              {showValues && <LabelList dataKey={yAxis} position="top" />}
            </Bar>
          </BarChart>
        );
      case 'Column':
        return (
          <BarChart data={chartData} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis type="number" />
            <YAxis dataKey={xAxis} type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill={chartColor}>
              {showValues && <LabelList dataKey={yAxis} position="right" />}
            </Bar>
          </BarChart>
        );
      case 'Stacked Bar':
        return (
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="top" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="top" />}
            </Bar>
          </BarChart>
        );
      case 'Stacked Column':
        return (
          <BarChart data={chartData} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis type="number" />
            <YAxis dataKey={xAxis} type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="right" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="right" />}
            </Bar>
          </BarChart>
        );
      case 'Line':
        return (
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={yAxis} stroke={chartColor} />
          </LineChart>
        );
      case 'Donut':
        return (
          <PieChart>
            <Pie
              data={chartData}
              dataKey={yAxis}
              nameKey={xAxis}
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={60}
              fill={chartColor}
              label={showValues}
            />
          </PieChart>
        );
      case 'Funnel':
        return (
          <FunnelChart>
            <Tooltip />
            <Funnel
              dataKey={yAxis}
              data={chartData}
              isAnimationActive
              fill={chartColor}
            >
              {chartData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={chartColor} />
              ))}
            </Funnel>
          </FunnelChart>
        );
      case 'Scatter Plot':
        return (
          <ScatterChart>
            <CartesianGrid vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis dataKey={yAxis} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="Scatter Plot" data={chartData} fill={chartColor} />
          </ScatterChart>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let query = `select * from insighttables.new_table_${queryID.replace(/-/g, '_')};`
        if (query.endsWith(';')) {
          query = query.slice(0, -1);
        }
        query += ' LIMIT 10;';
        const result = await fetchTableData({ sqlQuery: query });
        setChartData(result.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const columns = chartData ? Object.keys(chartData[0]) : [];

  return (
    <Card>
      <div className="p-4 bg-white rounded-lg shadow-md max-w-sm">
        <h3 className="text-lg font-semibold mb-4">Display As</h3>
        <Radio.Group
          value={selectedChartType}
          onChange={(e) => setSelectedChartType(e.target.value)}
          className="grid grid-cols-3 gap-2 mb-4"
        >
          {chartTypes?.map((type) => (
            <Radio.Button
              key={type.label}
              value={type.label}
              className="flex flex-col h-full items-center p-2 border rounded-md text-xs"
            >
              <div className="mb-1 flex justify-center text-lg">{type.icon}</div>
              <span>{type.label}</span>
            </Radio.Button>
          ))}
        </Radio.Group>

        <div className="chart-attributes">
          <h4 className="text-md font-medium mb-2">Chart Attributes</h4>
          <label className="block mb-2">
            <span className="block text-sm font-medium mb-1">Chart Title</span>
            <Input
              value={chartTitle}
              onChange={(e) => setChartTitle(e.target.value)}
              placeholder="Enter chart title"
            />
          </label>
          <label className="block mb-2">
            <span className="block text-sm font-medium mb-1">X-Axis</span>
            <Select
              value={xAxis}
              onChange={setXAxis}
              placeholder="Select X-Axis"
              style={{ width: '100%' }}
            >
              {columns?.map((col) => (
                <Option key={col} value={col}>
                  {col}
                </Option>
              ))}
            </Select>
          </label>
          <label className="block mb-2">
            <span className="block text-sm font-medium mb-1">Y-Axis</span>
            <Select
              value={yAxis}
              onChange={setYAxis}
              placeholder="Select Y-Axis"
              style={{ width: '100%' }}
            >
              {columns?.map((col) => (
                <Option key={col} value={col}>
                  {col}
                </Option>
              ))}
            </Select>
          </label>
          <label className="block mb-2">
            <span className="block text-sm font-medium mb-1">Chart Color</span>
            <Input
              type="color"
              className='w-[25%] p-1'
              value={chartColor}
              onChange={(e) => setChartColor(e.target.value)}
            />
          </label>
          <div className="flex items-center mb-2">
            <Checkbox
              checked={showReferenceLine}
              onChange={(e) => setShowReferenceLine(e.target.checked)}
              className="mr-2"
            >
              Show Grid Lines
            </Checkbox>
          </div>
          <div className="flex items-center mb-2">
            <Checkbox
              checked={showValues}
              onChange={(e) => setShowValues(e.target.checked)}
              className="mr-2"
            >
              Show Values
            </Checkbox>
          </div>
        </div>

        <span className="block text-sm font-medium mb-4">Preview Window</span>
        <div className="chart-display mb-6" style={{ height: '200px', width: '100%' }}>
          <h2>{chartTitle}</h2>
          <ResponsiveContainer>
            {renderChart()}
          </ResponsiveContainer>
        </div>
        <Button type="primary" onClick={() => updateChart()} className="mt-4">
          Update Chart
        </Button>
      </div>
    </Card>
  );
};

export default GraphOptions;
