import axios from "axios";

const injectToken = (
  config
) => {
  // const token = localStorage.getItem('loginToken');
  let token = '';
  try {
    token = JSON.parse(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''));
  } catch (err) {
    console.error(err);
  }
  if (token != null && config?.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const handleError = (error) => {
  const { status } = error;
  console.log(error, status);
  if ([401, 403].includes(status)) {
    window.location.href = '/login'
  }
  return Promise.reject(error);
};

const Client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || "http://app.gtmcopilot.com/api_v3/",
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept": "application/json",
    // "Authorization": "Bearer ${token}"
    "Authorization": "Bearer token"
  },
});

Client.interceptors.request.use(injectToken, error => Promise.reject(error));

/**
 * @todo 
 * Update the response interceptor to redirect on token invalidation 
 * right now for permission denied as well it gives 401 so we can't redirect to login always
 */

// Client.interceptors.response.use(
//   response => response,
//   error => {
//     const { response } = error;
//     return handleError(response);
//   }
// );

export { Client };