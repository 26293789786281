import { Button, Col, Form, Input, Progress, Row } from "antd";
import { useState } from "react";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { faker } from "@faker-js/faker";
import ActionCard from "../../ActionCard";

const conicColors = {
  "0%": "#87d068",
  "50%": "#ffe58f",
  "100%": "#ffccc7",
};

const data = [
  {
    name: "Jan",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Feb",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Mar",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Apr",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "May",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Jun",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Jul",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Aug",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Sep",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Oct",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Nov",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
  {
    name: "Dec",
    credits: faker.number.int({ min: 1000, max: 10000 }),
  },
];

export function Billing() {
  const [topupSettings, setTopupSettings] = useState({
    creditThreshold: 1000,
    creditAmount: 500,
  });

  const [form] = Form.useForm();

  const handleCreateRole = () => {
    const updatedValue = {
      creditThreshold: form.getFieldValue("threshold"),
      creditAmount: form.getFieldValue("amount"),
    };
    setTopupSettings(updatedValue);
    form.resetFields();
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <ActionCard title="Credit Usage History">
            <div className="py-8">
              <BarChart width={800} height={300} data={data}>
                <XAxis dataKey="name" />
                <YAxis dataKey="credits" />
                <Tooltip />
                <Legend />
                <Bar dataKey="credits" fill="#8884d8" />
              </BarChart>
            </div>
          </ActionCard>
        </Col>
      </Row>
      <Row className="mt-8">
        <Col span={9} className="text-center">
          <ActionCard title="Billing Details">
            <div className="flex justify-center gap-2 items-center mb-8">
              <div className="text-[16px] font-bold">Business plan</div>
              <div className="text-gray-500 text-[14px] font-semibold">
                $10/month
              </div>
            </div>
            <Progress type="dashboard" percent={93} strokeColor={conicColors} />
            <div className="text-[14px] font-bold">
              Credit Remaining: 7500 / 10000
            </div>
          </ActionCard>
        </Col>
        <Col span={14} offset={1} className="text-center">
          <ActionCard title="Auto top up settings">
            <Form
              form={form}
              name="control-hooks"
              style={{ width: "100%" }}
              layout="vertical"
              initialValues={{
                threshold: topupSettings.creditThreshold,
                amount: topupSettings.creditAmount,
              }}
            >
              <Form.Item
                name="threshold"
                label="Top-up when credit falls below"
              >
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Form.Item name="amount" label="Top-up Amount">
                <Input placeholder="input placeholder" />
              </Form.Item>
              <Button
                className="flex self-start"
                type="primary"
                onClick={handleCreateRole}
              >
                Save
              </Button>
            </Form>
          </ActionCard>
        </Col>
      </Row>
    </>
  );
}
