import React, { useCallback, useState, useEffect } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
  ReactFlowProvider
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import EnrichmentIONode from "../../components/EnrichmentGumloopComponents/EnrichmentIONode.js";
import EnrichmentTextNode from "../../components/EnrichmentGumloopComponents/EnrichmentTextNode.js";
import EnrichmentCompanyNode from "../../components/EnrichmentGumloopComponents/EnrichmentCompanyNode.js";
import EnrichmentSummarizerNode from "../../components/EnrichmentGumloopComponents/EnrichmentSummarizerNode.js";

import EnrichMainHeader from "../../components/EnrichmentGumloopComponents/EnrichMainHeader.js";
import SideOpt from "../../components/GumloopComponents/SideOpt.js";

import inputImg from "../../assets/images/inputImg.png";
import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";
import personImg from "../../assets/images/personImg.png";
import calcImg from "../../assets/images/calcImg.png";
import parseImg from "../../assets/images/parseImg.png";
import robotImg from "../../assets/images/robotImg.png";

import EnrichmentCustomEdge from '../../components/EnrichmentGumloopComponents/EnrichmentCustomEdge.js';

import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms.js";
import { enrichFocusNodeIdAtom, enrichNewNodeAtom, enrichContactAtom, enrichSlackAtom, enrichFitEntireAtom, enrichCollapsibleAtom, enrichTestLoadingAtom, enrichPolledContentAtom, enrichTestEmailAtom, enrichNodeInProgressAtom } from "../../store/index.js";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";

import EnrichmentRightSider from "../../components/EnrichmentGumloopComponents/EnrichmentRightSider.js";
import { DoubleRightOutlined, DoubleLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Modal, Table, Button, Spin } from "antd";
import OutputComponent from "../../components/EnrichmentGumloopComponents/OutputComponent.js";
import JsonToCsv from "../../components/EnrichmentGumloopComponents/JsonToCsv.js";

export const initialNodes = [
  {
    id: "node-1",
    type: "ioNode",
    position: { x: 0, y: 0 },
    data: {
      label: "User Email",
      icon: inputImg,
      firstKey: "Default Value:",
      firstVal: "max@hubspot.com",
      bgColor: "grey",
    },
  },
  {
    id: "node-3",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 179.7, y: 1820.52 },
    data: {
      label: "Agent Made Decision",
      icon: robotImg,
      firstKey: "Output Name:",
      firstVal: "research_results",
      bgColor: "grey",
    },
  },
  {
    id: "node-4",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 5.68, y: 252.55 },
    data: {
      label: "Parse Email",
      icon: parseImg,
      bgColor: "peach",
    },
  },
  {
    id: "node-5",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: -587.35, y: 525.12 },
    data: {
      label: "Crawl Website",
      icon: webScraperImg,
      bgColor: "peach",
    },
  },
  {
    id: "node-13",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: 605.79, y: 500.64 },
    data: {
      label: "Person Verification",
      icon: personImg,
      bgColor: "blue",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },
  {
    id: "node-14",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: -856.62, y: 764.38 },
    data: {
      label: "Summarizer",
      icon: summaryImg,
      bgColor: "green",
      firstKey: true
    },
  },
  {
    id: "node-15",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: -376.31, y: 752.16 },
    data: {
      label: "Company Verification",
      icon: companyImg,
      bgColor: "blue",
      firstKey: "Outputs",
      firstVal: "hubspot.com"
    },
  },
  {
    id: "node-16",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: 19.19, y: 501.41 },
    data: {
      label: "Company Verification",
      icon: companyImg,
      bgColor: "blue",
      firstKey: "Inputs",
      firstVal: "domain name"
    },
  },
  {
    id: "node-17",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: -227.54, y: 1004.74 },
    data: {
      label: "Fit Score Calculation",
      icon: calcImg,
      bgColor: "pink",
      firstKey: "Inputs",
      firstVal: "domain name"
    },
  },
  {
    id: "node-18",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: 608.14, y: 943.49 },
    data: {
      label: "Lead Score Calculation",
      icon: calcImg,
      bgColor: "pink",
      firstKey: "Inputs",
      firstVal: "domain name"
    },
  }
];

const initialEdges = [
  {
    id: "edge-1",
    source: "node-1",
    target: "node-4",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'email', suppText: 'type = text' },
      endLabel: { mainText: 'email', suppText: 'type = text' }
    },
    type: 'start-end',
  },
  {
    id: "edge-2",
    source: "node-4",
    target: "node-5",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      endLabel: { mainText: 'company', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-3",
    source: "node-4",
    target: "node-16",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      endLabel: { mainText: 'company', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-4",
    source: "node-4",
    target: "node-13",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      endLabel: { mainText: 'person', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-5",
    source: "node-5",
    target: "node-14",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'scraped data', suppText: 'type = text' },
      // endLabel: { mainText: 'url', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-6",
    source: "node-5",
    target: "node-15",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'scraped data', suppText: 'type = text' },
      // endLabel: { mainText: 'url', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-7",
    source: "node-15",
    target: "node-17",
    sourceHandle: "d",
    animated: true,
    data: {
      startLabel: { mainText: 'verified content', suppText: 'type = text' },
      // endLabel: { mainText: 'domain name', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-8",
    source: "node-16",
    target: "node-17",
    sourceHandle: "d",
    animated: true,
    data: {
      startLabel: { mainText: 'verified content', suppText: 'type = text' },
      // endLabel: { mainText: 'text', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-9",
    source: "node-13",
    target: "node-18",
    sourceHandle: "d",
    animated: true,
    data: {
      startLabel: { mainText: 'verified content', suppText: 'type = text' },
      // endLabel: { mainText: 'output', suppText: 'type = string' },
    },
    type: 'start-end',
  },
  {
    id: "edge-10",
    source: "node-17",
    target: "node-3",
    targetHandle: "a",
    animated: true,
    data: {
      startLabel: { mainText: 'fit score', suppText: 'type = number' },
      // endLabel: { mainText: 'input1', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-11",
    source: "node-18",
    target: "node-3",
    targetHandle: "a",
    animated: true,
    data: {
      startLabel: { mainText: 'lead score', suppText: 'type = number' },
      // endLabel: { mainText: 'input9', suppText: 'type = text' },
    },
    type: 'start-end',
  }
];

const nodeTypes = {
  ioNode: (data) => <EnrichmentIONode data={data} />,
  textNode: (data) => <EnrichmentTextNode data={data} />,
  companyNode: (data) => <EnrichmentCompanyNode data={data} />,
  summarizerNode: (data) => <EnrichmentSummarizerNode data={data} />,
};

const edgeTypes = {
  'start-end': EnrichmentCustomEdge,
};

const Flow = (props) => {
  const { fitView, getNode } = useReactFlow();
  const [focusNodeId, setFocusNodeId] = useRecoilState(enrichFocusNodeIdAtom);

  useEffect(() => {
    if (focusNodeId) {
      if (focusNodeId === "whole") {
        fitView({ duration: 800 });
      } else {
        const node = getNode(focusNodeId);
        if (node) {
          fitView({ nodes: [node], duration: 800 });
        }
      }
      setFocusNodeId(null);
    }
  }, [focusNodeId, fitView, getNode]);

  return (
    <ReactFlow {...props} >
      <Controls position="bottom-right" />
      <Background variant="dots" gap={20} size={1} />
    </ReactFlow>
  );
}


const EnrichmentGumloop = () => {
  const setChatMode = useSetRecoilState(chatModeAtom);
  const setSideNavContent = useSetRecoilState(sideNavContentAtom);
  const [newNode, setNewNode] = useRecoilState(enrichNewNodeAtom);
  const [contact, setContact] = useRecoilState(enrichContactAtom);
  const [slack, setSlack] = useRecoilState(enrichSlackAtom);
  const [fitEntire, setFitEntire] = useRecoilState(enrichFitEntireAtom);
  const setFocusNodeId = useSetRecoilState(enrichFocusNodeIdAtom);
  const [isCollapsed, setIsCollapsed] = useRecoilState(enrichCollapsibleAtom)
  const [testLoading, setTestLoading] = useRecoilState(enrichTestLoadingAtom);
  const setPolledContent = useSetRecoilState(enrichPolledContentAtom);
  const setTestEmail = useSetRecoilState(enrichTestEmailAtom);
  const [enrichNodeInProgress, setEnrichNodeInProgress] = useRecoilState(enrichNodeInProgressAtom);

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const [modalOpen, setModalOpen] = useState(false);
  const [finalContent, setFinalContent] = useState(null);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    console.log(nodes)
  }, [nodes])
  
  useEffect(() => {
    // localStorage.setItem("path", "gumloop");
    // setChatMode(1);
    // setSideNavContent(1);
    setPolledContent(null)
    setTestEmail(null)
    setTestLoading(false)
  }, [])

  const replaceNode = (oldNodeId, newNode) => {
    setNodes((nds) => nds.map((node) => (node.id === oldNodeId ? newNode : node)));
  };

  useEffect(() => {
    if (newNode) {
      const hubspotNode = {
        id: "node-15",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -72, y: -33 },
        data: {
          label: "Hubspot Contact Reader",
          icon: hubspotImg,
          bgColor: "blue",
          firstKey: "Outputs",
          firstVal: "hubspot.com"
        },
      }
      replaceNode('node-1', hubspotNode)
      setNewNode(false);
    }
    if (contact) {
      const contactNode = {
        id: "node-16",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -528.8, y: 842 },
        data: {
          label: "Enrich Contact Information",
          icon: companyImg,
          bgColor: "blue",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      replaceNode('node-9', contactNode)
      setContact(false);
      setTimeout(() => {
        setFocusNodeId("node-16")
      }, 500)
    }
    if (slack) {
      const slackNode = {
        id: "node-17",
        // type: 'output',
        type: "summarizerNode",
        targetPosition: "top",
        position: { x: 55, y: 2098 },
        data: {
          label: "Slack Message Sender",
          icon: slackImg,
          bgColor: "pink",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      setNodes(prevNodes => [...prevNodes, slackNode])
      setSlack(false);
      setTimeout(() => {
        setFocusNodeId("node-17")
      }, 500)
    }
    if (fitEntire) {
      setFitEntire(false);
      setTimeout(() => {
        setFocusNodeId("whole")
      }, 500)
    }
  }, [newNode, contact, slack, fitEntire])

    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };
  
  useEffect(() => {
    console.log(enrichNodeInProgress, "enrichNodeInProgress----------->")
    setFocusNodeId(enrichNodeInProgress)
  }, [enrichNodeInProgress])


  return (
    <>
      <Modal
        title={<div className="text-center w-[98%]">Generated Output</div>}
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <JsonToCsv jsonData={JSON.parse(finalContent)} />
        ]}
        className="!w-[50vw]"
      >
        {finalContent ? (
          <OutputComponent finalContent={JSON.parse(finalContent)} />
        ) : (
          <Spin
            key="spinner"
            indicator={<LoadingOutlined />}
            size="large"
            className="w-16"
          />
        )}
      </Modal>
      <EnrichMainHeader setFinalContent={setFinalContent} setModalOpen={setModalOpen} />
      <div style={{ width: "100%", height: "80vh" }} className="p-2 relative">
        <SideOpt />
        <ReactFlowProvider>
          <Flow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            proOptions={{ hideAttribution: true }}
            fitView
            panOnScroll
            selectionOnDrag
            minZoom={0.2}
            maxZoom={2}
          />
        </ReactFlowProvider>
        {testLoading && (
          <>
            <button onClick={toggleCollapse} className={`absolute ${isCollapsed ? 'right-16' : 'right-[20rem]'} top-12 z-50 duration-300 ease-in-out`}>
              {isCollapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
            </button>
            <EnrichmentRightSider />
          </>
        )}
      </div>
    </>
  );
};

export default EnrichmentGumloop;
