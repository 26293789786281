import { atom } from 'recoil';

export const FORMULA_RECOIL_ATOMS = {
	FORMULA_PROMPT: 'FORMULA PROMPT',
	FORMULA_FORMULA: 'FORMULA FORMULA',
	FORMULA_SHOW_RESULTS: 'FORMULA SHOW RESULTS',
	FORMULA_FORMULA_FORM_DIRTY: 'FORMULA FORMULA FORM DIRTY',
    FORMULA_FINAL_RESULTS: 'FORMULA RESULTS'
};

export const formulaPromptAtom = atom({
	key: FORMULA_RECOIL_ATOMS.FORMULA_PROMPT,
	default: '',
});

export const formulaFormulaAtom = atom({
	key: FORMULA_RECOIL_ATOMS.FORMULA_FORMULA,
	default: '',
});

export const formulaShowResultsAtom = atom({
	key: FORMULA_RECOIL_ATOMS.FORMULA_SHOW_RESULTS,
	default: false,
});

export const formulaFormulaFormDirty = atom({
	key: FORMULA_RECOIL_ATOMS.FORMULA_FORMULA_FORM_DIRTY,
	default: false,
});

export const formulaFinalResults = atom({
    key: FORMULA_RECOIL_ATOMS.FORMULA_FINAL_RESULTS,
    default: []
})
