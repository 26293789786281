import React, { useState, useEffect } from "react";
import { Input } from "antd";
import NavBar from "../NavBar/NavBar";
import AgentCardWrapper from "../AgentCardWrapper/AgentCardWrapper";
import AgentFooter from "../AgentFooter/AgentFooter";

import inputImg from "../../assets/images/inputImg.png";
import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";
import personImg from "../../assets/images/personImg.png";
import calcImg from "../../assets/images/calcImg.png";
import parseImg from "../../assets/images/parseImg.png";
import robotImg from "../../assets/images/robotImg.png";
import { useRecoilValue } from "recoil";
import { selectedGalleryAtom, selectedGalleryCategoryAtom } from "../../store";
import { initialNodes } from "../../pages/Gumloop/EnrichmentGumloop";

const { Search } = Input;

export const navBarValues = [
  "Revenue",
  "Marketing",
  "CS/CX",
  "Advertising",
  "BI/Reporting",
  "Data Ops",
  "Compliance",
  "Alerts",
  "Research",
  "Aquisition",
  "Retention",
  "Expansion"
];

export const initialValues = [
  {
    name: "Lead Background Research",
    des: "Do background research on a lead to determine its fitment",
    route: 'sales_crm',
    category:0,
    process: "AI agents do research on the lead and its account, to determine if this is a good fit based on ICP and persona by generating a score and a concise summary.",
    output: "Lead score and fitment summary",
    tools: ["Salesforce", "Research Agent"],
    href: "/pipeline?template=true&flow_id=u1So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [
      {
        id: "node-1",
        type: "ioNode",
        position: { x: -250, y: 195 },
        data: {
          label: "User Email",
          icon: inputImg,
          firstKey: "Default Value:",
          firstVal: "max@hubspot.com",
          bgColor: "grey",
        },
      },
      {
        id: "node-3",
        // type: 'output',
        type: "ioNode",
        targetPosition: "top",
        position: { x: -108.7, y: 579.6 },
        data: {
          label: "Output",
          icon: outputImg,
          firstKey: "Output Name:",
          firstVal: "research_results",
          bgColor: "grey",
        },
      },
    ],
    initialEdges: [
      {
        id: "edge-1",
        source: "node-1",
        target: "node-3",
        sourceHandle: "b",
        animated: true,
        data: {
          startLabel: { mainText: "email", suppText: "type = text" },
          endLabel: {
            mainText: "enriched information",
            suppText: "type = text",
          },
        },
        type: "start-end",
      },
    ],
  },
  {
    name: "Intelligent Lead Enrichment and Routing",
    des: "Enriches lead data, scores leads, and routes them to appropriate sales reps.",
    route: 'lead',
    category: 0,
    process: "Lead arrives in Hubspot form. AI agents enrich lead information from HubSpot, calculate lead scores, and automatically assign leads to the most suitable sales representatives in the CRM.",
    output: "Enriched and routed leads",
    tools: ["HubSpot", "Salesforce"],
    href: "/lead?template=true&flow_id=u1So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [
      {
        id: "node-1",
        type: "summarizerNode",
        position: { x: 7.148331091731507, y: 386.68003499334736 },
        data: {
          label: "Webhook Listener",
          icon: hubspotImg,
          firstKey: "Default Value:",
          firstVal: "max@hubspot.com",
          bgColor: "grey",
        },
      },

      {
        id: "node-23",
        // type: 'output',
        type: "summarizerNode",
        targetPosition: "top",
        position: { x: 489.64, y: 991.63 },
        data: {
          label: "iCustomer Person Push API",
          icon: companyImg,
          bgColor: "peach",
          firstKey: "Domain Name",
          firstVal: "hubspot.com",
        },
      },
    ],
    initialEdges: [
      {
        id: "edge-1",
        source: "node-1",
        target: "node-23",
        sourceHandle: "b",
        animated: true,
        data: {
          startLabel: {
            mainText: "webhook data",
            suppText: "type = json",
          },
          endLabel: {
            mainText: "form data",
            suppText: "type = json",
          },
        },
        type: "start-end",
      },
    ],
  },
  {
    name: "Lead Enrichment Waterfall",
    des: "Turn an email address into a detailed fitment analysis",
    route: 'enrichment',
    category: 0,
    process: "You enter anyone work email and the flow dissect and research about the person and its company, run lead and ICP score and share a final analysis and recommendation",
    output: "Lead score and recommendation",
    href: "enrichment",
    initialNodes: [
      {
        id: "node-1",
        type: "ioNode",
        position: { x: -90, y: 150 },
        data: {
          label: "User Email",
          icon: inputImg,
          firstKey: "Default Value:",
          firstVal: "max@hubspot.com",
          bgColor: "grey",
        },
      },
      {
        id: "node-3",
        // type: 'output',
        type: "ioNode",
        targetPosition: "top",
        position: { x: 187.63, y: 501.46 },
        data: {
          label: "Agent Made Decision",
          icon: robotImg,
          firstKey: "Output Name:",
          firstVal: "research_results",
          bgColor: "grey",
        },
      },
    ],
    initialEdges: [
      {
        id: "edge-1",
        source: "node-1",
        target: "node-3",
        sourceHandle: "b",
        targetHandle: "a",
        animated: true,
        data: {
          startLabel: { mainText: "email", suppText: "type = text" },
          endLabel: {
            mainText: "fit & lead scores",
            suppText: "type = number",
          },
        },
        type: "start-end",
      },
    ],
  },
  {
    name: "Programmatic Leads",
    des: "Automatically qualifies leads using data-driven algorithms.",
    category: 0,
    route: 'prog',
    process: "AI agents collect lead data from various sources, apply machine learning algorithms to analyze demographics, firmographics, and behavioral data, then score and qualify leads based on predefined criteria. The system automatically routes high-quality leads to sales teams and provides detailed insights for personalized outreach.",
    href: "/prog?template=true&flow_id=u1So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [
      {
        id: "node-1",
        type: "progIoNode",
        position: { x: 121.73, y: -313.35 },
        data: {
          label: "Input",
          icon: inputImg,
          firstKey: "Account Name:",
          firstVal: "cisco",
          bgColor: "grey",
        },
      },
      {
        id: "node-3",
        // type: 'output',
        type: "progIoNode",
        targetPosition: "top",
        position: { x: 579.23, y: 89.14 },
        data: {
          label: "Output",
          icon: robotImg,
          // firstKey: "Output Name:",
          firstVal: "research_results",
          bgColor: "grey",
        },
      },
    ],
    initialEdges: [
      {
        id: "edge-1",
        source: "node-1",
        target: "node-3",
        sourceHandle: "b",
        targetHandle: "a",
        animated: true,
        data: {
          startLabel: { mainText: "form data", suppText: "type = json" },
          endLabel: { mainText: "lead information", suppText: "type = text" },
        },
        type: "canvas-start-end",
      },
    ],
  },
  {
    name: "AI SDR (Sales Development Representative)",
    des: "Automates initial prospect outreach and qualification.",
    category:0,
    process: 'AI agents identify target lists based on ICP and persona, analyze lead data, craft personalized messages, and engage prospects through email and LinkedIn, scheduling meetings for human SDRs.',
    output: 'Appointment settings in the calendar with summary',
    tools: ['Salesforce', "LinkedIn Sales Navigator", 'Gmail', "Calendly"],
    href: "/pipeline?template=true&flow_id=u1So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [
      {
        id: "node-1",
        type: "ioNode",
        position: { x: -250, y: 195 },
        data: {
          label: "User Email",
          icon: inputImg,
          firstKey: "Default Value:",
          firstVal: "max@hubspot.com",
          bgColor: "grey",
        },
      },
      {
        id: "node-3",
        // type: 'output',
        type: "ioNode",
        targetPosition: "top",
        position: { x: -108.7, y: 579.6 },
        data: {
          label: "Output",
          icon: outputImg,
          firstKey: "Output Name:",
          firstVal: "research_results",
          bgColor: "grey",
        },
      },
    ],
    initialEdges: [
      {
        id: "edge-1",
        source: "node-1",
        target: "node-3",
        sourceHandle: "b",
        animated: true,
        data: {
          startLabel: { mainText: "email", suppText: "type = text" },
          endLabel: {
            mainText: "enriched information",
            suppText: "type = text",
          },
        },
        type: "start-end",
      },
    ],
  },
  {
    name: "AI Marketer - Multi-Channel Campaign Orchestrator",
    des: "Coordinates and optimizes marketing campaigns across various channels.",
    category: 1,
    process: "AI agents plan, execute, and adjust campaigns based on real-time performance data across email, social media, and advertising platforms.",
    output: "Optimized, cross-channel marketing campaigns with real-time adjustments",
    tools: ["HubSpot", "Hootsuite"],
    href: "/pipeline?template=true&flow_id=u5So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Predictive Lead Scoring",
    des: "Identifies and prioritizes high-quality leads for marketing nurture campaigns.",
    category: 1,
    process: "AI agents analyze historical conversion data, lead behaviors, and firmographics to assign scores and recommend next best actions.",
    output: "Scored and segmented leads with recommended actions",
    tools: ["Marketo", "Salesforce"],
    href: "/pipeline?template=true&flow_id=u6So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Proactive Customer Health Monitor",
    des: "Predicts potential churn and suggests preemptive actions.",
    category: 2,
    process: "AI agents analyze product usage data, support tickets, and customer feedback to identify at-risk accounts and recommend interventions.",
    output: "At-risk account list with recommended interventions",
    tools: ["Gainsight", "Zendesk"],
    href: "/pipeline?template=true&flow_id=u7So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Intelligent Ticket Routing",
    des: "Automatically assigns and prioritizes customer support tickets.",
    category: 2,
    process: "AI agents analyze ticket content, customer history, and agent expertise to route issues to the most appropriate team or individual.",
    output: "Optimally routed and prioritized support tickets",
    tools: ["Zendesk", "Salesforce Service Cloud"],
    href: "/pipeline?template=true&flow_id=u8So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Voice of Customer Analyzer",
    des: "Synthesizes customer feedback from multiple sources to identify trends and sentiment.",
    category: 2,
    process: "AI agents collect and analyze data from surveys, social media, support tickets, and call transcripts to generate actionable insights.",
    output: "Actionable customer insights and sentiment analysis reports",
    tools: ["Qualtrics", "Clarabridge"],
    href: "/pipeline?template=true&flow_id=u9So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Cross-Platform Budget Optimizer",
    des: "Dynamically allocates advertising budget across multiple platforms for maximum ROI.",
    category: 3,
    process: "AI agents monitor performance metrics, analyze historical data, and adjust budget allocation in real-time across various ad platforms.",
    output: "Optimized budget allocation across ad platforms",
    tools: ["Google Ads", "Facebook Ads Manager"],
    href: "/pipeline?template=true&flow_id=u10So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Creative A/B Testing Automator",
    des: "Continuously tests and optimizes ad creatives across campaigns.",
    category: 3,
    process: "AI agents generate variations of ad copy and visuals, run tests, analyze performance, and automatically implement winning combinations.",
    output: "High-performing ad creatives for each campaign",
    tools: ["Persado", "Phrasee"],
    href: "/pipeline?template=true&flow_id=u11So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Audience Lookalike Expander",
    des: "Identifies and targets new audiences similar to high-performing segments.",
    category: 3,
    process: "AI agents analyze characteristics of converting customers, find similar profiles in advertising platforms, and create expanded targeting segments.",
    output: "Expanded, high-potential audience segments for targeting",
    tools: ["Facebook Audience Insights", "Google Ads Customer Match"],
    href: "/pipeline?template=true&flow_id=u12So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Automated Insight Generator",
    des: "Proactively identifies significant trends and anomalies in business data.",
    category: 4,
    process: "AI agents continuously analyze data across various sources, detect patterns or deviations, and generate natural language summaries of key findings.",
    output: "Automated reports highlighting key business insights and anomalies",
    tools: ["Power BI", "Tableau"],
    href: "/pipeline?template=true&flow_id=u13So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Predictive Sales Forecasting",
    des: "Generates accurate sales projections based on historical and current data.",
    category: 4,
    process: "AI agents analyze sales pipeline, historical performance, and external factors to create detailed forecasts and confidence intervals.",
    output: "Detailed sales forecasts with confidence intervals",
    tools: ["Salesforce Einstein", "InsightSquared"],
    href: "/pipeline?template=true&flow_id=u14So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Cross-Functional KPI Dashboard",
    des: "Creates and maintains a real-time, company-wide performance dashboard.",
    category: 4,
    process: "AI agents collect data from various departments, calculate KPIs, and dynamically update a centralized dashboard with the most relevant metrics.",
    output: "Real-time, interactive KPI dashboard for all departments",
    tools: ["Domo", "Sisense"],
    href: "/pipeline?template=true&flow_id=u15So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Data Quality Monitor",
    des: "Continuously checks and maintains data quality across systems.",
    category: 5,
    process: "AI agents perform regular data audits, identify inconsistencies or errors, and either auto-correct issues or alert relevant teams.",
    output: "Data quality reports and automated error corrections",
    tools: ["Talend", "Informatica"],
    href: "/pipeline?template=true&flow_id=u16So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "ETL Workflow Optimizer",
    des: "Improves efficiency of data extraction, transformation, and loading processes.",
    category: 5,
    process: "AI agents analyze ETL job performance, identify bottlenecks, and suggest or implement optimizations to reduce processing time and resource usage.",
    output: "Optimized ETL workflows with reduced processing time",
    tools: ["Alteryx", "Matillion"],
    href: "/pipeline?template=true&flow_id=u17So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Adaptive Data Modeling",
    des: "Automatically adjusts data models based on changing business requirements and data patterns.",
    category: 5,
    process: "AI agents monitor data usage patterns, analyze query performance, and propose or implement changes to data models for improved efficiency.",
    output: "Continuously optimized data models for improved query performance",
    tools: ["dbt", "Snowflake"],
    href: "/pipeline?template=true&flow_id=u18So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "GDPR Compliance Checker",
    des: "Ensures ongoing compliance with GDPR and other data protection regulations.",
    category: 6,
    process: "AI agents scan databases and data flows, identify potential compliance issues, and generate reports or trigger remediation workflows.",
    output: "Compliance status reports and remediation task lists",
    tools: ["OneTrust", "BigID"],
    href: "/pipeline?template=true&flow_id=u19So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Sensitive Data Detector",
    des: "Identifies and classifies sensitive information across company systems.",
    category: 6,
    process: "AI agents use NLP and pattern recognition to scan documents, databases, and communications for sensitive data, flagging items for review or protection.",
    output: "Mapped sensitive data locations with security recommendations",
    tools: ["Microsoft Information Protection", "Varonis"],
    href: "/pipeline?template=true&flow_id=u20So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Regulatory Change Monitor",
    des: "Tracks and assesses the impact of new regulations on business operations.",
    category: 6,
    process: "AI agents monitor regulatory sources, analyze new rules, and generate impact assessments and action plans for affected departments.",
    output: "Regulatory impact assessments and action plans",
    tools: ["Thomson Reuters Regulatory Intelligence", "Compliance.ai"],
    href: "/pipeline?template=true&flow_id=u21So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "High-Value Prospect Notifier",
    des: "Identifies and alerts sales teams about high-potential prospects requiring immediate attention.",
    category: 7,
    process: "AI agents analyze prospect behavior, firmographics, and intent signals to identify high-potential leads and provide actionable notifications to sales reps.",
    output: "Prioritized list of high-value prospects with engagement recommendations",
    tools: ["Salesforce", "ZoomInfo"],
    href: "/pipeline?template=true&flow_id=u22So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Churn Risk Alert System",
    des: "Predicts and notifies teams about customers at risk of churning.",
    category: 7,
    process: "AI agents monitor customer usage patterns, support interactions, and contract details to identify churn risks and suggest personalized retention tactics.",
    output: "Real-time alerts of at-risk accounts with retention strategies",
    tools: ["Gainsight", "Totango"],
    href: "/pipeline?template=true&flow_id=u23So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Competitive Threat Detector",
    des: "Alerts teams to potential competitive threats in customer accounts.",
    category: 7,
    process: "AI agents analyze customer communications, social media, and industry news to detect signs of competitor engagement and provide actionable intelligence.",
    output: "Timely alerts on competitive activities with defensive strategies",
    tools: ["Gong.io", "Crayon"],
    href: "/pipeline?template=true&flow_id=u24So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Upsell/Cross-sell Opportunity Alerter",
    des: "Identifies and notifies teams about potential upsell or cross-sell opportunities within existing accounts.",
    category: 7,
    process: "AI agents analyze customer usage data, purchase history, and industry trends to identify expansion opportunities and suggest targeted offerings.",
    output: "Personalized upsell/cross-sell recommendations for each account",
    tools: ["Salesforce", "Gainsight"],
    href: "/pipeline?template=true&flow_id=u25So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Customer Sentiment Shift Detector",
    des: "Monitors and alerts teams to significant changes in customer sentiment.",
    category: 7,
    process: "AI agents analyze customer interactions across various touchpoints (support, social media, surveys) to detect meaningful changes in sentiment and provide context-aware notifications.",
    output: "Real-time alerts on customer sentiment shifts with context and suggested actions",
    tools: ["Qualtrics", "Sprout Social"],
    href: "/pipeline?template=true&flow_id=u26So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Market Trend Analyzer",
    des: "Identifies emerging market trends and opportunities.",
    category: 8,
    process: "AI agents analyze news sources, social media, and industry reports to detect emerging trends, sentiment shifts, and potential market opportunities.",
    output: "Market trend reports with opportunity analysis",
    tools: ["Quid", "Brandwatch"],
    href: "/pipeline?template=true&flow_id=u27So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Ideal Customer Profile (ICP) Generator",
    des: "Dynamically creates and updates ideal customer profiles based on successful deals and market changes.",
    category: 8,
    process: "AI agents analyze closed-won deals, customer success metrics, and market data to continuously refine and segment ICPs, informing targeting and positioning strategies.",
    output: "Detailed ICP segmentation with targeting recommendations",
    tools: ["Salesforce", "Datanyze"],
    href: "/pipeline?template=true&flow_id=u28So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },
  {
    name: "Competitive Positioning Tracker",
    des: "Monitors and analyzes competitor positioning to inform GTM strategies.",
    category: 8,
    process: "AI agents track competitor messaging, product features, and market reception to provide insights on competitive differentiation and positioning opportunities.",
    output: "Competitive landscape map with positioning gap analysis",
    tools: ["Crayon", "G2 Crowd"],
    href: "/pipeline?template=true&flow_id=u29So8Ta6FEsBsG7F3gb8o9",
    initialNodes: [],
    initialEdges: [],
  },  
];

const AgentMainComponent = () => {
  const [values, setValues] = useState(initialValues);
  const onSearch = (value) => {
    const filteredValues = initialValues.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setValues(filteredValues);
  };
  const selectedGallery = useRecoilValue(selectedGalleryAtom);
  const selectedCategory = useRecoilValue(selectedGalleryCategoryAtom)
  console.log({selectedGallery})

  useEffect(() => {
    if (localStorage.getItem("workflow")) {
      const configure = JSON.parse(localStorage.getItem("workflow"));
      setValues((prevValues) => [
        ...configure?.map((conf) => ({
          name: conf?.name,
          des: conf?.des,
          href: "enrich",
          genOutput: conf?.genOutput,
          works: conf?.works,
          initialNodes: [
            {
              ...initialNodes?.filter(
                (node) => node?.id === conf?.input?.nodeId
              )[0],
              position: { x: -90, y: 150 },
            },
            {
              ...initialNodes?.filter(
                (node) => node?.id === conf?.output?.nodeId
              )[0],
              position: { x: 187.63, y: 501.46 },
            },
          ],
          initialEdges: [
            {
              id: "edge-1",
              source: conf?.input?.nodeId,
              target: conf?.output?.nodeId,
              sourceHandle: "b",
              targetHandle: "a",
              animated: true,
              data: {
                startLabel: {
                  mainText: conf?.input?.nodeOutput,
                  suppText: `type = ${conf?.input?.outputType}`,
                },
                endLabel: {
                  mainText: conf?.output?.nodeInput,
                  suppText: `type = ${conf?.output?.inputType}`,
                },
              },
              type: "start-end",
            },
          ],
          nodes: conf?.nodes,
        })),
        ...prevValues,
      ]);
    }
  }, []);

  const agentTemp = [
    {
      name: "Analytics Agent",
      des: "Transforms raw data into meaningful business insights.",
      genOutput: "Data insights and metrics.",
    },
    {
      name: "Knowledge Agent",
      des: "Compiles and organizes information for easy access.",
      genOutput: "Curated expertise base.",
    },
    {
      name: "Generative Agent",
      des: "Generates unique content and ideas using AI models.",
      genOutput: "Creates new content models.",
    },
    {
      name: "Research Agent",
      des: "Conducts in-depth studies to gather information and data.",
      genOutput: "Systematic study and data.",
    },
    {
      name: "Conversational Agent",
      des: "Facilitates natural language interactions and dialogue.",
      genOutput: "Engages in real-time chat.",
    },
    {
      name: "QA & Support Agent",
      des: "Provides answers and assists with customer inquiries.",
      genOutput: "Answers queries and aids.",
    },
    // {
    //   name: "Decision Support Agent",
    //   des: "Helps in making informed and data-driven decisions.",
    //   genOutput: "Guides strategic choices.",
    // },
  ];

  return (
    <div className="bg-[#F7F8FA] py-10">
      <div className="flex flex-col gap-4 bg-white mx-10 p-6 min-h-[75vh]">
        <Search
          placeholder="Search"
          onSearch={onSearch}
          enterButton
          className="extendSearch"
          allowClear
        />
        <NavBar />
        <AgentCardWrapper
          values={
            selectedGallery === 0
              ? values?.filter(x => x.category === selectedCategory)
              : selectedGallery === 2
                ? values?.filter((val) =>
                  val?.name === "Enrichment Waterfall"
                  //   JSON.parse(localStorage?.getItem("deployed"))?.some(
                  //     (dep) => val?.name === dep?.name
                  //   )
                )
                : agentTemp
          }
        />
        {selectedGallery === 0 && <AgentFooter />}
      </div>
    </div>
  );
};

export default AgentMainComponent;
