import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import { useNavigate, Outlet } from 'react-router-dom';
import Header from './Header/Header';
import './Main.css';
import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms";
import Sidenav from "./Sidenav/Sidenav";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { useSetRecoilState } from "recoil";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({}) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#01989D");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [siderWidth, setSiderWidth] = useState(225);
  const [isResizing, setIsResizing] = useState(false);
  const setChatMode = useSetRecoilState(chatModeAtom)
  const setSideNavContent = useSetRecoilState(sideNavContentAtom)

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    // setChatMode(0);
    setSideNavContent(0)
  };

  const handleMouseDown = () => {
    setIsResizing(true);
    document.body.classList.add('resizing');
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = e.clientX;
      if (newWidth >= 225 && newWidth <= 400) {
        setSiderWidth(newWidth);
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    document.body.classList.remove('resizing');
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <Layout className={`layout-dashboard`}>
      <div className="flex gap-1 justify-center align-center bg-[white] fixed">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
          width={collapsed ? 80 : siderWidth}
          theme="light"
          className={`sider-primary ant-layout-sider-primary`}
          style={{ height: "100vh" }}
        >
          <Sidenav collapsed={collapsed} siderWidth={siderWidth} />
          {!collapsed && <div className="resize-handle" onMouseDown={handleMouseDown} />}
        </Sider>
        <div className="chat-history-toggle">
          {collapsed ? <DoubleRightOutlined onClick={toggleCollapsed} /> : <DoubleLeftOutlined onClick={toggleCollapsed} />}
        </div>
      </div>
      <Layout>
        <AntHeader className="header">
          <Header
            siderWidth={siderWidth}
            onPress={openDrawer}
            name={pathname}
            subName={pathname}
            handleSidenavColor={handleSidenavColor}
            handleSidenavType={handleSidenavType}
            handleFixedNavbar={handleFixedNavbar}
          />
        </AntHeader>
        <Content 
          className={`content-ant bg-white pt-10`}
          style={{ marginLeft: collapsed ? '85px' : `${siderWidth + 5}px` }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Main;
