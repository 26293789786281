import React, { useState, useRef, useEffect } from "react";
import GridLayout from "react-grid-layout";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { FaLock, FaUnlock, FaPencilAlt, FaDownload, FaExpand, FaUndo, FaChartBar, FaTable } from 'react-icons/fa';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Select, message, Alert, Button, Card, Typography, List } from "antd";
import html2canvas from 'html2canvas';
import { CloseOutlined } from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";
import { getDashboardData } from "../../services/InsightsServices";
import DynamicChart from "../CopilotComponents/DynamicChart";
import ReactMarkdown from 'react-markdown'

const { Title, Paragraph, Text } = Typography;

const backgroundColors = [
  "#E07E65",
  "#F39F8E",
  "#8E4E2A",
  "#653712",
  "#F9B8A6",
];
const {Option} = Select 
const Dashboard = () => {
  const [dashboardData, setdashboardData] = useState([])
  const [layout, setLayout] = useState(
    dashboardData?.map((data, index) => ({
      i: index.toString(),
      x: (index % 2) * 4,
      y: Math.floor(index / 3) * 4,
      w: 4,
      h: 4,
      static: false,
      minW: 2,
      minH: 2,
    }))
  );
  const [dashboardBlocks, setdashboardBlocks] = useState ([
    {blockName: "Summary Block for Charts:- ", blockData: "Summaryyyyy!!!!!"},
    {blockName: "Recommendation Block for Charts:- ", blockData: "Recommendationnnn!!!!!"}
  ])
  const [blockLayout, setBlockLayout] = useState(
    dashboardBlocks?.map((data, index) => ({
      i: `block_${index}`,
      x: 0, // Start from the first column
      y: index * 2, // Position each block in a new line
      w: 12, // Full width
      h: 2, // Height of 2 rows
      static: false,
      minW: 2,
      minH: 2,
    }))
  );
  const [layoutHistory, setLayoutHistory] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalChartData, setModalChartData] = useState(null);
  const [modalChartType, setModalChartType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showChart, setShowChart] = useState(true); 
  const chartRefs = useRef([]);
  const [showSelector, setShowSelector] = useState(false)
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [chartDatas, setChartDatas] = useState([])
  const [isSummaryLoading, setIsSummaryLoading] = useState(false)
  const [cummulativeSummary, setCummulativeSummary] = useState('')
  

  const handleTileSelection = (selectedItems) => {
    setSelectedTiles(selectedItems);
    console.log({selectedItems})
  };
  const handleKeyDown = (event) => {
    if (event.key === '/') {
      setShowSelector(prevState => !prevState); 
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const saveCurrentLayout = () => {
    setLayoutHistory((prevHistory) => [...prevHistory, layout]);
  };

  const toggleLock = (index) => {
    saveCurrentLayout();
    setLayout((prevLayout) =>
      prevLayout?.map((item, i) => (i === index ? { ...item, static: !item.static } : item))
    );
  };

  const handleLayoutChange = (newLayout) => {
    if (isEditMode) {
      saveCurrentLayout();
      setLayout(newLayout);
    }
  };

  const parseCumulativeSummary = (summary) => {
    // Remove the "Cumulative Summary:" label at the start
    const cleanedSummary = summary.replace("Cumulative Summary:", "").trim();
  
    // Split by the main sections (e.g., "1.", "2.", etc.)
    const sectionRegex = /\d\.\s+/g;
    const sections = cleanedSummary.split(sectionRegex).filter(Boolean);
  
    // Create structured data by splitting each section into title and content
    return sections.map((section) => {
      // Split section into title and content
      const [titleLine, ...contentLines] = section.split(/\n\s*-\s*/);
  
      // First part is the title, rest are the bullet points
      const title = titleLine.trim();
      const content = contentLines.map(line => line.trim());
  
        return {
          title,
          content,
        };
      });
    };
  

  useEffect(() => {
    const fetchData = async () => {

      try {
        const payload = {
          user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).id
        };
        const response = await getDashboardData(payload);
        console.log({response})
        if (response.data.status === "success") {
          const data = response.data.result;
          // setdashboardData(data);
          console.log("Dashboard Data => ",data)
          setdashboardData(data)
          setLayout(data?.map((_, index) => ({
            i: index.toString(),
            x: (index % 2) * 4,
            y: Math.floor(index / 3) * 4,
            w: 4,
            h: 4,
            static: false,
            minW: 2,
            minH: 2,
          })));
          setBlockLayout(
            dashboardBlocks?.map((data, index) => ({
              i: `block_${index}`,
              x: 0,
              y: index * 2,
              w: 12,
              h: 2,
              static: false,
              minW: 2,
              minH: 2,
            }))
          );
        } else {
          message.error("Error fetching dashboard data");
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        message.error("Error fetching dashboard data");
      }
    };
console.log("Fetching dashboard Data")
    fetchData();
  }, []);


  const renderTable = (data, type, index) => {
      // Render table here
      return (
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              {Object.keys(data[0])?.map((key, idx) => (
                <th key={idx} className="px-4 py-2">{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, idx) => (
              <tr key={idx} className={idx % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                {Object.values(item)?.map((value, index) => (
                  <td key={index} className="border px-4 py-2">{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
  };

  const renderChart = (data, type, index, id) => {
    return <img id={id} src={`${data}`} alt={`Chart ${index}`} ref={(el) => chartRefs.current[index] = el} />;
  };

  const downloadChart = (id) => {
    const chartElement = document.getElementById(id);
    console.log(chartElement, "-----sdfsf------");
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'chart.png';
        link.click();
        message.success("Image downlaoded successfully")
      });
    }
  };

  const expandChart = (data, type) => {
    setModalChartData(data);
    setModalChartType(type);
    setShowModal(true);
    setShowChart(true); // Show chart by default
  };

  const handleUndo = () => {
    setdashboardData(dashboardData)
    setLayout(dashboardData?.map((data, index) => ({
      i: index.toString(),
      x: (index % 3) * 4,
      y: Math.floor(index / 3) * 4,
      w: 4,
      h: 4,
      static: false,
      minW: 2,
      minH: 2,
    })))
    setBlockLayout(
      dashboardBlocks?.map((data, index) => ({
        i: `block_${index}`,
        x: 0, // Start from the first column
        y: index * 2, // Position each block in a new line
        w: 12, // Full width
        h: 2, // Height of 2 rows
        static: false,
        minW: 2,
        minH: 2,
      }))
    );
  };
  const contentRef = useRef(null);

  const handleDownload = () => {
    if (contentRef.current) {
      html2canvas(contentRef.current).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'dashboard.png';
        link.click();
      });
    }
  };

  const generateCummulativeSummary = async () => {
    try {
      setIsSummaryLoading(true)
      console.log("PAYLOAD ======> ", selectedTiles)
      const filteredData = selectedTiles.map(tile => 
        dashboardData.filter(data => data.report_id === tile)
      ).flat();;
  
      console.log("Filtered Data =====> ", filteredData);
      const plotlyCodes = []
      const summaries = []

      filteredData.forEach(x => {
        plotlyCodes.push(JSON.parse(x?.chart_data))
        summaries.push(x?.description)
      })

      const payload = {
        plotly_codes : plotlyCodes,
        chart_summaries: summaries
      }

      console.log("FINAL PAYLOAD ====> ", payload)

      const response = await fetch('http://54.145.37.119:8001/demoapp/cummulative_summary/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log(data)
      setCummulativeSummary(data?.cumulative_summary)
    } catch (error) {
      console.error('Error generating formula:', error);
    } finally {
      setIsSummaryLoading(false)
    }
  }

  const deleteChart = (index) => {
    setdashboardData(dashboardData.filter((_, idx) => idx !== index))
    setLayout(layout.filter(l => Number(l.i) !== index))
  }
  
  return (
    <div className="flex flex-col justify-center">
      {!showSelector && <div className="flex justify-center">
          <span className="mt-2 text-[gray]">Press "/" and select the report to create a summary & recommendation block. You can do this for multiple reports</span>
        </div>
      }
      {showSelector && (
        <div className="new-tile-popup mt-2">
          <Select
            placeholder="Select charts to view summary"
            style={{ minWidth: "20%" }}
            mode="multiple"
            maxCount={2}
            value={selectedTiles}
            onChange={handleTileSelection}
            options={dashboardData?.map(option => ({
              value: option.report_id,
              label: option.name
            }))}
          />
          <Button type="primary" loading={isSummaryLoading} style={{ marginLeft: '10px' }} onClick={generateCummulativeSummary}>Summarize</Button>
        </div>
      )}
      <div className="flex mt-4 justify-end items-center w-[98%] p-2 bg-gray-300">
        <FaPencilAlt className="cursor-pointer mr-4" onClick={() => {
          setIsEditMode(!isEditMode);
          if (!isEditMode) {
            saveCurrentLayout();
          }
        }} />
        {isEditMode && <FaUndo className="cursor-pointer mr-4" onClick={handleUndo} />}
        {!isEditMode && <FaDownload className="cursor-pointer mr-4" onClick={handleDownload} />}
      </div>
      <div ref={contentRef}>
        {cummulativeSummary && 
        <>
        <GridLayout
            className="layout ml-3 w-[95%]"
            layout={blockLayout}
            cols={6}
            rowHeight={200}
            width={800}
            onLayoutChange={setBlockLayout}
            draggableHandle=".drag-handle"
            isDraggable={isEditMode}
            isResizable={isEditMode}
          >
            <div key={`block_1`}  className=" drag-handle cursor-move relative bg-gray-100 border p-2">
              <ReactMarkdown>{cummulativeSummary}</ReactMarkdown>
            </div>
          </GridLayout>
        {/* <Title level={3}>Cumulative Summary</Title> */}
        {/* <Typography>
          {cummulativeSummary.map((section, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Title level={5}>{section.title}</Title>
              <List
                dataSource={section.content}
                renderItem={item => (
                  <List.Item>
                    <Paragraph>{item}</Paragraph>
                  </List.Item>
                )}
              />
            </div>
          ))}
        </Typography> */}
        </>
        }
      {/* {selectedTiles.length > 0 &&  <>
          <GridLayout
            className="layout ml-3 w-[95%]"
            layout={blockLayout}
            cols={12}
            rowHeight={75}
            width={1200}
            onLayoutChange={setBlockLayout}
            draggableHandle=".drag-handle"
            isDraggable={isEditMode}
            isResizable={isEditMode}
          >
            {dashboardBlocks?.map((block, index) => (
              <div key={`block_${index}`}  className=" drag-handle cursor-move relative bg-gray-100 border p-2">
                <div className="font-bold">{block.blockName + " " + selectedTiles.join(", ") }</div>
                <div className="font-light">{block.blockData}</div>
              </div>
            ))}
          </GridLayout>
      </>} */}
      <div className="flex gap-2 justify-center">

      <GridLayout
        className="layout ml-3 w-full"
        layout={layout}
        cols={12}
        rowHeight={125}
        width={1200}
        onLayoutChange={handleLayoutChange}
        draggableHandle=".drag-handle"
        isDraggable={isEditMode}
        isResizable={isEditMode}
      >
        {layout?.map((item, index) => (
          <div
            key={index}
            className="bg-white p-4 border rounded shadow-md"
            data-grid={item}
          >
            <div className="flex justify-between items-center bg-gray-200 p-2">
              <span className="w-[60%]">
                {dashboardData[index].chart_type}
              </span>
              <div className="flex justify-end w-[40%]">
                <FaDownload className="cursor-pointer mx-1" onClick={() => downloadChart(index)} />
                {/* <FaExpand className="cursor-pointer mx-1" onClick={() => expandChart(dashboardData[index].chartData, dashboardData[index].type)} /> */}
                {isEditMode && (
                  <>
                    <span onClick={() => toggleLock(index)} className="cursor-pointer mx-1">
                      {item.static ? <FaLock /> : <FaUnlock />}
                    </span>
                    <span onClick={() => deleteChart(index)} className="cursor-pointer mx-0.5 -mt-[0.2rem]">
                      <CloseOutlined />
                    </span>
                  </>
                )}
              </div>
            </div>
            { (dashboardData != [] && dashboardData[index].chart_type === 'plotly') ?
             <DynamicChart chartData={dashboardData[index].chart_data} type='read'/>
             :
            <div className={`drag-handle ${isEditMode ? 'cursor-move' : ''}`} style={{ height: 'calc(100% - 40px)', overflowY: "scroll" }}>
              {renderChart(dashboardData[index]?.chart_data, dashboardData[index]?.chart_type, index, dashboardData[index]?.report_id)}
            </div> 
            }
          </div>
        ))}
      </GridLayout>
      </div>
      {
        dashboardData.length == 0 &&
        <div className="flex justify-center">
          <Alert message="Info" description="We're trying to load your dashboard data, but in case you have not saved any Insight to dashboard then it won't be visible here." type="info" showIcon/>
        </div>
      }
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="bg-white p-4 rounded shadow-lg w-full h-[90%] max-w-3xl max-h-full overflow-auto">
            <button className="float-right text-xl" style={{fontWeight: 500}} onClick={() => setShowModal(false)}>x</button>
            <div className="flex justify-center cursor-pointer items-center mt-2 mb-4">
              {!showChart && <FaChartBar onClick={() => setShowChart(true)} />}
              {showChart && <FaTable onClick={() => setShowChart(false)}/>}  
            </div>
            <div className="h-[80vh] flex items-center justify-center">
              { showChart ? renderChart(modalChartData, modalChartType, 'modal') : renderTable(modalChartData, modalChartType, 'modal')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
