import React, { useState, useRef, useEffect } from "react";
import {
  UndoOutlined,
  RedoOutlined,
  EditFilled,
  ArrowsAltOutlined,
} from "@ant-design/icons";
import { Card, Table, Input, Switch, message } from "antd";
import html2canvas from "html2canvas";
import {jwtDecode} from "jwt-decode";
import { getReport } from "../../services/InsightsServices";
// import { formatDate } from "../../utils/formatDate";
import { formatDate } from "../../helpers/formatDate";
import shareImg from "../../assets/images/shareImg.png";
import downloadImg from "../../assets/images/downloadImg.png";
import funnelImg from "../../assets/images/funnel.png";
import {
  BarChart, Bar, LineChart, Line, PieChart, Pie, FunnelChart, Funnel, ScatterChart, Scatter,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList
} from 'recharts';
import DynamicChart from "./DynamicChart.js";

import "./CoPilotStyles.css";

const { Search } = Input;

const Reports = () => {
  const [rowsSelected, setRowsSelected] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [chart, setChart] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const divRef = useRef(null);
  useEffect(() => {
    if (divRef?.current) {
      divRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  // const onSearch = (value, _e, info) => console.log(info?.source, value);
  const onSearch = (value) => {
    const lowercasedValue = value.toLowerCase();
    const filtered = data.filter((item) =>
      Object.values(item).some(
        (val) => String(val).toLowerCase().includes(lowercasedValue)
      )
    );
    setFilteredData(filtered);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setRowsSelected(selectedRows?.length);
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("loginToken");
        const decodedToken = jwtDecode(token?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ""));
        const payload = {
          user_id: decodedToken.id,
        };
        const response = await getReport(payload);
        if (response.data.status === "success") {
          const rawData = response.data.result;
          const transformedData = rawData.map((item) => ({
            key: item.report_id,
            dateCreated: formatDate(item.created_date),
            reportName: item.name,
            description: item.description,
            lastEdit: formatDate(item.created_date), // Adjust this as needed
            catalogue: false, // Adjust this as needed
            chart_data: item.chart_data,
            chart_color: item.chart_color,
            chart_type: item.chart_type,
            show_grid: item.show_grid,
            show_values: item.show_values,
            x_axis: item.x_axis,
            y_axis: item.y_axis,
          }));
          setData(transformedData);
          setFilteredData(transformedData)
        } else {
          message.error("Error fetching dashboard data");
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        message.error("Error fetching dashboard data");
      }
    };

    fetchData();
  }, []);

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const renderChartMain = (selectedChartType, chartData, showReferenceLine, xAxis, yAxis, chartColor, showValues) => {
    switch (selectedChartType) {
      case 'Bar':
        return (
          <BarChart
            width={1000} // Increased width
            height={500}
            data={chartData}
            margin={{ top: 20, right: 30, left: 40, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis
              dataKey={xAxis}
              tick={{
                angle: -60, // Increased angle for better fitting
                fontSize: 10,
                width: 100, // Increased width for labels
                interval: 0, // Show all labels
                textAnchor: 'end', // Align text to the end
                formatter: (value) => {
                  const stringValue = String(value);
                  return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                },
              }}
              height={100} // Further increased height for more space
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill={chartColor}>
              {showValues && (
                <LabelList
                  dataKey={yAxis}
                  position="top" // Move labels outside the bars
                  formatter={(value) => {
                    const stringValue = String(value);
                    return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                  }}
                  style={{ fontSize: 10 }} // Adjust font size if necessary
                />
              )}
            </Bar>
          </BarChart>
        );
      case 'Column':
        return (
<BarChart width={1000} height={500} data={chartData} layout="vertical" margin={{ top: 20, right: 30, left: 40, bottom: 30 }}>
  <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
  <XAxis type="number" tick={{ fontSize: 10 }} />
  <YAxis dataKey={xAxis} type="category" tick={{fontSize: 8 }} width={100} /> {/* Adjust width for longer labels */}
  <Tooltip />
  <Legend />
  <Bar dataKey={yAxis} fill={chartColor}>
    {showValues && (
      <LabelList
        dataKey={yAxis}
        position="right" // Options: 'insideLeft', 'insideRight', 'right'
        formatter={(value) => {
          const stringValue = String(value); // Ensure value is a string
          return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
        }}
        style={{ fontSize: 10 }} // Adjust font size if necessary
      />
    )}
  </Bar>
</BarChart>

        );
      case 'Stacked Bar':
        return (
          <BarChart width={600} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="top" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="top" />}
            </Bar>
          </BarChart>
        );
      case 'Stacked Column':
        return (
          <BarChart width={600} height={300} data={chartData} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis type="number" />
            <YAxis dataKey={xAxis} type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="right" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="right" />}
            </Bar>
          </BarChart>
        );
      case 'Line':
        return (
          <LineChart width={600} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={yAxis} stroke={chartColor} />
          </LineChart>
        );
      case 'Donut':
        return (
          <PieChart width={400} height={400}>
            <Pie
              data={chartData}
              dataKey={yAxis}
              nameKey={xAxis}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill={chartColor}
              label={showValues}
            />
          </PieChart>
        );
      case 'Funnel':
        return (
          <FunnelChart width={600} height={400}>
          <Tooltip />
          <Funnel
            dataKey={yAxis}
            data={chartData}
            isAnimationActive
            fill={chartColor}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={chartColor} />
            ))}
          </Funnel>
        </FunnelChart>
        );
      case 'Scatter Plot':
        return (
          <ScatterChart width={600} height={400}>
            <CartesianGrid vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis dataKey={yAxis} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="Scatter Plot" data={chartData} fill={chartColor} />
          </ScatterChart>
        );
      default:
        return null;
    }
  };
  const downloadChartAsImage = (id) => {
    const chartElement = document.getElementById(id);
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
        message.success("Image downloaded successfully");
      });
    }
  };
  console.log({chart});

  return (
    <>
      {/* Chart Component Starts */}
      <div className="w-full flex justify-center items-center">
        <Card className="w-[95%] relative mt-2">

          {
            chart?.chart_type == 'python' ? 
            <div id={chart?.id} className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md">
              <img className="w-[75%]" src={chart?.chart_data} alt="Generated by Python" />
            </div> : 
            <div id={chart?.id} className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md">
              {renderChartMain(chart?.chart_type, (chart && JSON.parse(chart?.chart_data)), chart?.show_grid, chart?.x_axis, chart?.y_axis, chart?.chart_color, chart?.show_values)}
            </div>
          }
          {
                  chart?.chart_type == 'python' ?
                    <div id={chart?.id} className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md">
                      <img className="w-[75%]" src={chart?.chart_data} alt="Generated by Python" />
                    </div> : 
                    chart?.chart_type == 'plotly' ?
                    <div id={chart?.id} className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md">
                      <DynamicChart chartData={chart?.chart_data} type="read" />
                    </div>
                      :
                    <div id={chart?.id} className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md">

                      {renderChartMain(chart?.chart_type, (chart && JSON.parse(chart?.chart_data)), chart?.show_grid, chart?.x_axis, chart?.y_axis, chart?.chart_color, chart?.show_values)}
                    </div>
                }
          <div className="flex justify-between items-center w-full border border-black px-4 py-2 rounded-md">
            <div className="flex justify-start items-center gap-4 w-full">
              <p className="w-[fit-content]">{rowsSelected}/{filteredData.length} Rows Selected</p>
              <Search
                placeholder="Search"
                onSearch={onSearch}
                allowClear
                className="extendSearch"
                style={{
                  width: "80%",
                }}
              />
            </div>
            {/* <div className="flex justify-center items-center gap-2">
              <img src={funnelImg} alt="funnelImg" width={14} />
            </div> */}
          </div>
          <Table
            rowSelection={rowSelection}
            columns={[
              {
                title: "Date Created",
                dataIndex: "dateCreated",
                width: "15%",
              },
              {
                title: "Report Name",
                dataIndex: "reportName",
              },
              {
                title: "Description",
                dataIndex: "description",
                width: "30%",
              },
              {
                title: "Last Edit",
                dataIndex: "lastEdit",
              },
              {
                title: "Catalogue",
                dataIndex: "catalogue",
                render: (_, { reportName }) => (
                  <div className="copilotSwitchContainer">
                    <Switch
                      defaultChecked={reportName === "Tech Report" ? true : false}
                      onChange={onChange}
                    />
                  </div>
                ),
              },
            ]}
            rowHoverable={false}
            dataSource={filteredData}
            bordered
            pagination={{ 
              pageSizeOptions: ['5', '10', '20', '30', '50'], 
              showSizeChanger: true,
              defaultPageSize: 5
            }}
            className={`overflow-scroll shadow-lg`}
            rowClassName={(record) => 
              record.key === selectedRowKey ? "bg-[#73D297]" : "hover:bg-[#E9EDF1]"
            }
            onRow={(record) => ({
              onClick: () => {
                if (selectedRowKey === record.key) {
                  setSelectedRowKey(null);
                  setChart(null);
                } else {
                  setSelectedRowKey(record.key);
                  setChart({
                    id: record.key,
                    chart_data: record.chart_data,
                    chart_type: record.chart_type,
                    chart_color: record.chart_color,
                    show_grid: record.show_grid,
                    show_values: record.show_values,
                    x_axis: record.x_axis,
                    y_axis: record.y_axis,
                  });
                }
              },
            })}
            
          />
        </Card>
      </div>
      {/* Chart Component Ends */}
    </>
  );
};

export default Reports;
