import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { copilotChatArrayAtom, copilotInputFilledAtom, copilotQuestionAtom, isFileAttachedAtom, attachedFileAtom, isProcessingAtom, visualModeAtom, assistantFocusAtom, activeSessionAtom, fileListAtom } from '../../../store';
import { Button, message, Modal, Card } from 'antd';
import { PaperClipOutlined, FileExcelTwoTone, FilePdfTwoTone } from "@ant-design/icons";
import Papa from "papaparse";
import { jwtDecode } from 'jwt-decode';
import { csvDataInDB } from '../../../services/chatServices';
import HeaderMappingModal from '../../ChatWindowInitial/HeaderMappingModal';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import { chatModeAtom } from "../../../store/atoms/MainAtoms";

const AttachFileInputBox = ({ handleSend }) => {
    const [isProcessing, setIsProcessing] = useRecoilState(isProcessingAtom);
    const chatMode = useRecoilValue(chatModeAtom);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const fileInputRef = useRef(null);
    const [assistantFocus, setAssistantFocus] = useRecoilState(assistantFocusAtom);
    const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
    const [inputFilled, setInputFilled] = useRecoilState(copilotInputFilledAtom);
    const [attachModalVisible, setAttachModalVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [isFileAttached, setIsFileAttached] = useRecoilState(isFileAttachedAtom);
    const [attachedFile, setAttachedFile] = useRecoilState(attachedFileAtom);
    const [visualMode, setVisualMode] = useRecoilState(visualModeAtom);
    const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom);
    const [fileList, setFileList] = useRecoilState(fileListAtom);

    const handleModalCancel = () => {
        setModalVisible(false);
        setAttachedFile({});
        setIsFileAttached(false);
        console.log(attachedFile, "attachhhhhhhhhh");
    };

    const handleAttachModalOpen = () => {
        setAttachModalVisible(true);
    };

    const handleCloseAttachModal = () => {
        setAttachModalVisible(false);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            // If no file is selected, clear the attached file
            setIsFileAttached(false);
            setAttachedFile(null);
            return;
        }

        // Check if file size is less than 1MB
        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
        if (file.size > maxFileSize) {
            message.error("File size should be less than 1MB");
            return;
        }

        setIsProcessing(true);
        setInputFilled(true);
        console.log(attachedFile, "attachhhhhhhhhdfsdfsfdsh");

        setTimeout(() => {
            handleCloseAttachModal();
            setIsProcessing(false);
            setIsFileAttached(true);
            setAttachedFile(file);
            fileInputRef.current.value = '';
        }, 1000);
    };

    const handleModalOk = async (headerMapping) => {
        setModalVisible(false);
        if (isFileAttached) {
            Papa.parse(attachedFile, {
                header: true,
                complete: (results) => {
                    const csvData = results.data;

                    const newCsvData = csvData
                        .filter(row => {
                            // Filter out rows where all fields are null or blank spaces
                            return Object.values(row).some(value => value !== null && value.trim() !== "");
                        })
                        .map(row => {
                            const newRow = {};

                            // Add all fields from the original row to newRow
                            Object.keys(row).forEach(key => {
                                newRow[key] = row[key];
                            });

                            // Update newRow with mapped fields if they are not an exact match
                            Object.keys(headerMapping).forEach(standardHeader => {
                                if (headerMapping[standardHeader] && headerMapping[standardHeader] !== standardHeader) {
                                    newRow[standardHeader] = row[headerMapping[standardHeader]];
                                    delete newRow[headerMapping[standardHeader]];
                                }
                            });

                            return newRow;
                        });
                    const query_id = uuidv4();
                    const payload = {
                        user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).id,
                        data: newCsvData,
                        filename: attachedFile?.name.split(".")[0],
                        query_id: query_id,
                        session_id: activeSession
                    };

                    csvDataInDB(payload)
                        .then(dataResponse => {
                            console.log(dataResponse.data);
                            setVisualMode('table');
                            setChatArray(prevChat => ([...prevChat,
                            {
                                question: dataResponse.data.question,
                                answer: dataResponse.data.summary,
                                postgres_query: `SELECT * FROM ${dataResponse.data.tableName}`,
                                visuals: true,
                                query_id: query_id
                            }
                            ]));
                            localStorage.setItem("tableName", dataResponse.data.tableName);
                            setAssistantFocus(dataResponse.data.tableName);
                            localStorage.setItem("session_id", dataResponse.data.sessionId);
                            setActiveSession(dataResponse.data.sessionId);
                            handleSend();

                            // Save query_id and file name to fileListAtom
                            setFileList(prevList => [
                                ...prevList,
                                { tableName: dataResponse.data.tableName, filename: attachedFile?.name }
                            ]);
                        })
                        .catch(error => {
                            message.error("Error sharing CSV data");
                            setAttachedFile(null);
                        });
                    setIsFileAttached(false);
                }
            });
        }
    };

    useEffect(() => {
        if (attachedFile?.name != null && isFileAttached) {
            Papa.parse(attachedFile, {
                header: true,
                complete: async (results) => {
                    const csvData = results.data;
                    setCsvHeaders(Object.keys(csvData[0]));
                    setModalVisible(true);
                }
            });
        }
    }, [attachedFile]);

    return (
        <>
            <Modal
                title="Select File"
                visible={attachModalVisible}
                onCancel={handleCloseAttachModal}
                footer={null}
            >
                <div className='flex justify-center gap-10'>
                    <label
                        htmlFor="file-input"
                        className="cursor-pointer flex items-center"
                    >
                        <Card className='mb-4'>
                            <div className="flex justify-center">
                                <FileExcelTwoTone className='text-[600%] cursor-pointer' />
                            </div>
                            <div className="flex justify-center text-[20px] mt-1" style={{ fontWeight: 600 }}>
                                CSV
                            </div>
                            <div className="flex text-[grey] justify-center text-[10px] mt-1" style={{ fontWeight: 500 }}>
                                File size should be {`<`} 1 mb
                            </div>
                        </Card>
                    </label>
                    <input
                        id="file-input"
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        accept=".csv"
                    />
                    <div>
                        <Card >
                            <div className="flex justify-center">
                                <FilePdfTwoTone className='text-[600%] cursor-pointer' />
                            </div>
                            <div className="flex justify-center text-[20px] mt-1" style={{ fontWeight: 600 }}>
                                PDF
                            </div>
                            <div className="flex justify-center text-[grey] text-[10px] mt-1" style={{ fontWeight: 500 }}>
                                File size should be {`<`} 100 mb
                            </div>
                        </Card>
                        <div className="flex justify-center text-[12px] mt-1 text-[grey]" style={{ fontWeight: 600 }}>
                            Coming Soon
                        </div>
                    </div>
                </div>
            </Modal>
            <HeaderMappingModal
                visible={modalVisible}
                onCancel={handleModalCancel}
                onOk={handleModalOk}
                csvHeaders={csvHeaders}
                maskClosable={false}
            />
            {chatMode == 0 &&
                <div className="mr-2">
                    <Button className="flex items-center justify-center rounded-lg" onClick={handleAttachModalOpen}>
                        <PaperClipOutlined style={{ fontSize: '14px' }} />
                    </Button>
                </div>
            }
        </>
    );
};

export default AttachFileInputBox;
