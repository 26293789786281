import React from "react";
import { CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const EnrichCustomButton = ({
  id,
  text,
  icon,
  onClick,
  testClicked,
  loading,
}) => {
  if (text === "Test" && loading) {
    return (
      <Spin
        key="spinner"
        indicator={<LoadingOutlined />}
        size="large"
        className="w-16"
      />
    );
  }

  return (
    <div
      className={`flex justify-center items-center gap-1 border rounded-lg border-2 px-2 cursor-pointer hover:bg-[#8EA9D0] hover:scale-105 duration-300 ease-in-out ${
        testClicked && id === 1 && "bg-[#8EA9D0] scale-105"
      }`}
      onClick={id === 1 ? onClick : null}
    >
      <img src={icon} alt={`${text}_img`} width={18} />
      <p className="text-base">{text}</p>
      {text === "Save" && <CaretDownOutlined />}
    </div>
  );
};

export default EnrichCustomButton;
