import React, { useState, useEffect, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { chatModeAtom } from '../../../store/atoms/MainAtoms';
import { copilotChatArrayAtom, copilotInputFilledAtom, copilotQuestionAtom } from '../../../store';
import { Button, Dropdown, Menu } from 'antd';
import { StarFilled, DownOutlined } from "@ant-design/icons";

const SuggestionsInputBox = ({ handleSend }) => {
    const chatMode = useRecoilValue(chatModeAtom);
    const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
    const [question, setQuestion] = useRecoilState(copilotQuestionAtom);
    const [inputFilled, setInputFilled] = useRecoilState(copilotInputFilledAtom);
    const [visibleCount, setVisibleCount] = useState(0);
    const containerRef = useRef(null);

    // useEffect(() => {
    //     calculateVisibleSuggestions();
    //     window.addEventListener('resize', calculateVisibleSuggestions);
    //     return () => {
    //         window.removeEventListener('resize', calculateVisibleSuggestions);
    //     };
    // }, [chatArray]);

    // const calculateVisibleSuggestions = () => {
    //     if (!containerRef.current) return;

    //     const containerWidth = containerRef.current.offsetWidth;
    //     let accumulatedWidth = 0;
    //     let count = 0;

    //     containerRef.current.childNodes.forEach((child) => {
    //         const buttonWidth = child.offsetWidth + 16; // including margin
    //         if (accumulatedWidth + buttonWidth <= containerWidth) {
    //             accumulatedWidth += buttonWidth;
    //             count++;
    //         }
    //     });

    //     setVisibleCount(count);
    // };

    const handleSuggestionClick = () => {
        setInputFilled(true);
        // handleSend();
    };

    const onSuggestionClick = (suggestion) => {
        setQuestion(suggestion);
        handleSuggestionClick();
    };

    const renderDropdownMenu = (suggestions) => (
        <Menu>
            {suggestions.map((suggestion, index) => (
                <Menu.Item key={index} onClick={() => onSuggestionClick(suggestion)}>
                    <Button className="w-full text-left px-4 py-2 text-[#9254DE] bg-[#EFDBFF] flex items-center text-[12px] cursor-pointer transition duration-300 ease-in-out">
                        <StarFilled /> {suggestion}
                    </Button>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <>
            {chatMode == 0 && chatArray[chatArray.length - 1]?.suggestions && (
                <div ref={containerRef} className="flex flex-wrap mt-1 justify-center items-center gap-4 w-full">
                    {chatArray[chatArray.length - 1]?.suggestions?.slice(0, 1).map((suggestion, index) => (
                        <Button
                            onClick={() => onSuggestionClick(suggestion)}
                            key={index}
                            className="px-4 py-2 mt-1 text-[#9254DE] bg-[#EFDBFF] flex items-center text-[14px] cursor-pointer transition duration-300 ease-in-out"
                        >
                            <StarFilled /> {suggestion}
                        </Button>
                    ))}
                    {1 < chatArray[chatArray.length - 1]?.suggestions?.length && (
                        <Dropdown
                            overlay={renderDropdownMenu(chatArray[chatArray.length - 1]?.suggestions.slice(1))}
                        >
                            <Button className="px-4 py-2 mt-1 text-[#9254DE] bg-[#EFDBFF] flex items-center text-[14px] cursor-pointer transition duration-300 ease-in-out">
                                View More <DownOutlined />
                            </Button>
                        </Dropdown>
                    )}
                </div>
            )}
        </>
    );
};

export default SuggestionsInputBox;
