import React, { useState, useRef, useEffect } from "react";

import {
  PieChartFilled,
  ShrinkOutlined,
  PlusOutlined,
  UndoOutlined,
  RedoOutlined,
  CloseOutlined,
  RiseOutlined,
  SafetyCertificateOutlined,
  ClusterOutlined,
  ExperimentOutlined,
  BankOutlined, BlockOutlined, IdcardOutlined
} from "@ant-design/icons";
import { Card, Table, Input, Dropdown, Menu, Modal, message, Progress } from "antd";
import { Bar } from "react-chartjs-2";
import { GlitterDropdown } from "./GlitterDropdown.js";

import graphImg from "../../assets/images/graphImg.png";
import shareImg from "../../assets/images/shareImg.png";
import downloadImg from "../../assets/images/downloadImg.png";
import glitterImg from "../../assets/images/glitter.png";
import funnelImg from "../../assets/images/funnel.png";
import overlapImg from "../../assets/images/overlapIcon.png";
import './ExtendedChat.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CopSuccessComponent from "./CopSuccessComponent.js";
import {
  faSalesforce,
  faHubspot,
  faLayerPlus
} from "@fortawesome/free-brands-svg-icons";
import {
  faDatabase,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import { useSetRecoilState, useRecoilState } from "recoil";
import { extendedChatAtom, copilotChatArrayAtom, cc_tableColsAtom,cc_tableDataAtom, cc_magicAtom, cc_modalAtom } from "../../store";
import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms";
import "./CoPilotStyles.css";
import EnrichOption from "../OptionsComponents/EnrichOption";
import DeduplicateOption from "../OptionsComponents/DeduplicateOption";
import FindLookALikeOption from "../OptionsComponents/FindLookALikeOption";
import AddPeopleOption from "../OptionsComponents/AddPeopleOption";
import ValidateOption from "../OptionsComponents/ValidateOption";
import FindParentChildOption from "../OptionsComponents/FindParentChildOption";

import html2canvas from "html2canvas";
import { getQueryReport } from "../../services/InsightsServices";
import DisplayTableComponent from "./DisplayTableComponent";

const { Search } = Input;

const ExtendedChat = () => {
  const [progress, setProgress] = useState(0); // Progress state
  const [pocTableData, setPocTableData] = useRecoilState(cc_tableDataAtom)
  const [pocTableCols, setPocTableCols] = useRecoilState(cc_tableColsAtom)
  const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
  const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);
  const [rowsSelected, setRowsSelected] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [chartVisible, setChartVisible] = useState(true);
  const [plusOptionsVisible, setPlusOptionsVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [starOptionsVisible, setStarOptionsVisible] = useState(false);
  const [modalVisible, setIsModalVisible] = useState(false)
  const [optionSelected, setOptionSelected] = useState("")
  const [data, setData] = useState(null)
  const [magic, setMagic] = useRecoilState(cc_magicAtom);
  const [modal, setModal] = useRecoilState(cc_modalAtom);


  const divRef = useRef(null);
  useEffect(() => {
    if (divRef?.current) {
        divRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  }, [])

  const setExtendedChat = useSetRecoilState(extendedChatAtom);
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setOptionSelected("")
  };
  const handleCSVModal = () => {
    setModal(false);
  };
  const handleOptionsClick = (optionName) => {
    setOptionSelected(optionName)
    setIsModalVisible(true);
  };
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const starOptions = [
    {
      label: "Enrich",
      icon: <RiseOutlined className="mr-2"/>,
    },
    {
      label: "Deduplicate",
      icon: <BlockOutlined className="mr-2"/>,
    },
    {
      label: "Find look-a-likes",
      icon: <BankOutlined className="mr-2"/>,
    },
    {
      label: "Add people",
      icon: <IdcardOutlined  className="mr-2"/>,
    },
    {
      label: "Validate",
      icon: <SafetyCertificateOutlined className="mr-2"/>,
    },
    {
      label: "Find parent/child",
      icon: <ClusterOutlined className="mr-2"/>,
    },
    {
      label: "Formula",
      icon: <ExperimentOutlined className="mr-2"/>,
    },
  ];
  const filteredStarOptions = starOptions.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );
  const starMenu = (
    <Menu>
      <Menu.Item>
        <Search
          placeholder="Search"
          onSearch={value => setSearchText(value)}
          allowClear
          style={{
            width: "100%",
            marginBottom: "10px",
          }}
        />
      </Menu.Item>
      {filteredStarOptions.map(option => (
        <Menu.Item onClick={() => handleOptionsClick(option.label)} key={option.label}>
          {option.icon}
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  const plusOptions = [
    {
      label: "Salesforce",
      icon: faSalesforce,
    },
    {
      label: "HubSpot",
      icon: faHubspot,
    },
    {
      label: "Unified Data",
      icon: faDatabase,
    },
    {
      label: "Favorite Columns",
      icon: faStar,
    },
  ];

  const filteredPlusOptions = plusOptions.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );

  const plusMenu = (
    <Menu>
      <Menu.Item>
        <Search
          placeholder="Search"
          onSearch={value => setSearchText(value)}
          allowClear
          style={{
            width: "100%",
            marginBottom: "10px",
          }}
        />
      </Menu.Item>
      {filteredPlusOptions.map(option => (
        <Menu.Item key={option.label}>
          <FontAwesomeIcon icon={option.icon} className="mr-2" />
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setRowsSelected(selectedRows?.length);
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          query_id: localStorage.getItem("extended_query_id")
        };
        const response = await getQueryReport(payload);
        if (response.data.status === "success") {
          const data = response.data.result;
          setData(data)
        } else {
          message.error("Error fetching dashboard data");
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        message.error("Error fetching dashboard data");
      }
    };
    if(localStorage.getItem("extended_query_id") == "FILE_CC"){

    } else {
      fetchData();
    }
  }, []);
  const minimizeChat = () => {
    setExtendedChat(false)
    setChatMode(0)
    setSideNavContent(0)
  }
  const downloadChartAsImage = (id) => {
    const chartElement = document.getElementById(id);
    console.log(chartElement, "-----sdfsf------");
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'chart.png';
        link.click();
        message.success("Image downlaoded successfully")
      });
    }
  };
  const handleProgressBar =() => {
    if ( progress < 100) {
      const interval = setInterval(() => {
        setProgress((prev) => prev + 16);
        
      }, 1300);      
      return () => clearInterval(interval);
    }
  }
  useEffect(() => {
    if (modal) {
      handleProgressBar()
    }
  }, [modal])

  return (
    <>
        <Modal
          title=""
          visible={modalVisible}
          onCancel={handleCloseModal}
          footer={null}
          className={optionSelected}
        >
          {optionSelected == 'Enrich' && <EnrichOption />}
          {optionSelected == 'Deduplicate' && <DeduplicateOption />}
          {optionSelected == 'Find look-a-likes' && <FindLookALikeOption />}
          {optionSelected == 'Add people' &&  <AddPeopleOption />}
          {optionSelected == 'Validate' &&  <ValidateOption/>}
          {optionSelected == 'Find parent/child' &&  <FindParentChildOption/>}

          
        </Modal>
        {/* <Modal
          title=""
          visible={modal}
          onCancel={handleCSVModal}
          footer={null}
          className=""
          centered
        >
          <div className="h-[40vh] flex flex-col justify-center items-center gap-4">
            {progress >= 100 ? (
              <div className="">
                <CopSuccessComponent setModal={setModal}/>
              </div>
            ) : (
             <>
              <Progress percent={progress} />
              <p className="text-xl text-[#2966BC] font-bold text-center">Matched & Enriched Contacts from Event CSV syncing with Salesforce</p>
             </> 
            )}
          </div>
        </Modal> */}
      <div className="flex justify-between items-center gap-2 mr-4 border border-2 border-black p-2 rounded-md">
        <>{chatMode == 1 ? "Summer Event 2024" : " "}</>
      <div className="flex gap-2">
      <div className="-mr-1 mb-0.5 relative" ref={divRef}>
                <img
                  src={shareImg}
                  alt="shareImg"
                  width={24}
                  className={`hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] ${
                    filterVisible && "border border-white border-b-[#E07E65]"
                  }`}
                  onClick={() => setFilterVisible(!filterVisible)}
                />
                {filterVisible && (
                  <div className="absolute -bottom-[8.5rem] -right-[4.5rem] z-50 flex flex-col gap-1 justify-center items-center w-[900%] border bg-gray-300 p-2">
                    <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                      Save to Dashboard
                    </div>
                    <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                      Generate Embeddable Link
                    </div>
                    <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                      Send on Slack
                    </div>
                    <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                      Send Via Email
                    </div>
                  </div>
                )}
              </div>
              <img
                  src={glitterImg}
                  alt="glitterImg"
                  width={20}
                  className={`hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] h-5 mt-0.5 ${
                    magic && "border border-white border-b-[#E07E65]"
                  }`}
                  onClick={() => setMagic(!magic)}
                />
                {magic && (
                  <div className="absolute top-36 right-10 z-50 flex flex-col gap-1 justify-center items-center w-[20%] bg-white border p-2 shadow-md rounded-md">
                    <GlitterDropdown />
                  </div>
                )}
              {/* <img
                src={graphImg}
                alt="graphImg"
                width={22}
                className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
              /> */}
              <PieChartFilled
                className={`-mr-1 mb-0.5 hover:scale-105 p-1 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] hover:p-1 ${
                  chartVisible && "border border-white border-b-[#E07E65] p-1"
                }`}
                onClick={() => setChartVisible(!chartVisible)}
              />
              <img
                src={downloadImg}
                alt="downloadImg"
                width={26}
                className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
                onClick={() => downloadChartAsImage()}
              />
              <ShrinkOutlined
                className="text-[14px] p-1 -ml-1 hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] hover:p-1"
                onClick={() => minimizeChat(false)}
              />
      </div>
      </div>
          <div className="w-full flex justify-center items-center">
            {localStorage.getItem("extended_query_id") == "FILE_CC" ? 
              <Card className="w-[95%] relative mt-2">
                <div className="" style={{overflowY: "scroll"}}>
                  <Table className='w-full'
                    rowSelection={rowSelection}
                    style={{ overflowX: "scroll" }}
                    dataSource={pocTableData}
                    columns={pocTableCols}
                    pagination={true}
                    />
                </div>
              </Card> :            
              <Card className="w-[95%] relative mt-2">
                {chartVisible ? 
                <>
                  {/* <div className="absolute right-5 top-2">
                    <CloseOutlined />
                  </div> */}
                  {
                    data?.chart_type == 'python' && 
                    <div id={data?.report_id} className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md">
                      <img className="w-[75%]" src={data?.chart_data} alt="Generated by Python" />
                    </div>
                  }
                  {/* <div id={chat?.query_id} className="flex justify-start items-center flex-wrap gap-4 text-base w-[90%] rounded-md mt-4 p-4 ml-[74px]">
                    <PythonExecutor code={chat.charts} query_id= {chat.query_id} />
                  </div> */}
              
              <div className="flex justify-between items-center w-full border border-black px-4 py-2 rounded-md">
                <div className="flex justify-center items-center gap-4">
                  <p>
                    {rowsSelected}/{2} Rows Selected
                  </p>
                  <p>2 Columns</p>
                  <Dropdown
                    overlay={plusMenu}
                    trigger={['click']}
                    visible={plusOptionsVisible}
                    onVisibleChange={setPlusOptionsVisible}
                  >
                    <PlusOutlined className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out" />
                  </Dropdown>
                  <Search
                    placeholder="Search"
                    onSearch={onSearch}
                    allowClear
                    className="extendSearch"
                    style={{
                      width: 200,
                    }}
                  />
                </div>
                <div className="flex justify-center items-center gap-2">
                  <UndoOutlined rotate={90} className="mr-1" />
                  <RedoOutlined rotate={270} className="mr-1" />
                  <Dropdown
                    overlay={starMenu}
                    trigger={['click']}
                    visible={starOptionsVisible}
                    onVisibleChange={setStarOptionsVisible}
                  >

                  <img className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out" src={glitterImg} alt="glitterImg" width={16} />
                  </Dropdown>
                  <img src={funnelImg} alt="funnelImg" width={14} />
                  <img src={overlapImg} alt="overlapImg" width={20} />
                </div>
              </div>
                {data?.sql_query &&
                <div className="h-[250px]" style={{overflowY: "scroll"}}>
                  <DisplayTableComponent postgres_query={data?.sql_query} minimized={true}/>
                </div>
                }
                </>
                :
                <>              
                  {data?.sql_query &&
                  <div className="" style={{overflowY: "scroll"}}>
                    <DisplayTableComponent postgres_query={data?.sql_query} minimized={false}/>
                  </div>
                  }
                </>
                }
              </Card>
            }
          </div>
    </>
  );
};

export default ExtendedChat;
