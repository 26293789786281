import React, { useState, useEffect } from "react";
import { ClockCircleOutlined, CheckOutlined } from "@ant-design/icons";

const EnrichmentTimer = ({ duration, onComplete, content }) => {
  const [timeProcessed, setTimeProcessed] = useState(0.0);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (!content) {
      const timerId = setInterval(() => {
        setTimeProcessed(prevTime => parseFloat((prevTime + 0.01).toFixed(2)));
      }, 10); // 10 milliseconds
  
      // Cleanup interval on component unmount
      return () => clearInterval(timerId);
    } else {
      setIsCompleted(true);
      if (onComplete) {
        onComplete();
      }
    }
  }, [timeProcessed]);

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center justify-center bg-gray-100 rounded-xl gap-1 px-2 py-1 text-gray-500 text-[11px]">
        <ClockCircleOutlined />
        <p>{timeProcessed} s</p>
      </div>
      {isCompleted && (
        <div className="flex items-center justify-center py-1 px-2 text-[10.5px] text-green-700 bg-green-200 gap-1 rounded-xl">
          <CheckOutlined />
          <p>Success</p>
        </div>
      )}
    </div>
  );
};

export default EnrichmentTimer;
