import React, { useRef, useEffect, useState } from 'react';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, FunnelChart, Funnel, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList } from 'recharts';
import Plot from 'react-plotly.js';
const VisualGraph = ({ chat, index }) => {

    const renderChartMain = (selectedChartType, chartData, showReferenceLine, xAxis, yAxis, chartColor, showValues) => {
        switch (selectedChartType) {
            case 'Bar':
                return (
                    <BarChart
                        width={1000} // Increased width
                        height={500}
                        data={chartData}
                        margin={{ top: 20, right: 30, left: 40, bottom: 30 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
                        <XAxis
                            dataKey={xAxis}
                            tick={{
                                angle: -60, // Increased angle for better fitting
                                fontSize: 10,
                                width: 100, // Increased width for labels
                                interval: 0, // Show all labels
                                textAnchor: 'end', // Align text to the end
                                formatter: (value) => {
                                    const stringValue = String(value);
                                    return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                                },
                            }}
                            height={100} // Further increased height for more space
                        />
                        <YAxis tick={{ fontSize: 10 }} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey={yAxis} fill={chartColor}>
                            {showValues && (
                                <LabelList
                                    dataKey={yAxis}
                                    position="top" // Move labels outside the bars
                                    formatter={(value) => {
                                        const stringValue = String(value);
                                        return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                                    }}
                                    style={{ fontSize: 10 }} // Adjust font size if necessary
                                />
                            )}
                        </Bar>
                    </BarChart>

                );
            case 'Column':
                return (
                    <BarChart width={1000} height={500} data={chartData} layout="vertical" margin={{ top: 20, right: 30, left: 40, bottom: 30 }}>
                        <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
                        <XAxis type="number" tick={{ fontSize: 10 }} />
                        <YAxis dataKey={xAxis} type="category" tick={{ fontSize: 8 }} width={100} /> {/* Adjust width for longer labels */}
                        <Tooltip />
                        <Legend />
                        <Bar dataKey={yAxis} fill={chartColor}>
                            {showValues && (
                                <LabelList
                                    dataKey={yAxis}
                                    position="right" // Options: 'insideLeft', 'insideRight', 'right'
                                    formatter={(value) => {
                                        const stringValue = String(value); // Ensure value is a string
                                        return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                                    }}
                                    style={{ fontSize: 10 }} // Adjust font size if necessary
                                />
                            )}
                        </Bar>
                    </BarChart>

                );
            case 'Stacked Bar':
                return (
                    <BarChart width={600} height={300} data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
                        <XAxis dataKey={xAxis} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="age" stackId="a" fill="#8884d8">
                            {showValues && <LabelList dataKey="age" position="top" />}
                        </Bar>
                        <Bar dataKey="salary" stackId="a" fill={chartColor}>
                            {showValues && <LabelList dataKey="salary" position="top" />}
                        </Bar>
                    </BarChart>
                );
            case 'Stacked Column':
                return (
                    <BarChart width={600} height={300} data={chartData} layout="vertical">
                        <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
                        <XAxis type="number" />
                        <YAxis dataKey={xAxis} type="category" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="age" stackId="a" fill="#8884d8">
                            {showValues && <LabelList dataKey="age" position="right" />}
                        </Bar>
                        <Bar dataKey="salary" stackId="a" fill={chartColor}>
                            {showValues && <LabelList dataKey="salary" position="right" />}
                        </Bar>
                    </BarChart>
                );
            case 'Line':
                return (
                    <LineChart width={1000} height={500} data={chartData} margin={{ top: 20, right: 30, left: 40, bottom: 30 }}>
                        <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
                        <XAxis dataKey={xAxis} />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey={yAxis} stroke={chartColor} />
                    </LineChart>
                );
            case 'Donut':
                return (
                    <PieChart width={800} height={800}>
                        <Pie
                            data={chartData}
                            dataKey={yAxis}
                            nameKey={xAxis}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill={chartColor}
                            label={showValues}
                        />
                    </PieChart>
                );
            case 'Funnel':
                return (
                    <FunnelChart width={1000} height={550}>
                        <Tooltip />
                        <Funnel
                            dataKey={yAxis}
                            data={chartData}
                            isAnimationActive
                            fill={chartColor}
                        >
                            {chartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={chartColor} />
                            ))}
                        </Funnel>
                    </FunnelChart>
                );
            case 'Scatter Plot':
                return (
                    <ScatterChart width={1000} height={550}>
                        <CartesianGrid vertical={showReferenceLine} horizontal={showReferenceLine} />
                        <XAxis dataKey={xAxis} />
                        <YAxis dataKey={yAxis} />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Legend />
                        <Scatter name="Scatter Plot" data={chartData} fill={chartColor} />
                    </ScatterChart>
                );
            case 'plotly':
                const { data, layout } = JSON.parse(chartData[0])
                const updatedLayout = { ...layout, paper_bgcolor: "#fffff" }
                console.log(data, '-------------------------------------')
                return (
                    <Plot
                        className='w-full h-[400px]'
                        data={data}
                        layout={updatedLayout}
                        config={{ responsive: true }}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <>
            <div id={chat?.query_id} className="flex justify-start items-center flex-wrap gap-4 text-base w-[90%] rounded-md mt-4 p-4 ml-[74px]">
                {renderChartMain(chat?.chartType, chat?.data, chat?.showGrid, chat?.x_Axis, chat?.y_Axis, chat?.color, chat?.showValues)}
            </div>
        </>
    );
};

export default VisualGraph;
