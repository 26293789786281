import { atom } from "recoil";

const activeTabAtom = atom({
    key: "activeTab",
    default: 1
})

const dropAtom = atom({
    key: "drop",
    default: "Month"
})

export {
    activeTabAtom,
    dropAtom
}