import { useCallback } from "react";
import { Handle, Position } from "@xyflow/react";
import ProgHead from "./ProgHead";
import ProgIOMain from "./ProgIOMain";
import ProgMain from "./ProgMain";

const handleStyle = { left: 20 };

function ProgIONode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
    console.log(data);
  }, []);

  return (
    <div className={`border border-[#eee] rounded-[5px] bg-white ${data?.id === "node-6" ? 'min-w-[38rem]' : 'min-w-[20rem]'} max-w-[40rem]`}>
      {data?.id !== "node-1" && (
        <Handle
          type="target"
          position={data?.id === "node-4" ? Position.Left : Position.Top}
          isConnectable={isConnectable}
          id="a"
          style={data?.id === "node-6" ? { left: 185 } : null}
        />
      )}
      {data?.id === "node-6" && (
        <>
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 14 }}
            id="b"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 72 }}
            id="c"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 130 }}
            id="d"
          />
          {/* <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 244 }}
            id="e"
          /> */}
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 300 }}
            id="f"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 372 }}
            id="g"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 430 }}
            id="h"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 494 }}
            id="i"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 550 }}
            id="j"
          />
        </>
      )}

      <div className="flex flex-col justify-start items-start">
        <ProgHead data={data} />
        {data?.data?.firstKey === true ? (
          <ProgIOMain data={data} />
        ) : !data?.data?.firstKey ? (
          <></>
        ) : (
          <ProgMain data={data} />
        )}
      </div>
      {data?.id !== "node-3" && (
        <Handle
          type="source"
          position={data?.id === "node-4" ? Position.Bottom : Position.Right}
          id="b"
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
}

export default ProgIONode;