import React from 'react';
import { Button, Card } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { sideNavContentAtom, chatModeAtom } from '../../store/atoms/MainAtoms';
// import { chatArrayAtom } from '../../store';
import { toastChatArrayAtom } from '../../store';
import { useRecoilValue, useRecoilState , useSetRecoilState} from 'recoil';
import { useNavigate } from 'react-router-dom';

const NewSegmentComponent = () => {
    const setChatArray = useSetRecoilState(toastChatArrayAtom)
    const setSidenav = useSetRecoilState(sideNavContentAtom)
    const setChatMode = useSetRecoilState(chatModeAtom);

    const navigate = useNavigate()
    const cardData = [
        {
            title: "Churned Lost Champions",
            description: "These are your high spending customers that you've lost recently but are still logging in and likely to make another purchase if re-engaged through paid channels.",
            number: "42,722"
        },
        {
            title: "Churned Regulars",
            description: "These are your frequent customers that have been lost recently but are still engaging with your marketing emails. They are likely to come back with a coupon offer sent over email.",
            number: "25,723"
        },
        {
            title: "Lost Laggards",
            description: "These are your lost customers that purchased infrequently, but in high priority categories. You can engage them with your most popular products through push notification.",
            number: "12,449"
        },
        {
            title: "Electronics Upgraders",
            description: "Customers that haven't purchased in the past 12 months but are likely to need an electronics upgrade based on their previous purchase behavior in the category.",
            number: "13,770"
        }
    ];
    const openStudio = async () => {
        navigate("/audiences/segment-studio");
        setSidenav(1)
        setChatArray([
            {
              answer:
                "What is your goal?",
            },
          ]
        )
        setChatMode(1)
    };
    return (
        <div className='mt-4' style={{ display: 'flex' }}>
            <div style={{ flex: 1, padding: 24 }}>
                {/* Top Card with Segments heading, button, and texts */}
                <Card className='w-[45%]' style={{ marginBottom: 24 }}>
                    <div className='flex justify-between mb-2'>
                        <span style={{ fontSize: '24px', color: '#8EA9D0' }}>Segments</span>
                    </div>
                    <Button onClick={() => openStudio()} type="primary">
                        Segment Studio
                    </Button>
                    <div className='flex-col flex mt-4'>
                        <span className='text-[15px] my-[4px]'><TeamOutlined style={{ color: '#2966BC' }} /> Start with the Basics</span>
                        <span className='text-[15px] my-[4px]'><TeamOutlined style={{ color: 'red' }} /> Build with a Template</span>
                        <span className='text-[15px] my-[4px]'><TeamOutlined style={{ color: 'red' }} /> Build from Scratch</span>
                    </div>
                </Card>

                {/* AI Suggested Segments */}
                <div>
                    <h2 className='my-2' style={{ fontSize: '24px', color: '#8EA9D0' }}>AI Suggested Segments</h2>
                    <h3 className='my-2' style={{ fontSize: '18px' }}>Reduce Churn</h3>
                    <p>Segments to target with high likelihood to reduce churn.</p>

                    <div className='flex flex-wrap mt-4'>
                        {cardData.map((card, index) => (
                            <Card key={index} title={card.title} style={{ width: '30%', marginBottom: '16px', marginRight: '16px' }}>
                                <p>{card.description}</p>
                                <div className="flex justify-between mt-2">
                                    <span className='text-[16px] text-[bold] text-[#2966BC]'>{card.number}</span>
                                    <Button type="primary">Preview</Button>
                                </div>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewSegmentComponent;
