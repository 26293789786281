import React, { useEffect } from "react";
import { PlusCircleOutlined, ArrowUpOutlined, PaperClipOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Input, message, Button } from "antd";
import { jwtDecode } from "jwt-decode";
import chatData from './chatData.json';
import chatData2 from './chatData2.json';
import chatData3 from './chatData3.json';
import chatData4 from './chatData4.json';
import chatData5 from './chatData5.json';
import { chatresponse } from "../../services/chatServices";
import { useRecoilValue } from "recoil";
import {
  toastRowsAtom,
  toastInputFilledAtom,
  toastQuestionAtom,
  toastChatArrayAtom,
  toastLoadingAtom,
  toastIsFollowupClickedAtom,
  activeToastComponentAtom,
} from "../../store";
import { chatModeAtom } from "../../store/atoms/MainAtoms";

import { useRecoilState, useSetRecoilState } from "recoil";

const { TextArea } = Input;

const InputBoxToast = ({ siderWidth }) => {
  const [rows, setRows] = useRecoilState(toastRowsAtom);
  const [inputFilled, setInputFilled] = useRecoilState(toastInputFilledAtom);
  const [question, setQuestion] = useRecoilState(toastQuestionAtom);
  const [chatArray, setChatArray] = useRecoilState(toastChatArrayAtom);
  const chatMode = useRecoilValue(chatModeAtom)
  const [loading, setLoading] = useRecoilState(toastLoadingAtom);
  const [isFollowupClicked, setIsFollowupClicked] = useRecoilState(
    toastIsFollowupClickedAtom
  );
  const setActiveToastComponent = useSetRecoilState(activeToastComponentAtom);

  const handleInputChange = (e) => {
    if (e.target.value.trim() === "") {
      setInputFilled(false);
    } else {
      setInputFilled(true);
    }
    const lines = e.target.value.split("\n").length;
    const newRows = Math.min(lines, 5);
    setRows(newRows || 1);
    setQuestion(e.target.value);
  };

  const handleSend = async () => {
    if (inputFilled) {
      // setChatArray((prevChatArray) => [
      //   ...prevChatArray,
      //   {
      //     question: question,
      //   },
      // ]);
      // setLoading(true);
      setLoading(true)
      setQuestion(null);
      setInputFilled(false);
      if (question === "What data was captured for this POC") {
        setLoading(false)
        setChatArray([...chatArray, {
          question,
          answer: "Data Captured:\nFor each of the 121 restaurants in Boston, we've collected the following information:\n > • Basic details: Name, address, phone number, website\n> • Cuisine type\n> • Price range\n> • Operating hours\n> • Customer ratings and reviews (from Yelp and Google)\n> • Social media presence (Facebook, Instagram, Twitter handles)\n> • Special features (e.g., outdoor seating, delivery options, etc.)\n> • Popular dishes (based on review analysis)\n> • Busiest hours and days (based on check-in data and reviews)\n> • Menu\n> • $$$ Sign - Expensive or moderate",
          suggestions: ["Key Insights", "Data Sources", "Monitoring"]
        }])
      } else if (question === "Data Source for this POC") {
        setLoading(false)
        setChatArray([...chatArray, {
          question,
          answer: "Data Sources for this POC are:\n> • Google\n> • Yelp\n> • Website",
          suggestions: ["Key Insights"]
        }])
      }
      else if (question === "Key Insights") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "Key Insights",
          answer: "We have identified 5 Key areas of Insights based on these 121 restaurant public information",
          suggestions: ["Segment", "Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness"]
        }])
      }
      else if (question === "Segment") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "Segment",
          answer: "Here are few suggested Questions:",
          suggestions: ["What are the key types of restaurant in this market?", "Can you give me insights on restaurant seasonal demand?", "What's the most popular dish across this market?"]
        }])
      }
      else if (question === "What are the key types of restaurant in this market?") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "What are the key types of restaurant in this market?",
          answer: "Well below is the graph of types of Restaurant in this market",
          charts: true,
          toastChartWidthClassName: 'w-1/2 h-[40vh]',
          tileName: "Restaurant Types",
          toastChartData: {
            labels: chatData3.map((data) => data.type),
            datasets: [
              {
                label: "No. of Restaurants",
                data: chatData3.map((data) => data.number),
                backgroundColor: ["#73D297"],
                borderRadius: 0,
                // categoryPercentage: 0.1,
                // barPercentage: 2.0,
              }
            ],
          },
          toastChartOptions: {
            scales: {
              x: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Types of Restaurants",
                },
              },
              y: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Number of Restaurants",
                },
              },
            },
          },
          suggestions: ["Can you give me insights on restaurant seasonal demand?", "What's the most popular dish across this market?", "Other Insights"]
        }])
      }
      else if (question === "Can you give me insights on restaurant seasonal demand?") {
        setLoading(false)
        setChatArray([...chatArray, {
          question,
          answer: "Certainly! I don’t have direct access to the purchase data but we can infer seasonality through the number of reviews posted by season.\nHere is a breakdown of number of reviews submitted by season:",
          charts: true,
          toastChartWidthClassName: 'w-1/2 h-[40vh]',
          tileName: "Seasonal Demand",
          toastChartData: {
            labels: chatData2.map((data) => data.label),
            datasets: [
              {
                label: "Google",
                data: chatData2.map((data) => data.google),
                backgroundColor: ["#73D297"],
                borderRadius: 0,
                // categoryPercentage: 0.1,
                // barPercentage: 2.0,
              },
              {
                label: "Yelp",
                data: chatData2.map((data) => data.yelp),
                backgroundColor: ["#5A9BD4"],
                borderRadius: 0,
                // categoryPercentage: 0.1,
                // barPercentage: 4.0,
              },
            ],
          },
          toastChartOptions: {
            scales: {
              x: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Number of Reviews by Season: Yelp vs Google",
                },
              },
              y: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Number of Reviews",
                },
              },
            },
          },
          suggestions: ["What's the most popular dish across this market?", "Other Insights"]
          // suggestions: ["Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness", "Value for Money", "Tracking"]
        }])
      }
      else if (question === "What's the most popular dish across this market?") {
        setChatArray([...chatArray, {
          question,
          answer: "The most popular dishes include\n> •  'lemongrass pork dumplings',\n> •  'scallion pancake sandwich',\n> •  'vegan five spice tofu dumplings',\n> •  'double awesome sandwich',\n> •  'dumpling party'",
          suggestions: ["Recommendation", "Other Insights"]
          // suggestions: ["Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness", "Value for Money", "Tracking"]
        }])
      }
      else if (question === "Monitoring") {
        setChatArray([...chatArray, {
          question: "I am monitoring Aceituna Grill on an ongoing basis, help me with my latest insights",
          answer: "Sure, below are the categories for monitoring",
          // suggestions: ["What are the key types of restaurant in this market?", "Can you give me insights on restaurant seasonal demand?", "Other Insights"]
          suggestions: ["Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness", "Value for Money"]
        }])
      }
      else if (question === "Sentiment" || question === "How do my ratings compare with the average in my area?") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "How do my ratings compare with the average in my area?",
          answer: "Your restaurant has an average of 3.55 which is 0.35 lower than the average 3.90.\nHere’s a breakdown of where your reviews stand compared to restaurants in your area:",
          charts: true,
          toastChartWidthClassName: 'w-full h-[40vh]',
          tileName: "Ratings Tracker",
          toastChartData: {
            labels: chatData.map((data) => data.label),
            datasets: [
              {
                label: "Distribution of Yelp Ratings for Boston Restaurants",
                data: chatData.map((data) => data.restaurants),
                backgroundColor: chatData.map((_, index) => index === 8 ? "#6999CF" : "#73D297"),
                borderRadius: 0,
                categoryPercentage: 0.1,
                barPercentage: 10.0,
              },
              {
                label: "Your Rating",
                data: chatData.map((data) => data.yelp),
                backgroundColor: ["#5A9BD4"],
                borderRadius: 0,
                categoryPercentage: 0.1,
                barPercentage: 0.0,
              },
            ],
          },
          toastChartOptions: {
            scales: {
              x: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Rating Range",
                },
              },
              y: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Number of Restaurants",
                },
              },
            },
          },
          suggestions: ["Would you like to see how your ratings have trended over time?", "Food Quality", "Service Quality", "Ambience and Cleanliness", "Value for Money"]
        }])
      } else if (question === "Would you like to see how your ratings have trended over time?") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "How my ratings have trended over time?",
          answer: "Here is how your ratings have trended over time:",
          charts: true,
          toastChartWidthClassName: 'w-full h-[40vh]',
          tileName: "Ratings Trend",
          toastChartData: {
            labels: chatData5.map((data) => data.label),
            datasets: [
              {
                label: "Rating vs. Year",
                data: chatData5.map((data) => data.rating),
                backgroundColor: ["#73D297"],
                borderRadius: 0,
                // categoryPercentage: 0.1,
                // barPercentage: 10.0,
              }
            ],
          },
          toastChartOptions: {
            scales: {
              x: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Year",
                },
              },
              y: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Rating",
                },
              },
            },
          },
          // img: "reviewRating",
          // toastChartMessage: "Shows time series of restaurant reviews where X axis equals Month-Year and Y Axis equals average rating for that time period and gives synopsis of the trend.",
          suggestions: ["How do my ratings compare with the average in my area?", "Food Quality", "Service Quality", "Ambience and Cleanliness", "Value for Money"]
        }])
      } else if (question === "Food Quality") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "How does my food compare with restaurants in my area?",
          answer: "Here are the top dishes mentioned in positive and negative reviews compared to the to restaurants in your area:",
          table: true,
          tileName: "Food Comparison",
          tableColumns: [
            {
              title: "(Aceituna Grill) Top Positive Reviewed Dishes",
              dataIndex: "one",
            },
            {
              title: "(Aceituna Grill) Top Negative Reviewed Dishes",
              dataIndex: "two",
            },
            {
              title: "Boston Restaurants Top Positive Dishes",
              dataIndex: "three",
            },
            {
              title: "Boston Restaurants Top Negative Dishes",
              dataIndex: "four",
            },
          ],
          tableDatasource: [
            { one: "greek salad plate (12)", two: "arancini (8)", three: "lemongrass pork dumplings (32)", four: "chiken rice (28)" },
            { one: "fried cauliflower (8)", two: "short rib lasagna (5)", three: "scallion pancake sandwich (21)", four: "chicken shawarma (18)" },
            { one: "spicy chicken rice bowl (6)", two: "tiramisu (5)", three: "vegan five spice tofu dumplings (18)", four: "fattoush salad (13)" },
          ].map((data, index) => ({
            key: index,
            ...data,
          })),
          suggestions: ["Service Quality", "Sentiment", "Ambience and Cleanliness", "Value for Money"]
        }])
      } else if (question === "Service Quality") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "What about service quality? What do customers say about the service provided by the staff?",
          answer: "There are 350 mentions of service quality in 420 positive reviews and 120 mentions of service quality in 200 negative reviews",
          // charts: true,
          toastChartMessage: "Show two side by side pie charts",
          tileName: "Service Quality",
          chartMessage: "Strategies to Improve Service Quality and Address Negative Reviews\n1) Comprehensive Staff Training\n > • Focus on friendly interactions and product knowledge.\n2) Efficiency Enhancements\n  > • Streamline processes and integrate technology to reduce wait times.\n3) Regular Feedback Analysis\n > • Identify and address recurring issues from customer feedback.\n4) Enhance Dining Experience\n   > • Create a pleasant ambience and personalize service.\n5) Apologies and Compensation\n    > • Offer sincere apologies and compensations for service lapses.\n6) Initiatives\n   > • Monthly service workshops.\n    > • Customer feedback cards for immediate feedback.\n   > • Recognition programs to reward exceptional service.",
          table: true,
          tableColumns: [
            {
              title: "Positive Service Phrases",
              dataIndex: "one",
            },
            {
              title: "Negative Service Phrases",
              dataIndex: "two",
            },
            {
              title: "Positive Reviews",
              dataIndex: "three",
            },
            {
              title: "Negative Reviews",
              dataIndex: "four",
            },
          ],
          tableDatasource: [
            { one: "The staff was incredibly friendly and welcoming.", two: "Service was slow and inattentive.", three: "Food is incredible, super fresh.", four: "Customer service is horrible, rude staff." },
            { one: "Excellent service from start to finish.", two: "The staff was rude and unhelpful.", three: "Always fresh, flavorful, and delicious.", four: "Food is just okay, overpriced." },
            { one: "Highly recommend for their prompt and courteous service.", two: "Long wait times and poor service.", three: "Healthy, fast, and efficient service.", four: "Always crowded, long wait times." },
            { one: "The waitstaff was attentive and efficient.", two: "Unfriendly staff, made the experience unpleasant.", three: "Delicious food, generous portions.", four: "Out of several items on the menu." },
            { one: "Great service, the staff went above and beyond.", two: "The service was inconsistent and disappointing.", three: "Good flavors, great sauces, quick service.", four: "Dirty tables and sticky floors." },
          ].map((data, index) => ({
            key: index,
            ...data,
          })),
          // toastTableMessage: "Show similar table as above",
          suggestions: ["Ambience and Cleanliness", "Value for Money", "Sentiment", "Food Quality"]
        }])
      } else if (question === "Ambience and Cleanliness") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "What about ambience and cleanliness ratings?",
          answer: "1) Ambience \n > •  There are 145 mentions of ambience in 180 positive reviews and 30 mentions of ambience in 70 negative reviews. \n \n 2) Cleanliness \n > •  There are 120 mentions of cleanliness in 150 positive reviews and 25 mentions of cleanliness in 60 negative reviews.",
          // charts: true,
          // toastChartMessage: "Show two side by side pie charts",
          chartMessage: "Ambience\n 1) Consistent Theme and Decor: \n > •  Ensure that the restaurant’s decor is consistent and aligns with the theme. This includes furniture, lighting, wall art, and overall aesthetic.\n > •  Regularly update and maintain the decor to keep it fresh and appealing. \n \n 2) Lighting and Music: \n > •  Adjust lighting to create a comfortable and inviting atmosphere. Avoid overly bright or dim settings. \n > •  Curate a playlist that matches the restaurant’s vibe and plays at a volume that enhances the dining experience without being intrusive. \n Cleanliness \n 1) Regular Cleaning Schedules: \n > •  Implement and strictly follow regular cleaning schedules for all areas, including dining areas, restrooms, kitchens, and entryways. \n > •  Ensure tables, chairs, and other surfaces are wiped down after each use. \n \n 2) Kitchen and Food Prep Areas: \n > •  Maintain high standards of cleanliness in the kitchen and food preparation areas. Regularly sanitize surfaces and equipment. \n > •  Ensure all staff follow proper hygiene protocols, including wearing clean uniforms and washing hands frequently. \n \n 3) Pest Control: \n > •  Conduct regular pest control checks and treatments to prevent infestations. Ensure no food or waste is left out that could attract pests.",
          table: true,
          tileName: "Ambiance & Cleanliness",
          tableColumns: [
            {
              title: "(Aceituna Grill) Positive Ambiance Keywords",
              dataIndex: "one",
            },
            {
              title: "(Aceituna Grill) Negative Ambiance Keywords",
              dataIndex: "two",
            },
            {
              title: "(Aceituna Grill) Positive Cleanliness Keywords",
              dataIndex: "three",
            },
            {
              title: "(Aceituna Grill) Negative Cleanliness Keywords",
              dataIndex: "four",
            },
            {
              title: "(Overall Restaurants) Positive Ambiance Keywords",
              dataIndex: "five",
            },
            {
              title: "(Overall Restaurants) Negative Ambiance Keywords",
              dataIndex: "six",
            },
            {
              title: "(Overall Restaurants) Positive Cleanliness Keywords",
              dataIndex: "seven",
            },
            {
              title: "(Overall Restaurants) Negative Cleanliness Keywords",
              dataIndex: "eight",
            },
          ],
          tableDatasource: [
            { one: "Intimate Setting", two: "Overcrowded", three: "Exceptionally clean", four: "Filthy", five: "Cozy", six: "Cramped", seven: "Spotless", eight: "Dirty" },
            { one: "Inviting Atmosphere", two: "Loud", three: "Highly Hygienic", four: "Cluttered", five: "Inviting", six: "Noisy", seven: "Hygienic", eight: "Messy" },
            { one: "Pleasant Ambiance", two: "Dimly Lit", three: "Well-kept", four: "Sticky Surfaces", five: "Pleasant Atmosphere", six: "Poorly Lit", seven: "Well-maintained", eight: "Sticky" },
            { one: "Cozy Seating", two: "Outdated design", three: "Spotless", four: "Unsanitary", five: "Comfortable", six: "Outdated decor", seven: "Immaculate", eight: "Unsanitary" },
            { one: "Soothing Environment", two: "Off-putting odor", three: "Immaculate", four: "Dirty Bathrooms", five: "Relaxing", six: "Unappealing smell", seven: "Pristine", eight: "Unclean restrooms" },
          ].map((data, index) => ({
            key: index,
            ...data,
          })),
          // toastTableMessage: "Show similar table as above",
          suggestions: ["Value for Money", "Sentiment", "Food Quality", "Service Quality"]
        }])
      } else if (question === "Value for Money") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "How does my restaurant stack up when it comes to price within my area?",
          answer: "Your restaurant prices are $12.95 to $15.95 for main dishes and around $6.49 to $15.95 for sides and salads. Compared to the average prices of $10-$18 for similar dishes at Tatte Bakery & Cafe and $12-$20 at Noor Mediterranean Grill, it would seem that your prices are competitive and reasonable compared to restaurants in your area.",
          charts: true,
          toastChartWidthClassName: 'w-full h-[40vh]',
          tileName: "Value for Money",
          toastChartData: {
            labels: chatData4.map((data) => data.label),
            datasets: [
              {
                label: "$",
                data: chatData4.map((data) => data.restaurants),
                backgroundColor: chatData4.map((_, index) => index === 8 ? "#6999CF" : "#73D297"),
                borderRadius: 0,
                // categoryPercentage: 0.1,
                // barPercentage: 10.0,
              },
            ],
          },
          toastChartOptions: {
            scales: {
              x: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Price Category",
                },
              },
              y: {
                grid: {
                  display: false, // Disable x-axis grid lines
                },
                title: {
                  display: true,
                  text: "Count of restaurants by",
                },
              },
            },
          },
          suggestions: ["Recommendation", "Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness"]
        }])
      }
      // else if (question === "Evaluate further") {
      //   setLoading(false)
      //   setChatArray([...chatArray, {
      //       question: "Now, let's evaluate the value for money. Do customers feel they are getting good value for the price they pay?",
      //       answer: "Value for money is rated at 4.0 stars. Positive feedback mentions generous portions, while negative feedback often highlights high prices for some dishes.\nHere’s a table of the most common negative and positive keywords associated with price compared to restaurants in your area:",
      //       table: true,
      //       suggestions: ["Tracking"]
      //   }])
      // }
      else if (question === "Recommendation") {
        setLoading(false)
        setChatArray([...chatArray, {
          question: "Give me Recommendations for Next Steps.",
          answer: "Based on what I have seen, I recommend following activities to start with\nHere are some suggested next best actions to address this issue:\n1. Analyze review content:\n> •  Identify common complaints and pain points\n> •  Look for patterns in negative feedback\n2. Staff training:\n> •  Conduct refresher training on customer service\n> •  Address specific issues highlighted in reviews\n3. Quality control:\n> •  Review food preparation processes\n> •  Implement stricter quality checks\n4. Customer feedback system:\n> •  Implement real-time feedback collection\n> •  Create a process for immediate issue resolution\n5. Menu evaluation:\n> •  Assess poorly-received dishes\n> • Consider menu updates or improvements\n6. Operational audit:\n> •  Review wait times and service efficiency\n> •  Optimize kitchen and front-of-house operations\n7. Respond to reviews:\n> •  Engage with reviewers professionally and empathetically\n> •  Offer solutions or compensation where appropriate\n8. Mystery shopper program:\n> •  Hire mystery diners to provide detailed feedback\n> •  Use insights to make targeted improvements\n9. Customer recovery campaign:\n> •  Reach out to dissatisfied customers\n> •  Offer incentives for a return visit\n10. Management review:\n> •  Assess current leadership effectiveness\n> •  Consider bringing in experienced consultants if needed\nWould you like me to set up a call with you.",
          suggestions: ["Yes", "No"]
        }])
      }
      else if (question === "Yes") {
        // setActiveToastComponent(1)
        setLoading(false)
        setChatArray([...chatArray, {
          question: "Yes",
          answer: "Sure, we will connect with you shortly!",
          // suggestions: ["Take me to my Dashboard"]
        }])
      }
      else if (question === "No") {
        // setActiveToastComponent(1)
        setLoading(false)
        setChatArray([...chatArray, {
          question: "No",
          answer: "No worries!",
          // suggestions: ["Take me to my Dashboard"]
        }])
      }
      // else if (question === "Other Insights") {
      //   setLoading(false)
      //   setChatArray(prevChatArray => {
      //     const lastIndex = prevChatArray.length - 1;
      //     const lastChat = prevChatArray[lastIndex];

      //     let newSuggestions = [];
      // if (question === "What are the key types of restaurant in this market?") {
      //   newSuggestions = ["Can you give me insights on restaurant seasonal demand?", "What's the most popular dish across this market?", "Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness"];
      // } else if (question === "Can you give me insights on restaurant seasonal demand?") {
      //   newSuggestions = ["What's the most popular dish across this market?", "What are the key types of restaurant in this market?", "Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness"];
      // } else if (question === "What's the most popular dish across this market?") {
      //   newSuggestions = ["What are the key types of restaurant in this market?", "Can you give me insights on restaurant seasonal demand?", "Sentiment", "Food Quality", "Service Quality", "Ambience and Cleanliness"];
      // }

      //     return [
      //       ...prevChatArray.slice(0, lastIndex),
      //       {
      //         ...lastChat,
      //         suggestions: newSuggestions
      //       }
      //     ];
      //   });

      // }
      else {
        setChatArray([...chatArray, {
          question: question,
        }])
        setLoading(true)
        let response = await chatresponse({ query: question });
        if (response) {
          setChatArray(prevChatArray => {
            const lastIndex = prevChatArray.length - 1;
            const responseMessage = response?.data?.message;

            // Check if responseMessage exists and contains multiple \n
            const formattedMessage = responseMessage ? responseMessage.replace(/\n{2,}/g, '\n') : "Currently we're facing some issues in getting response for you. Please try again later and if the issue persists do let us know at issue@gtmcopilot.com";

            return [
              ...prevChatArray.slice(0, lastIndex),
              {
                ...prevChatArray[lastIndex],
                answer: formattedMessage,
                suggestions: null
              }
            ];
          });

          setLoading(false)
        }
      }
      setTimeout(() => {
        setLoading(false)
      }, 7000)
    }
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };
  useEffect(() => {
    if (isFollowupClicked === true) {
      setInputFilled(true);
      handleSend();
      setIsFollowupClicked(false);
    }
  }, [isFollowupClicked]);
  return (
    <>
      <div className={`flex items-center ${chatMode == 0 ? "fixed left-[22%] w-full" : `w-full`} bottom-[3%]`}>
        <div className={`border bg-[#E9EDF1] p-1 ${chatMode == 0 ? "w-[70%]" : ``}`} style={chatMode !== 0 ? { width: `${siderWidth}px` } : {}}>
          <div className={`border border-[#E9EDF1] ${chatMode == 0 ? "shadow-xl px-2 p-2" : "px-1"} flex justify-center items-center`}>
            <div className={`flex gap-2 justify-center items-center ${chatMode == 0 ? "gap-5 w-[95%]" : "w-[90%]"}`}>
              <div className="w-full">
                <div className="flex items-center">
                  {chatMode == 0 &&
                    <div className="mr-2">
                      <Button className="flex items-center justify-center pl-1"> <PaperClipOutlined /></Button>
                    </div>
                  }
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    value={question}
                    rows={rows}
                    onChange={handleInputChange}
                    onPressEnter={handlePressEnter}
                    placeholder="Message CoPilot"
                    className={`${chatMode == 0 ? "text-xl " : "text-[14px] "} w-[100%] border laterTextArea`}
                    autoFocus
                  />
                  <div
                    className={`flex justify-center items-center gap-2 border-2 ${chatMode == 0 ? "p-2" : "p-1"} ${inputFilled ? "bg-[#F7F8FA] cursor-pointer" : "bg-white cursor-not-allowed"}`}
                    onClick={handleSend}
                  >
                    <ArrowRightOutlined style={{ color: inputFilled ? "black" : "grey" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className={`flex justify-center items-center gap-5 ${chatMode == 0 ? "" : "ml-1"} mt-0.5`}>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputBoxToast;
