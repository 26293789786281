// src/App.js
import React, { useState } from "react";

// TreeNode Component
const TreeNode = ({ node }) => {
    const [isOpen, setIsOpen] = useState(false);

    const hasChildren = node.children && node.children.length > 0;

    return (
        <div style={{ marginLeft: 50 }}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    padding: "4px",
                    cursor: "pointer",
                    fontWeight: hasChildren ? "bold" : "normal",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    transition: "background-color 0.2s",
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = "transparent"}
            >
                {hasChildren && (isOpen ? "[-] " : "[+] ") + node.name}
                {!hasChildren && ("•  ") + node.name}
            </div>
            {isOpen && hasChildren && (
                <div>
                    {node.children.map((childNode) => (
                        <TreeNode key={childNode.name} node={childNode} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeNode
