import React from 'react'
import NestedList from './NestedList'

const fitData = [
    {
        name: "Industry (0-20 points)",
        subItems: [
            { name: "Target Industry A", points: 20 },
            { name: "Target Industry B", points: 15 },
            { name: "Other Industries", points: "5-10" }
        ]
    },
    {
        name: "Company Size (0-20 points)",
        subItems: [
            { name: "1000+ employees", points: 20 },
            { name: "500-999 employees", points: 15 },
            { name: "100-499 employees", points: 10 },
            { name: "<100 employees", points: 5 }
        ]
    },
    {
        name: "Annual Revenue (0-20 points)",
        subItems: [
            { name: ">$100M", points: 20 },
            { name: "$50M-$100M", points: 15 },
            { name: "$10M-$49M", points: 10 },
            { name: "<$10M", points: 5 }
        ]
    },
    {
        name: "Technology Stack (0-20 points)",
        subItems: [
            { name: "Uses 3+ complementary technologies", points: 20 },
            { name: "Uses 1-2 complementary technologies", points: 10 },
            { name: "No complementary technologies", points: 0 }
        ]
    },
    {
        name: "Geographic Location (0-20 points)",
        subItems: [
            { name: "Primary target region", points: 20 },
            { name: "Secondary target region", points: 10 },
            { name: "Other regions", points: 5 }
        ]
    },
]

const leadData = [
    {
        name: "Job Title (0-25 points)",
        subItems: [
            { name: "C-level", points: 25 },
            { name: "VP/Director", points: 20 },
            { name: "Manager", points: 15 },
            { name: "Individual Contributor", points: 10 }
        ]
    },
    {
        name: "Department (0-25 points)",
        subItems: [
            { name: "Primary target department", points: 25 },
            { name: "Secondary target department", points: 15 },
            { name: "Other departments", points: 5 }
        ]
    },
    {
        name: "Website Engagement (0-20 points)",
        subItems: [
            { name: "Default (No data present)", points: 20 },
            { name: "Visited pricing page", points: 20 },
            { name: "Visited product pages", points: 15 },
            { name: "Visited blog", points: 10 },
            { name: "Visited homepage only", points: 5 }
        ]
    },
    {
        name: "Content Interactions (0-15 points)",
        subItems: [
            { name: "Default (No data present)", points: 15 },
            { name: "Downloaded whitepaper", points: 15 },
            { name: "Attended webinar", points: 10 },
            { name: "Subscribed to newsletter", points: 5 }
        ]
    },
    {
        name: "Email Engagement (0-15 points)",
        subItems: [
            { name: "Default (No data present)", points: 15 },
            { name: "Opened multiple emails", points: 15 },
            { name: "Opened one email", points: 10 },
            { name: "No email interaction", points: 0 }
        ]
    },
]

const Formulas = ({ id }) => {
  return (
    <div className='my-2'>
        <NestedList data={id === "node-17" ? fitData : leadData} />
    </div>
  )
}

export default Formulas