import React, { useEffect, useRef, useState } from "react";
import { DownOutlined, ArrowRightOutlined, StarFilled, PaperClipOutlined } from "@ant-design/icons";
import { Input, message, Modal, Card, Button, Dropdown, Menu } from "antd";
import { jwtDecode } from "jwt-decode";
import { getGenericResponse, asyncData, uploadToS3, csvDataInDB, asyncDataVanna } from "../../../services/chatServices";
import { saveFreshReportsInDB } from "../../../services/InsightsServices";
import { chatModeAtom } from "../../../store/atoms/MainAtoms";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import _ from 'lodash'
import { copilotRowsAtom, copilotInputFilledAtom, copilotQuestionAtom, copilotChatArrayAtom, copilotLoadingAtom, isFollowupClickedAtom, attachedFileAtom, isFileAttachedAtom, copilotFileLoadingAtom, cc_tableColsAtom, cc_tableDataAtom, cc_modalAtom, isProcessingAtom, talkToCSVAtom, assistantFocusAtom, activeSessionAtom, fileListAtom, reportListAtom } from "../../../store";
import SuggestionsInputBox from "./SuggestionsInputBox";
import AttachFileInputBox from "./AttachFileInputBox";

const { TextArea } = Input;

const AssistantInputBox = ({ siderWidth }) => {
    const { pathname } = useLocation();
    const [fileList, setFileList] = useRecoilState(fileListAtom);
    const [assistantFocus, setAssistantFocus] = useRecoilState(assistantFocusAtom)
    const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom)
    const [rows, setRows] = useRecoilState(copilotRowsAtom);
    const [inputFilled, setInputFilled] = useRecoilState(copilotInputFilledAtom);
    const [question, setQuestion] = useRecoilState(copilotQuestionAtom);
    const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
    const [loading, setLoading] = useRecoilState(copilotLoadingAtom);
    const chatMode = useRecoilValue(chatModeAtom)
    const [attachedFile, setAttachedFile] = useRecoilState(attachedFileAtom);
    const [isFileAttached, setIsFileAttached] = useRecoilState(isFileAttachedAtom);
    const [reportList, setReportList] = useRecoilState(reportListAtom);

    const handleInputChange = (e) => {
        if (e.target.value.trim() === "") {
            setInputFilled(false);
        } else {
            setInputFilled(true);
        }
        const lines = e.target.value.split("\n").length;
        const newRows = Math.min(lines, 5);
        setRows(newRows || 1);
        setQuestion(e.target.value);
    };
    const handleSend = async () => {
        const chat_id = uuidv4()
        if (attachedFile && isFileAttached) {
            handleUpload(chat_id)
            // setChatArray([
            //     {
            //         question: "You've uploaded " + attachedFile?.name
            //     }
            // ]);
        }
        else if (inputFilled) {
            setLoading(true)
            setInputFilled(false)
            setChatArray(prevChatArray => (
                [
                    ...prevChatArray,
                    {
                        question: question
                    }
                ]
            ))
            setQuestion(null)
            let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
            let table_name;
            let schema_name;
            if (assistantFocus != "master") {
                table_name = assistantFocus
                schema_name = "insighttables"
            }
            else if (organization === "talkable.com") {
                table_name = "master_account_hfsf_account_deals_oppo, master_contact_hfsf_lead_contact";
                schema_name = "talkable_master";
            } else if (organization === 'zillasecurity.com') {
                table_name = "zilla_security_master_account_selected_attributes, zilla_security_master_contact_selected_attributes";
                schema_name = "zillasecurity_master";
            } else if (organization === 'icustomer.ai') {
                table_name = "account, contact";
                schema_name = "icustomer";
            }
            else {
                table_name = "master_account_hfsf_account_deals_oppo, master_contact_hfsf_lead_contact";
                schema_name = "talkable_master";
            }
            let sessionId = activeSession
            const payload = {
                session_id: sessionId,
                user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
                question: question,
                organization: organization,
                query_id: chat_id,
                table_name: table_name,
                schema_name: schema_name,
                llm: pathname == '/assistant' ? 'vanna' : ''
            };
            let response = await getGenericResponse(payload);
            setActiveSession(response.data.session_id)
            pollAsyncData(response.data.session_id);
        }
    };

    const pollAsyncData = async (sessionId) => {
        let response
        do {
            const fetchData = pathname === '/assistant' ? asyncDataVanna : asyncData;
            response = await fetchData({ session_id: sessionId });
            const { data } = response.data;

            console.log({ polldata: data })

            if (data != null && response.data.status !== 'done') {
                const cleanedData = data.replace(/Currently I am trying to generate a chart based on your query and results generated. This will just take a few seconds./, 'Currently I am trying to generate a chart based on your query and results generated. This will just take a few seconds.').replace(/```python[\s\S]*?```/, '').replace(/Answer:/, '').replace(/follow-up questions: \[.*?\]/, '').trim();

                setChatArray((prevChatArray) => {
                    const updatedChatArray = [...prevChatArray];
                    if (updatedChatArray.length === 0) {
                        updatedChatArray.push({
                            answer: cleanedData,
                            suggestions: [],
                            // chartData: cleanedData,
                        });
                    } else {
                        updatedChatArray[updatedChatArray.length - 1] = {
                            ...updatedChatArray[updatedChatArray.length - 1],
                            answer: cleanedData,
                            suggestions: [],

                        };
                    }
                    return updatedChatArray;
                });
            }

            if (response.data.status === 'done') {
                setLoading(false);
                setChatArray((prevChatArray) => {
                    const updatedChatArray = [...prevChatArray];
                    console.log("RES +> ", response.data);
                    if (updatedChatArray.length > 0) {
                        updatedChatArray[updatedChatArray.length - 1] = {
                            ...updatedChatArray[updatedChatArray.length - 1],
                            answer: response.data.cleanedData,
                            suggestions: response.data.followUpQuestions ? response.data.followUpQuestions : [],
                            visuals: true,
                            data: response.data.pythonCode ? [response.data.pythonCode] : null,
                            chartMessage: response.data.graphAnswer,
                            chartType: response.data.pythonCode ? "plotly" : null,
                            query_id: response.data.query_id,
                            postgres_query: response.data.postgres_query,
                            topic: response.data.topic,
                            newTable: response.data.newTable,
                            onlySummary: response.data.onlySummary,
                            is_sql_error: response.data.is_sql_error ? response.data.is_sql_error : false
                        };
                    }
                    return updatedChatArray;
                });
                if (response?.data?.newTable) {
                    setReportList(prevList => [
                        ...prevList,
                        { tableName: response.data.newTable, filename: response?.data?.topic || "Report " + reportList.length + 1 }
                    ]);
                }
                if (!response.data.onlySummary) {
                    const payload = {
                        user_id: jwtDecode(
                            localStorage
                                .getItem("loginToken")
                                ?.replace(process.env.REACT_APP_TOKEN_PASSCODE, "")
                        )?.id,
                        query_id: response.data.query_id,
                        base64Img: response.data.pythonCode,
                        postgres_query: response.data.postgres_query,
                        description: response.data.cleanedData,
                        name: response?.data?.topic
                    };

                    let insightResponse = await saveFreshReportsInDB(payload);
                    console.log(insightResponse);
                    // if (insightResponse.data.status === "success") {
                    //     message.success("Insight has been saved in Reports");
                    // }
                }
            }
            await new Promise(resolve => setTimeout(resolve, 1000));

        } while (response.data.status !== 'done')
    };

    const handlePressEnter = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleUpload = async (chat_id) => {
        if (!attachedFile) {
            message.error("No file selected for upload.");
            return;
        }
        if (!(attachedFile instanceof Blob)) {
            console.error("uploadedFile is not a Blob or File object.");
            return;
        }

        try {
            const user_id = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id
            const currentDate = new Date().toISOString().split('T')[0];
            const currentDateTime = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
            // Pre-signed URL fetch
            const formData = new FormData();
            formData.append("file", attachedFile);

            const url = `https://vnggl7etafv24fzbk45b5r3woe0mfcgn.lambda-url.us-east-1.on.aws/upload-object?folder_name=copilotupload&user_id=${user_id}&file_name=${attachedFile?.name}${currentDateTime}`;
            const myHeaders = new Headers();
            myHeaders.append("token", "zkfhnpiehvcjdloehbkxjdk");
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: "follow"
            };
            const response = await fetch(url, requestOptions);
        } catch (error) {
            console.error("Error uploading file:", error);
            // message.error("Failed to upload file to S3.");
        }
    };
    const fileMenu = (
        <Menu onClick={({ key }) => setAssistantFocus(key)}>
            {reportList.map(file => (
                <Menu.Item key={file.tableName}>
                    {file.filename}
                </Menu.Item>
            ))}
            <Menu.Divider />
            {fileList.map(file => (
                <Menu.Item key={file.tableName}>
                    {file.filename}
                </Menu.Item>
            ))}
            <Menu.Divider />
            <Menu.Item key="master">
                Master DB
            </Menu.Item>
        </Menu>
    );

    return (
        <>

            <div className={`flex items-center ${chatMode == 0 ? "fixed left-[22%] w-full" : `w-full`} bottom-[3%] z-50`}>
                <div className={`border bg-[#E9EDF1] p-1 ${chatMode == 0 ? "w-[70%]" : ``}`} style={chatMode !== 0 ? { width: `${siderWidth}px` } : {}}>
                    <SuggestionsInputBox handleSend={handleSend} />
                    <div className={`border border-[#E9EDF1] ${chatMode == 0 ? "shadow-xl px-2 p-2" : "px-1"} flex justify-center items-center`}>
                        <div className={`flex gap-2 justify-center items-center ${chatMode == 0 ? "gap-5 w-[95%]" : "w-[90%]"}`}>
                            {isFileAttached &&
                                <div className="flex justify-center items-center gap-2 absolute -top-6 left-1 bg-[#8EA9D0] text-white text-xs rounded p-2 shadow-md">
                                    1 File Uploaded
                                </div>
                            }
                            <div className="w-full">
                                <div className="flex items-center">
                                    {
                                        (fileList.length > 0 || reportList.length > 0) && chatMode == 0 &&
                                        <Dropdown overlay={fileMenu} trigger={['click']}>
                                            <Button className="mr-2 items-center" style={{ width: '140px', display: 'flex', justifyContent: 'space-between', overflow: 'hidden' }}>
                                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    {fileList.find(file => file.tableName === assistantFocus)?.filename || reportList.find(file => file.tableName === assistantFocus)?.filename || 'Master'}
                                                </span>
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    }

                                    <AttachFileInputBox handleSend={handleSend} />
                                    <TextArea
                                        autoSize={{ minRows: 1, maxRows: 5 }}
                                        value={question}
                                        rows={rows}
                                        onChange={handleInputChange}
                                        onPressEnter={handlePressEnter}
                                        placeholder="Message CoPilot"
                                        className={`${chatMode == 0 ? "text-xl " : "text-[14px] "} w-[100%] border laterTextArea`}
                                        autoFocus
                                    />
                                    <div
                                        className={`flex justify-center items-center gap-2 border-2 ${chatMode == 0 ? "p-2" : "p-1"} ${inputFilled ? "bg-[#F7F8FA] cursor-pointer" : "bg-white cursor-not-allowed"}`}
                                        onClick={handleSend}
                                    >
                                        <ArrowRightOutlined style={{ color: inputFilled ? "black" : "grey" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssistantInputBox;