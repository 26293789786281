import { atom } from "recoil";

const cc_tableDataAtom = atom({
  key: "cc_tableDataAtom",
  default: [
        {
          key: '1',
          event_name: 'Regular Webinar',
          date: '2024-06-15',
          attendee_email: 'annev@email@sc.edu',
          first_name: 'rnne',
          last_name: '',
          country: 'usa'
        },
        {
          key: '2',
          event_name: 'Regular##Webinar',
          date: '2024-06-16',
          attendee_email: '##$jonpjonesmts57@gmail.com',
          first_name: 'Jooonathon',
          last_name: '',
          country: ''
        },
        {
          key: '3',
          event_name: 'RegularWebinar',
          date: '2024-06-17',
          attendee_email: 'tayrolle77@gmail.com^^^^',
          first_name: 'Shawnta656',
          last_name: '',
          country: 'united States of America'
        },
        {
          key: '4',
          event_name: 'Regular Webinar',
          date: '18-06-2024',
          attendee_email: '*())shannon@gmail.com',
          first_name: 'Shannon',
          last_name: '',
          country: ''
        },
        {
          key: '5',
          event_name: 'Regular##Webinar',
          date: '2024-06-19',
          attendee_email: 'taylorcouch19@gmail.comgmail.com',
          first_name: '**Taylor',
          last_name: '',
          country: ''
        },
        {
          key: '6',
          event_name: 'RegularWebinar',
          date: '20/06/2024',
          attendee_email: 'llgarcia_1@yahoo2323.com',
          first_name: 'Lydia',
          last_name: 'GV',
          country: ''
        },
        {
          key: '7',
          event_name: 'Regular Webinar',
          date: '2024-06-21',
          attendee_email: 'momsadams@hotmail.com',
          first_name: '#####',
          last_name: 'Adams',
          country: ''
        },
        {
          key: '8',
          event_name: 'Regular##Webinar',
          date: '2024-07-22',
          attendee_email: 'dgonzales@valleywater.org',
          first_name: '#####',
          last_name: 'Gonzales',
          country: ''
        },
        {
          key: '9',
          event_name: 'On-DemandWebinar',
          date: '2024-06-23',
          attendee_email: 'alexisjames0428@gmail.com',
          first_name: '#####',
          last_name: 'James',
          country: ''
        },
        {
          key: '10',
          event_name: 'On-Demand(Webinar',
          date: '24-06-2024',
          attendee_email: 'hdgirl1@aol.com###',
          first_name: '#####',
          last_name: 'Heisey',
          country: ''
        },
        {
          key: '11',
          event_name: 'On-DemandWebinar',
          date: '2024-06-25',
          attendee_email: 'nathan.petrucci@gmail.com$$$$$',
          first_name: '#####',
          last_name: 'Petrucci',
          country: ''
        },
        {
          key: '12',
          event_name: 'Virtual Event@@@@',
          date: '26/06/2024',
          attendee_email: 'daniel.king@aol.com$$$$$',
          first_name: '#####',
          last_name: 'King',
          country: ''
        },
        {
          key: '13',
          event_name: 'Virtual Event@@@@',
          date: '2024-06-27',
          attendee_email: 'ashleymyers@@@@@yahoo.com',
          first_name: '#####',
          last_name: 'Myers',
          country: ''
        },
        {
          key: '14',
          event_name: 'Virtual-Event@@@@',
          date: '28-06-2024',
          attendee_email: 'liberated_souls@gmail.com$$$$$',
          first_name: '#####',
          last_name: 'Souls',
          country: ''
        },
        {
          key: '15',
          event_name: 'VirtualEvent@@@@',
          date: '2024-06-29',
          attendee_email: 'jackson_ryan$$$$$@hotmail.com',
          first_name: '#####',
          last_name: 'Ryan',
          country: ''
        },
        {
          key: '16',
          event_name: 'Virtual-Event@@@@',
          date: '2024-06-30',
          attendee_email: 'christine@example.com$$$$$',
          first_name: '#####',
          last_name: 'Christine',
          country: ''
        },
        {
          key: '17',
          event_name: 'Workshop@@@@',
          date: '01-07-2024',
          attendee_email: 'john_doe@example.com$$$$$',
          first_name: '#####',
          last_name: 'Doe',
          country: ''
        },
        {
          key: '18',
          event_name: 'Workshop@@@@',
          date: '2024-06-17',
          attendee_email: 'timothy@gmail.com$$$$$',
          first_name: '#####',
          last_name: 'Timothy',
          country: ''
        },
        {
          key: '19',
          event_name: 'Workshop@@@@',
          date: '18-06-2024',
          attendee_email: 'richard@example.com$$$$$',
          first_name: '#####',
          last_name: 'Richard',
          country: ''
        },
        {
          key: '20',
          event_name: 'Workshop@@@@',
          date: '2024-06-19',
          attendee_email: 'juliaschildwachter#######@yahoo.com',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '21',
          event_name: 'Workshop@@@@',
          date: '20/06/2024',
          attendee_email: 'gisela2683@********hotmail.com',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '22',
          event_name: 'Workshop@@@@',
          date: '2024-06-21',
          attendee_email: '######thochatown@gmail.com',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '23',
          event_name: 'Partner Event',
          date: '2024-07-22',
          attendee_email: 'timtamcole@hotmail.com######',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '24',
          event_name: 'PartnerEvent',
          date: '2024-06-23',
          attendee_email: 'victoriadb@@@1999@gmail.com',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '25',
          event_name: 'Partner-Event',
          date: '24-06-2024',
          attendee_email: '-----taylorhetherington2002@gmail.com',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '26',
          event_name: 'Partner Event',
          date: '2024-06-25',
          attendee_email: 'ritzam@aol.com#####',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '27',
          event_name: 'PartnerEvent',
          date: '26/06/2024',
          attendee_email: 'fdubaisy#####@hotmail.com',
          first_name: '',
          last_name: '',
          country: 'Saudi'
        },
        {
          key: '28',
          event_name: 'Partner-Event',
          date: '2024-06-27',
          attendee_email: '$$$$$arianmansur@gmail.com$$$$$',
          first_name: '',
          last_name: '',
          country: ''
        },
        {
          key: '29',
          event_name: 'Partner Event',
          date: '28-06-2024',
          attendee_email: 'dillidinesh005$$$$$@gmail.com$$$$$',
          first_name: '',
          last_name: '',
          country: 'Tamil Nadu'
        },
        {
          key: '30',
          event_name: 'Partner Event',
          date: '2024-06-29',
          attendee_email: 'gismay85@#####gmail.com#####',
          first_name: '',
          last_name: '',
          country: ''
        }  
    ],
});

const cc_tableColsAtom = atom({
    key: "cc_tableColsAtom",
    default: [
        {
          title: 'Event Name',
          dataIndex: 'event_name',
          key: 'event_name'
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date'
        },
        {
          title: 'Attendee Email',
          dataIndex: 'attendee_email',
          key: 'attendee_email'
        },
        {
          title: 'First Name',
          dataIndex: 'first_name',
          key: 'first_name'
        },
        {
          title: 'Last Name',
          dataIndex: 'last_name',
          key: 'last_name'
        },
        {
          title: 'Country',
          dataIndex: 'country',
          key: 'country'
        }
      ],
  });

const cc_magicAtom = atom({
  key: "magic",
  default: false
})

const cc_modalAtom = atom({
  key: "modal",
  default: false
})



export {
  cc_tableDataAtom,
  cc_tableColsAtom,
  cc_magicAtom,
  cc_modalAtom
};
