import { Button, Checkbox, Input } from "antd";
import React, { useState, useEffect } from "react";
import {
    InfoCircleFilled
  } from "@ant-design/icons";

const FindLookALikeOption = () => {
    const [displayModal, setDisplayModal] = useState(null)
    const handleClick = (modalName) => {
        setDisplayModal(modalName)
    }
  return (
    <>
          <div className="flex text-[16px]" style={{fontWeight: 600}}>
          Find Look A Like Companies
      </div>
    {displayModal == null ? 
        <div className='flex flex-col h-[30vh] w-full p-2'>
        <div onClick={() => handleClick('existing')} className="flex border mt-4 justify-center items-center gap-2 p-2">  
        Use Existing Definition
        <InfoCircleFilled />
        </div>
        <div className="flex border cursor-disable mt-4 justify-center items-center gap-2 p-2">  
        Define Custom Criteria 
        <InfoCircleFilled />
        </div>
    </div> : displayModal == "existing" ?       
    <div className="p-4 mt-2">
      <div className="flex justify-center">
      </div>
        <div className="flex mt-6 justify-start">
            <Checkbox className="text-[16px]">All</Checkbox>
        </div>
        <div className="flex mt-2 justify-start">
            <Checkbox className="text-[16px]">ICP Tier 1</Checkbox>
        </div>
        <div className="flex mt-2 justify-start">
            <Checkbox className="text-[16px]">ICP Tier 2</Checkbox>
        </div>
        <div className="flex mt-2 justify-start">
            <Checkbox className="text-[16px]">ICP Tier 3</Checkbox>
        </div>
        
        <div className="flex mt-8 gap-8 justify-center">
<Button onClick={() => handleClick(null)}>Back</Button>
<Button >Continue to add fields</Button>
</div>
      </div> : <></>
    }
    </>
  );
};

export default FindLookALikeOption;
