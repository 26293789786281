import React, { useEffect, useState, useRef } from "react";
import logo from "../../assets/images/logoWOname.png";
import avatar from "../../assets/images/user.png";
import {
  CopyOutlined,
  LikeOutlined,
  DislikeOutlined,
  LinkOutlined,
  PieChartFilled,
  ArrowsAltOutlined,
} from "@ant-design/icons";
import { Card, Button, Skeleton, message, Table, Input, Select } from "antd";
import { motion } from "framer-motion";
import { Bar } from "react-chartjs-2";
import graphImg from "../../assets/images/graphImg.png";
import shareImg from "../../assets/images/shareImg.png";
import downloadImg from "../../assets/images/downloadImg.png";
import refreshImg from "../../assets/images/refresh.png";
import reviewRating from "../../assets/images/reviewRatingGraph.png";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  toastChatArrayAtom,
  toastLoadingAtom,
  toastExtendedChatAtom,
  toastQuestionAtom,
  toastIsFollowupClickedAtom,
  toastSampleDAtom,
  copilotChatArrayAtom,
  copilotLoadingAtom,
  copilotQuestionAtom,
  isFollowupClickedAtom
} from "../../store";
import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms";
import HeatmapComponent from "./HeatMap";


const charVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const CharMotion = ({ text, onComplete }) => {
  const chatMode = useRecoilValue(chatModeAtom);

  return (
    <div className="text-[14px]">
      {text.split("").map((ch, idx) =>
        ch === ">" ? (
          <></>
        ) : (
          <motion.span
            key={`${ch}_${idx}`}
            initial="hidden"
            animate="visible"
            variants={charVariants}
            transition={{ duration: 0.1, delay: idx * 0.001 }}
            onAnimationComplete={idx === text.length - 1 ? onComplete : null}
            className={`${chatMode && 'text-[12px]'}`}
          >
            {ch}
          </motion.span>
        )
      )}
    </div>
  );
};

const ChartOptions = ({
  filterVisible,
  setFilterVisible,
  index,
  handleFilter,
  tableVisible,
  setExtendedChat,
  chartData,
  chartOptions,
  type,
  tileName,
}) => {
  const [sampleD, setSampleD] = useRecoilState(toastSampleDAtom);

  const handleSave = () => {
    // console.log(filterVisible, "filterVisible");
    // console.log(chartData, "chartData");
    // console.log(chartOptions, "chartOptions");
    setSampleD([
      {
        // tileName:
        //   tileName ||
        //   `${type === "chart" ? "Chart" : "Table"} ${sampleD.length + 1}`,
        tileName: `Saved Widget ${sampleD.length + 1}`,
        type: type === "chart" ? "Bar" : "Table",
        chartData: chartData,
        chartOptions: chartOptions,
      },
      ...sampleD
    ]);
    setFilterVisible(-1);
    message.success("Saved to Dashboard");
  };

  return (
    <div className="absolute top-2 right-2 flex justify-center items-center gap-2">
      <div className="w-full -mr-1 mb-0.5 relative">
        <img
          src={shareImg}
          alt="shareImg"
          width={24}
          className={`hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#8EA9D0] ${filterVisible === index && "border border-white border-b-[#8EA9D0]"
            }`}
          onClick={() => handleFilter(index)}
        />
        {filterVisible === index && (
          <div className="absolute -bottom-[8.5rem] -right-[4.5rem] z-50 flex flex-col gap-1 justify-center items-center w-[900%] border bg-[#E9EDF1] p-2">
            <div
              className="w-full flex justify-center items-center border bg-white hover:bg-[#8EA9D0] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md"
              onClick={handleSave}
            >
              Save to Dashboard
            </div>
            <div className="w-full flex justify-center items-center border bg-white hover:bg-[#8EA9D0] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
              Generate Embedded Hyperlink
            </div>
            <div className="w-full flex justify-center items-center border bg-white hover:bg-[#8EA9D0] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
              Send on Slack
            </div>
            <div className="w-full flex justify-center items-center border bg-white hover:bg-[#8EA9D0] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
              Send Via Email
            </div>
          </div>
        )}
      </div>
      <img
        src={graphImg}
        alt="graphImg"
        width={22}
        className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#8EA9D0]"
      />
      <PieChartFilled
        className={`-mr-1 mb-0.5 hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#8EA9D0] hover:p-1 ${tableVisible !== index && "border border-white border-b-[#8EA9D0] p-1"
          }`}
      // onClick={() => handleTableVisible(index)}
      />
      <img
        src={downloadImg}
        alt="downloadImg"
        width={26}
        className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#8EA9D0]"
      // onClick={() =>
      //   downloadChartAsImage(
      //     chat.chartData.metadata.labelX +
      //       chat.chartData.metadata.labelY +
      //       chat.chartData.metadata.rep
      //   )
      // }
      />
      <ArrowsAltOutlined
        className="text-[14px] -ml-1 hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#8EA9D0] hover:p-1"
        onClick={() => setExtendedChat(true)}
      />
    </div>
  );
};

const ChatMessage = ({ textToDisplay, chat, setShowSuggestion }) => {
  const [displayedLines, setDisplayedLines] = useState([]);
  const lines = textToDisplay?.split("\n") || [];
  const chatMode = useRecoilValue(chatModeAtom);

  const lineRef = useRef();

  useEffect(() => {
    if (lines.length > 0) {
      setShowSuggestion(false);
      setDisplayedLines([lines[0]]);
    }
  }, [chat]);

  let lineTimeoutId;
  useEffect(() => {
    lineTimeoutId = setTimeout(() => {
      console.log("called");
      lineRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 1);

    return () => clearTimeout(lineTimeoutId);
  }, [displayedLines]);

  const handleAnimationComplete = () => {
    if (displayedLines.length < lines.length) {
      // setShowSuggestion(false);
      setDisplayedLines((prevDisplayedLines) => [
        ...prevDisplayedLines,
        lines[prevDisplayedLines.length],
      ]);
    } else if (displayedLines.length === lines.length) {
      console.log("show now");
      setShowSuggestion(true);
    }
  };

  return displayedLines.map((line, idx) => (
    <motion.div
      initial="hidden"
      animate="visible"
      key={idx}
      className={`overflow-hidden ${line?.trim()[0] === ">" && !chatMode && "pl-16"} ${chatMode && 'p-0'}`}
      ref={lineRef}
    >
      <CharMotion
        text={line}
        onComplete={
          idx === displayedLines.length - 1 ? handleAnimationComplete : null
        }
      />
    </motion.div>
  ));
};

const ChatWindow = () => {
  const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
  const [loading, setLoading] = useRecoilState(copilotLoadingAtom);
  const [question, setQuestion] = useRecoilState(copilotQuestionAtom);
  const [isFollowupClicked, setIsFollowupClicked] = useRecoilState(
    isFollowupClickedAtom
  );
  const sideNavContent = useRecoilValue(sideNavContentAtom)
  const setExtendedChat = useSetRecoilState(toastExtendedChatAtom);
  const chatMode = useRecoilValue(chatModeAtom);
  const [showSuggestion, setShowSuggestion] = useState(false);

  const [randomText, setRandomText] = useState(
    "Gathering and cleaning relevant data"
  );
  const [isFeedbackVisible, setFeedbackVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(-1);
  const [tableVisible, setTableVisible] = useState(-1);

  let lastInsightRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    if (lastInsightRef?.current) {
      timeoutId = setTimeout(() => {
        lastInsightRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 100);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showSuggestion]);

  const handleSuggestionClick = (insight) => {
    setQuestion(insight)
    setIsFollowupClicked(true)
  };


  const handleFilter = (index) => {
    console.log(filterVisible);
    const prevIndex = filterVisible;
    if (filterVisible === -1 || prevIndex !== index) {
      setFilterVisible(index);
    } else {
      setFilterVisible(-1);
    }
  };

  const generateChartData = (chat) => {
    if (!chat?.tableColumns || !chat?.tableDatasource) return [];
    const chartData = [];
    chat.tableDatasource.forEach((data) => {
      const row = {};
      chat.tableColumns.forEach((column) => {
        row[column.title] = data[column.dataIndex];
      });
      chartData.push(row);
    });

    return chartData;
  };

  return (
    <div
      className={`${chatMode == 1 ? "h-[100%]" : "h-[70%]"} flex flex-col gap-2 mt-2`}
      style={{ overflowY: "scroll" }}
    >
      {chatArray.map((chat, index) => (
        <React.Fragment key={index}>
          {/* Question Component Starts */}
          {chat?.question && (
            <div
              className={`flex justify-start items-center ${chatMode == 1 ? "ml-2 gap-2 p-1" : "gap-4 p-2"}`}
            >
              <img
                src={
                  localStorage.getItem("profile_url")
                    ? localStorage.getItem("profile_url").toString()
                    : avatar
                }
                alt="user-logo"
                width={chatMode == 1 ? 20 : 30}
                className="rounded-full self-start mt-[10px]"
              />
              <div className={`text-[14px] w-[90%] ${chatMode == 1 ? "p-2" : "p-4"} rounded-md relative text-black`}>
                {/* <div className={`triangle-left tl-question absolute left-[-18px] top-2`} /> */}
                {chat.question}
              </div>
            </div>
          )}
          {/* Question Component Ends */}

          {/* Answer Component Starts */}
          <div className={`bg-[#F7F8FA] ${chatMode == 1 ? "p-1" : "p-4"}`}>
            <div className={`flex ${chatMode == 1 ? "gap-2" : "gap-4"}`}>

              <div className={`flex justify-start items-center ${chatMode == 1 ? "ml-2 " : "mt-2"}`}>
                <img
                  src={logo}
                  alt="brand-logo"
                  width={35}
                  className="mt-1 self-start"
                />
              </div>
              {/* <div className="text-[14px] bg-[#E9EDF1] w-[90%] p-4 rounded-md relative"> */}
              <div className={`text-[14px] w-[90%] ${chatMode == 1 ? "p-2" : "p-4"} rounded-md relative`}>
                {/* <div className="triangle-left tl-answer absolute left-[-20px] top-2" /> */}

                <div className="flex flex-col justify-center items-start flex-wrap gap-2">
                  {chat?.answer && (
                    <ChatMessage
                      textToDisplay={chat?.answer}
                      chat={chat}
                      setShowSuggestion={setShowSuggestion}
                    />
                  )}
                  {loading && index === chatArray?.length - 1 && (
                    <div
                      className="flex flex-wrap mt-2 gap-3"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                  {/* {chat.sources &&
                    chat.sources.map((metadata, index) => (
                      <div
                        key={`source-${index}`}
                        className="flex flex-col justify-center items-center border rounded-lg w-[300px] h-[120px] gap-2 cursor-pointer border-[#609DA1] bg-gray-100 hover:scale-105 delay-150 duration-300 ease-in-out"
                        onClick={() =>
                          window.open(
                            `${metadata?.url}`,
                            "_blank",
                            "noreferrer"
                          )
                        }
                      >
                        <sub
                          style={{
                            display: "inline-block",
                            marginTop: "4px",
                            width: "14px",
                            borderRadius: "50%",
                            backgroundColor: "grey",
                            color: "white",
                            height: "14px",
                            textAlign: "center",
                            lineHeight: "14px",
                            fontSize: "9px",
                          }}
                        >
                          {metadata.number}
                        </sub>
                        <div className="flex gap-2 justify-start items-center w-[90%] text-[15px]">
                          <div
                            className="flex justify-center items-center border border-[#609DA1] rounded-full p-1 bg-white"
                            style={{ width: "30px", height: "30px" }}
                          >
                            {metadata?.imageUrl?.includes("http") ? (
                              <img
                                alt="img"
                                src={metadata?.imageUrl}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <LinkOutlined
                                style={{ color: "black", fontSize: "20px" }}
                              />
                            )}
                          </div>
                          {metadata?.title?.length > 25
                            ? metadata?.title?.substring(0, 25) + "..."
                            : metadata?.title}
                        </div>
                        <div className="flex justify-start items-center gap-2 w-full pl-[0.75rem]">
                          <div className="text-[12px]">
                            {metadata?.description?.length > 90
                              ? metadata?.description?.substring(0, 90) + "..."
                              : metadata?.description}
                          </div>
                        </div>
                      </div>
                    ))} */}
                  {/* </div> */}
                </div>
                <Skeleton
                  loading={index === chatArray.length - 1 && loading}
                  active
                />
              </div>
            </div>
            {/* Answer Component Ends */}

            {(!loading || index !== chatArray.length - 1) && (
              <>
                {/* Chart Component Starts */}
                {
                  chat?.heatMap && !chatMode &&
                  <HeatmapComponent />
                }
                {chat?.charts && !chatMode &&
                  ((index === chatArray?.length - 1 && showSuggestion) ||
                    index !== chatArray?.length - 1) && (
                    <div className="w-full flex justify-center items-center">
                      <Card className="w-[85%] relative">
                        <ChartOptions
                          filterVisible={filterVisible}
                          setFilterVisible={setFilterVisible}
                          index={index}
                          handleFilter={handleFilter}
                          tableVisible={tableVisible}
                          setExtendedChat={setExtendedChat}
                          chartData={chat?.toastChartData}
                          chartOptions={chat?.toastChartOptions}
                          type={chat?.type}
                          tileName={chat?.tileName}
                        />
                        <div
                          className={`flex justify-center items-center ${chat?.toastChartWidthClassName} mx-auto`}
                        >
                          {chat?.toastChartMessage ? (
                            <div className="mt-4 text-center">
                              {chat?.toastChartMessage}
                            </div>
                          ) : chat?.img ? (
                            <img src={reviewRating} alt="reviewRating" />
                          ) : (
                            <Bar
                              style={{
                                flexGrow: 0.25,
                              }}
                              data={chat?.toastChartData}
                              options={chat?.toastChartOptions}
                            />
                          )}
                        </div>
                      </Card>
                    </div>
                  )}
                {/* Chart Component Ends */}
                {chat?.addForm && (
                  <>
                    <div className="flex justify-center gap-8">
                      <div className="p-4 border border-black">
                        Title<span className="text-[red]">*</span>
                        <Input value={"Pre-Black Friday Campaign Preparation"} />
                        <div className="flex justify-center gap-2 w-full my-4">
                          <div>
                            <div>
                              Type<span className="text-[red]">*</span>
                            </div>
                            <Select className="w-full" value={"To-do"} />
                          </div>
                          <div>
                            <div>

                              Priority<span className="text-[red]">*</span>
                            </div>
                            <Select className="w-full" value={"High"} />
                          </div>
                        </div>
                        <div>
                          Assigned To
                        </div>
                        <div className="w-full">

                          <Select className="w-full mb-4" value={"Suzie"} />
                        </div>
                        <div>

                          Due Date
                        </div>
                        <div className="w-full">

                          <Select className="w-full" value={"July 1"} />
                        </div>
                      </div>
                      <div className="p-4 border border-black">
                        Title<span className="text-[red]">*</span>
                        <Input value={"Post-Black Friday Strategy"} />
                        <div className="flex justify-center gap-2 w-full my-4">
                          <div>
                            <div>
                              Type<span className="text-[red]">*</span>
                            </div>
                            <Select className="w-full" value={"To-do"} />
                          </div>
                          <div>
                            <div>

                              Priority<span className="text-[red]">*</span>
                            </div>
                            <Select className="w-full" value={"High"} />
                          </div>
                        </div>
                        <div>
                          Assigned To
                        </div>
                        <div className="w-full">

                          <Select className="w-full mb-4" value={"Suzie"} />
                        </div>
                        <div>

                          Due Date
                        </div>
                        <div className="w-full">

                          <Select className="w-full" value={"November 1"} />
                        </div>
                      </div>
                      <div className="p-4 border border-black">
                        Title<span className="text-[red]">*</span>
                        <Input value={"Reengage After Code Freeze"} />
                        <div className="flex justify-center gap-2 w-full my-4">
                          <div>
                            <div>
                              Type<span className="text-[red]">*</span>
                            </div>
                            <Select className="w-full" value={"To-do"} />
                          </div>
                          <div>
                            <div>

                              Priority<span className="text-[red]">*</span>
                            </div>
                            <Select className="w-full" value={"High"} />
                          </div>
                        </div>
                        <div>
                          Assigned To
                        </div>
                        <div className="w-full">

                          <Select className="w-full mb-4" value={"Suzie"} />
                        </div>
                        <div>

                          Due Date
                        </div>
                        <div className="w-full">

                          <Select className="w-full" value={"January 1"} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* Chart Message Starts */}
                {chat?.chartMessage && (
                  <div className={` p-4 ${chatMode ? 'w-[68%] ml-[60px]' : 'w-[90%] ml-[74px]'} flex flex-col justify-center items-start flex-wrap gap-2 rounded-md`}>
                    <ChatMessage
                      textToDisplay={chat?.chartMessage}
                      chat={chat}
                      setShowSuggestion={setShowSuggestion}
                    />
                  </div>
                )}

                {chat?.table && !chatMode &&
                  ((index === chatArray?.length - 1 && showSuggestion) ||
                    index !== chatArray?.length - 1) && (
                    <div className="w-full flex justify-center items-center">
                      <Card className="w-[85%] relative">
                        <ChartOptions
                          filterVisible={filterVisible}
                          setFilterVisible={setFilterVisible}
                          index={index}
                          handleFilter={handleFilter}
                          tableVisible={tableVisible}
                          setExtendedChat={setExtendedChat}
                          chartData={generateChartData(chat)}
                          tileName={chat?.tileName}
                        />
                        <div
                          className={`flex justify-center items-center w-full`}
                        >
                          {chat?.toastTableMessage ? (
                            <div className="mt-4 text-center">
                              {chat?.toastTableMessage}
                            </div>
                          ) : (
                            <Table
                              className="mt-2"
                              columns={chat?.tableColumns}
                              dataSource={chat?.tableDatasource}
                              pagination={false}
                            />
                          )}
                        </div>
                      </Card>
                    </div>
                  )}
                {/* Chart Message Ends */}

                {/* Insights Component Starts */}
                {/* {!chatMode && ((index === chatArray?.length - 1 &&
                  showSuggestion &&
                  chat?.suggestions?.length > 0) ||
                  (index !== chatArray?.length - 1 &&
                    chat?.suggestions &&
                    chat?.suggestions?.length > 0)) ? (
                  <div
                    // ref={
                    //   index !== 0 && index === chatArray.length - 1
                    //     ? lastChatRef
                    //     : null
                    // }
                    className="flex justify-start items-center flex-wrap gap-4 bg-[#E9EDF1] text-base w-[90%] rounded-md p-4 ml-[74px]"
                  >
                    {chat?.suggestions?.map((followUp, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-center text-[14px] text-[#2966BC] bg-white border border-[#2966BC] rounded-md p-2 cursor-pointer hover:scale-105 duration-300 ease-in-out hover:bg-[#2966BC] hover:text-white"
                        onClick={() => handleSuggestionClick(followUp)}
                      >
                        {followUp}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )} */}
                {/* Insights Component Ends */}

                {/* Feedback Component Starts */}
                {index !== 0 && showSuggestion && !chatMode && sideNavContent == 0 && (
                  <div
                    className={`flex justify-end items-center gap-4 pr-20 ${index === chatArray.length - 1 && "pb-10"
                      }`}
                    ref={index === chatArray.length - 1 ? lastInsightRef : null}
                  >
                    <CopyOutlined
                      className="text-base cursor-pointer hover:scale-125 duration-300"
                    // onClick={() => copyAnswer(chat.answer)}
                    />
                    <img
                      src={refreshImg}
                      alt="refreshImg"
                      width={20}
                      className="cursor-pointer hover:scale-125 duration-300"
                    />
                    {/* <UndoOutlined
                      rotate={100}
                      className="text-base cursor-pointer hover:scale-125 duration-300"
                    /> */}
                    <LikeOutlined
                      className="text-base cursor-pointer hover:scale-125 duration-300"
                    // onClick={() => handleFeedback(JSON.stringify(chat), 0, " ")}
                    />
                    <DislikeOutlined
                      className="text-base cursor-pointer hover:scale-125 duration-300"
                    // onClick={() => handleDislike(chat)}
                    />
                  </div>
                )}
                {/* Feedback Component Ends */}
              </>
            )}

            {/* Placeholder text for margin bottom Starts */}
            {loading && index === chatArray.length - 1 && (
              <div className="mt-40 text-white">Placeholder Text</div>
            )}
            {/* Placeholder text for margin bottom Ends */}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ChatWindow;
