import React, { useState } from "react";

import {
  DashOutlined,
  DeleteOutlined,
  ProductOutlined,
} from "@ant-design/icons";

import { Select, Dropdown } from "antd";

const DefineBrand = () => {
  const [brandOp, setBrandOp] = useState("equals");
  const [brandAff, setBrandAff] = useState("");

  const subItems = [
    {
      key: "1-a",
      label: "Nike",
      onClick: () => setBrandAff("Nike"),
    },
    {
      key: "1-b",
      label: "Allbirds",
      onClick: () => setBrandAff("Allbirds"),
    },
    {
      key: "1-c",
      label: "Adidas",
      onClick: () => setBrandAff("Adidas"),
    },
  ];

  const brandItems = [
    {
      key: "1-1-1",
      label: (
        <Select
          options={[
            { label: "equals", value: "equals" },
            { label: "not equals", value: "not equals" },
          ]}
          defaultValue={"equals"}
          className="w-full"
          onChange={(val) => setBrandOp(val)}
        />
      ),
      children: [
        {
          key: "1-1-1-1",
          label: (
            <Dropdown
              menu={{
                items: subItems,
              }}
            >
              <p className="flex items-center border rounded-md w-full px-4 py-1">
                {brandAff || "Select Any Value"}
              </p>
            </Dropdown>
          ),
        },
      ],
    },
  ];

  return (
    <div className="border-l-[1rem] border-l-light-purple border border-secondary rounded-md py-4 px-6 flex justify-between items-center">
      <div className="flex flex-col justify-center gap-2">
        <div className="flex items-center gap-4">
          <p className="body_Regular">Has property</p>
          <div className="flex items-center gap-2 border rounded-md py-1 px-2">
            <ProductOutlined className="border rounded-md p-2 bg-primary text-white" />
            <p className="text-character text-[14px]">Brand Affinity</p>
          </div>
          <Dropdown
            menu={{
              items: brandItems,
            }}
            trigger={["click"]}
          >
            <div className="flex items-center gap-1 border rounded-md py-1 px-2 min-h-10 cursor-pointer">
              <p className="text-secondary text-[14px]">
                {brandOp || "equals"}
              </p>
              <p className="text-character text-[14px]">
                {brandAff || "any value"}
              </p>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <DashOutlined />
        <DeleteOutlined style={{ color: "red" }} />
      </div>
    </div>
  );
};

export default DefineBrand;
