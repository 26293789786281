import React, { useEffect, useState } from "react";
import LogRocket from 'logrocket';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Main from "./components/layout/Main";
import SignUp from "./pages/Auth/Signup/SignUp";
import LogIn from "./pages/Auth/Login/Login";
import { GetUserStatus } from "./services/AuthServices";
import { jwtDecode } from "jwt-decode";
import { message } from "antd";
import ChatWindow from "./pages/ChatWindow/ChatWindow.js";
import AutoDiscover from "./pages/AutoDiscover/AutoDiscover.js";
import Projects from "./pages/Projects/Projects.js";
import Agents from "./pages/Agents/Agents.js";
import Integration from "./pages/Integration/Integration.js";
import { integrationStatus } from "./services/integrationServices.js";
import { useRecoilState, useRecoilValue } from "recoil";
import { integrationStatusAtom } from "./store/atoms/IntegrationAtoms.js";
import { isPyodideLoadingAtom, pyodideLoadErrorAtom } from "./store/atoms/MainAtoms.js";
import Settings from "./pages/Settings/Settings.js";
import Home from "./pages/Home/Home.js";
import { getTenant } from "./services/generateListService";
import Analytics from "./pages/Analytics/Analytics.js";
import Copilot from "./pages/Copilot/Copilot.js";
import Toast from './pages/Toast/Toast.js';
import NewAgent from './pages/NewAgent/NewAgent.js';
import { Gumloop } from './pages/Gumloop/Gumloop.js';
import EnrichmentGumloop from './pages/Gumloop/EnrichmentGumloop.js';
import Template from './pages/Template/Template.js';
import EnrichmentTemplate from './pages/Template/EnrichmentTemplate.js';
import Audiences from "./pages/Audiences/Audiences.js";
import NewSegmentComponent from "./components/AudienceComponents/NewSegmentComponent.js";
import SegmentStudioComponent from "./components/AudienceComponents/SegmentStudioComponent.js";
import Live from "./pages/Live/Live.js";
import { debounce } from "lodash";
import DemoSuzieCopilot from "./pages/DemoSuzie/DemoSuzieCopilot.js";
import Main2 from "./components/layout2/Main";
import GraphOptions from "./components/GraphOptions.js";
import ProtectedRoute from "./components/ProtectedRoute.js";
import OnboardingV2 from "./pages/Onboarding/OnboardingV2.js";
import Workspaces from "./pages/Workspaces/Workspaces.js";
import WorkspaceRoute from "./components/WorkspaceRoute.js";
import SettingsV2 from "./pages/SettingsV2/SettingsV2.js";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
// import ResetPassword from "./pages/Auth/ResetPassword/ResetPassword";
import VerifyPassword from "./pages/Auth/VerifyPassword/VerifyPassword";
import ChatHistory from "./components/ChatHistory/ChatHistory.js";
import LeadTemplate from "./pages/Template/LeadTemplate.js";
import LeadGumloop from "./pages/Gumloop/LeadGumloop.js";
import ProgTemplate from "./pages/Template/ProgTemplate.js";
import ProgGumloop from "./pages/Gumloop/ProgGumloop.js";
import AssistantMain from "./components/AssistantComponent/AssistantMain/AssistantMain.js";
import Gallery from "./pages/Gallery/Gallery.js";
import NewTemplate from "./pages/NewTemplate/newTemplate.js";
import CommonTemplate from "./pages/Template/CommonTemplate.js";
import './App.css'
import Segment from "./pages/Segment/Segment.js";

function App() {
  LogRocket.init('rs0lbg/appgtmcopilot');
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("loginToken"));
  const [redirectPath, setRedirectPath] = useState(null);
  const [connectedApps, setConnectedApps] = useRecoilState(integrationStatusAtom);
  const [pyodide, setPyodide] = useState(null);
  const [isPyodideLoading, setIsPyodideLoading] = useRecoilState(isPyodideLoadingAtom);
  const [loadError, setLoadError] = useRecoilState(pyodideLoadErrorAtom);
  useEffect(() => {
    // Initializing LogRocket
    // LogRocket.init('rs0lbg/appgtmcopilot');
    const user = localStorage.getItem("loginToken") ? jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')) : null

    if (user) {
      LogRocket.identify(user?.id, {
        name: user?.name,
        email: user?.email,
      });
    }
  }, []);
  const getUserStatus = async () => {
    if (isLoggedIn) {
      let login = await GetUserStatus({
        userid: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id
      });

      if (login.data.status === "failed") {
        localStorage.clear();
        message.error("It seems like there is some issue. Please re-login.");
        setTimeout(() => {
          navigate("/login");
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoggedIn && (location.pathname !== "/login" && location.pathname !== "/sign-up" && !location.pathname.includes("verify-user") && !location.pathname.includes("reset-password"))) {
      setRedirectPath(location.pathname);
    } else if (isLoggedIn && location.pathname !== "/login" && location.pathname !== "/sign-up" && !location.pathname.includes("verify-user") && !location.pathname.includes("reset-password")) {
      getUserStatus();
      getIntegrationStatus();
    }
    console.log(redirectPath, "redirectPath");
  }, [isLoggedIn, location.pathname]);

  const handleLogin = () => {
    navigate(redirectPath || "/assistant");
  };

  useEffect(() => {
    if (isLoggedIn) {
      const userId = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id;
      if (!localStorage.getItem('tenantid')) {
        getTenantID();
      }
    }
  }, []);

  const getTenantID = async () => {
    const data = {
      tenant_name: "zillasecurity.com",
    };
    let response = await getTenant(data);
    if (response.status === 200) {
      localStorage.setItem("tenantid", response.data?.data.tenantid);
    }
  };

  const getIntegrationStatus = async () => {
    const data = {
      userid: jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).id,
    };
    let response = await integrationStatus(data);
    if (response.status === 200) {
      const connectedAppsWithoutSlack = response?.data?.data?.filter((app) => app !== 'Slack');
      setConnectedApps(connectedAppsWithoutSlack);
    }
    console.log(connectedApps, "ConnectedApps");
  };

  return (
    <div className={`App`} style={{ height: "100vh" }}>
      {/* {isPyodideLoading && <div>Loading Pyodide...</div>} */}
      {loadError && <div>Failed to load Pyodide. Please try again later.</div>}
      <Routes>
        <Route path="/sign-up" element={<SignUp setIsLoggedIn={setIsLoggedIn} onLogin={handleLogin} />} />
        <Route path="/login" element={<LogIn setIsLoggedIn={setIsLoggedIn} onLogin={handleLogin} />} />
        <Route path="/onboarding" element={isLoggedIn ? <ProtectedRoute><OnboardingV2 /></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/workspaces" element={isLoggedIn ? <ProtectedRoute><Workspaces /></ProtectedRoute> : <Navigate to="/login" />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-user/:verification_token/:user_id" name="verifyUser Page" element={<VerifyPassword />} />
        {/* <Route path='/reset-password/:reset_code/:user_id' name='ResetPassword Page' element={<ResetPassword />} /> */}
        {/* <Route path="/" element={isLoggedIn ? <ProtectedRoute><WorkspaceRoute><Main2 setIsLoggedIn={setIsLoggedIn} /></WorkspaceRoute></ProtectedRoute> : <Navigate to="/login" />}> */}
        <Route path="/" element={isLoggedIn ? <Main2 setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/login" />}>

          <Route path="/" element={<Navigate to={'/assistant'} />} />
          <Route path="/chat" element={<ChatWindow />} />
          <Route path="/discover" element={<AutoDiscover />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/new-agent" element={<Agents />} />
          <Route path="/integration" element={<Integration />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/home" element={<Home />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/copilot" element={<Copilot />} />
          <Route path="/assistant" element={<Copilot />} />
          <Route path="/cc-demo" element={<Copilot />} />
          <Route path="/toast" element={<Toast />} />
          <Route path="/old-agents" element={<NewAgent />} />
          <Route path="/agents" element={<Gallery />} />
          <Route path="/audiences" element={<Audiences />} />
          <Route path="/audiences/old-new-segment" element={<NewSegmentComponent />} />
          <Route path="/audiences/new-segment" element={<Segment />} />
          <Route path="/audiences/segment-studio" element={<SegmentStudioComponent />} />
          <Route path="/live" element={<Live />} />
          <Route path="/old-templates/sales_crm" element={<Template />} />
          <Route path="/templates/sales_crm" element={<NewTemplate />} />

          <Route path="/old-templates/enrichment" element={<EnrichmentTemplate />} />
          <Route path="/templates/enrichment" element={<NewTemplate />} />

          <Route path="/old-templates/lead" element={<LeadTemplate />} />
          <Route path="/templates/lead" element={<NewTemplate />} />

          <Route path="/old-templates/prog" element={<ProgTemplate />} />
          <Route path="/templates/prog" element={<NewTemplate />} />
          <Route path="/templates/enable/:name" element={<CommonTemplate />} />
          <Route path="/pipeline" element={<Gumloop />} />
          <Route path="/enrichment" element={<EnrichmentGumloop />} />
          <Route path="/lead" element={<LeadGumloop />} />
          <Route path="/ttyd-advanced" element={<DemoSuzieCopilot />} />
          {/* <Route path="/test" element={<GraphOptions />} /> */}
          <Route path="/chat-history" element={<ChatHistory />} />

          <Route path="/prog" element={<ProgGumloop />} />
          <Route path="/assistant_v2" element={<AssistantMain />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;




