import React, { useEffect, useState, useRef } from "react";
import Box from "./Box";
import Line from "./Line";
import ConversionRate from "./ConversionRate";
import MiddleLine from "./MiddleLine";
import UpperLine from "./UpperLine";
import MiddleUpperLine from "./MiddleUpperLine";
import ConversionUpperRate from "./ConversionUpperRate";
import { Card, Input, Select } from "antd";
import { useRecoilValue } from "recoil";
import { activeCompanyAtom } from "../../../store";
import { SaveFilled, EditFilled, InfoCircleFilled } from "@ant-design/icons";
import {
  activeTabAtom,
  dropAtom,
} from "../../../store/atoms/AnalyticsAtoms.js";

const { Option } = Select;

const App = () => {
  const activeCompany = useRecoilValue(activeCompanyAtom);
  const activeTab = useRecoilValue(activeTabAtom);
  const drop = useRecoilValue(dropAtom);
  const [visibleCard, setVisibleCard] = useState(null);
  const [edit, setEdit] = useState(false);
  const firstRef = useRef(null);
  const [filters, setFilters] = useState([
    [
      {
        id: 1,
        selectedColumn: "ICP Name",
        selectedOperator: "EQUALS",
        selectedValue: "TRUE",
      },
    ],
  ]);
  const [filterDiffs, setFilterDiffs] = useState([[["OR"]]]);
  const [blockOperators, setBlockOperators] = useState([]); // For operators between filter blocks
  const [filterNames, setFilterNames] = useState([""]); // For filter block names
  const scrollRef = useRef(null);

  const defaultFilter = [
    {
      id: 1,
      selectedColumn: "ICP Name",
      selectedOperator: "EQUALS",
      selectedValue: "TRUE",
    },
  ];
  const defaultFilterDiff = [["OR"]];

  const renderBoxValues = (boxNo) => { // boxNo is actually Stage Name's Index (eg: 0 => "Identified")
    if ([0, 5].includes(activeTab)) {
      switch (boxNo) {
        case 0:
          return 1100
        case 1:
          return 1000
        case 2:
          return 614
        case 3:
          return 237
        case 4:
          return 167
        case 5:
          return 74
        case 6:
          return 38
        case 7:
          return 26
        case 8:
          return 6
        default:
          return null;
      }
    } else if (activeTab === 1) {  // Channels
      switch (boxNo) {
        case 0:
          return activeCompany === 0
            ? 1100
            : activeCompany === 1
            ? 228
            : activeCompany === 2
            ? 226
            : activeCompany === 3
            ? 229
            : activeCompany === 4
            ? 211
            : 206;
        case 1:
          return activeCompany === 0
            ? 800
            : activeCompany === 1
            ? 150
            : activeCompany === 2
            ? 145
            : activeCompany === 3
            ? 140
            : activeCompany === 4
            ? 130
            : 125;
        case 2:
          return activeCompany === 0
            ? 606
            : activeCompany === 1
            ? 127
            : activeCompany === 2
            ? 122
            : activeCompany === 3
            ? 132
            : activeCompany === 4
            ? 113
            : 112;
        case 3:
          return activeCompany === 0
            ? 229
            : activeCompany === 1
            ? 41
            : activeCompany === 2
            ? 52
            : activeCompany === 3
            ? 62
            : activeCompany === 4
            ? 37
            : 37;
        case 4:
          return activeCompany === 0
            ? 166
            : activeCompany === 1
            ? 33
            : activeCompany === 2
            ? 36
            : activeCompany === 3
            ? 41
            : activeCompany === 4
            ? 31
            : 25;
        case 5:
          return activeCompany === 0
            ? 124
            : activeCompany === 1
            ? 22
            : activeCompany === 2
            ? 28
            : activeCompany === 3
            ? 33
            : activeCompany === 4
            ? 24
            : 17;
        case 6:
          return activeCompany === 0
            ? 68
            : activeCompany === 1
            ? 12
            : activeCompany === 2
            ? 14
            : activeCompany === 3
            ? 16
            : activeCompany === 4
            ? 15
            : 11;
        case 7:
          return activeCompany === 0
            ? 51
            : activeCompany === 1
            ? 8
            : activeCompany === 2
            ? 14
            : activeCompany === 3
            ? 11
            : activeCompany === 4
            ? 13
            : 5;
        case 8:
          return activeCompany === 0
            ? 12
            : activeCompany === 1
            ? 2
            : activeCompany === 2
            ? 4
            : activeCompany === 3
            ? 1
            : activeCompany === 4
            ? 3
            : 2;
        default:
          return null;
      }
    } else if (activeTab === 2) { // Segment
      switch (boxNo) {
        case 0:
          return activeCompany === 0
            ? 1100
            : activeCompany === 1
            ? 228
            : activeCompany === 2
            ? 226
            : activeCompany === 3
            ? 229
            : activeCompany === 4
            ? 230
            : activeCompany === 5
            ? 666
            : 206;
        case 1:
          return activeCompany === 0
            ? 800
            : activeCompany === 1
            ? 210
            : activeCompany === 2
            ? 201
            : activeCompany === 3
            ? 177
            : activeCompany === 4
            ? 224
            : activeCompany === 5
            ? 188
            : 188;
        case 2:
          return activeCompany === 0
            ? 606
            : activeCompany === 1
            ? 129
            : activeCompany === 2
            ? 134
            : activeCompany === 3
            ? 112
            : activeCompany === 4
            ? 144
            : activeCompany === 5
            ? 95
            : 95;
        case 3:
          return activeCompany === 0
            ? 229
            : activeCompany === 1
            ? 46
            : activeCompany === 2
            ? 54
            : activeCompany === 3
            ? 38
            : activeCompany === 4
            ? 65
            : activeCompany === 5
            ? 36
            : 36;
        case 4:
          return activeCompany === 0
            ? 166
            : activeCompany === 1
            ? 32
            : activeCompany === 2
            ? 34
            : activeCompany === 3
            ? 36
            : activeCompany === 4
            ? 29
            : activeCompany === 5
            ? 34
            : 34;
        case 5:
          return activeCompany === 0
            ? 124
            : activeCompany === 1
            ? 15
            : activeCompany === 2
            ? 15
            : activeCompany === 3
            ? 20
            : activeCompany === 4
            ? 5
            : activeCompany === 5
            ? 19
            : 19;
        case 6:
          return activeCompany === 0
            ? 68
            : activeCompany === 1
            ? 6
            : activeCompany === 2
            ? 8
            : activeCompany === 3
            ? 11
            : activeCompany === 4
            ? 3
            : activeCompany === 5
            ? 10
            : 10;
        case 7:
          return activeCompany === 0
            ? 51
            : activeCompany === 1
            ? 4
            : activeCompany === 2
            ? 5
            : activeCompany === 3
            ? 9
            : activeCompany === 4
            ? 2
            : activeCompany === 5
            ? 6
            : 6;
        case 8:
          return activeCompany === 0
            ? 12
            : activeCompany === 1
            ? 2
            : activeCompany === 2
            ? 1
            : activeCompany === 3
            ? 2
            : activeCompany === 4
            ? 1
            : activeCompany === 5
            ? 3
            : 3;
        default:
          return null;
      }
    } else if (activeTab === 3) { // Teams
      switch (boxNo) {
        case 0:
          return activeCompany === 0
            ? 1100
            : activeCompany === 1
            ? 500
            : activeCompany === 2
            ? 450
            : activeCompany === 3
            ? 360
            : 206;
        case 1:
          return activeCompany === 0
            ? 800
            : activeCompany === 1
            ? 354
            : activeCompany === 2
            ? 340
            : activeCompany === 3
            ? 306
            : 306;
        case 2:
          return activeCompany === 0
            ? 606
            : activeCompany === 1
            ? 224
            : activeCompany === 2
            ? 208
            : activeCompany === 3
            ? 180
            : 182;
        case 3:
          return activeCompany === 0
            ? 229
            : activeCompany === 1
            ? 89
            : activeCompany === 2
            ? 82
            : activeCompany === 3
            ? 66
            : 66;
        case 4:
          return activeCompany === 0
            ? 166
            : activeCompany === 1
            ? 53
            : activeCompany === 2
            ? 65
            : activeCompany === 3
            ? 49
            : 49;
        case 5:
          return activeCompany === 0
            ? 124
            : activeCompany === 1
            ? 16
            : activeCompany === 2
            ? 32
            : activeCompany === 3
            ? 26
            : 26;
        case 6:
          return activeCompany === 0
            ? 68
            : activeCompany === 1
            ? 11
            : activeCompany === 2
            ? 16
            : activeCompany === 3
            ? 11
            : 11;
        case 7:
          return activeCompany === 0
            ? 51
            : activeCompany === 1
            ? 5
            : activeCompany === 2
            ? 13
            : activeCompany === 3
            ? 8
            : 8;
        case 8:
          return activeCompany === 0
            ? 12
            : activeCompany === 1
            ? 1
            : activeCompany === 2
            ? 3
            : activeCompany === 3
            ? 3
            : 3;
        default:
          return null;
      }
    } else if (activeTab === 4) { // Time Period
      if (drop === "Month") {
        switch (boxNo) {
          case 0:
            return activeCompany === 0
              ? 300
              : activeCompany === 1
              ? 228
              : activeCompany === 2
              ? 226
              : activeCompany === 3
              ? 229
              : activeCompany === 4
              ? 211
              : activeCompany === 5
              ? 229
              : activeCompany === 6
              ? 214
              : activeCompany === 7
              ? 300
              : activeCompany === 8
              ? 314
              : activeCompany === 9
              ? 295
              : activeCompany === 10
              ? 290
              : activeCompany === 11
              ? 250
              : activeCompany === 12
              ? 280
              : 206;
          case 1:
            return activeCompany === 0
              ? 71
              : activeCompany === 1
              ? 94
              : activeCompany === 2
              ? 82
              : activeCompany === 3
              ? 87
              : activeCompany === 4
              ? 107
              : activeCompany === 5
              ? 83
              : activeCompany === 6
              ? 107
              : activeCompany === 7
              ? 98
              : activeCompany === 8
              ? 89
              : activeCompany === 9
              ? 90
              : activeCompany === 10
              ? 77
              : activeCompany === 11
              ? 15
              : 15;
          case 2:
            return activeCompany === 0
              ? 36
              : activeCompany === 1
              ? 54
              : activeCompany === 2
              ? 56
              : activeCompany === 3
              ? 57
              : activeCompany === 4
              ? 60
              : activeCompany === 5
              ? 50
              : activeCompany === 6
              ? 68
              : activeCompany === 7
              ? 58
              : activeCompany === 8
              ? 64
              : activeCompany === 9
              ? 59
              : activeCompany === 10
              ? 40
              : activeCompany === 11
              ? 12
              : 12;
          case 3:
            return activeCompany === 0
              ? 16
              : activeCompany === 1
              ? 24
              : activeCompany === 2
              ? 24
              : activeCompany === 3
              ? 22
              : activeCompany === 4
              ? 22
              : activeCompany === 5
              ? 17
              : activeCompany === 6
              ? 29
              : activeCompany === 7
              ? 25
              : activeCompany === 8
              ? 27
              : activeCompany === 9
              ? 17
              : activeCompany === 10
              ? 15
              : activeCompany === 11
              ? 10
              : 10;
          case 4:
            return activeCompany === 0
              ? 15
              : activeCompany === 1
              ? 23
              : activeCompany === 2
              ? 8
              : activeCompany === 3
              ? 13
              : activeCompany === 4
              ? 19
              : activeCompany === 5
              ? 16
              : activeCompany === 6
              ? 19
              : activeCompany === 7
              ? 23
              : activeCompany === 8
              ? 16
              : activeCompany === 9
              ? 14
              : activeCompany === 10
              ? 15
              : activeCompany === 11
              ? 8
              : 8;
          case 5:
            return activeCompany === 0
              ? 6
              : activeCompany === 1
              ? 13
              : activeCompany === 2
              ? 4
              : activeCompany === 3
              ? 6
              : activeCompany === 4
              ? 7
              : activeCompany === 5
              ? 9
              : activeCompany === 6
              ? 8
              : activeCompany === 7
              ? 12
              : activeCompany === 8
              ? 9
              : activeCompany === 9
              ? 8
              : activeCompany === 10
              ? 10
              : activeCompany === 11
              ? 7
              : 7;
          case 6:
            return activeCompany === 0
              ? 4
              : activeCompany === 1
              ? 4
              : activeCompany === 2
              ? 2
              : activeCompany === 3
              ? 3
              : activeCompany === 4
              ? 2
              : activeCompany === 5
              ? 6
              : activeCompany === 6
              ? 3
              : activeCompany === 7
              ? 7
              : activeCompany === 8
              ? 7
              : activeCompany === 9
              ? 3
              : activeCompany === 10
              ? 8
              : activeCompany === 11
              ? 5
              : 5;
          case 7:
            return activeCompany === 0
              ? 4
              : activeCompany === 1
              ? 3
              : activeCompany === 2
              ? 2
              : activeCompany === 3
              ? 2
              : activeCompany === 4
              ? 2
              : activeCompany === 5
              ? 4
              : activeCompany === 6
              ? 2
              : activeCompany === 7
              ? 3
              : activeCompany === 8
              ? 4
              : activeCompany === 9
              ? 2
              : activeCompany === 10
              ? 5
              : activeCompany === 11
              ? 3
              : 3;
          case 8:
            return activeCompany === 0
              ? 1
              : activeCompany === 1
              ? 1
              : activeCompany === 2
              ? 1
              : activeCompany === 3
              ? 1
              : activeCompany === 4
              ? 1
              : activeCompany === 5
              ? 2
              : activeCompany === 6
              ? 1
              : activeCompany === 7
              ? 1
              : activeCompany === 8
              ? 2
              : activeCompany === 9
              ? 1
              : activeCompany === 10
              ? 2
              : activeCompany === 11
              ? 1
              : 1;
          default:
            return null;
        }
      } else if (drop === "Quarter") {
        switch (boxNo) {
          case 0:
            return activeCompany === 0
              ? 1100
              : activeCompany === 1
              ? 350
              : activeCompany === 2
              ? 400
              : activeCompany === 3
              ? 229
              : 206;
          case 1:
            return activeCompany === 0
              ? 247
              : activeCompany === 1
              ? 277
              : activeCompany === 2
              ? 294
              : activeCompany === 3
              ? 182
              : 182;
          case 2:
            return activeCompany === 0
              ? 146
              : activeCompany === 1
              ? 167
              : activeCompany === 2
              ? 190
              : activeCompany === 3
              ? 111
              : 111;
          case 3:
            return activeCompany === 0
              ? 64
              : activeCompany === 1
              ? 58
              : activeCompany === 2
              ? 79
              : activeCompany === 3
              ? 36
              : 36;
          case 4:
            return activeCompany === 0
              ? 46
              : activeCompany === 1
              ? 51
              : activeCompany === 2
              ? 60
              : activeCompany === 3
              ? 10
              : 10;
          case 5:
            return activeCompany === 0
              ? 23
              : activeCompany === 1
              ? 22
              : activeCompany === 2
              ? 29
              : activeCompany === 3
              ? 8
              : 8;
          case 6:
            return activeCompany === 0
              ? 10
              : activeCompany === 1
              ? 11
              : activeCompany === 2
              ? 17
              : activeCompany === 3
              ? 6
              : 6;
          case 7:
            return activeCompany === 0
              ? 9
              : activeCompany === 1
              ? 8
              : activeCompany === 2
              ? 9
              : activeCompany === 3
              ? 4
              : 4;
          case 8:
            return activeCompany === 0
              ? 3
              : activeCompany === 1
              ? 3
              : activeCompany === 2
              ? 3
              : activeCompany === 3
              ? 2
              : 2;
          default:
            return null;
        }
      } else if (drop === "Year") {
        switch (boxNo) {
          case 0:
            return 1100
          case 1:
            return 1000
          case 2:
            return 614
          case 3:
            return 237
          case 4:
            return 167
          case 5:
            return 74
          case 6:
            return 38
          case 7:
            return 26
          case 8:
            return 6
          default:
            return null;
        }
      }
    } else {
      switch (boxNo) {
        case 0:
          return activeCompany === 0
            ? 1100
            : activeCompany === 1
            ? 228
            : activeCompany === 2
            ? 226
            : activeCompany === 3
            ? 229
            : activeCompany === 4
            ? 211
            : 206;
        case 1:
          return activeCompany === 0
            ? 800
            : activeCompany === 1
            ? 150
            : activeCompany === 2
            ? 145
            : activeCompany === 3
            ? 140
            : activeCompany === 4
            ? 130
            : 125;
        case 2:
          return activeCompany === 0
            ? 606
            : activeCompany === 1
            ? 127
            : activeCompany === 2
            ? 122
            : activeCompany === 3
            ? 132
            : activeCompany === 4
            ? 113
            : 112;
        case 3:
          return activeCompany === 0
            ? 229
            : activeCompany === 1
            ? 41
            : activeCompany === 2
            ? 52
            : activeCompany === 3
            ? 62
            : activeCompany === 4
            ? 37
            : 37;
        case 4:
          return activeCompany === 0
            ? 166
            : activeCompany === 1
            ? 33
            : activeCompany === 2
            ? 36
            : activeCompany === 3
            ? 41
            : activeCompany === 4
            ? 31
            : 25;
        case 5:
          return activeCompany === 0
            ? 124
            : activeCompany === 1
            ? 22
            : activeCompany === 2
            ? 28
            : activeCompany === 3
            ? 33
            : activeCompany === 4
            ? 24
            : 17;
        case 6:
          return activeCompany === 0
            ? 68
            : activeCompany === 1
            ? 12
            : activeCompany === 2
            ? 14
            : activeCompany === 3
            ? 16
            : activeCompany === 4
            ? 15
            : 11;
        case 7:
          return activeCompany === 0
            ? 51
            : activeCompany === 1
            ? 8
            : activeCompany === 2
            ? 14
            : activeCompany === 3
            ? 11
            : activeCompany === 4
            ? 13
            : 5;
        case 8:
          return activeCompany === 0
            ? 12
            : activeCompany === 1
            ? 2
            : activeCompany === 2
            ? 4
            : activeCompany === 3
            ? 1
            : activeCompany === 4
            ? 3
            : 2;
        default:
          return null;
      }
    }
  };

  const [boxes, setBoxes] = useState([
    {
      name: "Identified",
      value: renderBoxValues(0),
      description:
        "This stage represents the potential customers that are a good fit for your product",
    },
    {
      name: "Interested",
      value: renderBoxValues(1),
      description:
        "This stage includes potential customers who have shown initial interest in your product or service.",
    },
    {
      name: "Engaged",
      value: renderBoxValues(2),
      description:
        "This stage represents the potential customers who are actively engaged.",
    },
    {
      name: "Priority",
      value: renderBoxValues(3),
      description:
        "This stage represents potential customers who have been qualified as high-value leads and are more likely to make a purchase.",
    },
    {
      name: "Committed",
      value: renderBoxValues(4),
      description:
        "This stage includes potential customers who have expressed a strong intent to buy your product or service.",
    },
    {
      name: "First Impact",
      value: renderBoxValues(5),
      description:
        "This stage represents new customers who have recently purchased your product and are in the process of onboarding and initial value realization.",
    },
    {
      name: "Recurring Impact",
      value: renderBoxValues(6),
      description:
        "This stage includes customers who are consistently deriving value from your product and are likely to continue using it.",
    },
    {
      name: "Retention",
      value: renderBoxValues(7),
      description:
        "This stage represents customers who have been with your company for a significant period and are satisfied with your product or service.",
    },
    {
      name: "Expansion",
      value: renderBoxValues(8),
      description:
        "This stage includes customers who have the potential to increase their spending or usage of your product through upsells, cross-sells, or package upgrades.",
    },
  ]);

  useEffect(() => {
    if (activeTab !== 5) {
      setVisibleCard(null)
    }
    const newBoxes = boxes.map((prevBoxes, index) => (
      {
        ...prevBoxes,
        value: renderBoxValues(index)
      }
    ))
    setBoxes(newBoxes)
  }, [activeTab, activeCompany])

  const handleBoxClick = (index) => {
    if (activeTab !== 5) {
      setVisibleCard(null);
      return;
    }
    // setVisibleCard(index === visibleCard ? null : index);
    setVisibleCard(index);
    setFilters([defaultFilter]);
    setFilterDiffs([defaultFilterDiff]);
    setBlockOperators([]); // Reset block operators
    setFilterNames([""]); // Reset filter names
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100)
  };

  const addFilter = (blockIndex) => {
    const updatedFilters = filters.map((block, bIndex) =>
      bIndex === blockIndex
        ? [
            ...block,
            {
              id: block.length + 1,
              selectedColumn: "ICP Name",
              selectedOperator: "EQUALS",
              selectedValue: "TRUE",
            },
          ]
        : block
    );
    const updatedFilterDiffs = filterDiffs.map((diffs, dIndex) =>
      dIndex === blockIndex ? [...diffs, "OR"] : diffs
    );

    setFilters(updatedFilters);
    setFilterDiffs(updatedFilterDiffs);
  };

  const addFilterBlock = () => {
    setFilters([...filters, defaultFilter]);
    setFilterDiffs([...filterDiffs, defaultFilterDiff]);
    setBlockOperators([...blockOperators, "AND"]); // Default operator between blocks
    setFilterNames([...filterNames, ""]); // Add default filter name
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const updateFilter = (blockIndex, filterIndex, key, value) => {
    setFilters(
      filters.map((block, bIndex) =>
        bIndex === blockIndex
          ? block.map((filter, fIndex) =>
              fIndex === filterIndex ? { ...filter, [key]: value } : filter
            )
          : block
      )
    );
  };

  const updateFilterDiff = (blockIndex, filterIndex, value) => {
    setFilterDiffs(
      filterDiffs.map((block, bIndex) =>
        bIndex === blockIndex
          ? block.map((diff, dIndex) => (dIndex === filterIndex ? value : diff))
          : block
      )
    );
  };

  const updateBlockOperator = (index, value) => {
    setBlockOperators(
      blockOperators.map((op, opIndex) => (opIndex === index ? value : op))
    );
  };

  const updateFilterName = (index, value) => {
    setFilterNames(
      filterNames.map((name, nameIndex) => (nameIndex === index ? value : name))
    );
  };

  const handleChange = (e, idx) => {
    setBoxes(prevBoxes => {
      const newBoxes = [...prevBoxes]
      newBoxes[idx] = {
        ...newBoxes[idx],
        name: e.target.value
      }
      return newBoxes
    })
  }

  useEffect(() => {
    if (!edit) {
      firstRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [edit])

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          marginTop: "13rem",
          paddingBottom: "10rem",
          gap: "2rem",
        }}
        ref={firstRef}
      >
        {boxes.map(({ name, value, description }, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              cursor: activeTab === 5 ? "pointer" : "default"
            }}
            onClick={() => handleBoxClick(index)}
          >
            <Box label={name} value={value} index={index} />
            <Line index={index} />

            {(index === 4 || index === 5 || index === 2 || index === 7) && (
              <>
                <UpperLine index={index} />
                {index !== 7 && (
                  <>
                    <MiddleUpperLine index={index} />
                    {(index === 2 || index === 5) && (
                      <ConversionUpperRate
                        rate={((boxes[4].value / boxes[2].value) * 100).toFixed(
                          2
                        )}
                        type={"rate"}
                        index={index}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {index < boxes.length - 1 && (
              <>
                <MiddleLine />
                <ConversionRate
                  rate={(
                    (boxes[index + 1].value / boxes[index].value) *
                    100
                  ).toFixed(2)}
                  index={index}
                  type={"rate"}
                />
              </>
            )}
          </div>
        ))}
        <div ref={scrollRef} />
      </div>
      {visibleCard !== null && (
        <Card>
          <div className="flex justify-around">
            <div className="flex justify-center gap-4 items-center w-1/2">
              {edit ? (
                <>
                  <Input
                    placeholder={boxes[visibleCard].name}
                    onChange={(e) => handleChange(e, visibleCard)}
                    style={{ width: '150px' }}
                    onPressEnter={() => setEdit(!edit)}
                  />
                  <SaveFilled className="cursor-pointer" onClick={() => setEdit(!edit)}/>
                </>
              ) : (
                <>
                  <span className="text-[18px]">{boxes[visibleCard].name}</span>
                  <EditFilled className="cursor-pointer" onClick={() => setEdit(!edit)}/>
                </>
              )}
            </div>
            <Card className="w-1/2">
              <div className="flex justify-center items-center gap-2">
                <InfoCircleFilled />
                <h6>About this stage</h6>
              </div>
              <div className="ml-2">{boxes[visibleCard].description}</div>
            </Card>
          </div>
          <div className="flex flex-col w-full">
            {filters.map((block, blockIndex) => (
              <React.Fragment key={`block-${blockIndex}`}>
                <div className="p-2 border border-[1px] flex flex-col gap-4 mb-4">
                  <Input
                    placeholder="Filter Block Name"
                    value={filterNames[blockIndex]}
                    className="w-fit text-center font-semibold text-base mb-2"
                    onChange={(e) =>
                      updateFilterName(blockIndex, e.target.value)
                    }
                  />

                  {block.map((filter, filterIndex) => (
                    <div key={filter.id} className="flex flex-col gap-2">
                      <div className="flex justify-around items-center gap-2 pl-10 text-base">
                        <Select
                          defaultValue="System"
                          className="flex justify-center gap-4 items-center py-[0.2rem] px-8 rounded-md"
                        >
                          <Option value="System">System</Option>
                        </Select>
                        <Select
                          defaultValue="Object"
                          className="flex justify-center gap-4 items-center py-[0.2rem] px-8 rounded-md"
                        >
                          <Option value="Object">Object</Option>
                        </Select>
                        <Select
                          defaultValue="Field"
                          className="flex justify-center gap-4 items-center py-[0.2rem] px-8 rounded-md"
                        >
                          <Option value="Field">Field</Option>
                        </Select>
                        <Select
                          defaultValue="Operator"
                          className="flex justify-center gap-4 items-center py-[0.2rem] px-8 rounded-md"
                        >
                          <Option value="Operator">Operator</Option>
                        </Select>
                        <Select
                          defaultValue="Value"
                          className="flex justify-center gap-4 items-center py-[0.2rem] px-8 rounded-md"
                        >
                          <Option value="Value">Value</Option>
                        </Select>
                      </div>
                      {filterIndex + 1 < filterDiffs[blockIndex].length && (
                        <Select
                          id={`filterDiff-${blockIndex}-${filterIndex}`}
                          value={filterDiffs[blockIndex][filterIndex]}
                          placeholder="Select Filter Diff"
                          onChange={(value) =>
                            updateFilterDiff(blockIndex, filterIndex, value)
                          }
                          options={[
                            { label: "OR", value: "OR" },
                            { label: "AND", value: "AND" },
                            { label: "NOT", value: "NOT" },
                          ]}
                          className="flex justify-center items-center text-base w-[8%] text-center mt-2"
                        />
                      )}
                    </div>
                  ))}

                  <div className="flex justify-center items-center ml-3 mt-2">
                    <div
                      className="flex justify-center items-center py-1 px-4 rounded-md cursor-pointer hover:scale-105 hover:bg-[#8EA9D0] duration-300 ease-in-out bg-gray-700 text-white text-base"
                      onClick={() => addFilter(blockIndex)}
                    >
                      Add a Filter Condition
                    </div>
                  </div>
                </div>
                {blockIndex < filters.length - 1 && (
                  <Select
                    value={blockOperators[blockIndex]}
                    placeholder="Select Block Operator"
                    onChange={(value) => updateBlockOperator(blockIndex, value)}
                    options={[
                      { label: "AND", value: "AND" },
                      { label: "OR", value: "OR" },
                    ]}
                    className="flex justify-center items-center text-base w-[8%] text-center my-4"
                  />
                )}
              </React.Fragment>
            ))}

            <div className="flex justify-center items-center ml-3 mt-2">
              <div
                className="flex justify-center items-center py-1 px-4 rounded-md cursor-pointer hover:scale-105 hover:bg-[#8EA9D0] duration-300 ease-in-out bg-gray-700 text-white text-base"
                onClick={addFilterBlock}
              >
                Add a Filter Block
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default App;
