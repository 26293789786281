import React from 'react';
import { Card, Row, Col, Button } from 'antd';
import chatgpt from '../../../assets/images/chatgpt.jpg';
import mistral from '../../../assets/images/mistral.png';
import gemini from '../../../assets/images/gemini.png';
import claude from '../../../assets/images/claude.png';
import llama from '../../../assets/images/llama.png';

// Define an object that maps card titles to image paths
const imageMap = {
    'ChatGPT 4': chatgpt,
    'Claude 3 Opus': claude,
    'Gemini Pro': gemini,
    'Mistral': mistral,
    'Llama 3': llama,
};

const cardData = [
    {
        title: 'ChatGPT 4',
        description: 'Standard ChatGPT 4 from OpenAI',
    },
    {
        title: 'Claude 3 Opus',
        description: 'Standard Claude 3 Opus from Anthropic',
    },
    {
        title: 'Gemini Pro',
        description: 'Standard Gemini 1.5 Pro from Google',
    },
    {
        title: 'Mistral',
        description: 'Mistral AI product ',
    },
    {
        title: 'Llama 3',
        description: 'Standard Llama 3 from Meta.',
    },
];

const LLM = () => (
    <Row className='mt-4' gutter={[16, 16]}>
        <div className='flex justify-between items-center gap-6 w-[95%]'>
            <div className='text-[16px] font-[600]  m-4'>All LLM's</div>
            <Button type='primary'>+ Add LLM</Button>
        </div>
        {cardData.map((card, index) => (
            <Col xs={24} sm={12} md={8} key={index}>
                <Card className='bg-[#f3f3f3]'
                    title={
                        <span className='flex items-center'>
                            <img
                                src={imageMap[card.title]}
                                alt={card.title}
                                style={{ width: card.title == 'ChatGPT 4' ? 35 : 25, height: 22, marginRight: 8 }}
                            />
                            {card.title}
                        </span>
                    }
                    bordered={false}
                    hoverable
                >
                    {card.description}
                </Card>
            </Col>
        ))}
    </Row>
);

export default LLM;
