import React, { useState, useEffect } from 'react';
import { Table, Button, Menu, Dropdown, Tag, Input, Card, Typography } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, BarChartOutlined } from '@ant-design/icons';
import NavigationComponent from './NavigationComponent';
import { useNavigate } from 'react-router-dom';
import AgentCustomBtn from '../AgentCustomBtn/AgentCustomBtn';

const AudienceComponent = () => {
  const {Title} = Typography
const navigate = useNavigate()
const segments = [
    {
      name: "High Spenders",
      folder: "By Lifecycle/Loyal Customers",
      audience: "All Customers",
      size: 3289,
      lastUpdated: "2024-07-08",
      status: "Active",
      destinations: ["FB", "GA", "MC"],
      owner: "sarah@example.com"
    },
    {
      name: "Low Churners",
      folder: "By Lifecycle/At-risk",
      audience: "Active Users",
      size: 7291,
      lastUpdated: "2024-07-07",
      status: "In Review",
      destinations: ["FB", "GA"],
      owner: "john@example.com"
    },
    {
      name: "Medium Spenders",
      folder: "By Lifecycle/Loyal Customers",
      audience: "All Customers",
      size: 5890,
      lastUpdated: "2024-07-06",
      status: "Active",
      destinations: ["FB", "GA", "MC"],
      owner: "anna@example.com"
    },
    {
      name: "New Customers",
      folder: "By Acquisition/New Customers",
      audience: "New Users",
      size: 1500,
      lastUpdated: "2024-07-05",
      status: "In Review",
      destinations: ["FB", "GA"],
      owner: "mike@example.com"
    },
    {
      name: "Returning Customers",
      folder: "By Lifecycle/Returning Customers",
      audience: "Returning Users",
      size: 4520,
      lastUpdated: "2024-07-04",
      status: "Active",
      destinations: ["GA", "MC"],
      owner: "susan@example.com"
    },
    {
      name: "Engaged Users",
      folder: "By Engagement/Highly Engaged",
      audience: "Active Users",
      size: 6300,
      lastUpdated: "2024-07-03",
      status: "Active",
      destinations: ["FB", "GA", "MC"],
      owner: "dave@example.com"
    },
    {
      name: "Dormant Users",
      folder: "By Engagement/Dormant",
      audience: "Inactive Users",
      size: 8200,
      lastUpdated: "2024-07-02",
      status: "Inactive",
      destinations: ["GA", "MC"],
      owner: "kate@example.com"
    },
    {
      name: "Churned Users",
      folder: "By Lifecycle/Churned",
      audience: "Churned Users",
      size: 9400,
      lastUpdated: "2024-07-01",
      status: "In Review",
      destinations: ["FB", "MC"],
      owner: "chris@example.com"
    },
    {
      name: "Premium Subscribers",
      folder: "By Subscription/Premium",
      audience: "Premium Users",
      size: 3120,
      lastUpdated: "2024-06-30",
      status: "Active",
      destinations: ["FB", "GA"],
      owner: "steve@example.com"
    },
    {
      name: "Standard Subscribers",
      folder: "By Subscription/Standard",
      audience: "Standard Users",
      size: 4780,
      lastUpdated: "2024-06-29",
      status: "Active",
      destinations: ["GA", "MC"],
      owner: "lisa@example.com"
    },
    {
      name: "Trial Users",
      folder: "By Subscription/Trial",
      audience: "Trial Users",
      size: 2300,
      lastUpdated: "2024-06-28",
      status: "In Review",
      destinations: ["FB", "MC"],
      owner: "kevin@example.com"
    },
    {
      name: "Gold Members",
      folder: "By Membership/Gold",
      audience: "Gold Members",
      size: 1900,
      lastUpdated: "2024-06-27",
      status: "Active",
      destinations: ["FB", "GA"],
      owner: "amy@example.com"
    },
    {
      name: "Silver Members",
      folder: "By Membership/Silver",
      audience: "Silver Members",
      size: 2200,
      lastUpdated: "2024-06-26",
      status: "In Review",
      destinations: ["GA", "MC"],
      owner: "paul@example.com"
    }
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(segments);
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Folder',
      dataIndex: 'folder',
      key: 'folder',
    },
    {
      title: 'Audience',
      dataIndex: 'audience',
      key: 'audience',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Tag color={status === 'Active' ? 'green' : 'blue'}>{status}</Tag>
      ),
    },
    {
      title: 'Destinations',
      dataIndex: 'destinations',
      key: 'destinations',
      render: destinations => destinations.join(', '),
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className='flex justify-center gap-2'>
          <Button type="link" icon={<EditOutlined />} />
          <Button type="link" icon={<BarChartOutlined />} />
        </div>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item onClick={() => window.location = "audiences/new-segment"} key="1">Choose an Audience</Menu.Item>
      <Menu.Item
        key="2"
        style={{ backgroundColor: '#2966BC', color: 'white', cursor: 'default' }}
        disabled
      >
        Existing Customers
      </Menu.Item>
      <Menu.Item key="3">ICP</Menu.Item>
      <Menu.Item key="4">High Intent</Menu.Item>
    </Menu>
  );

  return (
    <div className='mt-4'>
      <header className='text-[#8EA9D0]' style={{ marginBottom: '20px' }}>
        <Title style={{fontSize: "18px", marginLeft: "20px"}} level={5} type="secondary">View all your Audience and Segments in one place</Title>
      </header>
        <div className='mt-2' style={{ display: 'flex' }}>
            <div className='h-[fit-content]'>
                <NavigationComponent />
            </div>
        <Card>
            <div style={{ flex: 1, padding: 4 }}>
                <div className='flex justify-between'>
                <div className='flex justify-center gap-6'>
                    <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>3</div>
                    <div style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)' }}>Audiences</div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>13</div>
                    <div style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)' }}>Segments</div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>4</div>
                    <div style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)' }}>In-review</div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>3</div>
                    <div style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.45)' }}>Draft</div>
                    </div>
                </div>
                <div className='flex justify-end gap-2 mt-4'>
                    <AgentCustomBtn disabled={true}>
                      View Overlap
                    </AgentCustomBtn>
                    <AgentCustomBtn otherClasses={"flex gap-1 items-center"} onClick={() => navigate("/audiences/new-segment")} >
                        <PlusOutlined />
                        <p>New Segment</p>
                    </AgentCustomBtn>
                </div>
                </div>
                <div className='mt-2'>
                <span className='text-[18px] text-[#8EA9D0]'>Segments</span>
                </div>
                <div className='mt-2 flex justify-between'>
                <Input className='text-[#8EA9D0] w-[25%]' placeholder='Search Segments'/>
                <Button>Filter</Button>
                </div>
                <Table
                columns={columns}
                dataSource={data}
                rowKey="name"
                style={{ marginTop: 24 }}
                />
            </div>
        </Card>
        </div>
    </div>
  );
};

export default AudienceComponent;
