import React, { useState, useEffect, useRef } from "react";
import "./EnrichmentRightSider.css";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import {
  enrichCollapsibleAtom,
  enrichGumloopChatArrayAtom,
  enrichPolledContentAtom,
  enrichEmailAtom,
  enrichTestEmailAtom,
  enrichVisibleAtom,
  enrichActiveIndexAtom,
  enrichChecked,
  enrichNodeInProgressAtom
} from "../../store";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import EnrichmentTimer from "./EnrichmentTimer.js";
import EnrichmentComplete from "./EnrichmentComplete.js";

import inputImg from "../../assets/images/inputImg.png";
import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";
import personImg from "../../assets/images/personImg.png";
import calcImg from "../../assets/images/calcImg.png";
import parseImg from "../../assets/images/parseImg.png";
import robotImg from "../../assets/images/robotImg.png";
import EnrichmentContent from "./EnrichmentContent.js";

const siderProcess = [
  {
    id: "node-1",
    type: "ioNode",
    position: { x: 0, y: 0 },
    data: {
      label: "User Email",
      icon: inputImg,
      firstKey: "Default Value:",
      firstVal: "max@hubspot.com",
      bgColor: "grey",
    },
    duration: 2,
  },
  {
    id: "node-4",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 5.68, y: 252.55 },
    data: {
      label: "Parse Email",
      icon: parseImg,
      bgColor: "peach",
    },
    duration: 2,
  },
  {
    id: "node-5",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: -587.35, y: 525.12 },
    data: {
      label: "Crawl Website",
      icon: webScraperImg,
      bgColor: "peach",
    },
    duration: 2,
  },
  {
    id: "node-14",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: -856.62, y: 764.38 },
    data: {
      label: "Summarizer",
      icon: summaryImg,
      bgColor: "green",
      firstKey: true,
    },
    duration: 2,
  },
  {
    id: "node-15",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: -376.31, y: 752.16 },
    data: {
      label: "Company Verification Through Web Scraping",
      icon: companyImg,
      bgColor: "blue",
      firstKey: "Outputs",
      firstVal: "hubspot.com",
    },
    duration: 2,
  },
  {
    id: "node-16",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: 19.19, y: 501.41 },
    data: {
      label: "Company Verification Through LinkedIn",
      icon: companyImg,
      bgColor: "blue",
      firstKey: "Inputs",
      firstVal: "domain name",
    },
    duration: 2,
  },
  {
    id: "node-13",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: 605.79, y: 500.64 },
    data: {
      label: "Person Verification",
      icon: personImg,
      bgColor: "blue",
      firstKey: "Domain Name",
      firstVal: "hubspot.com",
    },
    duration: 2,
  },
  {
    id: "node-17",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: -124.98, y: 953.46 },
    data: {
      label: "Fit Score Calculation",
      icon: calcImg,
      bgColor: "pink",
      firstKey: "Inputs",
      firstVal: "domain name",
    },
    duration: 2,
  },
  {
    id: "node-18",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: 399.81, y: 949.91 },
    data: {
      label: "Lead Score Calculation",
      icon: calcImg,
      bgColor: "pink",
      firstKey: "Inputs",
      firstVal: "domain name",
    },
    duration: 2,
  },
  {
    id: "node-3",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 40.03, y: 1263.3 },
    data: {
      label: "Agent Made Decision",
      icon: robotImg,
      firstKey: "Output Name:",
      firstVal: "research_results",
      bgColor: "grey",
    },
    duration: 2,
  },
];

const EnrichmentRightSider = () => {
  const isCollapsed = useRecoilValue(enrichCollapsibleAtom);
  const chatArray = useRecoilValue(enrichGumloopChatArrayAtom);
  const email = useRecoilValue(enrichEmailAtom);
  const polledContent = useRecoilValue(enrichPolledContentAtom);
  const [activeIndex, setActiveIndex] = useRecoilState(enrichActiveIndexAtom);
  const [visibleProcesses, setVisibleProcesses] = useRecoilState(enrichVisibleAtom);
  const testEmail = useRecoilValue(enrichTestEmailAtom);
  const setChecked = useSetRecoilState(enrichChecked);
  const [enrichNodeInProgress, setEnrichNodeInProgress] = useRecoilState(enrichNodeInProgressAtom);

  const handleTimerComplete = () => {
    // setActiveIndex((prevIndex) => prevIndex + 1);
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      setTimeout(() => {
        setVisibleProcesses([...visibleProcesses, newIndex]);
      }, 0);
      return newIndex;
    });
  };

  const ref = useRef(null);

  useEffect(() => {
    let timeout;
    // console.log(ref, "ref----------------")
    if (ref) {
      timeout = setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }

    console.log(activeIndex, "activeIndex----------->")
    let id;
    if (activeIndex !== 0) {
      id = siderProcess[visibleProcesses[visibleProcesses?.length-1]]?.id;
    }
    setEnrichNodeInProgress(id);

    // const newElement = document.querySelector(`.process-${activeIndex}`);
    // if (newElement) {
    //   setTimeout(() => {
    //     newElement.classList.add('visible');
    //   }, 100); // Timeout is needed to allow the element to be rendered before adding the class
    // }

    return () => clearTimeout(timeout);
  }, [activeIndex, visibleProcesses]);

  const giveContent = (index) => {
    switch (index) {
      case 0:
        if (!testEmail) return null
        return `**${testEmail}**`;
      case 1: {
        // if (!testEmail) return null
        const companyText = `Company Name: **${testEmail
          ?.split("@")[1]
          ?.split(".")[0]
          ?.toUpperCase()}**`;
        const personText = `Person Name: **${testEmail
          ?.split("@")[0]
          ?.replaceAll(".", " ")
          ?.toUpperCase()}**`;
        return companyText + "\n" + personText;
      }
      case 2:
        // if (!testEmail) return null
        return `Website Crawled: **https://www.${testEmail?.split("@")[1]}**`;
      case 3: {
        const marker = polledContent?.indexOf("COMPANY RESEARCH AGENT START")
        // console.log(marker, "marker---------------------")
        if (marker === -1) return null;
        const startMarker = "SUMMARIZATION AGENT START";
        const endMarker = "COMPANY RESEARCH AGENT START";

        // Find the start and end indices
        const startIndex =
          polledContent?.indexOf(startMarker) + startMarker.length;
        const endIndex = polledContent?.indexOf(endMarker);

        // Extract the content between the markers
        const extractedContent = polledContent
          ?.substring(startIndex, endIndex)
          ?.trim();
        return extractedContent
      }
      case 4: {
        const marker = polledContent?.indexOf("LINKEDIN COMPANY RESEARCH AGENT START")
        // console.log(marker, "marker---------------------")
        if (marker === -1) return null;
        const startMarker = "COMPANY RESEARCH AGENT START";
        const endMarker = "LINKEDIN COMPANY RESEARCH AGENT START";

        // Find the start and end indices
        const startIndex =
          polledContent?.indexOf(startMarker) + startMarker.length;
        const endIndex = polledContent?.indexOf(endMarker);

        // Extract the content between the markers
        const extractedContent = polledContent
          ?.substring(startIndex, endIndex)
          ?.trim();
        return extractedContent
      }
      case 5: {
        const marker = polledContent?.indexOf("LINKEDIN PERSON VERIFICATION AGENT START")
        // console.log(marker, "marker---------------------")
        if (marker === -1) return null;
        const startMarker = "LINKEDIN COMPANY RESEARCH AGENT START";
        const endMarker = "LINKEDIN PERSON VERIFICATION AGENT START";

        // Find the start and end indices
        const startIndex =
          polledContent?.indexOf(startMarker) + startMarker.length;
        const endIndex = polledContent?.indexOf(endMarker);

        // Extract the content between the markers
        const extractedContent = polledContent
          ?.substring(startIndex, endIndex)
          ?.trim();
        return extractedContent
      }
      case 6: {
        const marker = polledContent?.indexOf("FIT SCORE CALCULATION AGENT START")
        // console.log(marker, "marker---------------------")
        if (marker === -1) return null;
        const startMarker = "LINKEDIN PERSON VERIFICATION AGENT START";
        const endMarker = "FIT SCORE CALCULATION AGENT START";

        // Find the start and end indices
        const startIndex =
          polledContent?.indexOf(startMarker) + startMarker.length;
        const endIndex = polledContent?.indexOf(endMarker);

        // Extract the content between the markers
        const extractedContent = polledContent
          ?.substring(startIndex, endIndex)
          ?.trim();
        return extractedContent
      }
      case 7: {
        const marker = polledContent?.indexOf("LEAD SCORE CALCUALATION AGENT START")
        // console.log(marker, "marker---------------------")
        if (marker === -1) return null;
        const startMarker = "FIT SCORE CALCULATION AGENT START";
        const endMarker = "LEAD SCORE CALCUALATION AGENT START";

        // Find the start and end indices
        const startIndex =
          polledContent?.indexOf(startMarker) + startMarker.length;
        const endIndex = polledContent?.indexOf(endMarker);

        // Extract the content between the markers
        const extractedContent = polledContent
          ?.substring(startIndex, endIndex)
          ?.trim();
        return extractedContent
      }
      case 8: {
        const marker = polledContent?.indexOf("ROUTING AGENT START")
        // console.log(marker, "marker---------------------")
        if (marker === -1) return null;
        const startMarker = "LEAD SCORE CALCUALATION AGENT START";
        const endMarker = "ROUTING AGENT START";

        // Find the start and end indices
        const startIndex =
          polledContent?.indexOf(startMarker) + startMarker.length;
        const endIndex = polledContent?.indexOf(endMarker);

        // Extract the content between the markers
        const extractedContent = polledContent
          ?.substring(startIndex, endIndex)
          ?.trim();
        return extractedContent
      }
      case 9: {
        const marker = polledContent?.indexOf("-------DONE-------")
        // console.log(marker, "marker---------------------")
        if (marker === -1) return null;
        const startMarker = "ROUTING AGENT START";
        const endMarker = "-------DONE-------";

        // Find the start and end indices
        const startIndex =
          polledContent?.indexOf(startMarker) + startMarker.length;
        const endIndex = polledContent?.indexOf(endMarker);

        // Extract the content between the markers
        const extractedContent = polledContent
          ?.substring(startIndex, endIndex)
          ?.trim();
        // console.log(extractedContent?.toLowerCase(), "case 9------------------------------->")
        if (extractedContent?.toLowerCase()?.includes("add to google sheet")) {
          setChecked(2)
        } else {
          setChecked(1)
        }
        return extractedContent
      }
      default:
        return null;
    }
  };

  return (
    <div
      className={`rounded-md right-sider bg-white ${
        isCollapsed ? "collapsed" : ""
      }`}
    >
      <div className="sider-content flex flex-col gap-6">
        {siderProcess.map(
          (process, index) =>
            index <= activeIndex && (
              <div
                ref={visibleProcesses.includes(index) ? ref : null}
                key={process?.id}
                className={`flex flex-col gap-3 ${
                  visibleProcesses.includes(index) ? "visible" : ""
                }`}
              >
                <div className="flex items-center gap-2">
                  <img src={process?.data?.icon} alt={process?.id} width={18} />
                  <p className="font-bold text-[12px]">
                    {process?.data?.label}
                  </p>
                </div>
                <EnrichmentTimer
                  duration={process?.duration}
                  onComplete={handleTimerComplete}
                  content={giveContent(index)}
                />
                {/* {process?.id === "node-3" && ( */}
                <EnrichmentContent content={giveContent(index)} />
                {/* )} */}
                {index === siderProcess?.length - 1 &&
                chatArray[chatArray?.length - 1]?.answer !==
                  "I see you have selected the Lead Research Template. Would you like help setting it up?" ? (
                  <EnrichmentComplete />
                ) : (
                  <hr className="translate-y-2" />
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default EnrichmentRightSider;
