import React, { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { activeToastComponentAtom, toastChatArrayAtom } from "../../store";
import { sideNavContentAtom, chatModeAtom } from "../../store/atoms/MainAtoms.js";
import ToastChat from "../../components/ToastComponents/ToastChat.js";
import ToastDashboard from "../../components/ToastComponents/ToastDashboard.js";

const Toast = () => {
  const [activeToastComp, setActiveToastComp] = useRecoilState(activeToastComponentAtom);
  const setChatArray = useSetRecoilState(toastChatArrayAtom);
  const setSideNavContent = useSetRecoilState(sideNavContentAtom);
  const setChatMode = useSetRecoilState(chatModeAtom);

  useEffect(() => {
    setActiveToastComp(0);
    localStorage.setItem("path", "toast")
  }, []);

  const handleTab = (index) => {
    setActiveToastComp(index)
    if (index === 0) {
      setSideNavContent(0);
      setChatMode(0);
    } else {
      setSideNavContent(1);
      setChatMode(1);
    }
  }

  const toastTabs = [
    {
      name: "Chat",
      component: <ToastChat />,
    },
    {
      name: "Dashboard",
      component: <ToastDashboard />,
    }
  ];
  return (
    <div className='flex flex-col h-[90vh] w-full p-2'>
      <div className="flex justify-start items-center pl-1 pr-4 pt-2 border-b border-gray-300">
        {toastTabs.map((tab, index) => (
          <div
            key={index}
            className={`${activeToastComp === index
              ? "text-[#2966BC] border-b-2 border-[#2966BC]"
              : "text-gray-600"
              } px-4 py-2 text-sm cursor-pointer`}
            onClick={() => handleTab(index)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="flex-1 p-4">
        {toastTabs[activeToastComp].component}
      </div>
    </div>
  );
};

export default Toast;