import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
    ArrowRightOutlined
} from "@ant-design/icons"
import { useRecoilValue } from 'recoil'
import { agentValAtom } from '../../store'

import { message } from 'antd'
import { createSession } from '../../services/waterfallServices'
import { jwtDecode } from 'jwt-decode'

const AgentHeader = () => {
  const navigate = useNavigate()
  const agentVal = useRecoilValue(agentValAtom);
  console.log({agentVal})
  const generateLink = async (template, name) => {
    const response = await createSession({
      user_id: jwtDecode(
        localStorage
          .getItem("loginToken")
          ?.replace(process.env.REACT_APP_TOKEN_PASSCODE, "")
      )?.id,
    });
    console.log(response);
    const sessionId = response?.data?.session_id;
    if (sessionId) {
      if (template !== "noTemplate") {
        navigate(
          `/enrichment?template=true&flow_id=${response?.data?.session_id}`
        );
      } else {
        if (name) {
          navigate(
            `/playground?flow_id=${response?.data?.session_id}&name=${name}`
          );
        } else {
          navigate(
            `/playground?flow_id=${response?.data?.session_id}`
          );
        }
      }
    } else {
      message.error("Error Navigating");
    }
  };

  return (
    <div className='bg-even-light-primary flex flex-col gap-4 justify-center py-4 px-6 rounded-md'>
        <div className='flex items-center justify-between'>
            <p className='h3_Medium text-character'>
                {agentVal?.name}
            </p>
            <div className={`flex justify-center items-center gap-3 cursor-pointer px-6 py-1.5 rounded-md ${agentVal?.route ? 'bg-primary' : 'bg-blue-300'} hover:scale-110 duration-300 ease-in-out`} onClick={agentVal?.href === 'enrich' ? () => generateLink("noTemplate", agentVal?.name) : agentVal?.href === 'enrichment' ? generateLink : () => navigate(agentVal?.href)}>
                <p className='body_Regular text-white'>{agentVal?.route ? 'Use this Template' : 'Coming Soon'}</p>
                <ArrowRightOutlined className='text-white' />
            </div>
        </div>
        <p className='h5_Medium text-character w-1/2'>
            {agentVal?.des}
        </p>
        <p className='body_Regular text-primary'>
            Output: {agentVal?.output || 'CRM or Warehouse'}
        </p>
    </div>
  )
}

export default AgentHeader