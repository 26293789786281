import React from "react";
import collapseImg from "../../assets/images/collapseImg.png";
import expandImg from "../../assets/images/expandImg.png";

const LeadHead = ({ data }) => {
  console.log(data);
  return (
    <div
      className={`${
        data?.data?.bgColor === "peach"
          ? "bg-[#ffe8d9]"
          : data?.data?.bgColor === "grey"
          ? "bg-[#D4DCE5]"
          : data?.data?.bgColor === "blue"
          ? "bg-[#C3E6FA]"
          : data?.data?.bgColor === "pink"
          ? "bg-[#FED5D2]"
          : "bg-[#DEF8CF]"
      } w-full flex justofy-between items-center p-2`}
    >
      <div className="flex justify-start items-center w-full gap-2">
        <img src={data?.data?.icon} alt={`${data?.data?.label}`} width={data?.id === "node-13" ? 24 : 20} />
        <div className="flex flex-col justify-center items-start text-[12px]">
          <p className="text-[18px] font-semibold text-gray-700">{data?.data?.label}</p>
          <p className="text-[10px]">v0.01</p>
        </div>
      </div>
      {data?.data?.firstKey ? (
          <img src={collapseImg} alt="collapse" width={14} className="" />
      ) : (
        <img src={expandImg} alt="expand" width={18} className="" />
      )}
    </div>
  );
};

export default LeadHead;
