import React, { useEffect, useState } from "react";
import { PlusCircleOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Input, message, Dropdown, Menu } from "antd";
import { jwtDecode } from "jwt-decode";

import { chatresponse } from "../../services/chatServices";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import {
  gumloopRowsAtom,
  gumloopInputFilledAtom,
  gumloopQuestionAtom,
  gumloopChatArrayAtom,
  gumloopLoadingAtom,
  focusNodeIdAtom,
  newNodeAtom,
  contactAtom,
  slackAtom,
  fitEntireAtom,
  testLoadingAtom,
  deployAtom,
  btnLoadingAtom,
  btnClickedAtom,
  collapsibleAtom
} from "../../store";
import { chatModeAtom } from "../../store/atoms/MainAtoms";
import { initialNodes } from "../../pages/Gumloop/Gumloop.js";
import companyImg from "../../assets/images/companyImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";

const { TextArea } = Input;

const InputBoxGumloop = ({siderWidth}) => {
  const [rows, setRows] = useRecoilState(gumloopRowsAtom);
  const [inputFilled, setInputFilled] = useRecoilState(gumloopInputFilledAtom);
  const [question, setQuestion] = useRecoilState(gumloopQuestionAtom);
  const [chatArray, setChatArray] = useRecoilState(gumloopChatArrayAtom);
  const chatMode = useRecoilValue(chatModeAtom)
  const [loading, setLoading] = useRecoilState(gumloopLoadingAtom);
  const setFocusNodeId = useSetRecoilState(focusNodeIdAtom);
  const setNewNode = useSetRecoilState(newNodeAtom);
  const setContact = useSetRecoilState(contactAtom);
  const setSlack = useSetRecoilState(slackAtom);
  const setFitEntire = useSetRecoilState(fitEntireAtom);
  const setTestLoading = useSetRecoilState(testLoadingAtom);
  const setDeploy = useSetRecoilState(deployAtom);
  const setBtnLoading = useSetRecoilState(btnLoadingAtom);
  const setBtnClicked = useSetRecoilState(btnClickedAtom);
  const setCollapsed = useSetRecoilState(collapsibleAtom);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const replaceLastOccurrence = (str, char, replacement) => {
    const index = str?.lastIndexOf(char);
    if (index === -1) {
      return str; // Character not found
    }
    return str?.slice(0, index) + replacement + str?.slice(index + 1);
  };

  const handleMenuClick = (label) => {
    setQuestion(replaceLastOccurrence(question, "\\", label))
  }

  const gumloopOptions = initialNodes
    ?.filter(item => item?.data?.icon)
    ?.map(item => (
      {
        key: item?.data?.label,
        label: (
          <div className="flex items-center gap-2" onClick={() => handleMenuClick(item?.data?.label)}>
            <img src={item?.data?.icon} alt={item?.data?.label} width={20} />
            <p>{item?.data?.label}</p>
          </div>
        )
      }
    ));

  const additionalOptions = [
    {
      data: {
        label: "Hubspot Contact Reader",
        icon: hubspotImg,
      },
    },
    {
      data: {
        label: "Enrich Contact Information",
        icon: companyImg,
      },
    },
    {
      data: {
        label: "Slack Message Sender",
        icon: slackImg,
      },
    },
  ]

  additionalOptions.forEach(item => {
    gumloopOptions.push({
      key: item.data.label,
      label: (
        <div className="flex items-center gap-2" onClick={() => handleMenuClick(item.data.label)}>
          <img src={item.data.icon} alt={item.data.label} width={20} />
          <p>{item.data.label}</p>
        </div>
      )
    });
  });
  
  const handleInputChange = (e) => {
    if (e.target.value.trim() === "") {
      setInputFilled(false);
    } else {
      setInputFilled(true);
    }
    let val = e.target.value
    if (val?.endsWith("\\")) {
      // console.log("\\ Clicked")
      setDropdownVisible(true)
    } else {
      setDropdownVisible(false)
    }
    const lines = e.target.value.split("\n").length;
    const newRows = Math.min(lines, 5);
    setRows(newRows || 1);
    setQuestion(e.target.value);
  };

  const handleSend = async () => {
    if (inputFilled) {
      // setChatArray((prevChatArray) => [
      //   ...prevChatArray,
      //   {
      //     question: question,
      //   },
      // ]);
      // setLoading(true);
      setLoading(true)
      setQuestion(null);
      setInputFilled(false);
      const lastAnswer = chatArray[chatArray.length-1]?.answer;
      if (lastAnswer === "I see you have selected the Lead Research Template. Would you like help setting it up?" && question?.toLowerCase() === "yes") {
        setLoading(false)
        setChatArray([...chatArray, {
            question,
            answer: "Great. This template does the following:\n> 1. Takes a manual email as input & output.\n> 2. Splits the email using the @ character to split on.\n> 3. Grabs the company domain from the split list.\n> 4. Enriches company data using the domain name.\n> 5. Scrapes the company's website using the domain name.\n> 6. Creates a AI Summary using the scraped website data.\n> 7. Combines Input, Enriched data, & AI Summary into a structural document.\n> 8. Send structured data to Slack.",
        }])
        setTimeout(() => {
            setChatArray(prevChatArray => ([
                ...prevChatArray,
                {
                    answer: "Would you like to customize this flow?"
                }
            ]))
        }, 1500)
      } else if (lastAnswer === "Would you like to customize this flow?" && question?.toLowerCase() === "yes") {
        setLoading(false)
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: "What customizations would you like to add?"
            }
        ]))
        // Yes, let's have the input come from Hubspot when a new  lead fills out a contact form from our advertising campaigns. I also want to enrich contact data with First Name, Last Name, Job Title, & Linkedin URL.
      } else if (question?.toLowerCase().includes("hubspot") 
        // || question?.toLowerCase().includes("advertising") || question?.toLowerCase().includes("campaigns")
    ) {
        setLoading(false)
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: "No problem, let's get started!"
            }
        ]))
        setTimeout(() => {
            setFocusNodeId("node-1");
            setChatArray(prevChatArray => ([
                ...prevChatArray,
                {
                    answer: "I have zoomed in our view on the first node. So you can see the modifications I am making. I will change the first node to the Hubspot connector & select email as its output. Is there a specific list you would like to use or other output parameters you would like to include?"
                }
            ]))
        }, 1500)
      } else if (question?.toLowerCase().includes("advertising") || question?.toLowerCase().includes("campaigns")) {
        setLoading(false)
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: 'I see a list named "Advertising: Demo Request & Contact Form". Would you like me to use this list?'
            }
        ]))
      } else if (lastAnswer === 'I see a list named "Advertising: Demo Request & Contact Form". Would you like me to use this list?' && question?.toLowerCase() === "yes") {
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: "Working..."
            }
        ]))
        setTimeout(() => {
            setLoading(false);
            setNewNode(true);
            setChatArray(prevChatArray => ([
                ...prevChatArray,
                {
                    answer: "Great! Changes made. Let's add the Enrich Contact node to the workflow."
                }
            ]))
            setLoading(true);
            setTimeout(() => {
                setContact(true);
                setChatArray(prevChatArray => ([
                    ...prevChatArray,
                    {
                        answer: "Contact Enrichment Node & Parameters Added."
                    },
                    {
                        answer: "Are you okay with the default Account Enrichment Outputs?"
                    },
                ]))
                setLoading(false);
            }, 2500)
        }, 1500)
      } else if (lastAnswer === 'Are you okay with the default Account Enrichment Outputs?' && question?.toLowerCase() === "yes") {
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: "Excellent! Which Slack Channel would you like the message to be delivered in?"
            }
        ]))
        setLoading(false);
      } else if (question?.toLowerCase() === "marketing leads") {
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: "Making Changes to Slack Node"
            }
        ]))
        setLoading(true);
        setTimeout(() => {
            setSlack(true);
            setChatArray(prevChatArray => ([
                ...prevChatArray,
                {
                    answer: "Done"
                }
            ]))
            setTimeout(() => {
                setChatArray(prevChatArray => ([
                    ...prevChatArray,
                    {
                        answer: "Excellent! Are there any other modifications you would like to make before we move to testing & deployment?"
                    }
                ]))
            }, 1500)
            setLoading(false);
        }, 1500)
      } else if (question?.toLowerCase()?.includes("good to go")) {
        setTestLoading(false);
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: "Great! I will zoom out so you can see the flow in its entirety & run a test to see if the flow works properly.\nYou will see the output on the right hand of the screen."
            }
        ]))
        setLoading(true);
        setTimeout(() => {
            setFitEntire(true);
            setBtnLoading(true);
            setBtnClicked(true);
            setChatArray(prevChatArray => ([
                ...prevChatArray,
                {
                    answer: "Test Running..."
                }
            ]))
            setTimeout(() => {
              setCollapsed(false);
              setTestLoading(true);
            }, 1000)
        }, 1500)
      } else if (lastAnswer === 'Test ran successfully. Shall I deploy the workflow?' && question?.toLowerCase() === "yes") {
        setDeploy(true);
        setChatArray(prevChatArray => ([
            ...prevChatArray,
            {
                question,
                answer: "Deployed! To see your deployed workflows & their performance, please navigate to Live in the Main Menu."
            }
        ]))
        setLoading(false);
      }
    }
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className={`flex items-center  ${chatMode == 0 ? "fixed left-[22%] w-full" : `w-full`} bottom-[5%]`}>
      <div className={`border border-[#2966BC] bg-[#2966BC] p-1 ${chatMode == 0 ? "rounded-full  w-[70%]" : ``}`} style={chatMode !== 0 ? { width: `${siderWidth}px` } : {}}>
        <div className={`border border-[#2966BC] ${chatMode == 0 ? " shadow-xl rounded-full px-2 p-1" : "px-1"} flex justify-between items-center`}>
          <div className={`flex gap-2 justify-center items-center ${chatMode == 0 ? "gap-5 w-[95%]" : "w-[90%]"}`}>
              <label htmlFor="file-input" className="cursor-pointer flex items-center">
                <PlusCircleOutlined style={{ fontSize: chatMode == 0 ? "20px" : siderWidth > 300 ? '17px' : '12px', cursor: "pointer", color: "white" }} />
              </label>
              <input
                id="file-input"
                type="file"
                // ref={fileInputRef}
                // onChange={handleFileChange}
                style={{ display: "none" }}
                accept=".csv"
              />
            <Dropdown
              overlayClassName="overflow-scroll max-h-40"
              menu={{
                items: gumloopOptions,
                selectable: true
              }}
              open={dropdownVisible}
              trigger={[]}
              onOpenChange={(flag) => setDropdownVisible(flag)}
            >
              <TextArea
                autoSize={{ minRows: 1, maxRows: 5 }}
                value={question}
                rows={rows}
                onChange={handleInputChange}
                onPressEnter={handlePressEnter}
                placeholder="Message CoPilot"
                className={`${chatMode == 0 ? "text-xl" : "text-[12px]"}text-xl w-[90%] laterTextArea`}
                autoFocus
              />
            </Dropdown>
          </div>
          <div className={`flex justify-center items-center gap-5 ${chatMode == 0 ? "" : "ml-1"} mt-0.5`}>
            <div
              className={`flex justify-center items-center gap-2 border-2 rounded-2xl ${chatMode == 0 ? "p-2" : "p-1"} ${inputFilled ? "bg-[#2966BC] cursor-pointer" : "bg-white cursor-not-allowed"}`}
              onClick={handleSend}
            >
              <ArrowUpOutlined style={{ color: inputFilled ? "white" : "black" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputBoxGumloop;
