import React, { useState, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { fetchTableData, updateInsightsTable } from "../../services/InsightsServices";
import {
  hiddenColumnsAtom,
  deletedColumnsAtom,
  actionHistoryAtom,
} from "../../store";
import { chatModeAtom, reloadChatTableAtom } from "../../store/atoms/MainAtoms";
import TableMenu from "./TableMenu";
import { Button, Input, Modal, Tag, Table, message, Dropdown, TextArea } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { SpreadsheetComponent, SheetsDirective, SheetDirective, RangesDirective, RangeDirective, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-spreadsheet';
import { updateDataforSpreadsheet } from "../../services/InsightsServices";

const DisplayTableComponent = ({ query_id, minimized }) => {
  
  const { TextArea } = Input;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accumulatedChanges, setAccumulatedChanges] = useState([]);
  const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
  const [reloadChatTable, setReloadChatTable] = useRecoilState(reloadChatTableAtom);
  const [hiddenColumns, setHiddenColumns] = useRecoilState(hiddenColumnsAtom);
  const [deletedColumns, setDeletedColumns] = useRecoilState(deletedColumnsAtom);
  const [actionHistory, setActionHistory] = useRecoilState(actionHistoryAtom);
  
  const [inputText, setInputText] = useState('');
  const [formula, setFormula] = useState('');
  const [columns, setColumns] = useState([]);
  const [isGeneratingFormula, setIsGeneratingFormula] = useState(-1);
  const [sampleData, setSampleData] = useState({});
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formulaResult, setFormulaResult] = useState([]);
  const [originalColumnData, setOriginalColumnData] = useState(null);
  const [hasEdits, setHasEdits] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const spreadsheetRef = useRef(null);

  const headerStyleSheet = { 
    fontWeight: 'bold',
    fontSize: '12pt',
    backgroundColor: '#2A66BC',
    textAlign: 'center',
    color: '#ffffff',
    borderBottom: '1px solid #e0e0e0'
  }

  useEffect(() => {
    console.log("Hey => jion")
    fetchData();
  }, [minimized, reloadChatTable]);

  useEffect(() => {
    const spreadsheet = spreadsheetRef.current;
    if (spreadsheet && data.length > 0) {
      // Set columns
      console.log("In Useeffect", {data})
      const columnHeaders = Object.keys(data[0]);
      console.log("In Useeffect", {columnHeaders})

      setColumns(columnHeaders);

      // Set sample data (2nd row)
      try {
        const sampleRowData = data[1] || {}; // Use the second row of data, or an empty object if it doesn't exist
        setSampleData(sampleRowData);
      } catch (error) {
        console.error("Error getting sample row data:", error);
        setSampleData({});
      }
    }
    console.log({columns, sampleData})
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      applyFormatting();
    }
  }, [data])

  function getColumnAlphabet(index) {
    let columnAlphabet = '';
    
    while (index >= 0) {
      const remainder = index % 26;
      columnAlphabet = String.fromCharCode(65 + remainder) + columnAlphabet;
      index = Math.floor(index / 26) - 1;
    }
    
    return columnAlphabet;
  }

  function getColumnIndex(columnAlphabet) {
    columnAlphabet = columnAlphabet.toUpperCase();
    let index = 0;
    for (let i = 0; i < columnAlphabet.length; i++) {
      index = index * 26 + (columnAlphabet.charCodeAt(i) - 64);
    }
    return index - 1; // Subtract 1 to make it 0-based
  }

  const fetchData = async () => {
    try {
      setLoading(true);
      let query = `select * from insighttables.new_table_${query_id.replace(/-/g, '_')} ORDER BY s_no ASC;`
      if (minimized) {
        if (query.endsWith(";")) {
          query = query.slice(0, -1);
        }
        query += " LIMIT 10;";
      }
      setTimeout(async () => {
        const result = await fetchTableData({ sqlQuery: query });
        if (result.data && result.data.data) {
          setData(result.data.data);
        } else {
          throw new Error("Invalid data format received from the server");
        }
        setLoading(false);
      }, 2000);
    } catch (err) {
      setError(err.message || "An error occurred while fetching data");
      setLoading(false);
    }
  };

  const handleColumnSelect = columnName => {
    setInputText(prev => prev + `{{${columnName}}}`);
    setDropdownVisible(false);
  };

  const getHeaderRange = () => {
    if (data.length > 0) {
      const columnCount = Object.keys(data[0]).length;
      const lastColumn = String.fromCharCode(65 + columnCount - 1); // Convert to letter (A, B, C, ...)
      return `A1:${lastColumn}1`;
    }
    return 'A1:A1'; // Default range if no data
  };

  const applyFormatting = () => {
    let spreadsheet = spreadsheetRef.current;
    if (spreadsheet && data.length > 0) {
      const range = getHeaderRange();
      spreadsheet.cellFormat(headerStyleSheet,range);
      // spreadsheet.setRowsHeight([25]); // Pass an array with a single value
    }
  };

  const handleInputChange = (e) => {
    const val = e.target.value;
    setInputText(val);
    
    if (val.endsWith('/')) {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === '/') {
      e.preventDefault();
      setDropdownVisible(true);
    }
  };

  const generateFormula = async () => {
    console.log("In generate formula", {columns, sampleData})
    try {
      setIsGeneratingFormula(0);
      const response = await fetch('http://localhost:8000/demoapp/generate_formula/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: inputText, columns: columns, sampleData: sampleData }),
      });
      const resData = await response.json();
      console.log("Formula Data => ", resData)
      setIsGeneratingFormula(1);
      setFormula(resData.formula);
      let spreadsheet = spreadsheetRef.current;
      if (!spreadsheet) {
        throw new Error("Spreadsheet not initialized");
      }
      const colIndex = getColumnIndex(selectedColumn)

      // Store original column data
      try {
        console.log("LLLLL", data, data.length)
        const originalData = await spreadsheet.getData(`${selectedColumn}1:${selectedColumn}${data.length + 1}`);
        setOriginalColumnData(originalData);
      } catch (error) {
        console.error("Error storing original column data:", error);
        message.error("Failed to store original column data");
        return;
      }

      // Apply the formula to the selected column in Syncfusion Spreadsheet
      try {
        spreadsheet.updateCell({ value: "New Column" }, 0, colIndex);
        
        spreadsheet.cellFormat(headerStyleSheet, `${selectedColumn}1:${selectedColumn}1`);
        spreadsheet.updateCell({ value: resData?.formula }, `${selectedColumn}2:${selectedColumn}2`);
        spreadsheet.autoFill(`${selectedColumn}3:${selectedColumn}11`, `${selectedColumn}2:${selectedColumn}2`);
        const resultData = await spreadsheet.getData(`${selectedColumn}2:${selectedColumn}11`);
        console.log("Getting Row data here: ",spreadsheet.getRowData(0,0));
        console.log("Formula result data:", resultData);
        // Convert Map to array of objects
        const formattedResult = Array.from(resultData).map(([cell, cellData]) => ({
          cell,
          value: cellData.value,
          formula: cellData.formula
        }));
        setFormulaResult(formattedResult);
        setHasEdits(true);
        console.log("Check Header Row Data", spreadsheet.getRowData(0,0))
      } catch (error) {
        console.error("Error applying formula to spreadsheet:", error);
        message.error("Failed to apply formula to spreadsheet");
        revertChanges();
      }
    } catch (error) {
      console.error('Error generating formula:', error);
      setIsGeneratingFormula(-1);
      message.error("Failed to generate formula");
    }
  };

  const onContextMenuBeforeOpen = (args) => {
    console.log({args})
    let spreadsheet = spreadsheetRef.current;
    if (spreadsheet && args.element && args.element.id === spreadsheet.element.id + '_contextmenu') {
      spreadsheet.addContextMenuItems([{ text: 'Generate Formula using AI', id: 'generate_formula' }], 'Paste Special', false);
    }
  };

  const onContextMenuItemSelect = (args) => {
    console.log(args.element.id)
    if (args.item.id === 'generate_formula') {
      const spreadsheet = spreadsheetRef.current;
      if (spreadsheet) {
        const selectedRange = spreadsheet.getActiveSheet().selectedRange;
        if (selectedRange && selectedRange.length > 0) {
          console.log("COLUMN => ", selectedRange[0])
          setSelectedColumn(selectedRange[0]);
          setIsModalVisible(true);
        }
      }
    } else if(args?.element?.id.includes('delete_column') || args?.element?.id.includes('insert_column')) {
      console.log(args.element.id) 
      setHasEdits(true)
    }
  };

  const handleModalOk = () => {
    generateFormula();
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    revertChanges();
  };

  const saveSpreadsheetData = async () => {
    try {
      const spreadsheet = spreadsheetRef.current;
      if (!spreadsheet) {
        throw new Error("Spreadsheet not initialized");
      }

      const columnData = await spreadsheet.getData("A1:Z1")
      const x = Array.from(columnData)

      let lastUsedColumnIndex = -1;
      let rowCount = 0;

      // Find the last non-empty column
      for (let i = x.length - 1; i >= 0; i--) {
        const [key, value] = x[i];
        if (value && value.value !== "") {
          lastUsedColumnIndex = i;
          break;
        }
      }

      if (lastUsedColumnIndex === -1) {
        throw new Error("No data found in the spreadsheet");
      }

      const lastUsedColumn = getColumnAlphabet(lastUsedColumnIndex);

      // Get the row count
      let currentRow = 1;
      while (true) {
        const cellValue = await spreadsheet.getData(`A${currentRow}`);
        if (!cellValue || cellValue.size === 0 || !cellValue.get(`A${currentRow}`).value) {
          break;
        }
        currentRow++;
      }
      rowCount = currentRow - 1;

      const rangeAddress = `A1:${lastUsedColumn}${rowCount}`;
      const sheetData = await spreadsheet.getData(rangeAddress);
  
      // Convert the data to the format expected by the new backend endpoint
      const columnHeaders = Array.from(sheetData).slice(0, lastUsedColumnIndex + 1).map(([_, cellData]) => cellData.value);
      const dataRows = [];
  
      for (let i = lastUsedColumnIndex + 1; i < sheetData.size; i += (lastUsedColumnIndex + 1)) {
        const row = {};
        for (let j = 0; j <= lastUsedColumnIndex; j++) {
          const cellData = sheetData.get(`${getColumnAlphabet(j)}${Math.floor(i / (lastUsedColumnIndex + 1)) + 1}`);
          let cellDataValue;
          if(cellData?.formula) {
            cellDataValue = cellData?.formula
          } else if(!cellData?.formula && cellData.value) {
            cellDataValue = cellData?.value
          } else if(!cellData?.value && cellData?.hyperlink) {
            cellDataValue = cellData?.hyperlink;
          }
          row[columnHeaders[j]] = cellDataValue;
        }
        dataRows.push(row);
      }

      console.log({query_id, dataRows})
  
      // Call the new updateDataforSpreadsheet endpoint
      const {data: apiRes} = await updateDataforSpreadsheet({
        query_id: query_id,
        tableData: dataRows
      });
      console.log(apiRes)
      if(apiRes?.status === "success"){
        message.success(apiRes?.message);
      } else {
        throw new Error(apiRes?.message || "Failed to save spreadsheet data")
      }
      setHasEdits(false);
    } catch (error) {
      console.error("Error saving spreadsheet data:", error);
      message.error(error.message || "Failed to save spreadsheet data");
    }
  };

  const handleConfirm = async () => {
    setIsModalVisible(false);
    await saveSpreadsheetData();
  };

  const revertChanges = () => {
    let spreadsheet = spreadsheetRef.current;
    if (spreadsheet && originalColumnData) {
      try {
        console.log({originalColumnData});
        originalColumnData.forEach((value, key) => {
          spreadsheet.updateCell({ value: value.value, style: value.style }, key);
        });
      } catch (error) {
        console.error("Error reverting changes:", error);
        message.error("Failed to revert changes");
      }
    }
    setFormulaResult([]);
    setFormula('');
    setOriginalColumnData(null);
    setHasEdits(false);
  };

  const resultColumns = [
    {
      title: 'Cell',
      dataIndex: 'cell',
      key: 'cell',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Formula',
      dataIndex: 'formula',
      key: 'formula',
    },
  ];

  const handleCellSave = (args) => {
    console.log("handleCellSave", args.name)
    if (args.name === 'cellSave') {
      console.log("handleCellSave", args)
      let spreadsheet = spreadsheetRef.current
      // spreadsheet.save()
      setHasEdits(true);
    }
    console.log({hasEdits})
  };

  const handleCellEdit = (args) => {
    // Preventing the editing in 5th(Amount) column.
    if (args.address.includes('A')) { args.cancel = true; }
  };

  const handleCellSelect = async (args) => {
    console.log("SELECTION => ", args);

    // Add a condition to accept only whole row selection and not individual or column selection
    if (args.range) {
      const ranges = args.range.split(' ');
      const selectedData = [];
      const spreadsheet = spreadsheetRef.current;
      
      // Get column headers
      const headerData = await spreadsheet.getData("A1:Z1");
      const tempheaders = Array.from(headerData).map(([_, cellData]) => {
        if(cellData?.value){
          return cellData.value
        }
      });
      const headers = tempheaders.filter(x => {
        if(x){
          return x
        }
      })
      
      for (const range of ranges) {
        const [start, end] = range.split(':');
        const startColumn = start.match(/[A-Z]+/)[0];
        const endColumn = end.match(/[A-Z]+/)[0];
        const startRow = parseInt(start.match(/\d+/)[0]);
        const endRow = parseInt(end.match(/\d+/)[0]);
        
        // Check if the selection is a whole row
        if (startColumn === 'A' && endColumn !== startColumn) {
          for (let row = startRow; row <= endRow; row++) {
            if (row > 1) { // Skip header row
              const rowData = await spreadsheet.getData(`A${row}:${getColumnAlphabet(headers.length - 1)}${row}`);
              const rowObject = {};
              Array.from(rowData).forEach(([cell, cellData], index) => {
                rowObject[headers[index]] = cellData.value;
              });
              selectedData.push(rowObject);
            }
          }
        } else {
          // If it's not a whole row selection, don't update selectedRows
          setSelectedRows([])
          return;
        }
      }
      
      setSelectedRows(selectedData);
      console.log("SELECTED => ", selectedData);
    }
  };

  // New function to display all columns in the spreadsheet
  const columnOptions = columns.map((column) => ({
    key: column,
    label: (
      <div className="flex items-center gap-2" onClick={() => handleColumnSelect(column)}>
        <p>{column}</p>
      </div>
    )
  }));

  const examplePrompts = [
    {
      prompt: "Calculate the total revenue if the order quantity is greater than 100",
      formula: "=IF({{order_quantity}} > 100, {{order_quantity}} * {{unit_price}}, 0)"
    },
    {
      prompt: "Categorize customers based on their total purchases",
      formula: "=IF({{total_purchases}} > 1000, 'VIP', IF({{total_purchases}} > 500, 'Regular', 'New'))"
    },
    {
      prompt: "Calculate the days since last order",
      formula: "=TODAY() - {{last_order_date}}"
    }
  ];

  useEffect(() => {
    console.log({selectedRows});
  }, [selectedRows])

  if (loading) return <>Generating table based on the data...</>;
  if (error) return <div><p>Error: {error}</p></div>;

  return (
    <>
    <div className="w-full flex justify-between items-center">
      <Button 
          type="primary" 
          icon={<SaveOutlined />} 
          onClick={saveSpreadsheetData}
          disabled={!hasEdits}
          style={{ marginTop: '10px', marginRight: '10px' }}
        >
          Save Changes
        </Button>
        {chatMode == 1 && (
          <TableMenu data={data} selectedRows={selectedRows} query_id={query_id} fetchData={fetchData} setReloadChatTable={setReloadChatTable}/>
        )}
    </div>
      <div className="mt-2" style={{ overflowX: "auto" }}>
        <SpreadsheetComponent 
          ref={spreadsheetRef}
          contextMenuBeforeOpen={onContextMenuBeforeOpen}
          contextMenuItemSelect={onContextMenuItemSelect}
          created={applyFormatting}
          showSheetTabs={false} 
          showRibbon={false}
          selectionSettings={{ mode: 'Multiple' }}
          allowUndoRedo={true}
          cellSave={handleCellSave}
          cellEdit={handleCellEdit}
          select={handleCellSelect}
        >
          <SheetsDirective>
            <SheetDirective>
              <RangesDirective>
                <RangeDirective dataSource={data}/>
              </RangesDirective>
              <ColumnsDirective>
                {data && data.length > 0 && Object.keys(data[0]).map((_, index) => (
                  <ColumnDirective key={index} width={index === 0 ? 100 : 180} />
                ))}
              </ColumnsDirective>
            </SheetDirective>
          </SheetsDirective>
        </SpreadsheetComponent>

        <Modal
          title={`Generate Formula for this Column '${selectedColumn}'`}
          className="min-w-[50%]"
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          footer={[
            <Button key="back" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleModalOk} disabled={isGeneratingFormula === 0}>
              Generate
            </Button>,
            <Button key="confirm" type="primary" onClick={handleConfirm} disabled={formulaResult.length === 0}>
              Confirm
            </Button>,
          ]}
        >
          <div className={`grid ${formulaResult.length > 0 && 'grid-cols-2'} gap-4`}>
            <div className="">
              <Dropdown
              overlayClassName="overflow-scroll max-h-40 shadow-lg"
              menu={{
                items: columnOptions,
                selectable: true
              }}
              open={dropdownVisible && isModalVisible}
              trigger={[]}
              onOpenChange={(flag) => setDropdownVisible(flag)}
            >
              <TextArea
                autoSize={{ minRows: 2, maxRows: 5 }}
                value={inputText}
                rows={5}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter your formula prompt here"
                className={``}
                autoFocus
              />
            </Dropdown>
              <div className="text-sm text-gray-500 mt-1">
                Press '/' to enter column names
              </div>
              {(isGeneratingFormula !== 0 && isGeneratingFormula !== 1) && (
                <div className="mt-4">
                <h3 className="mb-2">Example Prompts:</h3>
                <ul>
                  {examplePrompts.map((example, index) => (
                    <li key={index} className="mb-2">
                      <strong>Prompt:</strong> {example.prompt}
                      <br />
                      <strong>Formula:</strong> {example.formula}
                    </li>
                  ))}
                </ul>
              </div>
              )}
              {isGeneratingFormula === 0 && (
                <Tag icon={<SyncOutlined spin />} color="processing">
                  Generating Formula
                </Tag>
              )}
              {isGeneratingFormula === 1 && (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  {formula}
                </Tag>
              )}
            </div>
            <div className="max-h-[30vh] overflow-auto">
              {formulaResult.length > 0 && (
                <Table columns={resultColumns} dataSource={formulaResult} pagination={false} />
              )}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DisplayTableComponent;
