// import { Grid } from "../../components/IntegrationComponents/Grid";
import { Grid } from "./Grid";
import { Header } from "../IntegrationComponents/Header";
// import "./Integration.css";
import React, { useRef, useEffect, useState } from "react";
import SuccessComponent from "./SuccessComponent";

const Destinations = () => {
    const [isGridSelected, setIsGridSelected] = useState(false)

  return (
    <>
    { !isGridSelected ?
        <div className="int-container">
        {/* <Header /> */}
        <div className="integration-container">
            <div className="container-center">
            <Grid setIsGridSelected={setIsGridSelected}/>
            </div>
        </div>
        </div> : <SuccessComponent />
    }
    </>
  );
}

export default Destinations;
