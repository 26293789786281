import React from 'react'

const LeadOutputComponent = ({ finalContent }) => {
  const targetIndustries = ["Target Industry A", "Target Industry B", "Other Industries", "Others"];

  const mapper = [
    {
        key: finalContent?.contact_linkedin_url?.salesforce_field || "Un-identified Field",
        value: finalContent?.contact_linkedin_url?.value || "Cannot Identify"
    },
    {
        key: finalContent?.contact_email?.salesforce_field || "Un-identified Field",
        value: finalContent?.contact_email?.value || "Cannot Identify"
    },
    {
        key: finalContent?.contact_title?.salesforce_field || "Un-identified Field",
        value: finalContent?.contact_title?.value || "Cannot Identify"
    },
    {
        key: finalContent?.contact_phone?.salesforce_field || "Un-identified Field",
        value: finalContent?.contact_phone?.value || "Cannot Identify"
    },
    {
        key: finalContent?.contact_city?.salesforce_field || "Un-identified Field",
        value: finalContent?.contact_city?.value || "Cannot Identify"
    },
    {
        key: finalContent?.contact_state?.salesforce_field || "Un-identified Field",
        value: finalContent?.contact_state?.value || "Cannot Identify"
    },
    {
        key: finalContent?.account_id?.salesforce_field || "Un-identified Field",
        value: finalContent?.account_id?.value || "Cannot Identify"
    },
    {
        key: finalContent?.account_name?.salesforce_field || "Un-identified Field",
        value: finalContent?.account_name?.value || "Cannot Identify"
    },
    {
        key: finalContent?.account_website?.salesforce_field || "Un-identified Field",
        value: finalContent?.account_website?.value || "Cannot Identify"
    },
    {
        key: finalContent?.account_country?.salesforce_field || "Un-identified Field",
        value: finalContent?.account_country?.value || "Cannot Identify"
    },
    {
        key: finalContent?.account_state?.salesforce_field || "Un-identified Field",
        value: finalContent?.account_state?.value || "Cannot Identify"
    },
  ]

  return (
    <div className='flex flex-col justify-start items-center w-full gap-4 h-[35vh] overflow-scroll py-4'>
        {mapper?.filter(item => item?.value !== "Cannot Identify")?.map((item, index) => (
            <div key={index} className='flex w-full justify-between items-center' >
                <div>
                    {item?.key}
                </div>
                <div className='border rounded-md bg-gray-200 px-2 py-3 w-[20rem] overflow-x-scroll whitespace-nowrap'>
                    {item?.value}
                </div>
            </div>
        ))}
    </div>
  )
}

export default LeadOutputComponent