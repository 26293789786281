import { atom } from "recoil";

const activeToastComponentAtom = atom({
  key: "activeToastComponent",
  default: 0
})

const toastRowsAtom = atom({
  key: "toastRows",
  default: 1,
});

const toastInputFilledAtom = atom({
  key: "toastInputFilled",
  default: false,
});

const toastQuestionAtom = atom({
  key: "toastQuestion",
  default: "",
});

const toastChatArrayAtom = atom({
  key: "toastChatArray",
  default: [
    {
      answer:
      // "Hi this is ADA, your personal GTM CoPilot. How can I help you today?",
        "Hi I am ADA, for this demo we have picked 121 Toast customer in Boston and built a knowledge graph based on public data ONLY",
      suggestions: [
        "What data was captured for this POC",
        "Data Source for this POC",
        "Monitoring"
      ],
    },
  ],
});

const toastLoadingAtom = atom({
  key: "toastLoading",
  default: false,
});

const toastIsFollowupClickedAtom = atom({
  key: "toastFollowupClick",
  default: false,
});

const toastExtendedChatAtom = atom({
  key: "toastExtendedChat",
  default: false,
});

const toastSampleDAtom = atom({
  key: "sampleD",
  default: [],
});

export {
  activeToastComponentAtom,
  toastRowsAtom,
  toastInputFilledAtom,
  toastQuestionAtom,
  toastChatArrayAtom,
  toastLoadingAtom,
  toastIsFollowupClickedAtom,
  toastExtendedChatAtom,
  toastSampleDAtom
};
