import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import "./Header.css";
import { message, Col, Typography, Dropdown, Avatar, Menu, Divider, Switch, Button } from "antd";
import {
  RobotOutlined,
  ApiOutlined,
  CompassOutlined,
  SettingOutlined,
  MessageOutlined,
  BookOutlined,
  PlayCircleOutlined,
  LineChartOutlined,
  HomeOutlined,
  TeamOutlined,
  ReadOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import avatar8 from './../../../assets/images/no_profile.png';
import { jwtDecode } from "jwt-decode";
import { useHistory, useNavigate } from "react-router-dom";
import logoWOname from './../../../assets/images/logoWOname.png'
// import gtmLogo from './../../../assets/images/gtmCopilotname.png';
import { readLogo } from "../../../services/Settings";
import { tenantLogoAtom } from "../../../store/atoms/MainAtoms";
import { selectedWorkspaceAtom } from "../../../store/atoms/WorkspaceAtoms";
import github from "../../../assets/images/Github.png"

function Header({ siderWidth }) {
  const { Title } = Typography;
  const [pageTitle, setPageTitle] = useState("");
  const [pageIcon, setPageIcon] = useState(null);
  const { pathname } = useLocation();
  const [tenantLogo, setTenenatLogo] = useRecoilState(tenantLogoAtom);
  const [workspace, setWorkspace] = useState('');
  const [selectedWorkspace] = useRecoilState(selectedWorkspaceAtom);
  const [name, setName] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('loginToken')
    let decodedToken
    token ? decodedToken = jwtDecode(token.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')) : decodedToken = null
    if (decodedToken.name.includes('|')) {
      setName(decodedToken.name.split('|')[0])
    }
    else {
      setName(decodedToken.name)
    }
  }, [])
  // const history = useHistory();
  const navigate = useNavigate();
  const getLogo = async () => {
    try {
      const domain = jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).organization_domain
      if (!domain) {
        message.error("Please provide an organization domain.")
        return;
      }
      const response = await readLogo({ organizationDomain: domain });
      if (response.data.imageData) {
        localStorage.setItem('logoData', response.data.imageData)
        setTenenatLogo(response.data.imageData)
        console.log(tenantLogo, "tenantLogo")
      }

      if (!response.data.imageData) {
        throw new Error("Image data not provided in the response.");
      }
      return response.data;
    } catch (error) {
      console.error("Error reading image:", error);
      return null; // Return null in case of error.
    }
  };
  useEffect(() => {
    getLogo()
  }, [])
  useEffect(() => {
    let title = "";
    let icon = null;

    if (pathname === "/chat" || pathname === "/copilot" || pathname === "/cc-demo" || pathname === "/toast" || pathname === "/ttyd-advanced" || pathname === '/assistant') {
      title = "Assistant";
      icon = <MessageOutlined style={{ fontSize: "16px" }} />;
    } else if (pathname === "/discover") {
      title = "ICP";
      icon = <CompassOutlined style={{ fontSize: "16px" }} />;
    } else if (pathname === "/agents" || pathname.includes("/templates") || pathname.includes("/pipeline") || pathname.includes("lead") || pathname.includes("enrichment") || pathname.includes("prog")) {
      title = "Multi Agent Workflow Builder";
      icon = <RobotOutlined style={{ fontSize: "16px" }} />;
    } else if (pathname === "/settings") {
      title = "Settings";
      icon = <SettingOutlined />;
    } else if (pathname === "/integration") {
      title = "Integrations";
      icon = <ApiOutlined style={{ fontSize: "16px" }} />;
    }
    else if (pathname === "/audiences") {
      title = "Audiences";
      icon = <TeamOutlined style={{ fontSize: "16px" }} />;
    }
    else if (pathname === "/audiences/new-segment") {
      title = "Audiences > New Segment";
      icon = <TeamOutlined style={{ fontSize: "16px" }} />;
    }
    else if (pathname === "/audiences/segment-studio") {
      title = "Audiences > New Segment > Segment Studio";
      icon = <TeamOutlined style={{ fontSize: "16px" }} />;
    }
    else if (pathname === "/live") {
      title = "Live Sync in Production";
      icon = <PlayCircleOutlined style={{ fontSize: "16px" }} />;
    }
    else if (pathname === "/home") {
      title = "Home";
      icon = <HomeOutlined style={{ fontSize: "16px" }} />;
    }
    else if (pathname === "/analytics") {
      title = "Analytics";
      icon = <LineChartOutlined style={{ fontSize: "16px" }} />;
    }
    else if (pathname === "/copilot-new") {
      title = "Analytics";
      icon = <LineChartOutlined style={{ fontSize: "16px" }} />;
    }


    setPageTitle(title);
    setPageIcon(icon);
  }, [pathname]);
  useEffect(() => {
    const token = localStorage.getItem('loginToken')
    let decodedToken
    token ? decodedToken = jwtDecode(token.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')) : decodedToken = null
    if (decodedToken.organization_domain.includes('.')) {
      setWorkspace(decodedToken.organization_domain.split('.')[0])
    }
    else {
      setWorkspace(decodedToken.organization_domain)
    }
  }, [])
  const handleMenuClick = (e) => {
    if (e.key === "settings") {
      // Handle settings click
      // history.push('/settings')
      navigate("/settings");
    } else if (e.key === "logout") {
      // Handle logout click
      localStorage.clear();
      setTimeout(() => {
        // history.push('/login');
        // setIsLoggedIn(false);
        navigate("/login");
      });
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Link to="https://gtmcopilot.notion.site/gtmcopilot/User-Guide-v1-6c0686c0d1f84b37a2efc9ced228525a" target="_blank">
        <Menu.Item key="userGuide" icon={<ReadOutlined />}>
          User Guide
        </Menu.Item>
      </Link>
      <Menu.Item
        key="settings"
        icon={<SettingOutlined />}
      // onClick={sidebarColor}
      >
        Settings
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className="h-[35%] mb-2 flex justify-between">
        <div className={` w-[35%] gap-2 justify-start items-center h-full`} style={siderWidth <= 225 ? { width: "225px" } : { width: `${siderWidth}px` }}>
          <div className="brand h-full flex justify-center py-[1rem] -ml-[2.2rem] items-center flex cursor-pointer w-[90%] gap-2" style={{ fontSize: "14px", fontWeight: 500 }}>

            {/* {localStorage.getItem("path") == "copilot" ? "CC_demo-workspace" : localStorage.getItem("path") == "toast" ? "toast_demo-workspace" : "demo_workspace"} */}
            {selectedWorkspace?.workspace_name}
            {/* {['/agents', '/templates/sales_crm', '/templates/enrichment', '/templates/lead', '/templates/prog'].includes(pathname) && (
              <>
                <img src={github} alt="github" width={16} />
                <p>Github</p>
              </>
            )} */}
          </div>
        </div>

        <div className="flex gap-2 mt-2 items-center h-full justify-end">
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="flex cursor-pointer justify-center gap-2 items-center">
              <Avatar style={{ height: "25px", width: "25px" }}
                src={localStorage.getItem('profile_url') ? localStorage.getItem('profile_url').toString() : avatar8}
              ></Avatar>
              <Title level={5} style={{ marginBottom: "0", color: "gray", fontSize: "14px" }}>
                {name}
              </Title>
            </div>
          </Dropdown>

          {/* <img src={gtmLogo} alt="logo" className="h-[100%]" /> */}
        </div>
      </div>
      {/* {!['/agents', '/templates/sales_crm', '/templates/enrichment', '/templates/lead', '/templates/prog'].includes(pathname) && ( */}
      <div className=" flex justify-start mx-4">
        <div style={{ color: "#2966BC" }} className="flex ml-4 justify-center items-center gap-2">
          {pageIcon}
          <Title className="mt-2" style={{ color: "#2966BC" }} level={4}>{pageTitle}</Title>
          {/* <h4 className="text-[16px] font-[600]">{pageTitle}</h4> */}
        </div>
      </div>
      {/* )} */}

    </>
  );
}

export default Header;
