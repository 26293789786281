import { atom } from "recoil";

const activeAudienceAtom = atom({
  key: "activeAudience",
  default: 0,
});

const resultsVisibleAtom = atom({
  key: "resultsVisible",
  default: false
})


export {
  activeAudienceAtom,
  resultsVisibleAtom
};
