import React from "react";

const DefineHeader = () => {
  return (
    <div className="flex flex-col justify-center gap-1">
      <p className="h3_Medium text-primary">Define your Audience</p>
      <p className="h5_Regular text-primary">
        Filter down customers using anything in your underlying data
      </p>
    </div>
  );
};

export default DefineHeader;
