import React, { useEffect, useState, useRef } from "react";
import logo from "../../assets/images/logoWOname.png";
import avatar from "../../assets/images/user.png";
import {
  CopyOutlined,
  LikeOutlined,
  DislikeOutlined,
  LinkOutlined,
  PieChartFilled,
  ArrowsAltOutlined,
  UndoOutlined
} from "@ant-design/icons";
import { Card, Button, Skeleton, message, Table, Progress, Modal, Input } from "antd";
import { motion } from "framer-motion";
// import { Bar } from "react-chartjs-2";
import graphImg from "../../assets/images/graphImg.png";
import shareImg from "../../assets/images/shareImg.png";
import downloadImg from "../../assets/images/downloadImg.png";
import refreshImg from "../../assets/images/refresh.png";
import PythonExecutor from "./PythonExecutor";
import salesforceImg from "../../assets/images/salesforceImg.png";
import {
  BarChart, Bar, LineChart, Line, PieChart, Pie, FunnelChart, Funnel, ScatterChart, Scatter,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList
} from 'recharts';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  copilotChatArrayAtom,
  copilotLoadingAtom,
  extendedChatAtom,
  copilotQuestionAtom,
  chatArrayAtom,
  isFollowupClickedAtom,
  isFileAttachedAtom,
  copilotFileLoadingAtom,
  cc_tableColsAtom,
  cc_tableDataAtom,
  cc_magicAtom,
  hiddenColumnsAtom,
  deletedColumnsAtom,
  actionHistoryAtom
} from "../../store";
import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms.js";

import { sendFeedback } from "../../services/chatServices";
import { jwtDecode } from "jwt-decode";
import html2canvas from "html2canvas";
import DisplayTableComponent from "./DisplayTableComponent.js";
import { saveToDashboard } from "../../services/InsightsServices.js";
import GraphOptions from "../GraphOptions.js";

const ChatWindow = () => {
  const [pocTableData, setPocTableData] = useRecoilState(cc_tableDataAtom)
  const [pocTableCols, setPocTableCols] = useRecoilState(cc_tableColsAtom)
  const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
  const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);
  const isFileAttached = useRecoilValue(isFileAttachedAtom)
  const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
  const [loading, setLoading] = useRecoilState(copilotLoadingAtom);
  const [question, setQuestion] = useRecoilState(copilotQuestionAtom);
  const [isFollowupClicked, setIsFollowupClicked] = useRecoilState(isFollowupClickedAtom);
  const setExtendedChat = useSetRecoilState(extendedChatAtom)
  const [randomText, setRandomText] = useState(
    "Gathering and cleaning relevant data"
  );
  const [isFeedbackVisible, setFeedbackVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(-1)
  const [tableVisible, setTableVisible] = useState(-1)
  const [progress, setProgress] = useState(0); // Progress state
  const [progressIndex, setProgressIndex] = useState(0); // Index for messages
  const [fileLoading, setFileLoading] = useRecoilState(copilotFileLoadingAtom);
  const setMagic = useSetRecoilState(cc_magicAtom);
  const [selectedGraphQueryId, setSelectedGraphQueryId] = useState(null)
  const [selectedGraphSQLquery, setSelectedGraphSQLquery] = useState(null)
  const [graphOptionsModal, setGraphOptionsModal] = useState(false)
  const pocRef = useRef(null);

  const [hiddenColumns, setHiddenColumns] = useRecoilState(hiddenColumnsAtom);
  const [deletedColumns, setDeletedColumns] = useRecoilState(deletedColumnsAtom);
  const [actionHistory, setActionHistory] = useRecoilState(actionHistoryAtom);

  const messages = [
    "Analyzing CSV",
    "Reading and categorizing headers",
    "Analyzing Rows",
    "Identifying primary keys and natural keys",
    "Matching CSV data with OGraph",
    "Deduplicating",
    "Summarizing CSV data and analysis",
  ];

  let lastChatRef = useRef(null);
  let lastInsightRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    let timeoutId, insightTimeoutId;
    if (lastChatRef.current && !lastInsightRef.current) {
      timeoutId = setTimeout(() => {
        lastChatRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 500);
    } else if (lastInsightRef.current) {
      insightTimeoutId = setTimeout(() => {
        lastInsightRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 1500); // Delay to wait for text animation to at least be visible to user
    }
    console.log(
      chatArray,
      "------------------------------chatArray----------------------------"
    );
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(insightTimeoutId);
    };
  }, [chatArray]);

  useEffect(() => {
    let pocTimeout
    if (pocRef.current) {
      pocTimeout = setTimeout(() => {
        pocRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 500);
    }

    return () => clearTimeout(pocTimeout)
  }, [fileLoading, chatArray])
  const handleProgressBar = () => {
    if (progress < 100 && progressIndex < messages.length) {
      const interval = setInterval(() => {
        setProgress((prev) => prev + 16);

        setProgressIndex((prev) => prev + 1);
      }, 1300);
      return () => clearInterval(interval);
    }
  }
  useEffect(() => {
    if (fileLoading) {
      handleProgressBar()
    }
  }, [fileLoading]);
  useEffect(() => {
    if (progress > 100) {
      setFileLoading(false)
      setProgress(0)
    }
  }, [progress]);

  const isNumberedList = (text) => {
    // Check if the text starts with a number followed by a dot and a space
    const result = /^(-|\d+\.\s)/.test(text?.trim());
    // console.log(text, result1);
    return result;
  };

  const charVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };

  const splitString = (inputString) => {
    const characters = [];
    const regex = /[\s\S]/gu;

    let match;

    while ((match = regex.exec(inputString)) != null) {
      characters.push(match[0]);
    }

    return characters;
  };

  const handleSuggestionClick = (insight) => {
    setQuestion(insight)
    setIsFollowupClicked(true)
  };

  const copyAnswer = (textToCopy) => {
    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }

    // Write the text to the clipboard
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard:", textToCopy);
        message.success("The answer has been copied to your clipboard");
        // You can optionally show a success message or perform other actions
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
        // Handle the error, such as showing an error message to the user
      });
  };

  const handleFeedback = async (chat, feedbackType, textmessage) => {
    const payload = {
      session_id: localStorage.getItem("session_id"),
      user_id: jwtDecode(
        localStorage
          .getItem("loginToken")
          ?.replace(process.env.REACT_APP_TOKEN_PASSCODE, "")
      )?.id,
      chat: chat,
      feedbackType: feedbackType == 0 ? true : false,
      message: textmessage,
    };
    let response = await sendFeedback(payload);
    console.log(response);
    if (response.data.status == "success") {
      console.log(response.data.data, "dkaugcfjsdgfajsdgbasx");
      message.success("Your Feedback has been recorded");
    }
  };

  const handleSaveToDashboard = async (query_id) => {
    const payload = {
      query_id: query_id
    };
    let response = await saveToDashboard(payload);
    console.log(response);
    if (response.data.status == "success") {
      message.success("Insight has been saved to Dashboard");
    }
  };

  const [feedback, setFeedback] = useState("");

  const handleFeedbackCancel = () => {
    handleFeedback(localStorage.getItem("dislikeChat"), 1, "")
    setFeedbackVisible(false);
    setFeedback("");
  };


  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };
  const handleDislike = (chat) => {
    localStorage.setItem("dislikeChat", JSON.stringify(chat))
    setFeedbackVisible(true)
  }
  const handleOk = (chat) => {
    handleFeedback(localStorage.getItem("dislikeChat"), 1, feedback)
    setFeedback("");
    setFeedbackVisible(false)
  };

  const handleFilter = (index) => {
    console.log(filterVisible)
    const prevIndex = filterVisible
    if (filterVisible === -1 || prevIndex !== index) {
      setFilterVisible(index)
    } else {
      setFilterVisible(-1)
    }
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleFilter(-1);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleTableVisible = (index) => {
    console.log(tableVisible)
    const prevIndex = tableVisible
    if (tableVisible === -1 || prevIndex !== index) {
      setTableVisible(index)
    } else {
      setTableVisible(-1)
    }
  }

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const downloadChartAsImage = (id) => {
    const chartElement = document.getElementById(id);
    console.log(chartElement, "-----sdfsf------");
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'chart.png';
        link.click();
        message.success("Image downlaoded successfully")
      });
    }
  };
  const extendChat = (chat, type) => {
    if (type == "live") {
      localStorage.setItem("extended_query_id", chat.query_id)
      localStorage.setItem("extended_chat_table", chat.newTable)

    }
    else {
      localStorage.setItem("extended_query_id", "FILE_CC")
      const answerToCheck = "Great! Before we begin I can see the data has not been cleaned or normalized. Shall we start by cleaning and normalizing the data before enrichment?";

      setChatArray(prev => {
        const alreadyExists = prev.some(chatItem => chatItem.answer === answerToCheck);

        if (!alreadyExists) {
          return [
            ...prev,
            {
              question: "Expand the table",
              answer: answerToCheck
            }
          ];
        }
        return prev;
      });
    }
    setSideNavContent(1);
    setChatMode(1);
    setExtendedChat(true);
  }
  const openGraphOptionsModal = (query_id, postgres_query) => {
    setSelectedGraphQueryId(query_id)
    setSelectedGraphSQLquery(postgres_query)
    setGraphOptionsModal(true)
  }
  const handleCSVBtnClick = (btn) => {
    if (btn === "Add New Fields") {
      setMagic(true);
      setChatArray(prevChatArray => ([
        ...prevChatArray,
        {
          answer: "Great! I have surfaced the Add Enrichment tool for your reference. Please click the fields you would like to add or simply tell me in the chat."
        }
      ]))
    } else {
      setChatArray(prevChatArray => ([
        ...prevChatArray,
        {
          question: "Let's add them to my Summer Events Salesforce Campaign & push them to Salesforce",
          answer: "Is this Campaign Correct?",
          sfPlace: true
        }
      ]))
    }
  }
  const renderChartMain = (selectedChartType, chartData, showReferenceLine, xAxis, yAxis, chartColor, showValues) => {
    switch (selectedChartType) {
      case 'Bar':
        return (
          <BarChart
            width={1000} // Increased width
            height={500}
            data={chartData}
            margin={{ top: 20, right: 30, left: 40, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis
              dataKey={xAxis}
              tick={{
                angle: -60, // Increased angle for better fitting
                fontSize: 10,
                width: 100, // Increased width for labels
                interval: 0, // Show all labels
                textAnchor: 'end', // Align text to the end
                formatter: (value) => {
                  const stringValue = String(value);
                  return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                },
              }}
              height={100} // Further increased height for more space
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill={chartColor}>
              {showValues && (
                <LabelList
                  dataKey={yAxis}
                  position="top" // Move labels outside the bars
                  formatter={(value) => {
                    const stringValue = String(value);
                    return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                  }}
                  style={{ fontSize: 10 }} // Adjust font size if necessary
                />
              )}
            </Bar>
          </BarChart>

        );
      case 'Column':
        return (
          <BarChart width={1000} height={500} data={chartData} layout="vertical" margin={{ top: 20, right: 30, left: 40, bottom: 30 }}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis type="number" tick={{ fontSize: 10 }} />
            <YAxis dataKey={xAxis} type="category" tick={{ fontSize: 8 }} width={100} /> {/* Adjust width for longer labels */}
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill={chartColor}>
              {showValues && (
                <LabelList
                  dataKey={yAxis}
                  position="right" // Options: 'insideLeft', 'insideRight', 'right'
                  formatter={(value) => {
                    const stringValue = String(value); // Ensure value is a string
                    return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                  }}
                  style={{ fontSize: 10 }} // Adjust font size if necessary
                />
              )}
            </Bar>
          </BarChart>

        );
      case 'Stacked Bar':
        return (
          <BarChart width={600} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="top" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="top" />}
            </Bar>
          </BarChart>
        );
      case 'Stacked Column':
        return (
          <BarChart width={600} height={300} data={chartData} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis type="number" />
            <YAxis dataKey={xAxis} type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="right" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="right" />}
            </Bar>
          </BarChart>
        );
      case 'Line':
        return (
          <LineChart width={1000} height={500} data={chartData} margin={{ top: 20, right: 30, left: 40, bottom: 30 }}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={yAxis} stroke={chartColor} />
          </LineChart>
        );
      case 'Donut':
        return (
          <PieChart width={800} height={800}>
            <Pie
              data={chartData}
              dataKey={yAxis}
              nameKey={xAxis}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill={chartColor}
              label={showValues}
            />
          </PieChart>
        );
      case 'Funnel':
        return (
          <FunnelChart width={1000} height={550}>
            <Tooltip />
            <Funnel
              dataKey={yAxis}
              data={chartData}
              isAnimationActive
              fill={chartColor}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={chartColor} />
              ))}
            </Funnel>
          </FunnelChart>
        );
      case 'Scatter Plot':
        return (
          <ScatterChart width={1000} height={550}>
            <CartesianGrid vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis dataKey={yAxis} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="Scatter Plot" data={chartData} fill={chartColor} />
          </ScatterChart>
        );
      default:
        return null;
    }
  };
  const graphOptionclose = () => {
    setGraphOptionsModal(false)
  }
  const handleUndo = () => {
    if (actionHistory.length === 0) return;

    const lastAction = actionHistory[actionHistory.length - 1];
    if (lastAction.action === "hide") {
      setHiddenColumns(
        hiddenColumns.filter((col) => col !== lastAction.column)
      );
    } else if (lastAction.action === "delete") {
      setDeletedColumns(
        deletedColumns.filter((col) => col !== lastAction.column)
      );
    }

    setActionHistory(actionHistory.slice(0, -1));
  };
  return (
    <>
      <Modal
        title="Feedback"
        visible={isFeedbackVisible}
        onCancel={handleFeedbackCancel}
        footer={[
          <Button key="back" onClick={handleFeedbackCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} disabled={feedback?.trim() == null || feedback?.trim() == ""}>
            OK
          </Button>
        ]}
      >
        <div className="m-8 flex flex-col justify-center gap-4">
          <span className="text-[15px]">Uh-oh! Seems like you're not satisfied with our response. Please describe what went wrong so we could improve it:</span>
          <Input.TextArea
            value={feedback}
            onChange={handleFeedbackChange}
            rows={4}
          />
        </div>
      </Modal>
      <Modal
        title=""
        visible={graphOptionsModal}
        onCancel={() => graphOptionclose(false)}
        footer={[
        ]}
      >
        <GraphOptions queryID={selectedGraphQueryId} postgres_query={selectedGraphSQLquery} graphOptionclose={graphOptionclose} />
      </Modal>
      <div className={` ${chatMode == 1 ? "h-[100%]" : "h-[70%]"} flex flex-col gap-2 mt-2`} style={{ overflowY: "scroll" }}>
        {chatArray.map((chat, index) => (
          <React.Fragment key={index}>
            {/* Question Component Starts */}
            {chat?.question && (
              <div
                className={`flex justify-start items-center ${chatMode == 1 ? "ml-2 gap-2 p-1" : "gap-4 p-2"}`}
              >
                <img
                  src={
                    localStorage.getItem("profile_url")
                      ? localStorage.getItem("profile_url").toString()
                      : avatar
                  }
                  alt="user-logo"
                  width={chatMode == 1 ? 20 : 30}
                  className="rounded-full self-start mt-[10px]"
                />
                <div className={`text-[14px] w-[90%] ${chatMode == 1 ? "p-2" : "p-4"} rounded-md relative text-black`}>
                  {/* <div className={`triangle-left tl-question absolute left-[-18px] top-2`} /> */}
                  {chat.question}
                </div>
              </div>
            )}
            {/* Question Component Ends */}

            {/* Answer Component Starts */}
            {/* {chat?.answer && ( */}
            <div className={`bg-[#F7F8FA] ${chatMode == 1 ? "p-1" : "p-4"}`}>
              <div className={`flex ${chatMode == 1 ? "gap-2" : "gap-4"}`}>
                <div className={`flex justify-start items-center ${chatMode == 1 ? "ml-2 " : "mt-2"}`}>
                  <img
                    src={logo}
                    alt="brand-logo"
                    width={35}
                    className="mt-1 self-start"
                  />
                </div>
                <div className={`text-[14px] w-[90%] ${chatMode == 1 ? "p-2" : "p-4"} rounded-md relative`}>
                  {/* <div className="triangle-left tl-answer absolute left-[-18px] top-2" /> */}

                  <>
                    {chat.answer?.split("\n")?.map((ansString, idx) => (
                      <motion.div
                        initial="hidden"
                        animate="reveal"
                        transition={{ staggerChildren: 0.04 }}
                        key={idx}
                      >
                        <div
                          // initial="hidden"
                          // animate="reveal"
                          // transition={{ staggerChildren: idx * 0.01 }}
                          className={`${isNumberedList(ansString) && "px-4"}`}
                        // onAnimationComplete={handleAnimationComplete}
                        >
                          {ansString
                            .split(/(sourceLink\d+)/)
                            .map((segment, indx) => {
                              if (segment.match(/^sourceLink\d+$/)) {
                                const number = segment.match(/\d+/)[0];
                                return (
                                  <span
                                    key={indx}
                                    style={{ verticalAlign: "super" }}
                                  >
                                    <sub
                                      style={{
                                        fontSize: "12px",
                                        fontStyle: "italic",
                                      }}
                                    >
                                      {number}
                                    </sub>
                                  </span>
                                );
                              } else if (segment.includes("Sources:")) {
                                const parts = segment.split("Sources:");
                                return (
                                  <span key={indx}>
                                    <span>{parts[0]}</span>
                                    <strong>Sources:</strong>
                                    <span>{parts[1]}</span>
                                  </span>
                                );
                              } else {
                                return splitString(segment).map((ch, idx) => (
                                  <motion.span
                                    key={`${ch}_${idx}`}
                                    transition={{ duration: 0.2 }}
                                    variants={charVariants}
                                  >
                                    {ch}
                                  </motion.span>
                                ));
                              }
                            })}
                        </div>
                      </motion.div>
                    ))}
                  </>
                  {chat?.sfPlace && (
                    <>
                      <div className="border rounded-md bg-white p-1">
                        <div className="flex gap-2" >
                          <img src={salesforceImg} alt="salesforce" width={24} />
                          <p className="text-[10px]">Summer Event</p>
                        </div>
                        <p className="text-[10px] ml-8">2024</p>
                      </div>
                    </>
                  )}
                  <Skeleton
                    loading={index === chatArray.length - 1 && loading}
                    active
                  />
                  {chat.fileRunning && fileLoading && (
                    <>
                      <div className="flex justify-center w-[50%] items-center gap-4">
                        <Progress percent={progress} />
                      </div>
                      <div className="text-[grey]"><strong>{messages[progressIndex]}</strong></div>
                    </>
                  )}
                </div>
              </div>

              {/* Answer Component Ends */}
              {chat?.pocRef && (
                <div className="text-white" ref={pocRef}>placeholder</div>
              )}
              {/* CSV Buttons Starts */}
              {chat?.csvBtns && chat?.csvBtns?.length > 0 && (
                <div className="ml-10 flex flex-col gap-2 justify-center items-center flex-wrap" ref={pocRef}>
                  {chat?.csvBtns.map((btn, index) => (
                    <div key={index} className="border bg-[#E9EDF1] hover:bg-[#2966BC] hover:text-white cursor-pointer rounded-md p-1 text-center text-[12px]" onClick={() => handleCSVBtnClick(btn)} >
                      {btn}
                    </div>
                  ))}
                </div>
              )}
              {/* CSV Buttons Ends */}
              {/* File Upload data starts*/}
              {chat.fileRunning && !fileLoading && chatMode != 1 && (
                <>
                  <Card className="w-1/2 ml-24 bg-[#E9EDF1] ">
                    <div className="">
                      <div className="mb-6"><strong>Match Distribution</strong></div>
                      {/* <Progress percent={progress} /> */}
                      <div className="">Matched:</div>
                      <div className="flex justify-center items-center gap-4">
                        <Progress percent={90} />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>{27}</span>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-1/2 ml-24 bg-[#E9EDF1] ">
                    <div className="">
                      <div className="mb-6"><strong>Existing and Net New Contacts</strong></div>
                      {/* <Progress percent={progress} /> */}
                      <div className="">Existing Contacts:</div>
                      <div className="flex justify-center items-center gap-4">
                        <Progress percent={80} />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>{24}</span>
                      </div>
                      <div className="">New Contacts:</div>
                      <div className="flex justify-center items-center gap-4">
                        <Progress percent={20} />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>{6}</span>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-1/2 ml-24 bg-[#E9EDF1] " ref={index === chatArray.length - 1 ? pocRef : null}>
                    <div className="">
                      <div className="mb-6"><strong>CSV Data Summary</strong></div>
                      {/* <Progress percent={progress} /> */}
                      <div className="">Email:</div>
                      <div className="flex justify-center items-center gap-4">
                        <Progress percent={100} />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>{30}</span>
                      </div>
                      <div className="">Name:</div>
                      <div className="flex justify-center items-center gap-4">
                        <Progress percent={36.6} />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>{11}</span>
                      </div>
                      <div className="">Event Name:</div>
                      <div className="flex justify-center items-center gap-4">
                        <Progress percent={100} />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>{30}</span>
                      </div>
                      <div className="">Country:</div>
                      <div className="flex justify-center items-center gap-4">
                        <Progress percent={16.6} />
                        <span style={{ marginLeft: 10, fontSize: 18 }}>{5}</span>
                      </div>
                    </div>
                  </Card>
                </>
              )}
              {/* File Upload data ends */}
              {(!loading || index !== chatArray.length - 1) && (
                <>
                  {/* Chart Component Starts */}

                  {(chat?.charts || chat?.fileRunning) && !fileLoading && chatMode != 1 &&
                    <div className="w-full flex justify-center items-center">
                      <Card className="w-[85%] relative">
                        <>
                          {chat?.fileRunning && "Summer Event 2024"}
                          {chat?.chartTitle && <div><h2>{chat?.chartTitle}</h2></div>}
                        </>
                        <div className="absolute mt-2 top-2 right-2 flex justify-center items-center gap-2">
                          {actionHistory?.length > 0 && tableVisible === index && (
                            <UndoOutlined rotate={90} className="" onClick={handleUndo} />
                          )}
                          <div ref={menuRef} className="w-full -mr-1 mb-0.5 relative">
                            <img
                              src={shareImg}
                              alt="shareImg"
                              width={24}
                              className={`hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] ${filterVisible === index && 'border border-white border-b-[#E07E65]'}`}
                              onClick={() => handleFilter(index)}
                            />
                            {filterVisible === index && (
                              <div className="absolute -bottom-[8.5rem] -right-[4.5rem] z-50 flex flex-col gap-1 justify-center items-center w-[900%] border bg-gray-300 p-2">
                                <div onClick={() => handleSaveToDashboard(chat?.query_id)} className="w-full flex justify-center items-center border bg-white hover:bg-[#2966BC] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                                  Save to Dashboard
                                </div>
                                <div className="w-full flex justify-center items-center border bg-white hover:bg-[#2966BC] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                                  Generate Embeddable Link
                                </div>
                                <div className="w-full flex justify-center items-center border bg-white hover:bg-[#2966BC] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                                  Send on Slack
                                </div>
                                <div className="w-full flex justify-center items-center border bg-white hover:bg-[#2966BC] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                                  Send Via Email
                                </div>
                              </div>
                            )}
                          </div>
                          <img
                            src={graphImg}
                            alt="graphImg"
                            width={22}
                            className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
                            onClick={() => openGraphOptionsModal(chat?.query_id, chat?.postgres_query)}
                          />
                          <PieChartFilled
                            className={`-mr-1 mb-0.5 hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] hover:p-1 ${tableVisible !== index && 'border border-white border-b-[#E07E65] p-1'}`}
                            onClick={() => handleTableVisible(index)}
                          />
                          <img
                            src={downloadImg}
                            alt="downloadImg"
                            width={26}
                            className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
                            onClick={() => downloadChartAsImage(chat?.query_id)}
                          />
                          <ArrowsAltOutlined
                            className="text-[14px] -ml-1 hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] hover:p-1"
                            onClick={() => chat?.fileRunning ? extendChat(chat, "file") : extendChat(chat, "live")}
                          />
                        </div>
                        {tableVisible !== index && chat?.charts ? (
                          <>
                            {chat?.chartType == "python" ?

                              <div id={chat?.query_id} className="flex justify-start items-center flex-wrap gap-4 text-base w-[90%] rounded-md mt-4 p-4 ml-[74px]">
                                <PythonExecutor code={chat.charts} query_id={chat.query_id} postgres_query={chat?.postgres_query} description={chat?.chartMessage} topic={chat?.topic} />
                              </div> :
                              <div id={chat?.query_id} className="flex justify-start items-center flex-wrap gap-4 text-base w-[90%] rounded-md mt-4 p-4 ml-[74px]">
                                {renderChartMain(chat?.chartType, chat?.data, chat?.showGrid, chat?.x_Axis, chat?.y_Axis, chat?.color, chat?.showValues)}
                              </div>
                            }

                          </>
                        ) : chat?.fileRunning ? (
                          <div id={chat?.query_id} className="flex border border-[#2966BC] justify-start items-center flex-wrap gap-4 text-base w-[90%] rounded-md mt-4 p-4 ml-[40px]">
                            <Table
                              className="w-full h-[300px]"
                              style={{ overflowX: "auto", overflowY: "auto" }}
                              dataSource={pocTableData}
                              columns={pocTableCols}
                              pagination={false}
                              rowKey={(record) => record.id || record.key || record[Object.keys(record)[0]]}
                              scroll={{ y: 250 }}
                            />
                          </div>
                        ) : (
                          // <div id={chat?.query_id} className="flex justify-start items-center flex-wrap gap-4 text-base w-[90%] rounded-md mt-4 p-4 ml-[40px]">
                          //   <DisplayTableComponent postgres_query={chat?.postgres_query} minimized={true} />
                          // </div>
                          <div className=" w-[90%] rounded-md mt-4 ml-[40px]" >
                            <DisplayTableComponent postgres_query={chat?.postgres_query} minimized={true} />
                          </div>
                        )
                        }
                      </Card>
                    </div>
                  }
                  {/* Chart Component Ends */}

                  {/* Chart Message Starts */}
                  {chat?.chartMessage && !fileLoading && chatMode != 1 ? (
                    <div className="flex text-[14px] justify-start items-center flex-wrap gap-4 w-[90%] rounded-md p-4 ml-[74px]">
                      {chat.chartMessage}
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Chart Message Ends */}

                  {/* Insights Component Starts */}
                  {/* {chat?.suggestions && chat?.suggestions?.length > 0 && chatMode != 1 ? (
                      <div ref={
                        index !== 0 && index === chatArray.length - 1
                          ? lastChatRef
                          : null
                      } className="flex justify-start items-center flex-wrap gap-4 bg-[#E9EDF1] text-base w-[90%] rounded-md p-4 ml-[74px]">
                        {chat?.suggestions?.map((followUp, index) => (
                          <div
                            key={index}
                            className="flex justify-center items-center text-[14px] text-[#2966BC] bg-white border border-[#2966BC] rounded-md p-2 cursor-pointer hover:scale-105 duration-300 ease-in-out hover:bg-[#2966BC] hover:text-white"
                            onClick={() => handleSuggestionClick(followUp)}
                          >
                            {followUp}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )} */}
                  {/* Insights Component Ends */}

                  {/* Feedback Component Starts */}
                  {chat?.question && chatMode != 1 && (
                    <div
                      className={`flex justify-end items-center gap-4 pr-20 ${index === chatArray.length - 1 && "pb-10"
                        }`}
                      ref={index === chatArray.length - 1 ? lastInsightRef : null}
                    >
                      <CopyOutlined
                        className="text-base cursor-pointer hover:scale-125 duration-300"
                        onClick={() => copyAnswer(chat.answer)}
                      />
                      <img src={refreshImg} alt="refreshImg" width={20} className="cursor-pointer hover:scale-125 duration-300" />
                      {/* <UndoOutlined
                            rotate={100}
                            className="text-base cursor-pointer hover:scale-125 duration-300"
                          /> */}
                      <LikeOutlined
                        className="text-base cursor-pointer hover:scale-125 duration-300"
                        onClick={() => handleFeedback(JSON.stringify(chat), 0, " ")}
                      />
                      <DislikeOutlined
                        className="text-base cursor-pointer hover:scale-125 duration-300"
                        onClick={() => handleDislike(chat)}
                      />
                    </div>
                  )}
                  {/* Feedback Component Ends */}
                </>
              )}

              {/* Placeholder text for margin bottom Starts */}
              {loading && index === chatArray.length - 1 && (
                <div className="mt-40 text-white"></div>
              )}
              {/* Placeholder text for margin bottom Ends */}
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default ChatWindow;
