import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Table, Card } from "antd";

const CsvPreview = ({ csvString }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (csvString) {
            Papa.parse(csvString, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    setData(result.data);
                },
            });
        }
    }, [csvString]);

    const columns = data.length > 0 ? Object.keys(data[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key,
    })) : [];

    return (
        <Table
            dataSource={data}
            columns={columns}
            rowKey={(record, index) => index}
            pagination={{
                pageSizeOptions: ["5", "10", "20", "30", "50"],
                showSizeChanger: true,
                defaultPageSize: 5,
            }}
            bordered
            scroll={{ x: true }}
        />
    );
};

export default CsvPreview;
