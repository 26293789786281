import { Input, Radio, Select, Modal, Avatar, List } from "antd";
import React, { useState } from "react";

import icustomer from "../../assets/images/gtmCopilotname.svg"

import AgentCustomBtn from "../AgentCustomBtn/AgentCustomBtn";

const FinalizeSync = () => {
  const [intervalSelected, setIntervalSelected] = useState(false);

  const [value, setValue] = useState(1);
  const [secValue, setSecValue] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === 2) setIntervalSelected(true);
    else setIntervalSelected(false);
  };

  const data = [
    {
      title: "Splits",
      des: "Build randomized Audience Scripts directly in iCustomer to centrally manage your A/B testing across all channels consistently"
    },
    {
      title: "Traits",
      des: `Easily create new computed fields for personalization & segmentation like "Last Page Viewed" or "Lifetime Value" in a simple no-code editor`
    },
    {
      title: "Performance",
      des: "Get audience level analytics to understand how your marketing across channels is driving growth"
    },
    {
      title: "Priority Lists",
      des: "Coordinate priority across any number of your audiences within seconds"
    },
  ];

  const handleInterval = (val) => {
    setSecValue(val)
    if (val === "Minute(s)") {
        setModalOpen(true)
    }
  }

  return (
    <>
      <Modal
        title=""
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <div className="flex justify-center items-center">
            <AgentCustomBtn otherClasses={"w-fit"}>Book a Demo</AgentCustomBtn>
          </div>,
        ]}
      >
        <div className="flex flex-col justify-enter gap-6">
            <div className="flex justify-center items-center">
                <img src={icustomer} alt="logo_iCustomer" width={125} />
            </div>
        <p className="h5_Regular">
                That's it!
            </p>
            <p className="h5_Regular">
                Once you click <span className="font-bold">Finish</span> your data will start syncing immediately
            </p>
            <p className="h5_Regular">
                You know how to create a simple audience & sync. However, iCustomer has a ton of powerful features to allow marketers to access all of their customer data.
            </p>
            <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item key={index}>
                <List.Item.Meta
                    avatar={
                    <p className="text-2xl font-bold -mt-1">·</p>
                    }
                    title={<p className="h5_Regular font-bold">{item?.title}</p>}
                    description={<p className="body_Regular">{item?.des}</p>}
                />
                </List.Item>
            )}
            />
            <p className="h5_Regular mb-2">
                Our solutions team is standing by to help you get set up!
            </p>
        </div>
      </Modal>
      <div className="h4_Medium text-primary px-32 py-4">
        Finalize Settings for this Sync
      </div>
      <div className="px-36 flex flex-col justify-center gap-10">
        <div className="flex flex-col gap-2 justify-center border-b border-character pb-10">
          <p className="h5_Regular">Schedule Type</p>
          <Radio.Group
            onChange={onChange}
            value={value}
            className="flex flex-col gap-2"
          >
            <Radio
              value={1}
              className={`${
                value === 1 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Manual</p>
                <p className="body_Regular text-secondary">
                  Trigger your sync manually in the iCustomer app or using our
                  API.
                </p>
              </div>
            </Radio>
            <Radio
              value={2}
              className={`${
                value === 2 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Interval</p>
                <p className="body_Regular text-secondary">
                  Schedule your sync to run on a set interval (eg: once per
                  hour)
                </p>
              </div>
            </Radio>
            <Radio
              value={3}
              className={`${
                value === 3 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Custom Recurrence</p>
                <p className="body_Regular text-secondary">
                  Schedule your sync to run on specific days (eg: Mondays at
                  9AM)
                </p>
              </div>
            </Radio>
            <Radio
              value={4}
              className={`${
                value === 4 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Cron Expression</p>
                <p className="body_Regular text-secondary">
                  Schedule your sync using a cron expression.
                </p>
              </div>
            </Radio>
            <Radio
              value={5}
              className={`${
                value === 5 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">dbt Cloud</p>
                <p className="body_Regular text-secondary">
                  Automatically trigger your sync upon completion of a dbt Cloud
                  Job.
                </p>
              </div>
            </Radio>
            <Radio
              value={6}
              className={`${
                value === 6 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Fivetran</p>
                <p className="body_Regular text-secondary">
                  Automatically trigger your sync upon completion of a Fivetran
                  Job.
                </p>
              </div>
            </Radio>
          </Radio.Group>
        </div>
        {intervalSelected && (
          <div className="flex flex-col justify-center gap-2 border-b border-character pb-6">
            <p className="h5_Regular">Schedule Configuration</p>
            <div className="flex items-center gap-2 w-1/2">
              <p className="font-bold">Every</p>
              <Input defaultValue={"1"} />
              <Select
                placeholder="Interval..."
                className=""
                options={[
                  { label: "Minute(s)", value: "Minute(s)" },
                  { label: "Hour(s)", value: "Hour(s)" },
                  { label: "Day(s)", value: "Day(s)" },
                ]}
                value={secValue}
                onChange={(val) => handleInterval(val)}
              />
            </div>
            <div className="flex justify-end items-center mr-6">
              <AgentCustomBtn>Finish</AgentCustomBtn>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FinalizeSync;
