import { Button, Checkbox, Input } from "antd";
import React, { useState, useEffect } from "react";
const { Search } = Input;

const ValidateOption = () => {
  const [activeOption, setactiveOption] = useState(0);

    const header = [
        {
          name: "Enrich Missing",
        },
        {
            name: "Add Enrichment",
        },
      ];
  return (
    <div className='flex flex-col h-[50vh] w-full p-2'>
      <div className="flex text-[16px]" style={{fontWeight: 600}}>
      Validate and Enrich Columns
      </div>
      <div className="p-4 mt-2">
      <div className="flex justify-center">
      <Search
        placeholder="Search"
        // onSearch={onSearch}
        allowClear
        className="extendSearch"
        style={{
            width: 200,
        }}
        />
      </div>
        <div className="flex mt-6 justify-start">
            <Checkbox className="text-[16px]">All</Checkbox>
        </div>
        <div className="flex mt-2 justify-start">
            <Checkbox className="text-[16px]">Column Name</Checkbox>
        </div>
        <div className="flex mt-2 justify-start">
            <Checkbox className="text-[16px]">Column Name</Checkbox>
        </div>
        <div className="flex mt-2 justify-start">
            <Checkbox className="text-[16px]">Column Name</Checkbox>
        </div>
        
        <div className="flex mt-8 justify-center">

<Button >{activeOption == 0 ? "Enrich missing values" : "Add enrichment columns"}</Button>
</div>

      </div>
    </div>
  );
};

export default ValidateOption;
