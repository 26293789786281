import React from 'react'
import SVGElement from '../NavBar/SvgElement'

const AgentFooter = () => {
  return (
    <div className='flex justify-end items-center gap-4'>
        <p className='body_Regular'>Row 1-10 of 24</p>
        <div className='p-1 flex justify-center items-center border rounded-md hover:scale-125 cursor-pointer duration-300 ease-in-out -mr-2'>
            <SVGElement name="MoveLeft" fillColor="#D9D9D9" />
        </div>
        <div className='p-1 flex justify-center items-center border rounded-md hover:scale-125 cursor-pointer duration-300 ease-in-out'>
            <SVGElement name="MoveRight" fillColor="#16163FD9" />
        </div>
    </div>
  )
}

export default AgentFooter