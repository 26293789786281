import React from "react";
import { Card } from "antd";

import questionImg from "../../../assets/images/questionImg.png";
import appImg from "../../../assets/images/appImg.png";
import leadsImg from "../../../assets/images/leadsImg.png";
import enrichImg from "../../../assets/images/enrichImg.png";
import reportImg from "../../../assets/images/reportImg.png";
import resourcesImg from "../../../assets/images/resources.png";

import { useNavigate } from "react-router-dom";

const WorkflowCard = ({ title, icon, url, index }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 h-full cursor-pointer"
      onClick={() => navigate(url)}
    >
      <div className="flex flex-col items-center justify-center p-4 h-full">
        <img
          src={icon}
          alt={title + icon}
          className={`${index === 5 ? 'w-14' : 'w-24'} text-orange-500 mb-4 text-4xl`}
        />
        <h3 className="text-gray-800 text-lg font-semibold text-center">
          {title}
        </h3>
      </div>
    </Card>
  );
};

const Explore = () => {
  const data = [
    {
      image: questionImg,
      title: "Ask Questions",
      url: "/assistant",
    },
    {
      image: appImg,
      title: "Launch an App",
      url: "/agents",
    },
    {
      image: leadsImg,
      title: "Discover Audience",
      url: "/audiences",
    },
    {
      image: enrichImg,
      title: "List Management",
      url: "/assistant",
    },
    {
      image: reportImg,
      title: "Analytics",
      url: "/analytics",
    },
    {
      image: resourcesImg,
      title: "Resources",
    },
  ];
  return (
    <div className="bg-[#E9EDF1] m-2 min-h-[90vh] rounded-md mt-4 mr-4">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-2 gap-6">
          {data?.map((workflow, index) => (
            <div key={index} className="h-48">
              <WorkflowCard
                title={workflow.title}
                icon={workflow.image}
                url={workflow.url}
                index={index}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Explore;
