import React, { useState, useEffect } from 'react';
import { Button, Card, Table, Input, Tag } from 'antd';
import Destinations from './Destinations';

const { Search } = Input;

const getRandomDate = (start, end) => {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

const getRandomElement = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)];
};

const generateData = (numSamples) => {
    const names = ['John Doe', 'Jane Smith', 'Bob Johnson', 'Alice Brown', 'Charlie Davis', "David A", "Kim F", "Aditya kapoor", "Caroline Forbes", "Tyler G", "Jenna A", "Alaric S", "Bonnie Saltzman"];
    const emails = ['hasashi.com', 'renewbuy.com', 'wordwings.com', 'spatious.com', "gilbert.com", "elijah.com"];
    const activities = ['Visited Pricing Page', 'Downloaded Whitepaper', 'Attended Webinar', 'Signed Up for Newsletter', 'Requested Demo'];
    const tagsList = [
        ['Visited Website Recently', 'High Intent Score'],
        ['Downloaded Whitepaper', 'Engaged with Email'],
        ['Attended Webinar', 'Decision Maker'],
        ['Signed Up for Newsletter', 'Potential Lead'],
        ['Requested Demo', 'High Potential'],
    ];

    const data = [];
    for (let i = 1; i <= numSamples; i++) {
        const name = getRandomElement(names);
        const email = `${name.split(' ')[0].toLowerCase()}.${name.split(' ')[1].toLowerCase()}@${getRandomElement(emails)}`;
        const lastPurchase = getRandomDate(new Date(2022, 0, 1), new Date(2023, 11, 31)).toISOString().split('T')[0];
        const recentActivity = getRandomElement(activities);
        const tags = getRandomElement(tagsList);

        data.push({
            key: i.toString(),
            name,
            email,
            lastPurchase,
            recentActivity,
            tags,
        });
    }

    return data;
};

const SegmentStudioPreview = () => {
    const [searchText, setSearchText] = useState('');
    const [exportClicked, setExportClicked] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        const generatedData = generateData(5734);
        setData(generatedData);
    }, []);

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const filteredData = data.filter((item) => 
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase()) ||
        item.lastPurchase.toLowerCase().includes(searchText.toLowerCase()) ||
        item.recentActivity.toLowerCase().includes(searchText.toLowerCase()) ||
        item.tags.some(tag => tag.toLowerCase().includes(searchText.toLowerCase()))
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Last Purchase',
            dataIndex: 'lastPurchase',
            key: 'lastPurchase',
        },
        {
            title: 'Recent Activity',
            dataIndex: 'recentActivity',
            key: 'recentActivity',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: tags => (
                <>
                    {tags.map(tag => (
                        <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </>
            ),
        },
    ];

    return (
        <>
        {!exportClicked ?
            <div className='mt-4' style={{ flex: 1, padding: 24 }}>
                <div className='flex justify-between items-center mb-4' >
                    <h1 className='text-[24px] text-[#8EA9D0] text-bold'>Segment Preview: High Intent Former Customers</h1>
                    <div>
                        <Button type="default" style={{ marginRight: '8px' }}>Save Draft</Button>
                        <Button onClick={() => setExportClicked(true)} type="primary">Export</Button>
                    </div>
                </div>
                <Card className='bg-[#E9EDF1]'>
                <p><strong>Description:</strong> Former customers who have shown high intent to re-engage with our products or services based on recent interactions and behavior.</p>
                <p><strong>Total Contacts:</strong> 5,734</p>
                </Card>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px', marginBottom: '16px' }}>
                    <Search 
                        className='w-[45%]' 
                        placeholder="Search contacts..." 
                        onSearch={handleSearch}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Button type="default">Filter</Button>
                </div>
                <Table columns={columns} dataSource={filteredData} pagination={true} />
            </div> : <Destinations />
        }        
        </>
    );
};

export default SegmentStudioPreview;
