import React from "react";
import AgentCustomBtn from "../../components/AgentCustomBtn/AgentCustomBtn";
import { CheckOutlined } from "@ant-design/icons";
import { useRecoilValue } from "recoil";
import { activeSegmentAtom } from "../../store";
import { useNavigate } from "react-router-dom";

const navItems = [
  "Select Parent Model",
  "Define Audience",
  "Select Destination",
  "Configure Sync",
  "Finalize Sync",
];

const SegmentNavbar = () => {
  const activeSegment = useRecoilValue(activeSegmentAtom);

  const navigate = useNavigate()

  return (
    <div className="flex justify-between items-center border-b pb-4 bg-white w-[102%] -ml-4 z-50 px-4 pt-1 sticky top-[4.8rem]">
      <p className="body_Regular">New Audience</p>
      <div className="flex justify-center items-center gap-4">
        {navItems.map((nav, index) => (
          <React.Fragment key={index}>
            <div className="flex justify-center items-center gap-2">
              <CheckOutlined
                className={`rounded-full border border-primary ${
                  activeSegment > index
                    ? "bg-light-primary text-primary"
                    : activeSegment === index
                    ? "bg-primary text-primary"
                    : "bg-white text-white"
                } px-1 py-1 text-[8px]`}
              />
              <p className="text-primary text-center text-[13px]">
                {nav}
              </p>
            </div>
            {index !== navItems?.length-1 && <hr className="w-[15px] border-primary" />}
          </React.Fragment>
        ))}
      </div>
      <AgentCustomBtn otherClasses={"!px-4 !py-1"} onClick={() => navigate("/audiences")}>Exit</AgentCustomBtn>
    </div>
  );
};

export default SegmentNavbar;
