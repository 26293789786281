import React, { useState, useEffect } from "react";
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button } from "antd";

const GumSuccess = ({ setModal }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="mt-2 flex flex-col items-center justify-center">
      <div className="flex justify-center mb-4">
        {loading ? (
          <LoadingOutlined style={{ fontSize: '150px', color: '#73D297', animation: 'spin 1s infinite linear' }} />
        ) : (
          <CheckCircleOutlined style={{ fontSize: '150px', color: '#73D297', animation: 'fadeIn 1s forwards' }} />
        )}
      </div>
      <div className="mb-8 text-xl text-[#2966BC] flex flex-col gap-2 text-center" style={{fontWeight: 600}}>
        <p>Deployed.</p>
        <p>To see your deployed workflows & their performance, please navigate to Live in the Main Menu.</p>
      </div>
      <Button className="rounded hover:bg-blue-700" onClick={() => setModal(false)}>
        Done
      </Button>
      <style jsx>{`
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default GumSuccess;
