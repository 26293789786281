import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeAudienceAtom } from "../../store";
import AudienceComponent from "../../components/AudienceComponents/AudienceComponents";

const Audiences = () => {
  const [activeAudienceComp, setActiveAudienceComp] = useRecoilState(activeAudienceAtom);

  useEffect(() => {
    setActiveAudienceComp(0);
  }, []);

  return (
    <div>
        <AudienceComponent />
    </div>
  );
};

export default Audiences;
