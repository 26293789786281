import React from 'react'
import { CaretDownOutlined } from "@ant-design/icons"

const LeadCustomButton = ({ id, text, icon, onClick, testClicked }) => {
  return (
    <div className={`flex justify-center items-center gap-1 border rounded-lg border-2 px-2 cursor-pointer hover:bg-[#8EA9D0] hover:scale-105 duration-300 ease-in-out ${testClicked && id === 1 && 'bg-[#8EA9D0] scale-105'}`} onClick={id === 1 ? onClick : null}>
        <img src={icon} alt={`${text}_img`} width={text === "Test" ? 22 : 18} />
        <p className='text-base'>{text}</p>
        {text === "Save" && <CaretDownOutlined />}
    </div>
  )
}

export default LeadCustomButton