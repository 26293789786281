import React from "react";
import { dropAtom, activeTabAtom } from "../../../store/atoms/AnalyticsAtoms";
import { useRecoilValue } from "recoil";

const ConversionUpperRate = ({ rate, type, index }) => {
  const drop = useRecoilValue(dropAtom);
  const activeTab = useRecoilValue(activeTabAtom);
  const renderDays = () => {
    if (activeTab === 4 && (drop === "Month" || drop === "Quarter")) {
      if (index === 2) {
        return "9 days"
      } else {
        return "15 days"
      }
    } else {
      if (index === 2) {
        return "90 days"
      } else {
        return "150 days"
      }
    }
  }
  return (
<>
<div
      style={{
        width: "60px",
        height: "20px",
        backgroundColor: "rgba(209, 213, 219, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "10px",
        position: "absolute",
        top: (type === "rate" || (type === "▵t" && index !== 2)) ? "-5rem" : "-7rem",
        left: (index === 2 || index === 5) ? "190%" : index === 4 ? "120%" : "125%",
        transform: "translateX(-50%)",
      }}
    >
      {`${rate}${type === "rate" ? "%" : ""}`}
    </div>
    <div
      style={{
        width: "60px",
        height: "20px",
        backgroundColor: "rgba(209, 213, 219, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "20px",
        position: "absolute",
        top: (type === "rate" || (type === "▵t" && index !== 2)) ? "-4.6rem" : "-7rem",
        left: (index === 2 || index === 5) ? "190%" : index === 4 ? "120%" : "125%",
        transform: "translateX(-50%) translateY(120%)",
      }}
    >
      {renderDays()}
    </div>
</>
  );
};

export default ConversionUpperRate;
