import { useRecoilState } from "recoil";
import { Client } from "../../services/Client";
import { authUserAtom } from "../../store/atoms/AuthUserAtoms";
import { useEffect, useState } from "react";
import { Button, Spin } from "antd";

const OnboardingV2 = () => {
  const [authUser] = useRecoilState(authUserAtom);
  const [loading, setLoading] = useState(true);
  const [userRequestExist, setUserRequestExist] = useState(null);
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

  const fetchPendingRequest = async () => {
    try {
      const { data } = await Client.get("/onboarding/access");
      setUserRequestExist(data?.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleCreateAppRequest = async () => {
    try {
      await Client.post("/onboarding/access");
      setIsRequestSubmitted(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchPendingRequest();
    }
  }, [authUser]);

  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  if (userRequestExist) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <h2>
          Our team is working on your onboarding request. Will update you soon
        </h2>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      {isRequestSubmitted ? (
        <h2>Request Access is Submitted</h2>
      ) : (
        <Button onClick={handleCreateAppRequest}>Request App Access</Button>
      )}
    </div>
  );
};

export default OnboardingV2;
