import React, { useState } from 'react';
import add from '../../../assets/images/user-add.svg';
import { message, Table, Select } from 'antd';
import './InviteTab.css';
import { InviteUser } from '../../../../src/services/Settings';
import { jwtDecode } from 'jwt-decode';
import CreatableSelect from 'react-select/creatable';

const { Option } = Select;

const InviteTab = () => {
  const [EmailList, setEmailList] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label) => ({
    label,
    value: label
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        event.preventDefault();
        if (/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm.test(inputValue) && emailDomainValidation(inputValue) && duplicateEmail(inputValue)) {
          setEmailList((prev) => [...prev, createOption(inputValue)]);
          setInputValue('');
        }
    }
  };

  const emailDomainValidation = (email) => {
    if (email.split('@')[1].includes(jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain) == false) {
      message.error("Invite user in the same domain only");
    }
    return email.split('@')[1].includes(jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain);
  };

  const duplicateEmail = (email) => {
    if (EmailList != null && EmailList != undefined && EmailList.length > 0) {
      let isEmailPresent = true;
      EmailList.map((mail) => {
        if (mail.value == email) {
          isEmailPresent = false;
          setInputValue('');
        }
      })
      return isEmailPresent;
    } else {
      return true;
    }
  };

  const validate = (e) => {
    e.preventDefault();
    let mailList = EmailList.map(mail => mail.value).toString();
    UserInvite(mailList);
  }

  const UserInvite = async (mailList) => {
    let response = await InviteUser({
      mailList: mailList,
      usermail: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
      userName: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.name.replace('|', ' '),
      workspace: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain,
      inviteURL: window.location.origin,
    })
    if (response?.data?.status == 'success') {
      console.log(response.data);
      message.success(response.data.data);
    } else {
      message.error(response.data.data);
    }
    setEmailList('');
    setInputValue('');
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text, record) => (
        <Select defaultValue={text} style={{ width: 120 }}>
          <Option value="Admin">Admin</Option>
          <Option value="Editor">Editor</Option>
          <Option value="Viewer">Viewer</Option>
          {/* Add more roles as needed */}
        </Select>
      ),
    },
    {
      title: 'Authentication Method',
      dataIndex: 'authentication',
      key: 'authentication',
    },
    {
      title: 'Member Since',
      dataIndex: 'memberSince',
      key: 'memberSince',
    },
  ];

  const data = [
    {
      key: '1',
      name: 'Abhi',
      email: 'abhi@icustomer.ai',
      role: 'Admin',
      authentication: 'Google OAuth',
      memberSince: '2024-08-10',
    },
    // Add more sample data as needed
  ];

  return (
    <div className='invite-container'>
      <label htmlFor="emailInput" className='invite-label flex'><img src={add} alt='add' style={{ paddingBottom: '5px', marginRight: '5px' }} />Invite Members</label>
      <div style={{ marginTop: '15px' }}>
        <CreatableSelect
          className="creatableSelect"
          components={components}
          inputValue={inputValue}
          validate
          isClearable
          isMulti
          menuIsOpen={false}
          onChange={(newValue) => { setEmailList(newValue) }}
          onInputChange={(newValue) => {
            setInputValue(newValue)
          }}
          onKeyDown={handleKeyDown}
          placeholder="Add emails separated by commas"
          value={EmailList}
        />
      </div>
      <div className='invite-btn'>
        <button type="button" onClick={validate} disabled={EmailList.length === 0}>
          Invite
        </button>
      </div>
      <Table columns={columns} dataSource={data} style={{ marginTop: '20px' }} />
    </div>
  );
};

export default InviteTab;
