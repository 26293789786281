import React, { useState } from "react";
import { Input, Card, Modal } from "antd";
import {
  RiseOutlined,
  DownOutlined,
  BarChartOutlined,
  MailOutlined,
  ShareAltOutlined,
  SearchOutlined,
  FileTextOutlined,
  LineChartOutlined,
  UserAddOutlined,
  CloudSyncOutlined,
  RobotOutlined,
  ExperimentOutlined,
  ApiOutlined,
  CalendarOutlined,
  FileSyncOutlined,
  WechatOutlined,
  DownloadOutlined,
  TeamOutlined,
  DollarOutlined,
  ProfileOutlined,
  StarOutlined,
  ContactsOutlined,
  SolutionOutlined,
  CommentOutlined,
  PieChartOutlined,
  HeartOutlined,
  CustomerServiceOutlined,
  UserSwitchOutlined,
  FormOutlined,
  TrophyOutlined,
  SendOutlined,
  AreaChartOutlined,
  FundProjectionScreenOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  MessageOutlined,
  DatabaseOutlined,
  UsergroupAddOutlined,
  SafetyOutlined,
  BuildOutlined,
  InteractionOutlined,
  NotificationOutlined,
  AppstoreOutlined,
  CopyOutlined,
  FileOutlined,
  EditOutlined,
  RadarChartOutlined,
  ProjectOutlined,
  RocketOutlined,
  AlertOutlined,
  SyncOutlined,
  BookOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import "./NewAgent.css";
import gumloop from "../../assets/images/gumloop.png";
import { useNavigate } from "react-router-dom";


const { Search } = Input;

const CategoryButton = ({ children, isActive, onClick }) => (
  <button
    className={`px-4 py-1 rounded-md font-medium ${
      isActive ? "bg-[#2966BC] text-white" : "bg-[#E9EDF1] text-gray-700"
    } hover:bg-[#2966BC] hover:text-white transition-colors duration-300`}
    onClick={onClick}
  >
    {children}
  </button>
);

const WorkflowCard = ({ title, icon: Icon }) => {
  const [showPopUp, setShowPopup] = useState(false)
  const navigate = useNavigate()
  const handleGumloop = (title) => {
    if (title === "Lead Background Research") {
      navigate("/templates/sales_crm")
    } else if (title === "Enrichment Waterfall") {
      navigate("/templates/enrichment")
    } else if (title === "Lead Enrichment") {
      navigate("/templates/lead")
    } else {
      navigate("/templates/prog")
    }
  }
  return (
  <Card className={`${["Enrichment Waterfall", "Lead Background Research", "Lead Enrichment", "Programmatic Leads"].includes(title) && 'cursor-pointer'} bg-white shadow-md hover:shadow-lg transition-shadow duration-300 h-full`} onClick={title == "Customer Social Media Sentiment Ananlysis" ? () => setShowPopup(!showPopUp) : ["Enrichment Waterfall", "Lead Background Research", "Lead Enrichment", "Programmatic Leads"].includes(title) ? () =>  handleGumloop(title) : () => console.log("clicked")}>
    <div className="flex flex-col items-center text-[#8EA9D0] hover:text-[#73D297] justify-center p-4 h-full">
      <Icon className="w-12 h-20 mb-2 text-5xl" />
      <h3 className="text-gray-800 text-lg font-semibold text-center">
        {title}
      </h3>
    </div>
  </Card>
)};

const NewAgent = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState("All");
  const [showPopUp, setShowPopup] = useState(false)

  const categories = [
    "All",
    "RevOps",
    "Marketing",
    "Tools Sync",
    "Web Scrape",
    "Customer Success",
    "Insights",
    "Community",
    "Custom",
    "Request a Template",
    "Acquisition",
    "Retention",
    "Expansion"
  ];
  const categories2 = [
    "Acquisition",
    "Retention",
    "Expansion"
  ]

  const workflows = {
    Marketing: [
      { title: "Lead Background Research", icon: ProfileOutlined },
      { title: "Enrichment Waterfall", icon: FundProjectionScreenOutlined },
      { title: "Campaign Management", icon: BarChartOutlined },
      { title: "Customer Social Media Sentiment Ananlysis", icon: ShareAltOutlined },
      { title: "SEO Optimization", icon: SearchOutlined },
      { title: "Content Marketing", icon: FileTextOutlined },
      { title: "Marketing Analytics", icon: LineChartOutlined },
      { title: "Lead Generation", icon: UserAddOutlined },
      { title: "A/B Testing", icon: ExperimentOutlined },
      { title: "Customer Journey Optimizer", icon: RiseOutlined },
      { title: "Competitive Intelligence Tracker", icon: SearchOutlined },
    ],
    RevOps: [
      { title: "Sales Forecasting", icon: LineChartOutlined },
      { title: "Sales Lead Scoring", icon: StarOutlined },
      { title: "Sales Lead Enrich & Route", icon: SendOutlined },
      { title: "New Lead Discovery", icon: UserAddOutlined },
      { title: "Champion Tracking", icon: TrophyOutlined },
      { title: "Opportunity Contact Roles", icon: ContactsOutlined },
    ],
    "Tools Sync": [
      { title: "CRM Integration", icon: CloudSyncOutlined },
      { title: "Marketing Automation Sync", icon: RobotOutlined },
      { title: "Data Synchronization", icon: DatabaseOutlined },
      { title: "API Connections", icon: ApiOutlined },
      { title: "Calendar Sync", icon: CalendarOutlined },
      { title: "File Storage Integration", icon: FileSyncOutlined },
      { title: "Communication Tools Integration", icon: WechatOutlined },
    ],
    "Web Scrape": [
      { title: "Data Extraction", icon: DownloadOutlined },
      { title: "Competitor Analysis", icon: TeamOutlined },
      { title: "Price Monitoring", icon: DollarOutlined },
      { title: "Content Aggregation", icon: FileTextOutlined },
      { title: "Market Research", icon: SearchOutlined },
      { title: "Job Listing Scraping", icon: ProfileOutlined },
      { title: "Review Aggregation", icon: StarOutlined },
      { title: "Contact Information Harvesting", icon: ContactsOutlined },
    ],
    "Customer Success": [
      { title: "Onboarding Management", icon: SolutionOutlined },
      { title: "Customer Feedback", icon: CommentOutlined },
      { title: "Churn Analysis", icon: LineChartOutlined },
      { title: "Usage Tracking", icon: PieChartOutlined },
      { title: "Customer Health Scoring", icon: HeartOutlined },
      { title: "Support Ticketing", icon: CustomerServiceOutlined },
      { title: "Account Management", icon: UserSwitchOutlined },
      { title: "NPS Surveys", icon: FormOutlined },
    ],
    Insights: [
      { title: "Data Visualization", icon: AreaChartOutlined },
      { title: "Business Intelligence", icon: FundProjectionScreenOutlined },
      { title: "Performance Metrics", icon: DashboardOutlined },
      { title: "Predictive Analytics", icon: BarChartOutlined },
      { title: "Customer Insights", icon: ProfileOutlined },
      { title: "Sales Insights", icon: LineChartOutlined },
      { title: "Market Trends", icon: BarChartOutlined },
      { title: "Dashboard Reporting", icon: FileSearchOutlined },
    ],
    Community: [
      { title: "Forum Management", icon: TeamOutlined },
      { title: "Social Media Engagement", icon: MessageOutlined },
      { title: "Event Management", icon: CalendarOutlined },
      { title: "User Groups", icon: UsergroupAddOutlined },
      { title: "Community Analytics", icon: LineChartOutlined },
      { title: "Content Moderation", icon: SafetyOutlined },
      { title: "Member Onboarding", icon: SolutionOutlined },
      { title: "Community Feedback", icon: CommentOutlined },
    ],
    Custom: [
      { title: "Custom Reporting", icon: FileOutlined },
      { title: "Custom Workflows", icon: BuildOutlined },
      { title: "Personalized Dashboards", icon: DashboardOutlined },
      { title: "Tailored Solutions", icon: BuildOutlined },
      { title: "API Customization", icon: ApiOutlined },
      { title: "Bespoke Integrations", icon: InteractionOutlined },
      { title: "User-Specific Automations", icon: RobotOutlined },
      { title: "Custom Notifications", icon: NotificationOutlined },
    ],
    "Request a Template": [
      { title: "Template Request Form", icon: FormOutlined },
      { title: "Custom Template Design", icon: EditOutlined },
      { title: "Template Gallery", icon: AppstoreOutlined },
      { title: "Use Case Templates", icon: FileSearchOutlined },
      { title: "Industry-Specific Templates", icon: SolutionOutlined },
      { title: "Template Customization Services", icon: BuildOutlined },
      { title: "Template Feedback", icon: CommentOutlined },
      { title: "Pre-built Templates", icon: CopyOutlined },
    ],
    Acquisition: [
      { title: "Predictive Lead Scoring", icon: RadarChartOutlined },
      { title: "Account-Based Marketing Orchestrator", icon: TeamOutlined },
      { title: "Intent-Based Outreach", icon: MailOutlined },
    ],
    Expansion: [
      { title: "Cross-Sell Opportunity Identifier", icon: ProjectOutlined },
      { title: "Customer Health Scoring", icon: HeartOutlined },
      { title: "Upsell Campaign Automator", icon: RocketOutlined },
    ],
    Retention: [
      { title: "Churn Prediction & Prevention", icon: AlertOutlined },
      { title: "Customer Feedback Loop", icon: MessageOutlined },
      { title: "Engagement Revitalization", icon: SyncOutlined },
    ],
  };

  const allWorkflows = {
    Marketing: [
      { title: "Lead Background Research", icon: ProfileOutlined },
      { title: "Enrichment Waterfall", icon: FundProjectionScreenOutlined },
      { title: "Lead Enrichment", icon: ClusterOutlined },
      { title: "Programmatic Leads", icon: UsergroupAddOutlined },
      // { title: "Campaign Management", icon: BarChartOutlined },
      // { title: "Customer Social Media Sentiment Ananlysis", icon: ShareAltOutlined },
      // { title: "SEO Optimization", icon: SearchOutlined },
      // { title: "Content Marketing", icon: FileTextOutlined },
      // { title: "Marketing Analytics", icon: LineChartOutlined },
      // { title: "Lead Generation", icon: UserAddOutlined },
      // { title: "A/B Testing", icon: ExperimentOutlined },
      { title: "Customer Journey Optimizer", icon: RiseOutlined },
      { title: "Competitive Intelligence Tracker", icon: SearchOutlined },
    ],
    Acquisition: [
      { title: "Predictive Lead Scoring", icon: RadarChartOutlined },
      { title: "Account-Based Marketing Orchestrator", icon: TeamOutlined },
      { title: "Intent-Based Outreach", icon: MailOutlined },
    ],
    Expansion: [
      { title: "Cross-Sell Opportunity Identifier", icon: ProjectOutlined },
      { title: "Customer Health Scoring", icon: HeartOutlined },
      { title: "Upsell Campaign Automator", icon: RocketOutlined },
    ],
    Retention: [
      { title: "Churn Prediction & Prevention", icon: AlertOutlined },
      { title: "Customer Feedback Loop", icon: MessageOutlined },
      { title: "Engagement Revitalization", icon: SyncOutlined },
    ],
    RevOps: [
      { title: "Sales Forecasting", icon: LineChartOutlined },
      { title: "Sales Lead Scoring", icon: StarOutlined },
      { title: "Sales Lead Enrich & Route", icon: SendOutlined },
      { title: "New Lead Discovery", icon: UserAddOutlined },
      { title: "Champion Tracking", icon: TrophyOutlined },
      { title: "Opportunity Contact Roles", icon: ContactsOutlined },
    ],
    "Tools Sync": [
      { title: "CRM Integration", icon: CloudSyncOutlined },
      { title: "Marketing Automation Sync", icon: RobotOutlined },
      { title: "Data Synchronization", icon: DatabaseOutlined },
      { title: "API Connections", icon: ApiOutlined },
      { title: "Calendar Sync", icon: CalendarOutlined },
      { title: "File Storage Integration", icon: FileSyncOutlined },
      { title: "Communication Tools Integration", icon: WechatOutlined },
    ],
    "Web Scrape": [
      { title: "Data Extraction", icon: DownloadOutlined },
      { title: "Competitor Analysis", icon: TeamOutlined },
      { title: "Price Monitoring", icon: DollarOutlined },
      { title: "Content Aggregation", icon: FileTextOutlined },
      { title: "Market Research", icon: SearchOutlined },
      { title: "Job Listing Scraping", icon: ProfileOutlined },
      { title: "Review Aggregation", icon: StarOutlined },
      { title: "Contact Information Harvesting", icon: ContactsOutlined },
    ],
    "Customer Success": [
      { title: "Onboarding Management", icon: SolutionOutlined },
      { title: "Customer Feedback", icon: CommentOutlined },
      { title: "Churn Analysis", icon: LineChartOutlined },
      { title: "Usage Tracking", icon: PieChartOutlined },
      { title: "Customer Health Scoring", icon: HeartOutlined },
      { title: "Support Ticketing", icon: CustomerServiceOutlined },
      { title: "Account Management", icon: UserSwitchOutlined },
      { title: "NPS Surveys", icon: FormOutlined },
    ],
    Insights: [
      { title: "Data Visualization", icon: AreaChartOutlined },
      { title: "Business Intelligence", icon: FundProjectionScreenOutlined },
      { title: "Performance Metrics", icon: DashboardOutlined },
      { title: "Predictive Analytics", icon: BarChartOutlined },
      { title: "Customer Insights", icon: ProfileOutlined },
      { title: "Sales Insights", icon: LineChartOutlined },
      { title: "Market Trends", icon: BarChartOutlined },
      { title: "Dashboard Reporting", icon: FileSearchOutlined },
    ],
    Community: [
      { title: "Forum Management", icon: TeamOutlined },
      { title: "Social Media Engagement", icon: MessageOutlined },
      { title: "Event Management", icon: CalendarOutlined },
      { title: "User Groups", icon: UsergroupAddOutlined },
      { title: "Community Analytics", icon: LineChartOutlined },
      { title: "Content Moderation", icon: SafetyOutlined },
      { title: "Member Onboarding", icon: SolutionOutlined },
      { title: "Community Feedback", icon: CommentOutlined },
    ],
    Custom: [
      { title: "Custom Reporting", icon: FileOutlined },
      { title: "Custom Workflows", icon: BuildOutlined },
      { title: "Personalized Dashboards", icon: DashboardOutlined },
      { title: "Tailored Solutions", icon: BuildOutlined },
      { title: "API Customization", icon: ApiOutlined },
      { title: "Bespoke Integrations", icon: InteractionOutlined },
      { title: "User-Specific Automations", icon: RobotOutlined },
      { title: "Custom Notifications", icon: NotificationOutlined },
    ],
    "Request a Template": [
      { title: "Template Request Form", icon: FormOutlined },
      { title: "Custom Template Design", icon: EditOutlined },
      { title: "Template Gallery", icon: AppstoreOutlined },
      { title: "Use Case Templates", icon: FileSearchOutlined },
      { title: "Industry-Specific Templates", icon: SolutionOutlined },
      { title: "Template Customization Services", icon: BuildOutlined },
      { title: "Template Feedback", icon: CommentOutlined },
      { title: "Pre-built Templates", icon: CopyOutlined },
    ],
  };

  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const handleCloseModal = () => {
    setShowPopup(false);
    // setOptionSelected("")
  };
  const navigate = useNavigate();
  return (
    <div className="bg-gray-100 m-2 min-h-[92vh] rounded-md mt-4 mr-4">
      <div className="relative container mx-auto px-5 py-8">
        <p className="absolute top-4 left-[2%] text-[18px] text-[grey]">Achieve goals and outcome via powerful agentic automation with guardrails</p>
        <Search
          placeholder="Search templates (e.g. Find work email)"
          onSearch={onSearch}
          className="extendSearch mb-6 mt-10"
          allowClear
        />
        <div className="mb-6 flex flex-wrap items-center gap-2">
          <CategoryButton
            isActive={activeCategory === "All"}
            onClick={() => {
              setActiveCategory("All");
              setIsDropdownOpen(false);
            }}
          >
            All
          </CategoryButton>
          <button
            className="px-4 py-1 bg-[#E9EDF1] text-gray-700 rounded-md font-medium hover:bg-gray-300 transition-colors duration-300 flex items-center"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {activeCategory === "All" ? "Use cases" : activeCategory}{" "}
            <DownOutlined className="ml-1" />
          </button>
          {isDropdownOpen && (
            <div>
            <div className="w-[65%] justify-start mt-2 flex flex-wrap gap-2">
              {categories.slice(1).map((category) => (
                <CategoryButton
                  key={category}
                  isActive={activeCategory === category}
                  onClick={() => setActiveCategory(category)}
                >
                  {category}
                </CategoryButton>
              ))}
            </div>
            {/* <div className="w-full justify-center mt-2 flex flex-wrap gap-2">
              {categories2.map((category) => (
                <CategoryButton
                  key={category}
                  isActive={activeCategory === category}
                  onClick={() => setActiveCategory(category)}
                >
                  {category}
                </CategoryButton>
              ))}
            </div> */}
            </div>

          )}
        </div>
        <div className="grid grid-cols-3 gap-6">
          {activeCategory === "All"
            ? Object.keys(allWorkflows).map((key) =>
                allWorkflows[key]?.map((workflow, index) => (
                  <div key={index} className="h-48">
                    <WorkflowCard title={workflow.title} icon={workflow.icon} />
                  </div>
                ))
              )
            : workflows[activeCategory]?.map((workflow, index) => (
                <div key={index} className="h-48">
                  <WorkflowCard title={workflow.title} icon={workflow.icon} onClick={()=> setShowPopup(true)} />
                </div>
              ))}
          {}
        </div>
      </div>
    </div>
  );
};

export default NewAgent;
