import React from 'react';
import { useRecoilValue } from 'recoil';
import { copilotChatArrayAtom } from '../../../store/index.js';
import AssistantQuestion from './AssistantQuestion/AssistantQuestion.js';
import AssistantAnswer from './AssistantAnswer/AssistantAnswer.js';

const AssistantChatWindow = () => {
    const chatArray = useRecoilValue(copilotChatArrayAtom);

    return (
        <div className='flex flex-col w-full pt-2 overflow-y-auto'>
            {chatArray.map((chat, index) => (
                <div key={index} className='flex flex-col'>
                    {chat?.question && <AssistantQuestion chat={chat} />}
                    <AssistantAnswer
                        chat={chat}
                        isLast={index === chatArray.length - 1}
                        index={index}
                    />
                </div>
            ))}
        </div>
    );
};

export default AssistantChatWindow;
