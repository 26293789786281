import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import pageImg from "../../assets/images/progTemplate.png";
import searchImg from "../../assets/images/searchImg.png";
import rightImg from "../../assets/images/rightImg.png";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import aiImg from "../../assets/images/AI.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import AgentHeader from "../../components/AgentTemplateComponents/AgentHeader";
import AgentHow from "../../components/AgentTemplateComponents/AgentHow";
import AgentExplore from "../../components/AgentTemplateComponents/AgentExplore";
import AgentTopBar from "../../components/AgentTopBar/AgentTopBar";
import { agentValAtom } from "../../store";
import { useRecoilValue } from "recoil";
import { jwtDecode } from "jwt-decode";
// import { createSession } from "../../services/waterfallServices.js";

const CommonTemplate = () => {
  const navigate = useNavigate();
  const agentVal = useRecoilValue(agentValAtom);
  return (
    <div className='px-11'>
    <AgentTopBar />
    <div className='bg-[#F7F8FA] flex flex-col gap-4 justify-center p-4'>
          <p className='bode_Regular text-secondary'>
                Agent / <span className='cursor-pointer' onClick={() => navigate('/agents')}>Templates</span> / <span className='text-character'>{agentVal?.name}</span>
            </p>
        <div className='flex flex-col gap-8 justify-center bg-white'>
        <AgentHeader />
        <AgentHow />
        <AgentExplore />
    </div>
    </div>
</div>
  );
};

export default CommonTemplate;