import React, { useCallback, useState, useEffect } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
  ReactFlowProvider
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import LeadIONode from "../../components/LeadGumloopComponents/LeadIONode.js";
import LeadTextNode from "../../components/LeadGumloopComponents/LeadTextNode.js";
import LeadCompanyNode from "../../components/LeadGumloopComponents/LeadCompanyNode.js";
import LeadSummarizerNode from "../../components/LeadGumloopComponents/LeadSummarizerNode.js";

import LeadMainHeader from "../../components/LeadGumloopComponents/LeadMainHeader.js";
import SideOpt from "../../components/GumloopComponents/SideOpt.js";

import inputImg from "../../assets/images/inputImg.png";
import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";
import personImg from "../../assets/images/personImg.png";
import calcImg from "../../assets/images/calcImg.png";
import parseImg from "../../assets/images/parseImg.png";
import robotImg from "../../assets/images/robotImg.png";
import sheetImg from '../../assets/images/sheets copy.png'
import jsonImg from '../../assets/images/json.png'
import settingImg from '../../assets/images/settings.png'


import LeadCustomEdge from '../../components/LeadGumloopComponents/LeadCustomEdge.js';

import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms.js";
import { leadFocusNodeIdAtom, leadNewNodeAtom, leadContactAtom, leadSlackAtom, leadFitEntireAtom, leadCollapsibleAtom, leadTestLoadingAtom, leadPolledContentAtom, leadTestEmailAtom, collapsibleAtom } from "../../store/index.js";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";

import LeadRightSider from "../../components/LeadGumloopComponents/LeadRightSider.js";
import { DoubleRightOutlined, DoubleLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { message, Modal, Spin } from "antd";
import LeadOutputComponent from "../../components/LeadGumloopComponents/LeadOutputComponent.js";
import LeadJsonToCsv from "../../components/LeadGumloopComponents/LeadJsonToCsv.js";

const initialNodes = [
  {
    id: "node-1",
    type: "summarizerNode",
    position: {x: 7.148331091731507, y: 386.68003499334736},
    data: {
      label: "Webhook Listener",
      icon: hubspotImg,
      firstKey: "Default Value:",
      firstVal: "max@hubspot.com",
      bgColor: "grey",
    },
  },
  {
    id: "node-4",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: 
    {x: 112.90496637597141, y: 1017.4214268152639},
    data: {
      label: "ReadJsonValues",
      icon: jsonImg,
      bgColor: "peach",
      firstKey: "Key",
      firstVal: "results.response.email"
    },
  },
  {
    id: "node-16",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: {x: 101.63836762376366, y: 1340.5150662037656},
    data: {
      label: "Ograph Person Verification API",
      icon: companyImg,
      bgColor: "blue",
      firstKey: "Email to Verify",
      firstVal: "jenish@icustomer.ai"
    },
  },
  {
    id: "node-15",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 40.70208949773826, y: 1691.7641476536464},
    data: {
      label: "ReadJsonValues",
      icon: parseImg,
      bgColor: "peach",
      firstKey: "Key",
      firstVal: "results.response"
    },
  },
  {
    id: "node-18",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: {x: 40.70208949773826, y: 1691.7641476536464},
    data: {
      label: "ReadJsonValues",
      icon: jsonImg,
      bgColor: "peach",
      firstKey: "Key",
      firstVal: "results.response"
    },
  },
  {
    id: "node-19",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 108.61123486918689, y: 2047.8338696544147},
    data: {
      label: "Decision Gate",
      icon: settingImg,
      bgColor: "blue",
      firstKey: "True False",
      firstVal: "results.response"
    },
  },
  {
    id: "node-3",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: -454.47341602269057, y: 2735.578287797923},
    data: {
      label: "Google Sheets Writer",
      icon: sheetImg,
      // firstKey: "Output Name:",
      firstVal: "research_results",
      bgColor: "grey",
    },
  },
  {
    id: "node-13",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 553.2144882513198, y: 2718.1778886951947},
    data: {
      label: "oGraph Person Enrich API",
      icon: personImg,
      bgColor: "peach",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },
  {
    id: "node-20",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 241.5848504258165, y: 3216.729080546343},
    data: {
      label: "Decision Gate",
      icon: settingImg,
      bgColor: "blue",
      firstKey: "True False",
      firstVal: "results.response"
    },
  },
  {
    id: "node-21",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 650.8878681010174, y: 3904.9923510213057},
    data: {
      label: "oGraph Person Enrich APII",
      icon: personImg,
      bgColor: "peach",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },
  {
    id: "node-22",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 1173.6492814666688, y: 3901.485101371988},
    data: {
      label: "oGraph Account Enrich API",
      icon: companyImg,
      bgColor: "peach",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },
  {
    id: "node-23",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 262.29177445275013, y: 4511.368153640071},
    data: {
      label: "iCustomer Person Push API",
      icon: companyImg,
      bgColor: "peach",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },
  {
    id: "node-24",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: 1154.0876585315489, y: 4502.487953798763},
    data: {
      label: "iCustomer Account Push API",
      icon: companyImg,
      bgColor: "peach",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },

  {
    id: "node-25",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: {x: -239.3644557178502, y: 3913.591241092447},
    data: {
      label: "Hubspot Contact Updater",
      icon: hubspotImg,
      bgColor: "peach",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },

  {
    id: "sticknode-1",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: -443.6051620208352, y: 603.5027503269882},
    data: {
      label: "Step 1",
      text: "Receive webhook from hubspot form fill"
    },
  },
  {
    id: "sticknode-2",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: -461.0218746892763, y: 1025.916702888959},
    data: {
      label: "Step 2",
      text: "Parse webhook JSON and grab email from formfill"
    },
  },

  {
    id: "sticknode-3",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: -461.0218746892763, y: 1347.791702888959},
    data: {
      label: "Step 3",
      text: "Validate this is a real person using iCustomer's Open Graph aka oGraph"
    },
  },

  {
    id: "sticknode-4",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: -444.6892773843351, y: 1723.0849336159588},
    data: {
      label: "Step 4",
      text: "Parse JSON response from iCustomer oGraph"
    },
  },

  {
    id: "sticknode-5",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: -346.0218746892763, y: 2204.6667028889588},
    data: {
      label: "Step 5",
      text: "Decision gate: if real person true else false"
    },
  },

  {
    id: "sticknode-6",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: -956.0218746892763, y: 2820.9167028889588},
    data: {
      label: "Step 6",
      text: "If false append lead to google sheet bad apples"
    },
  },

  {
    id: "sticknode-7",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: 933.9781253107235, y: 2755.9167028889588},
    data: {
      label: "Step 7",
      text: "If True check oGraph to see if account and persona are ICP"
    },
  },

  {
    id: "sticknode-8",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: -306.0218746892763, y: 3300.9167028889588},
    data: {
      label: "Step 8",
      text: "Decision gate 2 if  account is ICP then True if not then false"
    },
  },

  {
    id: "sticknode-9",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: 
    {x: -641.0218746892763, y: 3935.9167028889588},
    data: {
      label: "Step 9",
      text: "If false send lead to nurture campaign"
    },
  },

  {
    id: "sticknode-10",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: 361.1676799532779, y: 3975.6121108129046},
    data: {
      label: "Step 10",
      text: "If true enrich person data"
    },
  },

  {
    id: "sticknode-11",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: 1627.517594833249, y: 3988.6969260548085},
    data: {
      label: "Step 11",
      text: "Use good leads to keep account data fresh"
    },
  },

  {
    id: "sticknode-12",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: {x: 1861.032456421377, y: 4696.2065864274555},
    data: {
      label: "Step 12",
      text: "Push enriched lead and account data to salesforce"
    },
  },

  

];

// const initialEdges = [
//   {
//     id: "edge-1",
//     source: "node-1",
//     target: "node-4",
//     sourceHandle: "b",
//     animated: true,
//     data: {
//       // startLabel: { mainText: 'email', suppText: 'type = text' },
//       endLabel: { mainText: 'email', suppText: 'type = text' }
//     },
//     type: 'start-end',
//   },
//   {
//     id: "edge-2",
//     source: "node-4",
//     target: "node-5",
//     sourceHandle: "b",
//     animated: true,
//     data: {
//       // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
//       endLabel: { mainText: 'Free Domain' },
//     },
//     type: 'start-end',
//   },
//   {
//     id: "edge-3",
//     source: "node-4",
//     target: "node-16",
//     sourceHandle: "b",
//     animated: true,
//     data: {
//       // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
//       endLabel: { mainText: 'Business Domain' },
//     },
//     type: 'start-end',
//   },
//   // {
//   //   id: "edge-4",
//   //   source: "node-4",
//   //   target: "node-13",
//   //   sourceHandle: "b",
//   //   animated: true,
//   //   data: {
//   //     // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
//   //     endLabel: { mainText: 'person', suppText: 'type = text' },
//   //   },
//   //   type: 'start-end',
//   // },
//   {
//     id: "edge-5",
//     source: "node-5",
//     target: "node-14",
//     sourceHandle: "b",
//     animated: true,
//     data: {
//       startLabel: { mainText: 'scraped data', suppText: 'type = text' },
//       // endLabel: { mainText: 'url', suppText: 'type = text' },
//     },
//     type: 'start-end',
//   },
//   {
//     id: "edge-6",
//     source: "node-5",
//     target: "node-15",
//     sourceHandle: "b",
//     animated: true,
//     data: {
//       startLabel: { mainText: 'scraped data', suppText: 'type = text' },
//       // endLabel: { mainText: 'url', suppText: 'type = text' },
//     },
//     type: 'start-end',
//   },
//   {
//     id: "edge-7",
//     source: "node-15",
//     target: "node-17",
//     sourceHandle: "d",
//     animated: true,
//     data: {
//       startLabel: { mainText: 'verified content', suppText: 'type = text' },
//       // endLabel: { mainText: 'domain name', suppText: 'type = text' },
//     },
//     type: 'start-end',
//   },
//   {
//     id: "edge-8",
//     source: "node-16",
//     target: "node-17",
//     sourceHandle: "d",
//     animated: true,
//     data: {
//       // startLabel: { mainText: 'verified content', suppText: 'type = text' },
//       endLabel: { mainText: 'Invalid Email' },
//     },
//     type: 'start-end',
//   },
//   // {
//   //   id: "edge-9",
//   //   source: "node-13",
//   //   target: "node-18",
//   //   sourceHandle: "d",
//   //   animated: true,
//   //   data: {
//   //     startLabel: { mainText: 'verified content', suppText: 'type = text' },
//   //     // endLabel: { mainText: 'output', suppText: 'type = string' },
//   //   },
//   //   type: 'start-end',
//   // },
//   // {
//   //   id: "edge-10",
//   //   source: "node-17",
//   //   target: "node-3",
//   //   targetHandle: "a",
//   //   animated: true,
//   //   data: {
//   //     startLabel: { mainText: 'fit score', suppText: 'type = number' },
//   //     // endLabel: { mainText: 'input1', suppText: 'type = text' },
//   //   },
//   //   type: 'start-end',
//   // },
//   {
//     id: "edge-11",
//     source: "node-19",
//     target: "node-3",
//     targetHandle: "a",
//     animated: true,
//     data: {
//       // startLabel: { mainText: 'lead score', suppText: 'type = number' },
//       endLabel: { mainText: 'No' },
//     },
//     type: 'start-end',
//   },
//   {
//     id: "edge-12",
//     source: "node-16",
//     target: "node-18",
//     sourceHandle: "d",
//     animated: true,
//     data: {
//       // startLabel: { mainText: 'verified content', suppText: 'type = text' },
//       endLabel: { mainText: 'Valid Email' },
//     },
//     type: 'start-end',
//   },
//   // {
//   //   id: "edge-13",
//   //   source: "node-18",
//   //   target: "node-13",
//   //   targetHandle: "a",
//   //   animated: true,
//   //   data: {
//   //     // startLabel: { mainText: 'lead score', suppText: 'type = number' },
//   //     endLabel: { mainText: 'Yes' },
//   //   },
//   //   type: 'start-end',
//   // },
//   {
//     id: "edge-14",
//     source: "node-19",
//     target: "node-13",
//     sourceHandle: "b",
//     animated: true,
//     data: {
//       // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
//       endLabel: { mainText: 'Yes' },
//     },
//     type: 'start-end',
//   },
// ];

const initialEdges =
  [
    {
        "id": "edge-1",
        "source": "node-1",
        "target": "node-4",
        "sourceHandle": "b",
        "animated": true,
        "data": {
            "endLabel": {
                "mainText": "form data",
                "suppText": "type = json"
            }
        },
        "type": "start-end"
    },
    {
        "id": "edge-2",
        "source": "node-4",
        "target": "node-5",
        "sourceHandle": "b",
        "animated": true,
        "data": {
            "endLabel": {
                "mainText": "Email"
            }
        },
        "type": "start-end"
    },
    {
        "id": "edge-3",
        "source": "node-4",
        "target": "node-16",
        "sourceHandle": "b",
        "animated": true,
        "data": {
            "endLabel": {
                "mainText": "email"
            }
        },
        "type": "start-end"
    },
    {
        "id": "edge-5",
        "source": "node-5",
        "target": "node-14",
        "sourceHandle": "b",
        "animated": true,
        "data": {
            "startLabel": {
                "mainText": "scraped data",
                "suppText": "type = text"
            }
        },
        "type": "start-end"
    },
    {
        "id": "edge-6",
        "source": "node-5",
        "target": "node-15",
        "sourceHandle": "b",
        "animated": true,
        "data": {
            "startLabel": {
                "mainText": "scraped data",
                "suppText": "type = text"
            }
        },
        "type": "start-end"
    },
    {
        "id": "edge-7",
        "source": "node-15",
        "target": "node-17",
        "sourceHandle": "d",
        "animated": true,
        "data": {
            "startLabel": {
                "mainText": "verified content",
                "suppText": "type = text"
            }
        },
        "type": "start-end"
    },
    {
        "id": "edge-8",
        "source": "node-16",
        "target": "node-17",
        "sourceHandle": "d",
        "animated": true,
        "data": {
            "endLabel": {
                "mainText": "Invalid Email"
            }
        },
        "type": "start-end"
    },
    // {
    //     "id": "edge-11",
    //     "source": "node-19",
    //     "target": "node-3",
    //     "targetHandle": "a",
    //     "animated": true,
    //     "data": {
    //         "endLabel": {
    //             "mainText": "No"
    //         }
    //     },
    //     "type": "start-end"
    // },
    {
        "id": "edge-12",
        "source": "node-16",
        "target": "node-18",
        "sourceHandle": "d",
        "animated": true,
        "data": {
            "endLabel": {
                "mainText": "Valid Email"
            }
        },
        "type": "start-end"
    },
    {
        "id": "edge-14",
        "source": "node-19",
        "target": "node-13",
        "sourceHandle": "b",
        "animated": true,
        "data": {
            "endLabel": {
                "mainText": "Yes"
            }
        },
        "type": "start-end"
    },
    {
        "source": "node-16",
        "sourceHandle": "b",
        "target": "node-18",
        "animated": true,
        "targetHandle": "a",
        "id": "xy-edge__node-16b-node-18a"
    },
    {
        "source": "node-18",
        "sourceHandle": "b",
        "animated": true,
        "target": "node-19",
        "id": "xy-edge__node-18b-node-19"
    },
    {
        "source": "node-13",
        "animated": true,
        "sourceHandle": "b",
        "target": "node-20",
        "id": "xy-edge__node-13b-node-20"
    },
    {
        "source": "node-20",
        "animated": true,
        "sourceHandle": "b",
        "target": "node-25",
        "type": "start-end",
        "data": {
          'endLabel': {
            "mainText": "No"
          }
        },
        "id": "xy-edge__node-20b-node-25"
    },
    {
        "source": "node-20",
        "animated": true,
        "sourceHandle": "b",
        "target": "node-21",
        "type": "start-end",
        "data": {
          'endLabel': {
            "mainText": "Yes"
          }
        },
        "id": "xy-edge__node-20b-node-21"
    },
    {
        "source": "node-20",
        "sourceHandle": "b",
        "animated": true,
        "target": "node-22",
        "type": "start-end",
        "data": {
          'endLabel': {
            "mainText": "Yes"
          }
        },
        "id": "xy-edge__node-20b-node-22"
    },
    {
        "source": "node-21",
        "sourceHandle": "b",
        "animated": true,
        "target": "node-23",
        "id": "xy-edge__node-21b-node-23"
    },
    {
        "source": "node-22",
        "sourceHandle": "b",
        "animated": true,
        "target": "node-24",
        "id": "xy-edge__node-22b-node-24"
    },
    {
        "source": "node-19",
        "animated": true,
        "sourceHandle": "b",
        "target": "node-3",
        "id": "xy-edge__node-19b-node-3",
        "data": {
            "endLabel": {
                "mainText": "No"
            }
        },
        "type": "start-end"
    }
]

const nodeTypes = {
  ioNode: (data) => <LeadIONode data={data} />,
  textNode: (data) => <LeadTextNode data={data} />,
  companyNode: (data) => <LeadCompanyNode data={data} />,
  summarizerNode: (data) => <LeadSummarizerNode data={data} />,
};

const edgeTypes = {
  'start-end': LeadCustomEdge,
};

const Flow = (props) => {
  const { fitView, getNode } = useReactFlow();
  const [focusNodeId, setFocusNodeId] = useRecoilState(leadFocusNodeIdAtom);

  useEffect(() => {
    if (focusNodeId) {
      if (focusNodeId === "whole") {
        fitView({ options: { duration: 5, padding: 0.2 } });
      } else {
        const node = getNode(focusNodeId);
        if (node) {
          fitView({ nodes: [node], options: { duration: 5, padding: 0.2 } });
        }
      }
      setFocusNodeId(null);
    }
  }, [focusNodeId, fitView, getNode]);

  return (
    <ReactFlow {...props} >
      <Controls position="bottom-right" />
      <Background variant="dots" gap={20} size={1} />
    </ReactFlow>
  );
}


const LeadGumloop = () => {
  const setChatMode = useSetRecoilState(chatModeAtom);
  const setSideNavContent = useSetRecoilState(sideNavContentAtom);
  const [newNode, setNewNode] = useRecoilState(leadNewNodeAtom);
  const [contact, setContact] = useRecoilState(leadContactAtom);
  const [slack, setSlack] = useRecoilState(leadSlackAtom);
  const [fitEntire, setFitEntire] = useRecoilState(leadFitEntireAtom);
  const setFocusNodeId = useSetRecoilState(leadFocusNodeIdAtom);
  const [isCollapsed, setIsCollapsed] = useRecoilState(leadCollapsibleAtom)
  const [testLoading, setTestLoading] = useRecoilState(leadTestLoadingAtom);
  const setPolledContent = useSetRecoilState(leadPolledContentAtom);
  const setTestEmail = useSetRecoilState(leadTestEmailAtom);
  const [isCollapsibleAtom, setIsCollapsibleAtom] = useRecoilState(collapsibleAtom)

  const [modalOpen, setModalOpen] = useState(false);
  const [finalContent, setFinalContent] = useState({
    "contact_salesforce_id": "0035g00000A1bCdE",
    "contact_linkedin_url": {
      "value": "https://www.linkedin.com/in/johndoe",
      "salesforce_field": "LinkedIn_URL__c"
    },
    "contact_email": {
      "value": "john.doe@example.com",
      "salesforce_field": "Email"
    },
    "contact_title": {
      "value": "Senior Product Manager",
      "salesforce_field": "Title"
    },
    "contact_phone": {
      "value": "+1-555-123-4567",
      "salesforce_field": "Phone"
    },
    "contact_city": {
      "value": "San Francisco",
      "salesforce_field": "MailingCity"
    },
    "contact_state": {
      "value": "California",
      "salesforce_field": "MailingState"
    },
    "account_salesforce_id": "0015g00000A1bCdE",
    "account_id": {
      "value": "A0012345678",
      "salesforce_field": "Account_ID__c"
    },
    "account_name": {
      "value": "Acme Corp",
      "salesforce_field": "Name"
    },
    "account_website": {
      "value": "https://www.acmecorp.com",
      "salesforce_field": "Website"
    },
    "account_industry": {
      "value": "Manufacturing",
      "salesforce_field": "Industry"
    },
    "account_country": {
      "value": "United States",
      "salesforce_field": "BillingCountry"
    },
    "account_state": {
      "value": "California",
      "salesforce_field": "BillingState"
    }
  });

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    console.log({nodes})
    // console.log({edges})
  }, [nodes])
  
  useEffect(() => {
    // localStorage.setItem("path", "gumloop");
    // setChatMode(1);
    // setSideNavContent(1);
    setPolledContent(null)
    setTestEmail(null)
    setTestLoading(false)
  }, [])

  const replaceNode = (oldNodeId, newNode) => {
    setNodes((nds) => nds.map((node) => (node.id === oldNodeId ? newNode : node)));
  };

  useEffect(() => {
    if (newNode) {
      const hubspotNode = {
        id: "node-15",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -72, y: -33 },
        data: {
          label: "Hubspot Contact Reader",
          icon: hubspotImg,
          bgColor: "blue",
          firstKey: "Outputs",
          firstVal: "hubspot.com"
        },
      }
      replaceNode('node-1', hubspotNode)
      setNewNode(false);
    }
    if (contact) {
      const contactNode = {
        id: "node-16",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -528.8, y: 842 },
        data: {
          label: "Enrich Contact Information",
          icon: companyImg,
          bgColor: "blue",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      replaceNode('node-9', contactNode)
      setContact(false);
      setTimeout(() => {
        setFocusNodeId("node-16")
      }, 500)
    }
    if (slack) {
      const slackNode = {
        id: "node-17",
        // type: 'output',
        type: "summarizerNode",
        targetPosition: "top",
        position: { x: 55, y: 2098 },
        data: {
          label: "Slack Message Sender",
          icon: slackImg,
          bgColor: "pink",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      setNodes(prevNodes => [...prevNodes, slackNode])
      setSlack(false);
      setTimeout(() => {
        setFocusNodeId("node-17")
      }, 500)
    }
    if (fitEntire) {
      setFitEntire(false);
      setTimeout(() => {
        setFocusNodeId("whole")
      }, 500)
    }
  }, [newNode, contact, slack, fitEntire])

    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };


  return (
    <>
      <Modal
        title={<div className="text-center w-[98%]">Generated Output</div>}
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <LeadJsonToCsv jsonData={finalContent} />
        ]}
        className="!w-[50vw]"
      >
        {finalContent ? (
          <LeadOutputComponent finalContent={finalContent} />
        ) : (
          <Spin
            key="spinner"
            indicator={<LoadingOutlined />}
            size="large"
            className="w-16"
          />
        )}
      </Modal>
      <LeadMainHeader setModalOpen={setModalOpen} />
      <div style={{ width: "100%", height: "80vh" }} className="p-2 relative">
        <SideOpt />
        <ReactFlowProvider>
          <Flow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            proOptions={{ hideAttribution: true }}
            fitView
            panOnScroll
            selectionOnDrag
            minZoom={0.2}
            maxZoom={2}
          />
        </ReactFlowProvider>
        {testLoading && (
          <>
            <button onClick={toggleCollapse} className={`absolute ${isCollapsed ? 'right-16' : 'right-[20rem]'} top-12 z-50 duration-300 ease-in-out`}>
              {isCollapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
            </button>
            <LeadRightSider setModalOpen={setModalOpen}/>
          </>
        )}
      </div>
    </>
  );
};

export default LeadGumloop;
