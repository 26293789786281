import { useRecoilState } from "recoil"
import { chatModeAtom } from "../../../../../store/atoms/MainAtoms"
import { copilotChatArrayAtom } from "../../../../../store"
import { useRef, useEffect, useState } from "react"
import {
    CopyOutlined,
    LikeOutlined,
    DislikeOutlined,
} from "@ant-design/icons";
import { sendFeedback } from "../../../../../services/chatServices";
import { message, Modal, Button, Input } from "antd";
import { jwtDecode } from "jwt-decode";
const Feedback = ({ chat, index }) => {
    const [chatMode, setChatMode] = useRecoilState(chatModeAtom)
    const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom)
    const [isFeedbackVisible, setFeedbackVisible] = useState(false);
    const [feedback, setFeedback] = useState("");

    let lastInsightRef = useRef(null);
    const handleFeedbackCancel = () => {
        handleFeedback(localStorage.getItem("dislikeChat"), 1, "")
        setFeedbackVisible(false);
        setFeedback("");
    };


    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };
    const handleOk = (chat) => {
        handleFeedback(localStorage.getItem("dislikeChat"), 1, feedback)
        setFeedback("");
        setFeedbackVisible(false)
    };
    const handleDislike = (chat) => {
        localStorage.setItem("dislikeChat", JSON.stringify(chat))
        setFeedbackVisible(true)
    }
    const handleFeedback = async (chat, feedbackType, textmessage) => {
        const payload = {
            session_id: localStorage.getItem("session_id"),
            user_id: jwtDecode(
                localStorage
                    .getItem("loginToken")
                    ?.replace(process.env.REACT_APP_TOKEN_PASSCODE, "")
            )?.id,
            chat: chat,
            feedbackType: feedbackType == 0 ? true : false,
            message: textmessage,
        };
        let response = await sendFeedback(payload);
        console.log(response);
        if (response.data.status == "success") {
            console.log(response.data.data, "dkaugcfjsdgfajsdgbasx");
            message.success("Your Feedback has been recorded");
        }
    };
    const copyAnswer = (textToCopy) => {
        if (!navigator.clipboard) {
            console.error("Clipboard API not supported");
            return;
        }

        // Write the text to the clipboard
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                console.log("Text copied to clipboard:", textToCopy);
                message.success("The answer has been copied to your clipboard");
                // You can optionally show a success message or perform other actions
            })
            .catch((err) => {
                console.error("Failed to copy text:", err);
                // Handle the error, such as showing an error message to the user
            });
    };
    useEffect(() => {
        let timeoutId, insightTimeoutId;
        if (lastInsightRef.current) {
            insightTimeoutId = setTimeout(() => {
                lastInsightRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                });
            }, 1500); // Delay to wait for text animation to at least be visible to user
        }
        console.log(
            chatArray,
            "------------------------------chatArray----------------------------"
        );
        return () => {
            clearTimeout(timeoutId);
            clearTimeout(insightTimeoutId);
        };
    }, [chatArray]);
    return (
        <>
            <Modal
                title="Feedback"
                visible={isFeedbackVisible}
                onCancel={handleFeedbackCancel}
                footer={[
                    <Button key="back" onClick={handleFeedbackCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk} disabled={feedback?.trim() == null || feedback?.trim() == ""}>
                        OK
                    </Button>
                ]}
            >
                <div className="m-8 flex flex-col justify-center gap-4">
                    <span className="text-[15px]">Uh-oh! Seems like you're not satisfied with our response. Please describe what went wrong so we could improve it:</span>
                    <Input.TextArea
                        value={feedback}
                        onChange={handleFeedbackChange}
                        rows={4}
                    />
                </div>
            </Modal>
            {chat?.question && chatMode != 1 && (
                <div
                    className={`flex justify-end items-center gap-4 pr-20 ${index === chatArray.length - 1 && "pb-10"
                        }`}
                    ref={index === chatArray.length - 1 ? lastInsightRef : null}
                >
                    <CopyOutlined
                        className="text-base cursor-pointer hover:scale-125 duration-300"
                        onClick={() => copyAnswer(chat.answer)}
                    />
                    {/* <img src={refreshImg} alt="refreshImg" width={20} className="cursor-pointer hover:scale-125 duration-300" /> */}
                    {/* <UndoOutlined
                            rotate={100}
                            className="text-base cursor-pointer hover:scale-125 duration-300"
                          /> */}
                    <LikeOutlined
                        className="text-base cursor-pointer hover:scale-125 duration-300"
                        onClick={() => handleFeedback(JSON.stringify(chat), 0, " ")}
                    />
                    <DislikeOutlined
                        className="text-base cursor-pointer hover:scale-125 duration-300"
                        onClick={() => handleDislike(chat)}
                    />
                </div>
            )}
        </>
    )
}
export default Feedback