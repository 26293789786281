import React, { useEffect } from "react";

import { useRecoilState } from "recoil";
import AgentCustomBtn from "../../components/AgentCustomBtn/AgentCustomBtn";
import ConfigureSync from "../../components/SegmentComponents/ConfigureSync";
import DefineAudience from "../../components/SegmentComponents/DefineAudience";
import FinalizeSync from "../../components/SegmentComponents/FinalizeSync";
import SelectDestination from "../../components/SegmentComponents/SelectDestination";
import SelectModel from "../../components/SegmentComponents/SelectModel";
import SegmentNavbar from "../../components/SegmentNavbar/SegmentNavbar";
import { activeSegmentAtom, resultsVisibleAtom } from "../../store";

const mapper = [
  <SelectModel />,
  <DefineAudience />,
  <SelectDestination />,
  <ConfigureSync />,
  <FinalizeSync />,
];

const Segment = () => {
  const [activeSegment, setActiveSegment] = useRecoilState(activeSegmentAtom);
  const [resultsVisible, setResultsVisible] =
    useRecoilState(resultsVisibleAtom);

  useEffect(() => {
    setActiveSegment(0);
    setResultsVisible(false);
  }, []);

  return (
    <div className="p-4 flex flex-col gap-4 relative h-[92vh]">
      <SegmentNavbar />
      {mapper[activeSegment]}
      {activeSegment === 1 && resultsVisible && (
        <div className="flex justify-end items-center sticky bottom-0 right-0">
          <AgentCustomBtn
            otherClasses={"w-fit"}
            onClick={() => setActiveSegment(2)}
          >
            Continue
          </AgentCustomBtn>
        </div>
      )}
    </div>
  );
};

export default Segment;
