import React, { useState } from "react";

const names = [
  "Theron",
  "Octavia",
  "Lysander",
  "Seraphina",
  "Cassian",
  "Evangeline",
  "Leander",
  "Persephone",
  "Lucius",
  "Aurelia",
  "Orson",
  "Valentina",
  "Dorian",
  "Caspian",
  "Isolde",
  "Thaddeus",
  "Amara",
  "Marius",
  "Calliope",
  "Roderick",
];

const DefineRight = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="w-1/4 h-full flex flex-col gap-2">
      <div className="flex items-center gap-4 border-b">
        {["Results", "Overlap", "Breakdown"].map((sel, ind) => (
          <p
            key={ind}
            className={`${
              selected === ind
                ? "text-primary border-primary"
                : "text-black border-none"
            } border-b pb-2 cursor-pointer duration-300 ease-in-out`}
            onClick={() => setSelected(ind)}
          >
            {sel}
          </p>
        ))}
      </div>
      <div className="h-[60vh] overflow-scroll flex flex-col gap-4 border-l py-2">
        {names.map((name, index) => (
          <>
            <div
              className="flex justify-center items-center gap-4 -ml-24"
              key={index}
            >
              <p className="border bg-gray-100 rounded-full px-1.5">
                {name?.charAt(0)}
              </p>
              <div className="flex flex-col gap-0.5 justify-center">
                <p>{name}</p>
                <p>Email Redacted</p>
              </div>
            </div>
            <hr />
          </>
        ))}
      </div>
    </div>
  );
};

export default DefineRight;
