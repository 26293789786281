import { useCallback } from "react";
import { Handle, Position } from "@xyflow/react";

const handleStyle = { left: 10 };

function LeadTextNode({ data }) {
  return (
    <div className="border border-[#eee] rounded-[5px] bg-[#FEF9C3] max-w-[30rem] text-start p-2">
      <div className="flex flex-col justify-start items-start text-3xl font-bold">
        {data?.data?.label}
      </div>
      <div className="text-xl mt-2">
        {data?.data?.text}
      </div>
    </div>
  );
}

export default LeadTextNode;
