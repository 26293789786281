import React, { useState } from "react";
import DefineNavbar from "../DefineNavbar/DefineNavbar";
import AgentCustomBtn from "../AgentCustomBtn/AgentCustomBtn";

import {
  PlusOutlined,
  GlobalOutlined,
  ProductOutlined,
  DatabaseFilled,
  DashboardFilled,
  TeamOutlined,
  BlockOutlined,
} from "@ant-design/icons";

import { Dropdown } from "antd";
import DefineHeader from "../DefineHeader/DefineHeader";
import DefineBlock from "../DefineBlock/DefineBlock";
import DefineBrand from "../DefineBrand/DefineBrand";
import DefineRight from "../DefineRight/DefineRight";
import { useRecoilValue } from "recoil";
import { resultsVisibleAtom } from "../../store";

const DefineAudience = () => {
  const [block, setBlock] = useState(false);
  const [brand, setBrand] = useState(false);

  const resultsVisible = useRecoilValue(resultsVisibleAtom);

  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-4">
          <GlobalOutlined className="border rounded-md p-2 bg-primary text-white" />
          <div className="flex flex-col justify-center gap-0">
            <p className="body_Regular !font-bold text-primary">All Filters</p>
            <p className="text-character text-[14px]">
              View all available filters
            </p>
          </div>
        </div>
      ),
      children: [
        {
          key: "2-1",
          label: (
            <div className="flex items-center gap-2">
              <TeamOutlined className="border rounded-md p-2 bg-primary text-white" />
              <p className="text-character text-[14px]">
                Abandoners - Affinity Nike
              </p>
            </div>
          ),
        },
        {
          key: "2-2",
          label: (
            <div className="flex items-center gap-2">
              <TeamOutlined className="border rounded-md p-2 bg-primary text-white" />
              <p className="text-character text-[14px]">All Users</p>
            </div>
          ),
        },
        {
          key: "2-3",
          label: (
            <div className="flex items-center gap-2">
              <TeamOutlined className="border rounded-md p-2 bg-primary text-white" />
              <p className="text-character text-[14px]">Bottom Funnel</p>
            </div>
          ),
        },
        {
          key: "2-4",
          label: (
            <div className="flex items-center gap-2">
              <TeamOutlined className="border rounded-md p-2 bg-primary text-white" />
              <p className="text-character text-[14px]">
                High Propensity Users
              </p>
            </div>
          ),
        },
        {
          key: "2-5",
          label: (
            <div className="flex items-center gap-2">
              <DashboardFilled className="border rounded-md p-2 bg-primary text-white" />
              <p className="text-character text-[14px]">Item Added</p>
            </div>
          ),
          onClick: () => setBlock(true),
        },
        {
          key: "2-6",
          label: (
            <div className="flex items-center gap-2">
              <DashboardFilled className="border rounded-md p-2 bg-primary text-white" />
              <p className="text-character text-[14px]">Item Purchased</p>
            </div>
          ),
        },
        {
          key: "2-7",
          label: (
            <div className="flex items-center gap-2">
              <ProductOutlined className="border rounded-md p-2 bg-primary text-white" />
              <p className="text-character text-[14px]">Brand Affinity</p>
            </div>
          ),
          onClick: () => setBrand(true),
        },
      ],
    },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-4">
          <ProductOutlined className="border rounded-md p-2 bg-primary text-white" />
          <div className="flex flex-col justify-center gap-0">
            <p className="body_Regular !font-bold text-primary">Properties</p>
            <p className="text-character text-[14px]">
              Filter by a person's attributes
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="flex items-center gap-4">
          <DatabaseFilled className="border rounded-md p-2 bg-primary text-white" />
          <div className="flex flex-col justify-center gap-0">
            <p className="body_Regular !font-bold text-primary">Relations</p>
            <p className="text-character text-[14px]">
              Filter by a relationship to other entities
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div className="flex items-center gap-4">
          <DashboardFilled className="border rounded-md p-2 bg-primary text-white" />
          <div className="flex flex-col justify-center gap-0">
            <p className="body_Regular !font-bold text-primary">Events</p>
            <p className="text-character text-[14px]">
              Filter by an event they have performed
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div className="flex items-center gap-4">
          <TeamOutlined className="border rounded-md p-2 bg-primary text-white" />
          <div className="flex flex-col justify-center gap-0">
            <p className="body_Regular !font-bold text-primary">Audiences</p>
            <p className="text-character text-[14px]">
              Filter by whether they are included in other audience
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div className="flex items-center gap-4">
          <BlockOutlined className="border rounded-md p-2 bg-primary text-white" />
          <div className="flex flex-col justify-center gap-0">
            <p className="body_Regular !font-bold text-primary">
              Custom Traits
            </p>
            <p className="text-character text-[14px]">
              Filter by your organization's custom attributes
            </p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4 h-full">
      <DefineNavbar />
      <div className="flex items-start gap-6 w-full">
        <div className="flex flex-col justify-center gap-6 px-32 py-4 w-3/4">
          <DefineHeader />
          {block && <DefineBlock />}
          {brand && <DefineBrand />}
          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <AgentCustomBtn otherClasses={"!w-fit"}>
              <PlusOutlined />
              <p>Add Filter</p>
            </AgentCustomBtn>
          </Dropdown>
        </div>
        {resultsVisible && <DefineRight />}
      </div>
    </div>
  );
};

export default DefineAudience;
