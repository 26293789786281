import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { activeHomeAtom } from "../../store";
import Company from "../../components/AnalyticsTab/Company/Company";

const Analytics = () => {
  const [activeHomeComp, setActiveHomeComp] = useRecoilState(activeHomeAtom);

  useEffect(() => {
    setActiveHomeComp(0);
  }, []);

  const homeTabs = [
    {
        name: "Company Dashboard",
        component: <Company />,
    },
    // {
    //     name: "Scenarios",
    //     component: < ></>,
    // },


  ];
  return (
    <div>
      {/* <div className="flex justify-between items-center pl-1 pr-4 pt-2">
        {homeTabs.map((tab, index) => (
          <div
            key={index}
            className={`${
              activeHomeComp == index
                ? "text-white bg-[#2966BC]"
                : "bg-[#E9EDF1] font-semibold"
            } flex justify-center items-center border ${
              index == homeTabs.length - 1 ? "rounded-r-md" : "border-r-black"
            } ${
              index == 0 ? "rounded-l-md" : ""
            } px-2 py-1 text-sm cursor-pointer flex-1`}
            onClick={() => setActiveHomeComp(index)}
          >
            {tab.name}
          </div>
        ))}
      </div> */}
      <div className="h-[80vh] w-full pl-1 pr-4">
        {/* {homeTabs[activeHomeComp]?.component} */}
        <Company />
      </div>
    </div>
  );
};

export default Analytics;
