import React, { useState, useEffect, useRef } from "react";
import "./RightSider.css";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { collapsibleAtom, gumloopChatArrayAtom, btnLoadingAtom, nodeInProgressAtom, activeIndexAtom, visibleProcessesAtom } from "../../store";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import Timer from "./Timer.js";
import Complete from "./Complete.js";

import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";

const siderProcess = [
  {
    id: "node-15",
    label: "Hubspot Contact Reader",
    icon: hubspotImg,
    duration: 18.33,
  },
  {
    id: "node-4",
    label: "Split Text",
    icon: splitTextImg,
    duration: 14.03,
  },
  {
    id: "node-5",
    label: "Get List Item",
    icon: getListImg,
    duration: 12.03,
  },
  {
    id: "node-13",
    label: "Enrich Company Information",
    icon: companyImg,
    duration: 10.23,
  },
  {
    id: "node-12",
    label: "Advanced Website Scraper",
    icon: webScraperImg,
    duration: 15.32,
  },
  {
    id: "node-14",
    label: "Summarizer",
    icon: summaryImg,
    duration: 17.99,
  },
  {
    id: "node-16",
    label: "Enrich Contact Information",
    icon: companyImg,
    duration: 14.55,
  },
  {
    id: "node-6",
    label: "Combine Text",
    icon: combineTextImg,
    duration: 18.23,
  },
  {
    id: "node-3",
    label: "Output",
    icon: outputImg,
    duration: 13.32,
  },
  {
    id: "node-17",
    label: "Slack Message Sender",
    icon: slackImg,
    duration: 15.22,
  },
];

const RightSider = () => {
  const isCollapsed = useRecoilValue(collapsibleAtom);
  const chatArray = useRecoilValue(gumloopChatArrayAtom);
  const setBtnLoading = useSetRecoilState(btnLoadingAtom);
  const [activeIndex, setActiveIndex] = useRecoilState(activeIndexAtom);
  const [visibleProcesses, setVisibleProcesses] = useRecoilState(visibleProcessesAtom);
  const setNodeInProgress = useSetRecoilState(nodeInProgressAtom);

  const handleTimerComplete = () => {
    // setActiveIndex((prevIndex) => prevIndex + 1);
    setActiveIndex(prevIndex => {
        const newIndex = prevIndex + 1;
        setTimeout(() => {
            setVisibleProcesses([...visibleProcesses, newIndex]);
        }, 0)
        return newIndex;
    });
  };

  useEffect(() => {
    if (visibleProcesses?.length === siderProcess?.length) {
      setBtnLoading(false);
    }
  }, [visibleProcesses])

  const ref = useRef(null);

  useEffect(() => {
    let timeout;
    if (ref) {
        timeout = setTimeout(() => {
            ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" })
        }, 100)
    }

    // const newElement = document.querySelector(`.process-${activeIndex}`);
    // if (newElement) {
    //   setTimeout(() => {
    //     newElement.classList.add('visible');
    //   }, 100); // Timeout is needed to allow the element to be rendered before adding the class
    // }

    console.log(activeIndex, "activeIndex----------->")
    const id = siderProcess[visibleProcesses[visibleProcesses?.length-1]]?.id;
    setNodeInProgress(id);

    return () => clearTimeout(timeout)
  }, [activeIndex, visibleProcesses])

  return (
    <div
      className={`rounded-md right-sider bg-white ${
        isCollapsed ? "collapsed" : ""
      }`}
    >
      <div className="sider-content flex flex-col gap-6">
        {siderProcess.map(
          (process, index) =>
            index <= activeIndex && (
              <div key={process?.id} className={`flex flex-col gap-3 process ${visibleProcesses.includes(index) ? 'visible' : ''}`}>
                <div className="flex items-center gap-2">
                  <img src={process?.icon} alt={process?.id} width={18} />
                  <p className="font-bold text-[12px]">{process?.label}</p>
                </div>
                <Timer
                  duration={process?.duration}
                  onComplete={handleTimerComplete}
                />
                {process?.id === "node-3" && (
                  <div ref={ref} className="mt-4 flex flex-col gap-1 text-[11px] bg-gray-100 p-2 border rounded-md">
                    <p className="font-semibold">
                      max@hubspot.com (2,170,000,000 ARR)
                    </p>
                    <p>
                      <span className="font-semibold">INFO:</span> HubSpot is a
                      leading AI-powered customer relationship management (CRM)
                      platform that provides integrated software and tools for
                      marketing, sales, customer service, content management,
                      operations, and commerce, enabling businesses to grow and
                      connect with their customers more effectively.
                    </p>
                    <p>
                      <span className="font-semibold">Company name:</span>{" "}
                      HubSpot
                    </p>
                    <p>
                      <span className="font-semibold">Industry:</span>{" "}
                      Information Technology & Services
                    </p>
                    <p>
                      <span className="font-semibold">Country:</span> United
                      States
                    </p>
                  </div>
                )}
                {index === siderProcess?.length - 1 && chatArray[chatArray?.length-1]?.answer !== "I see you have selected the Lead Research Template. Would you like help setting it up?" ? <Complete /> : <hr className="translate-y-2"/>}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default RightSider;
