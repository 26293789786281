import React, { useState, useRef, useEffect } from "react";
import {
  UndoOutlined,
  RedoOutlined,
  EditFilled,
  ArrowsAltOutlined,
} from "@ant-design/icons";
import { Card, Table, Input, Switch, message } from "antd";
import html2canvas from "html2canvas";
import {jwtDecode} from "jwt-decode";
import { getReport } from "../../services/InsightsServices";
// import { formatDate } from "../../utils/formatDate";
import { formatDate } from "../../helpers/formatDate";
import shareImg from "../../assets/images/shareImg.png";
import downloadImg from "../../assets/images/downloadImg.png";
import funnelImg from "../../assets/images/funnel.png";

import "./CoPilotStyles.css";

const { Search } = Input;

const Reports = () => {
  const [rowsSelected, setRowsSelected] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState(null);

  const divRef = useRef(null);
  useEffect(() => {
    if (divRef?.current) {
      divRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setRowsSelected(selectedRows?.length);
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("loginToken");
        const decodedToken = jwtDecode(token?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ""));
        const payload = {
          user_id: decodedToken.id,
        };
        const response = await getReport(payload);
        if (response.data.status === "success") {
          const rawData = response.data.result;
          const transformedData = rawData.map((item) => ({
            key: item.report_id,
            dateCreated: formatDate(item.created_date),
            reportName: item.name,
            description: item.description,
            lastEdit: formatDate(item.created_date), // Adjust this as needed
            catalogue: false, // Adjust this as needed
            chart_data: item.chart_data,
          }));
          setData(transformedData);
        } else {
          message.error("Error fetching dashboard data");
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        message.error("Error fetching dashboard data");
      }
    };

    fetchData();
  }, []);

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const downloadChartAsImage = (id) => {
    const chartElement = document.getElementById(id);
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "chart.png";
        link.click();
        message.success("Image downloaded successfully");
      });
    }
  };

  return (
    <>
      <div className="flex justify-end items-center gap-2 mr-4 border border-2 border-black p-2 rounded-md">
        <UndoOutlined
          rotate={90}
          className="p-1 hover:border hover:border-white hover:border-b-[#E07E65] hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out]"
        />
        <RedoOutlined
          rotate={270}
          className="p-1 hover:border hover:border-white hover:border-b-[#E07E65] hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out]"
        />
        <EditFilled className="p-1 hover:border hover:border-white hover:border-b-[#E07E65] hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out]" />
        <div className="-mr-1 mb-0.5 relative" ref={divRef}>
          <img
            src={shareImg}
            alt="shareImg"
            width={24}
            className={`hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] ${
              filterVisible && "border border-white border-b-[#E07E65]"
            }`}
            onClick={() => setFilterVisible(!filterVisible)}
          />
          {filterVisible && (
            <div className="absolute -bottom-[8.5rem] -right-[4.5rem] z-50 flex flex-col gap-1 justify-center items-center w-[900%] border bg-gray-300 p-2">
              <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                Save to Dashboard
              </div>
              <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                Generate Embeddable Link
              </div>
              <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                Send on Slack
              </div>
              <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                Send Via Email
              </div>
            </div>
          )}
        </div>
        <img
          src={downloadImg}
          alt="downloadImg"
          width={26}
          className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
        />
      </div>
      {/* Chart Component Starts */}
      <div className="w-full flex justify-center items-center">
        <Card className="w-[95%] relative mt-2">
          <div className="absolute right-5 top-2">
            <ArrowsAltOutlined />
          </div>
          {chartData && (
            <div
              id={chartData?.id}
              className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md"
            >
              <img
                className="w-[50%]"
                src={`${chartData?.data}`}
                alt="Generated by Python"
              />
            </div>
          )}
          <div className="flex justify-between items-center w-full border border-black px-4 py-2 rounded-md">
            <div className="flex justify-center items-center gap-4">
              <p>{rowsSelected}/{data.length} Rows Selected</p>
              <Search
                placeholder="Search"
                onSearch={onSearch}
                allowClear
                className="extendSearch"
                style={{
                  width: 200,
                }}
              />
            </div>
            <div className="flex justify-center items-center gap-2">
              <img src={funnelImg} alt="funnelImg" width={14} />
            </div>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={[
              {
                title: "Date Created",
                dataIndex: "dateCreated",
                width: "15%",
              },
              {
                title: "Report Name",
                dataIndex: "reportName",
              },
              {
                title: "Description",
                dataIndex: "description",
                width: "30%",
              },
              {
                title: "Last Edit",
                dataIndex: "lastEdit",
              },
              {
                title: "Catalogue",
                dataIndex: "catalogue",
                render: (_, { reportName }) => (
                  <div className="copilotSwitchContainer">
                    <Switch
                      defaultChecked={reportName === "Tech Report" ? true : false}
                      onChange={onChange}
                    />
                  </div>
                ),
              },
            ]}
            rowHoverable={false}
            dataSource={data}
            bordered
            pagination={{ 
              pageSizeOptions: ['5', '10', '20', '30', '50'], 
              showSizeChanger: true,
              defaultPageSize: 5
            }}
            className={`overflow-scroll shadow-lg`}
            rowClassName={(record) => 
              record.key === selectedRowKey ? "bg-[#73D297]" : "hover:bg-[#E9EDF1]"
            }
            onRow={(record) => ({
              onClick: () => {
                setSelectedRowKey(record.key);
                setChartData({ id: record.key, data: record.chart_data });
              },
            })}
          />
        </Card>
      </div>
      {/* Chart Component Ends */}
    </>
  );
};

export default Reports;
