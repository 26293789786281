import React, { useEffect } from 'react';
import Chat from '../../components/DemoSuzieComponent/Chat.js';
import { activeCopilotCompAtom } from "../../store/index.js";
import { useRecoilState } from "recoil";
import Dashboard from "../../components/Dashboard/Dashboard.js";
import DemoDashboard from '../../components/DemoSuzieComponent/DemoSuzieDashboard.js';
import { chatModeAtom, sideNavContentAtom } from '../../store/atoms/MainAtoms.js';
import Files from '../../components/DemoSuzieComponent/Files.js';
import Reports from '../../components/DemoSuzieComponent/Reports.js';

const DemoSuzieCopilot = () => {
  const [activeCopilotComp, setActiveCopilotComp] = useRecoilState(activeCopilotCompAtom);
  const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
  const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);

  useEffect(() => {
    setActiveCopilotComp(0);
    setChatMode(0);
    setSideNavContent(0)
    localStorage.setItem("path", "ttyd-advanced")
  }, []);

  const homeTabs = [
    {
      name: "Chat",
      component: <Chat />,
    },
    {
      name: "Files",
      component: <Files />,
    },
    {
      name: "Reports",
      component: <Reports />,
    },
    {
      name: "Dashboard",
      component: <DemoDashboard />,
    },
  ];

  const handleTabClick = (index) => {
    setActiveCopilotComp(index);
    if (homeTabs[index].name === "Chat") {
      setChatMode(0);
      setSideNavContent(0)
    } else {
      setChatMode(1)
      setSideNavContent(1)
    }
  };

  return (
    <div className='flex flex-col h-[90vh] w-full p-2'>
      <div className="flex justify-start items-center pl-1 pr-4 pt-2 border-b border-gray-300">
        {homeTabs.map((tab, index) => (
          <div
            key={index}
            className={`${activeCopilotComp === index
              ? "text-[#2966BC] border-b-2 border-[#2966BC]"
              : "text-gray-600"
              } px-4 py-2 text-sm cursor-pointer`}
            onClick={() => handleTabClick(index)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="flex-1 p-4">
        {homeTabs[activeCopilotComp].component}
      </div>
    </div>
  );
};

export default DemoSuzieCopilot;
