import React from "react";
import {
  PartitionOutlined,
  MenuOutlined,
  TeamOutlined,
  ClockCircleFilled,
  EditFilled,
  SearchOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import {
  activeTabAtom,
  dropAtom,
} from "../../../store/atoms/AnalyticsAtoms.js";
import { useRecoilState } from "recoil";
import { Dropdown } from "antd";

const CatalogueNavbar = () => {
  const items = [
    {
      key: "1",
      label: (
        <div className="w-full" onClick={() => handleDropdown("Month")}>
          Month
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="w-full" onClick={() => handleDropdown("Quarter")}>
          Quarter
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: (
    //     <div className="w-full" onClick={() => handleDropdown("Year")}>
    //       Year
    //     </div>
    //   ),
    // },
  ];
  const handleDropdown = (item) => {
    setActiveTab(4);
    setDrop(item);
  };
  const catalogueTabs = [
    {
      name: "All",
      component: "",
    },
    {
      name: "Channels",
      component: <PartitionOutlined />,
    },
    {
      name: "Segment",
      component: <MenuOutlined />,
    },
    {
      name: "Team",
      component: <TeamOutlined />,
    },
    {
      name: "Time Period",
      component: <ClockCircleFilled />,
    },
    // {
    //   name: "Metric",
    //   component: <SearchOutlined />,
    // },
    {
      name: "Edit Funnel",
      component: <EditFilled />,
    },
  ];
  const [activeTab, setActiveTab] = useRecoilState(activeTabAtom);
  const [drop, setDrop] = useRecoilState(dropAtom);

  return (
    <div className="flex justify-between items-center px-10 pt-2">
      {catalogueTabs.map((tab, index) =>
        index === 4 ? (
          <Dropdown
            menu={{
              items,
            }}
          >
            <div
              key={index}
              className={`${
                activeTab === index
                  ? "text-white bg-[#8EA9D0]"
                  : "bg-[#E9EDF1] font-semibold"
              } flex justify-center items-center gap-1 border cursor-pointer ${
                index == catalogueTabs.length - 1
                  ? "rounded-r-md"
                  : "border-r-black"
              } ${index == 0 ? "rounded-l-md" : ""} px-2 py-1 text-sm flex-1`}
              onClick={() => setActiveTab(index)}
            >
              <div>{tab.component}</div>
              <div>{activeTab === index ? drop : tab.name}</div>
              <CaretDownOutlined />
            </div>
          </Dropdown>
        ) : (
          <div
            key={index}
            className={`${
              activeTab === index
                ? "text-white bg-[#8EA9D0]"
                : "bg-[#E9EDF1] font-semibold"
            } flex justify-center items-center gap-1 border cursor-pointer ${
              index == catalogueTabs.length - 1
                ? "rounded-r-md"
                : "border-r-black"
            } ${index == 0 ? "rounded-l-md" : ""} px-2 py-1 text-sm flex-1`}
            onClick={() => setActiveTab(index)}
          >
            <div>{tab.component}</div>
            <div>{tab.name}</div>
          </div>
        )
      )}
    </div>
  );
};

export default CatalogueNavbar;
