import React from 'react'

import { initialValues } from '../AgentMainComponent/AgentMainComponent'
import AgentCardWrapper from '../AgentCardWrapper/AgentCardWrapper'
import { useRecoilValue } from 'recoil'
import { agentValAtom } from '../../store'

const AgentExplore = () => {
  const agentVal = useRecoilValue(agentValAtom);

  return (
    <div className='flex flex-col justify-center gap-4 px-6 pb-4'>
        <p className='text-character h4_Medium'>
            Explore more Templates
        </p>
        <AgentCardWrapper values={initialValues?.filter(val => val.name !== agentVal?.name)?.slice(0, 3)} />
    </div>
  )
}

export default AgentExplore