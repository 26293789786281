import React, { useEffect, useState } from 'react';
import ModelsTable from './ModelsTable.js';
import { useRecoilState } from "recoil";
import Destination from './Destination.js';
import Catalog from './Catalog.js';
import { chatModeAtom, sideNavContentAtom } from '../../../store/atoms/MainAtoms.js';
import Sources from './Sources.js';
import Integration from '../../../pages/Integration/Integration.js';
import { Extensions } from './Extensions.js';

const DataManager = () => {
    const [activeDataManagerComp, setActiveDataManagerComp] = useState(0);
    const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
    const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);

    useEffect(() => {
        setActiveDataManagerComp(0);
        setChatMode(0);
        setSideNavContent(0);
        localStorage.setItem("path", "assistant");
    }, []);

    const homeTabs = [
        {
            name: "Sources",
            component: <Sources />,
        },
        {
            name: "Destination",
            component: <Destination />,
        },
        {
            name: "Models",
            component: <ModelsTable />,
        },
        {
            name: "Catalog",
            component: <Catalog />,
        },
        {
            name: "Extensions",
            component: <Extensions />,
        },
    ];

    const handleTabClick = (index) => {
        setActiveDataManagerComp(index);
    };

    return (
        <div className='flex flex-col h-[90vh] w-full p-2'>
            <div className="flex justify-start items-center pl-1 pr-4 pt-2 border-b border-gray-300">
                {homeTabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`${activeDataManagerComp === index
                            ? "text-[#2966BC] border-b-2 border-[#2966BC]"
                            : "text-gray-600"
                            } px-4 py-2 text-sm cursor-pointer`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.name}
                    </div>
                ))}
            </div>
            <div className="flex-1 p-4">
                {homeTabs[activeDataManagerComp].component}
            </div>
        </div>
    );
};

export default DataManager;
