import React, { useState, useEffect } from 'react';
import { Button, Input, Card, Progress, Table } from 'antd';
import {
    SalesforceOutlined,
    HubspotOutlined,
    DatabaseOutlined,
    CloudServerOutlined,
    HddOutlined,
} from '@ant-design/icons';
import Sources_part2 from './Sources_part2';
import sfimg from '../../../assets/images/salesforceImg.png';
import hubspot from '../../../assets/images/hubspot.png';
import databricks from '../../../assets/images/databricks.png';
import redshift from '../../../assets/images/redshift.png';
import bigquery from '../../../assets/images/bigquery.png';
import postgres from '../../../assets/images/postgres.png';
import mysql from '../../../assets/images/mysql.png';
import s3 from '../../../assets/images/s3.png';
import gcloud from '../../../assets/images/gcloudstorage.png';
import azurepng from '../../../assets/images/azurepng.png';
import slackLogo from '../../../assets/images/slackLogo.png';
import marketo from '../../../assets/images/marketo_logo.svg';
import outreach from '../../../assets/images/outreach_logo.png';
import salesloft from '../../../assets/images/salesloft-logo.png';
import adobe from '../../../assets/images/adobe.png';
import amplitude from '../../../assets/images/amplitude.png';
import gSheet from '../../../assets/images/sheets.png';
import braze from '../../../assets/images/braze.png';
import mailchimp from '../../../assets/images/mailchimp.png';
import segment from '../../../assets/images/segment.png';
import dropbox from '../../../assets/images/dropbox.png';
import box from '../../../assets/images/box.png';
import gong from '../../../assets/images/gong.png';
import meta from '../../../assets/images/meta.png';
import cc from '../../../assets/images/cc.png';
import sg from '../../../assets/images/sg.png';
import klaviyo from '../../../assets/images/klaviyo.png';
import x from '../../../assets/images/x.jpg';
import wh from '../../../assets/images/wh.png';
import td from '../../../assets/images/td.png';
import iterable from '../../../assets/images/iterable.png';
import at from '../../../assets/images/at.png';
import ac from '../../../assets/images/ac.png';
import tiktok from '../../../assets/images/tiktok.jpg';
import snapchat from '../../../assets/images/snapchat.jpg';
import gcm from '../../../assets/images/gcm.png';
import gainsight from '../../../assets/images/gainsight.jpg';
import yahoo from '../../../assets/images/yahoo.jpg';
import zoho from '../../../assets/images/zoho.png';
import tr from '../../../assets/images/trustradius.png';
import pinterest from '../../../assets/images/pinterest.png';
import salesforce from '../../../assets/images/salesforceImg.png';
import chatgpt from '../../../assets/images/chatgpt.jpg';

const modelsData = [
    {
        key: '1',
        table: 'Salesforce Demo',
        description: 'This model finds the first marketing touch that influenced a sales opportunity.',
        type: 'Warehouse',
    },
];

const sourceOptions = [
    { label: 'Salesforce', logo: sfimg, value: 'a' },
    { label: 'Hubspot', logo: hubspot, value: 'b' },
    { label: 'Slack', logo: slackLogo, value: 'c' },
    { label: 'Marketo', logo: marketo, value: 'd' },
    { label: 'Outreach', logo: outreach, value: 'e' },
    { label: 'Adobe Analytics', logo: adobe, value: 'f' },
    { label: 'Amazon S3', logo: s3, value: 'g' },
    { label: 'Amplitude', logo: amplitude, value: 'h' },
    { label: 'Braze', logo: braze, value: 'i' },
    { label: 'Google BigQuery', logo: bigquery, value: 'j' },
    { label: 'Google Sheet', logo: gSheet, value: 'k' },
    { label: 'Mailchimp', logo: mailchimp, value: 'l' },
    { label: 'Segment', logo: segment, value: 'm' },
    { label: 'Dropbox', logo: dropbox, value: 'n' },
    { label: 'Box', logo: box, value: 'o' },
    { label: 'Gong', logo: gong, value: 'p' },
    { label: 'Meta', logo: meta, value: 'q' },
    { label: 'Constant Contact', logo: cc, value: 'r' },
    { label: 'SendGrid', logo: sg, value: 'r' },
    { label: 'Klaviyo', logo: klaviyo, value: 'r' },
    { label: 'X Ads (Formerly Twitter)', logo: x, value: 'r' },
    { label: 'Webhooks', logo: wh, value: 'r' },
    { label: 'Trade Desk', logo: td, value: 'r' },
    { label: 'Iterable', logo: iterable, value: 'r' },
    { label: 'ChatGPT Retrieval Plugin', logo: chatgpt, value: 'r' },
    { label: 'Adobe Target', logo: at, value: 'r' },
    { label: 'Active Campaign', logo: ac, value: 'r' },
    { label: 'TikTok', logo: tiktok, value: 'r' },
    { label: 'Snapchat', logo: snapchat, value: 'r' },
    { label: 'Pinterest', logo: pinterest, value: 'r' },
    { label: 'Google Campaign Manager 360', logo: gcm, value: 'r' },
    { label: 'Gainsight', logo: gainsight, value: 'r' },
    { label: 'Pardot', logo: salesforce, value: 'r' },
    { label: 'Yahoo Ads (DSP)', logo: yahoo, value: 'r' },
    { label: 'Zoho CRM', logo: zoho, value: 'r' },
    { label: 'Trust Radius', logo: tr, value: 'r' },
    // Add more options as needed
];

const Destination = () => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);

    const steps = [
        'Check network connectivity',
        `Validate ${selectedSource?.label} credentials`,
        'Verify permission to list schemas and tables',
        'Verify permission to write to planner schema',
        'Verify permission to write to audit schema',
    ];

    useEffect(() => {
        if (isConnecting && currentStep < steps.length) {
            const timer = setTimeout(() => {
                setCurrentStep((prevStep) => prevStep + 1);
            }, 2000);
            return () => clearTimeout(timer);
        } else if (currentStep === steps.length) {
            setIsCompleted(true);
        }
    }, [isConnecting, currentStep]);

    const handleModelClick = (model) => {
        setSelectedModel(model);
    };

    const handleOpenSourceSelection = () => {
        setIsModalOpen(true);
    };

    const handleSourceCardClick = (source) => {
        setSelectedSource(source);
    };

    const handleConnect = () => {
        setIsConnecting(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'table',
            key: 'table',
            render: (text, record) => (
                <Button type="link" onClick={() => handleModelClick(record)}>
                    {text}
                </Button>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
    ];

    return (
        <div>
            {!selectedModel && !isModalOpen ? (
                <>
                    <div className='flex justify-between mb-2'>
                        <div className='flex justify-center gap-4'>
                            <Input placeholder={"Search"} className='w-[65%]' />
                            <Button>Type</Button>
                            <Button type="primary" onClick={handleOpenSourceSelection}>Add Destination</Button>
                        </div>
                    </div>
                    <Table
                        dataSource={modelsData}
                        columns={columns}
                        pagination={false}
                    />
                </>
            ) : isModalOpen ? (
                <>
                    {selectedSource ? (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                <div className='justify-start items-center' style={{ display: 'flex', marginBottom: '20px' }}>
                                    <img src={selectedSource.logo} alt={selectedSource.label} style={{ width: selectedSource.label == 'Trust Radius' ? 100 : 40, height: 40, marginRight: 8 }} />
                                    <span className='text-[25px]'>{'Connect to ' + selectedSource.label}</span>
                                </div>
                                {isConnecting ? (
                                    <>
                                        {steps.map((step, index) => (
                                            <div key={index} style={{ marginBottom: 10 }}>
                                                <Progress
                                                    percent={index < currentStep ? 100 : 0}
                                                    showInfo={false}
                                                    status={index < currentStep ? 'success' : 'normal'}
                                                />
                                                <span style={{ marginLeft: 10 }}>{step}</span>
                                            </div>
                                        ))}
                                        {isCompleted && (
                                            <Button type='primary' onClick={() => setIsModalOpen(false)}>Completed</Button>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div style={{ width: '80%', marginBottom: '20px' }}>
                                            <h3 className='text-[grey]'>Step 1</h3>
                                            <p><strong>Configure your credentials</strong></p>
                                            <Input placeholder="Credentials" className="mb-4 mt-2" />
                                            <Button>Refresh</Button>
                                            <Button type="primary" style={{ marginLeft: '10px' }}>New credentials</Button>
                                        </div>
                                        <div style={{ width: '80%' }}>
                                            <h3 className='text-[grey]'>Step 2</h3>
                                            <p className='mb-4'><strong>Configure your {selectedSource.label} destination</strong></p>
                                            <h3 style={{ fontWeight: 600 }}>Project</h3>
                                            <p className='text-[12px] text-[grey]'>This is the unique identifier for the Google Cloud project to which your BigQuery data warehouse belongs. The project ID can be found in the Google Cloud web console by clicking on your project name in the top navigation bar or by visiting the project settings page. Visit our docs for details.</p>
                                            <Input placeholder="Project ID" className="mb-4 mt-2" />
                                            <h3 style={{ fontWeight: 600 }}>Dataset location</h3>
                                            <p className='text-[12px] text-[grey]'>This is the geographic location of your BigQuery dataset(s). It can be found by querying the INFORMATION_SCHEMA.SCHEMATA view or by visiting the Google Cloud web console and clicking on a BigQuery dataset in the Explorer panel. For details, see BigQuery docs.</p>
                                            <Input placeholder="Dataset location" className="mb-4 mt-2" />
                                        </div>
                                        <div style={{ width: '80%' }}>
                                            <h3 className='text-[grey]'>Step 3</h3>
                                            <p className='mb-4'><strong>Choose your sync engine</strong></p>
                                            <h3 style={{ fontWeight: 600 }}>Lightning sync engine</h3>
                                            <p className='text-[12px] text-[grey]'>This option provides optimal performance for {selectedSource.label} and other enhanced platform features.</p>
                                            <Input placeholder="Lightning sync engine configuration" className="mb-4 mt-2" />
                                        </div>
                                        <div className='flex justify-end gap-4 my-2'>
                                            <Button onClick={() => setSelectedSource(null)}>Cancel</Button>
                                            <Button type='primary' onClick={handleConnect}>Connect</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="grid grid-cols-3 gap-4">
                                {sourceOptions.map((source) => (
                                    <Card
                                        key={source.value}
                                        onClick={() => handleSourceCardClick(source)}
                                        hoverable
                                        style={{ textAlign: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center', gap: 2, backgroundColor: '#f3f3f3' }}
                                    >
                                        <div className='flex justify-center items-center'>
                                            <img src={source.logo} alt={source.label} style={{ width: source.label == 'Trust Radius' ? 60 : 40, height: source.label == 'Trust Radius' ? 30 : 40, marginRight: 8 }} />
                                            <span className='font-bold text-black text-[15px]'>{source.label}</span>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Sources_part2 source={selectedModel} />
                </>
            )}
        </div>
    );
};

export default Destination;
