import React from "react";
import { Select, Checkbox } from "antd";

const CompanyMain = ({ data }) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const options = [
    { label: "Company Name", value: "Company Name" },
    { label: "Country", value: "Country" },
    { label: "Industry", value: "Industry" },
    { label: "Annual Revenue", value: "Annual Revenue" },
    { label: "First Name", value: "First Name" },
    { label: "Last Name", value: "Last Name" },
    { label: "Job Title", value: "Job Title" },
    { label: "Linkedin URL", value: "Linkedin URL" },
  ];
  const outputOptions = [
    { label: "Email", value: "Email" },
    { label: "Domain Name", value: "Domain Name" },
    { label: "Create Date", value: "Create Date" },
    { label: "Currently in Workflow", value: "Currently in Workflow" },
    { label: "Days to Close", value: "Days to Close" },
    { label: "Deliverability", value: "Deliverability" },
    { label: "First Deal Created Date", value: "First Deal Created Date" },
    { label: "First Inbound Interaction Date", value: "First Inbound Interaction Date" },
    { label: "First Meeting Date", value: "First Meeting Date" },
  ];
  const listOptions = [
    { label: "Advertising: Demo Requests & Contact Form", value: "Advertising: Demo Requests & Contact Form" },
    { label: "21 April Intent List", value: "21 April Intent List" },
    { label: "ALL MASTER EMIAL-ABLE CONTACTS", value: "ALL MASTER EMIAL-ABLE CONTACTS" },
    { label: "Advertising Leads", value: "Advertising Leads" },
    { label: "All Contacts 07/01/2020 to 09/30/2020 - Created 10/21/2020", value: "All Contacts07/01/2020 to 09/30/2020 - Created 10/21/2020" },
    { label: "All Contacts 11/01/2020 to 11/16/2020 - Created 11/16/2020", value: "All Contacts 11/01/2020 to 11/16/2020 - Created 11/16/2020" },
    { label: "All Contacts 12/01/2020 to 12/31/2020 - Created 01/05/2021", value: "All Contacts 12/01/2020 to 12/31/2020 - Created 01/05/2021" }
  ];
  const proOptions = [
    { label: "Apollo", value: "Apollo" },
    { label: "Proxycurl", value: "Proxycurl" },
    { label: "SimilarWeb", value: "SimilarWeb" },
  ];
  return (
    <div className="flex flex-col w-full gap-4 text-[12px] my-4 p-2">
      <div className="w-full">
        <p>{data?.data?.firstKey}</p>
        {data?.data?.firstKey === "Domain Name" ? (
          <input
            type="text"
            defaultValue={data?.data?.firstVal}
            className="w-full"
          />
        ) : (
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
              fontSize: "12px",
            }}
            placeholder="Please select"
            defaultValue={data?.data?.firstKey === "Outputs" ? ["Email"] : ["Domain Name"]}
            onChange={handleChange}
            options={outputOptions}
          />
        )}
        <hr />
      </div>
      {data?.data?.firstKey === "Outputs" && (
        <Checkbox checked onChange={onChange}>Use List?</Checkbox>
      )}
      {data?.data?.firstKey !== "Outputs" ? (
        <div>
          <p className="text-[12px]">{data?.data?.firstKey === "Inputs" ? "Contact" : "Company"} Information</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
              fontSize: "12px",
            }}
            placeholder="Please select"
            defaultValue={data?.data?.firstKey === "Inputs" ? ["First Name", "Last Name", "Job Title", "Linkedin URL"] : ["Company Name", "Industry", "Country", "Annual Revenue"]}
            onChange={handleChange}
            options={options}
          />
          <hr className="mt-1"/>
        </div>
      ) : (
        <div>
          <p className="text-[12px]">List</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
              fontSize: "12px",
            }}
            placeholder="Please select"
            defaultValue={["Advertising: Demo Requests & Contact Form"]}
            onChange={handleChange}
            options={listOptions}
          />
          <hr className="mt-1"/>
        </div>
      )}
      {data?.data?.firstKey !== "Outputs" && (
        <div>
          <p className="text-[12px]">Providers</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
              fontSize: "12px"
            }}
            placeholder="Please select"
            defaultValue={["Apollo", "Proxycurl", "SimilarWeb"]}
            onChange={handleChange}
            options={proOptions}
          />
          <hr className="mt-1"/>
        </div>
      )}
    </div>
  );
};

export default CompanyMain;
