import React from "react";
import { Input, Table } from "antd";

import snowflake from "../../assets/images/snowflakeImg.png";
import { useSetRecoilState } from "recoil";
import { activeSegmentAtom } from "../../store";

const { Search } = Input;

const columns = [
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "TYPE",
    dataIndex: "type",
    render: ({ image, type }) => (
      <div className="flex justify-start items-center gap-1">
        <img src={image} alt={type} width={14} />
        <p>{type}</p>
      </div>
    ),
  },
  {
    title: "LABELS",
    dataIndex: "labels",
    render: () => (
      <div className="flex justify-start items-center gap-1">--</div>
    ),
  },
  {
    title: "LAST UPDATED",
    dataIndex: "lastUpdated",
    render: ({ lastUpdated, createdBy }) => (
      <div className="flex justify-start items-center gap-1">
        <p>{lastUpdated}</p>
        <div className="bg-gray-200 rounded-full py-0.5 px-1">{createdBy}</div>
      </div>
    ),
  },
];
const data = [
  {
    key: "1",
    name: "Users",
    type: { image: snowflake, type: "Users" },
    lastUpdated: { lastUpdated: "04/11/24", createdBy: "CE" },
  },
  {
    key: "2",
    name: "Accounts",
    type: { image: snowflake, type: "Accounts" },
    lastUpdated: { lastUpdated: "03/2/24", createdBy: "CE" },
  },
];

const SelectModel = () => {
  const setActiveSegment = useSetRecoilState(activeSegmentAtom)

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const handleClick = ({ name }) => {
    console.log(name);
    if (name === "Users") {
        setActiveSegment(1)
    }
  };

  return (
    <>
      <div className="h4_Medium text-primary px-32 py-4">
        Select a Parent Model
      </div>
      <Search
        placeholder="Search by name..."
        onSearch={onSearch}
        className="extendSearch px-36 mb-4"
        allowClear
      />
      <div className="px-36 flex flex-col justify-center gap-4">
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleClick(record),
            };
          }}
          pagination={false}
          columns={columns}
          dataSource={data}
          className="cursor-pointer"
        />
      </div>
    </>
  );
};

export default SelectModel;
