import { atom } from "recoil";

const gumloopRowsAtom = atom({
  key: "gumloopRows",
  default: 1,
});

const gumloopInputFilledAtom = atom({
  key: "gumloopInputFilled",
  default: false,
});

const gumloopQuestionAtom = atom({
  key: "gumloopQuestion",
  default: "",
});

const gumloopChatArrayAtom = atom({
  key: "gumloopChatArray",
  default: [
    {
      answer:
        "I see you have selected the Lead Research Template. Would you like help setting it up?"
    },
  ],
});

const gumloopLoadingAtom = atom({
  key: "gumloopLoading",
  default: false,
});

const focusNodeIdAtom = atom({
    key: "focusNodeId",
    default: null
})

const newNodeAtom = atom({
    key: "newNode",
    default: false
})

const contactAtom = atom({
    key: "contact",
    default: false
})

const slackAtom = atom({
    key: "slack",
    default: false
})

const fitEntireAtom = atom({
    key: "fitEntire",
    default: false
})

const testLoadingAtom = atom({
    key: "testLoading",
    default: false
})

const deployAtom = atom({
    key: "deploy",
    default: false
})

const collapsibleAtom = atom({
  key: "collapsible",
  default: false
})

const leadCollapsibleAtom = atom({
  key: "leadCollapsible",
  default: false
})

const btnLoadingAtom = atom({
  key: "btnLoading",
  default: false
})

const btnClickedAtom = atom({
  key: "btnClicked",
  default: false
})

const nodeInProgressAtom = atom({
  key: "nodeInProgress",
  default: null
})

const activeIndexAtom = atom({
  key: "activeIndex",
  default: 0
})

const visibleProcessesAtom = atom({
  key: "visibleProcesses",
  default: [0]
})

const agentValAtom = atom({
  key: "agentVal",
  default: {
    name: "",
    des: ""
  }
}) 

const selectedGalleryAtom = atom({
  key: "selectedGallery",
  default: 0
})

const selectedGalleryCategoryAtom = atom ({
  key:"selectedGalleryCategory",
  default: 0
})

export {
  gumloopRowsAtom,
  gumloopInputFilledAtom,
  gumloopQuestionAtom,
  gumloopChatArrayAtom,
  gumloopLoadingAtom,
  focusNodeIdAtom,
  newNodeAtom,
  contactAtom,
  slackAtom,
  fitEntireAtom,
  testLoadingAtom,
  deployAtom,
  collapsibleAtom,
  btnLoadingAtom,
  btnClickedAtom,
  nodeInProgressAtom,
  activeIndexAtom,
  visibleProcessesAtom,
  agentValAtom,
  selectedGalleryAtom,
  selectedGalleryCategoryAtom
};
