import React from "react";
import collapseImg from "../../assets/images/collapseImg.png";
import expandImg from "../../assets/images/expandImg.png";
import { enrichNodeInProgressAtom } from "../../store";
import { useRecoilValue } from "recoil";
import LoaderComponent from "../LoaderComponent.js";

const EnrichmentHead = ({ data, collapse, setCollapse }) => {
  // console.log(data);
  const enrichNodeInProgress = useRecoilValue(enrichNodeInProgressAtom);
  return (
    <div
      className={`${
        data?.data?.bgColor === "peach"
          ? "bg-[#ffe8d9]"
          : data?.data?.bgColor === "grey"
          ? "bg-[#D4DCE5]"
          : data?.data?.bgColor === "blue"
          ? "bg-[#C3E6FA]"
          : data?.data?.bgColor === "pink"
          ? "bg-[#FED5D2]"
          : "bg-[#DEF8CF]"
      } w-full flex justify-between items-center p-2`}
    >
      <div className="flex justify-start items-center w-full gap-2">
        <img src={data?.data?.icon} alt={`${data?.data?.label}`} width={data?.id === "node-13" ? 24 : 20} />
        <div className="flex flex-col justify-center items-start text-[12px]">
          <p>{data?.data?.label}</p>
          {data?.id === "node-15" ? 
            <p>Through Web Scraping</p>
            :
            ["node-16", "node-13"].includes(data?.id) ?
            <p>Through LinkedIn</p>
            : <></>
          }
          <p className="text-[10px]">v0.01</p>
        </div>
        {enrichNodeInProgress === data?.id && (
          <LoaderComponent />
        )}
      </div>
      {["node-17", "node-18"].includes(data?.id) && (
        !collapse ? (
            <img src={collapseImg} alt="collapse" width={14} className="cursor-pointer hover:scale-110 duration-300 ease-in-out" onClick={() => setCollapse(!collapse)} />
        ) : (
          <img src={expandImg} alt="expand" width={18} className="cursor-pointer hover:scale-110 duration-300 ease-in-out" onClick={() => setCollapse(!collapse)} />
        )
      )}
    </div>
  );
};

export default EnrichmentHead;
