import React, { useState } from 'react';
import { Menu, Card, Button, Input } from 'antd';
import { UserOutlined, TeamOutlined } from '@ant-design/icons';

const NavigationComponent = () => {
  const [newFolderVisible, setNewFolderVisible] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [folders, setFolders] = useState([]);

  const handleNewFolderClick = () => {
    setNewFolderVisible(!newFolderVisible);
  };

  const handleAddFolder = () => {
    if (folderName) {
      setFolders([...folders, folderName]);
      setFolderName('');
      setNewFolderVisible(false);
    }
  };

  return (
    <div className='ml-4'>
      <Card className=''>
        <Menu mode="vertical">
          <Menu.Item key="audiences" icon={<UserOutlined />} style={{ fontSize: '17px' }}>
            Audiences
          </Menu.Item>
          <Menu.SubMenu key="segments" icon={<TeamOutlined />} title="Segments" style={{ fontSize: '17px' }}>
            <Menu.Item key="by-channel" style={{ fontSize: '15px' }}>By Channel</Menu.Item>
            <Menu.Item key="by-lifecycle" style={{ fontSize: '15px' }}>By Lifecycle</Menu.Item>
            <Menu.Item key="by-campaign" style={{ fontSize: '15px' }}>By Campaign</Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="new_folder" style={{ fontSize: '17px' }} title={
            <div onClick={handleNewFolderClick}>
              + New Folder
            </div>
          }>
            {newFolderVisible && (
              <div style={{ padding: '10px' }}>
                <div className='flex justify-between gap-2'>
                    <Input
                    placeholder="Folder Name"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    style={{ marginBottom: '10px', width: '45%' }}
                    />
                    <Button type='primary' onClick={handleAddFolder}>
                    Add Folder
                    </Button>
                </div>
                <Menu.Item key="all-audiences" style={{ fontSize: '15px' }}>All Audiences</Menu.Item>
                <Menu.Item key="marketing-campaigns" style={{ fontSize: '15px' }}>Marketing Campaigns</Menu.Item>
                <Menu.Item key="summer-sale-2024" style={{ fontSize: '15px', marginLeft: '20px' }}>Summer Sale 2024</Menu.Item>
                <Menu.Item key="new-product-launch" style={{ fontSize: '15px', marginLeft: '20px' }}>New Product Launch</Menu.Item>
                <Menu.Item key="customer-segments" style={{ fontSize: '15px' }}>Customer Segments</Menu.Item>
                <Menu.Item key="behavioral" style={{ fontSize: '15px' }}>Behavioral</Menu.Item>
              </div>
            )}
          </Menu.SubMenu>
        </Menu>
      </Card>
    </div>
  );
};

export default NavigationComponent;
