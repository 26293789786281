import React, { useState } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import sheetImage from '../../assets/images/sheets copy.png'

const SheetSelector = () => {
  const [sheetName, setSheetName] = useState("Bad Apples");
  const [writerMode, setWriterMode] = useState("Add New Rows");
  
  const sheetOptions = ["Bad Apples", "Good Apples", "New Sheet"];
  const writerModeOptions = ["Add New Rows", "Overwrite Existing Rows"];

  return (
    <div className="p-4 border rounded-md bg-white">
      <div className="flex items-center gap-2 mb-4">
        <span className="text-sm">Select Sheet:</span>
        <Tooltip title="Choose your Google Sheet">
          <Checkbox className="flex items-center justify-center" checked/>
        </Tooltip>
        <div className="bg-gray-200 p-2 rounded-full">
          <img src={sheetImage} alt="Google Sheet Icon" className="h-6 w-6" />
        </div>
        <span>Untitled spreadsheet</span>
        <Tooltip title="View in Google Sheets">
          <a href="#">
            <InfoCircleOutlined />
          </a>
        </Tooltip>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <Checkbox className="flex items-center justify-center" />
        <span className="text-sm">Use Link</span>
        <Tooltip title="Provide a link to your Google Sheet">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <span className="text-sm">Sheet Name:</span>
        <select
          className="border rounded p-2 bg-white"
          value={sheetName}
          onChange={(e) => setSheetName(e.target.value)}
        >
          {sheetOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <ReloadOutlined className="cursor-pointer" onClick={() => window.location.reload()} />
      </div>
      <div className="flex items-center gap-2 mb-4">
        <span className="text-sm">Writer Mode:</span>
        <select
          className="border rounded p-2 bg-white"
          value={writerMode}
          onChange={(e) => setWriterMode(e.target.value)}
        >
          {writerModeOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SheetSelector;