import React, { useEffect } from 'react';
import Chat from '../../components/CopilotComponents/Chat.js';
import { activeCopilotCompAtom, activeSessionAtom, assistantFocusAtom, copilotChatArrayAtom, extendedChatAtom, fileListAtom, reportListAtom } from "../../store";
import { useRecoilState } from "recoil";
import Dashboard from "../../components/Dashboard/Dashboard.js";
import { chatModeAtom, sideNavContentAtom } from '../../store/atoms/MainAtoms.js';
import Files from '../../components/CopilotComponents/Files.js';
import Reports from '../../components/CopilotComponents/Reports.js';
import AssistantMain from '../../components/AssistantComponent/AssistantMain/AssistantMain.js';
import { useLocation } from 'react-router-dom';
import { chatHistory } from '../../services/chatServices.js';
import { jwtDecode } from 'jwt-decode';
import { message } from 'antd';

const Copilot = () => {
  const [activeCopilotComp, setActiveCopilotComp] = useRecoilState(activeCopilotCompAtom);
  const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
  const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);
  const [extendedChat, setExtendedChat] = useRecoilState(extendedChatAtom);
  const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
  const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom);
  const [assistantFocus, setAssistantFocus] = useRecoilState(assistantFocusAtom);
  const [fileList, setFileList] = useRecoilState(fileListAtom);
  const [reportList, setReportList] = useRecoilState(reportListAtom);
  const { pathname, search } = useLocation(); // Get query params from URL

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(search);
  const sessionId = queryParams.get('session_id');
  const queryId = queryParams.get('query_id');

  useEffect(() => {
    const initializeSession = async () => {
      if (sessionId) {
        await handleSession(sessionId);
      }
      // if (queryId) {
      //   handleQueryId(queryId);
      // }
    };

    if (sessionId || queryId) {
      initializeSession();
    } else {
      // Normal useEffect behavior
      setActiveCopilotComp(0);
      setChatMode(0);
      setSideNavContent(0);
      setExtendedChat(false);
      setChatArray([
        {
          answer:
            "Hi this is ADA, your personal assistant. How can I help you today?",
          suggestions: [
            "Filter companies in the technology sector with a revenue between $50 million and $100 million",
            "Show companies in the aerospace sector with more than 10,000 employees",
            "Show me the lost opportunities in previous quarter",
          ],
        },
      ]);
      localStorage.setItem("path", "assistant");
    }
  }, [sessionId, queryId]);

  const handleSession = async (sessionId) => {
    localStorage.removeItem("tableName");
    localStorage.setItem("session_id", sessionId);
    setActiveSession(sessionId);
    setAssistantFocus("master");
    setFileList([]);
    setReportList([]);
    message.loading("Getting the chat for you...");

    let response = await chatHistory({
      user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
      session_id: sessionId,
    });

    if (response.data.status === "success") {
      // Transform the data to rename the 'cleanedData' key to 'answer'
      const modifiedChatArray = response.data.data[0].chat_array.map(chat => {
        let modifiedChat = { ...chat };
        modifiedChat.answer = chat.cleanedData;
        modifiedChat.suggestions = chat.followUpQuestions;
        modifiedChat.visuals = true;
        modifiedChat.data = chat.pythonCode ? [chat.pythonCode] : null;
        modifiedChat.chartMessage = chat.graphAnswer ? chat.graphAnswer : null;
        modifiedChat.chartType = chat.chart_type ? chat.chart_type : "plotly";
        modifiedChat.query_id = chat.query_id;
        modifiedChat.postgres_query = chat.postgres_query ? chat.postgres_query : `SELECT * FROM insighttables.new_table_${chat.query_id.replace(/-/g, '_')}`;
        modifiedChat.topic = chat.topic;
        modifiedChat.newTable = chat.newTable ? chat.newTable : null;
        modifiedChat.onlySummary = chat.onlySummary ? chat.onlySummary : false;
        modifiedChat.feedback = chat.feedback;
        modifiedChat.is_sql_error = chat.is_sql_error ? chat.is_sql_error : false;
        delete modifiedChat.cleanedData;
        return modifiedChat;
      });

      setChatArray(modifiedChatArray);
    }
  };

  // const handleQueryId = (query_id) => {
  //   localStorage.setItem("extended_query_id", query_id);
  //   setSideNavContent(1);
  //   setChatMode(1);
  //   setExtendedChat(true);
  // };

  const homeTabs = [
    {
      name: "Chat",
      component: pathname === '/assistant' ? <AssistantMain /> : <Chat />,
    },
    {
      name: "Files",
      component: <Files />,
    },
    {
      name: "Reports",
      component: <Reports />,
    },
    {
      name: "Dashboard",
      component: <Dashboard />,
    },
  ];

  const handleTabClick = (index) => {
    setActiveCopilotComp(index);
    if (homeTabs[index].name === "Chat") {
      setChatMode(0);
      setSideNavContent(0);
    } else {
      setChatMode(1);
      setSideNavContent(1);
    }
  };

  return (
    <div className='flex flex-col h-[90vh] w-full p-2'>
      <div className="flex justify-start items-center pl-1 pr-4 pt-2 border-b border-gray-300">
        {homeTabs.map((tab, index) => (
          <div
            key={index}
            className={`${activeCopilotComp === index
              ? "text-[#2966BC] border-b-2 border-[#2966BC]"
              : "text-gray-600"
              } px-4 py-2 text-sm cursor-pointer`}
            onClick={() => handleTabClick(index)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="flex-1 p-4">
        {homeTabs[activeCopilotComp].component}
      </div>
    </div>
  );
};

export default Copilot;
