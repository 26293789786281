import { Button, Checkbox, Input } from "antd";
import React, { useState, useEffect } from "react";
import {
    InfoCircleFilled
  } from "@ant-design/icons";

const DeduplicateOption = () => {

  return (
    <div className='flex flex-col h-[30vh] w-full p-2'>
      <div className="flex text-[16px]" style={{fontWeight: 600}}>
          Deduplicate
      </div>
        <div className="flex border mt-4 justify-center items-center gap-2 p-2">  
        AI Deduplicate and Merge Duplicate Rows
        <InfoCircleFilled />
        </div>
        <div className="flex border mt-4 justify-center items-center gap-2 p-2">  
        AI Deduplicate and Merge Duplicate Rows 
        <InfoCircleFilled />
        </div>
        <div className="flex border mt-4 justify-center items-center gap-2 p-2">  
        AI Deduplicate and Merge Duplicate Rows
        <InfoCircleFilled />
        </div>
    </div>
  );
};

export default DeduplicateOption;
