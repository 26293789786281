import React, { useState } from "react";
import { Checkbox, Divider, Input } from "antd";
import {
  cc_magicAtom,
  copilotChatArrayAtom,
  copilotQuestionAtom,
  copilotLoadingAtom,
  cc_tableDataAtom,
  cc_tableColsAtom
} from "../../store";
import { useSetRecoilState } from "recoil";
const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  "Gender",
  "Job Title",
  "Linkedin URL",
  "Salary",
  "Age",
  "Postal Code",
  "Education",
];
const defaultCheckedList = [];
const { Search } = Input;

export const GlitterDropdown = () => {
  const setChatArray = useSetRecoilState(copilotChatArrayAtom);
  const setLoading = useSetRecoilState(copilotLoadingAtom);
  const setPocTableData = useSetRecoilState(cc_tableDataAtom);
  const setQuestion = useSetRecoilState(copilotQuestionAtom);
  const setTable = useSetRecoilState(cc_tableColsAtom);

  const setMagic = useSetRecoilState(cc_magicAtom);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;
  const onChange = (list) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };
  const handleEnrich = () => {
    setMagic(false);
    setChatArray((prevChatArray) => [
      ...prevChatArray,
      {
        question:
          "Add Gender, Job Title, Linkedin URL, Salary, Age, Postal Code & Education fields",
        answer: "Adding these fields in data",
        pocref: true
      },
    ]);
    setLoading(true);
    setTimeout(() => {
      setChatArray((prevChatArray) => {
        const newChat = [...prevChatArray];
        newChat[newChat.length - 1] = {
          ...newChat[newChat.length - 1],
          answer: "Fields added to CSV data. How would you like to proceed?",
          csvBtns: [
            "Tag Contacts",
            "Append to Campaign",
            "Research Unmatched Contacts",
          ],
        };
        return newChat;
      });
      setLoading(false);
      setTable([
        {
          title: 'Event Name',
          dataIndex: 'event_name',
          key: 'event_name'
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date'
        },
        {
          title: 'Attendee Email',
          dataIndex: 'attendee_email',
          key: 'attendee_email'
        },
        {
          title: 'First Name',
          dataIndex: 'first_name',
          key: 'first_name'
        },
        {
          title: 'Last Name',
          dataIndex: 'last_name',
          key: 'last_name'
        },
        {
          title: 'Country',
          dataIndex: 'country',
          key: 'country'
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'gender'
        },
        {
          title: 'Job Title',
          dataIndex: 'job_title',
          key: 'job_title'
        },
        {
          title: 'Social Profile URL',
          dataIndex: 'social_profile_url',
          key: 'social_profile_url',
        },
        {
          title: 'Inferred Salary',
          dataIndex: 'inferred_salary',
          key: 'inferred_salary',
        },
        {
          title: 'Age',
          dataIndex: 'age',
          key: 'age'
        },
        {
          title: 'Postal Code',
          dataIndex: 'postal_code',
          key: 'postal_code'
        },
        {
          title: 'Education Level',
          dataIndex: 'education_level',
          key: 'education_level'
        },
        {
          title: 'Campaign ID',
          dataIndex: 'existing id',
          key: 'existing id'
        }
      ])
      setPocTableData([
        {
          key: 1,
          event_name: "Regular Webinar",
          date: "6/15/2024",
          attendee_email: "aileenstratford@gmail.com",
          first_name: "Aileen",
          last_name: "Stratford",
          country: "United States of America",
          gender: "male",
          job_title: "Farm Manager",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAMRbyYBUaT-JwqY1dhSTyryHfF4480ymPE",
          inferred_salary: "$60,000 - $120,000",
          age: 31,
          postal_code: 33109,
          education_level: "Graduation",
          "existing id": "sf8756565",
        },
        {
          key: 2,
          event_name: "Regular Webinar",
          date: "6/16/2024",
          attendee_email: "jonpjonesmts57@gmail.com",
          first_name: "Jonathon",
          last_name: "Jones",
          country: "United States of America",
          gender: "male",
          job_title: "Specialist - Safety",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAC3kBdYBiCtPF11NnGNRiRNL8X4Zbgm3eVU",
          inferred_salary: "$60,000 - $80,000",
          age: 22,
          postal_code: 40165,
          education_level: "Graduation",
          "existing id": "sf8756524",
        },
        {
          key: 3,
          event_name: "Regular Webinar",
          date: "6/16/2024",
          attendee_email: "tayrolle77@gmail.com",
          first_name: "Shawnta",
          last_name: "Rolle",
          country: "United States of America",
          gender: "Female",
          job_title: "Student",
          social_profile_url:
            "https://www.linkedin.com/in/shawnta-rolle-694aa81ab",
          inferred_salary: "$30,000 - $40,000",
          age: 24,
          postal_code: 32757,
          education_level: "Graduation",
          "existing id": "sf8756483",
        },
        {
          key: 4,
          event_name: "Regular Webinar",
          date: "6/18/2024",
          attendee_email: "rebeccacolella65@gmail.com",
          first_name: "Rebecca",
          last_name: "Colella",
          country: "United States of America",
          gender: "female",
          job_title: "Office Assistant",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAABisWbUBU2J37VjjlW8HoJqA7HTs2kc1TPo",
          inferred_salary: "$35,000 - $50,000",
          age: 37,
          postal_code: "06701",
          education_level: "Graduation",
          "existing id": "sf8756442",
        },
        {
          key: 5,
          event_name: "Regular Webinar",
          date: "6/19/2024",
          attendee_email: "taylorcouch19@gmail.com",
          first_name: "Taylor",
          last_name: "Couch",
          country: "United States of America",
          gender: "male",
          job_title: "Student Staff",
          social_profile_url: "https://www.linkedin.com/in/tlcouch",
          inferred_salary: "$30,000 - $40,000",
          age: 25,
          postal_code: 77840,
          education_level: "Graduation",
          "existing id": "sf8756401",
        },
        {
          key: 6,
          event_name: "Regular Webinar",
          date: "20/06/2024",
          attendee_email: "llgarcia_1@yahoo.com",
          first_name: "Lydia",
          last_name: "Garcia",
          country: "United States of America",
          gender: "female",
          job_title: "Teacher'S Aide",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAg9GfUBCbQa2xRs99bP35lv3CXNfgkHTlM",
          inferred_salary: "$30,000 - $40,000",
          age: 29,
          postal_code: 87101,
          education_level: "Graduation",
          "existing id": "sf8756570",
        },
        {
          key: 7,
          event_name: "Regular Webinar",
          date: "6/21/2024",
          attendee_email: "momsadams@hotmail.com",
          first_name: "Sue",
          last_name: "Adams",
          country: "United States of America",
          gender: "female",
          job_title: "Teachers Aide",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAcgtScBPeOdZ1OrIpSCJV2vvwGCO0gA-Ns",
          inferred_salary: "$30,000 - $40,000",
          age: 29,
          postal_code: 59715,
          education_level: "Graduation",
          "existing id": "sf8756571",
        },
        {
          key: 8,
          event_name: "Regular Webinar",
          date: "6/22/2024",
          attendee_email: "dgonzales@valleywater.org",
          first_name: "David",
          last_name: "Gonzales",
          country: "United States of America",
          gender: "male",
          job_title: "Water Conservation Intern",
          social_profile_url:
            "https://www.linkedin.com/in/ACwAADoLIYUB_crJZ5U-13m0kqtRik8aMJ2diCE",
          inferred_salary: "$30,000 - $40,000",
          age: 22,
          postal_code: 94704,
          education_level: "Graduation",
          "existing id": "sf8756572",
        },
        {
          key: 9,
          event_name: "On-Demand Webinar",
          date: "6/23/2024",
          attendee_email: "woodypike@gmail.com",
          first_name: "Timothy",
          last_name: "Payne",
          country: "United States of America",
          gender: "male",
          job_title: "Senior Programmer",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAvDC8QB-aeicpijNCF7rKo6joKaz6fqq74",
          inferred_salary: "$90,000 - $120,000",
          age: 58,
          postal_code: 92054,
          education_level: "Graduation",
          "existing id": "sf8756573",
        },
        {
          key: 10,
          event_name: "On-Demand Webinar",
          date: "6/24/2024",
          attendee_email: "hdgirl1@aol.com",
          first_name: "Adrienne",
          last_name: "Heisey",
          country: "United States of America",
          gender: "female",
          job_title: "Assistant - Medical",
          social_profile_url:
            "https://www.linkedin.com/in/adrienne-heisey-525556185",
          inferred_salary: "$35,000 - $50,000",
          age: 50,
          postal_code: 19508,
          education_level: "Graduation",
          "existing id": "sf8756567",
        },
        {
          key: 11,
          event_name: "On-Demand Webinar",
          date: "6/25/2024",
          attendee_email: "nathan.petrucci@gmail.com",
          first_name: "Nathan",
          last_name: "Petrucci",
          country: "United States of America",
          gender: "male",
          job_title: "Biomedical Engineering Intern",
          social_profile_url:
            "https://www.linkedin.com/in/ACwAAD5iRAEB4OB7mnS9USu3gDRZLalSvS9p75E",
          inferred_salary: "$40,000 - $60,000",
          age: 25,
          postal_code: 53005,
          education_level: "Graduation",
          "existing id": "sf8756575",
        },
        {
          key: 12,
          event_name: "On-Demand Webinar",
          date: "6/26/2024",
          attendee_email: "meredith.needham14@gmail.com",
          first_name: "Meredith",
          last_name: "Needham",
          country: "United States of America",
          gender: "female",
          job_title: "Certified Occupational Therapy Assistant",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAADlzc0QBpGbDmTeyg42acbIr_o7Oc-SOK3k",
          inferred_salary: "$50,000 - $75,000",
          age: 27,
          postal_code: 49014,
          education_level: "Graduation",
          "existing id": "sf8756576",
        },
        {
          key: 13,
          event_name: "On-Demand Webinar",
          date: "6/26/2024",
          attendee_email: "emmaadavis5@gmail.com",
          first_name: "Emma",
          last_name: "Davis",
          country: "United States of America",
          gender: "female",
          job_title: "Registered Nursing/Registered Nurse",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAACl5PkIBTRHtDAZB5KbcDhEQZvdfZDqIjHU",
          inferred_salary: "$70,000 - $90,000",
          age: 27,
          postal_code: 64101,
          education_level: "Graduation",
          "existing id": "sf8756577",
        },
        {
          key: 14,
          event_name: "On-Demand Webinar",
          date: "6/28/2024",
          attendee_email: "tgoldy92@gmail.com",
          first_name: "Tyler",
          last_name: "Goldy",
          country: "United States of America",
          gender: "male",
          job_title: "Cook",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAACYWajMBCxw4mRNFQCtyFHl9AaXSRyI3jvg",
          inferred_salary: "$30,000 - $40,000",
          age: 25,
          postal_code: 85001,
          education_level: "Graduation",
          "existing id": "sf8756578",
        },
        {
          key: 15,
          event_name: "On-Demand Webinar",
          date: "6/29/2024",
          attendee_email: "lsilva@dirtt.com",
          first_name: "Lisa",
          last_name: "Silva",
          country: "United States of America",
          gender: "female",
          job_title: "Sales Representative",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAGYP3ABeqairdEenD4v0f2rpKFLlBXyFH4",
          inferred_salary: "$80,000 - $130,000",
          age: 35,
          postal_code: 30301,
          education_level: "Graduation",
          "existing id": "sf8756579",
        },
        {
          key: 16,
          event_name: "Workshop",
          date: "6/15/2024",
          attendee_email: "huangminzh@gmail.com",
          first_name: "Min",
          last_name: "Huang",
          country: "United States of America",
          gender:
            "https://www.linkedin.com/in/ACoAABx9pu8BfeEvq9JiusA88UvTm1Yx4DJ1hCw",
          job_title: "Systems and Infrastructure Engineer",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAABx9pu8BfeEvq9JiusA88UvTm1Yx4DJ1hCw",
          inferred_salary: "$90,000 - $140,000",
          age: 27,
          postal_code: 94085,
          education_level: "Master",
          "existing id": "sf8756580",
        },
        {
          key: 17,
          event_name: "Workshop",
          date: "6/16/2024",
          attendee_email: "matt.brian.harrison@gmail.com",
          first_name: "Matthew",
          last_name: "Bryan",
          country: "United Kingdom",
          gender: "male",
          job_title: "General Laborer",
          social_profile_url:
            "https://www.linkedin.com/in/ACwAADXgHiUB21du5sjejfAC1agLCNp_670YtGo",
          inferred_salary: "$25,000 - $40,000",
          age: 29,
          postal_code: "LE1",
          education_level: "Graduation",
          "existing id": "sf8756581",
        },
        {
          key: 18,
          event_name: "Workshop",
          date: "6/16/2024",
          attendee_email: "desertortoise@yahoo.com",
          first_name: "Gaylene",
          last_name: "Pheysey",
          country: "United States of America",
          gender: "female",
          job_title: "Homemaker",
          social_profile_url:
            "https://www.linkedin.com/in/gaylene-pheysey-449093a3",
          inferred_salary: "$20,000 - $40,000",
          age: 25,
          postal_code: 99301,
          education_level: "Graduation",
          "existing id": "sf8756582",
        },
        {
          key: 19,
          event_name: "Workshop",
          date: "6/18/2024",
          attendee_email: "haydengriffin1325@gmail.com",
          first_name: "Hayden",
          last_name: "Rumfield",
          country: "United States of America",
          gender: "female",
          job_title: "Lead - Development",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAADp0Kx8BOw-aiX1qPaedMj854t7XQfXhvvc",
          inferred_salary: "$100,000 - $160,000",
          age: 23,
          postal_code: 75201,
          education_level: "Graduation",
          "existing id": "sf8756583",
        },
        {
          key: 20,
          event_name: "Workshop",
          date: "6/19/2024",
          attendee_email: "chellet2226@yahoo.com",
          first_name: "Chelle",
          last_name: "Dunston",
          country: "United States of America",
          gender: "female",
          job_title: "TypeWell Transcriber",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAs9uvcBV6eQIUcPs6tYHGqXMSebJdue_H4",
          inferred_salary: "$40,000 - $60,000",
          age: 45,
          postal_code: 21201,
          education_level: "Graduation",
          "existing id": "sf8756584",
        },
        {
          key: 21,
          event_name: "Workshop",
          date: "6/20/2024",
          attendee_email: "gisela2683@hotmail.com",
          first_name: "Marlen",
          last_name: "Londono",
          country: "United States of America",
          gender: "female",
          job_title: "Máster Social Work",
          social_profile_url:
            "https://www.linkedin.com/in/marlen-londono-7a8b66249",
          inferred_salary: "$50,000 - $70,000",
          age: 26,
          postal_code: 33301,
          education_level: "Master",
          "existing id": "sf8756585",
        },
        {
          key: 22,
          event_name: "Workshop",
          date: "6/21/2024",
          attendee_email: "chrislbrown22@gmail.com",
          first_name: "Chris",
          last_name: "Brown",
          country: "United States of America",
          gender: "Male",
          job_title: "Design Engineer",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAABHUh9MBLe03kiBBTxDlfG2EaJXsydZnWX0",
          inferred_salary: "$80,000 - $130,000",
          age: 31,
          postal_code: 99352,
          education_level: "Graduation",
          "existing id": "sf8756586",
        },
        {
          key: 23,
          event_name: "Partner Event",
          date: "6/22/2024",
          attendee_email: "jason@eisenbergcompany.com",
          first_name: "Jason",
          last_name: "Eisenberg",
          country: "United States of America",
          gender: "male",
          job_title: "Broker",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAOGF3ABSx3dRiHClfne0qDa7ad6iYG0KMc",
          inferred_salary: "$70,000 - $120,000",
          age: 40,
          postal_code: 85001,
          education_level: "Graduation",
          "existing id": "sf8756587",
        },
        {
          key: 24,
          event_name: "Partner Event",
          date: "6/23/2024",
          attendee_email: "victoriadb1999@gmail.com",
          first_name: "Victoria",
          last_name: "Baker",
          country: "United States of America",
          gender: "female",
          job_title: "Resident Assistant",
          social_profile_url:
            "https://www.linkedin.com/in/victoria-baker-ab3223206",
          inferred_salary: "$30,000 - $40,000",
          age: 25,
          postal_code: 14478,
          education_level: "Graduation",
          "existing id": "sf8756588",
        },
        {
          key: 25,
          event_name: "Partner Event",
          date: "6/24/2024",
          attendee_email: "taylorhetherington2002@gmail.com",
          first_name: "Taylor",
          last_name: "Hetherington",
          country: "United States of America",
          gender: "male",
          job_title: "Restaurant Server",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAEpcS5cBy1SIewN2Fepma84LGiHLwLLkQ5A",
          inferred_salary: "$20,000 - $30,000",
          age: 23,
          postal_code: 21030,
          education_level: "Graduation",
          "existing id": "sf8756583",
        },
        {
          key: 26,
          event_name: "Partner Event",
          date: "6/25/2024",
          attendee_email: "ritzam@aol.com",
          first_name: "Ritza",
          last_name: "Melendez",
          country: "United States of America",
          gender: "Female",
          job_title: "Social Worker",
          social_profile_url:
            "https://www.linkedin.com/in/ritza-melendez-5981452aa",
          inferred_salary: "$50,000 - $70,000",
          age: 26,
          postal_code: 33101,
          education_level: "Master",
          "existing id": "sf8756582",
        },
        {
          key: 27,
          event_name: "Partner Event",
          date: "6/26/2024",
          attendee_email: "fdubaisy@hotmail.com",
          first_name: "Feras",
          last_name: "Aldubaisy",
          country: "Saudi Arabia",
          gender: "male",
          job_title: "Specialist - Logistics",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAACtDO-0B8kQ2ll_M_ffsc_WXFQHgyT1jLx0",
          inferred_salary: "$60,000 - $80,000",
          age: 25,
          postal_code: 31982,
          education_level: "Graduation",
          "existing id": "sf8756581",
        },
        {
          key: 28,
          event_name: "Partner Event",
          date: "6/26/2024",
          attendee_email: "coheneva5@gmail.com",
          first_name: "Eva",
          last_name: "Bouhadana Cohen",
          country: "United States of America",
          gender: "Female",
          job_title: "Licensed Real estate Broker Associate",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAaW1H0BCMynytNpE4g30NjPXWdFYBsZmgo",
          inferred_salary: "$60,000 - $90,000",
          age: 42,
          postal_code: 33101,
          education_level: "Graduation",
          "existing id": "sf8756580",
        },
        {
          key: 29,
          event_name: "Partner Event",
          date: "6/28/2024",
          attendee_email: "iramadan@gmail.com",
          first_name: "Ira",
          last_name: "Madan",
          country: "India",
          gender: "Female",
          job_title: "Technical Expert",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAB3iUUUBYM6fJlFnuvsc3SorK8k4YU47HbU",
          inferred_salary: "$100,000 - $130,000",
          age: 41,
          postal_code: 110017,
          education_level: "Master",
          "existing id": "sf8756520",
        },
        {
          key: 30,
          event_name: "Partner Event",
          date: "6/29/2024",
          attendee_email: "ncv@corcoran.com",
          first_name: "Nancy",
          last_name: "Van Bourgondien",
          country: "United States of America",
          gender: "Female",
          job_title: "Licensed Associate Real Estate Broker",
          social_profile_url:
            "https://www.linkedin.com/in/ACoAAAC7inkBXtbcF91Gsts2nbZVmKi5WT5s97A",
          inferred_salary: "$60,000 - $90,000",
          age: 42,
          postal_code: 10001,
          education_level: "Graduation",
          "existing id": "sf8756523",
        },
      ]);
    }, 3000);
    setQuestion(null);
  };
  return (
    <div className="flex flex-col gap-4 p-2">
      <Search
        placeholder="Search"
        onSearch={(value) => console.log(value)}
        allowClear
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      />
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Select All
      </Checkbox>
      <Divider className="my-1" />
      <CheckboxGroup
        options={plainOptions}
        value={checkedList}
        onChange={onChange}
        className="flex flex-col gap-2"
      />
      <div
        className="bg-[#2966BC] text-white hover:shadow-md cursor-pointer hover:scale-105 duration-300 ease-in-out text-center rounded-md"
        onClick={handleEnrich}
      >
        Add Enrichment Columns
      </div>
    </div>
  );
};
