// ModelsTable.js
import React, { useState, useEffect } from 'react';
import { Table, Button, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AccountFitScore from './AccountFitScore';
import { jwtDecode } from 'jwt-decode';

const modelsData_zillasecurity = [
    {
        key: '3',
        model: 'Active Customers by Industry Vertical',
        slug: 'active_customers_by_industry_vertical',
        query: `SELECT 
                    a.name, 
                    a.sum_of_won_arr__c, 
                    u.name, 
                    billingstate, 
                    TYPE, 
                    u.lastmodifieddate, 
                    a.numberofemployees,
                    a.billingstreet, 
                    COUNT(*) AS RowCount 
                FROM 
                    zillasecurity_master.salesforce_account as a
                JOIN 
                    zillasecurity_master.salesforce_user as u ON a.createdbyid = u.ID 
                WHERE 
                    TYPE = 'Customer' 
                GROUP BY 
                    a.name, 
                    a.sum_of_won_arr__c, 
                    u.name, 
                    billingstate, 
                    TYPE, 
                    u.lastmodifieddate, 
                    a.numberofemployees, 
                    a.billingstreet,
                        industry
                ORDER BY 
                    industry ASC;`,
        description: 'Displays active customers segmented by their specific industry vertical, such as Healthcare, Technology, or Retail.',
        syncs: '',
        createdBy: 'Ryan Burke',
        lastUpdated: '2024-05-17',
        source: 'Salesforce Accounts'
    },
    {
        key: '4',
        model: 'Banking & Finance Customers',
        slug: 'banking_and_finance_customers',
        query: `SELECT 
                    u.name AS user_name, 
                    a.name AS account_name, 
                    a.billingstate, 
                    a.type, 
                    a.lastmodifieddate 
                FROM 
                    zillasecurity_master.salesforce_account AS a 
                JOIN 
                    zillasecurity_master.salesforce_user AS u ON a.createdbyid = u.id 
                WHERE 
                    a.type = 'Customer' 
                    AND a.industry IN ('Banking', 'Finance', 'Financial Services')`,
        description: 'Provides details of active customers specifically within the Banking & Finance industry.',
        syncs: '',
        createdBy: 'Jason Goldberg',
        lastUpdated: '2024-05-07',
        source: 'Salesforce Accounts'
    },
    {
        key: '2',
        model: 'Active Customers by Size',
        slug: 'active_customers_by_size',
        query: `SELECT 
                    a.name AS ACCOUNT_NAME, 
                    u.name AS USER_NAME, 
                    a.billingstate, 
                    a.type, 
                    a.lastmodifieddate, 
                    a.billingstreet
                FROM 
                    zillasecurity_master.salesforce_account a
                JOIN 
                    zillasecurity_master.salesforce_user u ON a.createdbyid = u.id
                JOIN 
                    zillasecurity_master.salesforce_reports r ON r.ownerid = a.id
                WHERE 
                    a.type = 'Customer'
                ORDER BY 
                    a.numberofemployees ASC`,
        description: 'Lists active customers categorized by their company size, typically measured by revenue or employee count.',
        syncs: '',
        createdBy: 'Ryan Burke',
        lastUpdated: '2022-12-11',
        source: 'Salesforce Accounts'
    },
];

const ModelsTable = () => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [modelsData, setModelsData] = useState([]);

    const handleModelClick = (model) => {
        setSelectedModel(model);
    };

    const columns = [
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            render: (text, record) => (
                <Button type="link" onClick={() => handleModelClick(record)}>
                    {text}
                </Button>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Syncs',
            dataIndex: 'syncs',
            key: 'syncs',
        },
        {
            title: 'Created by',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (text) => (
                <div>
                    <UserOutlined /> {text}
                </div>
            ),
        },
        {
            title: 'Last updated',
            dataIndex: 'lastUpdated',
            key: 'lastUpdated',
        },
    ];

    useEffect(() => {
        let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
        if (organization === "talkable.com") {
            setModelsData([])
        } else if (organization === 'zillasecurity.com') {
            setModelsData(modelsData_zillasecurity)
        } else if (organization === 'icustomer.ai') {
            setModelsData([])
        }
        else {
            setModelsData([])
        }
    }, []);

    return (
        <div>
            {!selectedModel ? (
                <>
                    <div className='flex justify-between mb-2'>
                        <div className='flex justify-center gap-4'>
                            <Input placeholder={"Search"} className='w-[65%]' />
                            <Button>Source</Button>
                        </div>
                        <div className='flex justify-center gap-4'>
                            <Button>Delete</Button>
                            <Button>Add Model</Button>
                        </div>
                    </div>
                    <Table
                        dataSource={modelsData}
                        columns={columns}
                        pagination={false}
                    />
                </>
            ) : (
                <AccountFitScore model={selectedModel} onBack={() => setSelectedModel(null)} />
            )}
        </div>
    );
};

export default ModelsTable;
