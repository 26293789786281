import React, { useEffect } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { activeCompanyAtom, flashAtom } from "../../../store";
import { activeTabAtom, dropAtom } from "../../../store/atoms/AnalyticsAtoms.js";

const CompanyBoundNavbar = () => {
  const [activeCompany, setActiveCompany] = useRecoilState(activeCompanyAtom);
  const activeTab = useRecoilValue(activeTabAtom);
  const drop = useRecoilValue(dropAtom);
  const setFlash = useSetRecoilState(flashAtom);
  useEffect(() => {
    setActiveCompany(0);
  }, [activeTab, drop]);
  const handleLeftClick = () => {
    if (activeCompany === 0) {
      setActiveCompany(navbarItems.length - 1);
    } else {
      setActiveCompany(activeCompany - 1);
    }
    setFlash(true);
  };
  const handleRightClick = () => {
    if (activeCompany === navbarItems.length - 1) {
      setActiveCompany(0);
    } else {
      setActiveCompany(activeCompany + 1);
    }
    setFlash(true);
  };
  const tierArr = () => {
    const arr = ["All Segments"]
    for (let i = 1; i < 6; i++) {
      arr.push(`Tier ${i}`)
    }
    return arr;
  }
  const navbarItems = 
      activeTab === 1 ? [
        "All Channels",
        "Customer Webinar",
        "Linkedin",
        "Referral",
        "Webinar",
        "Website",
  ] : activeTab === 2 ? tierArr()
    : activeTab === 3 ? [
        "All Teams",
        "APAC",
        "Europe",
        "North America"
  ] : activeTab === 4 && drop === "Month" ? [
        "07/2023",
        "08/2023",
        "09/2023",
        "10/2023",
        "11/2023",
        "12/2023",
        "01/2024",
        "02/2024",
        "03/2024",
        "04/2024",
        "05/2024",
        "06/2024",
  ] : activeTab === 4 && drop === "Quarter" ? [
        "07/2023 - 09/2023",
        "10/2023 - 12/2023",
        "01/2024 - 03/2024",
        "04/2024 - 06/2024",
  ] : activeTab === 4 && drop === "Year" ? [
        "07/2023 - 06/2024"
  ] : [
        "All",
        "Yada"
  ];

  return (
    <div className="px-14 pt-6 flex justify-between items-center w-5/5 text-[13px] gap-4">
      <LeftOutlined className="text-[10px]" onClick={handleLeftClick} />
      {navbarItems.map((item, index) => (
        <div
          key={index}
          className={`border border-t-0 border-l-0 border-r-0 w-[20%] flex justify-center cursor-pointer duration-300 ease-in-out hover:border-[#73D297] ${
            activeCompany === index && "border-[#73D297] text-black scale-110 shadow-lg"
          }`}
          onClick={() => {
            setActiveCompany(index);
            setFlash(true);
          }}
        >
          {item}
        </div>
      ))}
      <RightOutlined className="text-[10px]" onClick={handleRightClick} />
    </div>
  );
};

export default CompanyBoundNavbar;
