import React, { useEffect, useState } from 'react';
import TextAnswer from './TextAnswer';
import VisualAnswer from './VisualAnswer/VisualAnswer';
import Feedback from './Feedback/Feedback';

const AssistantAnswer = ({ chat, isLast, index }) => {
    return (
        <div className={`bg-[white]`}>
            <TextAnswer chat={chat} isLast={isLast} />
            <VisualAnswer chat={chat} isLast={isLast} index={index} />
            <Feedback chat={chat} index={index}/>
        </div>
    );
};

export default AssistantAnswer;
