import React from 'react'
import AgentHeader from '../AgentTemplateComponents/AgentHeader'
import AgentHow from '../AgentTemplateComponents/AgentHow'
import AgentExplore from '../AgentTemplateComponents/AgentExplore'

const AgentTemplate = () => {
  return (
    <div className='flex flex-col gap-8 justify-center bg-white'>
        <AgentHeader />
        <AgentHow />
        <AgentExplore />
    </div>
  )
}

export default AgentTemplate