import React, { useState, useEffect } from "react";
import { Card, Table, Input, message } from "antd";
import debounce from "lodash/debounce";
import { jwtDecode } from "jwt-decode";
import { listFiles, downloadFiles } from "../../services/chatServices";
import { formatDateTime } from "../../helpers/formatDate";
import CsvPreview from "./CsvPreview";

const { Search } = Input;

const Files = () => {
  const [rowsSelected, setRowsSelected] = useState(0);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [csvString, setCsvString] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("loginToken");
        const decodedToken = jwtDecode(
          token?.replace(process.env.REACT_APP_TOKEN_PASSCODE, "")
        );
        const payload = {
          user_id: decodedToken.id,
        };
        const response = await listFiles(payload);
        const rawData = response.data.files;
        const transformData = (rawData) => {
          return rawData.map((item, index) => {
            const filePath = item.split("/");
            const fileNameWithDate = filePath[filePath.length - 1];
            const [fileName, dateUploadedPart] = fileNameWithDate.split(".csv");
            return {
              key: index,
              s3Path: item,
              dateUploaded: formatDateTime(dateUploadedPart) || "",
              fileName: fileName || "",
              type: "CSV",
              lastEdit: " ",
              destination: "false",
            };
          });
        };

        const transformedData = transformData(rawData);
        setData(transformedData);
        setFilteredData(transformedData);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        message.error("Error fetching dashboard data");
      }
    };

    fetchData();
  }, []);

  const handleSearch = debounce((value) => {
    const lowercasedValue = value.toLowerCase();
    const filtered = data.filter((item) =>
      item.dateUploaded.toLowerCase().includes(lowercasedValue) ||
      item.fileName.toLowerCase().includes(lowercasedValue) ||
      item.type.toLowerCase().includes(lowercasedValue)
    );
    setFilteredData(filtered);
  }, 300);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowsSelected(selectedRows?.length);
    },
  };

  const handleRowClick = async (record) => {
    try {
      setSelectedRowKey(record.key);
      message.loading("Getting your file for preview...")
      const response = await downloadFiles({ s3_path: record.s3Path });
      const csvString = response.data;
      setCsvString(csvString);
    } catch (error) {
      console.error("Error downloading file:", error);
      message.error("Failed to download the file");
    }
  };

  return (
    <>
      <div className="w-full flex justify-center items-center">
        <Card className="w-[95%] relative mt-2">
          {csvString && (
            <>
              <h3 className="text-lg  mb-4">
                <i> Preview of {filteredData[selectedRowKey]?.fileName} file </i>
              </h3>
              <CsvPreview csvString={csvString} />
            </>
          )}

          <div className="flex justify-between items-center w-full border border-black mb-4 px-4 py-2 rounded-md">
            <div className="flex justify-center items-center gap-4">
              <p>
                {rowsSelected}/{filteredData.length} Rows Selected
              </p>
              <Input
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                allowClear
                className="extendSearch"
                style={{
                  width: 200,
                }}
              />
            </div>
          </div>

          <Table
            rowSelection={rowSelection}
            columns={[
              {
                title: "Date Uploaded",
                dataIndex: "dateUploaded",
                width: "15%",
              },
              {
                title: "File Name",
                dataIndex: "fileName",
              },
              {
                title: "Type",
                dataIndex: "type",
                width: "10%",
              },
              {
                title: "Last Edit",
                dataIndex: "lastEdit",
              },
              {
                title: "Destination",
                dataIndex: "destination",
              },
            ]}
            rowHoverable={false}
            dataSource={filteredData}
            bordered
            pagination={{
              pageSizeOptions: ["5", "10", "20", "30", "50"],
              showSizeChanger: true,
              defaultPageSize: 5,
            }}
            className={`overflowY-scroll shadow-lg`}
            rowClassName={(record) =>
              record.key === selectedRowKey ? "bg-[#E9EDF1]" : ""
            }
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
        </Card>
      </div>
      {/* {loading && } */}
    </>
  );
};

export default Files;
