// utils/formatDate.js
export const formatDate = (dateString, timeZone = 'UTC') => {
    const date = new Date(dateString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone,
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return formatter.format(date).replace(',', '');
  };
  
  // utils/formatDate.js
export const formatDateTime = (dateString, timeZone = 'UTC') => {
  // Parse the date string from 'yyyymmddhhmmss' format
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  const hour = dateString.slice(8, 10);
  const minute = dateString.slice(10, 12);

  const formattedDateString = `${year}-${month}-${day}T${hour}:${minute}:00Z`;

  // Convert to Date object
  const date = new Date(formattedDateString);

  // Format the date
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
    hour12: false,
  };

  const formatter = new Intl.DateTimeFormat('en-GB', options); // 'en-GB' for dd-mm-yyyy format
  return formatter.format(date).replace(',', '');
};
