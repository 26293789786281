/* eslint-disable no-undef */
import { useEffect, useState, useRef } from "react";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { Select, Avatar, Typography, Tooltip, message, Button, Progress } from "antd";
import { FieldTimeOutlined, LogoutOutlined, LineChartOutlined, RobotOutlined, CaretDownOutlined, PlayCircleOutlined, CodeSandboxOutlined, BookOutlined, ApiOutlined, CompassOutlined, QuestionCircleFilled, BlockOutlined, SettingOutlined, PoweroffOutlined, MessageOutlined, DashboardOutlined, TeamOutlined, CheckCircleOutlined, HomeOutlined, Sett } from "@ant-design/icons";
import { useLocation, Link, useNavigate } from "react-router-dom";
// import logoWOname from "../../assets/images/logoWOname.png";
// import gtmcopilotLogo from "../../assets/images/v2Logo.png";
import avatar8 from './../../../assets/images/user.png';
import { chatHistory } from "../../../services/chatServices";
import { jwtDecode } from 'jwt-decode';
import "./Sidenav.css"
import ChatHistory from "../../ChatHistory/ChatHistory";
import { copilotChatArrayAtom, toastChatArrayAtom, activeSessionAtom, assistantFocusAtom, fileListAtom, reportListAtom, extendedChatAtom } from "../../../store";
import { integrationStatusAtom } from "../../../store/atoms/IntegrationAtoms";
import { initialPageAtom } from "../../../store/atoms/ProjectsAtoms";
import { initialAgentPageAtom } from "../../../store/atoms/AgentsAtoms";
import { sideNavContentAtom, chatModeAtom } from "../../../store/atoms/MainAtoms";
import ChatSidenav from '../../CopilotComponents/ChatSidenav'
import ChatWindow from "../../CopilotComponents/ChatWindow";
import InputBox from "../../CopilotComponents/InputBox";
import InputBoxToast from "../../ToastComponents/InputBoxToast";
// import InputBoxSidenav from "../../CopilotComponents/InputBoxSidenav";
import ChatWindowToast from "../../ToastComponents/ChatWindow";
import ChatWindowGumloop from "../../GumloopComponents/ChatWindowGumloop";
import InputBoxGumloop from "../../GumloopComponents/InputBoxGumloop";
import logoWOname from './../../../assets/images/logoWOname.png'
import gtmLogo from './../../../assets/images/gtmCopilotname.svg';
import { getCredits } from "../../../services/InsightsServices";
import AssistantInputBox from "../../AssistantComponent/AssistantInputBox/AssistantInputBox";
import AssistantChatWindow from "../../AssistantComponent/AssistantChatWindow/AssistantChatWindow"
const { Option } = Select;

function Sidenav({ collapsed, siderWidth }) {
  const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom)
  const [assistantFocus, setAssistantFocus] = useRecoilState(assistantFocusAtom)
  const [fileList, setFileList] = useRecoilState(fileListAtom);
  const [reportList, setReportList] = useRecoilState(reportListAtom);
  const connectedApps = useRecoilValue(integrationStatusAtom);
  const chatMode = useRecoilValue(chatModeAtom);
  const numberofConnectedApps = connectedApps.length
  const [selectedWorkspace, setSelectedWorkspace] = useState('BU');
  const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);
  const [workspace, setWorkspace] = useState('');
  const user = { id: "abvsdfdsdvdx" };
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const page = pathname?.replace("/", "");
  console.log("page " + page);
  const { Title } = Typography
  const [chatDropdownOpened, setchatDropdownOpened] = useState(false);
  const [builderDropdownOpened, setBuilderDropdownOpened] = useState(false);
  const [toasrChatArray, setToastChatArray] = useRecoilState(toastChatArrayAtom);
  const chatDropdownRef = useRef(null);
  const builderDropdownRef = useRef(null);
  const chatHistoryRef = useRef(null);
  const builderHistoryRef = useRef(null);
  const setChatArray = useSetRecoilState(copilotChatArrayAtom);
  const setInitialPage = useSetRecoilState(initialPageAtom)
  const setInitialAgentPage = useSetRecoilState(initialAgentPageAtom)
  const extendChat = useRecoilValue(extendedChatAtom)


  const handleNewChatClick = () => {
    if (pathname === "/assistant") {
      setActiveSession(sessionId)
      setAssistantFocus("master")
      setFileList([])
      setReportList([])
      localStorage.removeItem("session_id")
      localStorage.removeItem("tableName")
      setChatArray([
        {
          answer:
            "Hi this is ADA, your personal assistant. How can I help you today?",
          suggestions: [
            "Find companies with revenue over $1 million",
            "Filter companies founded in the last five years",
            "Filter companies in the technology sector with a revenue between $50 million and $100 million",
          ],
        },
      ])
    } else if (pathname === "/toast") {
      localStorage.removeItem("session_id")
      localStorage.removeItem("tableName")
      setToastChatArray([
        {
          answer:
            "Hi I am ADA, for this demo we have picked 121 Toast customer in Boston and built a knowledge graph based on public data ONLY",
          suggestions: [
            "What data was captured for this POC",
            "Data Source for this POC",
            "Monitoring"
          ],
        },
      ])
    } else {
      if (localStorage.getItem("path") == "assistant" || localStorage.getItem("path") == "ttyd-advanced") {
        localStorage.removeItem("session_id")
        localStorage.removeItem("tableName")
        navigate(`/${localStorage.getItem("path")}`)
        setChatArray([
          {
            answer:
              "Hi this is ADA, your personal assistant. How can I help you today?",
            suggestions: [
              "Find companies with revenue over $1 million",
              "Filter companies founded in the last five years",
              "Filter companies in the technology sector with a revenue between $50 million and $100 million",
            ],
          },
        ])
      } else {
        navigate("/toast")
        localStorage.removeItem("session_id")
        localStorage.removeItem("tableName")
        setToastChatArray([
          {
            answer:
              // "Hi this is ADA, your personal GTM CoPilot. How can I help you today?",
              "Hi I am ADA, for this demo we have picked 121 Toast customer in Boston and built a knowledge graph based on public data ONLY",
            suggestions: [
              "What data was captured for this POC",
              "Data Source for this POC",
              "Monitoring"
            ],
          },
        ])
      }

    }
  };

  const handleWorkspaceChange = value => {
    setSelectedWorkspace(value);
    if (value == 'CU') {
      window.location.href = "https://ada.gtmcopilot.com/demo";
    }
  };


  useEffect(() => {
    const token = localStorage.getItem('loginToken')
    let decodedToken
    token ? decodedToken = jwtDecode(token.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')) : decodedToken = null
    if (decodedToken.organization_domain.includes('.')) {
      setWorkspace(decodedToken.organization_domain.split('.')[0])
    }
    else {
      setWorkspace(decodedToken.organization_domain)
    }
    fetchData()
  }, [])
  const handleNavContentClick = (value) => {
    if (chatMode == 0 && !extendChat) {
      message.info("You're already viewing the chat in full screen mode.")
    }
    else {
      setSideNavContent(value)
    }
  }
  const handleBuilderDropdownClick = () => {
    setBuilderDropdownOpened(!builderDropdownOpened)
  };
  const logOutClick = () => {
    localStorage.clear()
    navigate('/login')
  };
  const [credits, setCredits] = useState(0);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("loginToken");
      const decodedToken = jwtDecode(token?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ""));
      const payload = {
        organisation_id: decodedToken.organisation_domain == "zillasecurity.com" ? '9c37cdef-41ca-4a80-a682-87d0cf6b184b' : '0e326d3e-21b9-4855-ac45-c2f659615ec3',
      };
      const response = await getCredits(payload);

      if (response) {
        // message.success('Data fetched successfully');
        setCredits(response.data.credits)
        // You can handle the response data here if needed
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      message.error("Error fetching dashboard data");
    }
  };

  return (
    <>
      <div className="flex flex-col  justify-between h-full border-r border-solid border-slate-300">
        <div>
          <div className={`px-2 flex flex-col justify-center mt-4 mb-4 w-full`}>
            <div className="flex justify-center">

              <img src={collapsed ? logoWOname : gtmLogo} alt="logo" className={`h-[50%] ${collapsed ? "w-[45px]" : "w-[120px]"}`} />
            </div>
            {/* <div className="flex justify-center">
                    <Title level={5} style={{ marginBottom: "0", color: "gray", fontSize: "14px" }}>
                        {(name.includes(" ") && collapsed) ? name.split(" ")[0] : name}
                    </Title>
                    </div> */}
          </div>
          {/* The below code is side menu and side chat which is commented out until next release */}
          {!collapsed && (
            <>
              <div className="flex mb-4 justify-center gap-1" >
                <div
                  className={` px-2 py-1 rounded cursor-pointer hover:bg-gray-200 transition duration-300 ease-in-out`}
                  style={{ borderBottom: sideNavContent == 0 ? '1px solid #2966BC' : '#fff', width: "35%", display: "flex", justifyContent: "space-around", fontWeight: "500", fontSize: "12px" }}
                  onClick={() => handleNavContentClick(0)}
                >
                  <span className="new-chat-label">Menu</span>
                </div>
                <div
                  className={` px-2 py-1 rounded cursor-pointer hover:bg-gray-200 transition duration-300 ease-in-out`}
                  style={{ borderBottom: sideNavContent != 0 ? '1px solid #2966BC' : '#fff', width: "35%", display: "flex", justifyContent: "space-around", fontWeight: "500", fontSize: "12px" }}
                  onClick={() => handleNavContentClick(1)}
                >
                  <span className="new-chat-label">Chat</span>
                </div>
              </div>
              {/* <div className="flex gap-1 justify-center">
                        <hr className="w-[35%] " style={{border: sideNavContent == 0 ? '1px solid #2966BC' : '#fff'}}/>
                        <hr className="w-[35%] " style={{border: sideNavContent != 0 ? '1px solid #2966BC' : '#fff'}}/>
                    </div> */}

            </>
          )}
          {sideNavContent == 0 ?
            <div>
              <div className={`flex justify-start ${collapsed && 'mt-20'}`}>
                <div
                  className={`${pathname.includes('home') ? "selectedOption bg-even-light-primary" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out ${collapsed && 'ml-2.5'}`}
                  style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }} onClick={() => navigate('/home')}
                >
                  <div className={`inline-flex ${!collapsed && 'mr-2'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                    <HomeOutlined className={`${pathname.includes('home') ? "selectedMenuIcon" : ""}`} style={{ fontSize: "15px", color: "black" }} />
                  </div>
                  {/* <HomeOutlined className="" style={{fontSize: "14px"}} />   */}
                  {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>Home</span>}
                </div>
              </div>
              <div className={`flex justify-start`} ref={chatDropdownRef}>
                <div
                  className={`${pathname.includes('assistant') || pathname.includes('toast') || pathname.includes('ttyd-advanced') ? "selectedOption bg-even-light-primary" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out ${collapsed && 'ml-2.5'}`}
                  style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                  //   onClick={() => navigate('/chat')}
                  onClick={() => localStorage.getItem("path") == "toast" ? navigate('/toast') : localStorage.getItem("path") == "assistant" ? navigate('/assistant') : navigate('/ttyd-advanced')}
                >
                  <div className="w-[90%] items-center flex gap-1">
                    <div className={`inline-flex ${!collapsed ? 'mr-2' : '-mr-1'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                      <MessageOutlined className={`${pathname.includes('assistant') || pathname.includes('toast') || pathname.includes('ttyd-advanced') ? "selectedMenuIcon" : ""}`} style={{ fontSize: "15px", color: "black" }} />
                    </div>
                    {/* <MessageOutlined /> */}
                    {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>Assistant</span>}
                  </div>
                  {!collapsed && (
                    <div className="w-[10%]" onClick={(e) => { e.stopPropagation(); setchatDropdownOpened(!chatDropdownOpened) }}>
                      <CaretDownOutlined className="pt-0.5" style={{ fontSize: "12px" }} />
                    </div>
                  )}
                </div>
              </div>
              {chatDropdownOpened && (
                <div className="bg-[#E9EDF1]">
                  <div className={`flex pl-6 justify-start`} ref={chatDropdownRef}>
                    <div
                      className={`${pathname.includes('chat-history') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out ${collapsed && 'ml-2.5'}`}
                      style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                      //   onClick={() => navigate('/chat')}
                      onClick={() => navigate('/chat-history')}
                    >
                      <div className="w-[90%] items-center flex gap-1">
                        <div className={`inline-flex ${!collapsed ? 'mr-2' : '-mr-1'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                          <FieldTimeOutlined className={`${pathname.includes('chat-history') ? "selectedMenuIcon" : ""}`} style={{ fontSize: "15px", color: "black" }} />
                        </div>
                        {/* <MessageOutlined /> */}
                        {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>History</span>}
                      </div>
                    </div>
                  </div>
                  <div className={`flex pl-6 justify-start`} ref={chatDropdownRef}>
                    <div
                      className={`${pathname.includes('chat-history') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out ${collapsed && 'ml-2.5'}`}
                      style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                      //   onClick={() => navigate('/chat')}
                      onClick={() => handleNewChatClick()}
                    >
                      <div className="w-[90%] items-center flex gap-1">
                        <div className={`inline-flex ${!collapsed ? 'mr-2' : '-mr-1'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                          <span className={`${pathname.includes('chat-history') ? "selectedMenuIcon" : ""}`} style={{ fontSize: "18px", color: "black" }} > + </span>
                        </div>
                        {/* <MessageOutlined /> */}
                        {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>New Chat</span>}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={`flex justify-start`} ref={builderDropdownRef}>
                <div
                  className={`${(pathname.includes('projects') || pathname.includes("agents") || pathname.includes("templates") || pathname.includes("pipeline") || pathname.includes("enrichment") || pathname.includes("lead") || pathname.includes("prog")) ? "selectedOption bg-even-light-primary" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out ${collapsed && 'ml-2.5'}`}
                  style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                  onClick={() => { navigate('/agents'); setInitialAgentPage(true) }}
                >
                  <div className="w-[90%] items-center flex gap-1">
                    <div className={`inline-flex ${!collapsed ? 'mr-2' : '-mr-1'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                      <RobotOutlined className={`${pathname.includes('projects') || pathname.includes("agents") || pathname.includes("templates") || pathname.includes("pipeline") || pathname.includes("lead") || pathname.includes("prog") || pathname.includes("enrichment") ? "selectedMenuIcon" : ""}`} style={{ fontSize: "15px", color: "black" }} />
                    </div>
                    {/* <RobotOutlined className="pt-0.5" style={{fontSize: "15px"}}/> */}
                    {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>Agents</span>}
                  </div>
                  {/* <div className="w-[10%]">
                      <CaretDownOutlined className="pt-0.5" style={{fontSize: "12px"}} onClick={handleBuilderDropdownClick}/>
                      </div> */}
                </div>
              </div>
              {builderDropdownOpened && (
                <div
                  className="absolute left-0 z-10 bg-white shadow-md p-2 flex flex-col gap-4 text-center"
                  style={{
                    width: "80%",
                    marginTop: "1%",
                    border: "1px black solid",
                    marginLeft: "30%"
                  }}
                  ref={builderHistoryRef}
                >
                  <div className={`${pathname.includes("projects") ? 'bg-[#2966BC]' : 'hover:bg-gray-200'} font-semibold rounded-md p-2 cursor-pointer`} onClick={() => navigate("/projects")}>Projects</div>
                  <div className={`${pathname.includes("agents") ? 'bg-[#2966BC]' : 'hover:bg-gray-200'} font-semibold rounded-md p-2 cursor-pointer`} onClick={() => navigate("/agents")}>Agents</div>
                </div>
              )}
              <div className={`flex justify-start`}>
                <div
                  className={`${pathname.includes('live') ? "selectedOption bg-even-light-primary" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer transition duration-300 ease-in-out ${collapsed ? 'ml-2.5 w-[90%]' : 'w-full'}`}
                  style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                  onClick={() => navigate('/live')}
                >
                  <div className={`inline-flex ${!collapsed ? 'mr-2' : '-mr-1'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                    <PlayCircleOutlined className={`${pathname.includes('live') ? "selectedMenuIcon" : ""}`} style={{ fontSize: "15px", color: "black" }} />
                  </div>
                  {/* <PlayCircleOutlined className="pt-0.5" style={{fontSize: "15px"}} />   */}
                  {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>Live</span>}
                </div>
              </div>
              <div className={`flex justify-start`}>
                <div
                  className={`${pathname.includes('audiences') ? "selectedOption bg-even-light-primary" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer transition duration-300 ease-in-out ${collapsed ? 'ml-2.5 w-[90%]' : 'w-full'}`}
                  style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                  onClick={() => navigate('/audiences')}
                >
                  <div className={`inline-flex ${!collapsed ? 'mr-2' : '-mr-1'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                    <TeamOutlined className={`${pathname.includes('audiences') ? "selectedMenuIcon" : ""}`} style={{ fontSize: "15px", color: "black" }} />
                  </div>
                  {/* <BookOutlined className="pt-0.5" style={{fontSize: "15px"}} />   */}
                  {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>Audience</span>}
                </div>
              </div>
              {/* <div className={`flex justify-start`} >
                      <div
                          className={`${pathname.includes('abcd') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-disable w-full  transition duration-300 ease-in-out`}
                          style={{borderRadius: "0px", display: "flex",alignItems: "center", gap: 4 , fontWeight: "600"}}
                      >
                      <div className="w-[90%] flex gap-1">  
                        <CodeSandboxOutlined className="pt-0.5" style={{fontSize: "15px"}}/>
                        <span className="new-chat-label" style={{fontSize: "14px"}}>Projects</span>
                      </div>  
                      <div className="w-[10%]">
                      <CaretDownOutlined className="pt-0.5" style={{fontSize: "12px"}}/>
                      </div>
                      </div>
                  </div> */}
              <div className={`flex justify-start`} >
                <div
                  className={`${pathname.includes('analytics') ? "selectedOption bg-even-light-primary" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer transition duration-300 ease-in-out ${collapsed ? 'ml-2.5 w-[90%]' : 'w-full'}`}
                  style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }} onClick={() => { navigate('/analytics') }}>
                  <div className="w-[90%] items-center flex gap-1">
                    <div className={`inline-flex ${!collapsed ? 'mr-2' : '-mr-1'} items-center justify-center w-[1.75rem] h-[1.75rem] rounded-full`}>
                      <LineChartOutlined className={`${pathname.includes('analytics') ? "selectedMenuIcon" : ""}`} style={{ fontSize: "15px", color: "black" }} />
                    </div>
                    {/* <LineChartOutlined className="pt-0.5" style={{fontSize: "15px"}}/> */}
                    {!collapsed && <span className="new-chat-label" style={{ fontSize: "14px" }}>Analytics</span>}
                  </div>
                  {/* {!collapsed && (
                      <div className="w-[10%]">
                      <CaretDownOutlined className="pt-0.5" style={{fontSize: "12px"}}/>
                      </div>)} */}
                </div>
              </div>
              {/* <div className={`flex justify-start mx-[10px]`}>
                      <div
                          className={`${pathname.includes('assessment') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out`}
                          style={{borderRadius: "0px", display: "flex",alignItems: "center", gap: 4 , fontWeight: "600"}}
                      // onClick={handleNewChat}
                      >
                      <DashboardOutlined className="pt-0.5" style={{fontSize: "15px"}} />  
                      <span className="new-chat-label" style={{fontSize: "14px"}}>Analytics</span>
                      </div>
                  </div>
                  <div className={`flex justify-start mx-[10px]`}>
                      <div
                          className={`${pathname.includes('assessment') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out`}
                          style={{borderRadius: "0px", display: "flex",alignItems: "center", gap: 4 , fontWeight: "600"}}
                      // onClick={handleNewChat}
                      >
                      <BlockOutlined className="pt-0.5" style={{fontSize: "15px"}} />  
                      <span className="new-chat-label" style={{fontSize: "14px"}}>Segments</span>
                      </div>
                  </div>
                  <div className={`flex justify-start mx-[10px]`}>
                      <div
                          className={`${pathname.includes('assessment') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out`}
                          style={{borderRadius: "0px", display: "flex",alignItems: "center", gap: 4 , fontWeight: "600"}}
                      // onClick={handleNewChat}
                      >
                      <TeamOutlined  className="pt-0.5" style={{fontSize: "15px"}} />  
                      <span className="new-chat-label" style={{fontSize: "14px"}}>Alerts</span>
                      </div>
                  </div>
                  <div className={`flex justify-start mx-[10px]`}>
                      <div
                          className={`${pathname.includes('assessment') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out`}
                          style={{borderRadius: "0px", display: "flex",alignItems: "center", gap: 4 , fontWeight: "600"}}
                      // onClick={handleNewChat}
                      >
                      <BlockOutlined className="pt-0.5" style={{fontSize: "15px"}} />  
                      <span className="new-chat-label" style={{fontSize: "14px"}}>Live</span>
                      </div>
                  </div> */}
            </div> :
            <div className="h-[80vh]"
            // style={{overflowY: "scroll"}}
            >
              {localStorage.getItem("path") == "assistant" || localStorage.getItem("path") == "ttyd-advanced" ? <ChatWindow /> : localStorage.getItem("path") == "toast" ? <ChatWindowToast /> : pathname == "/assistant_v2" ? <AssistantChatWindow /> : <ChatWindowGumloop />}
            </div>
          }
        </div>
        <div className="text-center">
          <>
            {!collapsed && (



              sideNavContent == 0 ? <>
                <hr />
                <div className="flex flex-col items-start mx-[10px] gap-2">
                  {/* Top Banner */}
                  {/* <div className="relative text-white bg-blue-600 mt-2 px-2 ml-3 py-1 w-[35%]">
                    <span className="font-semibold text-[12px]">Next step</span>
                  </div> */}

                  {/* Main Content */}
                  <div
                    className={`${pathname.includes('assessment') ? "selectedOption" : "hover:bg-gray-200"} px-4 text-grey cursor-pointer w-full transition duration-300 ease-in-out`}
                    style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                  >
                    {/* <ApiOutlined className="pt-0.5" style={{fontSize: "15px"}} />   */}
                    <span className="new-chat-label !text-start" style={{ fontSize: "14px" }}>Connect your Next Source</span>
                  </div>

                  {/* Button */}
                  <Button
                    className="bg-gray-100 hover:bg-gray-300 text-black mb-2 ml-3 font-semibold rounded-md w-fit"
                    style={{ fontSize: "12px" }}
                  // onClick={handleButtonClick}
                  >
                    Let’s go
                  </Button>
                </div>

                <hr />
                <div className={`mx-[10px] mb-2`}>
                  <div
                    className={`${pathname.includes('assessment') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-1 text-grey cursor-pointer w-full  transition duration-300 ease-in-out`}
                    style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                  // onClick={handleNewChat}
                  >
                    {/* <BlockOutlined className="pt-0.5" style={{fontSize: "15px"}} />   */}
                    <span className="new-chat-label " style={{ fontSize: "13px" }}>Your free trial is in progress</span>

                  </div>
                  {/* <div className="flex justify-center w-full items-center gap-4 px-3">
                    <Progress percent={65} />
                  </div> */}
                  <div className="flex text-[13px] ml-3 justify-start text-[gray]">
                    Credits Used : {credits || 65}
                  </div>
                  {/* <div className="flex text-[13px] ml-3 justify-start text-[gray]">
                    Credits Left : 35
                  </div> */}
                </div>
                <hr />
                <div onClick={() => navigate('/settings')} className={`flex justify-start mx-[10px]`}>
                  <div
                    className={`${pathname.includes('assessment') ? "selectedOption" : "hover:bg-gray-200"} px-4 py-2 text-grey cursor-pointer w-full  transition duration-300 ease-in-out`}
                    style={{ borderRadius: "0px", display: "flex", alignItems: "center", gap: 4, fontWeight: "600" }}
                  // onClick={handleNewChat}
                  >
                    {/* <div className="green-circle"></div> */}
                    <SettingOutlined />
                    <span className="new-chat-label" style={{ fontSize: "12px" }}>Settings</span>
                    {/* <Tooltip title={connectedApps.join(', ')}>
                      <span className="new-chat-label" style={{ fontSize: "11px", color: "grey" }}>{numberofConnectedApps} Connected</span>
                    </Tooltip> */}
                  </div>
                </div>
              </> : <div className={`bg-[#2966BC] w-[${siderWidth}] fixed bottom-0`}>

                {localStorage.getItem("path") == "assistant" || localStorage.getItem("path") == "ttyd-advanced" ? <InputBox siderWidth={siderWidth} /> : localStorage.getItem("path") == "toast" ? <InputBoxToast siderWidth={siderWidth} /> : pathname == "/assistant_v2" ? <AssistantInputBox siderWidth={siderWidth} /> : <InputBoxGumloop siderWidth={siderWidth} />}
              </div>
            )}
            {/* <hr />
                <div className={`flex ${collapsed ? 'flex-col' : "h-[2rem]"}  gap-2`} style={{alignItems: "center", justifyContent:"space-around"}}>
                    <Tooltip title="Settings" placement={collapsed ? "topRight" : "top"}>
                        <SettingOutlined onClick={() => navigate('/settings')} className="pt-0.5 cursor-pointer " style={{fontSize: "20px"}} /> 
                    </Tooltip>
                    <Tooltip title="Help">
                        <QuestionCircleFilled className="pt-0.5 cursor-pointer" style={{fontSize: "20px"}}/> 
                    </Tooltip>
                    <Tooltip title="Log Out">
                        <PoweroffOutlined onClick={logOutClick} className="pt-0.5 cursor-pointer" style={{fontSize: "20px"}}/>
                    </Tooltip>
                </div> */}
          </>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
