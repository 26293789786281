import React, { useState, useEffect } from "react";
import shareImg from "../../assets/images/shareImg.png";
import saveImg from "../../assets/images/saveImg.png";
import testImg from "../../assets/images/testImg.png";
import deployImg from "../../assets/images/deployImg.png";
import CustomButton from "./CustomButton";
import { Spin, Modal, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { testLoadingAtom, deployAtom, gumloopChatArrayAtom, btnLoadingAtom } from "../../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import GumSuccess from "./GumSuccess";

const MainHeader = () => {
  const [activeHead, setActiveHead] = useState(0);
  const [loading, setLoading] = useRecoilState(testLoadingAtom);
  const [deploy, setDeploy] = useRecoilState(deployAtom);
  const setChatArray = useSetRecoilState(gumloopChatArrayAtom);
  const btnLoading = useRecoilValue(btnLoadingAtom);
  const [modalOpen, setModalOpen] = useState(false);
  const btns = [
    {
      text: "Save",
      icon: saveImg,
    },
    {
      text: "Test",
      icon: testImg,
    },
    {
      text: "Deploy",
      icon: deployImg,
    },
  ];
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (loading) {
  //       setLoading(false);
  //       setModalOpen(true);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, [loading]);
  const handleClick = () => {
    setLoading(true);
  };
  const handleWorkflow = () => {
    setModalOpen(false);
    setDeploy(true);
    setChatArray(prev => ([
      ...prev,
      {
        question: "Yes",
        answer: "Deployed! To see your deployed workflows & their performance, please navigate to Live in the Main Menu."
      }
    ]))
  }
  return (
    <>
      <Modal
        title="Generated Output"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleWorkflow}
          >
            Deploy Workflow
          </Button>,
        ]}
      >
        <div className="mt-4 flex flex-col gap-1">
          <p className="font-semibold">
            max@hubspot.com (2,170,000,000 ARR)
          </p>
          <p>
            <span className="font-semibold">INFO:</span> HubSpot is a leading
            AI-powered customer relationship management (CRM) platform that
            provides integrated software and tools for marketing, sales,
            customer service, content management, operations, and commerce,
            enabling businesses to grow and connect with their customers more
            effectively.
          </p>
          <p>
            <span className="font-semibold">Company name:</span> HubSpot
          </p>
          <p>
            <span className="font-semibold">Industry:</span> Information
            Technology & Services
          </p>
          <p>
            <span className="font-semibold">Country:</span> United States
          </p>
        </div>
      </Modal>
      <Modal
          title=""
          visible={deploy}
          onCancel={() => setDeploy(false)}
          footer={null}
          className=""
          centered
        >
          <div className="h-[60vh] flex flex-col justify-center items-center gap-4">
            <GumSuccess setModal={setDeploy}/>
          </div>
        </Modal>
      <div className="p-2 ml-3">
        <div className="flex justify-start items-center pl-1 pr-4 border-b border-gray-300">
          {["Builder", "Files", "Log"].map((tab, index) => (
            <div
              key={index}
              className={`${
                activeHead === index
                  ? "text-[#2966BC] border-b-2 border-[#2966BC]"
                  : "text-gray-600"
              } px-4 py-2 text-sm cursor-pointer`}
              onClick={() => setActiveHead(index)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="flex w-full justify-between items-center p-2 mt-1 border border-2 bg-white rounded-lg">
          <p className="text-base">Lead Background Research</p>
          <div className="flex gap-1 items-center">
            <img
              src={shareImg}
              alt="shareImg"
              width={30}
              className="hover:scale-110 duration-300 ease-in-out cursor-pointer"
            />
            <div className="flex gap-2">
              {btns.map((btnData, idx) => {
                if (idx === 1 && btnLoading) {
                  return (
                    <Spin
                      key="spinner"
                      indicator={<LoadingOutlined />}
                      size="large"
                      className="w-16"
                    />
                  );
                }
                return (
                  <CustomButton
                    key={idx}
                    id={idx}
                    text={btnData.text}
                    icon={btnData.icon}
                    // onClick={handleClick}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainHeader;
