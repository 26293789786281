import React, { useEffect } from 'react'
import ChatWindow from './ChatWindow.js';
import InputBox from './InputBox';
import { useRecoilValue } from 'recoil';
import { extendedChatAtom } from '../../store/index.js';
import ExtendedChat from './ExtendedChat.js';

const Copilot = () => {
  const extendedChat = useRecoilValue(extendedChatAtom);

  useEffect(() => {
    localStorage.removeItem('session_id');
  }, []);

  return (
    <div className='flex flex-col h-[90vh] w-full p-2'>
      {!extendedChat ? (
        <>
          <ChatWindow />
          <InputBox />    
        </>
      ) : (
        <>
          <ExtendedChat />
        </>
      )}
    </div>
  )
}

export default Copilot;
