import React, { useRef, useEffect, useState } from 'react';
import VisualMenu from './VisualMenu';
import VisualGraph from './VisualGraph';
import { Card } from 'antd'
import { visualModeAtom } from '../../../../../store';
import { useRecoilState } from 'recoil';
import DisplayTableComponent from '../../../../CopilotComponents/DisplayTableComponent';
const VisualAnswer = ({ chat, index }) => {
    const [visualMode, setVisualMode] = useState('table');
    return (
        <>
            {
                chat?.visuals && !chat.is_sql_error && !chat.onlySummary &&
                <div className="w-full flex justify-center pb-4 items-center">
                    <Card className="w-[85%] relative">
                        <>
                            {chat?.chartTitle ? <div><h2>{chat?.chartTitle}</h2></div> : <div><h2> </h2></div>}
                        </>
                        <VisualMenu chat={chat} index={index} setVisualMode={setVisualMode} visualMode={visualMode} />
                        {chat?.data && visualMode == 'graph' ?
                            <div className='mt-8' id={`graph-${index}`}><VisualGraph chat={chat} index={index} /></div> :
                            chat?.postgres_query && visualMode == 'table' ?
                                <div className='mt-8'><DisplayTableComponent query_id={chat?.query_id} postgres_query={chat?.postgres_query} minimized={true} /></div> :
                                visualMode == 'sql' ? <Card className='mt-8' title='SQL Query'>{chat?.postgres_query}</Card> : <></>
                        }
                    </Card>
                </div>
            }
        </>
    );
};

export default VisualAnswer;
