import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { progInputAtom } from "../../store";
import { Select } from "antd";

const data = [
  [
    {
      label: "Account Name",
      value: "Cisco",
      apiName: "company",
    },
    {
      label: "Account Website",
      value: "https://www.cisco.com",
      apiName: "website",
    },
    {
      label: "Account Country",
      value: "USA",
      apiName: "country",
    },
  ],
  [
    {
      label: "Job Function",
      value: "Sales",
      options: [
        "Accounting / Finance",
        "Administration",
        "Advanced Analytics / Business Intelligence",
        "Agency / Program Management",
        "Business Process Outsourcing / Shared Services",
        "Business Transformation / Innovation / Digital",
        "Communications",
        "Compliance",
        "Consulting",
        "Customer Service",
        "Education",
        "Engineering",
        "Health Professional",
        "Human Resources",
        "Information Technology",
        "Information or Cyber Security",
        "Legal",
        "Management / Leadership / Owner",
        "Marketing",
        "Operations",
        "Product",
        "Project Management",
        "Public Relations",
        "Real Estate",
        "Records Management",
        "Research & Development",
        "Sales / Business Development",
        "Security",
        "Supply Chain",
      ],
      apiName: "job_function",
    },
    {
      label: "Job Level",
      value: "CEO",
      options: ["C-level / CXO", "Vice President", "Director", "Manager"],
      apiName: "job_level",
    },
  ],
];

const FormComponent = () => {
  const [progInput, setProgInput] = useRecoilState(progInputAtom);

  const handleChange = (val, apiName, type) => {
    // console.log(val, "|", apiName)
    if (type === "input") {
      setProgInput({
        ...progInput,
        company_info: [
          {
            ...progInput?.company_info[0],
            [apiName]: val?.trim(),
          },
        ],
      });
    } else {
      setProgInput({
        ...progInput,
        [apiName]: val,
      });
    }
  };

  //   useEffect(() => {
  //     console.log(progInput, "progInput")
  //   }, [progInput])

  return (
    <div className="flex flex-col gap-4 justify-center items-start -mt-4 w-full">
      {data.map((item, index) => (
        <div
          key={index}
          className="flex flex-col gap-2 justify-center items-start w-full"
        >
          <div className="font-bold">
            {index === 0 ? "Company Info Input" : "Persona Input"}
          </div>
          {item.map(({ label, value, apiName, options }, idx) => (
            <div
              key={idx}
              className="flex flex-col gap-1 justify-center items-start w-full"
            >
              <div>{label}</div>
              {!options ? (
                <input
                  type="text"
                  defaultValue={value}
                  placeholder={`Enter ${label}`}
                  className="w-full ml-1"
                  onChange={(e) =>
                    handleChange(e.target.value, apiName, "input")
                  }
                />
              ) : (
                <div className="w-full">
                  <Select
                    options={options?.map((opt) => ({
                      label: opt,
                      value: opt,
                    }))}
                    mode="multiple"
                    allowClear
                    className="w-full nodrag"
                    defaultValue={progInput[apiName]}
                    onChange={(val) => handleChange(val, apiName, "select")}
                  />
                </div>
              )}
              <hr className="w-full font-bold" />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FormComponent;
