// import { list } from "./integrationList";
import salesforce from '../../../assets/images/salesforceImg.png';
import slackLogo from '../../../assets/images/slackLogo.png';
import fivetran from '../../../assets/images/fivetran.jpg';
import looker from '../../../assets/images/looker.jpg';
import dbtLogo from '../../../assets/images/dbt_logo.svg.png'
import sigma from '../../../assets/images/sigma.png'
import datadog from '../../../assets/images/datadog.png'
import pagerduty from '../../../assets/images/pagerduty.jpg'
import logoWOname from '../../../assets/images/logoWOname.png'
import appollo from '../../../assets/images/apollo.png'
import db from '../../../assets/images/db.png'
import secureLocalStorage from 'react-secure-storage';
import { jwtDecode } from 'jwt-decode';
// import { GridItem } from "./GridItem";
import { GridItem } from '../../IntegrationComponents/GridItem';
import React, { useState } from 'react';
import { useRecoilValue } from "recoil";
import { integrationStatusAtom } from "../../../store/atoms/IntegrationAtoms";
import { Modal } from 'antd';
export const Extensions = () => {
    const [oGraphModal, setoGraphModal] = useState(false)
    const connectedApps = useRecoilValue(integrationStatusAtom)
    let slackToken = secureLocalStorage.getItem("SlackToken");
    if (!slackToken) {
        slackToken = "";
    }
    const list = [
        {
            id: 3,
            name: "Slack",
            logo: slackLogo,
            redirect_uri: 'https://app.gtmcopilot.com/api_v3/oauth/login?tenant_id=' + localStorage.getItem("tenant_id") + '&user_name=' + jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).name?.replace("|", " ") + '&email=' + jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).email + '&tenant_name=' + jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).organization_domain + '&source=slack&user_id=' + jwtDecode(localStorage.getItem('loginToken').replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).id,
            status: "Connect",
            description:
                "Connect Your Slack Workspace To Get Notified About New Leads."
        },
        {
            id: 0,
            name: "Fivetran",
            logo: fivetran,
            redirect_uri: 'Sample',
            status: "Connect",
            description:
                "The industry leader in data movement, powers real-time analytics, database replication, AI workflows"
        },
        {
            id: 1,
            logo: dbtLogo,
            redirect_uri: "Sample",
            status: "Connect",
            name: "DBT",
            description:
                "An open-source command line tool that helps analysts and engineers transform data in their warehouse more effectively"
        },
        {
            id: 4,
            name: "Looker",
            logo: looker,
            redirect_uri: 'Sample',
            status: "Connect",
            description:
                "Turn your data into compelling stories of data visualization art."
        },
        {
            id: 5,
            name: "Sigma",
            logo: sigma,
            redirect_uri: 'Sample',
            status: "Connect",
            description:
                "Next-generation analytics and business intelligence."
        },
        {
            id: 6,
            name: "Datadog",
            logo: datadog,
            redirect_uri: 'Sample',
            status: "Connect",
            description:
                "An observability service for cloud-scale applications"
        },

        {
            id: 8,
            name: "Appollo",
            logo: appollo,
            redirect_uri: 'Sample',
            status: "Connect",
            description:
                "A leading provider of alternative asset management and retirement solutions"
        },
        {
            id: 9,
            name: "D&B",
            logo: db,
            redirect_uri: 'Sample',
            status: "Connect",
            description:
                " Data Cloud offers the world's most comprehensive business data and analytical insights to power today's most crucial business needs"
        },

    ];
    const sortedList = list.sort((a, b) => {
        const isAConnected = connectedApps.includes(a.name);
        const isBConnected = connectedApps.includes(b.name);
        return isAConnected === isBConnected ? 0 : isAConnected ? -1 : 1;
    });

    const connectedItems = sortedList.filter(item => connectedApps.includes(item.name));
    const otherItems = sortedList.filter(item => !connectedApps.includes(item.name));

    if (secureLocalStorage.getItem("SlackConnected")) {
        const slackItem = sortedList.find(item => item.name === "Slack");
        if (slackItem && connectedItems) {
            connectedItems?.push(slackItem);
        }
        // Remove Slack from otherItems
        const slackIndex = otherItems.findIndex(item => item.name === "Slack");
        if (slackIndex > -1) {
            otherItems?.splice(slackIndex, 1);
        }
    }

    return (
        <>
            <Modal
                title={'oGraph@iCustomer'}
                open={oGraphModal}
                onCancel={() => setoGraphModal(false)}
                footer={null}
                width={'90%'}
                bodyStyle={{ height: '80vh' }}
            >
                <iframe
                    src="https://ographdb.icustomer.ai/neo4js/"
                    style={{ width: "100%", height: "600px", border: "none" }}
                    title="oGraph"
                />
            </Modal>
            <div className="flex flex-col ml-[10%] gap-[1.75rem]">
                <hr />
                <div className="p-2 font-[white] px-4" style={{ borderRadius: "10px", fontSize: "large", width: "fit-content", fontWeight: 400 }}>
                    {/* <h2>You can also connect :- </h2> */}
                </div>
                <div className="grid-list other-apps-section">
                    {list.map((item) => (
                        <GridItem key={item.id} {...item} />
                    ))}
                    <div className="grid-card">
                        <div className="card-top">
                            <img src={logoWOname} alt="logo" className="integration-logo" />
                            <h4>oGraph</h4>
                        </div>
                        <p>Proprietary curated and updated database of accounts and contacts</p>
                        <div className=" mt-4 flex justify-end">
                            <button type="button" onClick={() => setoGraphModal(true)} className=''>View</button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};
