import { Row, Col, Modal, message } from "antd";
import { useState, useEffect } from "react";
import { SyncOutlined } from "@ant-design/icons";
import "./GridItem.css"
export const GridItem = ({ id, logo, name, description, redirect_uri, status, setIsGridSelected }) => {

  return (
    <div className="grid-card">
        <div className="card-top">
          <img src={logo} alt="logo" className="integration-logo" />
          <h4>{name}</h4>
        </div>
        {((name === 'Slack') || name !== 'Slack') ? <p>{description}</p> : <p>{'Disconnect Your Slack Workspace To Stop Getting Notified About New Leads'}</p>}
        {/* {!connectedApps.includes(name) &&
          <button className="mt-4" onClick={showModal}>{name === 'Slack' && connected ? 'Disconnect' : status}</button>
        } */}
          <button className="mt-4" onClick={() => setIsGridSelected(true)}>Select</button>
        <div className="connectedApps mt-2">
          {/* {connectedApps.includes(name) && 
            <>
            <button className="sync-button" onClick={syncAirbyte}><SyncOutlined style={{fontSize: "1.1rem"}} /></button>
            <button onClick={showModal}>{'Disconnect'}</button>
            </>
          } */}
        </div>
    </div>
  );
};
