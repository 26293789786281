import { atom } from "recoil";

const enrichGumloopRowsAtom = atom({
  key: "enrichGumloopRows",
  default: 1,
});

const enrichGumloopInputFilledAtom = atom({
  key: "enrichGumloopInputFilled",
  default: false,
});

const enrichGumloopQuestionAtom = atom({
  key: "enrichGumloopQuestion",
  default: "",
});

const enrichGumloopChatArrayAtom = atom({
  key: "enrichGumloopChatArray",
  default: [
    {
      answer:
        "I see you have selected the Lead Research Template. Would you like help setting it up?"
    },
  ],
});

const enrichGumloopLoadingAtom = atom({
  key: "enrichGumloopLoading",
  default: false,
});

const enrichFocusNodeIdAtom = atom({
    key: "enrichFocusNodeId",
    default: null
})

const enrichNewNodeAtom = atom({
    key: "enrichNewNode",
    default: false
})

const enrichContactAtom = atom({
    key: "enrichContact",
    default: false
})

const enrichSlackAtom = atom({
    key: "enrichSlack",
    default: false
})

const enrichFitEntireAtom = atom({
    key: "enrichFitEntire",
    default: false
})

const enrichTestLoadingAtom = atom({
    key: "enrichTestLoading",
    default: false
})

const enrichDeployAtom = atom({
    key: "enrichDeploy",
    default: false
})

const enrichCollapsibleAtom = atom({
  key: "enrichCollapsible",
  default: false
})

const enrichChecked = atom({
  key: "enrichChecked",
  default: 1
})

const enrichEmailAtom = atom({
  key: "enrichEmail",
  default: "david.slavich@talkable.com"
})

const enrichPolledContentAtom = atom({
  key: "enrichPolledContent",
  default: ""
})

const enrichTestEmailAtom = atom({
  key: "enrichTestEmail",
  default: ""
})

const enrichVisibleAtom = atom({
  key: "enrichVisible",
  default: [0, 1, 2]
})

const enrichActiveIndexAtom = atom({
  key: "enrichActiveIndex",
  default: 0
})

const enrichNodeInProgressAtom = atom({
  key: "enrichNodeInProgress",
  default: null
})

export {
  enrichGumloopRowsAtom,
  enrichGumloopInputFilledAtom,
  enrichGumloopQuestionAtom,
  enrichGumloopChatArrayAtom,
  enrichGumloopLoadingAtom,
  enrichFocusNodeIdAtom,
  enrichNewNodeAtom,
  enrichContactAtom,
  enrichSlackAtom,
  enrichFitEntireAtom,
  enrichTestLoadingAtom,
  enrichDeployAtom,
  enrichCollapsibleAtom,
  enrichChecked,
  enrichEmailAtom,
  enrichPolledContentAtom,
  enrichTestEmailAtom,
  enrichVisibleAtom,
  enrichActiveIndexAtom,
  enrichNodeInProgressAtom
};
