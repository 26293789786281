import React, { useState, useRef, useEffect } from "react";

import {
    PlusOutlined,
    UndoOutlined,
    RiseOutlined,
    SafetyCertificateOutlined,
    ClusterOutlined,
    ExperimentOutlined,
    BankOutlined, BlockOutlined, IdcardOutlined, FieldTimeOutlined, ClearOutlined
} from "@ant-design/icons";
import { Card, Table, Input, Dropdown, Menu, Modal, message, Progress } from "antd";
// import { Bar } from "react-chartjs-2";
import { jwtDecode } from "jwt-decode";
import glitterImg from "../../assets/images/glitter.png";
import funnelImg from "../../assets/images/funnel.png";
import overlapImg from "../../assets/images/overlapIcon.png";
import './ExtendedChat.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSalesforce,
    faHubspot,
    faLayerPlus
} from "@fortawesome/free-brands-svg-icons";
import {
    faDatabase,
    faStar,
} from "@fortawesome/free-solid-svg-icons";
import { useSetRecoilState, useRecoilState } from "recoil";
import { extendedChatAtom, hiddenColumnsAtom, deletedColumnsAtom, actionHistoryAtom } from "../../store";
import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms";
import "./CoPilotStyles.css";
import EnrichOption from "../OptionsComponents/EnrichOption";
import DeduplicateOption from "../OptionsComponents/DeduplicateOption";
import FindLookALikeOption from "../OptionsComponents/FindLookALikeOption";
import AddPeopleOption from "../OptionsComponents/AddPeopleOption";
import ValidateOption from "../OptionsComponents/ValidateOption";
import FindParentChildOption from "../OptionsComponents/FindParentChildOption";

import { getColumns } from "../../services/InsightsServices";
import SfModal from "./SfModal.js";
import FormulaGenerator from "../OptionsComponents/FormulaOption.js";


const { Search } = Input;

const TableMenu = ({ data, selectedRows, query_id, fetchData, setReloadChatTable }) => {
    const [plusOptionsVisible, setPlusOptionsVisible] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [starOptionsVisible, setStarOptionsVisible] = useState(false);
    const [modalVisible, setIsModalVisible] = useState(false)
    const [optionSelected, setOptionSelected] = useState("")
    const [newObjects, setNewObjects] = useState(null)

    const [hiddenColumns, setHiddenColumns] = useRecoilState(hiddenColumnsAtom);
    const [deletedColumns, setDeletedColumns] = useRecoilState(deletedColumnsAtom);
    const [actionHistory, setActionHistory] = useRecoilState(actionHistoryAtom);
    const [sfModalOpen, setSfModalOpen] = useState(false);
    const [columnCount, setColumnCount] = useState(0);


    useEffect(() => {
        if (data && data.length > 0) {
            const columns = Object.keys(data[0]);
            setColumnCount(columns.length);
        }
    }, [data]);

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setOptionSelected("")
    };
    const handleOptionsClick = (optionName) => {
        console.log({optionName})
        setOptionSelected(optionName)
        console.log(optionSelected)
        setIsModalVisible(true);
    };
    const onSearch = (value, _e, info) => console.log(info?.source, value);
    const starOptions = [
        {
            label: "Clean & Normalise",
            icon: <ClearOutlined className="mr-2" />,
        },
        {
            label: "Enrich",
            icon: <RiseOutlined className="mr-2" />,
        },
        {
            label: "Deduplicate",
            icon: <BlockOutlined className="mr-2" />,
        },
        {
            label: "Find look-a-likes",
            icon: <BankOutlined className="mr-2" />,
        },
        {
            label: "Add people",
            icon: <IdcardOutlined className="mr-2" />,
        },
        {
            label: "Validate",
            icon: <SafetyCertificateOutlined className="mr-2" />,
        },
        {
            label: "Find parent/child",
            icon: <ClusterOutlined className="mr-2" />,
        },
        {
            label: "Formula",
            icon: <ExperimentOutlined className="mr-2" />,
        },
    ];
    const filteredStarOptions = starOptions.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );
    const starMenu = (
        <Menu>
            <Menu.Item>
                <Search
                    placeholder="Search"
                    onSearch={value => setSearchText(value)}
                    allowClear
                    style={{
                        width: "100%",
                        marginBottom: "10px",
                    }}
                />
            </Menu.Item>
            {filteredStarOptions.map(option => (
                <Menu.Item onClick={() => handleOptionsClick(option.label)} key={option.label}>
                    {option.icon}
                    {option.label}
                </Menu.Item>
            ))}
        </Menu>
    );
    const handleSFClick = () => {
        setSfModalOpen(true)
    }
    const plusOptions = [
        {
            label: "Salesforce",
            icon: faSalesforce,
            onClick: handleSFClick
        },
        {
            label: "HubSpot",
            icon: faHubspot,
        },
        {
            label: "Unified Data",
            icon: faDatabase,
        },
        {
            label: "Favorite Columns",
            icon: faStar,
        },
    ];

    const filteredPlusOptions = plusOptions.filter(option =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
    );

    const plusMenu = (
        <Menu>
            {filteredPlusOptions.map(option => (

                <Menu.Item key={option.label} onClick={() => option.onClick()}>
                    <FontAwesomeIcon icon={option.icon} className="mr-2" />
                    {option.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    const fetchColumnObjects = async () => {
        const orgParam = new URLSearchParams(window.location.search).get('org');
        let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
        if (orgParam) {
            const [password, orgName] = orgParam.split(' ');
            const domainPassword = process.env.REACT_APP_DOMAIN_PASSWORD;
            if (password === domainPassword) {
                organization = orgName + ".com";
            }
        }
        let schema_name;
        if (organization === "talkable.com") {
            schema_name = "talkable_master";
        } else if (organization === 'zillasecurity.com') {
            schema_name = "zillasecurity_master";
        } else {
            schema_name = "talkable_master";
        }
        try {
            const payload = {
                schema_name: schema_name
            };
            const response = await getColumns(payload);
            const data = response.data;
            setNewObjects(data)
            console.log(data, "asdasd")
        } catch (error) {
            console.error("Error fetching dashboard data:", error);
            message.error("Error fetching column object");
        }
    };
    useEffect(() => {
        fetchColumnObjects();
        console.log(data, "---------");

    }, []);

    const handleUndo = () => {
        if (actionHistory.length === 0) return;

        const lastAction = actionHistory[actionHistory.length - 1];
        if (lastAction.action === "hide") {
            setHiddenColumns(
                hiddenColumns.filter((col) => col !== lastAction.column)
            );
        } else if (lastAction.action === "delete") {
            setDeletedColumns(
                deletedColumns.filter((col) => col !== lastAction.column)
            );
        }

        setActionHistory(actionHistory.slice(0, -1));
    };
    return (
        <>
            <Modal
                title=""
                visible={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                className={`${optionSelected} min-w-1/2`}
                style={optionSelected == 'Formula' && {minWidth: "65vw"}}
            >
                {optionSelected == 'Enrich' && <EnrichOption data={data} selectedRows={selectedRows} query_id={query_id} handleCloseModal={handleCloseModal} fetchData={fetchData} />}
                {optionSelected == 'Deduplicate' && <DeduplicateOption />}
                {optionSelected == 'Find look-a-likes' && <FindLookALikeOption />}
                {optionSelected == 'Add people' && <AddPeopleOption />}
                {optionSelected == 'Validate' && <ValidateOption />}
                {optionSelected == 'Find parent/child' && <FindParentChildOption />}
                {optionSelected == 'Formula' && <FormulaGenerator data={data} query_id={query_id} setReloadChatTable={setReloadChatTable} handleCloseModal={handleCloseModal}/>}


            </Modal>
            <SfModal visible={sfModalOpen} newObjects={newObjects} setSfModalOpen={setSfModalOpen} />
            <div className="flex justify-between items-center w-full border border-black px-4 py-2 rounded-md">
                <div className="flex justify-center items-center gap-4">
                    <p>
                        {selectedRows?.length}/{data.length} Rows Selected
                    </p>
                    <p>{columnCount} Columns</p>
                    <Dropdown
                        overlay={plusMenu}
                        trigger={['click']}
                        visible={plusOptionsVisible}
                        onVisibleChange={setPlusOptionsVisible}
                    >
                        <PlusOutlined className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out" />
                    </Dropdown>
                    <Search
                        placeholder="Search"
                        onSearch={onSearch}
                        allowClear
                        className="extendSearch"
                        style={{
                            width: 200,
                        }}
                    />
                </div>
                <div className="flex justify-center items-center gap-2">
                    {actionHistory?.length > 0 && (
                        <UndoOutlined rotate={90} className="mr-1" onClick={handleUndo} />
                    )}
                    {/* <RedoOutlined rotate={270} className="mr-1" /> */}
                    <Dropdown
                        overlay={starMenu}
                        trigger={['click']}
                        visible={starOptionsVisible}
                        onVisibleChange={setStarOptionsVisible}
                    >

                        <img className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out" src={glitterImg} alt="glitterImg" width={16} />
                    </Dropdown>
                    <img src={funnelImg} alt="funnelImg" width={14} />
                    <img src={overlapImg} alt="overlapImg" width={20} />
                </div>
            </div>
        </>
    );
};

export default TableMenu;
