import React, { useState } from 'react';
import { Select, Input, Button } from 'antd';

const { Option } = Select;

const EnrichAPINodeComponent = ({type}) => {
  // State for the input and select field
  const [emailOrId, setEmailOrId] = useState('');
  const [template, setTemplate] = useState(type==="check" ? 'ICP Check Template' : type==="person" ? 'ICP Person Enrich Template' : 'ICP Account Enrich Template');
  const [customFields, setCustomFields] = useState([]);

  // Handler for the input field
  const handleInputChange = (e) => {
    setEmailOrId(e.target.value);
  };

  // Handler for the select field
  const handleTemplateChange = (value) => {
    setTemplate(value);
  };

  // Handler for adding a custom field
  const addCustomField = () => {
    setCustomFields([...customFields, `Field ${customFields.length + 1}`]);
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md space-y-4 border border-gray-200">
      <div className="text-sm text-gray-500 mb-2">$steps.readjsonvalues.result</div>

      {/* Input field for email or ID */}
      <div>
        <label className="block text-gray-700 font-medium mb-1">{["person", "check"].includes(type) ? 'Email or ID to lookup' : 'Website or ID to lookup'}</label>
        <Input 
          value={["person", "check"].includes(type) ? "jenish@icustomer.ai" : "wwww.icustomer.ai"} 
          onChange={handleInputChange} 
          placeholder="email" 
          className="w-full" 
        />
      </div>

      {/* Select field for template */}
      <div>
        <label className="block text-gray-700 font-medium mb-1">Fields to Enrich:</label>
        <span className="text-gray-600">Choose a template</span>
        <Select 
          value={template} 
          onChange={handleTemplateChange} 
          className="w-full mt-1"
          options={[
            {value:"ICP Check Template", value:"ICP Check Template"},
            {value:"ICP Lead Enrich Template", value:"ICP Lead Enrich Template"},
            {value:"ICP Account Enrich Template", value:"ICP Account Enrich Template"}

          ]}
        >
          {/* <Select.Option value="ICP Check Template">ICP Check Template</Select.Option>
          <Select.Option value="ICP Lead Enrich Template">ICP Lead Enrich Template</Select.Option>
          <Select.Option value="ICP Account Enrich Template">ICP Account Enrich Template</Select.Option> */}
        </Select>
      </div>

      {/* Section to add custom fields */}
      <div>
        <span className="block text-gray-600 mt-4">Or Pick Fields:</span>
        <Button 
          onClick={addCustomField} 
          className="mt-2 bg-gray-800 text-white"
        >
          + Add Field
        </Button>
      </div>

      {/* Render added custom fields */}
      <div className="mt-4">
        {customFields.map((field, index) => (
          <div key={index} className="p-2 border border-gray-300 rounded mt-2">
            {field}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnrichAPINodeComponent;