import React from 'react'
import AgentCard from '../AgentCard/AgentCard'

const AgentCardWrapper = ({ values }) => {
  return (
    <div className='grid grid-cols-3 gap-10'>
        {values.map((val, index) => (
            <AgentCard key={index} values={val} />
        ))}
    </div>
  )
}

export default AgentCardWrapper