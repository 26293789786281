import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { flashAtom } from "../../../store";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { faL } from "@fortawesome/free-solid-svg-icons";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip
);

const Box = ({ label, value, index }) => {
  const data = {
    labels: [label],
    datasets: [
      {
        label: 'VM',
        data: [value],
        backgroundColor: '#2966BC',
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: false, // Show tooltips on hover
        position: 'nearest', // Position tooltip nearest to the point
        intersect: false,
        external: (context) => {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          
          // Create tooltip element if not exists
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            tooltipEl.style.color = 'white';
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.padding = '5px';
            tooltipEl.style.fontSize = '12px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transition = 'opacity 0.1s ease';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          tooltipEl.innerHTML = tooltipModel.body.map(item => item.lines).join('<br>');

          const position = context.chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        },
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis labels
        grid: {
          display: false, // Hide x-axis grid lines
        },
        min: 0,
        max: 4, // Set your custom min and max values for the x-axis
      },
      y: {
        display: false, // Hide y-axis labels
        grid: {
          display: false, // Hide y-axis grid lines
        },
        min: 0,
        max: 1000, // Set your custom min and max values for the y-axis
      },
    },
  };
  const [flash, setFlash] = useRecoilState(flashAtom);
  useEffect(() => setFlash(false), []);
  useEffect(() => {
    let timeout;
    if (flash) {
      timeout = setTimeout(() => {
        setFlash(false);
      }, 200);
    }

    return () => clearTimeout(timeout);
  }, [flash]);
  return (
    <div
      style={{
        width: "60px",
        height:
          index === 0 || 8 - index === 0
            ? "200px"
            : index === 1 || 8 - index === 1
            ? "170px"
            : index === 2 || 8 - index === 2
            ? "140px"
            : index === 3 || 8 - index === 3
            ? "110px"
            : "80px",
        // backgroundColor: flash ? "#6999CF" : "#73D297",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "10px",
        position: "relative",
      }}
      className={`${
        flash ? "bg-[#6999CF]" : "bg-[#73D297]"
      } hover:bg-[#6999CF] `}
    >
      <div
        style={{
          position: "absolute",
          top:
            index === 0 || 8 - index === 0
              ? "-10rem"
              : index === 1 || 8 - index === 1
              ? "-10.95rem"
              : index === 2 || 8 - index === 2
              ? "-11.9rem"
              : index === 3 || 8 - index === 3
              ? "-12.8rem"
              : "-13.7rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "2px",
        }}
      >
        {label?.split(" ")?.map((lab) => (
          <div>{lab}</div>
        ))}
      </div>
      <div className="h-1/2 w-1/2">
        <Bar data={data} options={options} />
      </div>
      {/* <div style={{ color: "white" }}>{value}</div> */}
    </div>
  );
};

export default Box;
