import React, { useState, useRef, useEffect } from "react";
import GridLayout from "react-grid-layout";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { FaLock, FaUnlock, FaPencilAlt, FaDownload, FaExpand, FaUndo, FaChartBar, FaTable } from 'react-icons/fa';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Select, Table, Tooltip } from "antd";
import html2canvas from 'html2canvas';
import { CloseOutlined, DatabaseOutlined } from "@ant-design/icons";
import chatData from "./chatData.json";
import chatData2 from "./chatData2.json";
import { toastSampleDAtom } from "../../store";
import { useRecoilValue } from "recoil";

// const sampleD = [
//   {
//     tileName: "Chart1",
//     type: 'Bar',
//     chartData: chatData.map(data => ({ "xValue": data.label, "yValue": data.restaurants })),
//     tableData: [
//       { "xValue": "1-800 CONTACTS", "yValue": "$1B" },
//       { "xValue": "1800wheelchair.com", "yValue": "$250M" },
//       { "xValue": "4Life", "yValue": 250000000 },
//       { "xValue": "A1 Supplements", "yValue": 1000000000 },
//       { "xValue": "Accessible Vans of America", "yValue": 546200000 },
//       { "xValue": "Active&Fit Direct", "yValue": 1000000000 },
//       { "xValue": "Adventist Health", "yValue": 1000000000 },
//       { "xValue": "Aetna Medicare", "yValue": 1000000000 },
//       { "xValue": "Alcon", "yValue": 1000000000 },
//       { "xValue": "Anytime Fitness", "yValue": 1000000000 }
//     ]
//   },
//   {
//     tileName: "Table1",
//     type: 'Table',
//     chartData: chatData2.map(data => ({ "xValue": data.label, "yValue": data.google })),
//     tableData: [
//       { "xValue": "1-800 CONTACTS", "yValue": "$1B" },
//       { "xValue": "1800wheelchair.com", "yValue": "$250M" },
//       { "xValue": "4Life", "yValue": 250000000 },
//       { "xValue": "A1 Supplements", "yValue": 1000000000 },
//       { "xValue": "Accessible Vans of America", "yValue": 546200000 },
//       { "xValue": "Active&Fit Direct", "yValue": 1000000000 },
//       { "xValue": "Adventist Health", "yValue": 1000000000 },
//       { "xValue": "Aetna Medicare", "yValue": 1000000000 },
//       { "xValue": "Alcon", "yValue": 1000000000 },
//       { "xValue": "Anytime Fitness", "yValue": 1000000000 }
//     ]
//   },
//   {
//     tileName: "Chart3",
//     type: 'Bar',
//     chartData: chatData2.map(data => ({ "xValue": data.label, "yValue": data.yelp })),
//     tableData: [
//       { "xValue": "1-800 CONTACTS", "yValue": "$1B" },
//       { "xValue": "1800wheelchair.com", "yValue": "$250M" },
//       { "xValue": "4Life", "yValue": 250000000 },
//       { "xValue": "A1 Supplements", "yValue": 1000000000 },
//       { "xValue": "Accessible Vans of America", "yValue": 546200000 },
//       { "xValue": "Active&Fit Direct", "yValue": 1000000000 },
//       { "xValue": "Adventist Health", "yValue": 1000000000 },
//       { "xValue": "Aetna Medicare", "yValue": 1000000000 },
//       { "xValue": "Alcon", "yValue": 1000000000 },
//       { "xValue": "Anytime Fitness", "yValue": 1000000000 }
//     ]
//   }
// ];

const backgroundColors = [
  "#E07E65",
  "#F39F8E",
  "#8E4E2A",
  "#653712",
  "#F9B8A6",
];
const {Option} = Select 
const ToastDashboard = () => {
  const sampleD = useRecoilValue(toastSampleDAtom);

  const [sampleData, setSampleData] = useState(sampleD)
  const [layout, setLayout] = useState(
    sampleD.map((data, index) => ({
      i: index.toString(),
      x: (index % 1) * 4,
      y: Math.floor(index / 3) * 4,
      w: 4,
      h: 4,
      static: false,
      minW: 2,
      minH: 2,
    }))
  );
  const [sampleBlocks, setSampleBlocks] = useState ([
    {blockName: "Summary Block for Charts:- ", blockData: "Summaryyyyy!!!!!"},
    {blockName: "Recommendation Block for Charts:- ", blockData: "Recommendationnnn!!!!!"}
  ])
  const [blockLayout, setBlockLayout] = useState(
    sampleBlocks.map((data, index) => ({
      i: `block_${index}`,
      x: 0, // Start from the first column
      y: index * 2, // Position each block in a new line
      w: 12, // Full width
      h: 2, // Height of 2 rows
      static: false,
      minW: 2,
      minH: 2,
    }))
  );
  const [layoutHistory, setLayoutHistory] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalChartData, setModalChartData] = useState(null);
  const [modalChartType, setModalChartType] = useState(null);
  const [modalChartIndex, setModalChartIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showChart, setShowChart] = useState(true); 
  const chartRefs = useRef([]);
  const [showSelector, setShowSelector] = useState(false)
  const [selectedTiles, setSelectedTiles] = useState([]);
  

  const handleTileSelection = (selectedItems) => {
    setSelectedTiles(selectedItems);

  };
  const handleKeyDown = (event) => {
    if (event.key === '/') {
      setShowSelector(prevState => !prevState); 
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const saveCurrentLayout = () => {
    setLayoutHistory((prevHistory) => [...prevHistory, layout]);
  };

  const toggleLock = (index) => {
    saveCurrentLayout();
    setLayout((prevLayout) =>
      prevLayout.map((item, i) => (i === index ? { ...item, static: !item.static } : item))
    );
  };

  const handleLayoutChange = (newLayout) => {
    if (isEditMode) {
      saveCurrentLayout();
      setLayout(newLayout);
    }
  };

  const renderTable = (data, type, index) => {
      // Render table here
      console.log(data)
      return (
        <div className="w-full">
          <table className="table-fixed w-full overflow-scroll">
            <thead>
              <tr className="bg-gray-200 text-gray-700">
                {Object.keys(data[0])?.map((key, idx) => (
                  <th key={idx} className="px-4 py-2">{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, idx) => (
                <tr key={idx} className={idx % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                  {Object.values(item).map((value, index) => (
                    <td key={index} className="border px-4 py-2">{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
  };

  const renderChart = (data, type, index, chartOptions) => {
      // const chartData = {
      //   labels: data.map(item => item.xValue),
      //   datasets: [
      //     {
      //       label: index === 0 ? 'Distribution of Yelp Ratings for Boston Restaurants' : index === 1 ? 'Source: Google' : 'Source: Yelp',
      //       data: data.map(item => item.yValue),
      //       backgroundColor: backgroundColors,
      //     }
      //   ]
      // };

      const barGridLineoOptions = {
        scales: {
          x: {
            grid: {
              display: false, // Disable x-axis grid lines
            },
          },
          y: {
            grid: {
              display: false, // Disable y-axis grid lines
            },
          },
        },
      };

      switch (type) {
        case 'Bar':
          return <Bar 
            // data={chartData}
            data={data} 
            // options={barGridLineoOptions}
            options={chartOptions} 
            ref={(el) => {console.log(el); return chartRefs.current[index] = el}} 
          />;
        case "Table":
          return renderTable(data, type, index);
        // case 'Doughnut':
        //   return <Doughnut data={chartData} ref={(el) => chartRefs.current[index] = el} />;
        // case 'Line':
        //   return <Line data={chartData} ref={(el) => chartRefs.current[index] = el} />;
        default:
          return null;
      } 
  };

  const downloadChart = (index) => {
    const chart = chartRefs.current[index];
    if (chart && chart.canvas) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = chart.canvas.width;
      canvas.height = chart.canvas.height + 30;

      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, 30);
      context.fillStyle = 'black';
      context.font = '20px Arial';
      context.fillText(`Chart ${index + 1}`, 10, 25);

      context.drawImage(chart.canvas, 0, 30);

      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `chart-${index + 1}.png`;
      link.click();
    }
  };

  const expandChart = (data, type, index) => {
    setModalChartData(data);
    setModalChartType(type);
    setModalChartIndex(index);
    setShowModal(true);
    setShowChart(true); // Show chart by default
  };

  const handleUndo = () => {
    setSampleData(sampleD)
    setLayout(sampleD.map((data, index) => ({
      i: index.toString(),
      x: (index % 1) * 4,
      y: Math.floor(index / 3) * 4,
      w: 4,
      h: 4,
      static: false,
      minW: 2,
      minH: 2,
    })))
    setBlockLayout(
      sampleBlocks.map((data, index) => ({
        i: `block_${index}`,
        x: 0, // Start from the first column
        y: index * 2, // Position each block in a new line
        w: 12, // Full width
        h: 2, // Height of 2 rows
        static: false,
        minW: 2,
        minH: 2,
      }))
    );
  };
  const contentRef = useRef(null);

  const handleDownload = () => {
    if (contentRef.current) {
      html2canvas(contentRef.current).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'dashboard.png';
        link.click();
      });
    }
  };
  const deleteChart = (index) => {
    setSampleData(sampleData.filter((_, idx) => idx !== index))
    setLayout(layout.filter(l => Number(l.i) !== index))
  }
  return (
    <div className="flex flex-col justify-center">
      {!showSelector && <div className="flex justify-center">
          <span className="mt-2 text-[gray]">Press "/" and select the report to create a summary & recommendation block. You can do this for multiple reports</span>
        </div>
      }
      {showSelector && (
        <div className="new-tile-popup mt-2">
          <Select
            placeholder="Select charts to view summary"
            style={{ minWidth: "20%" }}
            mode="multiple"
            value={selectedTiles}
            onChange={handleTileSelection}
            options={sampleData.map(option => ({
              value: option.tileName,
              label: option.tileName
            }))}
          />
        </div>
      )}
      <div className="flex mt-4 justify-end items-center w-[98%] p-2 bg-gray-300">
        <FaPencilAlt className="cursor-pointer mr-4" onClick={() => {
          setIsEditMode(!isEditMode);
          if (!isEditMode) {
            saveCurrentLayout();
          }
        }} />
        {isEditMode && <FaUndo className="cursor-pointer mr-4" onClick={handleUndo} />}
        {!isEditMode && <FaDownload className="cursor-pointer mr-4" onClick={handleDownload} />}
      </div>
      <div ref={contentRef}>
      {selectedTiles.length > 0 &&  <>
<GridLayout
            className="layout ml-3 w-[95%]"
            layout={blockLayout}
            cols={12}
            rowHeight={75}
            width={1200}
            onLayoutChange={setBlockLayout}
            draggableHandle=".drag-handle"
            isDraggable={isEditMode}
            isResizable={isEditMode}
          >
            {sampleBlocks.map((block, index) => (
              <div key={`block_${index}`}  className=" drag-handle cursor-move relative bg-gray-100 border p-2">
                <div className="font-bold">{block.blockName + " " + selectedTiles.join(", ") }</div>
                <div className="font-light">{block.blockData}</div>
              </div>
            ))}
          </GridLayout>
      </>}
      <div className="flex gap-2 justify-center max-w-[100%] overflow-x-scroll">
      <GridLayout
        className="layout ml-3 w-[95%]"
        layout={layout}
        cols={12}
        rowHeight={75}
        width={2800}
        onLayoutChange={handleLayoutChange}
        draggableHandle=".drag-handle"
        isDraggable={isEditMode}
        isResizable={isEditMode}
      >
        {layout.map((item, index) => (
          <div
            key={index}
            className="bg-white p-4 border rounded shadow-md overflow-scroll"
            data-grid={item}
          >
            <div className="flex justify-between items-center bg-gray-200 p-2">
              <span className="w-[60%]">
                {sampleData[index].tileName}
              </span>
              <div className="flex justify-end w-[40%]">
                <FaDownload className="cursor-pointer mx-1" onClick={() => downloadChart(index)} />
                {/* Expand is a little tricky since layout hase 3 possibilities now: 1) Chart, 2) Table, 3) Image */}
                <FaExpand 
                  className="cursor-pointer mx-1" 
                  // onClick={() => expandChart(sampleData[index].chartData, sampleData[index].type, index)} 
                />
                <Tooltip title={"MetaData"} placement="right" color="#8EA9D0" >
                  <DatabaseOutlined className="mx-1 cursor-pointer"/>
                </Tooltip>
                {isEditMode && (
                  <>
                    <span onClick={() => toggleLock(index)} className="cursor-pointer mx-1">
                      {item.static ? <FaLock /> : <FaUnlock />}
                    </span>
                    <span onClick={() => deleteChart(index)} className="cursor-pointer mx-0.5 -mt-[0.2rem]">
                      <CloseOutlined />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className={`drag-handle ${isEditMode ? 'cursor-move' : ''}`} style={{ height: 'calc(100% - 40px)' }}>
              {renderChart(sampleData[index].chartData, sampleData[index].type, index, sampleData[index]?.chartOptions)}
            </div>
          </div>
        ))}
      </GridLayout>
      </div>

      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="bg-white p-4 rounded shadow-lg w-full h-[90%] max-w-3xl max-h-full overflow-auto">
            <button className="float-right text-xl" style={{fontWeight: 500}} onClick={() => setShowModal(false)}>x</button>
            <div className="flex justify-center cursor-pointer items-center mt-2 mb-4">
              {!showChart && <FaChartBar onClick={() => setShowChart(true)} />}
              {showChart && <FaTable onClick={() => setShowChart(false)}/>}  
            </div>
            <div className="h-[80vh] flex items-center justify-center">
              { showChart ? renderChart(modalChartData, modalChartType, modalChartIndex) : renderTable(modalChartData, modalChartType, 'modal')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToastDashboard;
