import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { activeAudienceAtom } from '../../store';
import AudienceComponent from '../../components/AudienceComponents/AudienceComponents';
import { Table, Input, Button, Typography, Space, message } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode'; // Assuming jwtDecode is imported for decoding the token
import { liveData } from '../../services/InsightsServices';
const { Title } = Typography;

const zillaTable = [
  {
    key: '1',
    name: 'Lead Enrichment',
    type: 'Agent',
    status: 'Active',
    lastUpdated: '07-19-2024',
    source: 'CRM',
    destination: 'CSV',
    owner: 'Zillasecurity',
    agent: 'aid007635648'
  },
  {
    key: '2',
    name: 'Champion Tracking',
    type: 'Agent',
    status: 'Active',
    lastUpdated: '07-19-2024',
    source: 'CRM',
    destination: 'CSV',
    owner: 'Zillasecurity',
    agent: 'aid009837793'
  },
];

const icustomerTable = [
  {
    key: '1',
    name: 'Lead Enrichment',
    type: 'Agent',
    status: 'Active',
    lastUpdated: '08-21-2024',
    source: 'CRM',
    destination: 'CSV',
    owner: 'iCustomer',
    agent: 'aid008476572'
  },
];

const columns = (fetchData) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    key: 'owner',
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
  },
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdated',
    key: 'lastUpdated',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => (
      <Space size="middle">
        <Button
          type="link"
          icon={<DownloadOutlined />}
          onClick={() => fetchData(record.agent)}
        />
      </Space>
    ),
  },
];

const Live = () => {
  const [activeAudienceComp, setActiveAudienceComp] = useRecoilState(activeAudienceAtom);

  useEffect(() => {
    setActiveAudienceComp(0);
  }, [setActiveAudienceComp]);

  const fetchData = async (agent) => {
    try {
      const token = localStorage.getItem("loginToken");
      const decodedToken = jwtDecode(token?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ""));
      const payload = {
        organisation_id: decodedToken.organisation_domain == "zillasecurity.com" ? '9c37cdef-41ca-4a80-a682-87d0cf6b184b' : '0e326d3e-21b9-4855-ac45-c2f659615ec3',
        agent_id: agent
      };
      const response = await liveData(payload);

      if (response) {
        message.success('Data fetched successfully');
        downloadCSV(response.data.data)
        // You can handle the response data here if needed
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      message.error("Error fetching dashboard data");
    }
  };
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const csvRows = [headers.join(',')]; // Create the CSV header row

    data.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape double quotes
        return `"${escaped}"`; // Wrap each value in double quotes
      });
      csvRows.push(values.join(',')); // Join values into a CSV row
    });

    return csvRows.join('\n'); // Join rows into a CSV string
  }
  function downloadCSV(data) {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv'); // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const decodedToken = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''));
  const dataSource = decodedToken?.organisation_id === '9c37cdef-41ca-4a80-a682-87d0cf6b184b' ? zillaTable : icustomerTable;

  return (
    <div style={{ padding: '20px' }}>
      <header className='text-[#8EA9D0]' style={{ marginBottom: '20px' }}>
        <Title style={{ fontSize: "18px", marginLeft: "20px" }} level={5} type="secondary">View all production items</Title>
      </header>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          placeholder="Search by name, type, or owner..."
          prefix={<SearchOutlined />}
          style={{ width: '70%' }}
        />
        <div>
          <Button type="primary" style={{ marginRight: '10px' }}>Filter</Button>
          <Button>Customize Columns</Button>
        </div>
      </div>

      <Table dataSource={dataSource} columns={columns(fetchData)} pagination={true} />
    </div>
  );
};

export default Live;
