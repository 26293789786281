import { useCallback } from "react";
import { Handle, Position } from "@xyflow/react";

const handleStyle = { left: 10 };

function EnrichmentTextNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
    console.log(data);
  }, []);

  return (
    <div className="border border-[#eee] rounded-[5px] bg-[#FEF9C3] max-w-[30rem] text-start p-2">
      {/* <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      /> */}
      <div className="flex flex-col justify-start items-start text-3xl font-bold">
        {data?.data?.label}
      </div>
      <div className="text-xl mt-2">
        {data?.data?.text}
      </div>
      {/* {data?.id === "node-1" && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          style={handleStyle}
          isConnectable={isConnectable}
        />
      )} */}
      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      /> */}
    </div>
  );
}

export default EnrichmentTextNode;
