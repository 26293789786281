import React, { useCallback, useState, useEffect } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
  ReactFlowProvider
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import IONode from "../../components/GumloopComponents/IONode.js";
import TextNode from "../../components/GumloopComponents/TextNode.js";
import EnrichCompanyNode from "../../components/GumloopComponents/EnrichCompanyNode.js";
import SummarizerNode from "../../components/GumloopComponents/SummarizerNode.js";

import MainHeader from "../../components/GumloopComponents/MainHeader.js";
import SideOpt from "../../components/GumloopComponents/SideOpt.js";

import inputImg from "../../assets/images/inputImg.png";
import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";

import CustomEdgeStartEnd from '../../components/GumloopComponents/CustomEdgeStartEnd.js';

import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms.js";
import { focusNodeIdAtom, newNodeAtom, contactAtom, slackAtom, fitEntireAtom, nodeInProgressAtom } from "../../store/index.js";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";

import RightSider from "../../components/GumloopComponents/RightSider.js";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { collapsibleAtom, testLoadingAtom } from "../../store/index.js";

export const initialNodes = [
  {
    id: "node-1",
    type: "ioNode",
    position: { x: 0, y: 0 },
    data: {
      label: "User Email",
      icon: inputImg,
      firstKey: "Default Value:",
      firstVal: "max@hubspot.com",
      bgColor: "grey",
    },
  },
  {
    id: "node-4",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 422, y: 366 },
    data: {
      label: "Split Text",
      icon: splitTextImg,
      bgColor: "peach",
    },
  },
  {
    id: "node-5",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 422, y: 550 },
    data: {
      label: "Get List Item",
      icon: getListImg,
      bgColor: "peach",
    },
  },
  {
    id: "node-6",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: -29.8, y: 1343 },
    data: {
      label: "Combine Text",
      icon: combineTextImg,
      bgColor: "peach",
      firstKey: true
    },
  },
  {
    id: "node-7",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: { x: 404, y: 34 },
    data: {
      label: "Input someone's corporate email like tim@apple.com"
    },
  },
  {
    id: "node-8",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: { x: 843.66, y: 394.22 },
    data: {
      label: "Here, we are splitting email on the @ because all we care about is the domain name for research purposes!"
    },
  },
  {
    id: "node-9",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: { x: -528.8, y: 842 },
    data: {
      label: "Use the enrich company info node to find details about their company like revenue & industry"
    },
  },
  {
    id: "node-10",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: { x: 1282, y: 916 },
    data: {
      label: "Scrape their website & summarize what they do"
    },
  },
  {
    id: "node-11",
    // type: 'output',
    type: "textNode",
    targetPosition: "top",
    position: { x: -629.64, y: 1361.18 },
    data: {
      label: "Format all the data into a nice concise message & output it",
      text: "Replace this output node with any number of nodes like the slack notification sender, email sender, etc."
    },
  },
  {
    id: "node-12",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 864, y: 790 },
    data: {
      label: "Advanced Website Scraper",
      icon: webScraperImg,
      bgColor: "blue",
    },
  },
  {
    id: "node-13",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: 158, y: 788 },
    data: {
      label: "Enrich Company Information",
      icon: companyImg,
      bgColor: "blue",
      firstKey: "Domain Name",
      firstVal: "hubspot.com"
    },
  },
  {
    id: "node-14",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: 796, y: 1102 },
    data: {
      label: "Summarizer",
      icon: summaryImg,
      bgColor: "green",
      firstKey: true
    },
  },
  {
    id: "node-3",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 60.26, y: 1764 },
    data: {
      label: "Output",
      icon: outputImg,
      firstKey: "Output Name:",
      firstVal: "research_results",
      bgColor: "grey",
    },
  },
];

const initialEdges = [
  {
    id: "edge-1",
    source: "node-1",
    target: "node-4",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'output', suppText: 'type = text' },
      endLabel: { mainText: 'text', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-3",
    source: "node-4",
    target: "node-5",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      endLabel: { mainText: 'list', suppText: 'type = List of text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-4",
    source: "node-5",
    target: "node-12",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'item', suppText: 'type = text' },
      endLabel: { mainText: 'url', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-5",
    source: "node-5",
    target: "node-13",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'item', suppText: 'type = text' },
      endLabel: { mainText: 'domain name', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-6",
    source: "node-12",
    target: "node-14",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'website content', suppText: 'type = text' },
      endLabel: { mainText: 'text', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-7",
    source: "node-6",
    target: "node-3",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'combined text', suppText: 'type = text' },
      endLabel: { mainText: 'output', suppText: 'type = string' },
    },
    type: 'start-end',
  },
  {
    id: "edge-8",
    source: "node-1",
    target: "node-6",
    targetHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'output', suppText: 'type = text' },
      endLabel: { mainText: 'input1', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-9",
    source: "node-14",
    target: "node-6",
    targetHandle: "j",
    animated: true,
    data: {
      startLabel: { mainText: 'summary', suppText: 'type = text' },
      endLabel: { mainText: 'input9', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-10",
    source: "node-13",
    target: "node-6",
    targetHandle: "f",
    sourceHandle: "a",
    animated: true,
    data: {
      startLabel: { mainText: 'company name', suppText: 'type = text' },
      endLabel: { mainText: 'input5', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-11",
    source: "node-13",
    target: "node-6",
    targetHandle: "g",
    sourceHandle: "d",
    animated: true,
    data: {
      startLabel: { mainText: 'industry', suppText: 'type = text' },
      endLabel: { mainText: 'input6', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-12",
    source: "node-13",
    target: "node-6",
    targetHandle: "h",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'annual revenue', suppText: 'type = text' },
      endLabel: { mainText: 'input7', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-13",
    source: "node-13",
    target: "node-6",
    targetHandle: "i",
    sourceHandle: "c",
    animated: true,
    data: {
      startLabel: { mainText: 'country', suppText: 'type = text' },
      endLabel: { mainText: 'input8', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-14",
    source: "node-16",
    target: "node-6",
    targetHandle: "b",
    sourceHandle: "a",
    animated: true,
    data: {
      startLabel: { mainText: 'first name', suppText: 'type = text' },
      endLabel: { mainText: 'input1', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-15",
    source: "node-16",
    target: "node-6",
    targetHandle: "c",
    sourceHandle: "d",
    animated: true,
    data: {
      startLabel: { mainText: 'last name', suppText: 'type = text' },
      endLabel: { mainText: 'input2', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-16",
    source: "node-16",
    target: "node-6",
    targetHandle: "d",
    sourceHandle: "b",
    animated: true,
    data: {
      startLabel: { mainText: 'linkedin url', suppText: 'type = text' },
      endLabel: { mainText: 'input3', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-17",
    source: "node-16",
    target: "node-6",
    targetHandle: "a",
    sourceHandle: "c",
    animated: true,
    data: {
      startLabel: { mainText: 'job title', suppText: 'type = text' },
      endLabel: { mainText: 'input4', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-18",
    source: "node-15",
    target: "node-4",
    sourceHandle: "d",
    animated: true,
    data: {
      startLabel: { mainText: 'output', suppText: 'type = text' },
      endLabel: { mainText: 'text', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-19",
    source: "node-15",
    target: "node-16",
    animated: true,
    data: {
      startLabel: { mainText: 'output', suppText: 'type = text' },
      endLabel: { mainText: 'domain name', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-20",
    source: "node-3",
    target: "node-17",
    animated: true,
    data: {
      startLabel: { mainText: 'output', suppText: 'type = string' },
      endLabel: { mainText: 'slack message', suppText: 'type = string' },
    },
    type: 'start-end',
  },
];

const nodeTypes = {
  ioNode: (data) => <IONode data={data} />,
  textNode: (data) => <TextNode data={data} />,
  companyNode: (data) => <EnrichCompanyNode data={data} />,
  summarizerNode: (data) => <SummarizerNode data={data} />,
};

const edgeTypes = {
  'start-end': CustomEdgeStartEnd,
};

const Flow = (props) => {
  const { fitView, getNode } = useReactFlow();
  const [focusNodeId, setFocusNodeId] = useRecoilState(focusNodeIdAtom);

  useEffect(() => {
    if (focusNodeId) {
      if (focusNodeId === "whole") {
        fitView({ duration: 800 });
      } else {
        const node = getNode(focusNodeId);
        if (node) {
          fitView({ nodes: [node], duration: 800 });
        }
      }
      setFocusNodeId(null);
    }
  }, [focusNodeId, fitView, getNode]);

  return (
    <ReactFlow {...props} >
      <Controls position="bottom-right" />
      <Background variant="dots" gap={20} size={1} />
    </ReactFlow>
  );
}


export const Gumloop = () => {
  const setChatMode = useSetRecoilState(chatModeAtom);
  const setSideNavContent = useSetRecoilState(sideNavContentAtom);
  const [newNode, setNewNode] = useRecoilState(newNodeAtom);
  const [contact, setContact] = useRecoilState(contactAtom);
  const [slack, setSlack] = useRecoilState(slackAtom);
  const [fitEntire, setFitEntire] = useRecoilState(fitEntireAtom);
  const setFocusNodeId = useSetRecoilState(focusNodeIdAtom);
  const [isCollapsed, setIsCollapsed] = useRecoilState(collapsibleAtom)
  const testLoading = useRecoilValue(testLoadingAtom);
  const nodeInProgress = useRecoilValue(nodeInProgressAtom);

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    console.log(nodes)
  }, [nodes])
  
  useEffect(() => {
    localStorage.setItem("path", "gumloop");
    setChatMode(1);
    setSideNavContent(1);
  }, [])

  const replaceNode = (oldNodeId, newNode) => {
    setNodes((nds) => nds.map((node) => (node.id === oldNodeId ? newNode : node)));
  };

  const effectHandler = async () => {
    if (newNode) {
      const hubspotNode = {
        id: "node-15",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -72, y: -33 },
        data: {
          label: "Hubspot Contact Reader",
          icon: hubspotImg,
          bgColor: "blue",
          firstKey: "Outputs",
          firstVal: "hubspot.com"
        },
      }
      replaceNode('node-1', hubspotNode)
      setNewNode(false);
    }
    if (contact) {
      const contactNode = {
        id: "node-16",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -528.8, y: 842 },
        data: {
          label: "Enrich Contact Information",
          icon: companyImg,
          bgColor: "blue",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      replaceNode('node-9', contactNode)
      setContact(false);
      setTimeout(() => {
        setFocusNodeId("whole")
      }, 500)
      await new Promise(r => setTimeout(r, 1000))
      setTimeout(() => {
        setFocusNodeId("node-16")
      }, 500)
    }
    if (slack) {
      const slackNode = {
        id: "node-17",
        // type: 'output',
        type: "summarizerNode",
        targetPosition: "top",
        position: { x: 55, y: 2098 },
        data: {
          label: "Slack Message Sender",
          icon: slackImg,
          bgColor: "pink",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      setNodes(prevNodes => [...prevNodes, slackNode])
      setSlack(false);
      setTimeout(() => {
        setFocusNodeId("whole")
      }, 500)
      await new Promise(r => setTimeout(r, 1000))
      setTimeout(() => {
        setFocusNodeId("node-17")
      }, 500)
    }
    if (fitEntire) {
      setFitEntire(false);
      setTimeout(() => {
        setFocusNodeId("whole")
      }, 500)
    }
  }

  useEffect(() => {
    effectHandler()
  }, [newNode, contact, slack, fitEntire])

    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

  useEffect(() => {
    console.log(nodeInProgress, "nodeInProgress----------->")
    setFocusNodeId(nodeInProgress)
  }, [nodeInProgress])

  return (
    <>
      <MainHeader />
      <div style={{ width: "100%", height: "80vh" }} className="p-2 relative">
        <SideOpt />
        <ReactFlowProvider>
          <Flow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            proOptions={{ hideAttribution: true }}
            fitView
            panOnScroll
            selectionOnDrag
            minZoom={0.2}
            maxZoom={2}
          />
        </ReactFlowProvider>
        {testLoading && (
          <>
            <button onClick={toggleCollapse} className={`absolute ${isCollapsed ? 'right-16' : 'right-[20rem]'} top-12 z-50 duration-300 ease-in-out`}>
              {isCollapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
            </button>
            <RightSider />
          </>
        )}
      </div>
    </>
  );
};
