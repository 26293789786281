import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AgentNavBar from '../AgentNavBar/AgentNavBar';
import { Typography } from 'antd';

const AgentTopBar = () => {
  const [topName, setTopName] = useState("")
  const { Title } = Typography

  const { pathname } = useLocation();

  useEffect(() => {
    if (['/agents', '/templates/sales_crm', '/templates/enrichment', '/templates/lead', '/templates/prog'].includes(pathname)) {
      setTopName("Achieve goals and outcome via powerful agentic automation with guardrails")
    } else if (pathname === "/playground") {
      setTopName("Agent's Name")
    }
  }, [pathname])

  return (
    <div className='flex flex-col justify-center gap-6'>
      <header className='text-[#8EA9D0]'>
        <Title style={{ fontSize: "18px" }} level={5} type="secondary">{topName}</Title>
      </header>
      {/* <p className={`text-character h3_Medium -mt-1 ${pathname !== '/agents' && 'border-b pb-6'}`}>{topName}</p> */}
      {pathname === '/agents' && <AgentNavBar />}
    </div>
  )
}

export default AgentTopBar