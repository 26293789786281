import { useCallback } from "react";
import { Handle, Position } from "@xyflow/react";
import Head from "./Head";
import IOMain from "./IOMain";
import Main from "./Main";

const handleStyle = { left: 20 };

function IONode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
    console.log(data);
  }, []);

  return (
    <div className={`border border-[#eee] rounded-[5px] bg-white ${data?.id === "node-6" ? 'min-w-[38rem]' : 'min-w-[20rem]'}`}>
      {data?.id !== "node-1" && (
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id="a"
          style={data?.id === "node-6" ? { left: 185 } : null}
        />
      )}
      {data?.id === "node-6" && (
        <>
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 14 }}
            id="b"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 72 }}
            id="c"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 130 }}
            id="d"
          />
          {/* <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 244 }}
            id="e"
          /> */}
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 300 }}
            id="f"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 372 }}
            id="g"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 430 }}
            id="h"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 494 }}
            id="i"
          />
          <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            style={{ left: 550 }}
            id="j"
          />
        </>
      )}

      <div className="flex flex-col justify-start items-start">
        <Head data={data} />
        {data?.data?.firstKey === true ? (
          <IOMain data={data} />
        ) : !data?.data?.firstKey ? (
          <></>
        ) : (
          <Main data={data} />
        )}
      </div>
      {/* {data?.id !== "node-3" && ( */}
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
        />
      {/* )} */}
    </div>
  );
}

export default IONode;
