import { Client } from './Client.js'

export const createSession = async (formdata) => {
    try {
      return await Client.post('/waterfall/createSession', formdata);
    } catch(error){
      return error;
    }
}

export const startAgent = async (formdata) => {
  try {
    return await Client.post('/waterfall/startAgent', formdata);
  } catch(error){
    return error;
  }
}

export const pollAgent = async (formdata) => {
  try {
    return await Client.post('/waterfall/pollAgent', formdata);
  } catch(error){
    return error;
  }
}

export const chatAgent = async (formdata) => {
  try {
    return await Client.post('/waterfall/chatAgent', formdata);
  } catch(error){
    return error;
  }
}