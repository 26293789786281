import { Radio, Select } from "antd";
import React, { useState } from "react";

import { ArrowRightOutlined } from "@ant-design/icons";

import { useSetRecoilState } from "recoil";
import hubspot from "../../assets/images/hubspot.png";
import list from "../../assets/images/list.png";
import refreshBlackImg from "../../assets/images/refresh.png";
import refreshImg from "../../assets/images/refreshWhite.png";
import snowflake from "../../assets/images/snowflakeImg.png";
import textImg from "../../assets/images/textImg.png";
import { activeSegmentAtom } from "../../store";
import AgentCustomBtn from "../AgentCustomBtn/AgentCustomBtn";

const ConfigureSync = () => {
  const [value, setValue] = useState(1);
  const [secValue, setSecValue] = useState(1);
  const [mapping, setMapping] = useState(false);
  const [mappedValue, setMappedValue] = useState(null);
  const [suggestMapping, setSuggestMapping] = useState(false)

  const setActiveSegment = useSetRecoilState(activeSegmentAtom);

  const onChange = (e, type) => {
    if (type === 1) {
      setValue(e.target.value);
    } else {
      setSecValue(e.target.value);
    }
  };

  const mapChange = (val) => {
    if (val === "First Name") setMappedValue("first name");
    else setMappedValue(null);
  };

  const handleSuggest = () => {
    if (mappedValue) {
      setSuggestMapping(true);
    }
  };

  return (
    <>
      <div className="h4_Medium text-primary px-32 py-4 flex items-center gap-3">
        <img src={hubspot} alt="hbImg" width={24} />
        <p>Configure Sync to Hubspot</p>
      </div>
      <div className="px-36 flex flex-col justify-center gap-10">
        <div className="flex flex-col gap-2 justify-center border-b border-character pb-10">
          <p className="h5_Regular">
            What would you like iCustomer to Send to Hubspot?
          </p>
          <Radio.Group
            onChange={(e) => onChange(e, 1)}
            value={value}
            className="flex flex-col gap-2"
          >
            <Radio
              value={1}
              className={`${
                value === 1 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Object</p>
                <p className="body_Regular text-secondary">
                  Sync records to objects such as users or organizations in
                  Hubspot.
                </p>
              </div>
            </Radio>
            <Radio
              value={2}
              className={`${
                value === 2 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Event</p>
                <p className="body_Regular text-secondary">
                  Sync records as events to Hubspot. This is often in the form
                  of a track call.
                </p>
              </div>
            </Radio>
            <Radio
              value={3}
              className={`${
                value === 3 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Segment</p>
                <p className="body_Regular text-secondary">
                  Create, sync, and keep up-to-date the query result as a
                  segment in Hubspot.
                </p>
              </div>
            </Radio>
          </Radio.Group>
        </div>
        <div className="flex flex-col justify-center gap-2 border-b border-character pb-10">
          <p className="h5_Regular">
            Which Hubspot Object would you like to sync data to?
          </p>
          <div className="flex items-center gap-2 w-1/2">
            <Select
              placeholder="Select an object..."
              className="w-2/3"
              options={[
                { label: "calls", value: "calls" },
                { label: "companies", value: "companies" },
                { label: "contacts", value: "contacts" },
                { label: "deals", value: "deals" },
                { label: "emails", value: "emails" },
                { label: "line items", value: "line items" },
                { label: "meetings", value: "meetings" },
                { label: "notes", value: "notes" },
                { label: "postal_mail", value: "postal_mail" },
                { label: "products", value: "products" },
              ]}
            />
            <AgentCustomBtn otherClasses={"flex items-center gap-2"}>
              <img src={refreshImg} alt="refresh" width={14} />
              <p>Refresh</p>
            </AgentCustomBtn>
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-center border-b border-character pb-10">
          <p className="h5_Regular">
            How do you want to update the records in Hubspot?
          </p>
          <Radio.Group
            onChange={(e) => onChange(e, 2)}
            value={secValue}
            className="flex flex-col gap-2"
          >
            <Radio
              value={1}
              className={`${
                secValue === 1 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Upsert</p>
                <p className="body_Regular text-secondary">
                  Push new records & update records that change in Snowflake.
                </p>
              </div>
            </Radio>
            <Radio
              value={2}
              className={`${
                secValue === 2 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Update</p>
                <p className="body_Regular text-secondary">
                  Update particular fields on existing records.
                </p>
              </div>
            </Radio>
            <Radio
              value={3}
              className={`${
                secValue === 3 && "bg-light-primary"
              } hover:bg-light-primary rounded-md duration-300 ease-in-out flex gap-1.5 items-end p-2`}
            >
              <div className="flex flex-col gap-0.5 items-start justify-start ">
                <p className="h5_Regular">Insert</p>
                <p className="body_Regular text-secondary">
                  Push new records & do not update the records as they change in
                  Snowflake.
                </p>
              </div>
            </Radio>
          </Radio.Group>
        </div>
        <div className="flex flex-col gap-2 justify-center border-b border-character pb-10">
          <p className="h5_Regular">
            How should records between your query results & Hubspot be matched?
          </p>
          <p className="h5_Regular text-secondary">
            Records in the query results & destination are matched when the ID
            value is equivalent.
          </p>
          <div className="flex items-center my-2">
            <div className="w-1/2 flex items-center gap-1.5">
              <img src={snowflake} alt="test" width={14} />
              <p className="font-bold">Users</p>
              <div className="flex justify-center items-center border border-character p-1 rounded-full cursor-pointer">
                <img src={refreshBlackImg} alt="refreshBlack" width={14} />
              </div>
            </div>
            <div className="w-1/2 flex items-center gap-1.5">
              <img src={snowflake} alt="test" width={14} />
              <p>
                Field from <span className="font-bold">Hubspot</span>
              </p>
              <div className="flex justify-center items-center border border-character p-1 rounded-full cursor-pointer">
                <img src={refreshBlackImg} alt="refreshBlack" width={14} />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-1/2 flex items-center gap-1.5">
              <Select
                defaultValue={"Email"}
                options={[
                  { label: "Email", value: "Email" },
                  { label: "First Name", value: "First Name" },
                ]}
                className="w-[95%]"
              />
              <ArrowRightOutlined className="text-secondary" />
            </div>
            <div className="w-1/2 flex items-center gap-1.5">
              <Select
                defaultValue={"email"}
                options={[
                  {
                    label: (
                      <div className="flex items-center gap-1">
                        <img src={textImg} alt="text" width={14} />
                        <p>email (recommended)</p>
                      </div>
                    ),
                    value: "email",
                  },
                  {
                    label: (
                      <div className="flex items-center gap-1">
                        <img src={textImg} alt="text" width={14} />
                        <p>first name (recommended)</p>
                      </div>
                    ),
                    value: "first name",
                  },
                ]}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-center border-b border-character pb-10">
          <p className="h5_Regular">
            Which columns would you like to sync to Hubspot fields?
          </p>
          <p className="h5_Regular text-secondary">
            Configure how the columns in your query results should be mapped to
            fields in Hubspot
          </p>
          {mapping && (
            <>
              <div className="flex items-center my-2">
                <div className="w-1/2 flex items-center gap-1.5">
                  <img src={snowflake} alt="test" width={14} />
                  <p className="font-bold">Users</p>
                  <div className="flex justify-center items-center border border-character p-1 rounded-full cursor-pointer">
                    <img src={refreshBlackImg} alt="refreshBlack" width={14} />
                  </div>
                </div>
                <div className="w-1/2 flex items-center gap-1.5">
                  <img src={snowflake} alt="test" width={14} />
                  <p>
                    Field from <span className="font-bold">Hubspot</span>
                  </p>
                  <div className="flex justify-center items-center border border-character p-1 rounded-full cursor-pointer">
                    <img src={refreshBlackImg} alt="refreshBlack" width={14} />
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-1 my-2">
                <div className="w-1/2 flex items-center gap-1.5">
                  <Select
                    placeholder="Select a value..."
                    options={[
                      { label: "First Name", value: "First Name" },
                      { label: "Last Name", value: "Last Name" },
                      { label: "Email", value: "Email" },
                      {
                        label: "Predictive Model (1-100)",
                        value: "Predictive Model (1-100)",
                      },
                      { label: "Ad Conversion", value: "Ad Conversion" },
                      { label: "Phone", value: "Phone" },
                      { label: "Blog Post Views", value: "Blog Post Views" },
                      { label: "User ID", value: "User ID" },
                    ]}
                    className="w-[95%]"
                    onChange={mapChange}
                  />
                  <ArrowRightOutlined className="text-secondary" />
                </div>
                <div className="w-1/2 flex items-center gap-1.5">
                  <Select
                    placeholder="Select a field..."
                    value={mappedValue}
                    options={[
                      {
                        label: (
                          <div className="flex items-center gap-1">
                            <img src={textImg} alt="text" width={14} />
                            <p>email</p>
                          </div>
                        ),
                        value: "email",
                      },
                      {
                        label: (
                          <div className="flex items-center gap-1">
                            <img src={textImg} alt="text" width={14} />
                            <p>first name</p>
                          </div>
                        ),
                        value: "first name",
                      },
                    ]}
                    className="w-full"
                  />
                </div>
              </div>
              {suggestMapping && (
                <>
                  <div className="flex items-center gap-1 my-2">
                    <div className="w-1/2 flex items-center gap-1.5">
                      <Select
                        placeholder="Select a value..."
                        options={[
                          { label: "First Name", value: "First Name" },
                          { label: "Last Name", value: "Last Name" },
                          { label: "Email", value: "Email" },
                          {
                            label: "Predictive Model (1-100)",
                            value: "Predictive Model (1-100)",
                          },
                          { label: "Ad Conversion", value: "Ad Conversion" },
                          { label: "Phone", value: "Phone" },
                          {
                            label: "Blog Post Views",
                            value: "Blog Post Views",
                          },
                          { label: "User ID", value: "User ID" },
                        ]}
                        className="w-[95%]"
                        value={"Last Name"}
                      />
                      <ArrowRightOutlined className="text-secondary" />
                    </div>
                    <div className="w-1/2 flex items-center gap-1.5">
                      <Select
                        placeholder="Select a field..."
                        value={"last name"}
                        options={[
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>email</p>
                              </div>
                            ),
                            value: "email",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>first name</p>
                              </div>
                            ),
                            value: "first name",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>last name</p>
                              </div>
                            ),
                            value: "last name",
                          },
                        ]}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-1 my-2">
                    <div className="w-1/2 flex items-center gap-1.5">
                      <Select
                        placeholder="Select a value..."
                        options={[
                          { label: "First Name", value: "First Name" },
                          { label: "Last Name", value: "Last Name" },
                          { label: "Email", value: "Email" },
                          {
                            label: "Predictive Model (1-100)",
                            value: "Predictive Model (1-100)",
                          },
                          { label: "Ad Conversion", value: "Ad Conversion" },
                          { label: "Phone", value: "Phone" },
                          {
                            label: "Blog Post Views",
                            value: "Blog Post Views",
                          },
                          { label: "User ID", value: "User ID" },
                          { label: "Lifetime Value", value: "Lifetime Value" },
                        ]}
                        className="w-[95%]"
                        value={"Lifetime Value"}
                      />
                      <ArrowRightOutlined className="text-secondary" />
                    </div>
                    <div className="w-1/2 flex items-center gap-1.5">
                      <Select
                        placeholder="Select a field..."
                        value={"ltv"}
                        options={[
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>email</p>
                              </div>
                            ),
                            value: "email",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>first name</p>
                              </div>
                            ),
                            value: "first name",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>last name</p>
                              </div>
                            ),
                            value: "last name",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={list} alt="text" width={14} />
                                <p>ltv</p>
                              </div>
                            ),
                            value: "ltv",
                          },
                        ]}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-1 my-2">
                    <div className="w-1/2 flex items-center gap-1.5">
                      <Select
                        placeholder="Select a value..."
                        options={[
                          { label: "First Name", value: "First Name" },
                          { label: "Last Name", value: "Last Name" },
                          { label: "Email", value: "Email" },
                          {
                            label: "Predictive Model (1-100)",
                            value: "Predictive Model (1-100)",
                          },
                          { label: "Ad Conversion", value: "Ad Conversion" },
                          { label: "Phone", value: "Phone" },
                          {
                            label: "Blog Post Views",
                            value: "Blog Post Views",
                          },
                          { label: "User ID", value: "User ID" },
                          { label: "Company", value: "Company" },
                        ]}
                        className="w-[95%]"
                        value={"Company"}
                      />
                      <ArrowRightOutlined className="text-secondary" />
                    </div>
                    <div className="w-1/2 flex items-center gap-1.5">
                      <Select
                        placeholder="Select a field..."
                        value={"company"}
                        options={[
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>email</p>
                              </div>
                            ),
                            value: "email",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>first name</p>
                              </div>
                            ),
                            value: "first name",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>last name</p>
                              </div>
                            ),
                            value: "last name",
                          },
                          {
                            label: (
                              <div className="flex items-center gap-1">
                                <img src={textImg} alt="text" width={14} />
                                <p>company</p>
                              </div>
                            ),
                            value: "company",
                          },
                        ]}
                        className="w-full"
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className="flex justify-between mt-2">
            <div className="flex items-center gap-2">
              <AgentCustomBtn onClick={() => setMapping(true)}>
                Add mapping
              </AgentCustomBtn>
              <AgentCustomBtn onClick={handleSuggest} disabled={suggestMapping}>
                Suggest mappings
              </AgentCustomBtn>
            </div>
            {suggestMapping && (
              <AgentCustomBtn onClick={() => setActiveSegment(4)}>
                Continue
              </AgentCustomBtn>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigureSync;
