import { useCallback } from "react";
import { Handle, Position } from "@xyflow/react";
import Head from "./Head";
import { Select } from "antd";

const handleStyle = { left: 10 };

function SummarizerNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
    console.log(data);
  }, []);
  const options = [
    { label: "Claude-3-Haiku", value: "Claude-3-Haiku" },
    { label: "GPT-4o", value: "GPT-4o" },
    { label: "GPT-3.5-Turbo", value: "GPT-3.5-Turbo" },
  ];
  const slackOptions = [
    { label: "Marketing Leads", value: "Marketing Leads" },
    { label: "Sales Leads", value: "Sales Leads" },
    { label: "general", value: "general" },
  ];

  return (
    <div className="border border-[#eee] rounded-[5px] bg-white min-w-[20rem]">
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className="flex flex-col justify-start items-start">
        <Head data={data} />
        <div className="w-full text-[12px] mt-2 flex flex-col gap-1 p-2">
            <p>{data?.id !== "node-17" ? "Model Preference" : "Slack Channel"}</p>
            <Select options={data?.id === "node-17" ? slackOptions : options} className="w-full" defaultValue={data?.id === "node-17" ? "Marketing Leads" : "Claude-3-Haiku"} />
        </div>
      </div>
      {data?.id === "node-1" && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          style={handleStyle}
          isConnectable={isConnectable}
        />
      )}
      {data?.id !== "node-17" && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
}

export default SummarizerNode;
