import React from "react";
import { getBezierPath, EdgeLabelRenderer, BaseEdge } from "@xyflow/react";

function EdgeLabel({ transform, label }) {
  return (
    <div
      style={{
        position: "absolute",
        background: "transparent",
        padding: 0,
        color: "black",
        fontSize: 12,
        transform,
      }}
      className="nodrag nopan flex flex-col gap-0.25 justify-center items-center"
    >
      <p className="text-[13px] font-[500]">{label?.mainText}</p>
      <p className="text-[10px]">{label?.suppText}</p>
    </div>
  );
}

const CanvasCustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        {data?.startLabel?.mainText && (
          <EdgeLabel
            transform={`translate(10%, 30%) translate(${sourceX}px,${sourceY}px)`}
            label={data?.startLabel}
          />
        )}
        {data?.endLabel?.mainText && (
          <EdgeLabel
            transform={`translate(-50%, -100%) translate(${targetX}px,${targetY}px)`}
            label={data?.endLabel}
          />
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CanvasCustomEdge;