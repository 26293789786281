import React from 'react'
import { useNavigate } from 'react-router-dom'

import driveImg from '../../assets/images/driveImg.png'
import sfImg from '../../assets/images/sfImg.png'
import { useSetRecoilState } from 'recoil'
import { agentValAtom } from '../../store'

const AgentCard = ({ values }) => {
    const navigate = useNavigate()
    const setAgentVal = useSetRecoilState(agentValAtom)

    const handleClick = () => {
        // console.log(values)
        setAgentVal({
            name: values?.name,
            des: values?.des,
            href: values?.href,
            initialNodes: values?.initialNodes,
            initialEdges: values?.initialEdges,
            genOutput: values?.genOutput,
            works: values?.works,
            nodes: values?.nodes,
            process: values?.process,
            tools: values?.tools,
            output: values?.output,
            route: values?.route
        })
        if(values?.route){
            navigate(`/templates/${values?.route}`)
        } else {
            navigate(`/templates/enable/${values?.name.toLowerCase().replace(/\s+/g, '_')}`)

        }
        // if (values?.name === "Lead Background Research") {
        //     navigate("/templates/sales_crm")
        // } else if (values?.name === "Enrichment Waterfall" || values?.href === "enrich") {
        //     navigate("/templates/enrichment")
        // } else if (values?.name === "Lead Enrichment") {
        //     navigate("/templates/lead")
        // } else if (values?.name === "Programmatic Leads") {
        //     navigate("/templates/prog")
        // }
    }

    return (
        <div className='flex flex-col gap-4 justify-center border rounded-md px-6 py-4 shadow-md cursor-pointer' onClick={handleClick}>
            <div className='flex justify-between items-center'>
                <p className='h5_Regular mr-2'>{values?.name}</p>
                <div className='flex justify-center items-center gap-2'>
                    <img src={sfImg} alt="sfImg" className='border rounded-md px-1.5 py-3 bg-cover'  />
                    <img src={driveImg} alt="driveImg" className='border rounded-md px-1 py-1.5 bg-cover' />
                    <p className='body_Regular text-character border p-2.5 rounded-md bg-light-primary'>+2</p>
                </div>
            </div>
            <div className='text-secondary w-3/3'>
                {values?.des}
            </div>
            <p className='text-primary border-t border-secondary w-full pt-4'>
                Output: {values?.output || 'CRM or Warehouse'}
            </p>
        </div>
    )
}

export default AgentCard