/* eslint-disable no-empty-pattern */
import { Modal, Input, List } from 'antd';
import { useState, useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { formulaPromptAtom } from '../../store/atoms/FormulaAtoms';

export default function SuggestionBox() {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [filterText, setFilterText] = useState('');
	const [filteredColumns, setFilteredColumns] = useState([]);
	const [prompt, setPrompt] = useRecoilState(formulaPromptAtom);

	const columns = useMemo(() => ['account_url', 'account_revenue'], []);

	const handleColumnInsertion = useCallback(
		e => {
			if (e.key === '/') {
				e.preventDefault();
				setIsModalVisible(true);
				setFilteredColumns(columns);
			}
		},
		[columns]
	);

	const handleColumnSelect = columnName => {
		setPrompt(prev => prev + `{{${columnName}}}`);
		setIsModalVisible(false);
	};

	const handleFilterChange = e => {
		const searchText = e.target.value.toLowerCase();
		setFilterText(searchText);
		setFilteredColumns(
			columns.filter(column => column.toLowerCase().includes(searchText))
		);
	};

	return (
		<>
			
            <textarea
                className="w-full h-full p-2 border border-gray-300 rounded-md"
                placeholder="Type your formula here..."
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
                onKeyDown={handleColumnInsertion}
            />

			<Modal
				title="Select a Column"
				open={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				footer={null}
			>
				<Input
					placeholder="Search columns"
					value={filterText}
					onChange={handleFilterChange}
				/>
				<List
					bordered
					dataSource={filteredColumns}
					renderItem={item => (
						<List.Item
							onClick={() => handleColumnSelect(item)}
							className="cursor-pointer"
						>
							{item}
						</List.Item>
					)}
				/>
			</Modal>
		</>
	);
}
