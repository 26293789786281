import React from 'react'

const AgentCustomBtn = ({ otherClasses, onClick, children, disabled=false }) => {
  return (
    <div
        className={`px-4 py-1.5 ${disabled ? 'bg-even-light-primary text-primary cursor-not-allowed' : 'bg-primary hover:scale-110 duration-300 ease-in-out text-white cursor-pointer'} flex justify-center items-center rounded-md border border-primary body_Regular ${otherClasses}`}
        onClick={!disabled ? onClick : null}
    >
        {children}   
    </div>
  )
}

export default AgentCustomBtn