import React, { useEffect } from "react";
import { gumloopChatArrayAtom, gumloopLoadingAtom } from "../../store";

import { useRecoilState, useSetRecoilState } from "recoil";

const Complete = () => {
  const [chatArray, setChatArray] = useRecoilState(gumloopChatArrayAtom);
  const setLoading = useSetRecoilState(gumloopLoadingAtom);

  useEffect(() => {
    setChatArray((prevChatArray) => {
      const lastAnswer = prevChatArray[prevChatArray.length - 1]?.answer;
      if (
        lastAnswer !== "Test ran successfully. Shall I deploy the workflow?"
      ) {
        return [
          ...prevChatArray,
          {
            answer: "Test ran successfully. Shall I deploy the workflow?",
          },
        ];
      }
      return prevChatArray;
    });
    setLoading(false);
  }, []);

  return <div className="text-white"></div>;
};

export default Complete;
