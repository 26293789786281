import React from 'react';
import AssistantChatWindow from '../AssistantChatWindow/AssistantChatWindow.js';
import AssistantInputBox from '../AssistantInputBox/AssistantInputBox.js';
import { useRecoilState } from 'recoil';
import { extendedChatAtom } from '../../../store/index.js';
import ExtendedChat from '../../CopilotComponents/ExtendedChat.js';
const AssistantMain = () => {
    const [extendedChat, setExtendedChat] = useRecoilState(extendedChatAtom);

    return (
        <div className='bg-[#F7F8FA] min-h-screen flex flex-col'>
            {!extendedChat ? (
                <div className='flex flex-col gap-2'>
                    <div className='h-[80vh] overflow-y-auto'>
                        <AssistantChatWindow />
                    </div>
                    <AssistantInputBox className='flex-grow' />
                </div>
            ) : (
                <div className='px-2 ml-2 pt-2'>
                    <ExtendedChat />
                </div>
            )}
        </div>
    );
}

export default AssistantMain;
