import {
	Divider,
	Table,
	message,
	Spin,
	Tooltip,
	Button,
	Row,
	Col,
	Layout,
	Input,
} from 'antd';
import { useState, useCallback, useMemo, useEffect, useId } from 'react';
import axios from 'axios';
import {
	CopyOutlined,
	ArrowLeftOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
// import { mockData144, mockData64, mockSuggestions } from './Fixtures/mockData';
import SuggestionBox from './SuggestionBox';
// import SuggestionBox2 from './SuggestionBoxWIthLibrary';
// import SuggestionBox3 from './SuggestionBoxWIthLibrary2';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  formulaFinalResults,
	formulaFormulaAtom,
	formulaFormulaFormDirty,
	formulaPromptAtom,
	formulaShowResultsAtom,
} from '../../store/atoms/FormulaAtoms';
import { updateDataforSpreadsheet } from '../../services/InsightsServices';

export default function FormulaGenerator({ data, query_id, setReloadChatTable, handleCloseModal }) {
	const prompt = useRecoilValue(formulaPromptAtom);
	const [generatedFormula, setGeneratedFormula] =
		useRecoilState(formulaFormulaAtom);
  const [formulaResults, setFormulaResults] = useRecoilState(formulaFinalResults)
	const [sampleRecords, setSampleRecords] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showResults, setShowResults] = useRecoilState(formulaShowResultsAtom);
	const [suggestionsList, setSuggestionsList] = useState([]);
	const [suggestionsLoading, setSuggestionsLoading] = useState(false);
	const [isDirty, setIsDirty] = useRecoilState(formulaFormulaFormDirty);
  const [applying, setApplying] = useState(false)
	console.log('loading', { loading, prompt });

	const columns = useMemo(() => {
		if (data && data.length > 0) {
			return Object.keys(data[0]).map(key => ({ label: key, value: key }));
		}
		return [];
	}, [data]);

	{
		console.log('dirty', { isDirty });
	}

	console.log('first', { showResults });

	const generateFormula = useCallback(async formulaDescription => {
		setLoading(true);
		try {
			const config = {
				method: 'POST',
				url: 'https://api.icustomer.ai/v1/formula/generate-apply-formula',
				data: {
					prompt: formulaDescription,
					schema_name: 'insighttables',
					table_name: `new_table_${query_id.replaceAll('-', '_')}`,
					records: data,
					formula: '',
				},
				headers: {
					accept: 'application/json',
					'api-key': 'y7RbL2xZ9uVgH3aFpK5qW8iU0sX1oJ6',
					'Content-Type': 'application/json',
				},
			};
			const response = await axios.request(config);
			// const response = mockData64();
			// const response = mockSuggestions;
			console.log({ response });
			if (response.data.status) {
				message.success('Formula generated successfully!');
				setGeneratedFormula(response.data.formula);
				setSampleRecords(response.data.records.slice(0, 10));
        setFormulaResults(response?.data?.records)
        console.log(formulaResults)
				setShowResults(true);
			} else {
        message.error('We might not able to serve this, Try different prompt')
      }
		} catch (error) {
			console.error('Error generating formula:', error);
			message.error('Failed to generate formula. Please try again.');
		} finally {
			setLoading(false);
		}
	}, []);

	const ApplyFormula = useCallback(async formulaDescription => {
		if (!formulaDescription.trim()) {
			message.warning('Please enter a formula.');
			return;
		}
		setLoading(true);
		try {
			const config = {
				method: 'POST',
				url: 'https://api.icustomer.ai/v1/formula/generate-apply-formula',
				data: {
					prompt: '',
					schema_name: 'insighttables',
					table_name: `new_table_${query_id.replaceAll('-', '_')}`,
					records: data,
					formula: formulaDescription,
				},
				headers: {
					accept: 'application/json',
					'api-key': 'y7RbL2xZ9uVgH3aFpK5qW8iU0sX1oJ6',
					'Content-Type': 'application/json',
				},
			};
			const response = await axios.request(config);
			// const response = mockData64();
			// const response = mockSuggestions;
			console.log({ response });
			if (response.data.status) {
				message.success('Formula generated successfully!');
				setGeneratedFormula(response.data.formula);
				setSampleRecords(response.data.records.slice(0, 10));
				setShowResults(true);
			}
		} catch (error) {
			console.error('Error generating formula:', error);
			message.error('Failed to generate formula. Please try again.');
		} finally {
			setLoading(false);
		}
	}, []);

	const handleFormulaGeneration = useCallback(() => {
		if (!prompt.trim()) {
			message.warning('Please enter a formula description.');
			return;
		}
		generateFormula(prompt);
	}, [prompt, generateFormula]);

	const handleColumnInsertion = useCallback(
		(e, setter) => {
			if (e.key === '/') {
				e.preventDefault();
				// Show a dropdown of available columns
				const column = prompt(
					'Select a column:',
					columns.map(c => c.label).join(', ')
				);
				if (column) {
					setter(prev => prev + `{{${column}}}`);
				}
			}
		},
		[columns]
	);

	// const handleCustomFormulaApply = useCallback(() => {
	// 	if (!customFormula.trim()) {
	// 		message.warning('Please enter a custom formula.');
	// 		return;
	// 	}
	// 	generateFormula(customFormula);
	// }, [customFormula, generateFormula]);
	const handleCustomFormulaApply = useCallback(() => {
		if (!generatedFormula.trim()) {
			message.warning('Please enter a custom formula.');
			return;
		}
		ApplyFormula(generatedFormula);
	}, [generatedFormula, ApplyFormula]);

	const handleCopyFormula = useCallback(() => {
		navigator.clipboard.writeText(generatedFormula);
		message.success('Formula copied to clipboard!');
	}, [generatedFormula]);

	const handleBack = useCallback(() => {
		setShowResults(false);
	}, []);

	const handleApply = useCallback(async () => {
    try {
      setApplying(true)
      console.log({formulaResults, query_id})
      const {data: apiRes} = await updateDataforSpreadsheet({
        query_id: query_id,
        tableData: formulaResults
      });
      console.log(apiRes)
      if(apiRes?.status === "success"){
        message.success('Formula applied successfully!');
        setReloadChatTable(Date.now)
        handleCloseModal()
      } else {
        throw new Error(apiRes?.message || "Failed to save spreadsheet data")
      }
    } catch (error) {
      console.error(error)
      message.error(error)
    } finally {
      setApplying(false)
    }
    
	}, [generatedFormula]);

	const tableColumns =
		sampleRecords.length > 0
			? Object.keys(sampleRecords[0]).map(key => ({
					title: key,
					dataIndex: key,
					key: key,
					ellipsis: true,
			  }))
			: [];

	useEffect(() => {
		const fetchSuggestivePrompts = async () => {
			setSuggestionsLoading(true);
			try {
				const config = {
					method: 'POST',
					url: 'https://api.icustomer.ai/v1/formula/prompts-and-formula-suggestion',
					data: {
						schema_name: 'insighttables',
						table_name: `new_table_${query_id.replaceAll('-', '_')}`,
						records: null,
					},
					headers: {
						accept: 'application/json',
						'api-key': 'y7RbL2xZ9uVgH3aFpK5qW8iU0sX1oJ6',
						'Content-Type': 'application/json',
					},
				};
				const response = await axios.request(config);
				// const response = mockData144;
				console.log({ response });
				if (response.data) {
					setSuggestionsList(convertArray(response.data));
				}
			} catch (error) {
				console.error('Error fetching suggestive prompts:', error);
			} finally {
				setSuggestionsLoading(false);
			}
		};
		fetchSuggestivePrompts();
	}, []);

	const convertArray = inputArray => {
		return inputArray.map(item => {
			// Get the prompt (first key) and the formula (first value) from each object
			const prompt = Object.keys(item)[0];
			const formula = item[prompt];

			return {
				prompt: prompt,
				formula: formula,
			};
		});
	};

	function codeTextHandlerChange(e) {
		setGeneratedFormula(e.target.value);
		setIsDirty(true);
	}

	return (
		<Spin spinning={loading || suggestionsLoading}>
			{!showResults ? (
				<>
					<Layout className="flex items-center justify-center bg-white">
						<div className="container">
							<div className="flex justify-between items-center mb-4">
								<h1 className="text-2xl font-bold text-gray-800">
									Formula Generator
								</h1>
							</div>
							{/* <div className="flex space-x-4 mb-6">
								<a href="#" className="text-blue-600 hover:underline">
									📺 Watch Tutorial
								</a>
								<a href="#" className="text-blue-600 hover:underline">
									💬 Get Support on Slack
								</a>
							</div> */}
							<Divider />
							<Row
								gutter={5}
								className="max-h-[750dvh] overflow-y-auto overflow-x-hidden"
							>
								<Col span={24} className="pr-3">
									<div className="mb-6 w-full">
										<SuggestionBox />
										{/* <SuggestionBox3 /> */}
										{/* <SuggestionBoxWIthLibrary /> */}
										<div className="flex justify-between mt-2">
											{/* <span className="text-sm text-gray-500">
												Type / to insert Column
											</span> */}
											<button
												className="bg-[#2A66BC] text-white px-4 py-2 rounded-md hover:bg-blue-900 disabled:opacity-50"
												onClick={handleFormulaGeneration}
												disabled={loading || !prompt.trim()}
											>
												{loading ? 'Generating...' : '+ Generate formula'}
											</button>
										</div>
									</div>
								</Col>
								<Col span={1} />
								<Col span={24} className="pl-3">
									<RenderSuggestivePrompts
										suggestionsList={suggestionsList}
										onClick={ApplyFormula}
									/>
								</Col>
							</Row>
						</div>
					</Layout>
				</>
			) : (
				<>
					<Layout className="flex items-center justify-center bg-white">
						<div className="container">
							<div className="flex justify-start items-center mb-4">
								<Tooltip title="Go back to formula input">
									<div onClick={handleBack} className="cursor-pointer p-4">
										<ArrowLeftOutlined />
									</div>
								</Tooltip>
								<h1 className="ml-8 text-2xl font-bold text-gray-800">
									Formula Results
								</h1>
							</div>
							<div className="mt-6">
                <h2 className="text-lg font-semibold mb-2 text-gray-700">
									Your Prompt
								</h2>
                <p>{prompt}</p>
								<h2 className="mt-6 text-lg font-semibold mb-2 text-gray-700">
									Generated Formula
								</h2>
								<div className="flex justify-between items-center p-4 bg-gray-100 rounded-md border border-solid">
									<code className="px-3 py-1 mr-3 w-full focus-within:border-red-500 focus-within::border-[3px] outline-8">
										<Input
											value={generatedFormula}
											onChange={e => {
												codeTextHandlerChange(e);
											}}
										/>
										{/* {generatedFormula} */}
									</code>
									<Tooltip
										title={
											isDirty ? 'Recalculate' : 'Copy formula to clipboard'
										}
									>
										<Button
											icon={isDirty ? <ReloadOutlined /> : <CopyOutlined />}
											onClick={
												isDirty ? handleCustomFormulaApply : handleCopyFormula
											}
                      className='bg-[#2A66BC]'
										>
											{isDirty ? 'Recalculate' : 'Copy'}
										</Button>
									</Tooltip>
								</div>
							</div>
							{sampleRecords.length > 0 && (
								<div className="mt-6 ">
									<h2 className="text-lg font-semibold mb-2 text-gray-700">
										Sample Output (up to 10 records)
									</h2>
									<div className="overflow-y-auto no-scrollbar max-h-[40dvh] ">
										<Table
											dataSource={sampleRecords}
											columns={tableColumns}
											pagination={false}
											scroll={{ x: true }}
										/>
									</div>
								</div>
							)}
							<div className="mt-6 flex justify-end">
								<Button type="primary" size="large" onClick={handleApply}>
                  {applying ? 'Updating Data...' : 'Apply'}
								</Button>
							</div>
						</div>
					</Layout>
				</>
			)}
		</Spin>
	);
}

function RenderSuggestivePrompts({ suggestionsList, onClick }) {
	console.log({ suggestionsList });
	return (
		<div className="w-full mt-4">
			<h2 className="text-lg font-semibold mb-2 text-gray-700">
				Suggestive Prompts
			</h2>
			<ul className="text-gray-600 overflow-x-auto no-scrollbar">
				{suggestionsList.map((suggestion, index) => (
					<RenderSuggestionButtonList
						suggestion={suggestion.prompt}
						key={index + suggestion.prompt}
						link={suggestion.formula}
						btnOnClickHandler={onClick}
					/>
				))}
			</ul>
		</div>
	);
}

function RenderSuggestionButtonList({ suggestion, link, btnOnClickHandler }) {
	const setShowResults = useSetRecoilState(formulaShowResultsAtom);
	const setPrompt = useSetRecoilState(formulaPromptAtom);
	const setFormula = useSetRecoilState(formulaFormulaAtom);

	function handleOnClick() {
		setShowResults(true);
		setPrompt(suggestion);
		setFormula(link);
		btnOnClickHandler(link);
	}

	const id = useId();
	return (
		<>
			<Tooltip title={suggestion}>
				<Button
					key={id}
					onClick={handleOnClick}
					className="mb-2 ml-2 bg-[#2A66BC]"
					type="primary"
					// title={suggestion}
				>
					{suggestion}
				</Button>
			</Tooltip>
		</>
	);
}
