import React, { useCallback, useState, useEffect } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
  ReactFlowProvider
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import ProgIONode from "../../components/ProgGumloopComponents/ProgIONode.js";
import ProgTextNode from "../../components/ProgGumloopComponents/ProgtextNode.js";
import ProgCompanyNode from "../../components/ProgGumloopComponents/ProgCompanyNode.js";
import ProgSummarizerNode from "../../components/ProgGumloopComponents/ProgSummarizerNode.js";

import ProgMainHeader from "../../components/ProgGumloopComponents/ProgMainHeader.js";
import SideOpt from "../../components/GumloopComponents/SideOpt.js";

import inputImg from "../../assets/images/inputImg.png";
import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";
import personImg from "../../assets/images/personImg.png";
import calcImg from "../../assets/images/calcImg.png";
import parseImg from "../../assets/images/parseImg.png";
import robotImg from "../../assets/images/robotImg.png";

import ProgCustomEdge from '../../components/ProgGumloopComponents/ProgCustomEdge.js';

import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms.js";
import { progFocusNodeIdAtom, progNewNodeAtom, progContactAtom, progSlackAtom, progFitEntireAtom, progCollapsibleAtom, progTestLoadingAtom, progPolledContentAtom, progTestEmailAtom, progFinalAtom, progInputAtom } from "../../store/index.js";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";

import ProgRightSider from "../../components/ProgGumloopComponents/ProgRightSider.js";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";

const initialNodes = [
  {
    id: "node-1",
    type: "ioNode",
    position: { x: 3.28, y: -301.51 },
    data: {
      label: "Input",
      icon: inputImg,
      firstKey: "Account Name:",
      firstVal: "cisco",
      bgColor: "grey",
    },
  },
  {
    id: "node-3",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 728.41, y: 611.25 },
    data: {
      label: "Output",
      icon: robotImg,
      // firstKey: "Output Name:",
      firstVal: "research_results",
      bgColor: "grey",
    },
  },
  {
    id: "node-4",
    // type: 'output',
    type: "ioNode",
    targetPosition: "top",
    position: { x: 720.87, y: -120.42 },
    data: {
      label: "Contact Discovery",
      icon: parseImg,
      bgColor: "blue",
    },
  },
  {
    id: "node-16",
    // type: 'output',
    type: "companyNode",
    targetPosition: "top",
    position: { x: 727.03, y: 58.77 },
    data: {
      label: "Job Title Standardization",
      icon: companyImg,
      bgColor: "blue",
      firstKey: "Inputs",
      firstVal: "domain name"
    },
  },
  {
    id: "node-17",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: 676.99, y: 404.27 },
    data: {
      label: "Leads",
      icon: calcImg,
      bgColor: "peach",
      firstKey: "Inputs",
      firstVal: "domain name"
    },
  },
  {
    id: "node-18",
    // type: 'output',
    type: "summarizerNode",
    targetPosition: "top",
    position: { x: 706.01, y: 247.85 },
    data: {
      label: "Contact Name Standardization",
      icon: calcImg,
      bgColor: "blue",
      firstKey: "Inputs",
      firstVal: "domain name"
    },
  }
];

const initialEdges = [
  {
    id: "edge-1",
    source: "node-1",
    target: "node-4",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'email', suppText: 'type = text' },
      // endLabel: { mainText: 'email', suppText: 'type = text' }
    },
    type: 'start-end',
  },
  {
    id: "edge-2",
    source: "node-4",
    target: "node-5",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      // endLabel: { mainText: 'Free Domain' },
    },
    type: 'start-end',
  },
  {
    id: "edge-3",
    source: "node-4",
    target: "node-16",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      // endLabel: { mainText: 'Business Domain' },
    },
    type: 'start-end',
  },
  {
    id: "edge-5",
    source: "node-5",
    target: "node-14",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'scraped data', suppText: 'type = text' },
      // endLabel: { mainText: 'url', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-6",
    source: "node-5",
    target: "node-15",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'scraped data', suppText: 'type = text' },
      // endLabel: { mainText: 'url', suppText: 'type = text' },
    },
    type: 'start-end',
  },
  {
    id: "edge-12",
    source: "node-16",
    target: "node-18",
    sourceHandle: "d",
    animated: true,
    data: {
      // startLabel: { mainText: 'verified content', suppText: 'type = text' },
      // endLabel: { mainText: 'Valid Email' },
    },
    type: 'start-end',
  },
  {
    id: "edge-14",
    source: "node-17",
    target: "node-3",
    targetHandle: "a",
    animated: true,
    data: {
      // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      // endLabel: { mainText: 'Yes' },
    },
    type: 'start-end',
  },
  {
    id: "edge-15",
    source: "node-18",
    target: "node-17",
    sourceHandle: "b",
    animated: true,
    data: {
      // startLabel: { mainText: 'split text', suppText: 'type = List of text' },
      // endLabel: { mainText: 'Yes' },
    },
    type: 'start-end',
  },
];

const nodeTypes = {
  ioNode: (data) => <ProgIONode data={data} />,
  textNode: (data) => <ProgTextNode data={data} />,
  companyNode: (data) => <ProgCompanyNode data={data} />,
  summarizerNode: (data) => <ProgSummarizerNode data={data} />,
};

const edgeTypes = {
  'start-end': ProgCustomEdge,
};

const Flow = (props) => {
  const { fitView, getNode } = useReactFlow();
  const [focusNodeId, setFocusNodeId] = useRecoilState(progFocusNodeIdAtom);

  useEffect(() => {
    if (focusNodeId) {
      if (focusNodeId === "whole") {
        fitView({ duration: 800 });
      } else {
        const node = getNode(focusNodeId);
        if (node) {
          fitView({ nodes: [node], duration: 800 });
        }
      }
      setFocusNodeId(null);
    }
  }, [focusNodeId, fitView, getNode]);

  return (
    <ReactFlow {...props} >
      <Controls position="bottom-right" />
      <Background variant="dots" gap={20} size={1} />
    </ReactFlow>
  );
}


const ProgGumloop = () => {
  const setChatMode = useSetRecoilState(chatModeAtom);
  const setSideNavContent = useSetRecoilState(sideNavContentAtom);
  const [newNode, setNewNode] = useRecoilState(progNewNodeAtom);
  const [contact, setContact] = useRecoilState(progContactAtom);
  const [slack, setSlack] = useRecoilState(progSlackAtom);
  const [fitEntire, setFitEntire] = useRecoilState(progFitEntireAtom);
  const setFocusNodeId = useSetRecoilState(progFocusNodeIdAtom);
  const [isCollapsed, setIsCollapsed] = useRecoilState(progCollapsibleAtom)
  const [testLoading, setTestLoading] = useRecoilState(progTestLoadingAtom);
  const setPolledContent = useSetRecoilState(progPolledContentAtom);
  const setTestEmail = useSetRecoilState(progTestEmailAtom);

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const setProgFinal = useSetRecoilState(progFinalAtom)
  const setProgInput = useSetRecoilState(progInputAtom)

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    console.log(nodes)
  }, [nodes])
  
  useEffect(() => {
    // localStorage.setItem("path", "gumloop");
    // setChatMode(1);
    // setSideNavContent(1);
    setPolledContent(null)
    setTestEmail(null)
    setTestLoading(false)

    setProgFinal([])
    setProgInput({
      "company_info": [
        {
          "id": "",
          "company": "Cisco",
          "country": "USA",
          "website": "https://www.cisco.com"
        }
      ],
      "job_function": [
        "Accounting / Finance"
      ],
      "job_level": [
        "C-level / CXO"
      ],
      "max_leads_count": 10
    })
  }, [])

  const replaceNode = (oldNodeId, newNode) => {
    setNodes((nds) => nds.map((node) => (node.id === oldNodeId ? newNode : node)));
  };

  useEffect(() => {
    if (newNode) {
      const hubspotNode = {
        id: "node-15",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -72, y: -33 },
        data: {
          label: "Hubspot Contact Reader",
          icon: hubspotImg,
          bgColor: "blue",
          firstKey: "Outputs",
          firstVal: "hubspot.com"
        },
      }
      replaceNode('node-1', hubspotNode)
      setNewNode(false);
    }
    if (contact) {
      const contactNode = {
        id: "node-16",
        // type: 'output',
        type: "companyNode",
        targetPosition: "top",
        position: { x: -528.8, y: 842 },
        data: {
          label: "Enrich Contact Information",
          icon: companyImg,
          bgColor: "blue",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      replaceNode('node-9', contactNode)
      setContact(false);
      setTimeout(() => {
        setFocusNodeId("node-16")
      }, 500)
    }
    if (slack) {
      const slackNode = {
        id: "node-17",
        // type: 'output',
        type: "summarizerNode",
        targetPosition: "top",
        position: { x: 55, y: 2098 },
        data: {
          label: "Slack Message Sender",
          icon: slackImg,
          bgColor: "pink",
          firstKey: "Inputs",
          firstVal: "domain name"
        },
      }
      setNodes(prevNodes => [...prevNodes, slackNode])
      setSlack(false);
      setTimeout(() => {
        setFocusNodeId("node-17")
      }, 500)
    }
    if (fitEntire) {
      setFitEntire(false);
      setTimeout(() => {
        setFocusNodeId("whole")
      }, 500)
    }
  }, [newNode, contact, slack, fitEntire])

    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };


  return (
    <>
      <ProgMainHeader />
      <div style={{ width: "100%", height: "80vh" }} className="p-2 relative !ml-[10px]">
        {/* <SideOpt /> */}
        <ReactFlowProvider>
          <Flow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            proOptions={{ hideAttribution: true }}
            fitView
            panOnScroll
            selectionOnDrag
            minZoom={0.2}
            maxZoom={2}
          />
        </ReactFlowProvider>
        {testLoading && (
          <>
            <button onClick={toggleCollapse} className={`absolute ${isCollapsed ? 'right-16' : 'right-[20rem]'} top-12 z-50 duration-300 ease-in-out`}>
              {isCollapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
            </button>
            <ProgRightSider />
          </>
        )}
      </div>
    </>
  );
};

export default ProgGumloop;