import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import shareImg from "../../assets/images/shareImg.png";
import saveImg from "../../assets/images/saveImg.png";
import testImg from "../../assets/images/testImg.png";
import deployImg from "../../assets/images/deployImg.png";
import ProgCustomButton from "./ProgCustomButton";
import { Spin, Modal, Button, Table, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { progTestLoadingAtom, progDeployAtom, progGumloopChatArrayAtom, progEmailAtom, progPolledContentAtom, progTestEmailAtom, progVisibleAtom, progActiveIndexAtom, progCollapsibleAtom, progInputAtom, progFinalAtom, progModalOpenAtom, progBtnLoadingAtom } from "../../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { jwtDecode } from "jwt-decode";
import { startAgent, pollAgent } from "../../services/waterfallServices.js";
import { v4 as uuid } from "uuid"
import { startProgAgent } from "../../services/progServices.js";
import JsonToCsv from "../EnrichmentGumloopComponents/JsonToCsv.js";
// import GumSuccess from "./GumSuccess";

const ProgMainHeader = () => {
  const [activeHead, setActiveHead] = useState(0);
  const [testClicked, setTestClicked] = useState(false);

  const [loading, setLoading] = useRecoilState(progTestLoadingAtom);
  const [deploy, setDeploy] = useRecoilState(progDeployAtom);
  const setChatArray = useSetRecoilState(progGumloopChatArrayAtom);
  const [modalOpen, setModalOpen] = useRecoilState(progModalOpenAtom);
  const email = useRecoilValue(progEmailAtom);
  const setPolledContent = useSetRecoilState(progPolledContentAtom);
  const setTestEmail = useSetRecoilState(progTestEmailAtom);
  const setVisibleProcess = useSetRecoilState(progVisibleAtom);
  const setActiveIndex = useSetRecoilState(progActiveIndexAtom);
  const setIsCollapsed = useSetRecoilState(progCollapsibleAtom);

  const [progFinal, setProgFinal] = useRecoilState(progFinalAtom)
  const [btnLoading, setBtnLoading] = useRecoilState(progBtnLoadingAtom)

  const [progInput, setProgInput] = useRecoilState(progInputAtom)

  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  const location = useLocation();
  const btns = [
    {
      text: "Save",
      icon: saveImg,
    },
    {
      text: "Test",
      icon: testImg,
    },
    {
      text: "Deploy",
      icon: deployImg,
    },
  ];
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if (loading) {
  //       setLoading(false);
  //       setModalOpen(true);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, [loading]);
  const handleClick = async (index) => {
    if (index !== 1) {
      return;
    }
    setBtnLoading(true)
    setModalOpen(false)
    setLoading(false)
    messageApi.open({
      key,
      type: 'loading',
      content: 'Starting Your WorkFlow...',
      duration: 0
    });
    const start = await startProgAgent(progInput)
    console.log(start?.data)
    if (start?.data?.length <= 0) {
      // Close the loading message and show error
      messageApi.open({
        key, // Use the same key to update the existing message
        type: 'error',
        content: 'No Leads Found!',
        duration: 2, // Set duration for the error message to disappear
      });
      setBtnLoading(false)
      return; // Exit the function if no leads are found
    }
    messageApi.open({
      key,
      type: 'success',
      content: 'WorkFlow Started!',
      duration: 2,
    });
    setLoading(true)
    setActiveIndex(0)
    setVisibleProcess([0])
    setProgFinal(start?.data || [])
  };

  // useEffect(() => {
  //   if (progFinal && progFinal?.length > 0) {
  //     console.log(progFinal)
  //     setModalOpen(true)
  //   }
  // }, [progFinal])
  const handleWorkflow = () => {
    setModalOpen(false);
    setDeploy(true);
    setChatArray(prev => ([
      ...prev,
      {
        question: "Yes",
        answer: "Deployed! To see your deployed workflows & their performance, please navigate to Live in the Main Menu."
      }
    ]))
  }
  return (
    <>
      {contextHolder}
      <Modal
        title=""
        centered
        open={progFinal && progFinal?.length > 0 && modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        className="!w-[80vw]"
        footer={[
          <JsonToCsv
            jsonData={progFinal}
            prog={true}
          />
        ]}
      >
        <div className="mt-4 flex flex-col gap-2">
          <p className="text-center h3_Medium">
            Generated Output
          </p>
          {progFinal && progFinal?.length > 0 && (
            <Table
              bordered
              pagination={false}
              dataSource={progFinal?.map((prog, index) => ({
                ...prog,
                key: index,
              }))}
              columns={Object.keys(progFinal[0])?.filter(prog => prog !== 'id')?.map(prog => ({
                dataIndex: prog,
                title: prog?.split("_")?.map(val => val[0]?.toUpperCase() + val?.slice(1))?.join(" "),
                key: prog,
              }))}
              className="w-full overflow-scroll h-[50vh]"
            />
          )}
        </div>
      </Modal>
      {/* <Modal
          title=""
          visible={deploy}
          onCancel={() => setDeploy(false)}
          footer={null}
          className=""
          centered
        >
          <div className="h-[50vh] flex flex-col justify-center items-center gap-4">
            <GumSuccess setModal={setDeploy}/>
          </div>
        </Modal> */}
      <div className="p-2 ml-3">
        <div className="flex justify-start items-center pl-1 pr-4 border-b border-gray-300">
          {["Builder", "Files", "Log"].map((tab, index) => (
            <div
              key={index}
              className={`${
                activeHead === index
                  ? "text-[#2966BC] border-b-2 border-[#2966BC]"
                  : "text-gray-600"
              } px-4 py-2 text-sm cursor-pointer`}
              onClick={() => setActiveHead(index)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="flex w-full justify-between items-center p-2 mt-1 border border-2 bg-white rounded-lg">
          <p className="text-base">Programmatic Leads</p>
          <div className="flex gap-1 items-center">
            <img
              src={shareImg}
              alt="shareImg"
              width={30}
              className="hover:scale-110 duration-300 ease-in-out cursor-pointer"
            />
            <div className="flex gap-2">
              {btns.map((btnData, idx) => {
                if (idx === 1 && btnLoading) {
                  return (
                    <Spin
                      key="spinner"
                      indicator={<LoadingOutlined />}
                      size="large"
                      className="w-16"
                    />
                  );
                }
                return (
                  <ProgCustomButton
                    key={idx}
                    id={idx}
                    text={btnData.text}
                    icon={btnData.icon}
                    onClick={() => handleClick(idx)}
                    testClicked={testClicked}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgMainHeader;