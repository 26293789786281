
  import React from 'react';
  import { Chart as ChartJS, Tooltip, Legend } from 'chart.js';
  import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
  import { Chart as ReactChartJS } from 'react-chartjs-2';
  
  ChartJS.register(Tooltip, Legend, MatrixController, MatrixElement);
  
  const HeatmapComponent = () => {
    const data = {
      datasets: [
        {
          label: 'Opportunities',
          data: [
            { x: '2016', y: 'Jan', v: 96 }, { x: '2017', y: 'Jan', v: 32 }, { x: '2018', y: 'Jan', v: 53 },
            { x: '2019', y: 'Jan', v: 65 }, { x: '2020', y: 'Jan', v: 59 }, { x: '2021', y: 'Jan', v: 58 },
            { x: '2022', y: 'Jan', v: 39 }, { x: '2023', y: 'Jan', v: 29 }, { x: '2024', y: 'Jan', v: 0 },
            { x: '2016', y: 'Feb', v: 10 }, { x: '2017', y: 'Feb', v: 45 }, { x: '2018', y: 'Feb', v: 60 },
            { x: '2019', y: 'Feb', v: 66 }, { x: '2020', y: 'Feb', v: 62 }, { x: '2021', y: 'Feb', v: 78 },
            { x: '2022', y: 'Feb', v: 74 }, { x: '2023', y: 'Feb', v: 52 }, { x: '2024', y: 'Feb', v: 34 },
            { x: '2016', y: 'Mar', v: 100 }, { x: '2017', y: 'Mar', v: 31 }, { x: '2018', y: 'Mar', v: 49 },
            { x: '2019', y: 'Mar', v: 59 }, { x: '2020', y: 'Mar', v: 53 }, { x: '2021', y: 'Mar', v: 54 },
            { x: '2022', y: 'Mar', v: 88 }, { x: '2023', y: 'Mar', v: 68 }, { x: '2024', y: 'Mar', v: 47 },
            { x: '2016', y: 'Apr', v: 52 }, { x: '2017', y: 'Apr', v: 30 }, { x: '2018', y: 'Apr', v: 49 },
            { x: '2019', y: 'Apr', v: 53 }, { x: '2020', y: 'Apr', v: 46 }, { x: '2021', y: 'Apr', v: 52 },
            { x: '2022', y: 'Apr', v: 45 }, { x: '2023', y: 'Apr', v: 0 }, { x: '2024', y: 'Apr', v: 0 },
            { x: '2016', y: 'May', v: 21 }, { x: '2017', y: 'May', v: 34 }, { x: '2018', y: 'May', v: 44 },
            { x: '2019', y: 'May', v: 85 }, { x: '2020', y: 'May', v: 36 }, { x: '2021', y: 'May', v: 53 },
            { x: '2022', y: 'May', v: 52 }, { x: '2023', y: 'May', v: 64 }, { x: '2024', y: 'May', v: 0 },
            { x: '2016', y: 'Jun', v: 19 }, { x: '2017', y: 'Jun', v: 35 }, { x: '2018', y: 'Jun', v: 51 },
            { x: '2019', y: 'Jun', v: 58 }, { x: '2020', y: 'Jun', v: 59 }, { x: '2021', y: 'Jun', v: 42 },
            { x: '2022', y: 'Jun', v: 52 }, { x: '2023', y: 'Jun', v: 25 }, { x: '2024', y: 'Jun', v: 0 },
            { x: '2016', y: 'Jul', v: 20 }, { x: '2017', y: 'Jul', v: 24 }, { x: '2018', y: 'Jul', v: 57 },
            { x: '2019', y: 'Jul', v: 88 }, { x: '2020', y: 'Jul', v: 44 }, { x: '2021', y: 'Jul', v: 61 },
            { x: '2022', y: 'Jul', v: 40 }, { x: '2023', y: 'Jul', v: 50 }, { x: '2024', y: 'Jul', v: 0 },
            { x: '2016', y: 'Aug', v: 33 }, { x: '2017', y: 'Aug', v: 25 }, { x: '2018', y: 'Aug', v: 50 },
            { x: '2019', y: 'Aug', v: 75 }, { x: '2020', y: 'Aug', v: 53 }, { x: '2021', y: 'Aug', v: 49 },
            { x: '2022', y: 'Aug', v: 48 }, { x: '2023', y: 'Aug', v: 34 }, { x: '2024', y: 'Aug', v: 0 },
            { x: '2016', y: 'Sep', v: 22 }, { x: '2017', y: 'Sep', v: 47 }, { x: '2018', y: 'Sep', v: 64 },
            { x: '2019', y: 'Sep', v: 78 }, { x: '2020', y: 'Sep', v: 57 }, { x: '2021', y: 'Sep', v: 54 },
            { x: '2022', y: 'Sep', v: 45 }, { x: '2023', y: 'Sep', v: 26 }, { x: '2024', y: 'Sep', v: 0 },
            { x: '2016', y: 'Oct', v: 31 }, { x: '2017', y: 'Oct', v: 31 }, { x: '2018', y: 'Oct', v: 66 },
            { x: '2019', y: 'Oct', v: 82 }, { x: '2020', y: 'Oct', v: 52 }, { x: '2021', y: 'Oct', v: 51 },
            { x: '2022', y: 'Oct', v: 39 }, { x: '2023', y: 'Oct', v: 25 }, { x: '2024', y: 'Oct', v: 0 },
            { x: '2016', y: 'Nov', v: 29 }, { x: '2017', y: 'Nov', v: 47 }, { x: '2018', y: 'Nov', v: 47 },
            { x: '2019', y: 'Nov', v: 67 }, { x: '2020', y: 'Nov', v: 47 }, { x: '2021', y: 'Nov', v: 47 },
            { x: '2022', y: 'Nov', v: 41 }, { x: '2023', y: 'Nov', v: 34 }, { x: '2024', y: 'Nov', v: 0 },
            { x: '2016', y: 'Dec', v: 17 }, { x: '2017', y: 'Dec', v: 27 }, { x: '2018', y: 'Dec', v: 39 },
            { x: '2019', y: 'Dec', v: 42 }, { x: '2020', y: 'Dec', v: 27 }, { x: '2021', y: 'Dec', v: 49 },
            { x: '2022', y: 'Dec', v: 40 }, { x: '2023', y: 'Dec', v: 31 }, { x: '2024', y: 'Dec', v: 0 },
          ],
          backgroundColor: function(context) {
            const value = context.dataset.data[context.dataIndex].v;
            const alpha = (value / 100).toFixed(2);
            return `rgba(255, ${255 - (value * 2.55)}, 0, ${alpha})`;
          },
          borderWidth: 1,
        },
      ],
    };
  
    const options = {
        responsive: true,
    maintainAspectRatio: false,
      scales: {
        x: {
          type: 'category',
          labels: ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
          title: {
            display: true,
            text: 'Year',
          },
        },
        y: {
          type: 'category',
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          title: {
            display: true,
            text: 'Month',
          },
        },
      },
      plugins: {
        legend: { display: true },
        tooltip: { callbacks: { label: context => `Opportunities: ${context.raw.v}` } },
      },
    };
  
    return (
    <div className="">
        <div className='flex justify-center my-4'>
            Seasonal patterns of inbound opportunities across years
        </div>
        <div className="w-[80%] ml-24 flex justify-center" style={{ height: '400px' }}>
          <ReactChartJS type="matrix" data={data} options={options} />
        </div>
    </div>
    );
  };
  
  export default HeatmapComponent;
  
