import { atom } from "recoil";

const leadGumloopRowsAtom = atom({
  key: "leadGumloopRows",
  default: 1,
});

const leadGumloopInputFilledAtom = atom({
  key: "leadGumloopInputFilled",
  default: false,
});

const leadGumloopQuestionAtom = atom({
  key: "leadGumloopQuestion",
  default: "",
});

const leadGumloopChatArrayAtom = atom({
  key: "leadGumloopChatArray",
  default: [
    {
      answer:
        "I see you have selected the Lead Research Template. Would you like help setting it up?"
    },
  ],
});

const leadGumloopLoadingAtom = atom({
  key: "leadGumloopLoading",
  default: false,
});

const leadFocusNodeIdAtom = atom({
    key: "leadFocusNodeId",
    default: null
})

const leadNewNodeAtom = atom({
    key: "leadNewNode",
    default: false
})

const leadContactAtom = atom({
    key: "leadContact",
    default: false
})

const leadSlackAtom = atom({
    key: "leadSlack",
    default: false
})

const leadFitEntireAtom = atom({
    key: "leadFitEntire",
    default: false
})

const leadTestLoadingAtom = atom({
    key: "leadTestLoading",
    default: false
})

const leadDeployAtom = atom({
    key: "leadDeploy",
    default: false
})

const leadCollapsibleAtom = atom({
  key: "leadCollapsible",
  default: false
})

const leadChecked = atom({
  key: "leadChecked",
  default: 1
})

const leadEmailAtom = atom({
  key: "leadEmail",
  default: "david.slavich@talkable.com"
})

const leadPolledContentAtom = atom({
  key: "leadPolledContent",
  default: ""
})

const leadTestEmailAtom = atom({
  key: "leadTestEmail",
  default: ""
})

const leadVisibleAtom = atom({
  key: "leadVisible",
  default: [0, 1, 2]
})

const leadActiveIndexAtom = atom({
  key: "leadActiveIndex",
  default: 0
})

const leadBtnLoadingAtom = atom({
  key: "leadBtnLoading",
  default: false
})

export {
  leadGumloopRowsAtom,
  leadGumloopInputFilledAtom,
  leadGumloopQuestionAtom,
  leadGumloopChatArrayAtom,
  leadGumloopLoadingAtom,
  leadFocusNodeIdAtom,
  leadNewNodeAtom,
  leadContactAtom,
  leadSlackAtom,
  leadFitEntireAtom,
  leadTestLoadingAtom,
  leadDeployAtom,
  leadCollapsibleAtom,
  leadChecked,
  leadEmailAtom,
  leadPolledContentAtom,
  leadTestEmailAtom,
  leadVisibleAtom,
  leadActiveIndexAtom,
  leadBtnLoadingAtom
};
