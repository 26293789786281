import React from 'react'
import AgentTopBar from '../../components/AgentTopBar/AgentTopBar'
import AgentMainComponent from '../../components/AgentMainComponent/AgentMainComponent'

const Gallery = () => {
  return (
    <div className='px-11 mt-4'>
      <AgentTopBar />
      <AgentMainComponent />
    </div>
  )
}

export default Gallery