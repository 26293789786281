import React from "react";
import ChatWindowToast from "./ChatWindow.js";
// import InputBox from "./InputBox.js";
import InputBoxToast from "./InputBoxToast.js";

const ToastChat = () => {
  return (
    <div className="flex flex-col h-[90vh] w-full p-2">
      <ChatWindowToast />
      <InputBoxToast />
    </div>
  );
};

export default ToastChat;