const optionsCountry = [
    { value :"Afghanistan", label: "Afghanistan"},
    { value :"Aland Islands", label: "Aland Islands"},
    { value :"Albania", label: "Albania"},
    { value :"Algeria", label: "Algeria"},
    { value :"American Samoa", label: "American Samoa"},
    { value :"Andorra", label: "Andorra"},
    { value :"Angola", label: "Angola"},
    { value :"Anguilla", label: "Anguilla"},
    { value :"Antarctica", label: "Antarctica"},
    { value :"Antigua and Barbuda", label: "Antigua and Barbuda"},
    { value :"Argentina", label: "Argentina"},
    { value :"Armenia", label: "Armenia"},
    { value :"Aruba", label: "Aruba"},
    { value :"Australia", label: "Australia"},
    { value :"Austria", label: "Austria"},
    { value :"Azerbaijan", label: "Azerbaijan"},
    { value :"Bahamas", label: "Bahamas"},
    { value :"Bahrain", label: "Bahrain"},
    { value :"Bangladesh", label: "Bangladesh"},
    { value :"Barbados", label: "Barbados"},
    { value :"Belarus", label: "Belarus"},
    { value :"Belgium", label: "Belgium"},
    { value :"Belize", label: "Belize"},
    { value :"Benin", label: "Benin"},
    { value :"Bermuda", label: "Bermuda"},
    { value :"Bhutan", label: "Bhutan"},
    { value :"Bolivia", label: "Bolivia"},
    { value :"Bonaire", label: "Bonaire"},
    { value :"Bosnia and Herzegovina", label: "Bosnia and Herzegovina"},
    { value :"Botswana", label: "Botswana"},
    { value :"Bouvet Island", label: "Bouvet Island"},
    { value :"Brazil", label: "Brazil"},
    { value :"British Indian Ocean Territory", label: "British Indian Ocean Territory"},
    { value :"Brunei", label: "Brunei"},
    { value :"Bulgaria", label: "Bulgaria"},
    { value :"Burkina Faso", label: "Burkina Faso"},
    { value :"Burundi", label: "Burundi"},
    { value :"Cape Verde", label: "Cape Verde"},
    { value :"Cambodia", label: "Cambodia"},
    { value :"Cameroon", label: "Cameroon"},
    { value :"Canada", label: "Canada"},
    { value :"Cayman Islands", label: "Cayman Islands"},
    { value :"Central African Republic", label: "Central African Republic"},
    { value :"Chad", label: "Chad"},
    { value :"Chile", label: "Chile"},
    { value :"China", label: "China"},
    { value :"Christmas Island", label: "Christmas Island"},
    { value :"Cocos Islands", label: "Cocos Islands"},
    { value :"Colombia", label: "Colombia"},
    { value :"Comoros", label: "Comoros"},
    { value :"Congo", label: "Congo"},
    { value :"DCR", label: "DCR"},
    { value :"Cook Islands", label: "Cook Islands"},
    { value :"Costa Rica", label: "Costa Rica"},
    { value :"Ivory Coast", label: "Ivory Coast"},
    { value :"Croatia", label: "Croatia"},
    { value :"Cuba", label: "Cuba"},
    { value :"Curacao", label: "Curacao"},
    { value :"Cyprus", label: "Cyprus"},
    { value :"Czech Republic", label: "Czech Republic"},
    { value :"Denmark", label: "Denmark"},
    { value :"Djibouti", label: "Djibouti"},
    { value :"Dominica", label: "Dominica"},
    { value :"Dominican Republic", label: "Dominican Republic"},
    { value :"Ecuador", label: "Ecuador"},
    { value :"Egypt", label: "Egypt"},
    { value :"El Salvador", label: "El Salvador"},
    { value :"Equatorial Guinea", label: "Equatorial Guinea"},
    { value :"Eritrea", label: "Eritrea"},
    { value :"Estonia", label: "Estonia"},
    { value :"Eswatini", label: "Eswatini"},
    { value :"Ethiopia", label: "Ethiopia"},
    { value :"Falkland Islands", label: "Falkland Islands"},
    { value :"Faroe Islands", label: "Faroe Islands"},
    { value :"Fiji", label: "Fiji"},
    { value :"Finland", label: "Finland"},
    { value :"France", label: "France"},
    { value :"French Guiana", label: "French Guiana"},
    { value :"French Polynesia", label: "French Polynesia"},
    { value :"French Southern Territories", label: "French Southern Territories"},
    { value :"Gabon", label: "Gabon"},
    { value :"Gambia", label: "Gambia"},
    { value :"Georgia", label: "Georgia"},
    { value :"Germany", label: "Germany"},
    { value :"Ghana", label: "Ghana"},
    { value :"Gibraltar", label: "Gibraltar"},
    { value :"Greece", label: "Greece"},
    { value :"Greenland", label: "Greenland"},
    { value :"Grenada", label: "Grenada"},
    { value :"Guadeloupe", label: "Guadeloupe"},
    { value :"Guam", label: "Guam"},
    { value :"Guatemala", label: "Guatemala"},
    { value :"Guernsey", label: "Guernsey"},
    { value :"Guinea", label: "Guinea"},
    { value :"Guinea Bissau", label: "Guinea Bissau"},
    { value :"Guyana", label: "Guyana"},
    { value :"Haiti", label: "Haiti"},
    { value :"Heard Island", label: "Heard Island"},
    { value :"Vatican City", label: "Vatican City"},
    { value :"Honduras", label: "Honduras"},
    { value :"Hong Kong", label: "Hong Kong"},
    { value :"Hungary", label: "Hungary"},
    { value :"Iceland", label: "Iceland"},
    { value :"India", label: "India"},
    { value :"Indonesia", label: "Indonesia"},
    { value :"Iran", label: "Iran"},
    { value :"Iraq", label: "Iraq"},
    { value :"Ireland", label: "Ireland"},
    { value :"Isle of Man", label: "Isle of Man"},
    { value :"Israel", label: "Israel"},
    { value :"Italy", label: "Italy"},
    { value :"Jamaica", label: "Jamaica"},
    { value :"Japan", label: "Japan"},
    { value :"Jersey", label: "Jersey"},
    { value :"Jordan", label: "Jordan"},
    { value :"Kazakhstan", label: "Kazakhstan"},
    { value :"Kenya", label: "Kenya"},
    { value :"Kiribati", label: "Kiribati"},
    { value :"North Korea", label: "North Korea"},
    { value :"South Korea", label: "South Korea"},
    { value :"Kuwait", label: "Kuwait"},
    { value :"Kyrgyzstan", label: "Kyrgyzstan"},
    { value :"Laos", label: "Laos"},
    { value :"Latvia", label: "Latvia"},
    { value :"Lebanon", label: "Lebanon"},
    { value :"Lesotho", label: "Lesotho"},
    { value :"Liberia", label: "Liberia"},
    { value :"Libya", label: "Libya"},
    { value :"Liechtenstein", label: "Liechtenstein"},
    { value :"Lithuania", label: "Lithuania"},
    { value :"Luxembourg", label: "Luxembourg"},
    { value :"Macao", label: "Macao"},
    { value :"Madagascar", label: "Madagascar"},
    { value :"Malawi", label: "Malawi"},
    { value :"Malaysia", label: "Malaysia"},
    { value :"Maldives", label: "Maldives"},
    { value :"Mali", label: "Mali"},
    { value :"Malta", label: "Malta"},
    { value :"Marshall Islands", label: "Marshall Islands"},
    { value :"Martinique", label: "Martinique"},
    { value :"Mauritania", label: "Mauritania"},
    { value :"Mauritius", label: "Mauritius"},
    { value :"Mayotte", label: "Mayotte"},
    { value :"Mexico", label: "Mexico"},
    { value :"Micronesia", label: "Micronesia"},
    { value :"Moldova", label: "Moldova"},
    { value :"Monaco", label: "Monaco"},
    { value :"Mongolia", label: "Mongolia"},
    { value :"Montenegro", label: "Montenegro"},
    { value :"Montserrat", label: "Montserrat"},
    { value :"Morocco", label: "Morocco"},
    { value :"Mozambique", label: "Mozambique"},
    { value :"Myanmar", label: "Myanmar"},
    { value :"Namibia", label: "Namibia"},
    { value :"Nauru", label: "Nauru"},
    { value :"Nepal", label: "Nepal"},
    { value :"Netherlands", label: "Netherlands"},
    { value :"Netherlands Antilles", label: "Netherlands Antilles"},
    { value :"New Caledonia", label: "New Caledonia"},
    { value :"New Zealand", label: "New Zealand"},
    { value :"Nicaragua", label: "Nicaragua"},
    { value :"Niger", label: "Niger"},
    { value :"Nigeria", label: "Nigeria"},
    { value :"Niue", label: "Niue"},
    { value :"Norfolk Island", label: "Norfolk Island"},
    { value :"Macedonia", label: "Macedonia"},
    { value :"Northern Mariana Islands", label: "Northern Mariana Islands"},
    { value :"Norway", label: "Norway"},
    { value :"Oman", label: "Oman"},
    { value :"Pakistan", label: "Pakistan"},
    { value :"Palau", label: "Palau"},
    { value :"Palestine", label: "Palestine"},
    { value :"Panama", label: "Panama"},
    { value :"Papua New Guinea", label: "Papua New Guinea"},
    { value :"Paraguay", label: "Paraguay"},
    { value :"Peru", label: "Peru"},
    { value :"Philippines", label: "Philippines"},
    { value :"Pitcairn", label: "Pitcairn"},
    { value :"Poland", label: "Poland"},
    { value :"Portugal", label: "Portugal"},
    { value :"Puerto Rico", label: "Puerto Rico"},
    { value :"Qatar", label: "Qatar"},
    { value :"Réunion", label: "Réunion"},
    { value :"Romania", label: "Romania"},
    { value :"Russian Federation", label: "Russian Federation"},
    { value :"Rwanda", label: "Rwanda"},
    { value :"Saint Barthélemy", label: "Saint Barthélemy"},
    { value :"Saint Helena", label: "Saint Helena"},
    { value :"Saint Kitts and Nevis", label: "Saint Kitts and Nevis"},
    { value :"Saint Lucia", label: "Saint Lucia"},
    { value :"Saint Martin (FR)", label: "Saint Martin (FR)"},
    { value :"Saint Pierre", label: "Saint Pierre"},
    { value :"Saint Vincent", label: "Saint Vincent"},
    { value :"Samoa", label: "Samoa"},
    { value :"San Marino", label: "San Marino"},
    { value :"Sao Tome and Principe", label: "Sao Tome and Principe"},
    { value :"Saudi Arabia", label: "Saudi Arabia"},
    { value :"Senegal", label: "Senegal"},
    { value :"Serbia", label: "Serbia"},
    { value :"Seychelles", label: "Seychelles"},
    { value :"Sierra Leone", label: "Sierra Leone"},
    { value :"Singapore", label: "Singapore"},
    { value :"Sint Maarten (DE)", label: "Sint Maarten (DE)"},
    { value :"Slovakia", label: "Slovakia"},
    { value :"Slovenia", label: "Slovenia"},
    { value :"Solomon Islands", label: "Solomon Islands"},
    { value :"Somalia", label: "Somalia"},
    { value :"South Africa", label: "South Africa"},
    { value :"South Georgia", label: "South Georgia"},
    { value :"South Sudan", label: "South Sudan"},
    { value :"Spain", label: "Spain"},
    { value :"Sri Lanka", label: "Sri Lanka"},
    { value :"Sudan", label: "Sudan"},
    { value :"Suriname", label: "Suriname"},
    { value :"Svalbard", label: "Svalbard"},
    { value :"Sweden", label: "Sweden"},
    { value :"Switzerland", label: "Switzerland"},
    { value :"Syria", label: "Syria"},
    { value :"Taiwan", label: "Taiwan"},
    { value :"Tajikistan", label: "Tajikistan"},
    { value :"Tanzania", label: "Tanzania"},
    { value :"Thailand", label: "Thailand"},
    { value :"Timor-Leste", label: "Timor-Leste"},
    { value :"Togo", label: "Togo"},
    { value :"Tokelau", label: "Tokelau"},
    { value :"Tonga", label: "Tonga"},
    { value :"Trinidad and Tobago", label: "Trinidad and Tobago"},
    { value :"Tunisia", label: "Tunisia"},
    { value :"Turkey", label: "Turkey"},
    { value :"Turkmenistan", label: "Turkmenistan"},
    { value :"Turks and Caicos Islands", label: "Turks and Caicos Islands"},
    { value :"Tuvalu", label: "Tuvalu"},
    { value :"Uganda", label: "Uganda"},
    { value :"Ukraine", label: "Ukraine"},
    { value :"United Arab Emirates", label: "United Arab Emirates"},
    { value :"United Kingdom", label: "United Kingdom"},
    { value :"United States Minor Outlying Islands", label: "United States Minor Outlying Islands"},
    { value :"United States", label: "United States"},
    { value :"Uruguay", label: "Uruguay"},
    { value :"Uzbekistan", label: "Uzbekistan"},
    { value :"Vanuatu", label: "Vanuatu"},
    { value :"Venezuela", label: "Venezuela"},
    { value :"Vietnam", label: "Vietnam"},
    { value :"Virgin Islands (British)", label: "Virgin Islands (British)"},
    { value :"Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)"},
    { value :"Wallis and Futuna", label: "Wallis and Futuna"},
    { value :"Western Sahara", label: "Western Sahara"},
    { value :"Yemen", label: "Yemen"},
    { value :"Zambia", label: "Zambia"},
    { value :"Zimbabwe", label: "Zimbabwe"},
  ];
const optionsIndustry = [
    { value : "10101010", label : "Oil & Gas Drilling"},
    { value : "10101020", label : "Oil & Gas Equipment & Services"},
    { value : "10102010", label : "Integrated Oil & Gas"},
    { value : "10102020", label : "Oil & Gas Exploration & Production"},
    { value : "10102030", label : "Oil & Gas Refining & Marketing"},
    { value : "10102040", label : "Oil & Gas Storage & Transportation"},
    { value : "10102050", label : "Coal & Consumable Fuels"},
    { value : "15101010", label : "Commodity Chemicals"},
    { value : "15101020", label : "Diversified Chemicals"},
    { value : "15101030", label : "Fertilizers & Agricultural Chemicals"},
    { value : "15101040", label : "Industrial Gases"},
    { value : "15101050", label : "Specialty Chemicals"},
    { value : "15102010", label : "Construction Materials"},
    { value : "15103010", label : "Metal & Glass Containers"},
    { value : "15103020", label : "Paper Packaging"},
    { value : "15104010", label : "Aluminum"},
    { value : "15104020", label : "Diversified Metals & Mining"},
    { value : "15104025", label : "Copper"},
    { value : "15104030", label : "Gold"},
    { value : "15104040", label : "Precious Metals & Minerals"},
    { value : "15104045", label : "Silver"},
    { value : "15104050", label : "Steel"},
    { value : "15105010", label : "Forest Products"},
    { value : "15105020", label : "Paper Products"},
    { value : "20101010", label : "Aerospace & Defense"},
    { value : "20102010", label : "Building Products"},
    { value : "20103010", label : "Construction & Engineering"},
    { value : "20104010", label : "Electrical Components & Equipment"},
    { value : "20104020", label : "Heavy Electrical Equipment"},
    { value : "20105010", label : "Industrial Conglomerates"},
    { value : "20106010", label : "Construction Machinery & Heavy Trucks"},
    { value : "20106015", label : "Agricultural & Farm Machinery"},
    { value : "20106020", label : "Industrial Machinery"},
    { value : "20107010", label : "Trading Companies & Distributors"},
    { value : "20201010", label : "Commercial Printing"},
    { value : "20201050", label : "Environmental & Facilities Services"},
    { value : "20201060", label : "Office Services & Supplies"},
    { value : "20201070", label : "Diversified Support Services"},
    { value : "20201080", label : "Security & Alarm Services"},
    { value : "20202010", label : "Human Resource & Employment Services"},
    { value : "20202020", label : "Research & Consulting Services"},
    { value : "20301010", label : "Air Freight & Logistics"},
    { value : "20302010", label : "Airlines"},
    { value : "20303010", label : "Marine"},
    { value : "20304010", label : "Railroads"},
    { value : "20304020", label : "Trucking"},
    { value : "20305010", label : "Airport Services"},
    { value : "20305020", label : "Highways & Railtracks"},
    { value : "20305030", label : "Marine Ports & Services"},
    { value : "25101010", label : "Auto Parts & Equipment"},
    { value : "25101020", label : "Tires & Rubber"},
    { value : "25102010", label : "Automobile Manufacturers"},
    { value : "25102020", label : "Motorcycle Manufacturers"},
    { value : "25201010", label : "Consumer Electronics"},
    { value : "25201020", label : "Home Furnishings"},
    { value : "25201030", label : "Homebuilding"},
    { value : "25201040", label : "Household Appliances"},
    { value : "25201050", label : "Housewares & Specialties"},
    { value : "25202010", label : "Leisure Products"},
    { value : "25203010", label : "Apparel, Accessories & Luxury Goods"},
    { value : "25203020", label : "Footwear"},
    { value : "25203030", label : "Textiles"},
    { value : "25301010", label : "Casinos & Gaming"},
    { value : "25301020", label : "Hotels, Resorts & Cruise Lines"},
    { value : "25301030", label : "Leisure Facilities"},
    { value : "25301040", label : "Restaurants"},
    { value : "25302010", label : "Education Services"},
    { value : "25302020", label : "Specialized Consumer Services"},
    { value : "25501010", label : "Distributors"},
    { value : "25502020", label : "Internet & Direct Marketing Retail"},
    { value : "25503010", label : "Department Stores"},
    { value : "25503020", label : "General Merchandise Stores"},
    { value : "25504010", label : "Apparel Retail"},
    { value : "25504020", label : "Computer & Electronics Retail"},
    { value : "25504030", label : "Home Improvement Retail"},
    { value : "25504040", label : "Specialty Stores"},
    { value : "25504050", label : "Automotive Retail"},
    { value : "25504060", label : "Homefurnishing Retail"},
    { value : "30101010", label : "Drug Retail"},
    { value : "30101020", label : "Food Distributors"},
    { value : "30101030", label : "Food Retail"},
    { value : "30101040", label : "Hypermarkets & Super Centers"},
    { value : "30201010", label : "Brewers"},
    { value : "30201020", label : "Distillers & Vintners"},
    { value : "30201030", label : "Soft Drinks"},
    { value : "30202010", label : "Agricultural Products"},
    { value : "30202030", label : "Packaged Foods & Meats"},
    { value : "30203010", label : "Tobacco"},
    { value : "30301010", label : "Household Products"},
    { value : "30302010", label : "Personal Products"},
    { value : "35101010", label : "Health Care Equipment"},
    { value : "35101020", label : "Health Care Supplies"},
    { value : "35102010", label : "Health Care Distributors"},
    { value : "35102015", label : "Health Care Services"},
    { value : "35102020", label : "Health Care Facilities"},
    { value : "35102030", label : "Managed Health Care"},
    { value : "35103010", label : "Health Care Technology"},
    { value : "35201010", label : "Biotechnology"},
    { value : "35202010", label : "Pharmaceuticals"},
    { value : "35203010", label : "Life Sciences Tools & Services"},
    { value : "40101010", label : "Diversified Banks"},
    { value : "40101015", label : "Regional Banks"},
    { value : "40102010", label : "Thrifts & Mortgage Finance"},
    { value : "40201020", label : "Other Diversified Financial Services"},
    { value : "40201030", label : "Multi-Sector Holdings"},
    { value : "40201040", label : "Specialized Finance"},
    { value : "40202010", label : "Consumer Finance"},
    { value : "40203010", label : "Asset Management & Custody Banks"},
    { value : "40203020", label : "Investment Banking & Brokerage"},
    { value : "40203030", label : "Diversified Capital Markets"},
    { value : "40203040", label : "Financial Exchanges & Data"},
    { value : "40204010", label : "Mortgage REITs"},
    { value : "40301010", label : "Insurance Brokers"},
    { value : "40301020", label : "Life & Health Insurance"},
    { value : "40301030", label : "Multi-line Insurance"},
    { value : "40301040", label : "Property & Casualty Insurance"},
    { value : "40301050", label : "Reinsurance"},
    { value : "45102010", label : "IT Consulting & Other Services"},
    { value : "45102020", label : "Data Processing & Outsourced Services"},
    { value : "45102030", label : "Internet Services & Infrastructure"},
    { value : "45103010", label : "Application Software"},
    { value : "45103020", label : "Systems Software"},
    { value : "45201020", label : "Communications Equipment"},
    { value : "45202030", label : "Technology Hardware, Storage & Peripherals"},
    { value : "45203010", label : "Electronic Equipment & Instruments"},
    { value : "45203015", label : "Electronic Components"},
    { value : "45203020", label : "Electronic Manufacturing Services"},
    { value : "45203030", label : "Technology Distributors"},
    { value : "45301010", label : "Semiconductor Equipment"},
    { value : "45301020", label : "Semiconductors"},
    { value : "50101010", label : "Alternative Carriers"},
    { value : "50101020", label : "Integrated Telecommunication Services"},
    { value : "50102010", label : "Wireless Telecommunication Services"},
    { value : "50201010", label : "Advertising"},
    { value : "50201020", label : "Broadcasting"},
    { value : "50201030", label : "Cable & Satellite"},
    { value : "50201040", label : "Publishing"},
    { value : "50202010", label : "Movies & Entertainment"},
    { value : "50202020", label : "Interactive Home Entertainment"},
    { value : "50203010", label : "Interactive Media & Services"},
    { value : "55101010", label : "Electric Utilities"},
    { value : "55102010", label : "Gas Utilities"},
    { value : "55103010", label : "Multi-Utilities"},
    { value : "55104010", label : "Water Utilities"},
    { value : "55105010", label : "Independent Power Producers & Energy Traders"},
    { value : "55105020", label : "Renewable Electricity"},
    { value : "60101010", label : "Diversified REITs"},
    { value : "60101020", label : "Industrial REITs"},
    { value : "60101030", label : "Hotel & Resort REITs"},
    { value : "60101040", label : "Office REITs"},
    { value : "60101050", label : "Health Care REITs"},
    { value : "60101060", label : "Residential REITs"},
    { value : "60101070", label : "Retail REITs"},
    { value : "60101080", label : "Specialized REITs"},
    { value : "60102010", label : "Diversified Real Estate Activities"},
    { value : "60102020", label : "Real Estate Operating Companies"},
    { value : "60102030", label : "Real Estate Development"},
    { value : "60102040", label : "Real Estate Services"},
];

const searchFilterIndustry = optionsIndustry.map(({ label }) => ({ value: label, label }));

const optionsRevenueRange = [
    { value : "$0-$1M", label: "$0-$1M"},
    { value : "$1M-$10M", label: "$1M-$10M"},
    { value : "$10M-$50M", label: "$10M-$50M"},
    { value : "$50M-$100M", label: "$50M-$100M"},
    { value : "$100M-$250M", label: "$100M-$250M"},
    { value : "$250M-$500M", label: "$250M-$500M"},
    { value : "$500M-$1B", label: "$500M-$1B"},
    { value : "$1B-$10B", label: "$1B-$10B"},
    { value : "$10B+", label: "$10B+"},  
];
const keywords = [
    
];

const optionsEmployeeRange = [
    { value: "Soho", label: "1-9" },
    { value: "Small", label: "10-99" },
    { value: "Medium", label: "100-499" },
    { value: "Medium Large", label: "500-999" },
    { value: "Enterprise", label: "1000+" }
];
const empMap = {
    'Soho': '1-9',
    'Small': '10-99',
    'Medium': '100-499',
    'Medium Large': '500-999',
    'Enterprise': '1000+'
}

const optionsCompany = [
    { value: "B2B", label: "B2B" },
    { value: "B2C", label: "B2C" }
];

const optionsTechnology = [
    // { value: "Salesforce", label: "Salesforce" },
    // { value: "Confluence", label: "Confluence" },
    // { value: "Marketo", label: "Marketo" },
    // { value: "TEST", label: "TEST" },
    { value: "100 Shopify Products", label: "100 Shopify Products" },
    { value: "123FormBuilder", label: "123FormBuilder" },
    { value: "1and1 Ionos Website Builder", label: "1and1 Ionos Website Builder" },
    { value: "1and1 Website Builder", label: "1and1 Website Builder" },
    { value: "2Checkout", label: "2Checkout" },
    { value: "AB Tasty", label: "AB Tasty" },
    { value: "ABAP", label: "ABAP" },
    { value: "accessiBe", label: "accessiBe" },
    { value: "Active Campaign", label: "Active Campaign" },
    { value: "Acuity Scheduling", label: "Acuity Scheduling" },
    { value: "Acxiom (Unspecified Product)", label: "Acxiom (Unspecified Product)" },
    { value: "AddEvent", label: "AddEvent" },
    { value: "AddThis", label: "AddThis" },
    { value: "AddThisEvent", label: "AddThisEvent" },
    { value: "Adobe (Unspecified Product)", label: "Adobe (Unspecified Product)" },
    { value: "Adobe Acrobat Pro", label: "Adobe Acrobat Pro" },
    { value: "Adobe After Effects CC", label: "Adobe After Effects CC" },
    { value: "Adobe Analytics", label: "Adobe Analytics" },
    { value: "Adobe Audience Manager", label: "Adobe Audience Manager" },
    { value: "Adobe Business Catalyst", label: "Adobe Business Catalyst" },
    { value: "Adobe Captivate", label: "Adobe Captivate" },
    { value: "Adobe CQ", label: "Adobe CQ" },
    { value: "Adobe Creative Cloud", label: "Adobe Creative Cloud" },
    { value: "Adobe Digital Marketing Suite", label: "Adobe Digital Marketing Suite" },
    { value: "Adobe Dreamweaver", label: "Adobe Dreamweaver" },
    { value: "Adobe DTM", label: "Adobe DTM" },
    { value: "Adobe Experience Manager", label: "Adobe Experience Manager" },
    { value: "Adobe Illustrator", label: "Adobe Illustrator" },
    { value: "Adobe InDesign", label: "Adobe InDesign" },
    { value: "Adobe Marketing Cloud", label: "Adobe Marketing Cloud" },
    { value: "Adobe Photoshop CC", label: "Adobe Photoshop CC" },
    { value: "Adobe Premiere Pro CC", label: "Adobe Premiere Pro CC" },
    { value: "Adobe Shockwave", label: "Adobe Shockwave" },
    { value: "Adobe Target Standard", label: "Adobe Target Standard" },
    { value: "Adobe Typekit", label: "Adobe Typekit" },
    { value: "ADP (Unspecified Product)", label: "ADP (Unspecified Product)" },
    { value: "ADP Payroll", label: "ADP Payroll" },
    { value: "AdRoll", label: "AdRoll" },
    { value: "Agilent Technologies", label: "Agilent Technologies" },
    { value: "Ajax", label: "Ajax" },
    { value: "Akamai (Unspecified Product)", label: "Akamai (Unspecified Product)" },
    { value: "Akamai CDN", label: "Akamai CDN" },
    { value: "Akamai DNS", label: "Akamai DNS" },
    { value: "Akamai Edge", label: "Akamai Edge" },
    { value: "Akamai Hosting", label: "Akamai Hosting" },
    { value: "Alteryx", label: "Alteryx" },
    { value: "Altium (Unspecified Product)", label: "Altium (Unspecified Product)" },
    { value: "Amadeus e-Retail", label: "Amadeus e-Retail" },
    { value: "Amazon", label: "Amazon" },
    { value: "Amazon AWS", label: "Amazon AWS" },
    { value: "Amazon AWS Identity and Access Management (IAM)", label: "Amazon AWS Identity and Access Management (IAM)" },
    { value: "Amazon CloudFront", label: "Amazon CloudFront" },
    { value: "Amazon Cloudwatch", label: "Amazon Cloudwatch" },
    { value: "Amazon DynamoDB", label: "Amazon DynamoDB" },
    { value: "Amazon EC2", label: "Amazon EC2" },
    { value: "Amazon Elastic Load Balancing", label: "Amazon Elastic Load Balancing" },
    { value: "Amazon Payments", label: "Amazon Payments" },
    { value: "Amazon Redshift", label: "Amazon Redshift" },
    { value: "Amazon Relational Database Service (RDS)", label: "Amazon Relational Database Service (RDS)" },
    { value: "Amazon Route 53", label: "Amazon Route 53" },
    { value: "Amazon S3", label: "Amazon S3" },
    { value: "Amazon Web Hosting", label: "Amazon Web Hosting" },
    { value: "American Express", label: "American Express" },
    { value: "Amobee DMP", label: "Amobee DMP" },
    { value: "Android OS", label: "Android OS" },
    { value: "Android SDK", label: "Android SDK" },
    { value: "AngularJS", label: "AngularJS" },
    { value: "Ansible", label: "Ansible" },
    { value: "ANSYS", label: "ANSYS" },
    { value: "Apache (Unspecified Product)", label: "Apache (Unspecified Product)" },
    { value: "Apache ActiveMQ", label: "Apache ActiveMQ" },
    { value: "Apache Cassandra", label: "Apache Cassandra" },
    { value: "Apache Hadoop", label: "Apache Hadoop" },
    { value: "Apache Hbase", label: "Apache Hbase" },
    { value: "Apache Hive", label: "Apache Hive" },
    { value: "Apache HTTP Server", label: "Apache HTTP Server" },
    { value: "Apache JMeter", label: "Apache JMeter" },
    { value: "Apache Kafka", label: "Apache Kafka" },
    { value: "Apache Lucene", label: "Apache Lucene" },
    { value: "Apache Mesos", label: "Apache Mesos" },
    { value: "Apache Oozie", label: "Apache Oozie" },
    { value: "Apache Pig", label: "Apache Pig" },
    { value: "Apache Solr", label: "Apache Solr" },
    { value: "Apache Spark", label: "Apache Spark" },
    { value: "Apache Storm", label: "Apache Storm" },
    { value: "Apache Tomcat", label: "Apache Tomcat" },
    { value: "AppDynamics", label: "AppDynamics" },
    { value: "Appier", label: "Appier" },
    { value: "Apple (Unspecified Product)", label: "Apple (Unspecified Product)" },
    { value: "Apple Final Cut Pro", label: "Apple Final Cut Pro" },
    { value: "Apple iPhone", label: "Apple iPhone" },
    { value: "Apple Pay", label: "Apple Pay" },
    { value: "Apple xCode", label: "Apple xCode" },
    { value: "AppNexus", label: "AppNexus" },
    { value: "ArcGIS Platform", label: "ArcGIS Platform" },
    { value: "Ariba (Unspecified Product)", label: "Ariba (Unspecified Product)" },
    { value: "Ariba Payables", label: "Ariba Payables" },
    { value: "Articulate Storyline", label: "Articulate Storyline" },
    { value: "Aruba", label: "Aruba" },
    { value: "ASP.NET", label: "ASP.NET" },
    { value: "AspDotNetStorefront", label: "AspDotNetStorefront" },
    { value: "Atlassian (Unspecified Product)", label: "Atlassian (Unspecified Product)" },
    { value: "Atlassian Bamboo", label: "Atlassian Bamboo" },
    { value: "Atlassian Bitbucket", label: "Atlassian Bitbucket" },
    { value: "Atlassian Confluence", label: "Atlassian Confluence" },
    { value: "Atlassian JIRA", label: "Atlassian JIRA" },
    { value: "AudioEye", label: "AudioEye" },
    { value: "AutoCAD", label: "AutoCAD" },
    { value: "Autodesk (Unspecified Product)", label: "Autodesk (Unspecified Product)" },
    { value: "Autodesk Inventor", label: "Autodesk Inventor" },
    { value: "Autodesk Maya", label: "Autodesk Maya" },
    { value: "Avaya (Unspecified Product)", label: "Avaya (Unspecified Product)" },
    { value: "AWeber Email", label: "AWeber Email" },
    { value: "AWS CloudFormation", label: "AWS CloudFormation" },
    { value: "AWS Global Accelerator", label: "AWS Global Accelerator" },
    { value: "AWS Lambda", label: "AWS Lambda" },
    { value: "Axure RP", label: "Axure RP" },
    { value: "Azure DNS", label: "Azure DNS" },
    { value: "Azure Hosting", label: "Azure Hosting" },
    { value: "Azure Redis Cache", label: "Azure Redis Cache" },
    { value: "Balsamiq", label: "Balsamiq" },
    { value: "Bancontact", label: "Bancontact" },
    { value: "Basecamp", label: "Basecamp" },
    { value: "Bazaarvoice", label: "Bazaarvoice" },
    { value: "Big Cartel", label: "Big Cartel" },
    { value: "BigCommerce", label: "BigCommerce" },
    { value: "Bing Universal Event Tracking", label: "Bing Universal Event Tracking" },
    { value: "Bizo", label: "Bizo" },
    { value: "BizTalk Server", label: "BizTalk Server" },
    { value: "BlackBerry", label: "BlackBerry" },
    { value: "BlackLine", label: "BlackLine" },
    { value: "Bloomberg", label: "Bloomberg" },
    { value: "Blue Coat", label: "Blue Coat" },
    { value: "BlueKai", label: "BlueKai" },
    { value: "BMC Control-M", label: "BMC Control-M" },
    { value: "BMC Software", label: "BMC Software" },
    { value: "BootstrapCDN", label: "BootstrapCDN" },
    { value: "Braintree", label: "Braintree" },
    { value: "Brightcove", label: "Brightcove" },
    { value: "Brivity", label: "Brivity" },
    { value: "Brocade", label: "Brocade" },
    { value: "Bugzilla", label: "Bugzilla" },
    { value: "BV Commerce", label: "BV Commerce" },
    { value: "C#", label: "C#" },
    { value: "CA Agile Central", label: "CA Agile Central" },
    { value: "Canonical Ubuntu", label: "Canonical Ubuntu" },
    { value: "Cart Functionality", label: "Cart Functionality" },
    { value: "CATIA", label: "CATIA" },
    { value: "CCBill", label: "CCBill" },
    { value: "Celerant", label: "Celerant" },
    { value: "CentOS", label: "CentOS" },
    { value: "Chaty Premio", label: "Chaty Premio" },
    { value: "Checkout.com", label: "Checkout.com" },
    { value: "Chef", label: "Chef" },
    { value: "Cisco Adaptive Security Appliances", label: "Cisco Adaptive Security Appliances" },
    { value: "Cisco Catalyst 65", label: "Cisco Catalyst 65" },
    { value: "Cisco Catalyst Switches", label: "Cisco Catalyst Switches" },
    { value: "Cisco Firewalls", label: "Cisco Firewalls" },
    { value: "Cisco Identity Services Engine", label: "Cisco Identity Services Engine" },
    { value: "Cisco Jabber", label: "Cisco Jabber" },
    { value: "Cisco Nexus 7000", label: "Cisco Nexus 7000" },
    { value: "Cisco Nexus Switches", label: "Cisco Nexus Switches" },
    { value: "Cisco Routers", label: "Cisco Routers" },
    { value: "Cisco Switches", label: "Cisco Switches" },
    { value: "Cisco VoIP", label: "Cisco VoIP" },
    { value: "Cisco WebEx", label: "Cisco WebEx" },
    { value: "Citrix (Unspecified Product)", label: "Citrix (Unspecified Product)" },
    { value: "Citrix NetScaler", label: "Citrix NetScaler" },
    { value: "Citrix Virtual Apps", label: "Citrix Virtual Apps" },
    { value: "Citrix Virtual Desktops", label: "Citrix Virtual Desktops" },
    { value: "Clarivate MarkMonitor", label: "Clarivate MarkMonitor" },
    { value: "ClickCertain", label: "ClickCertain" },
    { value: "ClickFunnels", label: "ClickFunnels" },
    { value: "ClickTale", label: "ClickTale" },
    { value: "Cloudera", label: "Cloudera" },
    { value: "Cloudera Impala", label: "Cloudera Impala" },
    { value: "CloudFlare CDN", label: "CloudFlare CDN" },
    { value: "Cloudflare Hosting", label: "Cloudflare Hosting" },
    { value: "Cloudflare Insights", label: "Cloudflare Insights" },
    { value: "Cloudflare Rocket Loader", label: "Cloudflare Rocket Loader" },
    { value: "Cloudflare Web Analytics", label: "Cloudflare Web Analytics" },
    { value: "CNC Software", label: "CNC Software" },
    { value: "COBOL", label: "COBOL" },
    { value: "Commvault (Unspecified Product)", label: "Commvault (Unspecified Product)" },
    { value: "Comodo UserTrust", label: "Comodo UserTrust" },
    { value: "comScore", label: "comScore" },
    { value: "Concur (Unspecified Product)", label: "Concur (Unspecified Product)" },
    { value: "Concur Expense", label: "Concur Expense" },
    { value: "Concur Travel", label: "Concur Travel" },
    { value: "Constant Contact", label: "Constant Contact" },
    { value: "Contact Form 7", label: "Contact Form 7" },
    { value: "Content Square", label: "Content Square" },
    { value: "Convert", label: "Convert" },
    { value: "Corel", label: "Corel" },
    { value: "Crazy Egg", label: "Crazy Egg" },
    { value: "CrazyEgg", label: "CrazyEgg" },
    { value: "Crestron", label: "Crestron" },
    { value: "Criteo", label: "Criteo" },
    { value: "Criteo Direct Bidder", label: "Criteo Direct Bidder" },
    { value: "CS Cart", label: "CS Cart" },
    { value: "CSC Corptax", label: "CSC Corptax" },
    { value: "Cucumber", label: "Cucumber" },
    { value: "Cvent", label: "Cvent" },
    { value: "Dassault SOLIDWORKS", label: "Dassault SOLIDWORKS" },
    { value: "Dassault Systemes (Unspecified Product)", label: "Dassault Systemes (Unspecified Product)" },
    { value: "Datadog", label: "Datadog" },
    { value: "Debian", label: "Debian" },
    { value: "Demandware", label: "Demandware" },
    { value: "DigiCert", label: "DigiCert" },
    { value: "Digital Showroom", label: "Digital Showroom" },
    { value: "DNN Software", label: "DNN Software" },
    { value: "DNS Made Easy", label: "DNS Made Easy" },
    { value: "Docker", label: "Docker" },
    { value: "DocuSign", label: "DocuSign" },
    { value: "Dropbox", label: "Dropbox" },
    { value: "Drupal", label: "Drupal" },
    { value: "Duda", label: "Duda" },
    { value: "Dynatrace (Unspecified Product)", label: "Dynatrace (Unspecified Product)" },
    { value: "DynDNS", label: "DynDNS" },
    { value: "Eclipse IDE", label: "Eclipse IDE" },
    { value: "Elasticsearch", label: "Elasticsearch" },
    { value: "Eloqua", label: "Eloqua" },
    { value: "Enterprise JavaBeans (EJB)", label: "Enterprise JavaBeans (EJB)" },
    { value: "Epicor (Unspecified Product)", label: "Epicor (Unspecified Product)" },
    { value: "ESRI (Unspecified Product)", label: "ESRI (Unspecified Product)" },
    { value: "ETAP", label: "ETAP" },
    { value: "Eventbrite", label: "Eventbrite" },
    { value: "ExactTarget Email", label: "ExactTarget Email" },
    { value: "ExpertRec", label: "ExpertRec" },
    { value: "ExpressionEngine", label: "ExpressionEngine" },
    { value: "F5 BIG-IP", label: "F5 BIG-IP" },
    { value: "F5 Networks", label: "F5 Networks" },
    { value: "Facebook Conversion Tracking", label: "Facebook Conversion Tracking" },
    { value: "Facebook Custom Audiences", label: "Facebook Custom Audiences" },
    { value: "Facebook Customer Chat SDK", label: "Facebook Customer Chat SDK" },
    { value: "Facebook Web Custom Audiences", label: "Facebook Web Custom Audiences" },
    { value: "Facebook Widget", label: "Facebook Widget" },
    { value: "Facebook-Domain-Insights", label: "Facebook-Domain-Insights" },
    { value: "Facebook-for-Websites", label: "Facebook-for-Websites" },
    { value: "Facebook-Like", label: "Facebook-Like" },
    { value: "Facebook-SDK", label: "Facebook-SDK" },
    { value: "FareHarbor", label: "FareHarbor" },
    { value: "Fastly", label: "Fastly" },
    { value: "FileMaker", label: "FileMaker" },
    { value: "Findify", label: "Findify" },
    { value: "Flask", label: "Flask" },
    { value: "FlexSlider", label: "FlexSlider" },
    { value: "Flodesk", label: "Flodesk" },
    { value: "Font Awesome", label: "Font Awesome" },
    { value: "Forcepoint", label: "Forcepoint" },
    { value: "ForeSee Results", label: "ForeSee Results" },
    { value: "Format", label: "Format" },
    { value: "Fortinet", label: "Fortinet" },
    { value: "Fortran", label: "Fortran" },
    { value: "FullCalendar", label: "FullCalendar" },
    { value: "Gandi", label: "Gandi" },
    { value: "Genesys (Unspecified Product)", label: "Genesys (Unspecified Product)" },
    { value: "GeoTrust", label: "GeoTrust" },
    { value: "Ghost", label: "Ghost" },
    { value: "Git", label: "Git" },
    { value: "GitHub", label: "GitHub" },
    { value: "GitLab", label: "GitLab" },
    { value: "GlobalSign", label: "GlobalSign" },
    { value: "Gnuboard", label: "Gnuboard" },
    { value: "GoDaddy Hosting", label: "GoDaddy Hosting" },
    { value: "GoDaddy Website Builder", label: "GoDaddy Website Builder" },
    { value: "Google", label: "Google" },
    { value: "Google Ad Manager", label: "Google Ad Manager" },
    { value: "Google Ads", label: "Google Ads" },
    { value: "Google AdSense", label: "Google AdSense" },
    { value: "Google AdWords Conversion", label: "Google AdWords Conversion" },
    { value: "Google Analytics", label: "Google Analytics" },
    { value: "Google API", label: "Google API" },
    { value: "Google Cloud", label: "Google Cloud" },
    { value: "Google Content Experiments", label: "Google Content Experiments" },
    { value: "Google Conversion Tracking", label: "Google Conversion Tracking" },
    { value: "Google Docs", label: "Google Docs" },
    { value: "Google Drive", label: "Google Drive" },
    { value: "Google Font API", label: "Google Font API" },
    { value: "Google G Suite", label: "Google G Suite" },
    { value: "Google Golang", label: "Google Golang" },
    { value: "Google Hosted jQuery", label: "Google Hosted jQuery" },
    { value: "Google Hosted Libraries", label: "Google Hosted Libraries" },
    { value: "Google Maps", label: "Google Maps" },
    { value: "Google Marketing Platform", label: "Google Marketing Platform" },
    { value: "Google My Business", label: "Google My Business" },
    { value: "Google Optimize 360", label: "Google Optimize 360" },
    { value: "Google Pay", label: "Google Pay" },
    { value: "Google Plus One", label: "Google Plus One" },
    { value: "Google Remarketing", label: "Google Remarketing" },
    { value: "Google Search Appliance", label: "Google Search Appliance" },
    { value: "Google Sheets", label: "Google Sheets" },
    { value: "Google Tag Manager", label: "Google Tag Manager" },
    { value: "Google Translate Widget", label: "Google Translate Widget" },
    { value: "Grafana", label: "Grafana" },
    { value: "GraphQL", label: "GraphQL" },
    { value: "Gravatar", label: "Gravatar" },
    { value: "Gravity Forms", label: "Gravity Forms" },
    { value: "Groove.CM", label: "Groove.CM" },
    { value: "HashiCorp Terraform", label: "HashiCorp Terraform" },
    { value: "Heap", label: "Heap" },
    { value: "Hibu CMS", label: "Hibu CMS" },
    { value: "HighLevel", label: "HighLevel" },
    { value: "Homestead", label: "Homestead" },
    { value: "Hootsuite", label: "Hootsuite" },
    { value: "Hortonworks", label: "Hortonworks" },
    { value: "Hotjar", label: "Hotjar" },
    { value: "HP-UX", label: "HP-UX" },
    { value: "HPE Servers", label: "HPE Servers" },
    { value: "HTML", label: "HTML" },
    { value: "Hubspot", label: "Hubspot" },
    { value: "Hubspot Forms", label: "Hubspot Forms" },
    { value: "HubSpot Marketing Hub", label: "HubSpot Marketing Hub" },
    { value: "Hudson", label: "Hudson" },
    { value: "Hugo", label: "Hugo" },
    { value: "IBM CICS", label: "IBM CICS" },
    { value: "IBM Cognos Analytics", label: "IBM Cognos Analytics" },
    { value: "IBM Db2", label: "IBM Db2" },
    { value: "IBM InfoSphere Platform", label: "IBM InfoSphere Platform" },
    { value: "IBM Lotus Notes", label: "IBM Lotus Notes" },
    { value: "IBM Mainframe", label: "IBM Mainframe" },
    { value: "IBM SPSS Statistics", label: "IBM SPSS Statistics" },
    { value: "IBM System i", label: "IBM System i" },
    { value: "IBM Tivoli", label: "IBM Tivoli" },
    { value: "IBM Watson", label: "IBM Watson" },
    { value: "IBM WebSphere", label: "IBM WebSphere" },
    { value: "IBM WebSphere Application Server", label: "IBM WebSphere Application Server" },
    { value: "iCIMS", label: "iCIMS" },
    { value: "IIS", label: "IIS" },
    { value: "Incapsula", label: "Incapsula" },
    { value: "Infoblox", label: "Infoblox" },
    { value: "Informatica", label: "Informatica" },
    { value: "Informatica PowerCenter", label: "Informatica PowerCenter" },
    { value: "Infusionsoft", label: "Infusionsoft" },
    { value: "Intel (Unspecified Product)", label: "Intel (Unspecified Product)" },
    { value: "IntelliJ", label: "IntelliJ" },
    { value: "Intershop", label: "Intershop" },
    { value: "InVision", label: "InVision" },
    { value: "Iron Mountain", label: "Iron Mountain" },
    { value: "Ivanti (Unspecified Product)", label: "Ivanti (Unspecified Product)" },
    { value: "J2EE", label: "J2EE" },
    { value: "Java", label: "Java" },
    { value: "JavaScript", label: "JavaScript" },
    { value: "JD Edwards", label: "JD Edwards" },
    { value: "JDA", label: "JDA" },
    { value: "Jekyll", label: "Jekyll" },
    { value: "Jenkins", label: "Jenkins" },
    { value: "JetBrains", label: "JetBrains" },
    { value: "Jimdo", label: "Jimdo" },
    { value: "Joomla!", label: "Joomla!" },
    { value: "JotForm", label: "JotForm" },
    { value: "jQuery", label: "jQuery" },
    { value: "Judge Me", label: "Judge Me" },
    { value: "Juniper Networks (Unspecified Product)", label: "Juniper Networks (Unspecified Product)" },
    { value: "Juniper Networks Junos", label: "Juniper Networks Junos" },
    { value: "JW Player", label: "JW Player" },
    { value: "K2", label: "K2" },
    { value: "Kentico Xperience", label: "Kentico Xperience" },
    { value: "Keras", label: "Keras" },
    { value: "Kibana", label: "Kibana" },
    { value: "KISSmetrics", label: "KISSmetrics" },
    { value: "Klarna", label: "Klarna" },
    { value: "Klaviyo", label: "Klaviyo" },
    { value: "Kofax (Unspecified Product)", label: "Kofax (Unspecified Product)" },
    { value: "Kronos (Unspecified Product)", label: "Kronos (Unspecified Product)" },
    { value: "Kubernetes", label: "Kubernetes" },
    { value: "KVM", label: "KVM" },
    { value: "LabVIEW", label: "LabVIEW" },
    { value: "Lawson Software", label: "Lawson Software" },
    { value: "Lead Fusion", label: "Lead Fusion" },
    { value: "LeaseWeb", label: "LeaseWeb" },
    { value: "lemlist", label: "lemlist" },
    { value: "Lenovo (Unspecified Product)", label: "Lenovo (Unspecified Product)" },
    { value: "LexisNexis", label: "LexisNexis" },
    { value: "Lightbox", label: "Lightbox" },
    { value: "LimeSpot", label: "LimeSpot" },
    { value: "Line.me", label: "Line.me" },
    { value: "LinkedIn Insights", label: "LinkedIn Insights" },
    { value: "LinkedIn Marketing Solutions", label: "LinkedIn Marketing Solutions" },
    { value: "LinkedIn Recruiter", label: "LinkedIn Recruiter" },
    { value: "Linux", label: "Linux" },
    { value: "LiveChat", label: "LiveChat" },
    { value: "LivePerson (Unspecified Product)", label: "LivePerson (Unspecified Product)" },
    { value: "Mac OS", label: "Mac OS" },
    { value: "Maestro", label: "Maestro" },
    { value: "Magento", label: "Magento" },
    { value: "Magento (Unspecified Product)", label: "Magento (Unspecified Product)" },
    { value: "Magento Enterprise", label: "Magento Enterprise" },
    { value: "MailChimp", label: "MailChimp" },
    { value: "MailChimp Mandrill", label: "MailChimp Mandrill" },
    { value: "MailerLite", label: "MailerLite" },
    { value: "Marketo", label: "Marketo" },
    { value: "Mastercam", label: "Mastercam" },
    { value: "MasterCard", label: "MasterCard" },
    { value: "MasterPass", label: "MasterPass" },
    { value: "MATLAB", label: "MATLAB" },
    { value: "Matomo", label: "Matomo" },
    { value: "matplotlib", label: "matplotlib" },
    { value: "McAfee (Unspecified Product)", label: "McAfee (Unspecified Product)" },
    { value: "MediaMath", label: "MediaMath" },
    { value: "Meiqia", label: "Meiqia" },
    { value: "Meraki", label: "Meraki" },
    { value: "Mesosphere", label: "Mesosphere" },
    { value: "Micro Focus (Unspecified Product)", label: "Micro Focus (Unspecified Product)" },
    { value: "Micro Focus Application Lifecycle Management", label: "Micro Focus Application Lifecycle Management" },
    { value: "Micro Focus LoadRunner", label: "Micro Focus LoadRunner" },
    { value: "Micro Focus Quality Center Enterprise (QC)", label: "Micro Focus Quality Center Enterprise (QC)" },
    { value: "Micro Focus UFT", label: "Micro Focus UFT" },
    { value: "Micro Focus Unified Functional Testing", label: "Micro Focus Unified Functional Testing" },
    { value: "Microsoft Access", label: "Microsoft Access" },
    { value: "Microsoft Active Directory", label: "Microsoft Active Directory" },
    { value: "Microsoft Active Directory Federation Services", label: "Microsoft Active Directory Federation Services" },
    { value: "Microsoft Azure", label: "Microsoft Azure" },
    { value: "Microsoft Azure DevOps Server", label: "Microsoft Azure DevOps Server" },
    { value: "Microsoft Bing", label: "Microsoft Bing" },
    { value: "Microsoft Bitlocker", label: "Microsoft Bitlocker" },
    { value: "Microsoft Clarity", label: "Microsoft Clarity" },
    { value: "Microsoft Dynamics", label: "Microsoft Dynamics" },
    { value: "Microsoft Exchange 2013", label: "Microsoft Exchange 2013" },
    { value: "Microsoft Exchange Server", label: "Microsoft Exchange Server" },
    { value: "Microsoft Exchange Server 2010", label: "Microsoft Exchange Server 2010" },
    { value: "Microsoft Hyper-V Server", label: "Microsoft Hyper-V Server" },
    { value: "Microsoft IIS", label: "Microsoft IIS" },
    { value: "Microsoft Office", label: "Microsoft Office" },
    { value: "Microsoft Office 365", label: "Microsoft Office 365" },
    { value: "Microsoft OneDrive", label: "Microsoft OneDrive" },
    { value: "Microsoft OneNote", label: "Microsoft OneNote" },
    { value: "Microsoft Outlook", label: "Microsoft Outlook" },
    { value: "Microsoft PowerPoint", label: "Microsoft PowerPoint" },
    { value: "Microsoft Project", label: "Microsoft Project" },
    { value: "Microsoft SharePoint", label: "Microsoft SharePoint" },
    { value: "Microsoft SharePoint Online", label: "Microsoft SharePoint Online" },
    { value: "Microsoft Silverlight", label: "Microsoft Silverlight" },
    { value: "Microsoft Skype for Business", label: "Microsoft Skype for Business" },
    { value: "Microsoft SQL Server", label: "Microsoft SQL Server" },
    { value: "Microsoft SQL Server 2005", label: "Microsoft SQL Server 2005" },
    { value: "Microsoft SQL Server 2008", label: "Microsoft SQL Server 2008" },
    { value: "Microsoft SQL Server 2012", label: "Microsoft SQL Server 2012" },
    { value: "Microsoft SQL Server 2014", label: "Microsoft SQL Server 2014" },
    { value: "Microsoft SQL Server Analysis Services", label: "Microsoft SQL Server Analysis Services" },
    { value: "Microsoft SQL Server Integration Services", label: "Microsoft SQL Server Integration Services" },
    { value: "Microsoft SQL Server Management Studio", label: "Microsoft SQL Server Management Studio" },
    { value: "Microsoft SQL Server Reporting Services", label: "Microsoft SQL Server Reporting Services" },
    { value: "Microsoft System Center", label: "Microsoft System Center" },
    { value: "Microsoft System Center Configuration Manager", label: "Microsoft System Center Configuration Manager" },
    { value: "Microsoft System Center OpsMgr", label: "Microsoft System Center OpsMgr" },
    { value: "Microsoft Visio", label: "Microsoft Visio" },
    { value: "Microsoft Visual Basic", label: "Microsoft Visual Basic" },
    { value: "Microsoft Visual Studio", label: "Microsoft Visual Studio" },
    { value: "Microsoft Windows 7", label: "Microsoft Windows 7" },
    { value: "Microsoft Yammer", label: "Microsoft Yammer" },
    { value: "Microsoft.NET", label: "Microsoft.NET" },
    { value: "MicroStrategy", label: "MicroStrategy" },
    { value: "Mitel (Unspecified Product)", label: "Mitel (Unspecified Product)" },
    { value: "Miva Merchant", label: "Miva Merchant" },
    { value: "Mixpanel", label: "Mixpanel" },
    { value: "MobileIron", label: "MobileIron" },
    { value: "Modernizr", label: "Modernizr" },
    { value: "MongoDB", label: "MongoDB" },
    { value: "Moosend", label: "Moosend" },
    { value: "Mulesoft", label: "Mulesoft" },
    { value: "MySQL", label: "MySQL" },
    { value: "Nagios", label: "Nagios" },
    { value: "National Instruments", label: "National Instruments" },
    { value: "NetApp (Unspecified Product)", label: "NetApp (Unspecified Product)" },
    { value: "NetApp Storage Systems", label: "NetApp Storage Systems" },
    { value: "Netezza", label: "Netezza" },
    { value: "NetSuite", label: "NetSuite" },
    { value: "NetSuite eCommerce", label: "NetSuite eCommerce" },
    { value: "Network Solutions DNS", label: "Network Solutions DNS" },
    { value: "Neustar", label: "Neustar" },
    { value: "New Relic", label: "New Relic" },
    { value: "Nextopia", label: "Nextopia" },
    { value: "nginx", label: "nginx" },
    { value: "Node.js", label: "Node.js" },
    { value: "nopCommerce", label: "nopCommerce" },
    { value: "Nortel", label: "Nortel" },
    { value: "Nosto", label: "Nosto" },
    { value: "nsCommerceSpace", label: "nsCommerceSpace" },
    { value: "NumPy", label: "NumPy" },
    { value: "October CMS", label: "October CMS" },
    { value: "Omniture Adobe Test and Target", label: "Omniture Adobe Test and Target" },
    { value: "Omniture SiteCatalyst", label: "Omniture SiteCatalyst" },
    { value: "ONE by AOL", label: "ONE by AOL" },
    { value: "OpenCart", label: "OpenCart" },
    { value: "OpenID", label: "OpenID" },
    { value: "OpenSSL", label: "OpenSSL" },
    { value: "OpenStack", label: "OpenStack" },
    { value: "OpenText (Unspecified Product)", label: "OpenText (Unspecified Product)" },
    { value: "Optimizely", label: "Optimizely" },
    { value: "OptinMonster", label: "OptinMonster" },
    { value: "Oracle 10g", label: "Oracle 10g" },
    { value: "Oracle 11g Enterprise Edition", label: "Oracle 11g Enterprise Edition" },
    { value: "Oracle 12c", label: "Oracle 12c" },
    { value: "Oracle Cloud", label: "Oracle Cloud" },
    { value: "Oracle Commerce", label: "Oracle Commerce" },
    { value: "Oracle Data Integrator Enterprise Edition", label: "Oracle Data Integrator Enterprise Edition" },
    { value: "Oracle Database", label: "Oracle Database" },
    { value: "Oracle E-Business", label: "Oracle E-Business" },
    { value: "Oracle Enterprise Manager", label: "Oracle Enterprise Manager" },
    { value: "Oracle ERP", label: "Oracle ERP" },
    { value: "Oracle Essbase", label: "Oracle Essbase" },
    { value: "Oracle Forms", label: "Oracle Forms" },
    { value: "Oracle Fusion", label: "Oracle Fusion" },
    { value: "Oracle Fusion Middleware", label: "Oracle Fusion Middleware" },
    { value: "Oracle Hyperion Planning", label: "Oracle Hyperion Planning" },
    { value: "Oracle Real Application Clusters", label: "Oracle Real Application Clusters" },
    { value: "Oracle Recovery Manager", label: "Oracle Recovery Manager" },
    { value: "Oracle WebLogic", label: "Oracle WebLogic" },
    { value: "osCommerce", label: "osCommerce" },
    { value: "OWL Carousel", label: "OWL Carousel" },
    { value: "OXID EShop", label: "OXID EShop" },
    { value: "Palo Alto Networks", label: "Palo Alto Networks" },
    { value: "pandas", label: "pandas" },
    { value: "Parallels", label: "Parallels" },
    { value: "Parallels Plesk", label: "Parallels Plesk" },
    { value: "Pardot", label: "Pardot" },
    { value: "Pardot Mail", label: "Pardot Mail" },
    { value: "PayPal", label: "PayPal" },
    { value: "PayPal Button", label: "PayPal Button" },
    { value: "PayPal BuyNow Button", label: "PayPal BuyNow Button" },
    { value: "PayPal Cart Button", label: "PayPal Cart Button" },
    { value: "PayPal Donate Button", label: "PayPal Donate Button" },
    { value: "PayPal Express Checkout", label: "PayPal Express Checkout" },
    { value: "PayPal Form", label: "PayPal Form" },
    { value: "PayPal Marketing Solutions", label: "PayPal Marketing Solutions" },
    { value: "Pentaho", label: "Pentaho" },
    { value: "PeopleSoft", label: "PeopleSoft" },
    { value: "Perfect Audience", label: "Perfect Audience" },
    { value: "Perfex", label: "Perfex" },
    { value: "Perforce", label: "Perforce" },
    { value: "Perl", label: "Perl" },
    { value: "PersonaClick", label: "PersonaClick" },
    { value: "PHP", label: "PHP" },
    { value: "Pinterest", label: "Pinterest" },
    { value: "Pipedrive", label: "Pipedrive" },
    { value: "Pivotal Cloud Foundry", label: "Pivotal Cloud Foundry" },
    { value: "Pivotal RabbitMQ", label: "Pivotal RabbitMQ" },
    { value: "Pivotal Software", label: "Pivotal Software" },
    { value: "PL/SQL", label: "PL/SQL" },
    { value: "Polycom (Unspecified Product)", label: "Polycom (Unspecified Product)" },
    { value: "PostgreSQL", label: "PostgreSQL" },
    { value: "PrestaShop", label: "PrestaShop" },
    { value: "Primavera Enterprise Project Portfolio Management", label: "Primavera Enterprise Project Portfolio Management" },
    { value: "Progress Sitefinity", label: "Progress Sitefinity" },
    { value: "Progress Software", label: "Progress Software" },
    { value: "Proofpoint", label: "Proofpoint" },
    { value: "Prototype", label: "Prototype" },
    { value: "PTC Creo", label: "PTC Creo" },
    { value: "PTC Mathcad", label: "PTC Mathcad" },
    { value: "PubMatic", label: "PubMatic" },
    { value: "Puppet Enterprise", label: "Puppet Enterprise" },
    { value: "Python", label: "Python" },
    { value: "QlikView", label: "QlikView" },
    { value: "Qualtrics", label: "Qualtrics" },
    { value: "QualysGuard", label: "QualysGuard" },
    { value: "Quantcast (Unspecified Product)", label: "Quantcast (Unspecified Product)" },
    { value: "Quark", label: "Quark" },
    { value: "Quest Software (Unspecified Product)", label: "Quest Software (Unspecified Product)" },
    { value: "QuickBooks", label: "QuickBooks" },
    { value: "R Project", label: "R Project" },
    { value: "Rackspace", label: "Rackspace" },
    { value: "Rackspace Hosting", label: "Rackspace Hosting" },
    { value: "Rapid7", label: "Rapid7" },
    { value: "Razorpay", label: "Razorpay" },
    { value: "ReachLocal", label: "ReachLocal" },
    { value: "ReactJS", label: "ReactJS" },
    { value: "Rebuy", label: "Rebuy" },
    { value: "reCAPTCHA", label: "reCAPTCHA" },
    { value: "Red Hat (Unspecified Product)", label: "Red Hat (Unspecified Product)" },
    { value: "Red Hat OpenShift", label: "Red Hat OpenShift" },
    { value: "Redis", label: "Redis" },
    { value: "Redtail Technology", label: "Redtail Technology" },
    { value: "RequireJS", label: "RequireJS" },
    { value: "Retailer Web Services", label: "Retailer Web Services" },
    { value: "Rhinoceros 3D", label: "Rhinoceros 3D" },
    { value: "Riverbed Technology (Unspecified Product)", label: "Riverbed Technology (Unspecified Product)" },
    { value: "Rocket Chat", label: "Rocket Chat" },
    { value: "Rockwell Automation (Unspecified Product)", label: "Rockwell Automation (Unspecified Product)" },
    { value: "RSLogix", label: "RSLogix" },
    { value: "Rubicon Project (Unspecified Product)", label: "Rubicon Project (Unspecified Product)" },
    { value: "Ruby on Rails", label: "Ruby on Rails" },
    { value: "Salesforce", label: "Salesforce" },
    { value: "Salesforce Commerce Cloud", label: "Salesforce Commerce Cloud" },
    { value: "Salesforce CRM", label: "Salesforce CRM" },
    { value: "Salesforce Email Studio", label: "Salesforce Email Studio" },
    { value: "Salesforce Marketing Cloud", label: "Salesforce Marketing Cloud" },
    { value: "Salesforce.com (Unspecified Product)", label: "Salesforce.com (Unspecified Product)" },
    { value: "Salesforce.com Force.com", label: "Salesforce.com Force.com" },
    { value: "Salesforce.com Heroku", label: "Salesforce.com Heroku" },
    { value: "Salesforce.com Service Cloud", label: "Salesforce.com Service Cloud" },
    { value: "Salla", label: "Salla" },
    { value: "Sana Commerce", label: "Sana Commerce" },
    { value: "SAP BusinessObjects BI", label: "SAP BusinessObjects BI" },
    { value: "SAP Commerce Cloud", label: "SAP Commerce Cloud" },
    { value: "SAP Controlling (CO)", label: "SAP Controlling (CO)" },
    { value: "SAP Crystal Reports", label: "SAP Crystal Reports" },
    { value: "SAP ERP", label: "SAP ERP" },
    { value: "SAP ERP Central Component (ECC)", label: "SAP ERP Central Component (ECC)" },
    { value: "SAP HANA", label: "SAP HANA" },
    { value: "SAP Materials Management", label: "SAP Materials Management" },
    { value: "SAP Procurement", label: "SAP Procurement" },
    { value: "SAP R/3", label: "SAP R/3" },
    { value: "SAP Sales", label: "SAP Sales" },
    { value: "SAP Supply Chain Management", label: "SAP Supply Chain Management" },
    { value: "Sape", label: "Sape" },
    { value: "SAS Base", label: "SAS Base" },
    { value: "ScarabResearch", label: "ScarabResearch" },
    { value: "scikit-learn", label: "scikit-learn" },
    { value: "SciPy", label: "SciPy" },
    { value: "Second Phase", label: "Second Phase" },
    { value: "Selenium", label: "Selenium" },
    { value: "Seliton", label: "Seliton" },
    { value: "Sender", label: "Sender" },
    { value: "SendGrid", label: "SendGrid" },
    { value: "SendinBlue", label: "SendinBlue" },
    { value: "ServiceNow", label: "ServiceNow" },
    { value: "ServiceNow Change Management", label: "ServiceNow Change Management" },
    { value: "SessionCam", label: "SessionCam" },
    { value: "Shareaholic", label: "Shareaholic" },
    { value: "ShareThis", label: "ShareThis" },
    { value: "Shopify", label: "Shopify" },
    { value: "Shopify Account", label: "Shopify Account" },
    { value: "Shopify Conversions", label: "Shopify Conversions" },
    { value: "Shopify Custom Theme", label: "Shopify Custom Theme" },
    { value: "Shopify Dawn Theme", label: "Shopify Dawn Theme" },
    { value: "Shopify Gift Cards", label: "Shopify Gift Cards" },
    { value: "Shopify Inbox", label: "Shopify Inbox" },
    { value: "Shopify Plus", label: "Shopify Plus" },
    { value: "Shopify US Dollar", label: "Shopify US Dollar" },
    { value: "Shopware", label: "Shopware" },
    { value: "Simple Calendar", label: "Simple Calendar" },
    { value: "Simulink", label: "Simulink" },
    { value: "Sitecore", label: "Sitecore" },
    { value: "Sitecore CMS", label: "Sitecore CMS" },
    { value: "Siteimprove", label: "Siteimprove" },
    { value: "SiteSpect", label: "SiteSpect" },
    { value: "Sizmek", label: "Sizmek" },
    { value: "Skype", label: "Skype" },
    { value: "SmartHint", label: "SmartHint" },
    { value: "Smartsheet", label: "Smartsheet" },
    { value: "SMTP.com", label: "SMTP.com" },
    { value: "Snowplow", label: "Snowplow" },
    { value: "SocketLabs", label: "SocketLabs" },
    { value: "Solaris", label: "Solaris" },
    { value: "SolarWinds (Unspecified Product)", label: "SolarWinds (Unspecified Product)" },
    { value: "SonarQube", label: "SonarQube" },
    { value: "Sonatype Nexus Platform", label: "Sonatype Nexus Platform" },
    { value: "Splunk", label: "Splunk" },
    { value: "Sprinklr (Unspecified Product)", label: "Sprinklr (Unspecified Product)" },
    { value: "SQLite", label: "SQLite" },
    { value: "Sqreen", label: "Sqreen" },
    { value: "Squarespace", label: "Squarespace" },
    { value: "Squarespace Online Appointment Scheduling", label: "Squarespace Online Appointment Scheduling" },
    { value: "Squirrelcart", label: "Squirrelcart" },
    { value: "StackPath", label: "StackPath" },
    { value: "Strikingly", label: "Strikingly" },
    { value: "Stripe", label: "Stripe" },
    { value: "Stripe Checkout", label: "Stripe Checkout" },
    { value: "SunGard (Unspecified Product)", label: "SunGard (Unspecified Product)" },
    { value: "SurveyMonkey", label: "SurveyMonkey" },
    { value: "SUSE (Unspecified Product)", label: "SUSE (Unspecified Product)" },
    { value: "Symantec (Unspecified Product)", label: "Symantec (Unspecified Product)" },
    { value: "Tableau", label: "Tableau" },
    { value: "Tableau Desktop", label: "Tableau Desktop" },
    { value: "Tableau Server", label: "Tableau Server" },
    { value: "Talend", label: "Talend" },
    { value: "Taleo (Unspecified Product)", label: "Taleo (Unspecified Product)" },
    { value: "tawk.to", label: "tawk.to" },
    { value: "Tealium", label: "Tealium" },
    { value: "TeamCity", label: "TeamCity" },
    { value: "TechSmith Camtasia", label: "TechSmith Camtasia" },
    { value: "Telerik", label: "Telerik" },
    { value: "Tenable Nessus", label: "Tenable Nessus" },
    { value: "Tenable Network Security", label: "Tenable Network Security" },
    { value: "Tencent Cloud", label: "Tencent Cloud" },
    { value: "TensorFlow", label: "TensorFlow" },
    { value: "Teradata", label: "Teradata" },
    { value: "TestNG", label: "TestNG" },
    { value: "The Events Calendar", label: "The Events Calendar" },
    { value: "Thinkific", label: "Thinkific" },
    { value: "ThomasNet Web Traxs", label: "ThomasNet Web Traxs" },
    { value: "Thomson Reuters (Unspecified Product)", label: "Thomson Reuters (Unspecified Product)" },
    { value: "Thomson Reuters ONESOURCE", label: "Thomson Reuters ONESOURCE" },
    { value: "Tibco (Unspecified Product)", label: "Tibco (Unspecified Product)" },
    { value: "Tidio", label: "Tidio" },
    { value: "TikTok Conversion Tracking Pixel", label: "TikTok Conversion Tracking Pixel" },
    { value: "Tilda", label: "Tilda" },
    { value: "Transact-SQL", label: "Transact-SQL" },
    { value: "Trello", label: "Trello" },
    { value: "Trustpilot", label: "Trustpilot" },
    { value: "TrustRatings", label: "TrustRatings" },
    { value: "Twik", label: "Twik" },
    { value: "Twitter", label: "Twitter" },
    { value: "Twitter Ads", label: "Twitter Ads" },
    { value: "Twitter Bootstrap", label: "Twitter Bootstrap" },
    { value: "Twitter Cards", label: "Twitter Cards" },
    { value: "Twitter Tweet Button", label: "Twitter Tweet Button" },
    { value: "TYPO3", label: "TYPO3" },
    { value: "Ubercart", label: "Ubercart" },
    { value: "Uducat", label: "Uducat" },
    { value: "UnionPay", label: "UnionPay" },
    { value: "Unity", label: "Unity" },
    { value: "Unix", label: "Unix" },
    { value: "Varnish", label: "Varnish" },
    { value: "Veeam", label: "Veeam" },
    { value: "Vendio", label: "Vendio" },
    { value: "Venmo", label: "Venmo" },
    { value: "Verilog", label: "Verilog" },
    { value: "Verint", label: "Verint" },
    { value: "Verisign (Unspecified Product)", label: "Verisign (Unspecified Product)" },
    { value: "Veritas", label: "Veritas" },
    { value: "Veritas NetBackup", label: "Veritas NetBackup" },
    { value: "Vertex", label: "Vertex" },
    { value: "VHSIC Hardware Description Language (VHDL)", label: "VHSIC Hardware Description Language (VHDL)" },
    { value: "VideoJS", label: "VideoJS" },
    { value: "Vimeo", label: "Vimeo" },
    { value: "VirtueMart", label: "VirtueMart" },
    { value: "Visa", label: "Visa" },
    { value: "Visual Basic.NET", label: "Visual Basic.NET" },
    { value: "Visual Website Optimizer", label: "Visual Website Optimizer" },
    { value: "VMware (Unspecified Product)", label: "VMware (Unspecified Product)" },
    { value: "VMware AirWatch", label: "VMware AirWatch" },
    { value: "VMware ESXi", label: "VMware ESXi" },
    { value: "VMware vCenter", label: "VMware vCenter" },
    { value: "VMware Virtual Infrastructure", label: "VMware Virtual Infrastructure" },
    { value: "VMware vRealize", label: "VMware vRealize" },
    { value: "VMware vSphere", label: "VMware vSphere" },
    { value: "Web Shop Manager", label: "Web Shop Manager" },
    { value: "Web.com", label: "Web.com" },
    { value: "WebsiteBuilder", label: "WebsiteBuilder" },
    { value: "Webtrends", label: "Webtrends" },
    { value: "Weebly", label: "Weebly" },
    { value: "Weebly eCommerce", label: "Weebly eCommerce" },
    { value: "WildFly", label: "WildFly" },
    { value: "Windows 10", label: "Windows 10" },
    { value: "Windows 8", label: "Windows 8" },
    { value: "Windows Live Writer", label: "Windows Live Writer" },
    { value: "Windows Server", label: "Windows Server" },
    { value: "Windows Server 2003", label: "Windows Server 2003" },
    { value: "Windows Server 2008", label: "Windows Server 2008" },
    { value: "Windows Server 2012", label: "Windows Server 2012" },
    { value: "Windows Server 2016", label: "Windows Server 2016" },
    { value: "Windows XP", label: "Windows XP" },
    { value: "Wireshark", label: "Wireshark" },
    { value: "Wistia", label: "Wistia" },
    { value: "Wix", label: "Wix" },
    { value: "Wix Bookings", label: "Wix Bookings" },
    { value: "Wix Stores", label: "Wix Stores" },
    { value: "Wonderware (Unspecified Product)", label: "Wonderware (Unspecified Product)" },
    { value: "WooCommerce", label: "WooCommerce" },
    { value: "WooCommerce Add To Cart", label: "WooCommerce Add To Cart" },
    { value: "WooCommerce Checkout", label: "WooCommerce Checkout" },
    { value: "WordPress", label: "WordPress" },
    { value: "Workday", label: "Workday" },
    { value: "Workfront (Unspecified Product)", label: "Workfront (Unspecified Product)" },
    { value: "Workiva", label: "Workiva" },
    { value: "WPForms", label: "WPForms" },
    { value: "X-Cart", label: "X-Cart" },
    { value: "Xamarin", label: "Xamarin" },
    { value: "Xerox (Unspecified Product)", label: "Xerox (Unspecified Product)" },
    { value: "XPath", label: "XPath" },
    { value: "Yahoo Store", label: "Yahoo Store" },
    { value: "Yandex Metrika", label: "Yandex Metrika" },
    { value: "Yoast", label: "Yoast" },
    { value: "Yoast SEO", label: "Yoast SEO" },
    { value: "YouCan", label: "YouCan" },
    { value: "YouTube", label: "YouTube" },
    { value: "Zabbix", label: "Zabbix" },
    { value: "Zen Cart", label: "Zen Cart" },
    { value: "Zendesk", label: "Zendesk" },
    { value: "Zendesk Embeddables", label: "Zendesk Embeddables" },
    { value: "Zoho CRM", label: "Zoho CRM" },
    { value: "Zoom Video Conferencing", label: "Zoom Video Conferencing" },
    { value: "Zopim", label: "Zopim" },
];

const optionsAdvancedTechnology = [
    // { value: "eCommerce", label: "eCommerce" },
    // { value: "Generative AI", label: "Generative AI" },
    // { value: "AI", label: "AI" },
    // { value: "PLG", label: "PLG" },
    { value: "ai_first", label: "AI First" },
    { value: "certificate", label: "Certificates" },
    { value: "community", label: "Community" },
    { value: "demo", label: "Demo" },
    { value: "ecommerce", label: "ECommerce" },
    { value: "event", label: "Event" },
    { value: "free_plan", label: "Free Plan" },
    { value: "free_trial", label: "Free Trial" },
    { value: "generative_ai", label: "Generative AI" },
    { value: "job_opening_admin", label: "Job Opening Admin" },
    { value: "job_opening_analytics", label: "Job Opening Analytics" },
    { value: "job_opening_engg", label: "Job Opening Engg" },
    { value: "job_opening_it", label: "Job Opening It" },
    { value: "job_opening_marketing", label: "Job Opening Marketing" },
    { value: "job_opening_sales", label: "Job Opening Sales" },
    { value: "job_page", label: "Job Page" },
    { value: "newsletter", label: "Newsletter" },
    { value: "offer", label: "Offer" },
    { value: "partner", label: "Partner" },
    { value: "plg", label: "Plg" },
    { value: "pricing", label: "Pricing" },
    { value: "product_tour", label: "Product Tour" },
    { value: "video_content", label: "Video Content" },
    { value: "webinar", label: "Webinar" },
];

const chooseList = [
    { value: 'List 1', label: 'List 1' },
    { value: 'List 2', label: 'List 2' },
    { value: 'List 3', label: 'List 3' },
];

const mapCompanyName = [
    { value: 'whoozbuying', label: 'whoozbuying' },
    { value: 'iCustomer', label: 'iCustomer' },
];

const mapURL = [
    { value: 'whoozbuying.com', label: 'whoozbuying.com' },
    { value: 'iCustomer.ai', label: 'iCustomer.ai' },
];

const syncMode = [
    { value: 'Overwrite', label: 'Overwrite' },
    { value: 'Erase', label: 'Erase' },
    { value: 'Lose', label: 'Lose' },
];

const syncFrequency = [
    { value: 'Manual', label: 'Manual' },
    { value: 'Autoloading', label: 'Autoloading' },
    { value: 'Semiautomatic', label: 'Semiautomatic' },
];

const mapValue = [
    { value: '', label: 'wb_account_id' },
    { value: '', label: 'account_sfdc_id' },
    { value: '', label: 'account_sfdc_name' },
    { value: '', label: 'account_name' },
    { value: '', label: 'account_url' },
    { value: '', label: 'account_address_street' },
    { value: '', label: 'account_address_city' },
    { value: '', label: 'account_address_state' },
    { value: '', label: 'account_address_zip_code' },
    { value: '', label: 'account_sfdc_country_name' },
    { value: '', label: 'account_country_name' },
    { value: '', label: 'account_continent_name' },
    { value: '', label: 'account_country_code' },
    { value: '', label: 'account_continent_code' },
    { value: '', label: 'account_industry' },
    { value: '', label: 'account_subindustry' },
    { value: '', label: 'account_gics_code' },
    { value: '', label: 'account_employees' },
    { value: '', label: 'account_size' },
    { value: '', label: 'account_linkedin_id' },
    { value: '', label: 'account_phone' },
    { value: '', label: 'account_revenue' },
    { value: '', label: 'wb_account_fit_score' },
    { value: '', label: 'wb_contact_fit_score' },
    { value: '', label: 'account_feedback' },
    { value: '', label: 'account_feedback_reason' },
    { value: '', label: 'contact_feedback' },
    { value: '', label: 'DUNS_NUMBER' },
    { value: '', label: 'PARENT_DUNS' },
    { value: '', label: 'SIC_CODE' },
    { value: '', label: 'NAICS_CODE' },
    { value: '', label: 'ULTIMAPARENT' },
    { value: '', label: 'PARENT' },
    { value: '', label: 'Industry' },
    { value: '', label: 'Industry_Keywords' },
    { value: '', label: 'Generic_email' },
    { value: '', label: 'IS_E_Commerce' },
    { value: '', label: 'Is_Community_event_press release' },
    { value: '', label: 'Is_Certification_Training' },
    { value: '', label: 'Linkedin_followers' },
    { value: '', label: 'instagram' },
    { value: '', label: 'instagram_followers' },
    { value: '', label: 'facebook' },
    { value: '', label: 'Facebook_followers' },
    { value: '', label: 'twitter' },
    { value: '', label: 'Is_Demo_Trial_Offer' },
    { value: '', label: 'is_pricing' },
    { value: '', label: 'abm_code' },
    { value: '', label: 'global_du_it' },
    { value: '', label: 'regional_du_it' },
    { value: '', label: 'country_du_itmarketing' },
    { value: '', label: 'global_du_marketing' },
    { value: '', label: 'regional_du_marketing' },
    { value: '', label: 'country_du_marketing' },
    { value: '', label: 'global_du_sales' },
    { value: '', label: 'regional_du_sales' },
    { value: '', label: 'country_du_sales' },
    { value: '', label: 'global_du_engg' },
    { value: '', label: 'regional_du_engg' },
    { value: '', label: 'country_du_engg' },
    { value: '', label: 'global_du_finance' },
    { value: '', label: 'regional_du_finance' },
    { value: '', label: 'country_du_finance' },
    { value: '', label: 'global_du_admin' },
    { value: '', label: 'regional_du_admin' },
    { value: '', label: 'country_du_admin' },
    { value: '', label: 'global_du_analytics' },
    { value: '', label: 'regional_du_analytics' },
    { value: '', label: 'country_du_analytics' },
    { value: '', label: 'company_types' },
    { value: '', label: 'intent' },
    { value: '', label: 'FIT_Score_of_Company' },
    { value: '', label: 'FIT_Score_of_Contact' },
    { value: '', label: 'created_at' },
    { value: '', label: 'updated_at' },
  ];

  const colValues = [
    { value: 'ic_accountid', label: 'Account ID' },
    { value: 'account_name', label: 'Account Name' },
    { value: 'account_url', label: 'Account URL' },
    { value: 'account_addressstreet', label: 'Street Address' },
    { value: 'account_addresscity', label: 'City' },
    { value: 'account_addressstate', label: 'State' },
    { value: 'account_addresszip', label: 'ZIP Code' },
    { value: 'account_country', label: 'Country' },
    { value: 'account_continent', label: 'Continent' },
    { value: 'account_countrycode', label: 'Country Code' },
    { value: 'account_continentcode', label: 'Continent Code' },
    { value: 'account_industry', label: 'Industry' },
    { value: 'account_subindustry', label: 'Subindustry' },
    { value: 'account_gicscode', label: 'GICS Code' },
    { value: 'account_size', label: 'Company Size' },
    { value: 'account_phone', label: 'Phone Number' },
    { value: 'duns_number', label: 'DUNS Number' },
    { value: 'parent_duns', label: 'Parent DUNS Number' },
    { value: 'sic_code', label: 'SIC Code' },
    { value: 'naics_code', label: 'NAICS Code' },
    { value: 'abm_code', label: 'ABM Code' },
    { value: 'account_revenue', label: 'Revenue' },
    { value: 'account_employees', label: 'Number of Employees' },
    { value: 'company_type', label: 'Company Type' },
    { value: 'technographic', label: 'Technographic' },
    { value: 'web_traffic', label: 'Web Traffic' },
    { value: 'parent_accountid', label: 'Parent Account ID' },
    { value: 'ultimate_parent', label: 'Ultimate Parent' },
    { value: 'location_id', label: 'Location ID' },
    { value: 'location_tag', label: 'Location Tag' },
    { value: 'account_linkedin', label: 'LinkedIn Profile' },
    { value: 'account_linkedinfollowers', label: 'LinkedIn Followers' },
    { value: 'account_twitter', label: 'Twitter Profile' },
    { value: 'account_twitterfollowers', label: 'Twitter Followers' },
    { value: 'account_fb', label: 'Facebook Profile' },
    { value: 'account_fbfollowers', label: 'Facebook Followers' },
    { value: 'account_insta', label: 'Instagram Profile' },
    { value: 'account_instafollowers', label: 'Instagram Followers' },
    { value: 'global_duit', label: 'Global DUIT' },
    { value: 'regional_duit', label: 'Regional DUIT' },
    { value: 'global_dumarketing', label: 'Global DU Marketing' },
    { value: 'regional_dumarketing', label: 'Regional DU Marketing' },
    { value: 'country_dumarketing', label: 'Country DU Marketing' },
    { value: 'global_dusales', label: 'Global DU Sales' },
    { value: 'regional_dusales', label: 'Regional DU Sales' },
    { value: 'country_dusales', label: 'Country DU Sales' },
    { value: 'global_duengg', label: 'Global DU Engineering' },
    { value: 'regional_duengg', label: 'Regional DU Engineering' },
    { value: 'country_duengg', label: 'Country DU Engineering' },
    { value: 'global_dufinance', label: 'Global DU Finance' },
    { value: 'regional_dufinance', label: 'Regional DU Finance' },
    { value: 'country_dufinance', label: 'Country DU Finance' },
    { value: 'global_duadmin', label: 'Global DU Admin' },
    { value: 'regional_duadmin', label: 'Regional DU Admin' },
    { value: 'country_duadmin', label: 'Country DU Admin' },
    { value: 'global_duanalytics', label: 'Global DU Analytics' },
    { value: 'regional_duanalytics', label: 'Regional DU Analytics' },
    { value: 'country_duanalytics', label: 'Country DU Analytics' },
    { value: 'account_desc', label: 'Account Description' }
];  

const fitScore = [];

for (let i = 1; i <= 100; i++) {
    fitScore.push({ value: i, label: i });
}  

const jobLevel = [
    { value: 'C-level / CXO', label: 'C-level / CXO' },
    { value: 'Vice President', label: 'Vice President' },
    { value: 'Director', label: 'Director' },
    { value: 'Manager', label: 'Manager' },
    { value: 'Individual Contributor / Staff', label: 'Individual Contributor / Staff' },
    { value: 'Other', label: 'Other' },
]

const planOptions = [
    // { value: 'free', label: 'Free' },
    { value: 'basic', label: 'Basic' },
    { value: 'enterprise', label: 'Enterprise' },
    // { value: 'standard', label: 'Standard' },
    // { value: 'premium', label: 'Premium' },
]

const ptypeOptions = [
    { value: 'User', label: 'User' },
    { value: 'Buyer', label: 'Buyer' },
]

const enrichOptions = [
    { value: 'enrichlist', label: 'Enrich ICP List' },
    { value: 'newnet', label: 'Expand your ICP with net new' },
];

const leadFields = [
    'Lead ID',
    'First Name',
    'Last Name',
    'Salutation',
    'Title',
    'Company',
    'Email',
    'Phone',
    'Mobile Phone',
    'Website',
    'Lead Source',
    'Status',
    'Industry',
    'Annual Revenue',
    'Number of Employees',
    'Rating',
    'Street Address',
    'City',
    'State/Province',
    'ZIP/Postal Code',
    'Country',
    'Description',
    'Lead Owner',
    'Lead Created Date',
    'Lead Converted Date',
    'Converted Account',
    'Converted Contact',
    'Converted Opportunity',
    'Do Not Call',
    'Do Not Email',
    'Tags',
];

const leadOptions = leadFields.sort().map((lead) => ({ value: lead, label: lead }));

const acFields = [
    'Account ID',
    'Account Name',
    'Account Owner',
    'Account Number',
    'Industry',
    'Annual Revenue',
    'Billing Address',
    'Shipping Address',
    'Phone',
    'Fax',
    'Website',
    'Description',
    'Type',
    'Parent Account',
    'Rating',
    'Number of Employees',
    'Ticker Symbol',
    'Ownership',
    'SIC Code',
    'Billing Contact',
    'Shipping Contact',
    'Last Activity Date',
    'Last Modified Date',
    'Created Date',
    'Tags',
    'Contact ID',
    'First Name',
    'Last Name',
    'Salutation',
    'Title',
    'Email',
    'Phone',
    'Mobile Phone',
    'Home Phone',
    'Other Phone',
    'Fax',
    'Mailing Address',
    'Other Address',
    'Department',
    'Account Name',
    'Lead Source',
    'Birthdate',
    'Assistant Name',
    'Assistant Phone',
    'Reports To',
    'Email Opt-Out',
    'Do Not Call',
    'Description',
    'Last Activity Date',
    'Last Modified Date',
    'Created Date',
    'Tags',
]

const uniqueAcFields = [...new Set(acFields.sort())];

const uniqueAcOptions = new Set(Array.from(uniqueAcFields).map((acField) => (
    { value: acField, label: acField }
)))

const filterDropdown = ["Industry", "Employee Range", "Geo", "Revenue Range", "Technographics", "Signals"]

const threads = [
    {
        prompt: 'What are key attributes of different audience segments for true wireless earphones in North America?',
        btnText: [
            'Audience'
        ] 
    },
    {
        prompt: 'Give me the total revenue generated by each SKU over last year?',
        btnText: [
            'Reports & Analytics'
        ] 
    },
    {
        prompt: 'Analyze deal cycle according to each SKU',
        btnText: [
            'Reports & Analytics'
        ] 
    },
    {
        prompt: 'Create a segment of existing customers and anonymize PII information',
        btnText: [
            'DataOps'
        ] 
    },
    {
        prompt: 'Give me list of keywords for ad campaign targeted towards budget conscious consumers looking for TWS',
        btnText: [
            'Audience',
            'Marketing'
        ] 
    },
    {
        prompt: 'Based on industry trends, which channel is most effective for my next campaign for TWS, targeted towards fitness enthusiasts in North America?',
        btnText: [
            'Marketing',
            'Market Intelligence'
        ] 
    },
]

const collection = [
    {
        txt: 'Audience',
        num: '20'
    },
    {
        txt: 'Reports & Analytics',
        num: '10'
    },
    {
        txt: 'Segment & List',
        num: '3'
    },
]


const constants = {
    optionsCountry : optionsCountry,
    optionsIndustry: optionsIndustry,
    optionsRevenueRange: optionsRevenueRange,
    optionsEmployeeRange: optionsEmployeeRange,
    empMap: empMap,
    keywords: keywords,
    optionsCompany: optionsCompany,
    optionsTechnology: optionsTechnology,
    optionsAdvancedTechnology: optionsAdvancedTechnology,
    chooseList: chooseList,
    mapCompanyName: mapCompanyName,
    mapURL: mapURL,
    syncMode: syncMode,
    syncFrequency: syncFrequency,
    mapValue:mapValue,
    fitScore: fitScore,
    jobLevel: jobLevel,
    searchFilterIndustry: searchFilterIndustry,
    colValues: colValues,
    planOptions: planOptions,
    ptypeOptions: ptypeOptions,
    enrichOptions: enrichOptions,
    leadFields: leadOptions,
    acFields: uniqueAcOptions,
    filterDropdown: filterDropdown,
    threads: threads,
    collection: collection,
}
export default constants;
