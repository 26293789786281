import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Input, message, Table, Button } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";
import { initialCalledAtom, copilotChatArrayAtom, toastChatArrayAtom, redirectedHistoryAtom, activeSessionAtom, assistantFocusAtom, fileListAtom, reportListAtom } from "../../store";
import { useNavigate, useLocation } from "react-router-dom";
import { chatHistory, starChats } from "../../services/chatServices";

const ChatHistory = () => {
    const [initialCalled, setInitialCalled] = useRecoilState(initialCalledAtom);
    const [redirectedHistory, setRedirectedHistory] = useRecoilState(redirectedHistoryAtom);
    const setChatArray = useSetRecoilState(copilotChatArrayAtom);
    const setToastChatArray = useSetRecoilState(toastChatArrayAtom);
    const { pathname } = useLocation();
    const [chatList, setChatList] = useState([]);
    const [starredCollapsed, setStarredCollapsed] = useState(false);
    const [allChatsCollapsed, setAllChatsCollapsed] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom)
    const [assistantFocus, setAssistantFocus] = useRecoilState(assistantFocusAtom)
    const [fileList, setFileList] = useRecoilState(fileListAtom);
    const [reportList, setReportList] = useRecoilState(reportListAtom);

    const navigate = useNavigate();

    const getChatHistory = async () => {
        let response = await chatHistory({
            user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
            session_id: null
        });

        if (response.data.status === "success") {
            const sortedChatList = response.data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));
            setChatList(sortedChatList);
        }
    };

    useEffect(() => {
        getChatHistory();
    }, []);

    const handleStarChat = async (session_id, star_value) => {
        let response = await starChats({
            star_value: star_value,
            session_id: session_id
        });
        if (response.data.status === "success") {
            setChatList(prevChatList =>
                prevChatList.map(chat =>
                    chat.session_id === session_id ? { ...chat, is_starred: star_value } : chat
                )
            );
        }
    }

    const handleButtonClick = async (sessionId) => {
        navigate(`/assistant?session_id=${sessionId}`)
        // localStorage.removeItem("tableName")
        // localStorage.setItem("session_id", sessionId);
        // setActiveSession(sessionId)
        // setAssistantFocus("master")
        // setFileList([])
        // setReportList([])
        // message.loading("Getting the chat for you...");

        // let response = await chatHistory({
        //     user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
        //     session_id: sessionId
        // });

        // if (response.data.status === "success") {
        //     // Transform the data to rename the 'cleanedData' key to 'answer'
        //     const modifiedChatArray = response.data.data[0].chat_array.map(chat => {
        //         let modifiedChat = { ...chat };
        //         modifiedChat.answer = chat.cleanedData;
        //         modifiedChat.suggestions = chat.followUpQuestions;
        //         modifiedChat.visuals = true
        //         modifiedChat.data = chat.pythonCode ? [chat.pythonCode] : null;
        //         modifiedChat.chartMessage = chat.graphAnswer ? chat.graphAnswer : null;
        //         modifiedChat.chartType = chat.chart_type ? chat.chart_type : "plotly";
        //         modifiedChat.query_id = chat.query_id;
        //         modifiedChat.postgres_query = chat.postgres_query ? chat.postgres_query : `SELECT * FROM insighttables.new_table_${chat.query_id.replace(/-/g, '_')}`;
        //         modifiedChat.topic = chat.topic;
        //         modifiedChat.newTable = chat.newTable ? chat.newTable : null
        //         modifiedChat.onlySummary = chat.onlySummary ? chat.onlySummary : false
        //         modifiedChat.feedback = chat.feedback;
        //         modifiedChat.is_sql_error = chat.is_sql_error ? chat.is_sql_error : false
        //         delete modifiedChat.cleanedData;
        //         return modifiedChat;
        //     });

        //     setRedirectedHistory(true)
        //     setChatArray(modifiedChatArray);
        //     navigate("/assistant");
        // }
    };

    const columns = [
        {
            title: ' ',
            dataIndex: 'chat_name',
            key: 'chat_name',
            width: '2%',
            render: (text, record) => (
                <span>
                    {record.is_starred ? (
                        <StarFilled
                            style={{ color: 'gold', cursor: 'pointer' }}
                            onClick={() => handleStarChat(record.session_id, false)}
                        />
                    ) : (
                        <StarOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleStarChat(record.session_id, true)}
                        />
                    )}
                </span>
            ),
        },
        {
            title: 'Chat name',
            dataIndex: 'chat_name',
            key: 'chat_name',
            width: '48%',
            render: (text, record) => (
                <span className="cursor-pointer font-[300]" onClick={() => handleButtonClick(record.session_id)}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Files',
            dataIndex: 'files',
            key: 'files',
            width: '10%',
            render: (text, record) => <span>{record.files ? record.files.length : 0}</span>,
        },
        {
            title: 'Reports',
            dataIndex: 'reports',
            key: 'reports',
            width: '10%',
            render: (text, record) => <span>{record.reports ? record.reports.length : 0}</span>,
        },
        {
            title: 'Dashboard',
            dataIndex: 'dashboard',
            key: 'dashboard',
            width: '10%',
            render: (text, record) => (record.dashboard ? <span>✔</span> : <span>—</span>),
        },
    ];

    const filteredChatList = chatList?.filter(chat =>
        chat?.chat_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const starredChats = filteredChatList.filter(chat => chat.is_starred).map(chat => ({ ...chat, key: chat.session_id }));
    const allChats = filteredChatList.filter(chat => !chat.is_starred).map(chat => ({ ...chat, key: chat.session_id }));

    return (
        <div style={{ borderRight: '1px solid #d1d5db', overflow: 'auto', padding: '16px' }}>
            <Input
                placeholder="Search chats"
                style={{ marginBottom: '16px' }}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <div style={{ marginBottom: '16px' }}>
                <h3 className="text-[16px] font-[600]">
                    Starred chats ({starredChats.length})
                    <Button type="text" onClick={() => setStarredCollapsed(!starredCollapsed)}>
                        {starredCollapsed ? "▼" : "▲"}
                    </Button>
                </h3>
                {!starredCollapsed && (
                    <Table
                        dataSource={starredChats}
                        columns={columns}
                        pagination={false}
                        rowKey="session_id"
                        style={{ backgroundColor: '#fff' }}
                        scroll={{ y: 300, x: 'max-content' }} // Set the scroll height
                    />
                )}
            </div>
            <div style={{ marginBottom: '16px' }}>
                <h3 className="text-[16px] font-[600]">
                    All chats ({allChats.length})
                    <Button type="text" onClick={() => setAllChatsCollapsed(!allChatsCollapsed)}>
                        {allChatsCollapsed ? "▼" : "▲"}
                    </Button>
                </h3>
                {!allChatsCollapsed && (
                    <Table
                        dataSource={allChats}
                        columns={columns}
                        pagination={false}
                        rowKey="session_id"
                        style={{ backgroundColor: '#fff' }}
                        scroll={{ y: 300, x: 'max-content' }} // Set the scroll height
                    />
                )}
            </div>
        </div>
    );
};

export default ChatHistory;
