import { useCallback, useState } from "react";
import { Handle, Position } from "@xyflow/react";
import EnrichmentHead from "./EnrichmentHead";
import { Select } from "antd";
import Formulas from "./Formulas";

const handleStyle = { left: 10 };

function EnrichmentSummarizerNode({ data, isConnectable }) {
  const [collapse, setCollapse] = useState(false);
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
    console.log(data);
  }, []);
  const options = [
    { label: "Claude-3-Haiku", value: "Claude-3-Haiku" },
    { label: "GPT-4o", value: "GPT-4o" },
    { label: "GPT-3.5-Turbo", value: "GPT-3.5-Turbo" },
  ];
  const slackOptions = [
    { label: "Marketing Leads", value: "Marketing Leads" },
    { label: "Sales Leads", value: "Sales Leads" },
    { label: "general", value: "general" },
  ];

  return (
    <div className={`rounded-[5px] bg-white ${data?.id === "node-17" ? 'min-w-[27.5rem] border-x border-b border-black' : data?.id === "node-18" ? 'min-w-[24rem] border-x border-b border-black' : 'min-w-[20rem] border border-[#eee]'}`}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className="flex flex-col justify-start items-start">
        <EnrichmentHead data={data} collapse={collapse} setCollapse={setCollapse} />
        {!["node-17", "node-18", "node-14"].includes(data?.id) &&
          <div className="w-full text-[12px] mt-2 flex flex-col gap-1 p-2">
              <p>{data?.id !== "node-17" ? "Model Preference" : "Slack Channel"}</p>
              <Select options={data?.id === "node-17" ? slackOptions : options} className="w-full" defaultValue={data?.id === "node-17" ? "Marketing Leads" : "Claude-3-Haiku"} />
          </div>
        }
        {["node-17", "node-18"].includes(data?.id) && !collapse && (
          <Formulas id={data?.id} />
        )}
      </div>
      {data?.id === "node-1" && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          style={handleStyle}
          isConnectable={isConnectable}
        />
      )}
      {!["node-14"].includes(data?.id) && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
}

export default EnrichmentSummarizerNode;
