import React from "react";
import { Checkbox } from "antd";
import { useRecoilValue, useRecoilState } from "recoil";
import { enrichChecked, enrichEmailAtom } from "../../store";

const EnrichmentMain = ({ data }) => {
  const checked = useRecoilValue(enrichChecked);
  const [email, setEmail] = useRecoilState(enrichEmailAtom);
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const handleChange = (e) => {
    // console.log(e.target.value)
    setEmail(e.target.value)
  }
  return (
    <div className="flex flex-col w-full gap-4 text-[12px] my-4 p-2">
      {data?.id !== "node-3" &&
        <div className="w-full">
          <p>{data?.data?.firstKey}</p>
          <input
            type="text"
            defaultValue={email}
            placeholder="Enter Email Address Here"
            className="w-full"
            onChange={handleChange}
          />
          <hr />
        </div>
      }
      <div>
        {data?.data?.label === "Agent Made Decision" ? (
          <div className="flex flex-col gap-4">
            <Checkbox checked={true} onChange={onChange}>Download as CSV</Checkbox>
            <Checkbox checked={false} onChange={onChange}>Send To Salesforce</Checkbox>
            <Checkbox checked={false} onChange={onChange}>Add To Google Sheet</Checkbox>
            {/* <p>Output Type</p> */}
            {/* <select className="border border-[#eee]">
              <option value={"string"}>string</option>
              <option value={"string[]"}>string[]</option>
              <option value={"any"}>any</option>
            </select> */}
          </div>
        ) : (
          <>
            {/* <p>Input Name</p>
            <p>user_email</p>
            <hr /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default EnrichmentMain;
