import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { selectedGalleryAtom } from '../../store'

const topNavs = [
    {
        main: "Workflow Templates",
        sub: " "
        // sub: "Lorem Ipsum is simply dummy text"
    },
    // {
    //     main: "Agent Templates",
    //     sub: "Lorem Ipsum is simply dummy text"
    // },
    {
        main: "My Workflows",
        sub: "Your Multi Agent Workflows"
    }
]

const AgentNavBar = () => {
    const [selectedGallery, setSelectedGallery] = useRecoilState(selectedGalleryAtom)

    return (
        <div className='flex gap-4 border-b relative'>
            {topNavs.map((nav, index) => (
                <p
                    key={index}
                    className={`${selectedGallery === index ? 'text-primary border-b border-primary' : 'text-character'} pb-2 h5_Regular cursor-pointer duration-300 ease-in-out`}
                    onClick={() => setSelectedGallery(index)}
                >
                    {nav.main}
                </p>
            ))}
            <div className='absolute top-11 left-16 h5_Regular text-primary'>
                {topNavs[selectedGallery].sub}
            </div>
        </div>
    )
}

export default AgentNavBar