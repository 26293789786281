import React, { useEffect, useState } from 'react';
import { Card, Table, Button, Tabs, DatePicker, Avatar, Badge, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { FetchModels } from '../../../services/Settings';

const syncData = [
    {
        key: '1',
        status: 'Failed',
        model: 'Account Fit Score',
        destination: 'Salesforce',
        syncMode: 'Update',
        createdBy: 'David Slavich',
        lastRun: 'May 1, 2024 at 8:39 AM PDT',
    },
];

const SyncStatus = () => (
    <Table
        dataSource={syncData}
        columns={[
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => (
                    <Tag color={text === 'Failed' ? 'red' : 'green'}>
                        {text}
                    </Tag>
                ),
            },
            {
                title: 'Model',
                dataIndex: 'model',
                key: 'model',
                render: (text) => (
                    <>
                        {/* <Badge status="processing" /> */}
                        {text}
                    </>
                ),
            },
            {
                title: 'Destination',
                dataIndex: 'destination',
                key: 'destination',
                render: (text) => (
                    <>
                        {/* <Badge status="error" /> */}
                        {text}
                    </>
                ),
            },
            {
                title: 'Sync mode',
                dataIndex: 'syncMode',
                key: 'syncMode',
                render: (text) => (
                    <Tag color="blue">{text}</Tag>
                ),
            },
            {
                title: 'Created by',
                dataIndex: 'createdBy',
                key: 'createdBy',
                render: (text) => (
                    <Avatar icon={<UserOutlined />} size="small" /> && text
                ),
            },
            {
                title: 'Last run',
                dataIndex: 'lastRun',
                key: 'lastRun',
            },
        ]}
        pagination={false}
    />
);

const ColumnsData = ({data, model, exampleData}) => {
    return (
    <Table
        dataSource={data.map((item) => ({
            key: item.name,
            label: item.name,
            example: exampleData[item.name],
            type: item.format,
            source: model?.source,
        }))}
        columns={[
            {
                title: 'Label',
                dataIndex: 'label',
                key: 'label',
            },
            {
                title: 'Example',
                dataIndex: 'example',
                key: 'example',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: 'Source',
                dataIndex: 'source',
                key: 'source',
                render: (text) => (
                    <>
                        {/* <Badge status="processing" /> */}
                        {text}
                    </>
                ),
            },
        ]}
        pagination={false}
    />
)};

const AccountFitScore = ({ model, onBack }) => {
    console.log({model})
    const [activeTab, setActiveTab] = useState('query');
    const [modelData, setModelData] = useState([]);
    const [columnsData, setColumnsData] = useState([]);
    const [queryColumns, setQueryColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    // const queryColumns = [
    //     {
    //         title: 'Account ID',
    //         dataIndex: 'account_id',
    //         key: 'account_id',
    //     },
    //     {
    //         title: 'Account Name',
    //         dataIndex: 'account_name',
    //         key: 'account_name',
    //     },
    //     {
    //         title: 'Fit Score',
    //         dataIndex: 'fit_score',
    //         key: 'fit_score',
    //     },
    // ];

    const tabItems = [
        {
            key: 'query',
            label: 'Query',
            children: (
                <Table
                    dataSource={modelData}
                    columns={queryColumns}
                    pagination={false}
                    loading={isLoading}
                />
            ),
        },
        {
            key: 'syncs',
            label: 'Syncs',
            disabled: true,
            children: <SyncStatus />,
        },
        {
            key: 'columns',
            label: 'Columns',
            children: <ColumnsData data={columnsData} model={model} exampleData={modelData[0]}/>,
        },
    ];
    const fetchModelsData = async () => {
        setIsLoading(true);
        try {
            const { data } = await FetchModels({ model_slug: model?.slug });
            console.log(data?.data?.rows);
            setModelData(data?.data?.rows);
            setColumnsData(data?.data?.fields)
            const fieldsData = data?.data?.fields.map(x => {
                return {
                    title: x?.name.replace(/(^\w)/g, g => g[0].toUpperCase()).replace(/([-_]\w)/g, g => " " + g[1].toUpperCase()).trim(),
                    dataIndex: x?.name,
                    key: x?.name
                };
            });
    
            setQueryColumns(fieldsData);
            console.log({ modelData });
        } catch (error) {
            console.error("Error fetching models data:", error);
            // Handle the error appropriately (e.g., show an error message to the user)
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        fetchModelsData();
    }, [activeTab]);
    

    return (
        <Card
            title={model.name}
            extra={
                <Button type="primary" onClick={onBack}>
                    Back
                </Button>
            }
        >
            <p>{model.description}</p>
            <div className="my-2 flex justify-start gap-3 items-center">
                <div className='px-2' style={{ borderRight: "2px solid #EEE" }}>
                    <span>Sources: </span>
                    <Button style={{ borderRadius: "20px" }}>
                        {model?.source || "Salesforce Demo"}
                    </Button>
                </div>
                <div className="flex items-center">
                    <div style={{ borderRight: "2px solid #EEE" }}>
                        <DatePicker
                            defaultValue={dayjs('2024-05-15')}
                            format="MMM DD, YYYY [PDT]"
                            style={{ marginRight: '10px' }}
                        />
                    </div>
                    <div className='px-2 flex items-center'>
                        <Avatar
                            icon={<UserOutlined className='text-[15px]' />}
                            style={{ backgroundColor: '#87d068', marginRight: '5px', width: "25px", height: "25px" }}
                        />
                        <span>{model.createdBy}</span>
                    </div>
                </div>
            </div>
            <Tabs
                activeKey={activeTab}
                onChange={(key) => setActiveTab(key)}
                items={tabItems}
            />
        </Card>
    );
};

export default AccountFitScore;
