import { Button, Checkbox, Input, notification, message, Modal, Spin } from "antd";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { enrichColumns } from "../../services/InsightsServices";
import {
  FieldTimeOutlined
} from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { activeSessionAtom } from "../../store";
import { jwtDecode } from "jwt-decode";
const { Search } = Input;

const EnrichOption = ({ data, selectedRows, query_id, handleCloseModal, fetchData }) => {
  const [activeOption, setActiveOption] = useState(0);
  const [selectedFields, setSelectedFields] = useState(new Set());
  const [hasMissingValues, setHasMissingValues] = useState(false);
  const [activeSession, setActiveSession] = useRecoilState(activeSessionAtom)
  const [loading, setLoading] = useState(false);

  const header = [
    { name: "Enrich Missing" },
    { name: "Add Enrichment" },
  ];

  const addEnrichmentKeys = [
    "account_name",
    "account_url",
    "account_industry",
    "account_subindustry",
    "sic_code",
    "gics_code",
    "naics_code",
    "account_revenue",
    "account_employees",
    "account_size",
    "account_address",
    "account_phone",
    "account_country",
    "account_continent",
    "account_countrycode",
    "account_continentcode",
    "account_linkedin",
  ];

  const addEnrichmentKeySet = new Set(addEnrichmentKeys);

  const enrichMissingOptions = data.length
    ? Object.keys(data[0])
      .filter(key => addEnrichmentKeySet.has(key))
      .map(key => ({ label: key, value: key }))
    : [];

  const addEnrichmentOptions = addEnrichmentKeys.map((key) => ({ label: key, value: key }));

  const handleCheckboxChange = (value) => {
    setSelectedFields(prev => {
      const newSet = new Set(prev);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  };

  const checkForMissingValues = () => {
    if (activeOption === 0) {
      let missing = false;
      selectedRows.forEach(row => {
        selectedFields.forEach(field => {
          if (row[field] === null || row[field] === undefined || row[field] === "") {
            missing = true;
          }
        });
      });
      setHasMissingValues(missing);
    }
  };

  useEffect(() => {
    checkForMissingValues();
  }, [selectedFields, selectedRows, activeOption]);

  const handleEnrich = async () => {
    if (activeOption === 0 && !hasMissingValues) {
      notification.info({
        message: 'No Missing Values',
        description: 'There are no missing values in your selected data.',
      });
      return;
    }

    const account_fields_to_enrich = Array.from(selectedFields);
    const user_email = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.email
    const session_id = activeSession; // Assuming activeSession holds the session_id

    // Add user_id, query_id, and session_id to each record in selectedRows
    const enrichedRecords = selectedRows.map(record => ({
      ...record,
      user_email: user_email,
      query_id: query_id,
      session_id: session_id,
    }));

    if (enrichedRecords.length < 50) {
      setLoading(true);
      handleCloseModal();
      try {
        const response = await enrichColumns({
          query_id: query_id,
          records: enrichedRecords,
          account_fields_to_enrich: account_fields_to_enrich,
          session_id: session_id,
        });

        console.log('Enrichment response:', response.data);
        setLoading(false);
        fetchData();
        message.success('Data enrichment completed successfully!');
      } catch (error) {
        console.error('Error enriching columns:', error);
        setLoading(false);
        fetchData();
        message.error('Failed to enrich data within the timeframe, but will email you once your data is ready!');
      }
    } else {
      notification.info({
        message: 'Enrichment in Progress',
        description: 'We are enriching your data. You will be notified via email once the process is completed.',
      });
      handleCloseModal();
      // Here you could make the request without waiting for the result
      try {
        await enrichColumns({
          query_id: query_id,
          records: enrichedRecords,
          account_fields_to_enrich: account_fields_to_enrich,
        });
      } catch (error) {
        console.error('Error starting data enrichment:', error);
      }
    }
  };


  return (
    <div className='flex flex-col h-[70vh] w-full p-2'>
      <div className="flex text-[16px]" style={{ fontWeight: 600 }}>
        Enrich
      </div>
      <div className="flex justify-between items-center pl-1 pr-4 pt-2">
        {header.map((tab, index) => (
          <div
            key={index}
            className={`${activeOption === index
              ? "text-white bg-[#2966BC]"
              : "bg-gray-300 font-semibold"
              } flex justify-center items-center border ${index === header.length - 1 ? "rounded-r-md" : "border-r-black"
              } ${index === 0 ? "rounded-l-md" : ""
              } px-2 py-1 text-sm cursor-pointer flex-1`}
            onClick={() => setActiveOption(index)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      <div className="p-4 mt-2 flex flex-col h-[90%]">
        <div className="flex justify-center mb-2">
          <Search
            placeholder="Search"
            allowClear
            className="extendSearch"
            style={{ width: 200 }}
          />
        </div>
        <div className="flex mt-2 flex-col flex-grow overflow-y-scroll">
          {(activeOption === 0 ? enrichMissingOptions : addEnrichmentOptions).map(
            (option) => (
              <div className="flex mt-2 justify-start" key={option.value}>
                <Checkbox
                  className="text-[16px]"
                  checked={selectedFields.has(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                >
                  {option.label}
                </Checkbox>
              </div>
            )
          )}
        </div>
        <div className="flex mt-8 justify-center">
          <Button
            onClick={handleEnrich}
            disabled={activeOption === 0 && !hasMissingValues}
          >
            {activeOption === 0
              ? hasMissingValues
                ? "Enrich missing values"
                : "No missing values"
              : "Add enrichment columns"}
          </Button>
        </div>
      </div>
      <Modal visible={loading} footer={null} closable={false}>
        <div className="flex flex-col items-center gap-4 p-4">
          {/* <div className="loader w-12 h-12 border-4 border-t-4 border-blue-500 rounded-full animate-spin"></div> */}
          <Spin size="large" />
          <div className="flex items-center gap-2">
            <p className="text-lg font-semibold">Data Enrichment is in Progress</p>
          </div>
          <p className="text-gray-600 text-center">
            This may take upto 90 seconds. Please do not close this window or navigate away.
          </p>
        </div>
      </Modal>

    </div>
  );
};

export default EnrichOption;
