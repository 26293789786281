import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AgentTopBar from '../../components/AgentTopBar/AgentTopBar'
import AgentTemplate from '../../components/AgentTemplate/AgentTemplate'

import { agentValAtom } from '../../store'
import { useRecoilValue } from 'recoil'

const NewTemplate = () => {
  const navigate = useNavigate()
  const agentVal = useRecoilValue(agentValAtom);

  useEffect(() => {
    if (!agentVal?.name) {
      navigate("/gallery")
    }
  }, [])

  return (
    <div className='px-11'>
        <AgentTopBar />
        <div className='bg-[#F7F8FA] flex flex-col gap-4 justify-center p-4'>
            <p className='bode_Regular text-secondary'>
                Agent / <span className='cursor-pointer' onClick={() => navigate('/agents')}>Templates</span> / <span className='text-character'>{agentVal?.name}</span>
            </p>
            <AgentTemplate />
        </div>
    </div>
  )
}

export default NewTemplate