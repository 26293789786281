import React, { useEffect, useCallback, useState } from "react";

import {
  ReactFlow,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
  ReactFlowProvider,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import IONode from "../GumloopComponents/IONode";
import LeadSummarizerNode from "../LeadGumloopComponents/LeadSummarizerNode.js";
import ProgIONode from "../ProgGumloopComponents/ProgIONode.js";

import zoomInImg from '../../assets/images/zoomInImg.svg'
import zoomOutImg from '../../assets/images/zoomOutImg.svg'
import fitImg from '../../assets/images/fitImg.svg'

import EnrichmentCustomEdge from "../EnrichmentGumloopComponents/EnrichmentCustomEdge";
import CanvasCustomEdge from "./CanvasCustomEdge.js";

import { useRecoilValue } from "recoil";
import { agentValAtom } from "../../store";

const CustomControls = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  return (
    <div className="absolute bottom-2 right-5 flex gap-2 z-50">
      <div className="bg-black flex justify-center items-center w-full">
        <button onClick={() => zoomIn()} className="border-r border-white w-full p-1.5">
          <img src={zoomInImg} alt="zoomInImg" />
        </button>
        <button onClick={() => zoomOut()} className="w-full p-1.5">
          <img src={zoomOutImg} alt="zoomOutImg" />
        </button>
      </div>
      <button onClick={() => fitView()} className="bg-black px-2 py-1.5">
        <img src={fitImg} alt="fitImg" width={18} />
      </button>
    </div>
  );
};

const nodeTypes = {
  ioNode: (data) => <IONode data={data} />,
  summarizerNode: (data) => <LeadSummarizerNode data={data} />,
  progIoNode: (data) => <ProgIONode data={data} />,
};

const edgeTypes = {
  "start-end": EnrichmentCustomEdge,
  "canvas-start-end": CanvasCustomEdge
};

const Flow = (props) => {
  return (
    <ReactFlow {...props}>
      {/* <Controls position="bottom-left" /> */}
      <CustomControls />
      <Background variant="dots" gap={20} size={1} />
    </ReactFlow>
  );
};

const Canvas = () => {
  const agentVal = useRecoilValue(agentValAtom);

  const [nodes, setNodes] = useState(agentVal?.initialNodes);
  const [edges, setEdges] = useState(agentVal?.initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  useEffect(() => {
    console.log(nodes);
  }, [nodes]);
  return (
    <ReactFlowProvider>
      <Flow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        proOptions={{ hideAttribution: true }}
        fitView
        panOnScroll
        selectionOnDrag
        minZoom={0.2}
        maxZoom={2}
      />
    </ReactFlowProvider>
  );
};

export default Canvas;
