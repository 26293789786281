import React, { useEffect, useState } from "react";

import { PlanUpdate } from "../../../../src/services/Settings";

import "./ManageTab.css";
import diamond from "../../../assets/images/diamond.svg";
import { jwtDecode } from "jwt-decode";
import { Select, message } from "antd";
import constants from "../../../helpers/Constants";
import { getCredits } from "../../../services/InsightsServices";

const ManageTab = ({ GetUserSetting }) => {
  const [selectedOption, setSelectedOption] = useState('Basic');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [credits, setCredits] = useState(0);

  const handleOptionChange = (event) => {
    setSelectedOption(event);
    setButtonDisabled(false);
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();
    message.info("Please connect with info@icustomer.ai")
    // let response = await PlanUpdate({
    //   plan: selectedOption,
    //   userID: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
    // });
    // if (response.data.status == "success") {
    //   console.log(response.data);
    //   message.success("Plan updated successfully");
    //   setButtonDisabled(true);
    //   console.log(selectedOption);
    // }
  };

  useEffect(() => {
    (async () => {
      let response = await GetUserSetting({
        userID: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
      });
      if (response) {
        console.log(response.data.data[0])
        // setSelectedOption(response.data.data[0].plan);
      }
      fetchData()
    })();
  }, [])
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("loginToken");
      const decodedToken = jwtDecode(token?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ""));
      const payload = {
        organisation_id: decodedToken.organisation_domain == "zillasecurity.com" ? '9c37cdef-41ca-4a80-a682-87d0cf6b184b' : '0e326d3e-21b9-4855-ac45-c2f659615ec3',
      };
      const response = await getCredits(payload);

      if (response) {
        message.success('Data fetched successfully');
        setCredits(response.data.credits)
        // You can handle the response data here if needed
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      message.error("Error fetching dashboard data");
    }
  };
  return (
    <div className="plan-container">
      <label htmlFor="field2" className="plan-label flex">
        {/* <img
          src={diamond}
          alt="briefcase"
          style={{ paddingBottom: "5px", marginRight: "5px" }}
        />{" "} */}
        Current Plan <span className="text-[12px] ml-4 mt-1 text-[grey]">Consumed Credits: {credits}</span>
      </label>
      <Select
        type="text"
        id="field2"
        className="plan-select"
        value={selectedOption}
        defaultValue={selectedOption}
        onChange={handleOptionChange}
        options={constants.planOptions}
      >
      </Select>
      <div className="plan-btn">
        <button onClick={handleButtonClick} disabled={buttonDisabled}>
          Request Plan Upgrade
        </button>
      </div>
    </div>
  );
};

export default ManageTab;
