import React from 'react';

const LeadJsonToCsv = ({ jsonData }) => {
  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      let propName = parent ? `${parent}_${key}` : key;
      if (propName === "leadscore_final_lead_score") {
        propName = "lead_score"
      } else if (propName === "fitscore_final_fit_score") {
        propName = "fit_score"
      }

      propName = propName?.replace("company_research_", "")

      if (
        ["summary", "router", "linkedin_annual_revenue", "linkedin_company_size"].includes(propName) ||
        propName.includes("time") ||
        !obj[key] || obj[key]?.length < 1
      )
        continue;

      if (propName === "industry_types" || propName === "linkedin_industry_types") {
        const targetIndustries = ["Target Industry A", "Target Industry B", "Other Industries", "Others"];
  
        if (obj[key]?.some(industry => targetIndustries.includes(industry))) {
          continue;
        }
      }

      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else if (Array.isArray(obj[key])) {
        // Properly join the array values into a string
        res[propName] = obj[key].join(', ');
      } else {
        res[propName] = obj[key];
      }
    }
    if (res["fit_score"] && res["lead_score"]) {
      res["average_score"] = (res["fit_score"] + res["lead_score"]) / 2
    }
    return res;
  };

  const convertToCSV = (objArray) => {
    const array = Array.isArray(objArray) ? objArray : [objArray];
    const flatArray = array.map(item => flattenObject(item));
    const keys = Object.keys(flatArray[0]);

    // Create CSV content
    const csv = [
      keys.join(','), // header row
      ...flatArray.map(row => keys.map(key => `"${row[key]}"`).join(',')) // Enclose each field in quotes
    ].join('\r\n');

    return csv;
  };

  const downloadCSV = () => {
    const csv = convertToCSV(jsonData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${jsonData?.person_verification_linkedin?.full_name || "John Doe"}_${new Date().getTime()}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <button onClick={downloadCSV} className='p-2 border rounded-md bg-[#2966BC] text-white hover:scale-110 duration-300 ease-in-out'>Download CSV</button>
    </div>
  );
};

export default LeadJsonToCsv;
