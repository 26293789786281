import { atom } from "recoil";

const tenantLogoAtom = atom({
  key: "tenantLogo",
  default: [null],
});
const socketIOAtom = atom({
  key: "socketIO",
  default: [null],
});
const sideNavContentAtom = atom({
  key: "sideNavContent",
  default: [0], // 0 refers as side menu and 1 refers as Sidechat
});
const chatModeAtom = atom({
  key: "chatMode",
  default: 0, //0 refers as normal and 1 refers as sideChat
});
const pyodideAtom = atom({
  key: "pyodide",
  default: [null], 
});

const reloadChatTableAtom = atom({
  key: 'reloadChatTable',
  default: Date.now()
})

const isPyodideLoadingAtom = atom({
  key: 'isPyodideLoading',
  default: false,
});

const pyodideLoadErrorAtom = atom({
  key: 'pyodideLoadError',
  default: false,
});
export {
  tenantLogoAtom,
  socketIOAtom,
  sideNavContentAtom,
  chatModeAtom,
  pyodideAtom,
  isPyodideLoadingAtom,
  pyodideLoadErrorAtom,
  reloadChatTableAtom
};
