import { useCallback } from "react";
import { Handle, Position } from "@xyflow/react";
import Head from "./Head";
import CompanyMain from "./CompanyMain";

function EnrichCompanyNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
    console.log(data);
  }, []);

  return (
    <div className="border border-[#eee] rounded-[5px] bg-white min-w-[20rem]">
      {data?.data?.firstKey !== "Outputs" && (
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
        />
      )}
      <div className="flex flex-col justify-start items-start">
        <Head data={data} />
        {data?.data?.firstKey && <CompanyMain data={data} />}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="d"
        isConnectable={isConnectable}
        style={{ left: 160 }}
      />

      {data?.data?.firstKey !== "Outputs" && (
        <>
          <Handle
            type="source"
            position={Position.Bottom}
            id="b"
            isConnectable={isConnectable}
            style={{ left: 260 }}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id="a"
            style={{ left: 54 }}
            isConnectable={isConnectable}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id="c"
            isConnectable={isConnectable}
            style={{ left: 374 }}
          />
        </>
      )}
    </div>
  );
}

export default EnrichCompanyNode;
