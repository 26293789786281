import React, { useState, useEffect, useMemo } from 'react';
import { Card, Table, Button, Tabs, DatePicker, Avatar, Badge, Tag, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FetchCatalogue } from '../../../services/Settings';
import { jwtDecode } from "jwt-decode";
const AmplitudeProductEvents = ({ model, onBack }) => {
    console.log({model})
    const [activeTab, setActiveTab] = useState('columns');
    const [tableName, setTableName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [catalogueData, setCatalogueData] = useState([])

    const tabItems = [
        {
            key: 'columns',
            label: 'Columns',
            children: <Table
                        loading={isLoading}
                        dataSource={catalogueData}
                        columns={[
                            {
                                title: 'Column',
                                dataIndex: 'column_name',
                                key: 'column_name',
                            },
                            {
                                title: 'Description',
                                dataIndex: 'description',
                                key: 'description',
                                render: (text) => (
                                    <>
                                        <p>{text}</p>
                                        
                                    </>
                                ),
                            },
                            {
                                title: 'Type',
                                dataIndex: 'data_type',
                                key: 'data_type',
                                render: (text) => (
                                    <>
                                        <Tag color={'grey'}>
                                            {text}
                                        </Tag>
                                    </>
                                ),
                            },
                            {
                                title: 'AI usage',
                                dataIndex: 'ai_usage',
                                key: 'ai_usage',
                                render: (text) => (
                                    <>
                                        <Tag color={text === 'Failed' ? 'red' : 'green'}>
                                            {text}
                                        </Tag>
                                    </>
                                ),
                            },
                        ]}
                        pagination={false}
                    />,
        },
    ];

    useEffect(() => {
        const fetchCatalogueData = async () => {
            setIsLoading(true);
            try {
                let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
                let schema_name;
                if (organization === "talkable.com") {
                    schema_name = "talkable_master";
                } else if (organization === 'zillasecurity.com') {
                    schema_name = "zillasecurity_master";
                } else if (organization === 'icustomer.ai') {
                    schema_name = "icustomer";
                }
                else {
                    schema_name = "talkable_master";
                }
                const {data} = await FetchCatalogue({ table_slug: model?.slug, schema_name: schema_name });

                const responseData = data?.data.map(item => ({
                    key: item.column,
                    column_name: item.column,
                    description: item.description || " ",
                    data_type: item.data_type,
                    ai_usage: 'Included', // You might want to adjust this based on the API response
                }));
                setCatalogueData(responseData);
            } catch (error) {
                console.error("Error fetching catalogue data:", error);
                // Handle the error appropriately (e.g., show an error message to the user)
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchCatalogueData();
    }, [model?.slug]);

    return (
        <Card
            title={model?.table}
            extra={
                <Button type="primary" onClick={onBack}>
                    Back
                </Button>
            }
        >
            <p>{model?.description}</p>
            <div className="my-2 flex justify-start gap-3 items-center">
                <div className='my-2'>
                    <span>Sources: </span>
                    <span className='px-2 py-[2px] ml-2' style={{ borderRadius: "20px", border: "1px solid grey" }}>
                        {model?.table}
                    </span>
                </div>
            </div>
            <div className='flex justify-start gap-4'>
                <Input className='w-[fit-content]' placeholder='Search' />
                <Button>AI Usage</Button>
            </div>
            <Tabs
                activeKey={activeTab}
                onChange={(key) => setActiveTab(key)}
                items={tabItems}
            />
        </Card>
    );
};

export default AmplitudeProductEvents;
