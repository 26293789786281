import React, { useEffect, useState } from 'react';
import { saveFreshReportsInDB } from '../../services/InsightsServices';
import {jwtDecode} from 'jwt-decode';
import { message } from 'antd';
import {debounce} from 'lodash';

const PythonExecutor = ({ code, query_id, postgres_query, description, topic }) => {
  const [imageSrc, setImageSrc] = useState(null);

  const saveFreshReport = debounce(async (result) => {
    const payload = {
      user_id: jwtDecode(
        localStorage
          .getItem("loginToken")
          ?.replace(process.env.REACT_APP_TOKEN_PASSCODE, "")
      )?.id,
      query_id: query_id,
      base64Img: result,
      postgres_query: postgres_query,
      description: description,
      name: topic
    };

    let response = await saveFreshReportsInDB(payload);
    console.log(response);
    if (response.data.status === "success") {
      message.success("Insight has been saved in Reports");
    }
  }, 300); // Adjust the delay as needed

  useEffect(() => {
    const executePythonCode = async () => {
      if (window.pyodide && code) {
        try {
          console.log('Executing Python code...');
          const wrappedCode = `
import base64
from io import BytesIO
import pandas as pd
import matplotlib.pyplot as plt

${code}

buf = BytesIO()
plt.savefig(buf, format='png')
buf.seek(0)
image_base64 = base64.b64encode(buf.getvalue()).decode('utf-8')

image_src = f"data:image/png;base64,{image_base64}"
image_src
          `;
          const result = await window.pyodide.runPythonAsync(wrappedCode);
          console.log('Python code executed successfully');
          setImageSrc(result);
          saveFreshReport(result);
        } catch (error) {
          console.error('Error executing Python code:', error);
        }
      }
    };

    if (window.pyodide) {
      executePythonCode();
    }
  }, [window.pyodide, code]);

  return (
    <div className="python-output">
      {imageSrc ? <img src={imageSrc} alt="Generated by Python" /> : <p>Loading the chart generated by our copilot...</p>}
    </div>
  );
};

export default PythonExecutor;
