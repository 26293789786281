import React, { useState } from "react";

import {
  PlusSquareOutlined,
  ClockCircleOutlined,
  FunnelPlotFilled,
  CloseOutlined,
  DashboardFilled,
  DashOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Select } from "antd";

const DefineBlock = () => {
  const [subBlock, setSubBlock] = useState(false);

  return (
    <div className="border-l-[1rem] border-l-light-purple border border-secondary rounded-md py-4 px-6 flex justify-between items-center">
      <div className="flex flex-col justify-center gap-2">
        <div className="flex items-center gap-4">
          <p className="body_Regular">Performed</p>
          <div className="flex items-center gap-2 border rounded-md py-1 px-2">
            <DashboardFilled className="border rounded-md p-2 bg-primary text-white" />
            <p className="text-character text-[14px]">Item Added</p>
          </div>
          <div className="flex items-center gap-1 border rounded-md py-1 px-2 min-h-10">
            <p className="text-secondary text-[14px]">at least</p>
            <p className="text-character text-[14px]">1 Time</p>
          </div>
        </div>
        <div className="flex items-center gap-4 px-6">
          <div className="flex items-center gap-2">
            <PlusSquareOutlined />
            <p className="body_Regular">Where event property is...</p>
          </div>
          <div className="flex items-center gap-2">
            <ClockCircleOutlined />
            <p className="body_Regular">Time Window</p>
          </div>
          {!subBlock && (
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => setSubBlock(true)}
            >
              <FunnelPlotFilled />
              <p className="body_Regular">Then performed...</p>
            </div>
          )}
        </div>
        {subBlock && (
          <>
            <div className="flex items-center gap-4">
              <Select
                options={[
                  {
                    label: "Then did perform",
                    value: "Then did perform",
                  },
                  {
                    label: "Then did not perform",
                    value: "Then did not perform",
                  },
                ]}
                className="w-fit"
                defaultValue={"Then did perform"}
              />
              <Select
                options={[
                  { label: "Item Added", value: "Item Added" },
                  { label: "Item Removed", value: "Item Removed" },
                  { label: "Item Purchased", value: "Item Purchased" },
                ]}
                className="w-fit"
                placeholder="Select an event"
              />
              <CloseOutlined />
            </div>
            <div className="flex items-center gap-4 px-6">
              <div className="flex items-center gap-2">
                <PlusSquareOutlined />
                <p className="body_Regular">Where event property is...</p>
              </div>
              <div className="flex items-center gap-2">
                <ClockCircleOutlined />
                <p className="body_Regular">Time Window</p>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex items-center gap-4">
        <DashOutlined />
        <DeleteOutlined style={{ color: "red" }} />
      </div>
    </div>
  );
};

export default DefineBlock;
