import React from 'react'

const OutputComponent = ({ finalContent }) => {
  const targetIndustries = ["Target Industry A", "Target Industry B", "Other Industries", "Others"];

  const mapper = [
    {
        key: "Email ID",
        value: finalContent?.email_id || "Cannot Identify"
    },
    {
        key: "Industry Types (Through Web Scraping)",
        value: !finalContent?.company_research?.industry_types?.some(industry => targetIndustries.includes(industry)) ? finalContent?.company_research?.industry_types?.join(", ") || "Cannot Identify" : "Cannot Identify"
    },
    {
        key: "Industry Types (Through LinkedIn)",
        value: !finalContent?.company_research_linkedin?.industry_types?.some(industry => targetIndustries.includes(industry)) ? finalContent?.company_research_linkedin?.industry_types?.join(", ") || "Cannot Identify" : "Cannot Identify"
    },
    {
        key: "Company Size (Through Web Scraping)",
        value: finalContent?.company_research?.company_size || "Cannot Identify"
    },
    // {
    //     key: "Company Size (Through LinkedIn)",
    //     value: finalContent?.company_research_linkedin?.company_size || "Cannot Identify"
    // },
    {
        key: "Annual Revenue (Through Web Scraping)",
        value: finalContent?.company_research?.annual_revenue || "Cannot Identify"
    },
    // {
    //     key: "Annual Revenue (Through LinkedIn)",
    //     value: finalContent?.company_research_linkedin?.annual_revenue || "Cannot Identify"
    // },
    {
        key: "Technology Stacks (Through Web Scraping)",
        value: finalContent?.company_research?.technology_stacks?.join(", ") || "Cannot Identify"
    },
    {
        key: "Technology Stacks (Through LinkedIn)",
        value: finalContent?.company_research_linkedin?.technology_stacks?.join(", ") || "Cannot Identify"
    },
    {
        key: "Geographic Locations (Through Web Scraping)",
        value: finalContent?.company_research?.geographic_locations?.join(", ") || "Cannot Identify"
    },
    {
        key: "Geographic Locations (Through LinkedIn)",
        value: finalContent?.company_research_linkedin?.geographic_locations?.join(", ") || "Cannot Identify"
    },
    {
        key: "Person Full Name",
        value: finalContent?.person_verification_linkedin?.full_name || "Cannot Identify"
    },
    {
        key: "Person Department",
        value: finalContent?.person_verification_linkedin?.department || "Cannot Identify"
    },
    {
        key: "Person Job Title",
        value: finalContent?.person_verification_linkedin?.job_title || "Cannot Identify"
    },
    {
        key: "Person Current Company",
        value: finalContent?.person_verification_linkedin?.current_company || "Cannot Identify"
    },
    {
        key: "Person LinkedIn URL",
        value: finalContent?.person_verification_linkedin?.linkedin_url || "Cannot Identify"
    },
    {
        key: "Fit Score",
        value: finalContent?.fitscore?.final_fit_score || "Cannot Identify"
    },
    {
        key: "Lead Score",
        value: finalContent?.leadscore?.final_lead_score || "Cannot Identify"
    },
    {
        key: "Average Score",
        value: (finalContent?.fitscore?.final_fit_score + finalContent?.leadscore?.final_lead_score) / 2 || "Cannot Identify"
    }
  ]

  return (
    <div className='flex flex-col justify-start items-center w-full gap-4 h-[35vh] overflow-scroll py-4'>
        {mapper?.filter(item => item?.value !== "Cannot Identify")?.map((item, index) => (
            <div key={index} className='flex w-full justify-between items-center' >
                <div>
                    {item?.key}
                </div>
                <div className='border rounded-md bg-gray-200 px-2 py-3 w-[20rem] overflow-x-scroll whitespace-nowrap'>
                    {item?.value}
                </div>
            </div>
        ))}
    </div>
  )
}

export default OutputComponent