import React from 'react'

const LeadIOMain = () => {
  return (
    <div className='p-2'>
        <p className='text-[12px]'>format</p>
        <p className='mt-1 text-[12px]'>{"{input1}"} ({"{input4}"} ARR)</p>
        <p className='text-[12px]'>INFO: {"{input6}"}</p>
        <p className='mt-1 text-[12px]'>Company Name: {"{input2}"}</p>
        <p className='text-[12px]'>Industry: {"{input3}"}</p>
        <p className='text-[12px]'>Country: {"{input5}"}</p>
    </div>
  )
}

export default LeadIOMain