import { Pagination, Table, Tooltip } from "antd";
import { faker } from "@faker-js/faker";
import { useEffect, useState } from "react";
import { FaClipboard } from "react-icons/fa";
import CodeBlock from "./CodeBlock";

const columns = [
  { title: "DATE", dataIndex: "date", key: "date" },
  { title: "ACTION", dataIndex: "action_type", key: "action_type" },
  { title: "USER", dataIndex: "username", key: "username" },
  { title: "RESOURCE TYPE", dataIndex: "resource_type", key: "resource_type" },
];

function getRandomElement(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

const data = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i.toString(),
    date: faker.date.past().toLocaleString(),
    action_type: getRandomElement([
      "User Logged out",
      "Sync Updated",
      "Model Updated",
      "Draft Updated",
      "User Approved Draft",
      "Model Column Created",
    ]),
    username: faker.person.fullName(),
    resource_type: getRandomElement([
      "Session",
      "Sync",
      "Model",
      "Draft",
      "Model Column",
    ]),
  });
}

function AuditLogTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const handleCurrentPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    const start = (currentPage - 1) * 10;
    setTableData(data.slice(start, start + 10));
  }, [currentPage]);
  return (
    <div className="flex flex-col gap-4">
      <Table
        size="middle"
        columns={columns}
        dataSource={tableData}
        pagination={false}
      />
      <Pagination
        total={data.length}
        current={currentPage}
        onChange={handleCurrentPage}
        pageSize={10}
        showSizeChanger={false}
      />
    </div>
  );
}

export default function AuditLogs() {
  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false);
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(data, null, 2));
      setCopiedToClipBoard(true);
      setTimeout(() => {
        setCopiedToClipBoard(false);
      }, 3000);
    } catch (err) {
      console.error("Unable to copy text", err);
    }
  };
  return (
    <div className="flex w-full gap">
      <div className="w-3/5 px-4">
        <AuditLogTable />
      </div>
      <div className="w-2/5 shadow-lg h-[600px] overflow-y-auto border-2 border-gray-300 relative">
        <div className="absolute top-3 right-3">
          <Tooltip title={copiedToClipBoard ? "Copied" : "Copy to Clipboard"}>
            <FaClipboard
              className="w-[20px] h-[20px] cursor-pointer"
              onClick={handleCopyToClipboard}
            />
          </Tooltip>
        </div>
        <CodeBlock code={JSON.stringify(data, null, 2)} language="json" />
      </div>
    </div>
  );
}
