import React, { useState } from 'react';
import { Modal, Tabs, Select, Badge, List, Button, message } from 'antd';
import { addColumns } from '../../services/InsightsServices';
import { jwtDecode } from 'jwt-decode';
import { useSetRecoilState, useRecoilState } from "recoil";
import { reloadChatTableAtom } from '../../store/atoms/MainAtoms';

const { TabPane } = Tabs;
const { Option } = Select;

const SfModal = ({ visible, newObjects, setSfModalOpen }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [reloadChatData, setReloadChatData] = useRecoilState(reloadChatTableAtom)

    // Get unique table names
    const uniqueTables = [...new Set(newObjects?.map(item => item.table_name))];

    // State to manage selected columns
    const [selectedColumns, setSelectedColumns] = useState([]);

    // Filter columns based on selected tables
    const filteredColumnsByTable = (tableName) => {
        return newObjects
            ?.filter(item => item.table_name === tableName)
            ?.map(({ column_name }) => `${column_name}-${tableName}`);
    };

    // Handle column selection
    const handleColumnChange = (tableName, values) => {
        setSelectedColumns(prevColumns => {
            const columnsWithoutCurrentTable = prevColumns.filter(col => !col.includes(`-${tableName}`));
            return [...columnsWithoutCurrentTable, ...values];
        });
    };

    // Calculate total columns
    const totalColumns = uniqueTables.reduce((acc, tableName) => {
        return acc + (filteredColumnsByTable(tableName)?.length || 0);
    }, 0);

    // Generate columns JSON dynamically
    const generateColumnsJSON = () => {
        const columnsObj = uniqueTables.reduce((acc, tableName) => {
            acc[tableName] = selectedColumns
                .filter(col => col.includes(`-${tableName}`))
                .map(col => col.split('-')[0]);
            return acc;
        }, {});
        return columnsObj;
    };

    const addColumnsToTable = async () => {
        setIsLoading(true)
        const orgParam = new URLSearchParams(window.location.search).get('org');
        let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
        if (orgParam) {
            const [password, orgName] = orgParam.split(' ');
            const domainPassword = process.env.REACT_APP_DOMAIN_PASSWORD;
            if (password === domainPassword) {
                organization = orgName + ".com";
            }
        }
        let schema_name;
        if (organization === "talkable.com") {
            schema_name = "talkable_master";
        } else if (organization === 'zillasecurity.com') {
            schema_name = "zillasecurity_master";
        } else {
            schema_name = "talkable_master";
        }
        try {
            const payload = {
                master_schema_name: schema_name,
                chat_table: `insighttables.new_table_${localStorage.getItem("extended_query_id").replaceAll('-', '_')}`,   
                columns: generateColumnsJSON()
            };
            console.log("CHAT TABLE +++++ ", payload?.chat_table)
            const response = await addColumns(payload);
            const data = response.data;
            console.log(data, "Columns added successfully");
            message.success("Columns added successfully");
        } catch (error) {
            console.error("Error adding columns:", error);
            message.error("Error adding columns");
        } finally {
            setIsLoading(false)
            setReloadChatData(Date.now())
            setSfModalOpen(false)
        }
    };

    return (
        <Modal
            visible={visible}
            onCancel={() => console.log("Modal closed")}
            title="Select Columns from Objects"
            footer={null}
            style={{ maxWidth: 800 }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '400px', overflowY: 'auto' }}>
                {/* Tabs for each table */}
                <Tabs>
                    {uniqueTables.map(tableName => (
                        <TabPane
                            tab={
                                <Badge count={filteredColumnsByTable(tableName)?.length || 0} offset={[10, 0]}>
                                    {tableName}
                                </Badge>
                            }
                            key={tableName}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder={`Select columns from ${tableName}`}
                                value={selectedColumns.filter(col => col.includes(`-${tableName}`))}
                                onChange={(values) => handleColumnChange(tableName, values)}
                            >
                                {filteredColumnsByTable(tableName)?.map(column => (
                                    <Option key={column} value={column}>
                                        {column.split('-')[0]}
                                    </Option>
                                ))}
                            </Select>
                        </TabPane>
                    ))}
                </Tabs>

                {/* Display total number of columns */}
                <div style={{ marginTop: '16px' }}>
                    <strong>Total Columns:</strong> {totalColumns}
                </div>
                <div>
                    <strong>Selected Columns:</strong> {selectedColumns.length}
                </div>
                {/* Display selected columns */}
                {
                    selectedColumns.length > 0 &&
                    <div style={{ marginTop: '16px' }}>
                        <List
                            header={<div className='text-[grey]'>Selected Columns to add:</div>}
                            bordered
                            dataSource={selectedColumns}
                            renderItem={item => <List.Item>{item}</List.Item>}
                        />
                    </div>
                }
                {/* Button to add columns */}
                {selectedColumns.length > 0 &&
                    <div style={{ textAlign: 'right', marginTop: '16px' }}>
                        <Button type="primary" onClick={() => setSfModalOpen(false)}>
                            Cancel
                        </Button>
                        <Button type="primary" loading={isLoading} onClick={addColumnsToTable}>
                            Add Columns
                        </Button>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default SfModal;
