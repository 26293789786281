import { Client } from './Client.js'

export const saveFreshReportsInDB = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/saveFreshReport", formdata);
  } catch (err) {
    return err;
  }
};

export const fetchTableData = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/displayTable", formdata);
  } catch (err) {
    return err;
  }
};

export const saveToDashboard = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/saveToDashboard", formdata);
  } catch (err) {
    return err;
  }
};

export const getDashboardData = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/getDashboardData", formdata);
  } catch (err) {
    return err;
  }
};

export const getQueryReport = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/getQueryReport", formdata);
  } catch (err) {
    return err;
  }
};

export const getReport = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/getReport", formdata);
  } catch (err) {
    return err;
  }
};

export const updateChartType = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/updateChartType", formdata);
  } catch (err) {
    return err;
  }
};

export const updateInsightsTable = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/updateInsightTable", formdata);
  } catch (err) {
    return err;

  }
}

export const updateDataforSpreadsheet = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/update-spreadsheet", formdata);
  } catch (err) {
    return err;

  }
}

export const getColumns = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/getColumns", formdata);
  } catch (err) {
    return err;
  }
};

export const addColumns = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/addColumns", formdata);
  } catch (err) {
    return err;
  }
};

export const liveData = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/liveTable", formdata);
  } catch (err) {
    return err;
  }
};

export const getCredits = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/getCredits", formdata);
  } catch (err) {
    return err;
  }
};

export const enrichColumns = async (formdata) => {
  try {
    return await Client.post("/insightsRoutes/enrichColumns", formdata);
  } catch (err) {
    return err;
  }
};