import React, { useState, useEffect } from 'react';

const personOptions = [
  { name: 'Email', value: 'Email' },
  { name: 'Title', value: 'Title' },
  { name: 'Phone', value: 'MobilePhone' },
  { name: 'Linkedin_url', value: 'Linkedin_url' },
  { name: 'City', value: 'MailingCity' },
  { name: 'State', value: 'MailingState' },
];

const accountOptions = [
  { name: 'Account_ID', value: 'AccountNumber' },
  { name: 'Account_Name', value: 'Name' },
  { name: 'Account_Website', value: 'Website' },
  { name: 'Industry', value: 'Industry' },
  { name: 'Country', value: 'BillingCountry' },
  { name: 'State', value: 'BillingState' },
];

const salesforcePersonOptions = [
  'Email',
  'Title',
  'MobilePhone',
  'Linkedin_url',
  'MailingCity',
  'MailingState',
];

const salesforceAccountOptions = [
  'AccountNumber',
  'Name',
  'Website',
  'Industry',
  'BillingCountry',
  'BillingState',
];

const PushAPINodeComponent = ({ type }) => {
  const [system, setSystem] = useState('Salesforce');
  const [object, setObject] = useState('Account');
  const [syncType, setSyncType] = useState('Upsert');
  const [fields, setFields] = useState([]);
  const [sfFields, setSfFields] = useState([]);

  useEffect(() => {
    if (type === "person") {
      setFields(personOptions);
      setSfFields(salesforcePersonOptions);
    } else {
      setFields(accountOptions);
      setSfFields(salesforceAccountOptions);
    }
  }, [type]);

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...fields];
    updatedFields[index][key] = value;
    setFields(updatedFields);
  };

  const addField = () => {
    setFields([...fields, { name: '', value: '' }]);
  };

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">System</label>
          <select 
            value={system} 
            onChange={(e) => setSystem(e.target.value)} 
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="Salesforce">Salesforce</option>
            <option value="Hubspot">Hubspot</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Object</label>
          <select 
            value={object} 
            onChange={(e) => setObject(e.target.value)} 
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="Account">Account</option>
            <option value="Leads">Leads</option>
            <option value="Contacts">Contacts</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Sync Type</label>
          <select 
            value={syncType} 
            onChange={(e) => setSyncType(e.target.value)} 
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="Upsert">Upsert</option>
          </select>
        </div>
      </div>

      <h2 className="text-lg font-semibold mb-4">Field Mapping</h2>
      
      {fields.map((field, index) => (
        <div key={index} className="grid grid-cols-3 gap-4 mb-4">
          <select
            value={field.name}
            onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">Select Field</option>
            {fields.map((f) => (
              <option key={f.name} value={f.name}>
                {f.name}
              </option>
            ))}
          </select>
          <select defaultValue="=" className="w-full border border-gray-300 rounded-md p-2">
            <option value="=">=</option>
          </select>
          <select
            value={field.value}
            onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">Select Salesforce Field</option>
            {sfFields.map((f) => (
              <option key={f} value={f}>
                {f}
              </option>
            ))}
          </select>
        </div>
      ))}

      <button 
        onClick={addField} 
        type="button" 
        className="w-full mt-4 border border-gray-300 rounded-md p-2 bg-gray-100 hover:bg-gray-200"
      >
        Add Field
      </button>
    </div>
  );
};

export default PushAPINodeComponent;