import React from 'react'
import CatalogueNavbar from '../Catalogue/CatalogueNavbar'
import CompanyDashboard from './CompanyDashboard'
import CompanyBoundNavbar from './CompanyBoundNavbar'
import { activeTabAtom } from '../../../store/atoms/AnalyticsAtoms'
import { useRecoilValue } from 'recoil'
import { Typography } from 'antd'

const { Title } = Typography

const Company = () => {
  const activeTab = useRecoilValue(activeTabAtom);
  return (
    <div>
        <header className='text-[#8EA9D0]' >
          <Title style={{fontSize: "18px", marginLeft: "20px"}} className='mb-0 mt-2' level={5} type="secondary">GTM Observability for the company’s full funnel</Title>
        </header>
        <CatalogueNavbar />
        {[1, 2, 3, 4].includes(activeTab) && <CompanyBoundNavbar />}
        {/* <CompanyBoundNavbar /> */}
        <CompanyDashboard />
    </div>
  )
}

export default Company