import React, { useEffect, useState } from 'react';
import logo from "../../../../assets/images/logoWOname.png";
import { Skeleton } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { chatModeAtom } from '../../../../store/atoms/MainAtoms';
import { copilotLoadingAtom } from '../../../../store';
import { motion } from "framer-motion";

const TextAnswer = ({ chat, isLast }) => {
    const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
    const [loading, setLoading] = useRecoilState(copilotLoadingAtom);
    const [displayedAnswer, setDisplayedAnswer] = useState('');

    const charVariants = {
        hidden: { opacity: 0 },
        reveal: { opacity: 1 },
    };

    const splitString = (inputString) => {
        const characters = [];
        const regex = /[\s\S]/gu;

        let match;

        while ((match = regex.exec(inputString)) != null) {
            characters.push(match[0]);
        }

        return characters;
    };

    return (
        <div className={`flex justify-start ${chatMode == 1 ? "ml-2 gap-2 p-1" : "gap-4 p-2"}`}>
            <div className={`flex justify-start items-center ${chatMode == 1 ? "ml-2 " : "mt-2"}`}>
                <img
                    src={logo}
                    alt="brand-logo"
                    width={35}
                    className="mt-1 ml-2 self-start"
                />
            </div>
            <div className={`text-start text-[14px] w-[90%] ${chatMode == 1 ? "p-2" : "p-4"} rounded-md relative text-black`}>
                {isLast ? (
                    <>
                        {chat.answer?.split("\n")?.map((ansString, idx) => (
                            <motion.div
                                initial="hidden"
                                animate="reveal"
                                transition={{ staggerChildren: 0.04 }}
                                key={idx}
                            >
                                <div
                                    // initial="hidden"
                                    // animate="reveal"
                                    // transition={{ staggerChildren: idx * 0.01 }}
                                    className=''
                                // onAnimationComplete={handleAnimationComplete}
                                >
                                    {ansString
                                        .split(/(sourceLink\d+)/)
                                        .map((segment, indx) => {
                                            return splitString(segment).map((ch, idx) => (
                                                <motion.span
                                                    key={`${ch}_${idx}`}
                                                    transition={{ duration: 0.2 }}
                                                    variants={charVariants}
                                                >
                                                    {ch}
                                                </motion.span>
                                            ));
                                        }
                                        )}
                                </div>
                            </motion.div>
                        ))}
                    </>
                ) : (
                    chat?.answer.split('\n').map((line, index) => (
                        <p key={index} className="m-0">
                            {line}
                        </p>
                    ))
                )}
                {
                    loading && isLast && <Skeleton className='mt-3' active />
                }
            </div>
        </div>
    );
};

export default TextAnswer;
