import React from "react";
import { Input, Table } from "antd";

import notion from "../../assets/images/notion.png";
import hubspot from "../../assets/images/hubspot.png";
import google from "../../assets/images/google.png";
import metaImg from "../../assets/images/metaImg.png";
import salesforceImg from "../../assets/images/salesforceImg.png";
import slackLogo from "../../assets/images/slackLogo.png";
import { useSetRecoilState } from "recoil";
import { activeSegmentAtom } from "../../store";

const { Search } = Input;

const columns = [
  {
    title: "NAME",
    dataIndex: "name",
  },
  {
    title: "TYPE",
    dataIndex: "type",
    render: ({ image, type }) => (
      <div className="flex justify-start items-center gap-1">
        <img src={image} alt={type} width={14} />
        <p>{type}</p>
      </div>
    ),
  },
  {
    title: "LABELS",
    dataIndex: "labels",
    render: () => (
      <div className="flex justify-start items-center gap-1">--</div>
    ),
  },
  {
    title: "LAST UPDATED",
    dataIndex: "lastUpdated",
    render: ({ lastUpdated, createdBy }) => (
      <div className="flex justify-start items-center gap-1">
        <p>{lastUpdated}</p>
        <div className="bg-gray-200 rounded-full py-0.5 px-1">{createdBy}</div>
      </div>
    ),
  },
];
const data = [
  {
    key: "1",
    name: "Hubspot",
    type: { image: hubspot, type: "Hubspot" },
    lastUpdated: { lastUpdated: "04/13/24", createdBy: "NG" },
  },
  {
    key: "2",
    name: "Google Ads",
    type: { image: google, type: "Google Ads" },
    lastUpdated: { lastUpdated: "04/11/24", createdBy: "LW" },
  },
  {
    key: "3",
    name: "Facebook Ads",
    type: { image: metaImg, type: "Facebook Ads" },
    lastUpdated: { lastUpdated: "04/10/24", createdBy: "AP" },
  },
  {
    key: "4",
    name: "Sales CRM",
    type: { image: salesforceImg, type: "Salesforce" },
    lastUpdated: { lastUpdated: "03/22/24", createdBy: "CE" },
  },
  {
    key: "5",
    name: "Alerts",
    type: { image: slackLogo, type: "Slack" },
    lastUpdated: { lastUpdated: "03/20/24", createdBy: "DR" },
  },
  {
    key: "6",
    name: "Customer Sucess Tickets",
    type: { image: notion, type: "Notion" },
    lastUpdated: { lastUpdated: "03/13/24", createdBy: "CE" },
  },
];

const SelectDestination = () => {
  const setActiveSegment = useSetRecoilState(activeSegmentAtom)

  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const handleClick = ({ name }) => {
    console.log(name);
    if (name === "Hubspot") {
        setActiveSegment(3)
    }
  };

  return (
    <>
      <div className="h4_Medium text-primary px-32 py-4">
        Select a Destination
      </div>
      <Search
        placeholder="Search by name..."
        onSearch={onSearch}
        className="extendSearch px-36 mb-4"
        allowClear
      />
      <div className="px-36 flex flex-col justify-center gap-4">
        <Table
          onRow={(record) => {
            return {
              onClick: () => handleClick(record),
            };
          }}
          pagination={false}
          columns={columns}
          dataSource={data}
          className="cursor-pointer"
        />
      </div>
    </>
  );
};

export default SelectDestination;
