import React from "react";
import { dropAtom, activeTabAtom } from "../../../store/atoms/AnalyticsAtoms";
import { useRecoilValue } from "recoil";

const ConversionRate = ({ rate, type, index }) => {
  const drop = useRecoilValue(dropAtom);
  const activeTab = useRecoilValue(activeTabAtom);
  const renderDays = () => {
    if (activeTab === 4 && (drop === "Month" || drop === "Quarter")) {
      if (index === 0) {
        return "7 days"
      } else if (index === 1) {
        return "14 days"
      } else if ([2, 4].includes(index)) {
        return "3 days"
      } else if ([3, 5].includes(index)) {
        return "6 days"
      } else if (index === 6) {
        return "9 days"
      } else if (index === 7) {
        return "12 days"
      } else {
        return "5 days"
      } 
    } else {
      if (index === 0) {
        return "7 days"
      } else if (index === 1) {
        return "14 days"
      } else if ([2, 4].includes(index)) {
        return "30 days"
      } else if ([3, 5].includes(index)) {
        return "60 days"
      } else if (index === 6) {
        return "90 days"
      } else if (index === 7) {
        return "120 days"
      } else {
        return "5 days"
      } 
    }
  }
  return (
<>
<div
      style={{
        width: "60px",
        height: "20px",
        backgroundColor: "rgba(209, 213, 219, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "10px",
        position: "absolute",
        bottom: type === "rate" ? "-4.6rem" : "-7rem",
        left: "125%",
        transform: "translateX(-50%)",
      }}
    >
     {renderDays()}
    </div>
    <div
      style={{
        width: "60px",
        height: "20px",
        backgroundColor: "rgba(209, 213, 219, 1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "10px",
        position: "absolute",
        bottom: type === "rate" ? "-1.25rem" : "-7rem",
        left: "125%",
        transform: "translateX(-50%) translateY(120%)",
      }}
    >
      {`${rate}${type === "rate" ? "%" : ""}`}
    </div>
</>
  );
};

export default ConversionRate;
