import React from 'react';
import { useState } from 'react';
import { Table, Button, Typography, Form, Select, Input, Switch, DatePicker, Space, Modal, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, InfoCircleFilled } from '@ant-design/icons';
import './EnrichTemplate.css'
import TreeNode from './TreeNode';
const { Option } = Select;
const { Title } = Typography;

const dataSource = [
    {
        key: '1',
        integration: 'Salesforce ',
        name: 'ICP Check Template',
        owner: 'David',
        dateCreated: '08/10/2024',
    },
    {
        key: '2',
        integration: 'Salesforce ',
        name: 'ICP Lead Enrich Template',
        owner: 'Tyler',
        dateCreated: '07/22/2024',
    },
    {
        key: '3',
        integration: 'Salesforce ',
        name: 'ICP Account Enrich Template',
        owner: 'David',
        dateCreated: '07/18/2024',
    },
];

const columns = [
    {
        title: 'Integration',
        dataIndex: 'integration',
        key: 'integration',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
    },
    {
        title: 'Date Created',
        dataIndex: 'dateCreated',
        key: 'dateCreated',
    },
    {
        title: 'Actions',
        key: 'actions',
        render: () => (
            <Space size="middle">
                <Button icon={<EditOutlined />} />
                <Button icon={<DeleteOutlined />} />
            </Space>
        ),
    },
];
const modalData = [
    { key: '5', col1: 'Description', col2: 'Account Description', col3: 'e.g. Talkable is the most comprehensive...', col4: "Complete if missing", pe: true },
    { key: '6', col1: 'Street', col2: 'Billing Street', col3: 'e.g. 2261 Market Street #4588 San Francisco, California 94114 US', col4: "Complete if missing", pe: true },
    { key: '7', col1: 'City', col2: 'Billing City', col3: 'e.g. Waltham', col4: "Complete if missing", pe: true },
    { key: '8', col1: 'State', col2: 'Billing State/Province', col3: 'e.g. Massachusetts', col4: "Complete if missing", pe: true },
    { key: '9', col1: 'Zip Code', col2: 'Billing Zip/Postal Code', col3: 'e.g. 02452', col4: "Complete if missing", pe: true },
    { key: '10', col1: 'Country', col2: 'Billing Country', col3: 'e.g. United States', col4: "Complete if missing", pe: true },
    { key: '11', col1: 'Website', col2: 'Website', col3: 'e.g. www.talkable.com', col4: "Complete if missing", pe: true },
    { key: '1', col1: 'Name', col2: 'iCustomer Universal Id', col3: 'e.g. Talkable', col4: "Complete if missing", pe: false },
    { key: '2', col1: 'iCustomer Universal ID (oGraph_Universal_Id__c)', col2: 'iCustomer First Updated', col3: 'e.g. 344589814', col4: "Overwrite field", pe: false },
    { key: '3', col1: 'iCustomer First Updated (iCustomer_First_Updated__c)', col2: 'iCustomer Last Updated', col3: 'e.g. 01/02/2020 12:00:00PM UTC', col4: "Complete if missing.", pe: false },
    { key: '4', col1: 'iCustomer Last Updated (iCustomer_First_Updated__c)', col2: 'Talkable Last Updated (DOZISF__Talkable_Last_Updated__c)', col3: 'e.g. 01/02/2020 12:00:00PM UTC', col4: "Overwrite field", pe: false },
];
const modalColumns = [
    {
        title: <Select value={"Salesforce Account"}>
            <Option>Salesforce Account</Option>
            <Option disabled>Salesforce Contact</Option>
            <Option disabled>Salesforce Opportunity</Option>
            <Option disabled>Salesforce Lead</Option>
        </Select>,
        dataIndex: 'col1',
        key: 'col1',
        render: (text) => (
            <Select defaultValue={text} disabled={text == 'iCustomer Universal ID (oGraph_Universal_Id__c)' || text == 'iCustomer First Updated (iCustomer_First_Updated__c)' || text == 'iCustomer Last Updated (iCustomer_First_Updated__c)'} style={{ width: "fit-content" }}>
                <Option value="Name">Name</Option>
                <Option value="iCustomer Universal ID (oGraph_Universal_Id__c)">iCustomer Universal ID (oGraph_Universal_Id__c)</Option>
                <Option value="iCustomer First Updated (iCustomer_First_Updated__c)">iCustomer First Updated (iCustomer_First_Updated__c)</Option>
                <Option value="iCustomer Last Updated (iCustomer_First_Updated__c)">iCustomer Last Updated (iCustomer_First_Updated__c)</Option>
                <Option value="Description">Description</Option>
                <Option value="Street">Street</Option>
                <Option value="City">City</Option>
                <Option value="State">State</Option>
                <Option value="Zip Code">Zip Code</Option>
                <Option value="Country">Country</Option>
                <Option value="Website">Website</Option>
            </Select>
        ),
    },
    {
        title: " ",
        dataIndex: 'arrow',
        key: 'arrow',
        render: (text) => (
            <div>
                {"->"}
            </div>
        ),
    },
    {
        title: 'oGraph',
        dataIndex: 'col2',
        key: 'col2',
        render: (text) => (
            <Select defaultValue={text} disabled={text == 'iCustomer Universal Id' || text == 'iCustomer First Updated' || text == 'iCustomer Last Updated' || text == "Talkable Last Updated (DOZISF__Talkable_Last_Updated__c)"} style={{ width: "fit-content" }}>
                <Option value="Option A">Option A</Option>
                <Option value="Option B">Option B</Option>
                <Option value="Option C">Option C</Option>
            </Select>
        ),
    },
    {
        title: 'Example',
        dataIndex: 'col3',
        key: 'col3',
        // render: (text) => (
        //     <Select defaultValue={text} style={{ width: 120 }}>
        //         <Option value="Option X">Option X</Option>
        //         <Option value="Option Y">Option Y</Option>
        //         <Option value="Option Z">Option Z</Option>
        //     </Select>
        // ),
    },
    {
        title: 'Enrich Options',
        dataIndex: 'col4',
        key: 'col4',
        render: (text) => (
            <Select defaultValue={text} disabled={text == "Overwrite field" || text == "Complete if missing."} style={{ width: "fit-content" }}>
                <Option value="Complete if missing">Complete if missing</Option>
                <Option value="Overwrite field">Overwrite field</Option>
            </Select>
        ),
    },
    {
        title: 'Persist Enrichment',
        dataIndex: 'pe',
        key: 'pe',
        width: 250,
        render: (text) => (
            <>
                {text &&
                    <div className='flex justify-center gap-2 text-[20px]'>
                        <DeleteOutlined />
                        <Checkbox />
                    </div>}
            </>
        ),
    },
];
const EnrichTemplates = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isTreeVisible, setIsTreeVisible] = useState(false);
    const data = [
        {
            name: "Person",
            children: [
                {
                    name: "Demographic",
                    children: [
                        { name: "Subject Areas", children: [{ name: "Digital Marketing" }, { name: "Machine Learning" }, { name: "Sustainability" }] },
                        {
                            name: "Contact Information",
                            children: [
                                { name: "countryduadmin" },
                                { name: "countryduanalytics" },
                                { name: "countryduengg" },
                                { name: "countrydufinance" },
                                { name: "countryduit" },
                                { name: "countrydumarketing" },
                                { name: "countrydusales" },
                                { name: "email" },
                                { name: "first_name" },
                                { name: "global_duadmin" },
                                { name: "global_duanalytics" },
                                { name: "global_duengg" },
                                { name: "global_dufinance" },
                                { name: "global_duit" },
                                { name: "global_dumarketing" },
                                { name: "global_dusales" },
                                { name: "last_name" },
                                { name: "middle_name" },
                                { name: "other_email" },
                                { name: "phonenumber" },
                                { name: "regional_duadmin" },
                                { name: "regional_duanalytics" },
                                { name: "regional_duengg" },
                                { name: "regional_dufinance" },
                                { name: "regional_duit" },
                                { name: "regional_dumarketing" },
                                { name: "regional_dusales" },
                                { name: "salutation" },
                            ],
                        },
                    ],
                },
                {
                    name: "Signals",
                    children: [
                        { name: "Career Transitions", children: [{ name: "job change" }, { name: "Promoted" }] },
                        { name: "Professional Updates", children: [{ name: "Overall Skills" }, { name: "New skill acquisition" }] },
                    ],
                },
                {
                    name: "Social Network Activity",
                    children: [
                        { name: "contact_fb_id" },
                        { name: "contact_linkedin_id" },
                        { name: "contact_twitter_id" },
                        { name: "instagram_id" },
                        { name: "Social media posting" },
                        { name: "linkedin_followers" },
                        { name: "Instagram_followers" },
                        { name: "twitter_followers" },
                        { name: "fb_followers" },
                    ],
                },
                {
                    name: "Activities",
                    children: [
                        { name: "Unidentified User Actions", children: [{ name: "Time on site" }, { name: "Website page views" }] },
                        { name: "Identified User Interactions", children: [{ name: "Webinar attendance" }, { name: "Whitepaper download" }] },
                    ],
                },
                {
                    name: "News & Posts",
                    children: [
                        { name: "Content Tone Classification", children: [{ name: "Negative" }, { name: "Neutral" }, { name: "Positive" }] },
                        { name: "Performance Metrics", children: [{ name: "Engagement rate" }, { name: "Impressions" }, { name: "Reach" }] },
                        { name: "Event Categories", children: [{ name: "Award reception" }, { name: "Funding round" }, { name: "Product launch" }] },
                    ],
                },
                {
                    name: "Skills & Tools",
                    children: [
                        { name: "Hard Skills", children: [{ name: "Data analysis" }, { name: "Python programming" }, { name: "SEO" }] },
                        { name: "Soft Skills", children: [{ name: "Communication" }, { name: "Leadership" }, { name: "Problem-solving" }] },
                        { name: "Professional Qualifications", children: [{ name: "Certifications" }, { name: "CPA" }, { name: "MBA" }, { name: "PMP certification" }] },
                        { name: "Technology Proficiencies", children: [{ name: "Adobe Creative Suite" }, { name: "Salesforce" }, { name: "Tableau" }] },
                    ],
                },
                {
                    name: "Relevance",
                    children: [
                        { name: "Client Alignment Indicators", children: [{ name: "Budget match" }, { name: "Product interest level" }] },
                        { name: "Target Profile Matching", children: [{ name: "ICP fit score" }, { name: "Persona alignment percentage" }] },
                        { name: "Prospect Categorization", children: [{ name: "Hot lead" }, { name: "Not qualified" }, { name: "Nurturing required" }] },
                    ],
                },
                {
                    name: "Custom Attributes",
                    children: [
                        { name: "User-Defined Fields", children: [{ name: "Custom score" }, { name: "Special interest flag" }, { name: "donotcall" }] },
                    ],
                },
                {
                    name: "Education",
                    children: [
                        { name: "User-Defined Fields", children: [{ name: "education_1_graduation_year" }, { name: "education_1_institution" }, { name: "education_1_level" }, { name: "education_1_start_year" }, { name: "education_2_graduation_year" }, { name: "education_2_institution" }, { name: "education_2_level" }, { name: "education_2_start_year" }] },
                    ],
                },
                {
                    name: "Job Information",
                    children: [
                        { name: "User-Defined Fields", children: [{ name: "job_function" }, { name: "job_level" }, { name: "job_sub_function" }, { name: "job_title" }, { name: "job_title_raw" }, { name: "previous_position_1_id" }, { name: "previous_position_1_start_year" }, { name: "previous_position_1_title" }, { name: "previous_position_2_id" }, { name: "previous_position_2_start_year" }, { name: "previous_position_2_title" }, { name: "Promoted" }] },
                    ],
                },
            ],
        },
        {
            name: "Account",
            children: [
                {
                    name: "Firmographic",
                    children: [
                        { name: "Financial Metrics", children: [{ name: "Growth rate" }, { name: "IT Spend" }, { name: "Profit margin" }, { name: "revenue" }] },
                        {
                            name: "Organizational Size",
                            children: [
                                { name: "account_employees" },
                                { name: "Employee count" },
                                { name: "employees" },
                                { name: "Office locations" },
                                { name: "size" },
                            ],
                        },
                        {
                            name: "Business Classification",
                            children: [
                                { name: "account_gicscode" },
                                { name: "account_industry" },
                                { name: "account_subindustry" },
                                { name: "B2B/B2C" },
                                { name: "gics_code" },
                                { name: "industry" },
                                { name: "naics_code" },
                                { name: "Public or Private or PE Owned" },
                                { name: "sic_code" },
                                { name: "subindustry" },
                            ],
                        },
                    ],
                },
                {
                    name: "Technographics",
                    children: [
                        {
                            name: "IT Infrastructure",
                            children: [{ name: "Cloud services" }, { name: "On-premise servers" }, { name: "Technographics" }],
                        },
                        {
                            name: "Software Ecosystem",
                            children: [{ name: "CRM system" }, { name: "ERP solution" }, { name: "Marketing automation" }],
                        },
                        {
                            name: "Hardware Inventory",
                            children: [{ name: "Desktop types" }, { name: "IoT sensors" }, { name: "Mobile devices" }],
                        },
                        {
                            name: "Digital Technology",
                            children: [{ name: "browser_list" }, { name: "device_list" }, { name: "digital_tech_tags" }, { name: "domain_list" }, { name: "ip_list" }, { name: "platform_list" }],
                        },
                    ],
                },
                {
                    name: "Psychographic",
                    children: [
                        { name: "Organizational Behavior", children: [{ name: "Customer-centric" }, { name: "Innovation-driven" }] },
                        { name: "Corporate Philosophy", children: [{ name: "Core values" }, { name: "Mission statement" }] },
                        { name: "Environmental Initiatives", children: [{ name: "Carbon neutrality goal" }, { name: "Recycling program" }] },
                    ],
                },
                {
                    name: "Signals",
                    children: [
                        {
                            name: "Economic Indicators",
                            children: [{ name: "Credit rating" }, { name: "economicindicators" }, { name: "regulatorychanges" }, { name: "Stock price trend" }, { name: "trending_themes" }],
                        },
                        {
                            name: "Company-Specific Trends",
                            children: [{ name: "consumertrend" }, { name: "Customer churn" }, { name: "Product adoption rate" }],
                        },
                        {
                            name: "Workforce Dynamics",
                            children: [{ name: "Employee satisfaction score" }, { name: "Hiring rate" }, { name: "hiring_velocity" }],
                        },
                        {
                            name: "Corporate Actions",
                            children: [
                                { name: "Acquisition" },
                                { name: "address-relocation" },
                                { name: "addressrelocation" },
                                { name: "company-stage" },
                                { name: "Divestiture" },
                                { name: "Downsizing" },
                                { name: "Expansion" },
                                { name: "Franchising" },
                                { name: "Funding" },
                                { name: "funding-stage" },
                                { name: "Investment" },
                                { name: "Joint venture" },
                                { name: "latest_contract" },
                                { name: "latest_funding" },
                                { name: "Merger" },
                                { name: "Mergers" },
                                { name: "Public listing" },
                                { name: "Rebranding" },
                                { name: "Relocation" },
                                { name: "Takeover" },
                            ],
                        },
                        {
                            name: "Growth Patterns",
                            children: [{ name: "Market expansion" }, { name: "New product lines" }],
                        },
                        {
                            name: "Potential Threats",
                            children: [{ name: "Competitor actions" }, { name: "Regulatory changes" }, { name: "regulatorychanges" }],
                        },
                    ],
                },
                {
                    name: "News & Posts",
                    children: [
                        { name: "Official Announcements", children: [{ name: "Alliance" }, { name: "Earnings reports" }, { name: "event" }, { name: "expansion_news" }, { name: "Partnership" }, { name: "press_release" }, { name: "webinar" }] },
                        { name: "Digital Platform Updates", children: [{ name: "Blog posts" }, { name: "Social media updates" }] },
                        { name: "Media Coverage", children: [{ name: "Industry analyses" }, { name: "industrynews" }, { name: "News articles" }] },
                        { name: "Company News", children: [{ name: "hiring_news" }, { name: "hiring-news" }, { name: "layoff_news" }, { name: "layoff-news" }, { name: "legal_news" }, { name: "legal-news" }, { name: "merger_ipo_acquisition_news" }, { name: "merger-ipo-acquisition-news" }, { name: "webtraffic_trend" }, { name: "headcount_trend" }, { name: "expansion_news" }] },
                    ],
                },
                {
                    name: "Aggregated Engagement",
                    children: [
                        { name: "Identified Interaction Metrics", children: [{ name: "Email open rates" }, { name: "Sales call outcomes" }] },
                        { name: "Anonymous Activity Metrics", children: [{ name: "Ad impressions" }, { name: "web_traffic" }, { name: "Website traffic" }] },
                    ],
                },
                {
                    name: "Scores",
                    children: [
                        { name: "Interaction Assessment", children: [{ name: "Engagement score" }, { name: "NPS" }] },
                        { name: "Likelihood Evaluation", children: [{ name: "Churn risk" }, { name: "Propensity to buy" }] },
                        { name: "Economic Stability Rating", children: [{ name: "Credit score" }, { name: "Financial health index" }] },
                    ],
                },
                {
                    name: "Relevance",
                    children: [
                        { name: "Target Market Alignment", children: [{ name: "ICP match percentage" }, { name: "TAM fit score" }] },
                        { name: "Strategic Account Classification", children: [{ name: "At-risk" }, { name: "Growth potential" }, { name: "Key account" }] },
                    ],
                },
                {
                    name: "Custom Attributes",
                    children: [{ name: "Client-Specific Indicators", children: [{ name: "Custom segmentation" }, { name: "Special project flag" }] }],
                },
                {
                    name: "Company Information",
                    children: [
                        { name: "Client-Specific Indicators", children: [{ name: "account_desc" }, { name: "account_name" }, { name: "account_size" }, { name: "account_type" }, { name: "account_url" }, { name: "accounts_duns" }, { name: "branches/location" }, { name: "business_model" }, { name: "Company" }, { name: "competitors" }, { name: "dba" }, { name: "description" }, { name: "domain_alias" }, { name: "duns_number" }, { name: "founding_year" }, { name: "name" }, { name: "other_phone" }, { name: "phone" }, { name: "url" }, { name: "years_in_business" }, { name: "company-stage" }, { name: "Funding-stage" }, { name: "account_type" }, { name: "ic_accountid" }, { name: "Partnership" }, { name: "Alliance" }, { name: "account_gicscode" }, { name: "ultimate_parent" }, { name: "subsidiary_child" }, { name: "sibling" }, { name: "parent_tree" }, { name: "majority_stake_parent" }, { name: "parent_duns" }, { name: "child_tree" }, { name: "affiliate_child" }, { name: "minority_stake_parent" }, { name: "id" }] },
                    ],
                },
                {
                    name: "Location Information",
                    children: [{ name: "Client-Specific Indicators", children: [{ name: "account_city" }, { name: "account_country" }, { name: "contact_city" }, { name: "contact_country" }, { name: "contact_countrycode" }, { name: "contact_region" }, { name: "contact_region_code" }, { name: "contact_state" }, { name: "contact_street" }, { name: "contact_zip_code" }, { name: "addresscity" }] }],
                },
                {
                    name: "Product Information",
                    children: [{ name: "Client-Specific Indicators", children: [{ name: "demo" }, { name: "eCommerce" }, { name: "free_plan" }, { name: "free_trial" }, { name: "it_products" }, { name: "it-products" }, { name: "offer" }, { name: "pricing" }, { name: "pricing change" }, { name: "Product" }, { name: "product_tour" }] }],
                },
                {
                    name: "Job Openings",
                    children: [
                        { name: "Client-Specific Indicators", children: [{ name: "job_opening_admin" }, { name: "job_opening_analytics" }, { name: "job_opening_engg" }, { name: "job_opening_it" }, { name: "job_opening_marketing" }, { name: "job_opening_sales" }, { name: "job_page" }, { name: "job-opening-admin" }, { name: "job-opening-analytics" }, { name: "job-opening-engg" }, { name: "job-opening-it" }, { name: "job-opening-marketing" }, { name: "job-opening-sales" }, { name: "recruiting_velocity" }] },
                    ],
                },
                {
                    name: "Marketing Activities",
                    children: [{ name: "Client-Specific Indicators", children: [{ name: "newsletter" }, { name: "video_content" }, { name: "abm_code" }] }],
                },
                {
                    name: "Social Media",
                    children: [{ name: "Client-Specific Indicators", children: [{ name: "fb_followers" }, { name: "fb_id" }, { name: "insta_followers" }, { name: "insta_id" }, { name: "linkedin_followers" }, { name: "linkedin_id" }, { name: "twitter_followers" }, { name: "twitter_id" }] }],
                },
                {
                    name: "Technology Trends",
                    children: [{ name: "Client-Specific Indicators", children: [{ name: "ai_first" }, { name: "generative-ai" }] }],
                },
                {
                    name: "Miscellaneous",
                    children: [{ name: "Client-Specific Indicators", children: [{ name: "certificates" }, { name: "community" }, { name: "partner" }, { name: "plg" }, { name: "latest_funding" }, { name: "latest_contract" }, { name: "contact_id" }, { name: "offer" }, { name: "vendor" }] }],
                },
            ],
        },
    ];

    const showModal = () => {
        setIsVisible(true);
    };

    const handleCancel = () => {
        setIsVisible(false);
    };

    const handleCancelTree = () => {
        setIsTreeVisible(false);
    };

    const handleSave = () => {
        console.log('Form values saved');
        setIsVisible(false);
    };
    return (
        <>
            <Modal
                title="Template Name"
                visible={isVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                width={"fit-content"}
                header={[

                ]}
                footer={[
                    <Button key="back" type="primary" onClick={handleCancel}>
                        Test Mapping
                    </Button>,
                    <Button key="submit" onClick={handleSave}>
                        + Add Field
                    </Button>,
                ]}
            >
                <Button key="submit" className='float-right mb-2 mr-4' type='primary' onClick={handleSave}>
                    Save
                </Button>
                <Table dataSource={modalData} columns={modalColumns} pagination={false} />
            </Modal>
            <Modal
                title="Explore oGraph"
                visible={isTreeVisible}
                onCancel={handleCancelTree}
                onOk={handleCancelTree}
                width={"65%"}
            >
                <div>
                    {/* <div className='flex'>
                        <span className='ml-10'>Entity</span>
                        <span className='ml-[15%]'>Category</span>
                        <span className='ml-[15%]'>Sub Category</span>
                        <span className='ml-[15%]'>Attributes</span>
                    </div> */}
                    {data.map((node) => (
                        <TreeNode key={node.name} node={node} />
                    ))}
                </div>
            </Modal>
            <div style={{ margin: '24px' }}>
                <div className='flex justify-between'>
                    <Title level={5}>
                        Enrich templates help you define the fields, frequency, and options for any enrichment you execute in the platform.
                    </Title>
                    <Space className='flex flex-col' style={{ marginBottom: '20px' }}>
                        <Button type="primary" onClick={setIsVisible} icon={<InfoCircleFilled />}>+ Enrich Template</Button>
                        <Button type="primary" onClick={setIsTreeVisible} icon={<InfoCircleFilled />}>+ Explore oGraph</Button>
                    </Space>
                </div>
                <Table
                    className='custom-table'
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />

            </div>
        </>
    );
};


export default EnrichTemplates;
