import React, { useState, useEffect } from 'react';
import { Button, Input, Card, Progress, Table } from 'antd';
import {
    SalesforceOutlined,
    HubspotOutlined,
    DatabaseOutlined,
    CloudServerOutlined,
    HddOutlined,
} from '@ant-design/icons';
import Sources_part2 from './Sources_part2';
import sfimg from '../../../assets/images/salesforceImg.png';
import hubspot from '../../../assets/images/hubspot.png';
import databricks from '../../../assets/images/databricks.png';
import redshift from '../../../assets/images/redshift.png';
import bigquery from '../../../assets/images/bigquery.png';
import postgres from '../../../assets/images/postgres.png';
import mysql from '../../../assets/images/mysql.png';
import s3 from '../../../assets/images/s3.png';
import gcloud from '../../../assets/images/gcloudstorage.png';
import azurepng from '../../../assets/images/azurepng.png';
import reltio from '../../../assets/images/reltio.png';
import snowflake from '../../../assets/images/snowflake_logo.png'
import clickhouse from '../../../assets/images/clickhouse.jpg'
import rockset from '../../../assets/images/rockset.png'
import kafka from '../../../assets/images/kafka.png'
import es from '../../../assets/images/es.png'
import singlestore from '../../../assets/images/singlestore.jpg'
import athena from '../../../assets/images/athena.png'
import http from '../../../assets/images/http.png'
import trino from '../../../assets/images/trino.png'


const modelsData = [
    {
        key: '1',
        table: 'Snowflake Demo',
        description: 'This model finds the first marketing touch that influenced a sales opportunity.',
        type: 'Warehouse',
    },
];

const sourceOptions = [
    { label: 'Salesforce', logo: sfimg, value: 'a' },
    { label: 'Hubspot', logo: hubspot, value: 'b' },
    { label: 'Databricks', logo: databricks, value: 'c' },
    { label: 'Reltio', logo: reltio, value: 'k' },
    { label: 'Amazon Redshift', logo: redshift, value: 'd' },
    { label: 'Google BigQuery', logo: bigquery, value: 'e' },
    { label: 'Postgres', logo: postgres, value: 'f' },
    { label: 'MySQL', logo: mysql, value: 'g' },
    { label: 'Amazon S3', logo: s3, value: 'h' },
    { label: 'Google Cloud Storage', logo: gcloud, value: 'i' },
    { label: 'Azure Archive Storage', logo: azurepng, value: 'j' },
    { label: 'Snowflake', logo: snowflake, value: 'k' },
    { label: 'Clickhouse', logo: clickhouse, value: 'k' },
    { label: 'Rockset', logo: rockset, value: 'k' },
    { label: 'Trino', logo: trino, value: 'k' },
    { label: 'Kafka', logo: kafka, value: 'k' },
    { label: 'Elastic Search', logo: es, value: 'k' },
    { label: 'Singlestore', logo: singlestore, value: 'k' },
    // { label: 'MotherDuck', logo: snowflake, value: 'k' },
    { label: 'Amazon Athena', logo: athena, value: 'k' },
    { label: 'HTTP Request', logo: http, value: 'k' },
    // Add more options as needed
];

const Sources = () => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);

    const steps = [
        'Check network connectivity',
        `Validate ${selectedSource?.label} credentials`,
        'Verify permission to list schemas and tables',
        'Verify permission to write to planner schema',
        'Verify permission to write to audit schema',
    ];

    useEffect(() => {
        if (isConnecting && currentStep < steps.length) {
            const timer = setTimeout(() => {
                setCurrentStep((prevStep) => prevStep + 1);
            }, 2000);
            return () => clearTimeout(timer);
        } else if (currentStep === steps.length) {
            setIsCompleted(true);
        }
    }, [isConnecting, currentStep]);

    const handleModelClick = (model) => {
        setSelectedModel(model);
    };

    const handleOpenSourceSelection = () => {
        setIsModalOpen(true);
    };

    const handleSourceCardClick = (source) => {
        setSelectedSource(source);
    };

    const handleConnect = () => {
        setIsConnecting(true);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'table',
            key: 'table',
            render: (text, record) => (
                <Button type="link" onClick={() => handleModelClick(record)}>
                    {text}
                </Button>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
    ];
    const onBack = () => {
        setSelectedModel(null)
        isModalOpen(false)
    }
    return (
        <div>
            {!selectedModel && !isModalOpen ? (
                <>
                    <div className='flex justify-between mb-2'>
                        <div className='flex justify-center gap-4'>
                            <Input placeholder={"Search"} className='w-[65%]' />
                            <Button>Type</Button>
                            <Button type="primary" onClick={handleOpenSourceSelection}>Add Source</Button>
                        </div>
                    </div>
                    <Table
                        dataSource={modelsData}
                        columns={columns}
                        pagination={false}
                    />
                </>
            ) : isModalOpen ? (
                <>
                    {selectedSource ? (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                <div className='justify-start items-center' style={{ display: 'flex', marginBottom: '20px' }}>
                                    <img src={selectedSource.logo} alt={selectedSource.label} style={{ width: 40, height: 40, marginRight: 8 }} />
                                    <span className='text-[25px]'>{'Connect to ' + selectedSource.label}</span>
                                </div>
                                {isConnecting ? (
                                    <>
                                        {steps.map((step, index) => (
                                            <div key={index} style={{ marginBottom: 10 }}>
                                                <Progress
                                                    percent={index < currentStep ? 100 : 0}
                                                    showInfo={false}
                                                    status={index < currentStep ? 'success' : 'normal'}
                                                />
                                                <span style={{ marginLeft: 10 }}>{step}</span>
                                            </div>
                                        ))}
                                        {isCompleted && (
                                            <Button type='primary' onClick={() => setIsModalOpen(false)}>Completed</Button>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div style={{ width: '80%', marginBottom: '20px' }}>
                                            <h3 className='text-[grey]'>Step 1</h3>
                                            <p><strong>Configure your credentials</strong></p>
                                            <Input placeholder="Credentials" className="mb-4 mt-2" />
                                            <Button>Refresh</Button>
                                            <Button type="primary" style={{ marginLeft: '10px' }}>New credentials</Button>
                                        </div>
                                        <div style={{ width: '80%' }}>
                                            <h3 className='text-[grey]'>Step 2</h3>
                                            <p className='mb-4'><strong>Configure your {selectedSource.label} source</strong></p>
                                            <h3 style={{ fontWeight: 600 }}>Project</h3>
                                            <p className='text-[12px] text-[grey]'>This is the unique identifier for the Google Cloud project to which your {selectedSource.label} data warehouse belongs. The project ID can be found in the Google Cloud web console by clicking on your project name in the top navigation bar or by visiting the project settings page. Visit our docs for details.</p>
                                            <Input placeholder="Project ID" className="mb-4 mt-2" />
                                            <h3 style={{ fontWeight: 600 }}>Dataset location</h3>
                                            <p className='text-[12px] text-[grey]'>This is the geographic location of your {selectedSource.label} dataset(s). It can be found by querying the INFORMATION_SCHEMA.SCHEMATA view or by visiting the Google Cloud web console and clicking on a {selectedSource.label} dataset in the Explorer panel. For details, see {selectedSource.label} docs.</p>
                                            <Input placeholder="Dataset location" className="mb-4 mt-2" />
                                        </div>
                                        <div style={{ width: '80%' }}>
                                            <h3 className='text-[grey]'>Step 3</h3>
                                            <p className='mb-4'><strong>Choose your sync engine</strong></p>
                                            <h3 style={{ fontWeight: 600 }}>Lightning sync engine</h3>
                                            <p className='text-[12px] text-[grey]'>This option provides optimal performance for {selectedSource.label} and other enhanced platform features.</p>
                                            <Input placeholder="Lightning sync engine configuration" className="mb-4 mt-2" />
                                        </div>
                                        <div className='flex justify-end gap-4 my-2'>
                                            <Button onClick={() => setSelectedSource(null)}>Cancel</Button>
                                            <Button type='primary' onClick={handleConnect}>Connect</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="grid grid-cols-3 gap-4">
                                {sourceOptions.map((source) => (
                                    <Card
                                        key={source.value}
                                        onClick={() => handleSourceCardClick(source)}
                                        hoverable
                                        style={{ textAlign: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center', gap: 2, backgroundColor: '#f3f3f3' }}
                                    >
                                        <div className='flex justify-center items-center'>
                                            <img src={source.logo} alt={source.label} style={{ width: 40, height: 40, marginRight: 8 }} />
                                            <span className='font-bold text-black text-[15px]'>{source.label}</span>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Sources_part2 source={selectedModel} onBack={onBack} />
                </>
            )}
        </div>
    );
};

export default Sources;
