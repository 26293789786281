import React from 'react';

const NestedList = ({ data }) => {
  return (
    <div className='w-full'>
        <p className='font-bold ml-2 mt-2 mb-0.5'>
            {data[0]?.name?.includes("Industry") ? "Criteria:" : "Demographic Criteria:"}
        </p>
        <ul className='list-decimal ml-8 w-full'>
        {data.map((item, index) => (
            <>
                {item?.name?.includes("Website Engagement") && <p className='-ml-6 font-bold mt-4 mb-0.5'>Behavioral Criteria:</p>}
                <ListItem key={index} item={item} />
            </>
        ))}
        </ul>
    </div>
  );
};

const ListItem = ({ item }) => {
  return (
    <li className='mt-1 w-full'>
      {item.name}
      {item.subItems && item.subItems.length > 0 && (
        <ul className='list-disc ml-4 w-full'>
          {item.subItems.map((subItem, subIndex) => (
            <li key={subIndex} className={subIndex === 0 && 'mt-1'}>
                <div className='flex justify-between items-center gap-6 w-full'>
                    <p>{subItem?.name}</p>
                    <p className=''>{subItem?.points} points</p>
                </div>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default NestedList;
