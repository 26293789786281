import { useEffect, useState } from "react";
import { Client } from "../services/Client";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authUserAtom } from "../store/atoms/AuthUserAtoms";
import { Spin } from "antd";

const ProtectedRoute = ({ children }) => {

    const [loading, setLoading] = useState(true);
    const [authUser, setAuthUser] = useRecoilState(authUserAtom);

    const navigate = useNavigate();

    const fetchAuthUser = async () => {
        try {
            const { data } = await Client.get("/auth");

            if (!data?.data) {
                localStorage.removeItem('loginToken');
                navigate('/login');
            }
            const authData = data?.data;

            if (authData?.organisation_id) {
                navigate('/workspaces');
            } else {
                navigate('/onboarding');
            }
            setAuthUser(authData);
        } catch (err) {
            console.error(err);
            localStorage.removeItem('loginToken');
            navigate('/login');
        }
    }

    useEffect(() => {
        if (!authUser) {
            fetchAuthUser();
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return (
            <div className="w-screen h-screen flex justify-center items-center">
                <Spin size="large" />
            </div>
        )
    }

    return children;

}

export default ProtectedRoute;