import React from 'react';
import avatar from "../../../../assets/images/user.png";
import { useRecoilState } from 'recoil';
import { chatModeAtom } from '../../../../store/atoms/MainAtoms';

const AssistantQuestion = ({ chat }) => {
    const [chatMode, setChatMode] = useRecoilState(chatModeAtom);

    return (
        <div
            className={`flex justify-start items-center ${chatMode == 1 ? "ml-2 gap-2 p-1" : "gap-4 p-2"}`}
        >
            <img
                src={
                    localStorage.getItem("profile_url")
                        ? localStorage.getItem("profile_url").toString()
                        : avatar
                }
                alt="user-logo"
                width={chatMode == 1 ? 20 : 30}
                className="rounded-full ml-2 self-start mt-[10px]"
            />
            <div className={`text-start text-[14px] w-[90%] ${chatMode == 1 ? "p-2" : "p-4"} rounded-md relative text-black`}>
                {chat?.question}
            </div>
        </div>
    );
};

export default AssistantQuestion;
