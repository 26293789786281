import React, { useEffect, useRef, useState } from "react";
import { PlusCircleOutlined, ArrowUpOutlined, FileExcelTwoTone, FilePdfTwoTone, ArrowRightOutlined, StarFilled, PaperClipOutlined } from "@ant-design/icons";
import { Input, message, Modal, Card, Button } from "antd";
import { jwtDecode } from "jwt-decode";
import { getGenericResponse, asyncData, uploadToS3, csvDataInDB, asyncDataVanna } from "../../services/chatServices";
import { chatModeAtom } from "../../store/atoms/MainAtoms";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  copilotRowsAtom,
  copilotInputFilledAtom,
  copilotQuestionAtom,
  copilotChatArrayAtom,
  copilotLoadingAtom,
  isFollowupClickedAtom,
  attachedFileAtom,
  isFileAttachedAtom,
  copilotFileLoadingAtom,
  cc_tableColsAtom,
  cc_tableDataAtom,
  cc_modalAtom,
  isProcessingAtom,
  talkToCSVAtom
} from "../../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Papa from "papaparse";
import HeaderMappingModal from "../ChatWindowInitial/HeaderMappingModal";
import * as XLSX from 'xlsx'
import _ from 'lodash'

const { TextArea } = Input;

const InputBox = ({ siderWidth }) => {
  let userId
  const setTalkToCSV = useSetRecoilState(talkToCSVAtom);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const { pathname } = useLocation();
  const [pocTableData, setPocTableData] = useRecoilState(cc_tableDataAtom)
  const [pocTableCols, setPocTableCols] = useRecoilState(cc_tableColsAtom)
  const fileInputRef = useRef(null);
  const [rows, setRows] = useRecoilState(copilotRowsAtom);
  const [inputFilled, setInputFilled] = useRecoilState(copilotInputFilledAtom);
  const [question, setQuestion] = useRecoilState(copilotQuestionAtom);
  const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
  const [loading, setLoading] = useRecoilState(copilotLoadingAtom);
  const [isFollowupClicked, setIsFollowupClicked] = useRecoilState(isFollowupClickedAtom);
  const chatMode = useRecoilValue(chatModeAtom)
  const [attachedFile, setAttachedFile] = useRecoilState(attachedFileAtom);
  const [isFileAttached, setIsFileAttached] = useRecoilState(isFileAttachedAtom);
  const [fileLoading, setFileLoading] = useRecoilState(copilotFileLoadingAtom);
  const setModal = useSetRecoilState(cc_modalAtom);
  const [attachModalVisible, setattachModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useRecoilState(isProcessingAtom);
  const [modalVisible, setModalVisible] = useState(false);
  const [fileMetaInformation, setFileMetaInformation] = useState({})
  const [fileInformation, setFileInformation] = useState({})
  useEffect(() => {
    const token = localStorage.getItem('loginToken');
    setAttachedFile(null);
    setTalkToCSV(null);
    localStorage.removeItem("talkToCSV");
    let decodedToken;
    token ? decodedToken = jwtDecode(token.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')) : decodedToken = null;
    userId = decodedToken.id;
    setModalVisible(false)
  }, []);
  const handleInputChange = (e) => {
    if (e.target.value.trim() === "") {
      setInputFilled(false);
    } else {
      setInputFilled(true);
    }
    const lines = e.target.value.split("\n").length;
    const newRows = Math.min(lines, 5);
    setRows(newRows || 1);
    setQuestion(e.target.value);
  };

  const readExcel = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: "array" })
        // console.log(workbook.SheetNames, "workbook.SheetNames")

        const sheetName = workbook.SheetNames[0]
        // console.log(workbook.Sheets, "workbook.Sheets")
        const sheet = workbook.Sheets[sheetName]
        const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
        resolve(excelData)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsArrayBuffer(file)
    })
  }
  const handleDataOps = async (file) => {
    // setLoading(true)
    // setChatArray(prevChatArray => [
    //   ...prevChatArray,
    //   {
    //     question: `You've uploaded ${fileName}`,
    //     suggestions: []
    //   }
    // ])
    try {
      if (file) {
        // console.log(file)

        // -------------------------- File Handling Starts -------------------------------------
        const excelData = await readExcel(file)
        // console.log(excelData)
        let validData = []
        // validData = All the data before we encounter an empty row in the uploaded file

        for (let i = 0; i < excelData?.length; i++) {
          if (excelData[i]?.length === 0) {
            break;
          } else {
            validData?.push(excelData[i])
          }
        }
        // console.log(validData, "validData------------------")
        // setActualFileData(validData)
        const columnarData = _.zip(...validData)
        // columnarData = Transformed data from validData, that is, all rows are transformed to columns & all columns are transformed to rows. Console log both these values to better understand this

        // console.log(columnarData)
        const fields = columnarData?.length
        const records = columnarData[0]?.length - 1 // First entry of each row is the header

        // const totalCells = records * fields
        // console.log("No. of Fields ", fields)
        // console.log("No. of Records ", records)
        // console.log("No. of Cells ", totalCells)
        const metaInfo = {
          "No. of Fields": fields,
          "No. of Records": records
        } // no. of fields, records to give to fileMetaInformation state
        setFileMetaInformation(metaInfo)
        // console.log(metaInfo, "metaInfo--------------")

        let emptyTracker = {} // "Field Name" (mapped to) No. of empty values in this field/column
        let missingColNameCount = 1 // Handling for Empty Column Header Names => "Un-Named Column 1"
        for (let i = 0; i < columnarData?.length; i++) {
          const rowData = columnarData[i]
          let emptyCount = 0;
          for (let j = 1; j < rowData?.length; j++) {
            const cellData = rowData[j]
            if (!cellData || cellData?.toString()?.cellData?.trim() === "") {
              emptyCount++
            }
          }
          let percentageEmptyValues = 100 // For cases where just the column headers are defined & there are no records
          if (records !== 0) { // Dividing by 0 results in NaN
            percentageEmptyValues = emptyCount / records * 100
          }
          let columnName = rowData[0]
          if (!columnName || columnName?.toString()?.trim() === "") { // Handle case when field name is empty
            columnName = 'Un-Named Column ' + missingColNameCount
            missingColNameCount++
          }
          emptyTracker[columnName] = parseFloat(percentageEmptyValues.toFixed(2)) // Rounding off to 2 decimal places & converting to float value instead of string value
        }
        // console.log(emptyTracker, "emptyTracker--------------------")
        setFileInformation(emptyTracker)
        // -------------------------- File Handling Ends -------------------------------------

        // setIsProcessing(true);
        // setInputFilled(true)
        // setTimeout(() => {
        //   setIsProcessing(false);
        //   setAttachedFile(file);
        // }, 2000);
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (fileMetaInformation && fileMetaInformation["No. of Fields"]) {
      generateAnswerForFileUpload()
    }
  }, [fileMetaInformation])

  const generateAnswerForFileUpload = (check) => {
    const initialLine = (check === "check") ? "Here is the summary after cleaning and deduplicating:" : "I am analysing the file you have uploaded:"

    // fileMetaInformation contains no. of columns & records
    // fileInformation contains % of missing values in each column

    const fieldsInformation = `1. File has ${fileMetaInformation["No. of Fields"]} columns`
    const recordsInformation = `2. There are ${fileMetaInformation["No. of Records"]} records`
    // const entriesInformation = `3. In total, there are ${fileMetaInformation["No. of Entries"]} entries`

    // Output earlier --------------------------->
    // I am analysing the file you have uploaded:
    // 1. File has 14 columns
    // 2. There are 28 records
    // 3. "SDR" column has no missing values
    // 4. "Time" column has no missing values
    // 5. "Location" column has 78.57% missing values
    // 6. "Rep Needed" column has no missing values
    // 7. "email" column has no missing values
    // 8. "Brand" column has no missing values
    // 9. "Person to meet with Name" column has no missing values
    // 10. "Title" column has no missing values
    // 11. "Cell" column has 3.57% missing values
    // 12. "Opp yes/ no?" column has no missing values
    // 13. "In SFDC?" column has no missing values
    // 14. "Quick Notes from SDR:" column has 85.71% missing values
    // 15. "Have we connected on eTail? y/n" column has 100% missing values
    // 16. "Notes from AE" column has 100% missing values

    // Handled case to show all columns with same percentage of missing values

    const matchingEmptyFields = {}
    for (const [key, value] of Object.entries(fileInformation)) {
      if (!matchingEmptyFields[value]) {
        matchingEmptyFields[value] = []
      }
      matchingEmptyFields[value].push(key)
    }
    // console.log(matchingEmptyFields)

    let rowCount = 3 // Contains count for each new line
    const fieldInfo = Object.entries(matchingEmptyFields)?.map(([key, value]) => {
      const lengthOfJoinedColumns = value?.length
      const joinedColumnNames = value?.join(`", "`)
      const info = `${rowCount}. "${joinedColumnNames}" column${lengthOfJoinedColumns > 1 ? 's have' : ' has'} ${key === '0' ? 'no' : key + '%'} missing values`
      rowCount++
      return info
    })?.join("\n")

    // Output After MatchingEmptyFields --------------------------->
    // I am analysing the file you have uploaded:
    // 1. File has 14 columns
    // 2. There are 28 records
    // 3. "SDR", "Time", "Rep Needed", "email", "Brand", "Person to meet with Name", "Title", "Opp yes/ no?", "In SFDC?" columns have no missing values
    // 4. "Have we connected on eTail? y/n", "Notes from AE" columns have 100% missing values
    // 5. "Location" column has 78.57% missing values
    // 6. "Cell" column has 3.57% missing values
    // 7. "Quick Notes from SDR:" column has 85.71% missing values

    // const duplicates = `${rowCount}. ${(check === "check") ? "There are no duplicates left" : "There are 12% duplicate rows in the file"}`
    rowCount += 1

    const extra = `${(check === "check") ? `${rowCount}. We found out there are 20 new contacts based on emails & 10 new contacts based on URL` : ""}`

    const answer = initialLine + "\n" + fieldsInformation + "\n" + recordsInformation + "\n" + fieldInfo + "\n" + extra
    // console.log(answer, "answer------------------")
    setChatArray(prevChat => {
      const lastIndex = prevChat?.length - 1;
      return [
        ...prevChat?.slice(0, lastIndex),
        {
          ...prevChat[lastIndex],
          answer,
          file: true,
          postgres_query: `SELECT * FROM fileupload.${localStorage.getItem("tableName")}`
        }
      ]
    })
    // if(fileMetaInformation["No. of Fields"] != undefined){
    //   addSessionOfFile(answer)
    // }
    // const answer = `I am analysing the file you have uploaded:\n1. File has 5 columns\n2. company_name column represent name of companies and has no missing values\n3. email column represents emails of individual, it is a PII filed and has 20% missing values\n4. website column represents URL's with 10% missing values\n5. contact_name represents name of individuals with no missing values\n6. company_country represents country with no missing values.\n7. There are 12% duplicate rows in the file`
    return answer
  }

  const handleSend = async () => {
    const chat_id = uuidv4()
    if (isFileAttached && inputFilled && pathname == "/cc-demo") {
      setChatArray(prevChatArray => (
        [
          ...prevChatArray,
          {
            question: question,
            fileRunning: true,
            answer: "Certainly let me analyze the CSV for you.",
            chartMessage: "Please click on the expand button on the table so we can enrich the data in the table editor."
          }
        ]
      ))
      handleUpload(chat_id)
      setIsFileAttached(false)
      setFileLoading(true)
      setQuestion(null)
      setInputFilled(false)
    } else if (attachedFile && isFileAttached && (pathname == "/copilot" || pathname == "/assistant")) {
      handleDataOps(attachedFile)
      handleUpload(chat_id)
      setChatArray([
        {
          question: "You've uploaded " + attachedFile?.name
        }
      ]);
    }
    else if (inputFilled) {
      setLoading(true)
      setInputFilled(false)
      const lastQuestion = chatArray.length > 0 ? chatArray[chatArray.length - 1]?.question : null;
      const lastAnswer = chatArray.length > 0 ? chatArray[chatArray.length - 1]?.answer : null;
      if (lastQuestion === "Expand the table" && question.toLowerCase().includes("yes")) {
        setChatArray(prevChatArray => (
          [
            ...prevChatArray,
            {
              question,
              answer: "Cleaning and normalizing the data.",
              pocRef: true
            }
          ]
        ))
        setTimeout(() => {
          setChatArray(prevChatArray => (
            [
              ...prevChatArray,
              {
                // question: question,
                answer: "Cleaning and normalizing complete.",
                pocRef: true
              }
            ]
          ))
          setLoading(false)
          setPocTableData([{
            'key': '1',
            'event_name': 'Regular Webinar',
            'date': '6/15/2024',
            'attendee_email': 'annev@email.sc.edu',
            'first_name': 'Anne',
            'last_name': '',
            'country': 'usa'
          },
          {
            'key': '2',
            'event_name': 'Regular Webinar',
            'date': '6/16/2024',
            'attendee_email': 'jonpjonesmts57@gmail.com',
            'first_name': 'Jonathon',
            'last_name': '',
            'country': ''
          },
          {
            'key': '3',
            'event_name': 'Regular Webinar',
            'date': '6/16/2024',
            'attendee_email': 'tayrolle77@gmail.com',
            'first_name': 'Shawnta',
            'last_name': '',
            'country': 'united States of America'
          },
          {
            'key': '4',
            'event_name': 'Regular Webinar',
            'date': '6/18/2024',
            'attendee_email': 'shannon@gmail.com',
            'first_name': 'Shannon',
            'last_name': '',
            'country': ''
          },
          {
            'key': '5',
            'event_name': 'Regular Webinar',
            'date': '6/19/2024',
            'attendee_email': 'taylorcouch19@gmail.com',
            'first_name': 'Taylor',
            'last_name': '',
            'country': ''
          },
          {
            'key': '6',
            'event_name': 'Regular Webinar',
            'date': '20/06/2024',
            'attendee_email': 'llgarcia_1@yahoo.com',
            'first_name': 'Lydia',
            'last_name': 'Garcia',
            'country': ''
          },
          {
            'key': '7',
            'event_name': 'Regular Webinar',
            'date': '6/21/2024',
            'attendee_email': 'momsadams@hotmail.com',
            'first_name': " ",
            'last_name': 'Adams',
            'country': ''
          },
          {
            'key': '8',
            'event_name': 'Regular Webinar',
            'date': '6/22/2024',
            'attendee_email': 'dgonzales@valleywater.org',
            'first_name': " ",
            'last_name': 'Gonzales',
            'country': ''
          },
          {
            'key': '9',
            'event_name': 'On-Demand Webinar',
            'date': '6/23/2024',
            'attendee_email': 'alexisjames0428@gmail.com',
            'first_name': " ",
            'last_name': 'James',
            'country': ''
          },
          {
            'key': '10',
            'event_name': 'On-Demand Webinar',
            'date': '6/24/2024',
            'attendee_email': 'hdgirl1@aol.com',
            'first_name': " ",
            'last_name': 'Heisey',
            'country': ''
          },
          {
            'key': '11',
            'event_name': 'On-Demand Webinar',
            'date': '6/25/2024',
            'attendee_email': 'nathan.petrucci@gmail.com',
            'first_name': " ",
            'last_name': 'Petrucci',
            'country': ''
          },
          {
            'key': '12',
            'event_name': 'On-Demand Webinar',
            'date': '6/26/2024',
            'attendee_email': 'meredith.needham14@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '13',
            'event_name': 'On-Demand Webinar',
            'date': '6/26/2024',
            'attendee_email': 'jenna.dallow24@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '14',
            'event_name': 'On-Demand Webinar',
            'date': '6/28/2024',
            'attendee_email': 'tgoldy92@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '15',
            'event_name': 'On-Demand Webinar',
            'date': '6/29/2024',
            'attendee_email': 'maura.johansson@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '16',
            'event_name': 'Workshop',
            'date': '6/15/2024',
            'attendee_email': 'melchiorib916@yahoo.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '17',
            'event_name': 'Workshop',
            'date': '6/16/2024',
            'attendee_email': 'matt.brian.harrison@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': 'uk'
          },
          {
            'key': '18',
            'event_name': 'Workshop',
            'date': '6/16/2024',
            'attendee_email': 'desertortoise@yahoo.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '19',
            'event_name': 'Workshop',
            'date': '6/18/2024',
            'attendee_email': 'haydengriffin1325@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '20',
            'event_name': 'Workshop',
            'date': '6/19/2024',
            'attendee_email': 'juliaschildwachter@yahoo.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '21',
            'event_name': 'Workshop',
            'date': '6/20/2024',
            'attendee_email': 'gisela2683@hotmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '22',
            'event_name': 'Workshop',
            'date': '6/21/2024',
            'attendee_email': 'thochatown@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '23',
            'event_name': 'Partner Event',
            'date': '6/22/2024',
            'attendee_email': 'timtamcole@hotmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          }, {
            'key': '24',
            'event_name': 'Partner Event',
            'date': '6/23/2024',
            'attendee_email': 'victoriadb1999@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '25',
            'event_name': 'Partner Event',
            'date': '6/24/2024',
            'attendee_email': 'taylorhetherington2002@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '26',
            'event_name': 'Partner Event',
            'date': '6/25/2024',
            'attendee_email': 'ritzam@aol.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '27',
            'event_name': 'Partner Event',
            'date': '6/26/2024',
            'attendee_email': 'fdubaisy@hotmail.com',
            'first_name': " ",
            'last_name': '',
            'country': 'Saudi'
          },
          {
            'key': '28',
            'event_name': 'Partner Event',
            'date': '6/26/2024',
            'attendee_email': 'arianmansur@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          },
          {
            'key': '29',
            'event_name': 'Partner Event',
            'date': '6/28/2024',
            'attendee_email': 'dillidinesh005@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': 'Tamil Nadu'
          },
          {
            'key': '30',
            'event_name': 'Partner Event',
            'date': '6/29/2024',
            'attendee_email': 'gismay85@gmail.com',
            'first_name': " ",
            'last_name': '',
            'country': ''
          }])
        }, 3000);
        setTimeout(() => {
          setChatArray(prevChatArray => [
            ...prevChatArray,
            {
              answer: "Now that we have cleaned & normalized the data, shall we start with enriching missing fields?",
              pocRef: true
            }
          ])
        }, 5000)
        setQuestion(null)
      } else if (lastAnswer === "Now that we have cleaned & normalized the data, shall we start with enriching missing fields?" && question.toLowerCase().includes("yes")) {
        setChatArray(prevChatArray => (
          [
            ...prevChatArray,
            {
              question,
              answer: "Enriching missing fields in CSV with matched contacts",
              pocRef: true
            }
          ]
        ))
        setTimeout(() => {
          setChatArray(prevChatArray => (
            [
              ...prevChatArray,
              {
                // question: question,
                answer: "Missing fields have been enriched. Is there anything else I can help you with?",
                csvBtns: ["Tag Contacts", "Add New Fields", "Append to Campaign", "Research Unmatched Contacts"]
              },
            ]
          ))
          setLoading(false)
          setPocTableData([{
            'key': '1',
            'event_name': 'Regular Webinar',
            'date': '6/15/2024',
            'attendee_email': 'annev@email.sc.edu',
            'first_name': 'Anne',
            'last_name': 'Ventrone',
            'country': 'United States of America'
          },
          {
            'key': '2',
            'event_name': 'Regular Webinar',
            'date': '6/16/2024',
            'attendee_email': 'jonpjonesmts57@gmail.com',
            'first_name': 'Jonathon',
            'last_name': 'Jones',
            'country': 'United States of America'
          },
          {
            'key': '3',
            'event_name': 'Regular Webinar',
            'date': '6/16/2024',
            'attendee_email': 'tayrolle77@gmail.com',
            'first_name': 'Shawnta',
            'last_name': 'Rolle',
            'country': 'United States of America'
          },
          {
            'key': '4',
            'event_name': 'Regular Webinar',
            'date': '6/18/2024',
            'attendee_email': 'shannon@gmail.com',
            'first_name': 'Shannon',
            'last_name': 'Koch',
            'country': 'United States of America'
          },
          {
            'key': '5',
            'event_name': 'Regular Webinar',
            'date': '6/19/2024',
            'attendee_email': 'taylorcouch19@gmail.com',
            'first_name': 'Taylor',
            'last_name': 'Couch',
            'country': 'United States of America'
          },
          {
            'key': '6',
            'event_name': 'Regular Webinar',
            'date': '20/06/2024',
            'attendee_email': 'llgarcia_1@yahoo.com',
            'first_name': 'Lydia',
            'last_name': 'Garcia',
            'country': 'United States of America'
          },
          {
            'key': '7',
            'event_name': 'Regular Webinar',
            'date': '6/21/2024',
            'attendee_email': 'momsadams@hotmail.com',
            'first_name': "Sue",
            'last_name': 'Adams',
            'country': 'United States of America'
          },
          {
            'key': '8',
            'event_name': 'Regular Webinar',
            'date': '6/22/2024',
            'attendee_email': 'dgonzales@valleywater.org',
            'first_name': "David",
            'last_name': 'Gonzales',
            'country': 'United States of America'
          },
          {
            'key': '9',
            'event_name': 'On-Demand Webinar',
            'date': '6/23/2024',
            'attendee_email': 'alexisjames0428@gmail.com',
            'first_name': "Alexis",
            'last_name': 'James',
            'country': 'United States of America'
          },
          {
            'key': '10',
            'event_name': 'On-Demand Webinar',
            'date': '6/24/2024',
            'attendee_email': 'hdgirl1@aol.com',
            'first_name': "Adrienne",
            'last_name': 'Heisey',
            'country': 'United States of America'
          },
          {
            'key': '11',
            'event_name': 'On-Demand Webinar',
            'date': '6/25/2024',
            'attendee_email': 'nathan.petrucci@gmail.com',
            'first_name': "Nathan",
            'last_name': 'Petrucci',
            'country': 'United States of America'
          },
          {
            'key': '12',
            'event_name': 'On-Demand Webinar',
            'date': '6/26/2024',
            'attendee_email': 'meredith.needham14@gmail.com',
            'first_name': "Meredith",
            'last_name': 'Needham',
            'country': 'United States of America'
          },
          {
            'key': '13',
            'event_name': 'On-Demand Webinar',
            'date': '6/26/2024',
            'attendee_email': 'jenna.dallow24@gmail.com',
            'first_name': "Jenna",
            'last_name': 'Dallow',
            'country': 'United States of America'
          },
          {
            'key': '14',
            'event_name': 'On-Demand Webinar',
            'date': '6/28/2024',
            'attendee_email': 'tgoldy92@gmail.com',
            'first_name': "Tyler",
            'last_name': 'Goldy',
            'country': 'United States of America'
          },
          {
            'key': '15',
            'event_name': 'On-Demand Webinar',
            'date': '6/29/2024',
            'attendee_email': 'maura.johansson@gmail.com',
            'first_name': "Maura",
            'last_name': 'Johansson',
            'country': 'United States of America'
          },
          {
            'key': '16',
            'event_name': 'Workshop',
            'date': '6/15/2024',
            'attendee_email': 'melchiorib916@yahoo.com',
            'first_name': "Daniel",
            'last_name': 'Melchior',
            'country': 'United States of America'
          },
          {
            'key': '17',
            'event_name': 'Workshop',
            'date': '6/16/2024',
            'attendee_email': 'matt.brian.harrison@gmail.com',
            'first_name': "Matthew",
            'last_name': 'Brian',
            'country': 'United Kingdom'
          },
          {
            'key': '18',
            'event_name': 'Workshop',
            'date': '6/16/2024',
            'attendee_email': 'desertortoise@yahoo.com',
            'first_name': "Gaylene",
            'last_name': 'Pheysey',
            'country': 'United States of America'
          },
          {
            'key': '19',
            'event_name': 'Workshop',
            'date': '6/18/2024',
            'attendee_email': 'haydengriffin1325@gmail.com',
            'first_name': "Hayden",
            'last_name': 'Rumfield',
            'country': 'United States of America'
          },
          {
            'key': '20',
            'event_name': 'Workshop',
            'date': '6/19/2024',
            'attendee_email': 'juliaschildwachter@yahoo.com',
            'first_name': "Julia",
            'last_name': 'Schildwachter',
            'country': 'United States of America'
          },
          {
            'key': '21',
            'event_name': 'Workshop',
            'date': '6/20/2024',
            'attendee_email': 'gisela2683@hotmail.com',
            'first_name': "Marlen",
            'last_name': 'Londono',
            'country': 'United States of America'
          },
          {
            'key': '22',
            'event_name': 'Workshop',
            'date': '6/21/2024',
            'attendee_email': 'thochatown@gmail.com',
            'first_name': "Terri",
            'last_name': 'Fullenwider',
            'country': 'United States of America'
          },
          {
            'key': '23',
            'event_name': 'Partner Event',
            'date': '6/22/2024',
            'attendee_email': 'timtamcole@hotmail.com',
            'first_name': "Tammy",
            'last_name': 'Cole',
            'country': 'United States of America'
          }, {
            'key': '24',
            'event_name': 'Partner Event',
            'date': '6/23/2024',
            'attendee_email': 'victoriadb1999@gmail.com',
            'first_name': "Victoria",
            'last_name': 'Baker',
            'country': 'United States of America'
          },
          {
            'key': '25',
            'event_name': 'Partner Event',
            'date': '6/24/2024',
            'attendee_email': 'taylorhetherington2002@gmail.com',
            'first_name': "Taylor",
            'last_name': 'Hetherington',
            'country': 'United States of America'
          },
          {
            'key': '26',
            'event_name': 'Partner Event',
            'date': '6/25/2024',
            'attendee_email': 'ritzam@aol.com',
            'first_name': "Ritza",
            'last_name': 'Melendez',
            'country': 'United States of America'
          },
          {
            'key': '27',
            'event_name': 'Partner Event',
            'date': '6/26/2024',
            'attendee_email': 'fdubaisy@hotmail.com',
            'first_name': "Feras",
            'last_name': 'Aldubaisy',
            'country': 'Saudi Arabia'
          },
          {
            'key': '28',
            'event_name': 'Partner Event',
            'date': '6/26/2024',
            'attendee_email': 'arianmansur@gmail.com',
            'first_name': "Arian",
            'last_name': 'Mansur',
            'country': 'United States of America'
          },
          {
            'key': '29',
            'event_name': 'Partner Event',
            'date': '6/28/2024',
            'attendee_email': 'dillidinesh005@gmail.com',
            'first_name': "Dinesh",
            'last_name': 'Kumar',
            'country': 'India'
          },
          {
            'key': '30',
            'event_name': 'Partner Event',
            'date': '6/29/2024',
            'attendee_email': 'gismay85@gmail.com',
            'first_name': "Giselle",
            'last_name': 'Roman',
            'country': 'United States of America'
          }])
        }, 3000);
        setQuestion(null)
      } else if (lastAnswer === "Is this Campaign Correct?" && question.toLowerCase().includes("yes")) {
        setChatArray(prevChatArray => (
          [
            ...prevChatArray,
            {
              question,
              answer: "Pushing to Salesforce",
              pocRef: true
            }
          ]
        ))
        setQuestion(null)
        setLoading(false)
        setTimeout(() => {
          setModal(true);
        }, 1000)
      }
      else {
        setChatArray(prevChatArray => (
          [
            ...prevChatArray,
            {
              question: question
            }
          ]
        ))
        setQuestion(null)
        const orgParam = new URLSearchParams(window.location.search).get('org');
        let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
        if (orgParam) {
          const [password, orgName] = orgParam.split(' ');
          const domainPassword = process.env.REACT_APP_DOMAIN_PASSWORD;
          if (password === domainPassword) {
            organization = orgName + ".com";
          }
        }
        let table_name;
        let schema_name;
        if (localStorage.getItem("tableName")) {
          table_name = localStorage.getItem("tableName")
          schema_name = "fileupload"
        }
        else if (organization === "talkable.com") {
          table_name = "master_account_hfsf_account_deals_oppo, master_contact_hfsf_lead_contact";
          schema_name = "talkable_master";
        } else if (organization === 'zillasecurity.com') {
          table_name = "zilla_security_master_account_selected_attributes, zilla_security_master_contact_selected_attributes";
          schema_name = "zillasecurity_master";
        } else if (organization === 'icustomer.ai') {
          table_name = "account, contact";
          schema_name = "icustomer";
        }
        else {
          table_name = "master_account_hfsf_account_deals_oppo, master_contact_hfsf_lead_contact";
          schema_name = "talkable_master";
        }
        let sessionId
        if (localStorage.getItem("session_id")) {
          sessionId = localStorage.getItem("session_id")
        }
        else {
          sessionId = null
        }
        const payload = {
          session_id: sessionId,
          user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id,
          question: question,
          organization: organization,
          query_id: chat_id,
          table_name: table_name,
          schema_name: schema_name,
          llm: pathname == '/assistant' ? 'vanna' : ''
        };
        let response = await getGenericResponse(payload);
        // localStorage.removeItem("tableName")
        localStorage.setItem("session_id", response.data.session_id)
        pollAsyncData(response.data.session_id);
      }
    }
  };

  const pollAsyncData = async (sessionId) => {
    let response
    do {
      const fetchData = pathname === '/assistant' ? asyncDataVanna : asyncData;
      response = await fetchData({ session_id: sessionId });
      const { data } = response.data;

      console.log({ polldata: data })

      const codeMatch = data?.match(/```python([\s\S]*?)```/);
      const pythonCode = codeMatch ? codeMatch[1].trim() : null;

      if (data != null && response.data.status !== 'done') {
        const cleanedData = data.replace(/Currently I am trying to generate a chart based on your query and results generated. This will just take a few seconds./, 'Currently I am trying to generate a chart based on your query and results generated. This will just take a few seconds.').replace(/```python[\s\S]*?```/, '').replace(/Answer:/, '').replace(/follow-up questions: \[.*?\]/, '').trim();

        setChatArray((prevChatArray) => {
          const updatedChatArray = [...prevChatArray];
          if (updatedChatArray.length === 0) {
            updatedChatArray.push({
              answer: cleanedData,
              suggestions: [],
              // chartData: cleanedData,
            });
          } else {
            updatedChatArray[updatedChatArray.length - 1] = {
              ...updatedChatArray[updatedChatArray.length - 1],
              answer: cleanedData,
              suggestions: [],
              // chartData: cleanedData,
              // charts: pythonCode ? [...updatedChatArray[updatedChatArray.length - 1].charts, pythonCode] : updatedChatArray[updatedChatArray.length - 1].charts
            };
          }
          return updatedChatArray;
        });
      }

      if (response.data.status === 'done') {
        setLoading(false);
        setChatArray((prevChatArray) => {
          const updatedChatArray = [...prevChatArray];
          console.log("RES +> ", response.data);
          if (updatedChatArray.length > 0) {
            updatedChatArray[updatedChatArray.length - 1] = {
              ...updatedChatArray[updatedChatArray.length - 1],
              answer: response.data.cleanedData,
              suggestions: response.data.followUpQuestions ? response.data.followUpQuestions : [],
              charts: response.data.pythonCode ? [response.data.pythonCode] : true,
              chartMessage: response.data.graphAnswer,
              chartType: response.data.pythonCode ? "plotly" : null,
              query_id: response.data.query_id,
              postgres_query: response.data.postgres_query,
              topic: response.data.topic,
              newTable: response.data.newTable,
              onlySummary: response.data.onlySummary
            };
          }
          return updatedChatArray;
        });
      }
      await new Promise(resolve => setTimeout(resolve, 1000));

    } while (response.data.status !== 'done')
  };
  const handleAttachModalOpen = () => {
    setattachModalVisible(true);
  };
  const handleCloseAttachModal = () => {
    setattachModalVisible(false);
  };
  const handleModalOk = async (headerMapping) => {
    setModalVisible(false);
    if (isFileAttached) {
      Papa.parse(attachedFile, {
        header: true,
        complete: (results) => {
          const csvData = results.data;

          const newCsvData = csvData
            .filter(row => {
              // Filter out rows where all fields are null or blank spaces
              return Object.values(row).some(value => value !== null && value.trim() !== "");
            })
            .map(row => {
              const newRow = {};

              // Add all fields from the original row to newRow
              Object.keys(row).forEach(key => {
                newRow[key] = row[key];
              });

              // Update newRow with mapped fields if they are not an exact match
              Object.keys(headerMapping).forEach(standardHeader => {
                if (headerMapping[standardHeader] && headerMapping[standardHeader] !== standardHeader) {
                  newRow[standardHeader] = row[headerMapping[standardHeader]];
                  delete newRow[headerMapping[standardHeader]];
                }
              });

              return newRow;
            });

          const payload = {
            user_id: jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, '')).id,
            data: newCsvData,
            filename: attachedFile?.name.split(".")[0]
          };

          csvDataInDB(payload)
            .then(dataResponse => {
              console.log(dataResponse.data);
              localStorage.setItem("tableName", dataResponse.data.tableName)
              localStorage.setItem("session_id", dataResponse.data.sessionId)
              handleSend();
            })
            .catch(error => {
              message.error("Error sharing CSV data");
              setAttachedFile(null)
            });
          setIsFileAttached(false);
        }
      });
    }
  };
  const handlePressEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };
  // useEffect(() => {
  //   if(isFollowupClicked === true){
  //     setInputFilled(true)
  //     handleSend()
  //     setIsFollowupClicked(false)
  //   }
  // }, [isFollowupClicked]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      // If no file is selected, clear the attached file
      setIsFileAttached(false)
      setAttachedFile(null);
      return;
    }

    // Check if file size is less than 1MB
    const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
    if (file.size > maxFileSize) {
      message.error("File size should be less than 1MB");
      return;
    }
    setIsProcessing(true)
    setInputFilled(true);
    console.log(attachedFile, "attachhhhhhhhhdfsdfsfdsh");

    setTimeout(() => {
      handleCloseAttachModal()
      setIsProcessing(false);
      setIsFileAttached(true)
      setAttachedFile(file);
      fileInputRef.current.value = '';
    }, 1000);
  };
  const handleUpload = async (chat_id) => {
    if (!attachedFile) {
      message.error("No file selected for upload.");
      return;
    }
    if (!(attachedFile instanceof Blob)) {
      console.error("uploadedFile is not a Blob or File object.");
      return;
    }

    try {
      const user_id = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.id
      const currentDate = new Date().toISOString().split('T')[0];
      const currentDateTime = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
      // Pre-signed URL fetch
      const formData = new FormData();
      formData.append("file", attachedFile);

      const url = `https://vnggl7etafv24fzbk45b5r3woe0mfcgn.lambda-url.us-east-1.on.aws/upload-object?folder_name=copilotupload&user_id=${user_id}&file_name=${attachedFile?.name}${currentDateTime}`;
      const myHeaders = new Headers();
      myHeaders.append("token", "zkfhnpiehvcjdloehbkxjdk");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow"
      };
      const response = await fetch(url, requestOptions);
    } catch (error) {
      console.error("Error uploading file:", error);
      // message.error("Failed to upload file to S3.");
    }
  };
  useEffect(() => {
    if (attachedFile?.name != null && isFileAttached) {
      // handleSend();
      Papa.parse(attachedFile, {
        header: true,
        complete: async (results) => {
          const csvData = results.data;
          setCsvHeaders(Object.keys(csvData[0]));
          pathname == "/cc-demo" ? setModalVisible(false) : setModalVisible(true);
        }
      });
    }
  }, [attachedFile]);
  const handleSuggestionClick = () => {
    setInputFilled(true);
    handleSend()
  }
  const onSuggestionClick = (suggestion) => {
    console.log("Suggestion clicked:", suggestion);
    setQuestion(suggestion);
    handleSuggestionClick();
  };
  const handleModalCancel = () => {
    setModalVisible(false);
    setAttachedFile({})
    setIsFileAttached(false)
    console.log(attachedFile, "attachhhhhhhhhh");

  };
  return (
    <>
      <Modal
        title="Select File"
        visible={attachModalVisible}
        onCancel={handleCloseAttachModal}
        footer={null}
      >
        <div className='flex justify-center gap-10'>
          <label
            htmlFor="file-input"
            className="cursor-pointer flex items-center"
          >
            <Card className='mb-4'>
              <div className="flex justify-center">

                <FileExcelTwoTone className='text-[600%] cursor-pointer' />
              </div>
              <div className="flex justify-center text-[20px] mt-1" style={{ fontWeight: 600 }}>
                CSV
              </div>
              <div className="flex text-[grey] justify-center text-[10px] mt-1" style={{ fontWeight: 500 }}>
                File size should be {`<`} 1 mb
              </div>

            </Card>
          </label>
          <input
            id="file-input"
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept=".csv"
          />
          <div>
            <Card >
              <div className="flex justify-center">

                <FilePdfTwoTone className='text-[600%] cursor-pointer' />
              </div>
              <div className="flex justify-center text-[20px] mt-1" style={{ fontWeight: 600 }}>
                PDF
              </div>
              <div className="flex justify-center text-[grey] text-[10px] mt-1" style={{ fontWeight: 500 }}>
                File size should be {`<`} 100 mb
              </div>
            </Card>
            <div className="flex justify-center text-[12px] mt-1 text-[grey]" style={{ fontWeight: 600 }}>
              Coming Soon
            </div>
          </div>
        </div>
      </Modal>
      <HeaderMappingModal
        visible={modalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        csvHeaders={csvHeaders}
        maskClosable={false}
      />
      <div className={`flex items-center ${chatMode == 0 ? "fixed left-[22%] w-full" : `w-full`} bottom-[3%]`}>
        <div className={`border bg-[#E9EDF1] p-1 ${chatMode == 0 ? "w-[70%]" : ``}`} style={chatMode !== 0 ? { width: `${siderWidth}px` } : {}}>
          {chatMode == 0 && chatArray[chatArray.length - 1]?.suggestions && <>
            <div className="flex flex-wrap mt-1 justify-center items-center gap-4 w-full">
              {/* <span className="text-[12px] text-[grey] ml-2 my-2 p-1">Suggested Prompts </span> */}
              {chatArray[chatArray.length - 1]?.suggestions?.slice(0, 3).map((suggestion, index) => (
                <Button onClick={() => onSuggestionClick(suggestion)} key={index} className="px-4 py-2 mt-1 text-[#9254DE] bg-[#EFDBFF] flex items-center text-[10px] cursor-pointer transition duration-300 ease-in-out">
                  <StarFilled className="" /> {suggestion}
                </Button>
              ))}
            </div>
          </>}
          <div className={`border border-[#E9EDF1] ${chatMode == 0 ? "shadow-xl px-2 p-2" : "px-1"} flex justify-center items-center`}>
            <div className={`flex gap-2 justify-center items-center ${chatMode == 0 ? "gap-5 w-[95%]" : "w-[90%]"}`}>
              {isFileAttached &&
                <div className="flex justify-center items-center gap-2 absolute -top-6 left-1 bg-[#8EA9D0] text-white text-xs rounded p-2 shadow-md">
                  1 File Uploaded
                </div>
              }
              {pathname == "/cc-demo" ? <>
                <label htmlFor="file-input" className="cursor-pointer flex items-center">
                  <PlusCircleOutlined style={{ fontSize: chatMode == 0 ? "20px" : siderWidth > 300 ? '17px' : '12px', cursor: "pointer", color: "black" }} />
                </label>
                <input
                  id="file-input"
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept=".csv"
                />
              </> :
                <></>
                // <label className="cursor-pointer flex items-center" onClick={handleAttachModalOpen}>
                //   <PlusCircleOutlined style={{ fontSize: chatMode == 0 ? "20px" : siderWidth > 300 ? '17px' : '12px', cursor: "pointer", color: "black" }} />
                // </label>
              }
              <div className="w-full">
                <div className="flex items-center">
                  {chatMode == 0 && pathname != "/cc-demo" &&
                    <div className="mr-2">
                      <Button className="flex items-center justify-center pl-1" onClick={handleAttachModalOpen}> <PaperClipOutlined /></Button>
                    </div>
                  }
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    value={question}
                    rows={rows}
                    onChange={handleInputChange}
                    onPressEnter={handlePressEnter}
                    placeholder="Message CoPilot"
                    className={`${chatMode == 0 ? "text-xl " : "text-[14px] "} w-[100%] border laterTextArea`}
                    autoFocus
                  />
                  <div
                    className={`flex justify-center items-center gap-2 border-2 ${chatMode == 0 ? "p-2" : "p-1"} ${inputFilled ? "bg-[#F7F8FA] cursor-pointer" : "bg-white cursor-not-allowed"}`}
                    onClick={handleSend}
                  >
                    <ArrowRightOutlined style={{ color: inputFilled ? "black" : "grey" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className={`flex justify-center items-center gap-5 ${chatMode == 0 ? "" : "ml-1"} mt-0.5`}>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputBox;