import React, { useState } from 'react';
import { Card, Table, Button, Tabs, DatePicker, Avatar, Badge, Tag, Form, Input } from 'antd';
import { UserOutlined, DatabaseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/en';

const columnsData = [
    {
        key: '1',
        table: 'Amplitude_product_events',
        description: 'This model finds the first marketing touch that influenced a sales opportunity.',
        type: 'Warehouse',
    },
    {
        key: '2',
        table: 'Salesforce_account',
        description: 'This calculates a fit score on the account based on industry, employee size, and technology.',
        type: 'Warehouse',
    },
    {
        key: '3',
        table: 'Salesforce_activities',
        description: 'Looks at the most recent activity date on the account.',
        type: 'Warehouse',
    },
    {
        key: '4',
        table: 'Salesforce_campaign',
        description: 'Add a description...',
        type: 'Warehouse',
    },
    {
        key: '5',
        table: 'Salesforce_campaign_member',
        description: 'Add a description...',
        type: 'Warehouse',
    },
    {
        key: '6',
        table: 'Salesforce_contact',
        description: 'Add a description...',
        type: 'Warehouse',
    },
    {
        key: '7',
        table: 'Salesforce_opportunity',
        description: 'Add a description...',
        type: 'Warehouse',
    },
    {
        key: '8',
        table: 'Salesforce_opportunity_contact_role',
        description: 'Add a description...',
        type: 'Warehouse',
    },

];
const Connection = () => (
    <Card title="Connection details" style={{ width: 400 }}>
        <Form layout="vertical">
            <Form.Item label="BigQuery project ID">
                <Input value={"seamai-dev"} placeholder="Enter project ID" />
            </Form.Item>

            <Form.Item label="BigQuery dataset ID">
                <Input value={"prod_demo"} placeholder="Enter dataset ID" />
            </Form.Item>

            <Form.Item label="BigQuery service account JSON">
                <Input.Password value={"Test_123"} placeholder="Enter service account JSON" />
            </Form.Item>

            <Form.Item>
                <Button type="primary">Test connection</Button>
                <Button type="default" style={{ marginLeft: 8 }}>
                    Update
                </Button>
            </Form.Item>
        </Form>
    </Card>
)
const ColumnsData = () => (
    <>
        <div className='flex justify-between mb-2'>
            <div className='flex justify-center gap-4'>
                <Input placeholder={"Search"} className='w-[65%]' />
                <Button>Schema</Button>
            </div>
            <div className='flex gap-4'>
                <Button>Add Tables</Button>
                <Button>Delete</Button>
            </div>
        </div>
        <Table
            dataSource={columnsData.map((item) => ({
                key: item.key,
                table: item.table,
                schema: "prod_demo"
            }))}
            columns={[
                {
                    title: 'Table',
                    dataIndex: 'table',
                    key: 'table',
                },
                {
                    title: 'Schema',
                    dataIndex: 'schema',
                    key: 'schema',
                },
            ]}
            pagination={false}
        />
    </>
);

const Sources_part2 = ({ model, onBack }) => {
    const [activeTab, setActiveTab] = useState('connection');



    const tabItems = [
        {
            key: 'connection',
            label: 'Connection',
            children: <Connection />,
        },
        {
            key: 'columns',
            label: 'Tables',
            children: <ColumnsData />,
        },
    ];

    return (
        <Card
            title={"Sources"}
            extra={
                <Button type="primary" onClick={onBack}>
                    Back
                </Button>
            }
        >
            <div className="my-2 flex justify-start gap-3 items-center">
                <div className='px-2' style={{ borderRight: "2px solid #EEE" }}>
                    <span>Sources: </span>
                    <Button style={{ borderRadius: "20px" }}>
                        Salesforce Demo
                    </Button>
                </div>
                <div className="flex items-center">
                    <div style={{ borderRight: "2px solid #EEE" }}>
                        <DatePicker
                            defaultValue={dayjs('2024-05-15')}
                            format="MMM DD, YYYY [PDT]"
                            style={{ marginRight: '10px' }}
                        />
                    </div>
                    <div className='px-2 flex gap-2 items-center'>
                        <DatabaseOutlined />
                        <span>Warehouse</span>
                    </div>
                </div>
            </div>
            <Tabs
                activeKey={activeTab}
                onChange={(key) => setActiveTab(key)}
                items={tabItems}
            />
        </Card>
    );
};

export default Sources_part2;
