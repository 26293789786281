import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import pageImg from "../../assets/images/pageImg.webp";
import searchImg from "../../assets/images/searchImg.png";
import rightImg from "../../assets/images/rightImg.png";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import aiImg from "../../assets/images/AI.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import { btnClickedAtom, activeIndexAtom, visibleProcessesAtom, collapsibleAtom, testLoadingAtom, gumloopChatArrayAtom } from "../../store";
import { useSetRecoilState } from "recoil";

const Template = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const setBtnClicked = useSetRecoilState(btnClickedAtom);
  const setActiveIndex = useSetRecoilState(activeIndexAtom);
  const setVisibleProcesses = useSetRecoilState(visibleProcessesAtom);
  const setCollapsed = useSetRecoilState(collapsibleAtom);
  const setTestLoading = useSetRecoilState(testLoadingAtom);
  const setChatArray = useSetRecoilState(gumloopChatArrayAtom);
  useEffect(() => {
    setBtnClicked(false);
    setActiveIndex(0)
    setVisibleProcesses([0])
    setCollapsed(true);
    setTestLoading(false);
    setChatArray([
      {
        answer:
          "I see you have selected the Lead Research Template. Would you like help setting it up?"
      },
    ])
  }, [])
  const handleGumloop = () => {
    setLoading(true);
    setTimeout(() => {
      navigate("/pipeline?template=true&flow_id=u1So8Ta6FEsBsG7F3gb8o9");
      setLoading(false);
    }, 1000);
  };
  return (
    <div className="p-2 relative flex flex-col justify-start items-start h-[95vh]">
      {/* 73D297, 8EA9D0, E9EDF1, 2966BC */}
      <div className="bg-[#8EA9D0] h-fit flex flex-col gap-2 justify-center p-4 w-full">
        <div className="flex items-center gap-3">
          <img src={searchImg} alt="searchImg" width={45} />
          <p className="text-2xl text-black font-bold">
            Lead Background Research
          </p>
        </div>
        <p className="text-lg w-1/2 px-4 text-black">
          Automatically research your new users or sales leads and reach out
          with a personalized greeting
        </p>
        {loading ? (
          <Spin
            indicator={<LoadingOutlined />}
            size="large"
            className="text-start m-4"
          />
        ) : (
          <div
            onClick={handleGumloop}
            className="flex gap-2 items-center mx-4 p-2 border border-black w-fit rounded-md mt-2 cursor-pointer hover:scale-105 duration-300 ease-in-out hover:bg-[#2966BC] hover:text-white"
          >
            <p className="text-base">Begin Customizing this Template</p>
            <img src={rightImg} alt="rightImg" width={25} />
          </div>
        )}
      </div>
      <img
        src={pageImg}
        alt="pageImg"
        width={450}
        className="absolute z-50 right-10 top-10 rounded-md hover:shadow-lg"
      />
      <div className="bg-[#E9EDF1] h-fit">
        <p className="w-1/3 mx-8 my-8">
          Convert leads or free users into paid customers by automatically
          sending them a personalized email, ensuring a great first impression.
          Automate the tedious research work often performed by costly SDRs with
          this innovative flow.
        </p>
        <div className="h-fit w-1/2 border bg-white ml-7 z-50 p-2">
          <p className="px-4 py-2 text-xl">Nodes used in this template</p>
          <div className="flex flex-col gap-4 mt-1 px-4 py-2 text-lg">
            <div className="flex items-center gap-2 border p-4 rounded-xl w-fit hover:shadow-lg">
              <img src={aiImg} alt="aiImg" width={20} />
              <p>Ask AI - Single Value</p>
            </div>
            <div className="flex items-center gap-2 border p-4 rounded-xl w-fit hover:shadow-lg">
              <img src={companyImg} alt="companyImg" width={20} />
              <p>Enrich Company Information</p>
            </div>
            <div className="flex items-center gap-2 border p-4 rounded-xl w-fit hover:shadow-lg">
              <img src={summaryImg} alt="summaryImg" width={20} />
              <p>Summarizer</p>
            </div>
          </div>
        </div>
        <div className="h-fit ml-7 p-2 my-20 flex w-full justify-between items-start">
          <div className="w-1/2">
            <p className="font-bold text-lg">How it works</p>
            <p>
              This highly modular Agent Workflow is used by our own team
              hundreds of times a day to ensure our customers are getting
              everything they need out of the same.
            </p>
            <p>Here is how it works:</p>
            <ol className="pl-6 list-decimal">
              <li className="break-words">
                Webhook from new user signup sends customer email into the
                workflow
              </li>
              <li className="break-words">
                Top level domain is extracted from the email address to identify
                the company the user is from
              </li>
              <li className="break-words">
                Additional data is fetched from a variety of sources regarding
                the company. This data includes (but is not limited to) Business
                name, ARR, company size, industry, location and more.
              </li>
              <li className="break-words">
                LLM generates a handful of potential example use cases and cost
                saving suggestions based on their company profile
              </li>
              <li className="break-words">
                An email is generated and sent to the customer which includes
                these suggestions along with a personalized note encouraging
                them to reach out for more information.
              </li>
            </ol>
          </div>
          <div className="h-fit w-1/2 flex flex-col gap-2 ml-48 mr-10">
            <p className="font-bold text-lg">Example Output</p>
            <p>
              Agent Workflow's outputs are fully customizable to meet your
              individual needs
            </p>
            <div className="bg-white overflow-x-scroll h-48 px-4 py-2 w-fit flex flex-col gap-2">
              <p>
                <span className="font-bold">user email:</span>{" "}
                fakeemail@hubspot.com
              </p>
              <p>
                <p>
                  <span className="font-bold">Company Summary:</span> HubSpot is
                  a customer platform that offers a suite of software and
                  resources for marketing, sales, content management, and
                  customer service to support business growth. They provide
                  onboarding services, free courses, developer tools, startup
                  support, and a library of resources to help businesses
                  succeed.
                </p>
                <p>
                  <span className="font-bold">ARRL:</span> $883,000,000{" "}
                </p>
                <p>
                  <span className="font-bold">Company name:</span> HubSpot
                </p>
                <p>
                  <span className="font-bold">Industry:</span> information
                  technology & services
                </p>
                <p>
                  <span className="font-bold">Country:</span> United States
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
