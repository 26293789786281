import React, { useEffect } from 'react';
import Plot from 'react-plotly.js';
import { saveFreshReportsInDB } from '../../services/InsightsServices';
import { jwtDecode } from 'jwt-decode';
import { message } from 'antd';
import {debounce} from 'lodash';
const DynamicChart = ({ chartData, query_id, postgres_query, description, topic, onlySummary, type="write" }) => {
  console.log("DSDJSDOJW +> ", onlySummary)
  const { data, layout } = JSON.parse(chartData)
  const updatedLayout = { ...layout, paper_bgcolor: "#fffff" }

  const saveFreshReport = debounce(async () => {
    console.log("Before saving the payload => ", chartData[0]);
    const payload = {
      user_id: jwtDecode(
        localStorage
          .getItem("loginToken")
          ?.replace(process.env.REACT_APP_TOKEN_PASSCODE, "")
      )?.id,
      query_id: query_id,
      base64Img: chartData[0],
      postgres_query: postgres_query,
      description: description,
      name: topic
    };

    let response = await saveFreshReportsInDB(payload);
    console.log(response);
    if (response.data.status === "success") {
      message.success("Insight has been saved in Reports");
    }
  }, 300); // Adjust the delay as needed

  useEffect(() => {
    if(type === 'write' && !onlySummary){
      try {
        console.log('Saving Plotly Code...');
        saveFreshReport();
      } catch (error) {
        console.error('Error executing Plotly code:', error);
      }
    }

  }, [])


  // console.log
  return (
    <Plot
      className='w-full h-[400px]'
      data={data}
      layout={updatedLayout}
      config={{ responsive: true }}
    />
  );
};

export default DynamicChart;