import React, { useEffect, useState, useRef } from "react";
import logo from "../../assets/images/logoWOname.png";
import avatar from "../../assets/images/user.png";
import {
  CopyOutlined,
  LikeOutlined,
  DislikeOutlined,
  LinkOutlined,
  PieChartFilled,
  ArrowsAltOutlined,
} from "@ant-design/icons";
import { Card, Button, Skeleton, message, Table } from "antd";
import { motion } from "framer-motion";
import { Bar } from "react-chartjs-2";
import graphImg from "../../assets/images/graphImg.png";
import shareImg from "../../assets/images/shareImg.png";
import downloadImg from "../../assets/images/downloadImg.png";
import refreshImg from "../../assets/images/refresh.png";
import reviewRating from "../../assets/images/reviewRatingGraph.png";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  gumloopChatArrayAtom,
  gumloopLoadingAtom,
  gumloopExtendedChatAtom,
  gumloopQuestionAtom,
  gumloopIsFollowupClickedAtom,
} from "../../store";
import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms";


import { sendFeedback } from "../../services/chatServices";
import { jwtDecode } from "jwt-decode";

import html2canvas from "html2canvas";

const charVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const CharMotion = ({ text, onComplete }) => {
  const chatMode = useRecoilValue(chatModeAtom);

  return (
    <div className="text-[14px]">
      {text.split("").map((ch, idx) =>
        ch === ">" ? (
          <></>
        ) : (
          <motion.span
            key={`${ch}_${idx}`}
            initial="hidden"
            animate="visible"
            variants={charVariants}
            transition={{ duration: 0.0, delay: idx * 0.000001 }}
            onAnimationComplete={idx === text.length - 1 ? onComplete : null}
            className={`${chatMode && 'text-[12px]'}`}
          >
            {ch}
          </motion.span>
        )
      )}
    </div>
  );
};

const ChatMessage = ({ textToDisplay, chat, setShowSuggestion }) => {
  const [displayedLines, setDisplayedLines] = useState([]);
  const lines = textToDisplay?.split("\n") || [];
  const chatMode = useRecoilValue(chatModeAtom);

  const lineRef = useRef();

  useEffect(() => {
    if (lines.length > 0) {
      setShowSuggestion(false);
      setDisplayedLines([lines[0]]);
    }
  }, [chat]);

  let lineTimeoutId;
  useEffect(() => {
    lineTimeoutId = setTimeout(() => {
      console.log("called");
      lineRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 1);

    return () => clearTimeout(lineTimeoutId);
  }, [displayedLines]);

  const handleAnimationComplete = () => {
    if (displayedLines.length < lines.length) {
      // setShowSuggestion(false);
      setDisplayedLines((prevDisplayedLines) => [
        ...prevDisplayedLines,
        lines[prevDisplayedLines.length],
      ]);
    } else if (displayedLines.length === lines.length) {
      console.log("show now");
      setShowSuggestion(true);
    }
  };

  return displayedLines.map((line, idx) => (
    <motion.div
      initial="hidden"
      animate="visible"
      key={idx}
      className={`overflow-hidden ${line?.trim()[0] === ">" && !chatMode && "pl-16"} ${chatMode && 'p-0'}`}
      ref={lineRef}
    >
      <CharMotion
        text={line}
        onComplete={
          idx === displayedLines.length - 1 ? handleAnimationComplete : null
        }
      />
    </motion.div>
  ));
};

const ChatWindowGumloop = () => {
  const [chatArray, setChatArray] = useRecoilState(gumloopChatArrayAtom);
  const [loading, setLoading] = useRecoilState(gumloopLoadingAtom);
  const [question, setQuestion] = useRecoilState(gumloopQuestionAtom);
  
  const chatMode = useRecoilValue(chatModeAtom);
  const [showSuggestion, setShowSuggestion] = useState(false);

  let lastInsightRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    if (lastInsightRef?.current) {
      timeoutId = setTimeout(() => {
        lastInsightRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 100);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showSuggestion]);


  return (
    <div
      className={`${chatMode ? 'h-[100%]' : 'h-[80%]'} flex flex-col gap-2 mt-2`}
      style={{ overflowY: "scroll" }}
    >
      {chatArray.map((chat, index) => (
        <React.Fragment key={index}>
          {/* Question Component Starts */}
          {chat?.question && (
            <div className={`flex justify-start items-center ${chatMode ? 'gap-8' : 'gap-11'}`}>
              <img
                src={
                  localStorage.getItem("profile_url")
                    ? localStorage.getItem("profile_url").toString()
                    : avatar
                }
                alt="user-logo"
                width={30}
                className="rounded-full self-start mt-1"
              />
              <div className={`${chatMode ? 'text-[11.5px]' : 'text-[14px]'} bg-[#8EA9D0] w-[90%] p-4 rounded-md relative text-white`}>
                <div className="triangle-left tl-question absolute left-[-20px] top-2" />
                {chat.question}
              </div>
            </div>
          )}
          {/* Question Component Ends */}

          {/* Answer Component Starts */}
          <div
            className={`flex ${chatMode ? 'gap-6' : 'gap-10'}`}
            // ref={index === chatArray?.length - 1 ? ansRef : null}
          >
            <div className="flex justify-center items-start">
              <img
                src={logo}
                alt="brand-logo"
                width={chatMode ? 45 : 35}
                className={`${chatMode && 'ml-1'} mt-1 self-start`}
              />
            </div>
            <div className="text-[14px] bg-[#E9EDF1] w-[90%] p-4 rounded-md relative">
              <div className="triangle-left tl-answer absolute left-[-20px] top-2" />

              <div className="flex flex-col justify-center items-start flex-wrap gap-2">
                {chat?.answer && (
                  <ChatMessage
                    textToDisplay={chat?.answer}
                    chat={chat}
                    setShowSuggestion={setShowSuggestion}
                  />
                )}
                {loading && index === chatArray?.length - 1 && (
                  <div
                    className="flex flex-wrap mt-2 gap-3"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                
              </div>
              <Skeleton
                loading={index === chatArray.length - 1 && loading}
                active
              />
            </div>
          </div>
          {/* Answer Component Ends */}

          {(!loading || index !== chatArray.length - 1) && (
            <>
              

              {/* Chart Message Starts */}
              {chat?.chartMessage && (
                <div className={`bg-[#E9EDF1] p-4 ${chatMode ? 'w-[68%] ml-[60px]' : 'w-[90%] ml-[74px]'} flex flex-col justify-center items-start flex-wrap gap-2 rounded-md`}>
                  <ChatMessage
                    textToDisplay={chat?.chartMessage}
                    chat={chat}
                    setShowSuggestion={setShowSuggestion}
                  />
                </div>
              )}

              {/* Chart Message Ends */}

            </>
          )}

          {/* Placeholder text for margin bottom Starts */}
          {index === chatArray.length - 1 && (
            <div className="mt-40 text-white">Placeholder Text</div>
          )}
          {/* Placeholder text for margin bottom Ends */}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ChatWindowGumloop;
