const suggestedPrompts = [
    "Suggested Prompts",
    "Suggested Prompts",
    "Suggested Prompts",
    "Suggested Prompts",
  ];
  const prevQuestions = [
    "Show me high level insights on my customer accounts",
    "Show me high level insights on the opportunities",
    "Show me high level insights on the leads",
  ];
  
  export { suggestedPrompts, prevQuestions };
  