import React, { useEffect } from 'react'
import ChatWindow from './ChatWindow.js';
import ChatWindowVanna from './ChatWindowForVanna.js';
import InputBox from './InputBox';
import { useRecoilValue, useRecoilState } from 'recoil';
import { extendedChatAtom, redirectedHistoryAtom } from '../../store/index.js';
import ExtendedChat from './ExtendedChat.js';
import ChatWindowVannaWS from './ChatWindowForVannaWS.js';
import { useLocation } from 'react-router-dom'

const Copilot = () => {
  const extendedChat = useRecoilValue(extendedChatAtom);
  const [redirectedHistory, setRedirectedHistory] = useRecoilState(redirectedHistoryAtom);

  const { pathname } = useLocation();


  useEffect(() => {
    if (!redirectedHistory) {
      localStorage.removeItem('session_id');
    }
    else {
      setRedirectedHistory(false)
    }
    localStorage.removeItem('tableName')
  }, []);

  return (
    <div className='flex flex-col h-[90vh] w-full p-2'>
      {!extendedChat ? (
        <>
          {/* Need to configure this for accordingly based on the routes */}
          {/* <ChatWindowVanna /> */}
          {pathname === '/assistant' ?
            <ChatWindowVanna /> : <ChatWindow />
          }
          <InputBox />
        </>
      ) : (
        <>
          <ExtendedChat />
        </>
      )}
    </div>
  )
}

export default Copilot;
