import useWorkspacePermissions from "../../hooks/useWorskpacePermissions";
import "./SettingsV2.css";
import { Button, Card, Result, Tabs } from "antd";
import Members from "../../components/SettingsV2/Members/Members";
import { useNavigate } from "react-router-dom";
import Roles from "../../components/SettingsV2/Roles/Roles";
import { Billing } from "../../components/SettingsV2/Billing/Billing";
import AuditLogs from "../../components/SettingsV2/AuditLogs/AuditLogs";
import { USER_PERMISSIONS } from "../../constants/workspace";

const TAB_KEYS = {
  USERS: "t_users",
  ROLES: "t_roles",
  BILLING: "t_billings",
  AUDIT_LOGS: "t_audit_logs",
};

const SettingsV2 = () => {
  const { hasPermissions } = useWorkspacePermissions();
  const navigate = useNavigate();

  const handleRedirectToWorkspaceList = () => {
    navigate("/workspaces");
  };

  const tabItems = [];

  if (hasPermissions([USER_PERMISSIONS.WORKSPACE_SETTINGS.USERS])) {
    tabItems.push({
      key: TAB_KEYS.USERS,
      label: <div className="text-h3 font-bold">Users</div>,
      children: <Members />,
    });
  }

  if (hasPermissions([USER_PERMISSIONS.WORKSPACE_SETTINGS.ROLES])) {
    tabItems.push({
      key: TAB_KEYS.ROLES,
      label: <div className="text-h3 font-bold">Roles</div>,
      children: <Roles />,
    });
  }

  tabItems.push(
    ...[
      {
        key: TAB_KEYS.BILLING,
        label: <div className="text-h3 font-bold">Billing</div>,
        children: <Billing />,
      },
      {
        key: TAB_KEYS.AUDIT_LOGS,
        label: <div className="text-h3 font-bold">Audit Logs</div>,
        children: <AuditLogs />,
      },
    ]
  );

  if (!tabItems.length) {
    return (
      <div className="w-full p-4">
        <Result
          status="403"
          title="Permission Denied"
          subTitle="Sorry, looks like you don't have required permissions to access settings"
          extra={
            <Button type="primary" onClick={handleRedirectToWorkspaceList}>
              Back
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="w-full p-4">
      <Card
        title={
          <Tabs
            destroyInactiveTabPane
            size="middle"
            defaultActiveKey={tabItems[0].key}
            items={tabItems}
          />
        }
      ></Card>
    </div>
  );
};

export default SettingsV2;
