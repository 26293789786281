import React, { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { chatModeAtom, sideNavContentAtom } from '../../../../../store/atoms/MainAtoms';
import { message, Select, Dropdown, Menu, Modal } from 'antd';
import { ShareAltOutlined, ControlOutlined, DownloadOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import { saveToDashboard, fetchTableData } from '../../../../../services/InsightsServices';
import { copilotChatArrayAtom, extendedChatAtom } from '../../../../../store';
import GraphOptions from "../../../../GraphOptions";
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const { Option } = Select;

const VisualMenu = ({ chat, index, setVisualMode, visualMode }) => {
    const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
    const [selectedGraphQueryId, setSelectedGraphQueryId] = useState(null);
    const [selectedGraphSQLquery, setSelectedGraphSQLquery] = useState(null);
    const [graphOptionsModal, setGraphOptionsModal] = useState(false);
    const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);
    const [chatArray, setChatArray] = useRecoilState(copilotChatArrayAtom);
    const setExtendedChat = useSetRecoilState(extendedChatAtom);
    const handleDownload = () => {
        if (visualMode === 'graph') {
            downloadGraphAsImage();
        } else if (visualMode === 'table') {
            fetchSaveData();
        }
    };

    const downloadGraphAsImage = () => {
        const graphElement = document.querySelector(`#graph-${index}`);
        if (graphElement) {
            message.loading("Downloading your graph")
            html2canvas(graphElement).then(canvas => {
                canvas.toBlob(blob => {
                    saveAs(blob, `graph-${index}.png`);
                });
            });
        }
    };

    const fetchSaveData = async () => {
        message.loading("Downloading your records")
        try {
            let query = `select * from insighttables.new_table_${chat.query_id.replace(/-/g, '_')};`
            const currentDateTime = new Date().toISOString().replace(/[-:T]/g, '').split('.')[0];
            setTimeout(async () => {
                const result = await fetchTableData({ sqlQuery: query });
                console.log({ result })
                const tableData = result.data.data || [];
                const worksheet = XLSX.utils.json_to_sheet(tableData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');
                XLSX.writeFile(workbook, `iCustomer-${currentDateTime}.csv`);
            }, 500)
        } catch (err) {
            message.error("Oops, we went through some issues while downloading your file!")
        }
    };
    const handleSaveToDashboard = async (query_id) => {
        const payload = { query_id: query_id };
        let response = await saveToDashboard(payload);
        if (response.data.status === "success") {
            message.success("Insight has been saved to Dashboard");
        }
    };
    const graphOptionclose = () => {
        setGraphOptionsModal(false)
    }
    const openGraphOptionsModal = (query_id, postgres_query) => {
        setSelectedGraphQueryId(query_id);
        setSelectedGraphSQLquery(postgres_query);
        setGraphOptionsModal(true);
    };

    const extendChat = (chat) => {
        localStorage.setItem("extended_query_id", chat.query_id);
        // localStorage.setItem("extended_chat_table", chat.newTable);
        setSideNavContent(1);
        setChatMode(1);
        setExtendedChat(true);
    };

    const handleSelectChange = (value) => {
        setVisualMode(value);
    };

    const menu = (
        <Menu>
            {chat?.data && (
                <>
                    <Menu.Item key="1" onClick={() => handleSaveToDashboard(chat?.query_id)}>
                        Save to Dashboard
                    </Menu.Item>
                    <Menu.Item key="2">
                        Generate Embeddable Link
                    </Menu.Item>
                </>
            )}
            <Menu.Item key="3">
                Send on Slack
            </Menu.Item>
            <Menu.Item key="4">
                Send Via Email
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Modal
                title=""
                visible={graphOptionsModal}
                onCancel={() => graphOptionclose(false)}
                footer={[
                ]}
            >
                <GraphOptions queryID={selectedGraphQueryId} postgres_query={selectedGraphSQLquery} graphOptionclose={graphOptionclose} />
            </Modal>
            <div className="absolute mt-2 top-2 right-2 flex justify-center items-center gap-4">
                <div className="flex items-center">
                    <Select
                        value={visualMode}
                        onChange={handleSelectChange}
                        className="mr-2"
                        style={{ width: 120 }}
                    >
                        {chat?.data && <Option value="graph">Graph</Option>}
                        <Option value="table">Table</Option>
                        <Option value="sql">SQL Query</Option>
                    </Select>
                </div>
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    <ShareAltOutlined
                        className="hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
                    />
                </Dropdown>
                <ControlOutlined
                    className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
                    onClick={() => openGraphOptionsModal(chat?.query_id, chat?.postgres_query)}
                />
                <DownloadOutlined onClick={handleDownload} className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]" />
                <ArrowsAltOutlined
                    className="text-[14px] -ml-1 hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] hover:p-1"
                    onClick={() => extendChat(chat)}
                />
            </div>
        </>
    );
};

export default VisualMenu;
