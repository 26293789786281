import React, { useState, useEffect, useRef } from "react";
import "./LeadRightSider.css";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { leadCollapsibleAtom, gumloopChatArrayAtom, leadBtnLoadingAtom } from "../../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Timer from "./LeadTimer.js";
import Complete from "./LeadComplete.js";

import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";
import { Descriptions } from "antd";

const hubspotResponse =  {
  "eventId": "1234567890",
  "subscriptionId": 12345,
  "portalId": 6789012,
  "appId": 98765,
  "occurredAt": "2024-08-08T14:30:00.000Z",
  "subscriptionType": "form.submitted",
  "attemptNumber": 1,
  "objectId": 5678,
  "changeSource": "FORM",
  "eventType": "FORM_SUBMISSION",
  "form": {
  "id": "abcdef12-3456-7890-abcd-ef1234567890",
  "name": "Contact Us Form",
  "portalId": 6789012
  },
  "submissionValues": {
  "email": "john.doe@example.com",
  "firstname": "John",
  "lastname": "Doe",
  "company": "Acme Corp",
  "message": "I'd like to learn more about your services."
  },
  "contact": {
  "id": "987654",
  "createdAt": "2024-08-08T14:30:00.000Z",
  "properties": {
  "email": "john.doe@example.com",
  "firstname": "John",
  "lastname": "Doe",
  "company": "Acme Corp"
  }
  }
}
  
const siderProcess = [
  {
    id: "node-15",
    label: "Listening to Webhooks from Hubspot…",
    description: 'Received a Form Response',
    output: hubspotResponse,
    icon: hubspotImg,
    duration: 16.33,
  },
  {
    id: "node-4",
    label: "Parsing JSON",
    description: "Extracting the field which you asked for...",
    output: {
      email: `jenish@icustomer.ai`
    },
    icon: splitTextImg,
    duration: 11.03,
  },
  {
    id: "node-5",
    label: "Verifying if the Person is customer",
    descriptions: "Fetching the response from oGraph Person Verification API",
    output: {
      results: {
        match: true
      }
    },
    icon: getListImg,
    duration: 14.03,
  },
  {
    id: "node-13",
    label: "Parsing JSON",
    description: "Extracting the field which you asked for...",
    output: {match: true},
    icon: splitTextImg,
    duration: 11.03,
  },
  {
    id: "node-12",
    label: "Decision Gate",
    description: "Validating the JSON Results with Logic gate",
    output: {status: true},
    icon: webScraperImg,
    duration: 12.79,
  },
  {
    id: "node-14",
    label: "ICP Verification",
    description: "Enriching the Person records to verify ICP",
    output: {
      account_icp: true
    },
    icon: webScraperImg,
    duration: 14.79,
  },
  {
    id: "node-15",
    label: "Decision Gate",
    description: "Validating the ICP Results with Logic gate",
    output: {status: true},
    icon: webScraperImg,
    duration: 12.79,
  },
  {
    id: "node-16",
    label: "Enrich Contact Information with Template",
    description: "Template being used is ICP LEAD ENRICH TEMPLATE",
    output: {
      "person": {
        "id": "1234567890",
        "first_name": "John",
        "last_name": "Doe",
        "email": "john.doe@example.com",
        "title": "Senior Software Engineer",
        "phone": "+1-234-567-8901",
        "city": "San Francisco",
        "state": "California",
        "linkedin_url": "https://www.linkedin.com/in/johndoe",
        "enriched_fields": {
          "email": "john.doe@example.com",
          "title": "Senior Software Engineer",
          "phone": "+1-234-567-8901",
          "city": "San Francisco",
          "state": "California",
          "linkedin_url": "https://www.linkedin.com/in/johndoe"
        },
        "enrichment_status": "SUCCESS",
        "source": "Enrichment Service X",
        "timestamp": "2024-08-13T10:15:00.000Z"
      }
    },
    icon: companyImg,
    duration: 13.55,
  },
  {
    id: "node-16",
    label: "Enrich Account Information with Template",
    description: "Template being used is ICP ACCOUNT ENRICH TEMPLATE",
    output: {
      "account": {
        "account_id": "9876543210",
        "account_name": "Acme Corp",
        "account_website": "https://www.acmecorp.com",
        "industry": "Manufacturing",
        "country": "United States",
        "state": "California",
        "website": "https://www.acmecorp.com",
        "enriched_fields": {
          "account_id": "9876543210",
          "account_name": "Acme Corp",
          "account_website": "https://www.acmecorp.com",
          "industry": "Manufacturing",
          "country": "United States",
          "state": "California",
          "website": "https://www.acmecorp.com"
        },
        "enrichment_status": "SUCCESS",
        "source": "Enrichment Service Y",
        "timestamp": "2024-08-13T10:20:00.000Z"
      }
    },
    icon: companyImg,
    duration: 13.55,
  },
  {
    id: "node-3",
    label: "Syncing and Pushing the Contact Fields",
    description: "All the enriched fields of Contacts are being synced and pushed into the target",
    output: {
      "salesforce_sync": {
        "status": "SUCCESS",
        "sync_details": {
          "object": "Contact",
          "operation": "UPSERT",
          "salesforce_id": "0035g00000A1bCdE",
          "enriched_fields": {
            "linkedin_url": {
              "value": "https://www.linkedin.com/in/johndoe",
              "salesforce_field": "LinkedIn_URL__c"
            },
            "email": {
              "value": "john.doe@example.com",
              "salesforce_field": "Email"
            },
            "title": {
              "value": "Senior Product Manager",
              "salesforce_field": "Title"
            },
            "phone": {
              "value": "+1-555-123-4567",
              "salesforce_field": "Phone"
            },
            "city": {
              "value": "San Francisco",
              "salesforce_field": "MailingCity"
            },
            "state": {
              "value": "California",
              "salesforce_field": "MailingState"
            }
          },
          "timestamp": "2024-08-13T10:35:00.000Z"
        },
        "message": "The enriched fields have been successfully synced and pushed to the Salesforce Contacts object."
      }
    },
    icon: outputImg,
    duration: 15.32,
  },
  {
    id: "node-17",
    label: "Syncing and Pushing the Account Fields",
    description: "All the enriched fields of Accounts are being synced and pushed into the target",
    output: {
      "salesforce_sync": {
        "status": "SUCCESS",
        "sync_details": {
          "object": "Account",
          "operation": "UPSERT",
          "salesforce_id": "0015g00000A1bCdE",
          "enriched_fields": {
            "account_id": {
              "value": "A0012345678",
              "salesforce_field": "Account_ID__c"
            },
            "account_name": {
              "value": "Acme Corp",
              "salesforce_field": "Name"
            },
            "website": {
              "value": "https://www.acmecorp.com",
              "salesforce_field": "Website"
            },
            "industry": {
              "value": "Manufacturing",
              "salesforce_field": "Industry"
            },
            "country": {
              "value": "United States",
              "salesforce_field": "BillingCountry"
            },
            "state": {
              "value": "California",
              "salesforce_field": "BillingState"
            }
          },
          "timestamp": "2024-08-13T10:40:00.000Z"
        },
        "message": "The enriched fields have been successfully synced and pushed to the Salesforce Accounts object."
      }
    },
    icon: outputImg,
    duration: 15.32,
  },
];

const LeadRightSider = ({ setModalOpen }) => {
  const isCollapsed = useRecoilValue(leadCollapsibleAtom);
  const chatArray = useRecoilValue(gumloopChatArrayAtom);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleProcesses, setVisibleProcesses] = useState([0, 1, 2]);
  const [completedProcesses, setCompletedProcesses] = useState([]);
  const setBtnLoading = useSetRecoilState(leadBtnLoadingAtom);

  const ref = useRef([]);

  const handleTimerComplete = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      setTimeout(() => {
        setVisibleProcesses((prevVisible) => [...prevVisible, newIndex]);
        setCompletedProcesses([...completedProcesses, prevIndex]);
      }, 0);
      return newIndex;
    });
  };

  useEffect(() => {
    let timeout;
    if (ref) {
      timeout = setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
      
      if (activeIndex === siderProcess.length) {
        setTimeout(() => {
          setModalOpen(true);
          setBtnLoading(false);
        }, 1000)
      }
    }
    return () => clearTimeout(timeout);
  }, [activeIndex]);

  return (
    <div className={`rounded-md right-sider bg-white ${isCollapsed ? "collapsed" : ""}`}>
      <div className="sider-content flex flex-col gap-6">
        {siderProcess.map(
          (process, index) =>
            index <= activeIndex && (
              <div
                key={process?.id}
                className={`flex flex-col gap-3 process ${visibleProcesses.includes(index) ? "visible" : ""}`}
                ref={visibleProcesses.includes(index) ? ref : null}
              >
                <div className="flex items-center gap-2">
                  <img src={process?.icon} alt={process?.id} width={18} />
                  <p className="font-bold text-[12px]">{process?.label}</p>
                </div>
                <p className="text-[11px]">{typeof process?.description === "object" ? JSON.stringify(process?.description) : process?.description}</p>
                <Timer duration={process?.duration} onComplete={handleTimerComplete} />
                {completedProcesses.includes(index) && (
                  <div 
                    ref={index === siderProcess.length - 1 ? ref : null} 
                    className="mt-4 flex flex-col gap-1 text-[9px] bg-gray-100 p-2 border rounded-md"
                    style={{
                      wordBreak: "break-all",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      maxWidth: "100%",  // Ensure it doesn't exceed the container width
                      overflowX: "scroll", // Hide any overflow
                    }}
                  >
                    <p className="font-semibold">Output:</p>
                    <pre>{JSON.stringify(process?.output, null, 2)}</pre>
                  </div>
                )}
                {index === siderProcess?.length - 1 && chatArray[chatArray?.length - 1]?.answer !== "I see you have selected the Lead Research Template. Would you like help setting it up?" ? (
                  <Complete />
                ) : (
                  <hr className="translate-y-2" />
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default LeadRightSider;
