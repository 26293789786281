import React, { useState, useEffect, useRef } from "react";
import "./ProgRightSider.css";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import {
  collapsibleAtom,
  gumloopChatArrayAtom,
  btnLoadingAtom,
  nodeInProgressAtom,
  activeIndexAtom,
  visibleProcessesAtom,
  progCollapsibleAtom,
  progGumloopChatArrayAtom,
  progActiveIndexAtom,
  progVisibleAtom,
  progModalOpenAtom,
  progBtnLoadingAtom,
  progFocusNodeIdAtom,
  progNodeInProgressAtom,
} from "../../store";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import Timer from "./ProgTimer.js";
import Complete from "./ProgComplete.js";

import outputImg from "../../assets/images/outputImg.png";
import splitTextImg from "../../assets/images/splitTextImg.png";
import getListImg from "../../assets/images/getListImg.png";
import combineTextImg from "../../assets/images/combineTextImg.png";
import webScraperImg from "../../assets/images/webScraperImg.png";
import companyImg from "../../assets/images/companyImg.png";
import summaryImg from "../../assets/images/summaryImg.png";
import slackImg from "../../assets/images/slackLogo.png";
import hubspotImg from "../../assets/images/hubspot.png";
import inputImg from "../../assets/images/inputImg.png";
import calcImg from "../../assets/images/calcImg.png";
import parseImg from "../../assets/images/parseImg.png";
import robotImg from "../../assets/images/robotImg.png";

const siderProcess = [
  {
    id: "node-1",
    label: "Input",
    icon: inputImg,
    duration: 0.23,
  },
  {
    id: "node-4",
    label: "Contact Discovery",
    icon: parseImg,
    duration: 3.45,
  },
  {
    id: "node-16",
    label: "Job Title Standardization",
    icon: companyImg,
    duration: 2.93,
  },
  {
    id: "node-18",
    label: "Contact Name Standardization",
    icon: calcImg,
    duration: 2.33,
  },
  {
    id: "node-17",
    label: "Leads",
    icon: calcImg,
    duration: 4.2,
  },
  {
    id: "node-3",
    label: "Output",
    icon: robotImg,
    duration: 0.22,
  },
];

const RightSider = () => {
  const isCollapsed = useRecoilValue(progCollapsibleAtom);
  const chatArray = useRecoilValue(progGumloopChatArrayAtom);
  const setBtnLoading = useSetRecoilState(progBtnLoadingAtom);
  const [activeIndex, setActiveIndex] = useRecoilState(progActiveIndexAtom);
  const [visibleProcesses, setVisibleProcesses] =
    useRecoilState(progVisibleAtom);
  const setNodeInProgress = useSetRecoilState(progNodeInProgressAtom);

  const setModalOpen = useSetRecoilState(progModalOpenAtom);
  const setFocusNodeId = useSetRecoilState(progFocusNodeIdAtom);

  const handleTimerComplete = () => {
    // setActiveIndex((prevIndex) => prevIndex + 1);
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      setTimeout(() => {
        setVisibleProcesses([...visibleProcesses, newIndex]);
      }, 0);
      return newIndex;
    });
  };

  useEffect(() => {
    if (activeIndex === siderProcess?.length) {
      setBtnLoading(false);
      setModalOpen(true);
    }
  }, [activeIndex]);

  const ref = useRef(null);

  useEffect(() => {
    let timeout;
    if (ref) {
      timeout = setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }

    // const newElement = document.querySelector(`.process-${activeIndex}`);
    // if (newElement) {
    //   setTimeout(() => {
    //     newElement.classList.add('visible');
    //   }, 100); // Timeout is needed to allow the element to be rendered before adding the class
    // }

    console.log(activeIndex, "activeIndex----------->");
    const id = siderProcess[visibleProcesses[visibleProcesses?.length - 1]]?.id;
    setFocusNodeId(id === "node-3" ? "whole" : id);
    setNodeInProgress(id);

    return () => clearTimeout(timeout);
  }, [activeIndex, visibleProcesses]);

  return (
    <div
      className={`rounded-md right-sider bg-white ${
        isCollapsed ? "collapsed" : ""
      }`}
    >
      <div className="sider-content flex flex-col gap-6">
        {siderProcess.map(
          (process, index) =>
            index <= activeIndex && (
              <div
                key={process?.id}
                ref={ref}
                className={`flex flex-col gap-3 process ${
                  visibleProcesses.includes(index) ? "visible border-b pb-4" : ""
                }`}
              >
                <div className="flex items-center gap-2">
                  <img src={process?.icon} alt={process?.id} width={18} />
                  <p className="font-bold text-[12px]">{process?.label}</p>
                </div>
                <Timer
                  duration={process?.duration}
                  onComplete={handleTimerComplete}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default RightSider;
