// ModelsTable.js
import React, { useState,useEffect } from 'react';
import { Table, Button, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import AmplitudeProductEvents from './AmplitudeProductEvents';
import { FetchCatalogue, fetchCatalogue } from '../../../services/Settings';
import { jwtDecode } from 'jwt-decode';
const modelsData_zillasecurity = [
    {
        key: '2',
        table: 'Salesforce_account',
        description: 'Represents organizations or individuals involved in business relationships. Accounts typically store information like company name, address, industry, and associated contacts.',
        type: 'Warehouse',
        slug: 'salesforce_account'
    },
    {
        key: '6',
        table: 'Salesforce_contact',
        slug: 'salesforce_contact',
        description: 'Represents individuals who are associated with accounts and opportunities. Contacts typically include details like name, email, phone, and role in the organization.',
        type: 'Warehouse',
    },
    {
        key: '7',
        table: 'Salesforce_opportunity',
        slug: 'salesforce_opportunity',
        description: 'Represents potential sales deals with a specific value and probability of closing. Opportunities are linked to accounts and are used to track progress from initial interest to deal closure.',
        type: 'Warehouse',
    },
    {
        key: '8',
        table: 'Salesforce_lead',
        slug: 'salesforce_lead',
        description: 'Represents potential customers or prospects that are yet to be qualified. It stores information about individuals or companies interested in your products or services but not yet engaged in business.',
        type: 'Warehouse',
    },
];

const modelsData_icustomer = [
        {
            key: '2',
            table: 'Account',
            description: 'Represents organizations or individuals involved in business relationships. Accounts typically store information like company name, address, industry, and associated contacts.',
            type: 'Warehouse',
            slug: 'account'
        },
        {
            key: '6',
            table: 'Contact',
            slug: 'contact',
            description: 'Represents individuals who are associated with accounts and opportunities. Contacts typically include details like name, email, phone, and role in the organization.',
            type: 'Warehouse',
        },
]

const Catalog = ({table}) => {
    const [selectedModel, setSelectedModel] = useState(null);
    const [modelsData, setModelsData] = useState([]);

    const handleModelClick = (model) => {
        setSelectedModel(model);
    };

    const columns = [
        {
            title: 'Unified Master DB',
            dataIndex: 'table',
            key: 'table',
            render: (text, record) => (
                <Button type="link" onClick={() => handleModelClick(record)}>
                    {text}
                </Button>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text) => (
                <div>
                    <p style={{ border: "0px solid" }} >{text}</p>
                </div>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
    ];

    useEffect(() => {
        let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
        if (organization === "talkable.com") {
            setModelsData([])
        } else if (organization === 'zillasecurity.com') {
            setModelsData(modelsData_zillasecurity)
        } else if (organization === 'icustomer.ai') {
            setModelsData(modelsData_icustomer)
        }
        else {
            setModelsData([])
        }
    }, []);

    return (
        <div>
            {!selectedModel ? (
                <>
                    <div className='flex justify-between mb-2'>
                        <div className='flex justify-center gap-4'>
                            <Input placeholder={"Search"} className='w-[65%]' />
                            <Button>Source</Button>
                            <Button>Type</Button>
                        </div>
                    </div>
                    <Table
                        dataSource={modelsData}
                        columns={columns}
                        pagination={false}
                    />
                </>
            ) : (
                <AmplitudeProductEvents model={selectedModel} onBack={() => setSelectedModel(null)} />
            )}
        </div>
    );
};

export default Catalog;
