import React, { useState } from 'react';

const DecisionGate = ({defaultOption}) => {
  // State management for the fields
  const [firstValue, setFirstValue] = useState(defaultOption);
  const [comparisonType, setComparisonType] = useState('equal');
  const [secondValue, setSecondValue] = useState('TRUE');
  const [isCaseSensitive, setIsCaseSensitive] = useState(false);

  // Handle form submission or any other action
  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle the condition check
    console.log({
      firstValue,
      comparisonType,
      secondValue,
      isCaseSensitive,
    });
  };

  return (
    <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-4 border border-gray-200">
      {/* Title */}
      <h2 className="text-xl font-semibold text-gray-700 mb-4">Conditions</h2>

      <form onSubmit={handleSubmit}>
        {/* Condition Section */}
        <div className="mb-6">
          <div className="flex items-center">
            <div className="w-1/12 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="w-11/12 pl-3">
              <span className="text-gray-700 font-bold">Condition</span>
            </div>
          </div>
        </div>

        {/* 1st Value */}
        <div className="mb-6 ml-6">
          <label className="block text-gray-700 font-semibold mb-2">1st Value*</label>
          <input
            type="text"
            value={firstValue}
            onChange={(e) => setFirstValue(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        {/* Comparison Type */}
        <div className="mb-6 ml-6">
          <label className="block text-gray-700 font-semibold mb-2">Comparison type</label>
          <select
            value={comparisonType}
            onChange={(e) => setComparisonType(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <option value="equal">Equal to</option>
            <option value="notEqual">Not equal to</option>
          </select>
        </div>

        {/* 2nd Value */}
        <div className="mb-6 ml-6">
          <label className="block text-gray-700 font-semibold mb-2">2nd Value*</label>
          <input
            type="text"
            value={secondValue}
            onChange={(e) => setSecondValue(e.target.value)}
            className="w-full px-4 py-2 border border-pink-500 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
          />
        </div>

        {/* Case Sensitivity */}
        <div className="mb-6 ml-6">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={isCaseSensitive}
              onChange={(e) => setIsCaseSensitive(e.target.checked)}
              className="form-checkbox h-5 w-5 text-indigo-600"
            />
            <span className="ml-2 text-gray-700 font-semibold">Is comparison case-sensitive?</span>
          </label>
        </div>
      </form>
    </div>
  );
};

export default DecisionGate;