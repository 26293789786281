import React, { useState } from "react";

import SVGElement from "./SvgElement";

import plusImg from "../../assets/images/plus.svg";
import upImg from "../../assets/images/up.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedGalleryAtom } from "../../store";
import { selectedGalleryCategoryAtom } from "../../store";
import { navBarValues } from "../AgentMainComponent/AgentMainComponent";


const NavBar = () => {
  const [selected, setSelected] = useState(0);
  const [selectedCategory, setSelectedCategory] = useRecoilState(selectedGalleryCategoryAtom)
  const [selectedGallery, setSelectedGallery] = useRecoilState(selectedGalleryAtom);

  const handleSelected = (idx) => {
    if ([9, 10, 11].includes(idx)) {
      return;
    }
    setSelectedCategory(idx)
  }

  return (
    <div className="flex flex-col justify-center gap-10">
      {selectedGallery === 0 && (
        <div className="flex justify-start items-center flex-wrap gap-3">
          {navBarValues.map((nav, index) => (
            <div
              key={index}
              className={`flex cursor-pointer justify-center items-center gap-3 ml-3 px-4 py-1.5 rounded-md ${
                selectedCategory === index ? "bg-primary" : "bg-white"
              } border border-[#D9D9D9]`}
              onClick={() => handleSelected(index)}
            >
              {/* <SVGElement
                name={nav}
                fillColor={`${selectedCategory === index ? "white" : "#16163FD9"}`}
              /> */}
              <p
                className={`${
                  selectedCategory === index ? "text-white" : "text-character"
                }`}
              >
                {nav}
              </p>
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-between items-center">
        <div className="flex justify-center items-end gap-1">
          {/* <p className="h5_Regular">Results:</p>
          <p className="h3_Medium -mb-0.5">{selectedGallery === 0 ? JSON.parse(localStorage.getItem("workflow"))?.length + 205 || 205 : selectedGallery === 2 ? JSON.parse(localStorage.getItem("deployed"))?.length || 1 : 6}</p> */}
        </div>
        {selectedGallery === 0 && (
          <div className="flex justify-center items-center gap-4">
            <div className="flex justify-center items-center gap-2 bg-light-primary border py-1.5 px-4 rounded-md">
              <img src={plusImg} alt="plusImg" />
              <p className="text-primary">Add Custom</p>
            </div>
            <div className="flex justify-center items-center gap-2 bg-light-purple border py-1.5 px-4 rounded-md">
              <img src={upImg} alt="upImg" />
              <p className="text-main-purple">Request</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
