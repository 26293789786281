import React, {useState} from 'react';
import { Button, Card, Input } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import SegmentStudioPreview from './SegmentStudioPreview';

const SegmentStudioComponent = () => {
    const [isPreviewClicked, setIsPreviewClicked] = useState(false);
    const cardData = [
        {
            title: "Churned Lost Champions",
            description: "These are your high spending customers that you've lost recently but are still logging in and likely to make another purchase if re-engaged through paid channels.",
            number: "42,722"
        },
        {
            title: "High Intent Former Customer",
            description: "These are your frequent customers that have been lost recently but are still engaging with your marketing emails. They are likely to come back with a coupon offer sent over email.",
            number: "25,723"
        },
        {
            title: "Lost Laggards",
            description: "These are your lost customers that purchased infrequently, but in high priority categories. You can engage them with your most popular products through push notification.",
            number: "12,449"
        },
    ];
    const cardData2 = [
        {
            title: "Upsell Candidates",
            description: "Customers using our basic plan who have shown interest in advanced features. They are prime candidates for upselling to our premium tier.",
            number: "15,621"
        },
        {
            title: "Cross-Department Expansion",
            description: "Accounts where we have strong adoption in one department, but potential for expansion into other departments or teams.",
            number: "8,934"
        },
        {
            title: "Feature Adopters",
            description: "Users who have recently adopted new features and might be interested in related products or services in our ecosystem.",
            number: "11,247"
        },
    ];
    const tags = [
        { "title": "Demo Request Last 30 Days" },
        { "title": "Visited Website Recently" },
        { "title": "ICP Tier 1" },
        { "title": "Has 6Sense Keywords" },
        { "title": "Engaged with Email" },
        { "title": "Attended Webinar" },
        { "title": "Downloaded Whitepaper" },
        { "title": "High Intent Score" },
        { "title": "Decision Maker" },
        { "title": "Budget Holder" },
        { "title": "Industry: Technology" },
        { "title": "Company Size: 500+" },
        { "title": "Geographic: North America" },
        { "title": "Using Competitor Product" },
        { "title": "Renewal Coming Up" },
        { "title": "Active Social Media Engagement" },
        { "title": "Visited Pricing Page" },
        { "title": "Multiple Stakeholders Involved" },
        { "title": "In-Market for Solution" }
    ]
    return (
        <>
            {
                !isPreviewClicked ?
                <div className='mt-4' style={{ display: 'flex' }}>
                    <div style={{ flex: 1, padding: 24 }}>
                        {/* Top Card with Segments heading, button, and texts */}
                        <Card style={{ marginBottom: 24 }}>
                            <div className='flex justify-between mb-2'>
                                <span style={{ fontSize: '24px', color: '#8EA9D0' }}>Segment Tags</span>
                            </div>
                            <div className='flex w-[75%] justify-between mb-2'>
                                <Input placeholder={"Search Tags"} />
                            </div>
                            <div className='flex flex-wrap mt-4'>
                                {tags.map((tag, index) => (
                                    <div key={index} className="flex justify-center mt-2 mx-1">
                                        <div className='text-xs bg-gray-300 rounded-full px-3 py-1'>{tag.title}</div>
                                    </div>
                                ))}
                            </div>

                        </Card>

                        {/* AI Suggested Segments */}
                        <div>
                            <h2 className='my-2' style={{ fontSize: '24px', color: '#8EA9D0' }}>AI Suggested Segments</h2>
                            <h3 className='my-2' style={{ fontSize: '18px' }}>Reduce Churn</h3>
                            <p>Segments to target with high likelihood to reduce churn.</p>

                            <div className='flex flex-wrap mt-4'>
                                {cardData.map((card, index) => (
                                    <Card key={index} title={card.title} style={{ width: '30%', marginBottom: '16px', marginRight: '16px' }}>
                                        <p>{card.description}</p>
                                        <div className="flex justify-between mt-2">
                                            <span className='text-[16px] text-[bold] text-[#2966BC]'>{card.number}</span>
                                            <Button onClick={() => setIsPreviewClicked(true)} type="primary">Preview</Button>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                            <h3 className='my-2' style={{ fontSize: '18px' }}>Cross Sell Opportunities</h3>
                            <p>Segments of customers ready to cross sell into a new catory</p>

                            <div className='flex flex-wrap mt-4'>
                                {cardData2.map((card, index) => (
                                    <Card key={index} title={card.title} style={{ width: '30%', marginBottom: '16px', marginRight: '16px' }}>
                                        <p>{card.description}</p>
                                        <div className="flex justify-between mt-2">
                                            <span className='text-[16px] text-[bold] text-[#2966BC]'>{card.number}</span>
                                            <Button type="primary">Preview</Button>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> : <div><SegmentStudioPreview /></div>
            }        
        </>
    );
};

export default SegmentStudioComponent;
