// ModelsTable.js
import React, { useState } from 'react';
import { Table, Button, Input, Modal, message } from 'antd';
import { UserOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import AccountFitScore from './AccountFitScore';

const APIkey = () => {
    const [selectedModel, setSelectedModel] = useState(null);
    const generateApiKey = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };
    const [apiKeys, setApiKeys] = useState([{
        key: 0,
        name: "DS_key",
        apiKey: generateApiKey(),
    }]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newKeyName, setNewKeyName] = useState('');
    const [generatedApiKey, setGeneratedApiKey] = useState('');

    const handleModelClick = (model) => {
        setSelectedModel(model);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (newKeyName) {
            const newKey = {
                key: apiKeys.length + 1,
                name: newKeyName,
                apiKey: generateApiKey(),
            };
            setApiKeys([...apiKeys, newKey]);
            message.success('API Key generated successfully!');
            setNewKeyName('');
            setIsModalVisible(false);
        } else {
            message.error('Please enter a name for the API key.');
        }
    };

    const handleCancel = () => {
        setNewKeyName('');
        setIsModalVisible(false);
    };

    const copyToClipboard = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        message.success('API Key copied to clipboard!');
    };

    const deleteApiKey = (key) => {
        setApiKeys(apiKeys.filter(apiKey => apiKey.key !== key));
        message.success('API Key deleted!');
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'API Key',
            dataIndex: 'apiKey',
            key: 'apiKey',
            render: (text) => (
                <Input.Password value={text} readOnly />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div className='flex justify-start gap-4'>
                    <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(record.apiKey)}></Button>
                    <Button icon={<DeleteOutlined />} danger onClick={() => deleteApiKey(record.key)}></Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            {!selectedModel ? (
                <>
                    <div className='flex justify-end my-2'>
                        <Button type="primary" onClick={showModal}>Generate API Key</Button>
                    </div>
                    <Table
                        dataSource={apiKeys}
                        columns={columns}
                        pagination={false}
                    />
                </>
            ) : (
                <AccountFitScore model={selectedModel} onBack={() => setSelectedModel(null)} />
            )}
            <Modal title="Generate API Key" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Input
                    placeholder="Enter name for the API key"
                    value={newKeyName}
                    onChange={(e) => setNewKeyName(e.target.value)}
                />
                {generatedApiKey && (
                    <div className="mt-2">
                        <p>API Key: <Input.Password value={generatedApiKey} readOnly /></p>
                        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(generatedApiKey)}>Copy</Button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default APIkey;
