import React, { useState, useRef, useEffect } from "react";

import {
  PieChartFilled,
  ShrinkOutlined,
  PlusOutlined,
  UndoOutlined,
  RedoOutlined,
  CloseOutlined,
  RiseOutlined,
  SafetyCertificateOutlined,
  ClusterOutlined,
  ExperimentOutlined,
  BankOutlined, BlockOutlined, IdcardOutlined
} from "@ant-design/icons";
import {
  BarChart, Bar, LineChart, Line, PieChart, Pie, FunnelChart, Funnel, ScatterChart, Scatter,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, LabelList
} from 'recharts';
import { Card, Table, Input, Dropdown, Menu, Modal, message, Progress } from "antd";
// import { Bar } from "react-chartjs-2";
import { GlitterDropdown } from "./GlitterDropdown.js";
import { jwtDecode } from "jwt-decode";
import graphImg from "../../assets/images/graphImg.png";
import shareImg from "../../assets/images/shareImg.png";
import downloadImg from "../../assets/images/downloadImg.png";
import glitterImg from "../../assets/images/glitter.png";
import funnelImg from "../../assets/images/funnel.png";
import overlapImg from "../../assets/images/overlapIcon.png";
import './ExtendedChat.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CopSuccessComponent from "./CopSuccessComponent.js";
import {
  faSalesforce,
  faHubspot,
  faLayerPlus
} from "@fortawesome/free-brands-svg-icons";
import {
  faDatabase,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import GraphOptions from "../GraphOptions.js";
import { useSetRecoilState, useRecoilState } from "recoil";
import { extendedChatAtom, copilotChatArrayAtom, cc_tableColsAtom, cc_tableDataAtom, cc_magicAtom, cc_modalAtom, hiddenColumnsAtom, deletedColumnsAtom, actionHistoryAtom } from "../../store";
import { chatModeAtom, sideNavContentAtom } from "../../store/atoms/MainAtoms";
import "./CoPilotStyles.css";
import EnrichOption from "../OptionsComponents/EnrichOption";
import DeduplicateOption from "../OptionsComponents/DeduplicateOption";
import FindLookALikeOption from "../OptionsComponents/FindLookALikeOption";
import AddPeopleOption from "../OptionsComponents/AddPeopleOption";
import ValidateOption from "../OptionsComponents/ValidateOption";
import FindParentChildOption from "../OptionsComponents/FindParentChildOption";

import html2canvas from "html2canvas";
import { getColumns, getQueryReport } from "../../services/InsightsServices";
import DisplayTableComponent from "./DisplayTableComponent";
import SfModal from "./SfModal.js";
import Plot from 'react-plotly.js';

import DynamicChart from "./DynamicChart.js";
import FormulaGenerator from "../OptionsComponents/FormulaOption.js";
// import FormulaGeneratorModal from "../OptionsComponents/FormulaOption.js";

const { Search } = Input;

const ExtendedChat = () => {
  const [progress, setProgress] = useState(0); // Progress state
  const [pocTableData, setPocTableData] = useRecoilState(cc_tableDataAtom)
  const [pocTableCols, setPocTableCols] = useRecoilState(cc_tableColsAtom)
  const [chatMode, setChatMode] = useRecoilState(chatModeAtom);
  const [sideNavContent, setSideNavContent] = useRecoilState(sideNavContentAtom);
  const [rowsSelected, setRowsSelected] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [chartVisible, setChartVisible] = useState(true);
  const [plusOptionsVisible, setPlusOptionsVisible] = useState(false);
  const [sfObjectOptionsVisible, setSFObjectOptionsVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [starOptionsVisible, setStarOptionsVisible] = useState(false);
  const [modalVisible, setIsModalVisible] = useState(false)
  const [optionSelected, setOptionSelected] = useState("")
  const [data, setData] = useState(null)
  const [magic, setMagic] = useRecoilState(cc_magicAtom);
  const [modal, setModal] = useRecoilState(cc_modalAtom);
  const [selectedGraphQueryId, setSelectedGraphQueryId] = useState(null)
  const [selectedGraphSQLquery, setSelectedGraphSQLquery] = useState(null)
  const [graphOptionsModal, setGraphOptionsModal] = useState(false)
  const [newObjects, setNewObjects] = useState(null)

  const [hiddenColumns, setHiddenColumns] = useRecoilState(hiddenColumnsAtom);
  const [deletedColumns, setDeletedColumns] = useRecoilState(deletedColumnsAtom);
  const [actionHistory, setActionHistory] = useRecoilState(actionHistoryAtom);
  const [sfModalOpen, setSfModalOpen] = useState(false);

  const divRef = useRef(null);
  useEffect(() => {
    if (divRef?.current) {
      divRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  }, [])

  const setExtendedChat = useSetRecoilState(extendedChatAtom);
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setOptionSelected("")
  };
  const handleCSVModal = () => {
    setModal(false);
  };
  const handleOptionsClick = (optionName) => {
    setOptionSelected(optionName)
    setIsModalVisible(true);
  };
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const starOptions = [
    {
      label: "Enrich",
      icon: <RiseOutlined className="mr-2" />,
    },
    {
      label: "Deduplicate",
      icon: <BlockOutlined className="mr-2" />,
    },
    {
      label: "Find look-a-likes",
      icon: <BankOutlined className="mr-2" />,
    },
    {
      label: "Add people",
      icon: <IdcardOutlined className="mr-2" />,
    },
    {
      label: "Validate",
      icon: <SafetyCertificateOutlined className="mr-2" />,
    },
    {
      label: "Find parent/child",
      icon: <ClusterOutlined className="mr-2" />,
    },
    {
      label: "Formula",
      icon: <ExperimentOutlined className="mr-2" />,
    },
  ];
  const filteredStarOptions = starOptions.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );
  const starMenu = (
    <Menu>
      <Menu.Item>
        <Search
          placeholder="Search"
          onSearch={value => setSearchText(value)}
          allowClear
          style={{
            width: "100%",
            marginBottom: "10px",
          }}
        />
      </Menu.Item>
      {filteredStarOptions.map(option => (
        <Menu.Item onClick={() => handleOptionsClick(option.label)} key={option.label}>
          {option.icon}
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  const handleSFClick = () => {
    console.log("asdasdasd");
    setSfModalOpen(true)
  }
  const plusOptions = [
    {
      label: "Salesforce",
      icon: faSalesforce,
      onClick: handleSFClick
    },
    {
      label: "HubSpot",
      icon: faHubspot,
    },
    {
      label: "Unified Data",
      icon: faDatabase,
    },
    {
      label: "Favorite Columns",
      icon: faStar,
    },
  ];

  const filteredPlusOptions = plusOptions.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );

  const plusMenu = (
    <Menu>
      {filteredPlusOptions.map(option => (

        <Menu.Item key={option.label} onClick={() => option.onClick()}>
          <FontAwesomeIcon icon={option.icon} className="mr-2" />
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setRowsSelected(selectedRows?.length);
    },
  };
  const fetchData = async () => {
    try {
      const payload = {
        query_id: localStorage.getItem("extended_query_id")
      };
      const response = await getQueryReport(payload);
      if (response.data.status === "success") {
        const data = response.data.result;
        setData(data)
        setSelectedGraphQueryId(localStorage.getItem("extended_query_id"))
        setSelectedGraphSQLquery(data?.sql_query)
      } else {
        message.error("Error fetching Extended data");
      }
    } catch (error) {
      // console.error("Error fetching dashboard data:", error);
      message.error("Error fetching data");
    }
  };
  const fetchColumnObjects = async () => {
    const orgParam = new URLSearchParams(window.location.search).get('org');
    let organization = jwtDecode(localStorage.getItem("loginToken")?.replace(process.env.REACT_APP_TOKEN_PASSCODE, ''))?.organization_domain;
    if (orgParam) {
      const [password, orgName] = orgParam.split(' ');
      const domainPassword = process.env.REACT_APP_DOMAIN_PASSWORD;
      if (password === domainPassword) {
        organization = orgName + ".com";
      }
    }
    let schema_name;
    if (organization === "talkable.com") {
      schema_name = "talkable_master";
    } else if (organization === 'zillasecurity.com') {
      schema_name = "zillasecurity_master";
    } else {
      schema_name = "talkable_master";
    }
    try {
      const payload = {
        schema_name: schema_name
      };
      const response = await getColumns(payload);
      const data = response.data;
      setNewObjects(data)
      console.log(data, "asdasd")
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      message.error("Error fetching column object");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("extended_query_id") == "FILE_CC") {

    } else {
      fetchData();
    }
    fetchColumnObjects();
  }, []);
  const minimizeChat = () => {
    setExtendedChat(false)
    setChatMode(0)
    setSideNavContent(0)
  }
  const downloadChartAsImage = (id) => {
    const chartElement = document.getElementById(id);
    console.log(chartElement, "-----sdfsf------");
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'chart.png';
        link.click();
        message.success("Image downlaoded successfully")
      });
    }
  };
  const handleProgressBar = () => {
    if (progress < 100) {
      const interval = setInterval(() => {
        setProgress((prev) => prev + 16);

      }, 1300);
      return () => clearInterval(interval);
    }
  }
  useEffect(() => {
    if (modal) {
      handleProgressBar()
    }
  }, [modal])

  const openGraphOptionsModal = (query_id, postgres_query) => {
    setSelectedGraphQueryId(query_id)
    setSelectedGraphSQLquery(postgres_query)
    setGraphOptionsModal(true)
  }

  const renderChartMain = (selectedChartType, chartData, showReferenceLine, xAxis, yAxis, chartColor, showValues) => {
    switch (selectedChartType) {
      case 'Bar':
        return (
          <BarChart
            width={1000} // Increased width
            height={500}
            data={chartData}
            margin={{ top: 20, right: 30, left: 40, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis
              dataKey={xAxis}
              tick={{
                angle: -60, // Increased angle for better fitting
                fontSize: 10,
                width: 100, // Increased width for labels
                interval: 0, // Show all labels
                textAnchor: 'end', // Align text to the end
                formatter: (value) => {
                  const stringValue = String(value);
                  return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                },
              }}
              height={100} // Further increased height for more space
            />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill={chartColor}>
              {showValues && (
                <LabelList
                  dataKey={yAxis}
                  position="top" // Move labels outside the bars
                  formatter={(value) => {
                    const stringValue = String(value);
                    return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                  }}
                  style={{ fontSize: 10 }} // Adjust font size if necessary
                />
              )}
            </Bar>
          </BarChart>
        );
      case 'Column':
        return (
          <BarChart width={1000} height={500} data={chartData} layout="vertical" margin={{ top: 20, right: 30, left: 40, bottom: 30 }}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis type="number" tick={{ fontSize: 10 }} />
            <YAxis dataKey={xAxis} type="category" tick={{ fontSize: 8 }} width={100} /> {/* Adjust width for longer labels */}
            <Tooltip />
            <Legend />
            <Bar dataKey={yAxis} fill={chartColor}>
              {showValues && (
                <LabelList
                  dataKey={yAxis}
                  position="right" // Options: 'insideLeft', 'insideRight', 'right'
                  formatter={(value) => {
                    const stringValue = String(value); // Ensure value is a string
                    return stringValue.length > 10 ? `${stringValue.slice(0, 10)}...` : stringValue;
                  }}
                  style={{ fontSize: 10 }} // Adjust font size if necessary
                />
              )}
            </Bar>
          </BarChart>

        );
      case 'Stacked Bar':
        return (
          <BarChart width={600} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="top" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="top" />}
            </Bar>
          </BarChart>
        );
      case 'Stacked Column':
        return (
          <BarChart width={600} height={300} data={chartData} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis type="number" />
            <YAxis dataKey={xAxis} type="category" />
            <Tooltip />
            <Legend />
            <Bar dataKey="age" stackId="a" fill="#8884d8">
              {showValues && <LabelList dataKey="age" position="right" />}
            </Bar>
            <Bar dataKey="salary" stackId="a" fill={chartColor}>
              {showValues && <LabelList dataKey="salary" position="right" />}
            </Bar>
          </BarChart>
        );
      case 'Line':
        return (
          <LineChart width={600} height={300} data={chartData}>
            <CartesianGrid strokeDasharray="3 3" vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={yAxis} stroke={chartColor} />
          </LineChart>
        );
      case 'Donut':
        return (
          <PieChart width={400} height={400}>
            <Pie
              data={chartData}
              dataKey={yAxis}
              nameKey={xAxis}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              fill={chartColor}
              label={showValues}
            />
          </PieChart>
        );
      case 'Funnel':
        return (
          <FunnelChart width={600} height={400}>
            <Tooltip />
            <Funnel
              dataKey={yAxis}
              data={chartData}
              isAnimationActive
              fill={chartColor}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={chartColor} />
              ))}
            </Funnel>
          </FunnelChart>
        );
      case 'Scatter Plot':
        return (
          <ScatterChart width={600} height={400}>
            <CartesianGrid vertical={showReferenceLine} horizontal={showReferenceLine} />
            <XAxis dataKey={xAxis} />
            <YAxis dataKey={yAxis} />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} />
            <Legend />
            <Scatter name="Scatter Plot" data={chartData} fill={chartColor} />
          </ScatterChart>
        );
      case 'plotly':
        console.log({ data })
        const { data, layout } = chartData
        return (
          <Plot data={data} layout={layout}></Plot>
        )
      default:
        return null;
    }
  };
  const graphOptionclose = () => {
    setGraphOptionsModal(false)
    fetchData()
  }
  const handleUndo = () => {
    if (actionHistory.length === 0) return;

    const lastAction = actionHistory[actionHistory.length - 1];
    if (lastAction.action === "hide") {
      setHiddenColumns(
        hiddenColumns.filter((col) => col !== lastAction.column)
      );
    } else if (lastAction.action === "delete") {
      setDeletedColumns(
        deletedColumns.filter((col) => col !== lastAction.column)
      );
    }

    setActionHistory(actionHistory.slice(0, -1));
  };
  return (
    <>
      <Modal
        title=""
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        className={optionSelected == 'Formula' ? 'min-w-[80%]' : optionSelected}
      >
        {optionSelected == 'Enrich' && <EnrichOption />}
        {optionSelected == 'Deduplicate' && <DeduplicateOption />}
        {optionSelected == 'Find look-a-likes' && <FindLookALikeOption />}
        {optionSelected == 'Add people' && <AddPeopleOption />}
        {optionSelected == 'Validate' && <ValidateOption />}
        {optionSelected == 'Find parent/child' && <FindParentChildOption />}
        {optionSelected == 'Formula' && <FormulaGenerator />}
      </Modal>
      <Modal
        title=""
        visible={graphOptionsModal}
        onCancel={() => setGraphOptionsModal(false)}
        footer={[
        ]}
      >
        <GraphOptions queryID={selectedGraphQueryId} postgres_query={selectedGraphSQLquery} graphOptionclose={graphOptionclose} />
      </Modal>
      <Modal
        title=""
        visible={modal}
        onCancel={handleCSVModal}
        footer={null}
        className=""
        centered
      >
        <div className="h-[40vh] flex flex-col justify-center items-center gap-4">
          {progress >= 100 ? (
            <div className="">
              <CopSuccessComponent setModal={setModal} />
            </div>
          ) : (
            <>
              <Progress percent={progress} />
              <p className="text-xl text-[#2966BC] font-bold text-center">Matched & Enriched Contacts from Event CSV syncing with Salesforce</p>
            </>
          )}
        </div>
      </Modal>
      <SfModal visible={sfModalOpen} newObjects={newObjects} setSfModalOpen={setSfModalOpen} />
      <div className="flex justify-between items-center gap-2 mr-4 border border-2 border-black p-2 rounded-md">
        {data?.name ?
          <>
            {data?.name}
          </> :
          <>{chatMode == 1 ? "Summer Event 2024" : " "}</>
        }
        <div className="flex gap-2">
          {actionHistory?.length > 0 && !chartVisible && (
            <UndoOutlined rotate={90} className="" onClick={handleUndo} />
          )}
          <div className="-mr-1 mb-0.5 relative" ref={divRef}>
            <img
              src={shareImg}
              alt="shareImg"
              width={24}
              className={`hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] ${filterVisible && "border border-white border-b-[#E07E65]"
                }`}
              onClick={() => setFilterVisible(!filterVisible)}
            />
            {filterVisible && (
              <div className="absolute -bottom-[8.5rem] -right-[4.5rem] z-50 flex flex-col gap-1 justify-center items-center w-[900%] border bg-gray-300 p-2">
                <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                  Save to Dashboard
                </div>
                <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                  Generate Embeddable Link
                </div>
                <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                  Send on Slack
                </div>
                <div className="w-full flex justify-center items-center border bg-white hover:bg-[#E07E65] hover:text-white hover:scale-105 duration-300 ease-in-out cursor-pointer rounded-md">
                  Send Via Email
                </div>
              </div>
            )}
          </div>
          <img
            src={graphImg}
            alt="graphImg"
            width={20}
            className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
            onClick={() => openGraphOptionsModal(localStorage.getItem("extended_query_id"), data?.sql_query)}
          />
          <img
            src={glitterImg}
            alt="glitterImg"
            width={20}
            className={`hover:scale-110 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] h-5 mt-0.5 ${magic && "border border-white border-b-[#E07E65]"
              }`}
            onClick={() => setMagic(!magic)}
          />
          {magic && (
            <div className="absolute top-36 right-10 z-50 flex flex-col gap-1 justify-center items-center w-[20%] bg-white border p-2 shadow-md rounded-md">
              <GlitterDropdown />
            </div>
          )}
          {/* <img
                src={graphImg}
                alt="graphImg"
                width={22}
                className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
              /> */}
          <PieChartFilled
            className={`-mr-1 mb-0.5 hover:scale-105 p-1 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] hover:p-1 ${chartVisible && "border border-white border-b-[#E07E65] p-1"
              }`}
            onClick={() => setChartVisible(!chartVisible)}
          />
          <img
            src={downloadImg}
            alt="downloadImg"
            width={26}
            className="hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65]"
            onClick={() => downloadChartAsImage()}
          />
          <ShrinkOutlined
            className="text-[14px] p-1 -ml-1 hover:scale-105 cursor-pointer hover:shadow-lg duration-300 ease-in-out hover:border hover:border-white hover:border-b-[#E07E65] hover:p-1"
            onClick={() => minimizeChat(false)}
          />
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        {localStorage.getItem("extended_query_id") == "FILE_CC" ?
          <Card className="w-[95%] relative mt-2">
            <div className="" style={{ overflowY: "scroll" }}>
              <Table className='w-full'
                rowSelection={rowSelection}
                style={{ overflowX: "scroll" }}
                dataSource={pocTableData}
                columns={pocTableCols}
                pagination={true}
              />
            </div>
          </Card> :
          <Card className="w-[95%] flex flex-col relative mt-2">
            {chartVisible && data?.chart_data ?
              <>
                {
                  data?.chart_type == 'plotly' ?
                    <div id={data?.report_id} className="flex h-[400px] justify-center items-center flex-wrap gap-4 text-base rounded-md">
                      <DynamicChart chartData={data?.chart_data} type="read" />
                    </div>
                    :
                    <div id={data?.report_id} className="flex justify-center items-center flex-wrap gap-4 text-base rounded-md">
                      {renderChartMain(data?.chart_type, (data && JSON.parse(data?.chart_data)), data?.show_grid, data?.x_axis, data?.y_axis, data?.chart_color, data?.show_values)}
                    </div>
                }

                {/* <div id={chat?.query_id} className="flex justify-start items-center flex-wrap gap-4 text-base w-[90%] rounded-md mt-4 p-4 ml-[74px]">
                    <PythonExecutor code={chat.charts} query_id= {chat.query_id} />
                  </div> */}
                <div className="h-[250px]" style={{ overflowY: "scroll" }}>
                  <DisplayTableComponent query_id={localStorage.getItem("extended_query_id")} postgres_query={data?.sql_query} minimized={true} />
                </div>
              </>
              :
              <>
                <div className="" style={{ overflowY: "scroll" }}>
                  <DisplayTableComponent query_id={localStorage.getItem("extended_query_id")} postgres_query={data?.sql_query} minimized={false} />
                </div>
              </>
            }
          </Card>
        }
      </div>
    </>
  );
};

export default ExtendedChat;
