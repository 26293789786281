import React from "react";

import undo from "../../assets/images/undo.png";
import redo from "../../assets/images/redo.png";
import {
  BarChartOutlined,
  TeamOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import AgentCustomBtn from "../AgentCustomBtn/AgentCustomBtn";
import { useRecoilState } from "recoil";
import { resultsVisibleAtom } from "../../store";

const DefineNavbar = () => {
  const [resultsVisible, setResultsVisible] =
    useRecoilState(resultsVisibleAtom);

  return (
    <div className="px-32 py-4 bg-gray-100 w-[102%] -ml-4 -mt-4 z-50 sticky top-36">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <img src={undo} alt="undo" width={14} />
          <img src={redo} alt="redo" width={14} />
          <div className="flex items-center gap-0">
            <LeftOutlined />
            <RightOutlined />
          </div>
        </div>
        <div className="flex items-center gap-24">
          <div className="flex items-center gap-1">
            <TeamOutlined />
            <p className="body_Regular">Size: {resultsVisible && <span className="font-bold">10,277</span>}</p>
          </div>
          {resultsVisible ? (
            <AgentCustomBtn
              otherClasses={"flex items-center gap-2 !bg-white !text-primary"}
              onClick={() => setResultsVisible(!resultsVisible)}
            >
              <RightOutlined />
              <p className="body_Regular">Hide Results</p>
            </AgentCustomBtn>
          ) : (
            <AgentCustomBtn
              otherClasses={"flex items-center gap-2"}
              onClick={() => setResultsVisible(!resultsVisible)}
            >
              <BarChartOutlined />
              <p className="body_Regular">Preview Results</p>
            </AgentCustomBtn>
          )}
        </div>
      </div>
    </div>
  );
};

export default DefineNavbar;
