const EnrichmentContent = ({ content }) => {
  const getText = (text) => {
    // Handle lines starting with '**'
    // if (
    //   text?.startsWith("**") &&
    //   !text?.toLowerCase()?.includes("score") &&
    //   !text?.toLowerCase()?.includes("out of") &&
    //   // !text?.toLowerCase()?.includes("total lead score") &&
    //   // !text?.toLowerCase()?.includes("score for company research") &&
    //   // !text?.toLowerCase()?.includes("final lead total score") &&
    //   !text?.toLowerCase()?.includes("@")
    // ) {
    //   const line = text?.slice(2, -2).trim();
    //   return <span className="text-lg">{line}</span>;
    // }
    // Handle lines starting with '###'
    if (
      (text?.startsWith("###"))
      // !text?.toLowerCase()?.includes("final lead total score") &&
      // !text?.toLowerCase()?.includes("final fit total score")
    ) {
      const line = text?.slice(3).trim();
      return <span className="text-lg font-bold">{line?.replaceAll("**", "")?.replaceAll("#", "")}</span>;
    }
    text = text?.replace("###", "")?.trim();
    text = text?.replace("####", "")?.trim();
    // Handle lines starting with a number followed by ". **"
    const numberedMatch = text?.match(/^(\d+)\.\s\*\*(.*?)\*\*(.*)$/);
    if (numberedMatch) {
      const [_, num, boldText, restText] = numberedMatch;
      return (
        <span>
          <span className="text-md font-bold">{num}.</span>{" "}
          <span className="text-md font-bold">{boldText}</span>
          <span className="font-normal">{restText}</span>
        </span>
      );
    }
    // Handle mixed bold and normal text within a single line
    const mixedBoldNormalMatch = text
      ?.split(/(\*\*.*?\*\*)/g)
      ?.map((part, i) => {
        if (part?.startsWith("**") && part?.endsWith("**")) {
          let line = part?.slice(2, -2)?.trim();
          return (
            <span key={i} className="font-bold">
              {line}
            </span>
          );
        }
        // Handle markdown links
        const linkMatch = part?.match(/\[(.*?)\]\((.*?)\)/);
        if (linkMatch) {
          const [_, linkText, linkUrl] = linkMatch;
          return (
            <span key={i} className="font-bold">
              <a
                target="_blank"
                href={linkUrl}
                className="text-blue-500 underline pl-1"
              >
                {linkText}
              </a>
            </span>
          );
        }
        return part;
      });

    if (mixedBoldNormalMatch?.length > 1) {
      return <span>{mixedBoldNormalMatch}</span>;
    }

    // Handle markdown links if any
    const linkMatch = text?.match(/\[(.*?)\]\((.*?)\)/);
    if (linkMatch) {
      const [_, linkText, linkUrl] = linkMatch;
      return (
        <a target="_blank" href={linkUrl} className="text-blue-500 underline">
          {linkText}
        </a>
      );
    }

    // Handle text inside double quotes
    const quotedTextMatch = text?.split(/(".*?")/g)?.map((part, i) => {
      if (part?.startsWith('"') && part?.endsWith('"')) {
        const line = part?.slice(1, -1)?.trim();
        return (
          <span key={i} className="font-bold">
            {line}
          </span>
        );
      }
      return part;
    });

    if (quotedTextMatch?.length > 1) {
      return <span>{quotedTextMatch}</span>;
    }

    // Handle LaTeX-style math notation with dynamic numbers
    const latexMathMatch = text?.match(
      /\\text\{Average\} = \\frac\{\\text\{Lead Score\} \+ \\text\{Fit Score\}\}\{2\} = \\frac\{([\d.]+) \+ ([\d.]+)\}\{2\} = ([\d.]+)/
    );
    if (latexMathMatch) {
      const [_, leadScore, fitScore, average] = latexMathMatch;
      return (
        <span className="font-mono">
          average = (lead score + fit score) / 2 = ({leadScore} + {fitScore}) /
          2 = {average}
        </span>
      );
    }

    // Ignore isolated LaTeX delimiters
    if (["\\[", "\\]"].includes(text?.trim())) {
      return null;
    }

    // Return the text as it is if no conditions are met
    return text;
  };
  if (!content) return <></>;
  return (
    <div className="mt-4 flex flex-col gap-1 text-[11px] bg-gray-100 p-2 border rounded-md">
      {content?.split("\n")?.map((con, idx) =>
        !con?.startsWith("Starting tool") &&
        !con?.startsWith("Done tool") &&
        con?.trim() !== "" ? (
          <p key={idx}>{getText(con?.trim())}</p>
        ) : (
          // <p key={idx}>{con?.trim()}</p>
          <></>
        )
      )}
    </div>
  );
};

export default EnrichmentContent;
